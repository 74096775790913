import React from 'react';
import { useTranslation } from 'react-i18next';
import "./welcome.scss";
import {
  SuccessIllus,
  IconDelete
} from '../../../../assets/icons';

import IconButton from '@material-ui/core/IconButton';
import DoneButton from '../../../DoneButton';
export default function WelcomePremium({
  doClose,
}) {
  const { t } = useTranslation();

  return <div className="billing-welcome" data-testid="billing-welcome">
    <div>
      <SuccessIllus />
      <div>
        <div className="title">{t('settings.sections.billing.premium.welcome.title')}</div>
        <div className="subtitle">{t('settings.sections.billing.premium.welcome.message')}</div>
      </div>
    </div>
    {/* {doClose && <IconButton className="fixed-top" onClick={doClose} data-testid="success-close"><IconDelete /></IconButton>} */}
    <DoneButton label={t('settings.sections.billing.premium.welcome.ok')} onClick={doClose} name="success-close" />
  </div>
}