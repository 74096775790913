import React from 'react';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import { isValidDuration } from '../../../../validators/event'
import * as ACTIONS from '../../../../actions';
import moment from 'moment';
import "./periods.scss";
import NOOP from '../../../../utils/noop';
import { getContacts } from '../../../../reducers/contacts';
import * as uuid from "uuid";
import { linearize } from '../../../../worker/handlers/generate.slots';
import { PREFILL_EDIT } from '../../../../reducers/proposals'
import { PeriodRenderer } from './period.renderer.202212';
import ConfirmOneSlotOnlyDialog from '../../event/simple/confirm.one.slot';
import { useDialog } from '../../../dialogs/dialog.provider';
const GEN_SLOTS_ID = "GEN_SLOTS:" + uuid.v4();

const EMPTY = {}
const LOAD_SLOTS = (payload, dispatch, rid, useGlobalVoteRequested = true) => {
  payload.use_god_mod = true;
  payload.useGlobalVoteRequested = useGlobalVoteRequested;
  return dispatch({
    type: ACTIONS.WORKER_GEN_SLOTS,
    payload: payload,
    requestId: rid,
    options: {
      loadProfileIfUnknown: true,
    },
    resolvers: {
      resolveOn: ACTIONS.WORKER_GEN_SLOTS_SUCCESS,
      rejectOn: ACTIONS.WORKER_GEN_SLOTS_ERROR,
      workerID: GEN_SLOTS_ID, // force worker ID
    }
  })
}

const reduceInvitees = (inviteesDetails) => {
  return Object.keys(inviteesDetails).reduce((acc, inv) => {
    let tmp = inviteesDetails[inv];
    // if not optional or not vote requested, pass
    if (!tmp.optional && !tmp.vote_requested) return acc;
    acc[inv] = {
      optional: tmp.optional || false,
      vote_requested: tmp.vote_requested || false,
      __cpy: tmp.__cpy || 0
    }
    return acc;
  }, {})
}
const compare = (values, old) => {
  // check calendar is one of FB or not
  if (old) {
    if (old.duration !== values.duration
      || old.customduration !== values.customduration
      || old.oboCalendar !== values.oboCalendar
      //|| old.proposals !== values.proposals
      || old.important !== values.important
      || old.vote_required !== values.vote_required // add to comparaison as we react to change now
      || old.ignore_initial_avail !== values.ignore_initial_avail) return false;

    if (JSON.stringify(old.proposals) !== JSON.stringify(values.proposals)) return false;
    // only invitees is litigious
    let oldinv = reduceInvitees(old.inviteesDetails);
    let newinv = reduceInvitees(values.inviteesDetails);

    if (JSON.stringify(oldinv) !== JSON.stringify(newinv)) {
      return false;
    }
    // if one invitee got no details, do not process

    let oi = old.invitees || [];
    let ni = values.invitees || [];
    let same = ni.length === oi.length;
    for (let i of ni) {
      if (oi.indexOf(i) === -1) {
        // changed
        return false;
      }
    }
    return same;
  }
  return false;
}
/**
 * Display form information for creating event
 * on recap page
 * Periods for event
 * @param {Object} formDatas datas to display
 * {
 *  periods: {Array<???>} @TODO
 * }
 */
export default function Details({
  formDatas = EMPTY,
  oboCalendar = '',
  onChange = NOOP,
  // changeLabel = NOOP,
  isGeneratingLink = false,
  handleSubmit = NOOP,
  showcustomSlotsDlg = NOOP,
  showPreview, // function to call to show email preview
  setAttendeesStatus = NOOP, // change status for attendees: ie: forced or not
  disableSlots = false,
  freeUserExpired,
  Renderer = PeriodRenderer,
  setIsLoading = NOOP,
  mustShowWarningPopup = false,
  setMustShowWarningPopup = NOOP,
  setPollInfoForm = NOOP,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const [loading, _setLoading] = React.useState(false);
  const setLoading = React.useCallback((l) => {
    _setLoading(l);
    setIsLoading(l)
  }, [setIsLoading])
  const [slots, setSlots] = React.useState(false);
  const oldValues = React.useRef(false);
  const contacts = useSelector(getContacts);
  const { enqueueDialog, closeDialog } = useDialog();
  const [pollInfo, __setPollInfo] = React.useState({
    "will_turn_to_poll": false,
    "recommended_slot_count": 3
  });
  const setPollInfo = React.useCallback((info) => {
    setPollInfoForm(info)
    __setPollInfo(info)
  })
  const ctaLabel = React.useMemo(() => {
    if (loading) return 'createEvent.form.loading';
    if (formDatas.prefill_type === PREFILL_EDIT) return 'createEvent.form.saveChanges';
    else {
      if (pollInfo && pollInfo.will_turn_to_poll) return 'createEvent.form.sendPoll';
      else return 'createEvent.form.sendOptions';
    }
  }, [loading, pollInfo, formDatas.prefill_type]);

  // callback to show the Heatmap dialog
  const showHeatMap = React.useCallback((withSlots) => {
    let slotsForHeatmap = slots;
    if (withSlots && withSlots.slots) {
      slotsForHeatmap = withSlots;
    }
    if (slotsForHeatmap && slotsForHeatmap.slots) {
      let ln = linearize(slotsForHeatmap.slots).map((s) => ({
        start: moment(s.start),
        end: moment(s.end),
        uuid: uuid.v4(),
      }))
      let start = (ln[0] || { start: moment() }).start
      showcustomSlotsDlg(true, { values: ln, initialDate: start })
    }
  }, [slots, showcustomSlotsDlg]);

  React.useEffect(() => {
    // check if something change
    if (!formDatas.proposals ||
      !formDatas.organizer || !formDatas.organizer.email) {
      setPollInfo({
        "will_turn_to_poll": false,
        "recommended_slot_count": 3,
      });
      return;
    }
    let calendar = formDatas.calendar

    if (!calendar) {
      setPollInfo({
        "will_turn_to_poll": false,
        "recommended_slot_count": 3,
      });
      return;
    }

    let invitees = [...(formDatas.invitees || [])];
    let details = { ...(formDatas.inviteesDetails || {}) };

    // filter optionals
    invitees = invitees.filter((inv) => {
      return !(details[inv] || {}).optional;
    });
    let tmp = {
      proposals: formDatas.proposals,
      duration: formDatas.duration,
      important: formDatas.important,
      customduration: formDatas.customduration,
      vote_required: formDatas.vote_required,
      ignore_initial_avail: formDatas.currenttimeframe === -1 /*formDatas.ignore_initial_avail*/,
      oboCalendar,
      invitees, inviteesDetails: JSON.parse(JSON.stringify(details)) // deep copy?
    };
    if (compare(tmp, oldValues.current)) {
      // same data, pass
      console.log("Same data, pass")
      return;
    }
    // check duration is valid TODO
    let d = formDatas.duration > 0 ? formDatas.duration : formDatas.customduration;
    if (!isValidDuration(d)) return;
    oldValues.current = tmp;
    onChange({
      target: {
        name: 'slots',
        value: []
      }
    });
    setSlots(null);

    if (formDatas.proposals.length === 0) {
      setPollInfo({
        "will_turn_to_poll": false,
        "recommended_slot_count": 3,
      });
      return;
    }

    setLoading(true);
    // add datas in closure
    const payload = {
      proposals: formDatas.proposals.map((p) => ({
        ...p,
        start: p.start.toISOString(),
        end: p.end.toISOString()
      })),
      duration: formDatas.duration,
      customduration: formDatas.customduration,
      invitees: [formDatas.organizer.email, ...invitees],
      pollInfoInvitees: invitees,
      cal_id: oboCalendar,
      calendar: formDatas.calendar,
      important: formDatas.important,
      ignore_initial_avail: formDatas.currenttimeframe === -1 /*formDatas.ignore_initial_avail*/,
      forceSlot: true,
      vote_required: formDatas.vote_required,
      // new: for calculation of poll
      inviteesDetails: formDatas.inviteesDetails,
      overlapp: formDatas.overlapp,
    }
    let rid = uuid.v4();
    LOAD_SLOTS(payload, dispatch, rid, false)
      .then((datas) => {
        /* istanbul ignore if no datas */
        if (!datas || !datas.payload) return Promise.reject('No datas');
        // if form datas add changed, do not process
        let slots = JSON.parse(datas.payload);
        return slots;
      })
      .then((slots = {}) => {
        let pollInfo = slots.pollInfo;
        if (pollInfo) {
          setPollInfo({
            ...pollInfo,
            // populate some extra data for message
            total: slots.total,
            final_slot: slots.final_slot
          })
        } else {
          setPollInfo({
            "will_turn_to_poll": false,
            "recommended_slot_count": 3,
            total: slots.total,
            final_slot: slots.final_slot, // do not
          });
        }
        // ------------------------------------------------------------------------------------
        // show warning dialog if not enough slots for funnel
        // ------------------------------------------------------------------------------------
        if (mustShowWarningPopup) {
          setMustShowWarningPopup(false);
          const onlyKnownAvailbility = Object.keys((formDatas.inviteesDetails || {}))
            .filter(inviteeEmail => !(contacts[inviteeEmail] && contacts[inviteeEmail].isDoner)).length === 0;
          const inviteesToVote = Object.keys((formDatas.inviteesDetails || {}))
            .filter(inviteeEmail => {
              const isDoner = contacts[inviteeEmail] && contacts[inviteeEmail].isDoner
              const invitee = formDatas.inviteesDetails[inviteeEmail] || {};
              if (isDoner && !invitee.isForbidden && !invitee.vote_requested) {
                return false;
              } else {
                return true;
              }
            });
          const notEnoughSlots = (pollInfo && pollInfo.will_turn_to_poll) || slots.total === 1;

          if (
            notEnoughSlots
            && formDatas.currenttimeframe === -1
            && inviteesToVote.length > 0
            && !(onlyKnownAvailbility && slots.total === 1)
          ) {
            const addMoreSlots = () => {
              closeDialog();
              showHeatMap(slots);
            }
            const nbRecommended = (pollInfo && pollInfo.recommended_slot_count) || 2;
            enqueueDialog({
              content: <ConfirmOneSlotOnlyDialog
                doConfirm={() => closeDialog()}
                doClose={() => addMoreSlots()}
                nbSlotsVoted={/*linearize(slots.slots).length*/slots.total}
                nbSlotsRecommended={nbRecommended}
                nbGuestsLeftToVoteAfterMe={inviteesToVote.length}
                labelPrefix='.organizer' />,
              mustConfirm: true,
              addMoreSlots,
            })
          }
        }
        // ------------------------------------------------------------------------------------
        return slots;
      })
      .then((slots) => {
        // custom check: if no slots, create one of duration at start of proposal
        onChange({
          target: {
            name: 'slots',
            value: slots.total > 0 ? slots.slots : []
          }
        });
        setSlots(slots);
        setAttendeesStatus(slots.forcedAttendees, slots.forbiddens)
      }).catch((err) => {
        if (err && err.type === 'CANCELLED_HANDLER') {
          console.log('requestID check reject', err)
          return err;
        }
        console.error('Slots computation aborted', err)
        /* istanbul ignore if storybook */
        if (snackbar) snackbar.enqueueSnackbar(t('createEvent.form.recap.slotsError'), { variant: 'error' })
      }).then((res) => {
        if (res && res.type === 'CANCELLED_HANDLER') return;
        setLoading(false)
      })
  }, [formDatas.proposals, formDatas.duration, formDatas.customduration, formDatas.invitees, formDatas.inviteesDetails, onChange,
    oboCalendar, oldValues, formDatas.important, formDatas.ignore_initial_avail, formDatas.vote_required, setAttendeesStatus])

  // display table
  return <Renderer
    loading={loading} slots={slots} formDatas={formDatas} handleSubmit={handleSubmit} freeUserExpired={freeUserExpired}
    isGeneratingLink={isGeneratingLink}
    showcustomSlotsDlg={showcustomSlotsDlg} showHeatMap={showHeatMap}
    onChange={onChange} disableSlots={disableSlots} pollInfo={pollInfo}
    contacts={contacts} showPreview={showPreview} ctaLabel={ctaLabel} />

}

// simple display for mobile case
export function SlotsList({ slots, doClose }) {
  // display  simple list
  const { t } = useTranslation();
  if (!slots || !slots.slots) return null;
  return <div className='periods-details-slots' data-testid="details">
    <Button aria-label="delete" className="edit-btn"
      data-testid="details-close"
      onClick={doClose}>
      {t('common.close')}
    </Button>
    <List className="periods-details fancy-scroll fit">
      {slots.slots.map((row, i) => {
        let day = [<ListItemText align="center" className="table-head" data-testid={"day-" + i} key={"day-" + i}>{moment(row.day).format('ddd, DD/MM Z')}</ListItemText >]
        day.push(...(row.slots || []).map((v, j) => {
          return v && <ListItemText align="center" data-testid={"day-" + i + '-' + j} key={"day-" + i + '-' + j}>
            {moment(v.start).format('HH:mm')}-{moment(v.end).format('HH:mm')}
          </ListItemText >
        }));
        day.push(<Divider key={'divider' + i} />)
        return day;
      })}

    </List>
  </div>;
}