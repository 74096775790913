// get domain and api from current domain

const location = global
  ? global.location.origin
  : "https://web.dev.meet-cal.com";
// let api = location; // .replace('web.', 'api.');
// /* istanbul ignore if dev */
// if (location === "http://localhost:3000" || location.includes("192.168.0"))
//   api = ""; // "https://web.meet-cal.com";

export const SERVER = ""; // api; // api server
export const VERSION = "/v1";
export const HOME = "/app"; // home of application
export const DOMAIN = location + HOME;

export const SUPPORTED_PROVIDERS = {
  google: 1,
  microsoft: 1,
};

export const isProd = () => process.env.REACT_APP_MONSTER_ENV === "production";
export const isStaging = () => process.env.REACT_APP_MONSTER_ENV === "staging";