import React from 'react';
import { Route, useLocation, Redirect, Switch } from 'react-router-dom';
import routes from '../../../routes/routes.name';
import SettingsPage from './SettingsPage';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../reducers/profile';
import useDefaultTZ from '../../../hooks/use.default.tz';
import { isMobilePlateform } from '../../../utils/browser';
import { isDevSettingsEnabled } from '../../../hooks/use.dev.settings';
export default function Settings() {
  let location = useLocation();

  const devMode = isDevSettingsEnabled();
  const profile = useSelector(getProfile);
  const isAdmin = (profile.billing && profile.billing.customer_id && profile.billing.is_admin)

  useDefaultTZ()
  return (<div className="fadein">
    <Switch location={location}>
      <Route
        path={routes.APP_SETTINGS}
        exact={true}
        render={(props) => <SettingsPage id={'ACCOUNTS'} />
        } />
      <Route
        path={routes.APP_SETTINGS_ACCOUNTS}
        exact={true}
        render={(props) => <SettingsPage id={'ACCOUNTS'} />
        } />
      <Route
        path={routes.APP_SETTINGS_WH}
        exact={true}
        render={(props) => <SettingsPage id={'WORKING_HOURS'} />
        } />
      <Route
        path={routes.APP_SETTINGS_PRIVACY}
        exact={true}
        render={(props) => <SettingsPage id={'PRIVACY'} />
        } />
      <Route
        path={routes.APP_SETTINGS_INVITE}
        exact={true}
        render={(props) => <SettingsPage id={'INVITE'} />
        } />
      <Route
        path={routes.APP_SETTINGS_TOOLS}
        exact={true}
        render={(props) => <SettingsPage id={'TOOLS'} />
        } />
      <Route
        path={routes.APP_SETTINGS_ADVANCED}
        exact={true}
        render={(props) => <SettingsPage id={'ADVANCED'} />
        } />
      <Route
        path={routes.APP_SETTINGS_BILLING}
        exact={true}
        render={(props) => <SettingsPage id={'BILLING'} />
        } />
      {devMode &&
        <Route
          path={routes.APP_SETTINGS_DEV}
          exact={true}
          render={(props) => <SettingsPage id={'DEV'} />
          } />}
      {isAdmin &&
        <Route
          path={routes.APP_SETTINGS_PERSO}
          exact={true}
          render={(props) => <SettingsPage id={'COMPANY'} />
          } />}
      {/* <Route
                path={routes.APP_SETTINGS}
                exact={true}
                render={(props) => <SettingsPage id={'ACCOUNTS'} />
                } /> */}
      <Route path="*" render={(props) => <Redirect to={routes.APP_SETTINGS} />} />
    </Switch></div>
  );
}