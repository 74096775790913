/**
 * @fileOverview Redux store actions types
 * @author stephane@do.ne
 * @module redux
 */
/**
 * @property
 * Dumb start counter (Boilerplate only)
 */
export const WORKER_START_COUNTER = "WORKER_START_COUNTER";
export const WORKER_CREATE_EVENT_INIT = 'WORKER_CREATE_EVENT_INIT';
export const CREATE_EVENT_STEP_1 = "CREATE_EVENT_STEP_1";
export const EVENT_CREATED = "EVENT_CREATED";


export const WORKER_INIT = 'WORKER_INIT';
export const WORKER_CONNECT = 'WORKER_CONNECT';
export const WORKER_CONNECT_ERROR_NO_TOKEN = "CONNECT_ERROR_NO_TOKEN";
export const WORKER_DONE_TOKEN_RECEIVED = "DONE_TOKEN_RECEIVED";
export const WORKER_CONNECT_ERROR = 'CONNECT_ERROR';
export const WORKER_CONNECT_SUCCESS = 'CONNECT_SUCCESS';

export const WORKER_GET_ACCOUNTS = "WORKER_GET_ACCOUNTS";
export const WORKER_GET_ACCOUNTS_ERROR = "GET_ACCOUNTS_ERROR";
export const WORKER_GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";

export const WORKER_PROVIDER_TOKEN_REFRESH = 'WORKER_PROVIDER_TOKEN_REFRESH';
export const WORKER_PROVIDER_TOKEN_REFRESH_ERROR = 'WORKER_PROVIDER_TOKEN_REFRESH_ERROR';
export const DONE_TOKEN_REFRESH = 'DONE_TOKEN_REFRESH';

export const WORKER_FREE_BUSY = 'WORKER_FREE_BUSY';
export const WORKER_FREE_BUSY_SUCCESS = 'FREE_BUSY_SUCCESS';
export const WORKER_FREE_BUSY_ERROR = 'FREE_BUSY_ERROR';
export const WORKER_GEN_SLOTS = 'WORKER_GEN_SLOTS';
export const WORKER_GEN_SLOTS_SUCCESS = 'GEN_SLOTS_SUCCESS';
export const WORKER_GEN_SLOTS_ERROR = 'GEN_SLOTS_ERROR';
export const WORKER_SAVE_PROPOSAL = 'WORKER_SAVE_PROPOSAL';
export const WORKER_SAVE_PROPOSAL_SUCCESS = 'SAVE_PROPOSAL_SUCCESS';
export const WORKER_SAVE_PROPOSAL_ERROR = 'SAVE_PROPOSAL_ERROR';
export const WORKER_GET_PROPOSAL = 'WORKER_GET_PROPOSAL';
export const WORKER_GET_PROPOSAL_SUCCESS = 'GET_PROPOSAL_SUCCESS';
export const WORKER_GET_PROPOSAL_ERROR = 'GET_PROPOSAL_ERROR';
export const WORKER_GET_USER_EVENTS = 'WORKER_GET_USER_EVENTS';
export const WORKER_GET_USER_EVENTS_SUCCESS = 'GET_USER_EVENTS_SUCCESS';
export const WORKER_GET_USER_EVENTS_ERROR = 'GET_USER_EVENTS_ERROR';
export const WORKER_GET_PROFILE = 'WORKER_GET_PROFILE';
export const WORKER_GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const WORKER_GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const WORKER_GET_PROFILES = 'WORKER_GET_PROFILES';
export const WORKER_GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS';
export const WORKER_GET_PROFILES_ERROR = 'GET_PROFILES_ERROR';

export const WORKER_VOTE_PROPOSAL = 'WORKER_VOTE_PROPOSAL';
export const WORKER_VOTE_PROPOSAL_SUCCESS = 'VOTE_PROPOSAL_SUCCESS';
export const WORKER_VOTE_PROPOSAL_ERROR = 'VOTE_PROPOSAL_ERROR';
export const WORKER_LOGOUT = 'WORKER_LOGOUT';
export const WORKER_REFRESH_ACCOUNTS = 'WORKER_REFRESH_ACCOUNTS';
export const WORKER_REFRESH_ACCOUNTS_SUCCESS = 'REFRESH_ACCOUNTS_SUCCESS';
export const WORKER_REFRESH_ACCOUNTS_ERROR = 'REFRESH_ACCOUNTS_ERROR';

export const WORKER_CREATE_PROPOSAL = 'WORKER_CREATE_PROPOSAL';
export const WORKER_CREATE_PROPOSAL_SUCCESS = "CREATE_PROPOSAL_SUCCESS";
export const WORKER_CREATE_PROPOSAL_ERROR = "CREATE_PROPOSAL_ERROR";

export const WORKER_SAVE_EVENT = 'WORKER_SAVE_EVENT';
export const WORKER_SAVE_EVENT_SUCCESS = "SAVE_EVENT_SUCCESS";
export const WORKER_SAVE_EVENT_ERROR = "SAVE_EVENT_ERROR";

export const WORKER_DECLINE_EVENT = 'WORKER_DECLINE_EVENT';
export const WORKER_DECLINE_EVENT_SUCCESS = "DECLINE_EVENT_SUCCESS";
export const WORKER_DECLINE_EVENT_ERROR = "DECLINE_EVENT_ERROR";

export const WORKER_CANCEL_EVENT = 'WORKER_CANCEL_EVENT';
export const WORKER_CANCEL_EVENT_SUCCESS = "CANCEL_EVENT_SUCCESS";
export const WORKER_CANCEL_EVENT_ERROR = "CANCEL_EVENT_ERROR";

export const WORKER_SIGN_UP_ACCOUNT = 'WORKER_SIGN_UP_ACCOUNT';
export const WORKER_SIGN_UP_ACCOUNT_SUCCESS = "SIGN_UP_ACCOUNT_SUCCESS";
export const WORKER_SIGN_UP_ACCOUNT_ERROR = "SIGN_UP_ACCOUNT_ERROR";

export const WORKER_INVITE = 'WORKER_INVITE';
export const WORKER_INVITE_SUCCESS = "INVITE_SUCCESS";
export const WORKER_INVITE_ERROR = "INVITE_ERROR";

export const WORKER_UNREGISTER_ACCOUNT = "WORKER_UNREGISTER_ACCOUNT";
export const WORKER_UNREGISTER_ACCOUNT_SUCCESS = "UNREGISTER_ACCOUNT_SUCCESS";
export const WORKER_UNREGISTER_ACCOUNT_ERROR = "UNREGISTER_ACCOUNT_ERROR";

export const ASK_4_LOGIN = 'ASK_4_LOGIN';
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
export const PRE_CONNECTION_TOKEN = 'PRE_CONNECTION_TOKEN';


export const ANALYTICS_SIGNED_UP = "ANALYTICS_SIGNED_UP";
// export const ANALYTICS_NEW_ACCOUNT_REGISTERED = "ANALYTICS_NEW_ACCOUNT_REGISTERED";
export const ANALYTICS_PROPOSAL_CREATED = "ANALYTICS_PROPOSAL_CREATED";
export const ANALYTICS_MEETING_CREATED = "ANALYTICS_MEETING_CREATED";
export const ANALYTICS_VIEW_PROPOSAL = "ANALYTICS_VIEW_PROPOSAL";
export const ANALYTICS_PROPOSAL_ANSWERED = "ANALYTICS_PROPOSAL_ANSWERED";
export const ANALYTICS_INVITE_SENT = 'ANALYTICS_INVITE_SENT';
export const ANALYTICS_GET_LINK = 'ANALYTICS_GET_LINK';
export const ANALYTICS_PLAN_LATER = 'ANALYTICS_PLAN_LATER';
export const ANALYTICS_PLAN_NOW = 'ANALYTICS_PLAN_NOW';

export const WORKER_ANALYTICS_BOOKING_PAGE_VIEWED = 'WORKER_ANALYTICS_BOOKING_PAGE_VIEWED';
export const WORKER_ANALYTICS_BOOKING_PAGE_VIEWED_SUCCESS = 'ANALYTICS_BOOKING_PAGE_VIEWED_SUCCESS';
export const WORKER_ANALYTICS_BOOKING_PAGE_VIEWED_ERROR = 'ANALYTICS_BOOKING_PAGE_VIEWED_ERROR';

export const WORKER_REFRESH_PROFILE = 'WORKER_REFRESH_PROFILE';
export const WORKER_REFRESH_PROFILE_SUCCESS = 'REFRESH_PROFILE_SUCCESS';
export const WORKER_REFRESH_PROFILE_ERROR = 'REFRESH_PROFILE_ERROR';

export const WORKER_SAVE_WH = 'WORKER_SAVE_WH';
export const WORKER_SAVE_WH_SUCCESS = 'SAVE_WH_SUCCESS';
export const WORKER_SAVE_WH_ERROR = 'SAVE_WH_ERROR';

export const WORKER_SAVE_ACCOUNT_SETTINGS = 'WORKER_SAVE_ACCOUNT_SETTINGS';
export const WORKER_SAVE_ACCOUNT_SETTINGS_SUCCESS = 'SAVE_ACCOUNT_SETTINGS_SUCCESS';
export const WORKER_SAVE_ACCOUNT_SETTINGS_ERROR = 'SAVE_ACCOUNT_SETTINGS_ERROR';

export const CHECK_EXTENSION = 'CHECK_EXTENSION'; // when user click on first time dialog

export const WORKER_GEN_LINK = 'WORKER_GEN_LINK';
export const WORKER_GEN_LINK_SUCCESS = 'GEN_LINK_SUCCESS';
export const WORKER_GEN_LINK_ERROR = 'GEN_LINK_ERROR';

// Billing actions
export const WORKER_BILLING_SETUP = 'WORKER_BILLING_SETUP';
export const WORKER_BILLING_SETUP_SUCCESS = 'BILLING_SETUP_SUCCESS';
export const WORKER_BILLING_SETUP_ERROR = 'BILLING_SETUP_ERROR';

export const WORKER_BILLING_CREATE_SESSION = 'WORKER_BILLING_CREATE_SESSION';
export const WORKER_BILLING_CREATE_SESSION_SUCCESS = 'BILLING_CREATE_SESSION_SUCCESS';
export const WORKER_BILLING_CREATE_SESSION_ERROR = 'BILLING_CREATE_SESSION_ERROR';

export const WORKER_BILLING_CHECKOUT = 'WORKER_BILLING_CHECKOUT';
export const WORKER_BILLING_CHECKOUT_SUCCESS = 'BILLING_CHECKOUT_SUCCESS';
export const WORKER_BILLING_CHECKOUT_ERROR = 'BILLING_CHECKOUT_ERROR';

export const WORKER_BILLING_CUSTOMER = 'WORKER_BILLING_CUSTOMER';
export const WORKER_BILLING_CUSTOMER_SUCCESS = 'BILLING_CUSTOMER_SUCCESS';
export const WORKER_BILLING_CUSTOMER_ERROR = 'BILLING_CUSTOMER_ERROR';


export const WORKER_GET_TOOLS = 'WORKER_GET_TOOLS';
export const WORKER_GET_TOOLS_SUCCESS = 'GET_TOOLS_SUCCESS';
export const WORKER_GET_TOOLS_ERROR = 'GET_TOOLS_ERROR';
export const WORKER_REMOVE_TOOLS = 'WORKER_REMOVE_TOOLS';
export const WORKER_REMOVE_TOOLS_SUCCESS = 'REMOVE_TOOLS_SUCCESS';
export const WORKER_REMOVE_TOOLS_ERROR = 'REMOVE_TOOLS_ERROR';
export const WORKER_GET_TOOL_OAUTH = 'WORKER_GET_TOOL_OAUTH';
export const WORKER_GET_TOOL_OAUTH_SUCCESS = 'GET_TOOL_OAUTH_SUCCESS';
export const WORKER_GET_TOOL_OAUTH_ERROR = 'GET_TOOL_OAUTH_ERROR';

export const ON_NEW_CONTACTS = 'ON_NEW_CONTACTS';

export const WORKER_SET_TZ = "WORKER_SET_TZ";

export const WORKER_GET_PREVIEW = 'WORKER_GET_PREVIEW';
export const WORKER_GET_PREVIEW_SUCCESS = 'GET_PREVIEW_SUCCESS';
export const WORKER_GET_PREVIEW_ERROR = 'GET_PREVIEW_ERROR';

export const CLEAN_LS_VALUES = "CLEAN_LS_VALUES"; // token middleware only
export const WORKER_PATCH_TOOL_SETTINGS = "WORKER_PATCH_TOOL_SETTINGS";
export const WORKER_PATCH_TOOL_SETTINGS_SUCCESS = "PATCH_TOOL_SETTINGS_SUCCESS";
export const WORKER_PATCH_TOOL_SETTINGS_ERROR = "PATCH_TOOL_SETTINGS_ERROR";
export const SHOW_ONLINE_HELP_POPUP = 'SHOW_ONLINE_HELP_POPUP';

export const WORKER_GET_STATS = "WORKER_GET_STATS";
export const WORKER_GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const WORKER_GET_STATS_ERROR = "GET_STATS_ERROR";

export const WORKER_GET_UNKNWON_PROFILE = "GET_UNKNOWN_PROFILE"
export const WORKER_GET_NETWORK = "WORKER_GET_NETWORK";
export const WORKER_GET_NETWORK_SUCCESS = "GET_NETWORK_SUCCESS";
export const WORKER_GET_NETWORK_ERROR = "GET_NETWORK_ERROR";
export const WORKER_REGISTER_TO_PROPOSAL = "WORKER_REGISTER_TO_PROPOSAL";
export const WORKER_REGISTER_TO_PROPOSAL_SUCCESS = "REGISTER_TO_PROPOSAL_SUCCESS";
export const WORKER_REGISTER_TO_PROPOSAL_ERROR = "REGISTER_TO_PROPOSAL_ERROR";

export const WORKER_EDIT_PROPOSAL = "WORKER_EDIT_PROPOSAL";
export const ANALYTICS_PROPOSAL_UPDATED = "ANALYTICS_PROPOSAL_UPDATED";

export const WORKER_GET_PREMIUM_SEATS = "WORKER_GET_PREMIUM_SEATS";
export const WORKER_GET_PREMIUM_SEATS_SUCCESS = "GET_PREMIUM_SEATS_SUCCESS";
export const WORKER_GET_PREMIUM_SEATS_ERROR = "GET_PREMIUM_SEATS_ERROR";
export const WORKER_ADD_PREMIUM_SEATS = "WORKER_ADD_PREMIUM_SEATS";
export const WORKER_ADD_PREMIUM_SEATS_SUCCESS = "ADD_PREMIUM_SEATS_SUCCESS";
export const WORKER_ADD_PREMIUM_SEATS_ERROR = "ADD_PREMIUM_SEATS_ERROR";
export const WORKER_DEL_PREMIUM_SEATS = "WORKER_DEL_PREMIUM_SEATS";
export const WORKER_DEL_PREMIUM_SEATS_SUCCESS = "DEL_PREMIUM_SEATS_SUCCESS";
export const WORKER_DEL_PREMIUM_SEATS_ERROR = "DEL_PREMIUM_SEATS_ERROR";
export const WORKER_REMIND_ATTENDEES = 'WORKER_REMIND_ATTENDEES';
export const WORKER_REMIND_ATTENDEES_SUCCESS = "REMIND_ATTENDEES_SUCCESS";
export const WORKER_REMIND_ATTENDEES_ERROR = "REMIND_ATTENDEES_ERROR";

export const WORKER_DELETE_ATTENDEES = 'WORKER_DELETE_ATTENDEES';
export const WORKER_DELETE_ATTENDEES_SUCCESS = "DELETE_ATTENDEES_SUCCESS";
export const WORKER_DELETE_ATTENDEES_ERROR = "DELETE_ATTENDEES_ERROR";

export const WORKER_OPTIONALIZE_ATTENDEES = 'WORKER_OPTIONALIZE_ATTENDEES';
export const WORKER_OPTIONALIZE_ATTENDEES_SUCCESS = "OPTIONALIZE_ATTENDEES_SUCCESS";
export const WORKER_OPTIONALIZE_ATTENDEES_ERROR = "OPTIONALIZE_ATTENDEES_ERROR";

export const WORKER_ANALYTICS_ANON_ID = "WORKER_ANALYTICS_ANON_ID";
export const ANALYTICS_AB_VARIANTS = "ANALYTICS_AB_VARIANTS";
export const WORKER_SET_COMPANY_LOGO = "WORKER_SET_COMPANY_LOGO";
export const WORKER_SET_COMPANY_LOGO_SUCCESS = "SET_COMPANY_LOGO_SUCCESS";
export const WORKER_SET_COMPANY_LOGO_ERROR = "SET_COMPANY_LOGO_ERRRO";

export const WORKER_CREATE_LINK = "WORKER_CREATE_LINK"
export const WORKER_CREATE_LINK_SUCCESS = "CREATE_LINK_SUCCESS"
export const WORKER_CREATE_LINK_ERROR = "CREATE_LINK_ERROR";

export const WORKER_UPDATE_LINK = "WORKER_UPDATE_LINK"
export const WORKER_UPDATE_LINK_SUCCESS = "UPDATE_LINK_SUCCESS"
export const WORKER_UPDATE_LINK_ERROR = "UPDATE_LINK_ERROR"

export const WORKER_DELETE_LINK = "WORKER_DELETE_LINK"
export const WORKER_DELETE_LINK_SUCCESS = "DELETE_LINK_SUCCESS"
export const WORKER_DELETE_LINK_ERROR = "DELETE_LINK_ERROR"

export const WORKER_GET_MY_LINKS = "WORKER_GET_MY_LINKS"
export const WORKER_GET_MY_LINKS_SUCCESS = "GET_MY_LINKS_SUCCESS"
export const WORKER_GET_MY_LINKS_ERROR = "GET_MY_LINKS_ERROR"

export const WORKER_GET_LINK = "WORKER_GET_LINK"
export const WORKER_GET_LINK_SUCCESS = "GET_LINK_SUCCESS"
export const WORKER_GET_LINK_ERROR = "GET_LINK_ERROR"

export const WORKER_VALIDATE_SLUG = "WORKER_VALIDATE_SLUG"
export const WORKER_VALIDATE_SLUG_SUCCESS = "VALIDATE_SLUG_SUCCESS"
export const WORKER_VALIDATE_SLUG_ERROR = "VALIDATE_SLUG_ERROR"

export const WORKER_ACTIVATE_LINK = "WORKER_ACTIVATE_LINK"
export const WORKER_ACTIVATE_LINK_SUCCESS = "ACTIVATE_LINK_SUCCESS"
export const WORKER_ACTIVATE_LINK_ERROR = "ACTIVATE_LINK_ERROR"

export const WORKER_BOOK_LINK = "WORKER_BOOK_LINK"
export const WORKER_BOOK_LINK_SUCCESS = "BOOK_LINK_SUCCESS"
export const WORKER_BOOK_LINK_ERROR = "BOOK_LINK_ERROR"

export const WORKER_GET_BOOKABLE_SLOTS = "WORKER_GET_BOOKABLE_SLOTS"
export const WORKER_GET_BOOKABLE_SLOTS_SUCCESS = "GET_BOOKABLE_SLOTS_SUCCESS"
export const WORKER_GET_BOOKABLE_SLOTS_ERROR = "GET_BOOKABLE_SLOTS_ERROR"

export const FETCH_ERROR_IN_WORKER = "FETCH_ERROR_IN_WORKER";

export const WORKER_USER_VALIDATE_SLUG = "WORKER_USER_VALIDATE_SLUG"
export const WORKER_USER_VALIDATE_SLUG_SUCCESS = "USER_VALIDATE_SLUG_SUCCESS"
export const WORKER_USER_VALIDATE_SLUG_ERROR = "USER_VALIDATE_SLUG_ERROR"
