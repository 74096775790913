import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUser
} from '../../reducers/user';
import {
  KeyboardArrowDown,
  PermContactCalendar,
  Apple
} from '@material-ui/icons';
import {
  GmailIcon,
  OutlookMailIcon,
  ZoomIcon,
  MeetIcon,
  StatusSuccessIcon,
  TeamsIcon
} from '../../assets/icons';
import DoneButton from '../DoneButton';
import { Popover } from '@material-ui/core';
import NOOP from '../../utils/noop';
import { Link } from 'react-router-dom';
import ROUTES from '../../routes/routes.name';
import './connect.menu.scss';
/* istanbul ignore next popup in jest */
export const ConnectMenu = () => {

  const { t } = useTranslation();
  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const toggleMenu = React.useCallback((event) => {
    setAnchorMenuEl(event.currentTarget);
  }, []);
  const handleClose = React.useCallback((event) => {
    setAnchorMenuEl(null);
  }, []);


  return (<>
    {/* <DoneButton onClick={toggleMenu} label={t('common.more')}
      endIcon={<KeyboardArrowDown />}
      className="no-margin blue-outlined create-meeting item force-in-header connect-menu-button" /> */}
    <div className="nav-link nav-link-more hide-on-mobile" onClick={toggleMenu}>{t('common.more')}<KeyboardArrowDown /></div>
    <Popover
      className="connect-menu"
      open={!!anchorMenuEl}
      anchorEl={anchorMenuEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} >
      <ConnectItems handleClose={handleClose} />
    </Popover>
  </>)
}
export const ConnectItems = ({ handleClose }) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const profile = useSelector((state) => state.profile);
  const accounts = useSelector((state) => state.accounts);
  const dispatch = useDispatch();

  const userConnectItemGroups = React.useMemo(() => {
    // check states for zoom and contacts

    // check for account if contacts is permitted
    let account = accounts.find((acc) => acc.email === user.email);
    let isContactsPermit = false;
    if (account) {
      isContactsPermit = !!(account.permissions || []).find((p) => p === 'contact');
    }
    // generate meta for menu
    const groups = [
      {
        title: t('settings.sections.tools.items.mobile.title'),
        className: "full-width",
        items: [
          {
            icon: <Apple />,
            className: "full-width",
            title: t('settings.sections.tools.items.mobile.apple.title'),
            description: t('settings.sections.tools.items.mobile.apple.description'),
            link: t('settings.sections.tools.items.mobile.apple.link'),
          }
        ]
      },
      {
        title: t('settings.sections.tools.items.video.title'),
        className: "full-width",
        items: [
          {
            icon: <ZoomIcon />,
            title: t('settings.sections.tools.items.video.zoom.title'),
            description: t('settings.sections.tools.items.video.zoom.description'),
            state: false,
            action: ROUTES.APP_SETTINGS_TOOLS,
          },
          {
            icon: <MeetIcon />,
            title: t('settings.sections.tools.items.video.gmeet.title'),
            description: t('settings.sections.tools.items.video.gmeet.description'),
            state: false,
            action: ROUTES.CREATE_A_MEETING,
          },
          {
            icon: <TeamsIcon />,
            title: t('settings.sections.tools.items.video.teams.title'),
            description: t('settings.sections.tools.items.video.teams.description'),
            state: false,
            action: ROUTES.CREATE_A_MEETING,
          }
        ]
      },
      {
        title: t('settings.sections.tools.items.permissions.title'),
        className: "full-width",
        items: [
          {
            icon: <PermContactCalendar />,
            title: t('settings.sections.tools.items.permissions.contacts.title'),
            description: t('settings.sections.tools.items.permissions.contacts.description'),
            state: isContactsPermit,
            action: ROUTES.APP_SETTINGS_ACCOUNTS,
            className: "full-width",
          }
        ]
      },
      {
        title: t('settings.sections.tools.items.extensions.title'),
        className: "full-width",
        items: [
          {
            icon: <GmailIcon />,
            title: t('settings.sections.tools.items.extensions.gmail.title'),
            description: t('settings.sections.tools.items.extensions.gmail.description'),
            link: t('menu.chromeExtensionLinkURL'),
          },
          {
            icon: <OutlookMailIcon />,
            title: t('settings.sections.tools.items.extensions.outlook.title'),
            description: t('settings.sections.tools.items.extensions.outlook.description'),
            link: t('settings.sections.tools.items.extensions.outlook.link'),
          }
        ]
      }
    ];
    return groups;
  }, [user, profile, accounts]);

  return userConnectItemGroups.map((item) => <ConnectItemGroup handleClose={handleClose} {...item} />);

}
const EMPTY = [];
export const ConnectItemGroup = ({
  title = '',
  items = EMPTY,
  className = '',
  handleClose
}) => {
  return <div key={title} className={'connect-item-group ' + className}>
    <div className="group-title">{title}</div>
    <div className="group-items">
      {items.map((item) => item.link ? <LinkConnectItem {...item} /> : <ConnectItem handleClose={handleClose}  {...item} />)}
    </div>
  </div>
}
export const LinkConnectItem = ({
  icon,
  title = '',
  description = '',
  link,
  className = ''
}) => {
  return <a href={link} target='_blank' rel='noopener noreferrer' className={'connect-item ' + className}>
    <div className="title">{icon}<span>{title}</span></div>
    <div className="desc">{description}</div>
  </a>
}
export const ConnectItem = ({
  icon,
  title = '',
  description = '',
  state = false,
  action,
  className = '',
  handleClose = NOOP
}) => {
  return <Link to={action} onClick={handleClose} className={'connect-item ' + className + (state ? '' : ' active')}>
    <div className="title">{state ? <StatusSuccessIcon /> : icon}<span>{title}</span></div>
    <div className="desc">{description}</div>
  </Link>
}