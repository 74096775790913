import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./already.answer.scss";
import { useDialog } from "../../../../dialogs/dialog.provider";
import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { MAX_INVITEES_LEGIT_TO_DISPLAY } from "../../../../../conf/general";
const EMPTY = {}
export default function AlreadyAnswer({ invitees, inviteesDetails = EMPTY, isPoll = false }) {
  const { t } = useTranslation();
  const { enqueueDialog, closeDialog } = useDialog();

  let mand = Object.values(inviteesDetails || {}).filter((inv) => {
    return invitees.includes(inv.email) && !inv.optional;
  }).map((inv) => inv.isUnknown ? inv.label : inv.email);
  let opt = Object.values(inviteesDetails || {}).filter((inv) => {
    return invitees.includes(inv.email) && inv.optional;
  }).map((inv) => inv.isUnknown ? inv.label : inv.email/* + ' ' + t('proposal.details.optional')*/);

  const showAllInvitees = React.useCallback(() => {
    const onClose = () => {
      closeDialog();
    }
    enqueueDialog({
      content: <>
        <DialogTitle className='lm-guests-popup-title'>
          {t('proposal.details.guestsPopupTitle')}
          <IconButton aria-label="close" onClick={onClose} className="close-button">
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"lm-guests-popup fancy-scroll"}>
          <div className='section'>
            <div>
              {mand.map(
                (inv) => <div key={inv}>{inv}</div>
              )}
            </div>
          </div>
          {opt.length > 0 &&
            <div className='section'>
              <div>Optional:</div>
              <div>{opt.map(
                (inv) => <div key={inv}>{inv}</div>
              )}</div>
            </div>}
        </DialogContent>
      </>,
      doClose: onClose,
    })
  }, [enqueueDialog, closeDialog, mand, opt, t])

  const nbGuests = React.useMemo(() => {
    return (invitees || []).length;
  }, [invitees]);

  const tooManyInvitees = React.useMemo(() => {
    return nbGuests > MAX_INVITEES_LEGIT_TO_DISPLAY;
  }, [nbGuests])

  if (!invitees || invitees.length === 0) return null;
  const shrink = invitees.length > 5;
  return (
    <div className={"timeline-item already-answer " + (shrink ? "shrink-invitees" : "")}>
      <div className="title">
        {t(isPoll ? "proposal.timeline.answered.pollTitle" : "proposal.timeline.answered.title", { count: invitees.length })}
      </div>
      {tooManyInvitees ?
        <span><a href='#' onClick={showAllInvitees}>{t('proposal.timeline.unknown.viewFullList', { count: nbGuests })}</a></span>
        :
        invitees.map(invitee => (
          <div key={invitee} className="invitee answered">
            <span>{invitee}</span>{(inviteesDetails[invitee] || {}).optional && <span className="optional">&nbsp;{t('proposal.timeline.eventStatus.optional')}</span>}
          </div>
        ))}
    </div>
  );
}
export function AnswerNewSlots({ invitees, inviteesDetails }) {
  const { t } = useTranslation();
  let mand = Object.values(inviteesDetails || {}).filter((inv) => {
    return invitees.includes(inv.email) && !inv.optional;
  }).map((inv) => inv.isUnknown ? inv.label : inv.email);
  let opt = Object.values(inviteesDetails || {}).filter((inv) => {
    return invitees.includes(inv.email) && inv.optional;
  }).map((inv) => inv.isUnknown ? inv.label : inv.email/* + ' ' + t('proposal.details.optional')*/);

  const nbGuests = React.useMemo(() => {
    return (invitees || []).length;
  }, [invitees]);

  if (!invitees || invitees.length === 0) return null;
  return (
    <div className={"timeline-item already-answer propose-new-slots"}>
      <div className="title">
        {t("proposal.timeline.proposeNewSlots.title", { count: invitees.length })}
      </div>
      {invitees.map(invitee => (
        <div key={invitee} className="invitee answered propose-new-slots">
          <span>{invitee}</span>{(inviteesDetails[invitee] || {}).optional && <span className="optional">&nbsp;{t('proposal.timeline.eventStatus.optional')}</span>}
        </div>
      ))}
    </div>
  );
}
