import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import * as ACTIONS from "../../../../actions";
import routes from '../../../../routes/routes.name';
import { useSnackbar } from "notistack";
import DoneButton from "../../../DoneButton";
import { STATUS } from "../../../../api/validators/proposal";
// import Tooltip from '@material-ui/core/Tooltip';
import { Loader } from "../../../Loader";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useDialog } from "../../../dialogs/dialog.provider";
import { Link, Clear, Event, Edit, Refresh } from "@material-ui/icons";
import copyLinkToClipboard from "copy-to-clipboard";
import { useHistory } from "react-router-dom";
import "./proposal.actions.scss";
import { LMTooltip } from "../../../tooltip/Tooltip";
import ROUTES from '../../../../routes/routes.name';
import {
  PREFILL_EDIT
} from '../../../../reducers/proposals';
import { getLink } from "../../../../utils/get.proposal.link";
import moment from "moment";
const EMPTY = {};
function gotLeftSlots(proposal) {
  let now = moment();
  let days = proposal.slots;
  if (!days || days.length === 0) return false;
  let slots = days[days.length - 1].slots;
  if (!slots) return false;

  return now < moment(slots[slots.length - 1].start);
}
export function ProposalActions({
  proposal = EMPTY,
  loading = false,
  setShowSlots,
  doSelectNextProposal
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { enqueueDialog, closeDialog } = useDialog();
  const [error, setError] = React.useState(false);
  const dispatch = useDispatch();
  const doGenLink = React.useCallback(() => {
    /* istanbul ignore if no work */
    if (!proposal.id) return;
    if (loading) return;
    // just generate a dummy link?
    let link = getLink(proposal)
    // dispatch analytics
    dispatch({
      type: ACTIONS.ANALYTICS_GET_LINK,
      payload: {
        proposal: proposal
      }
    });
    /* istanbul ignore else no work */
    if (copyLinkToClipboard(link)) {
      enqueueSnackbar(t("event.linkSuccess"), {
        variant: "success",
        className: "snack-success",
        action: key => {
          return (
            <>
              <Button
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                {t("common.ok")}
              </Button>
            </>
          );
        }
      });
    } else {
      enqueueSnackbar(t("event.linkError"), {
        variant: "error",
        className: "snack-error"
      });
    }
  }, [proposal, loading, enqueueSnackbar, t, closeSnackbar]);
  const onCancel = React.useCallback(() => {
    // cancel event
    // ask for confirmation first
    if (loading) return;
    const unblock = history.block();
    const doClose = () => {
      unblock();
      closeDialog();
    };
    enqueueDialog({
      content: <CancelDialog proposal={proposal} onClose={doClose} doSelectNextProposal={doSelectNextProposal} />,
      doClose: doClose,
      mustConfirm: true
    });
  }, [loading, history, enqueueDialog, proposal, closeDialog, doSelectNextProposal]);
  const onReschedule = React.useCallback(() => {
    // cancel event
    // ask for confirmation first
    if (loading) return;
    // save form in ls
    // clear proposals
    try {
      localStorage.setItem('PREFILL_FROM_ID', proposal.id);
    } catch (err) { }
    // go to form
    history.push(routes.CREATE_A_MEETING);
  }, [loading, history, proposal]);
  const onShowShlots = React.useCallback(() => {
    if (loading) return;
    setShowSlots(true);
  }, [setShowSlots, loading]);
  const onEdit = React.useCallback(() => {
    // open form and prefill
    // save data in LS
    try {
      localStorage.setItem("PREFILL_FROM_ID", proposal.id);
      localStorage.setItem("PREFILL_TYPE", PREFILL_EDIT)
    } catch (err) { }
    // navigate
    history.push(ROUTES.CREATE_A_MEETING);
  }, [proposal, history])
  /* istanbul ignore if no work */
  if (!proposal) return null;
  const isPending = proposal.status === STATUS.PENDING;
  const isPoll = proposal.type === 'poll';
  const isCancelled = proposal.status === STATUS.CANCELLED || proposal.status === STATUS.EVT_CANCELLED;
  const isReschedulable = proposal.status === STATUS.CANCELLED || proposal.status === STATUS.EVT_CANCELLED || proposal.status === STATUS.NO_AVIABILITY;
  const isProposalComplete = proposal.status === STATUS.CONFIRMED;
  // check if can force a meeting, ie, has remaining slots
  const hasRemainingSlots =
    isPending ||
    (proposal.status === STATUS.NO_AVIABILITY && gotLeftSlots(proposal));

  return (
    <div className="proposal-actions">
      {hasRemainingSlots &&
        <LMTooltip content={<p>{t("proposal.details.actions.link_help")}</p>}>
          <div>
            <DoneButton
              name="btn-link"
              icon={<Link />}
              className="grey"
              disabled={loading}
              onClick={doGenLink}
              label={t("proposal.details.actions.link")}
            />
          </div>
        </LMTooltip>}
      {hasRemainingSlots && (
        <LMTooltip
          key="force"
          content={<p>{t("proposal.details.actions.force_help")}</p>}
        >
          <div>
            <DoneButton
              name="btn-force"
              icon={<Event />}
              className="grey"
              disabled={loading}
              onClick={() => onShowShlots()}
              label={t(isPoll ? "proposal.details.actions.forcePoll" : "proposal.details.actions.force")}
            />
          </div>
        </LMTooltip>
      )}

      {!isCancelled && <LMTooltip
        key="cancel"
        content={<p>{t(isProposalComplete ? "proposal.details.actions.cancel_help_confirmed" : "proposal.details.actions.cancel_help")}</p>}
      >
        <div>
          <DoneButton
            name="btn-cancel"
            icon={<Clear />}
            className="grey"
            disabled={loading}
            onClick={onCancel}
            label={t("proposal.details.actions.cancel")}
          />
        </div>
      </LMTooltip>}
      {isReschedulable &&
        <LMTooltip
          key="reschedule"
          content={<p>{t("proposal.details.actions.reschedule_help")}</p>}
        >
          <div>
            <DoneButton
              name="btn-reschedule"
              icon={<Refresh />}
              className="grey"
              disabled={loading}
              onClick={onReschedule}
              label={t("proposal.details.actions.reschedule")}
            />
          </div>
        </LMTooltip>}
      {isPending && (
        <LMTooltip
          key="edit"
          content={<p>{t("proposal.details.actions.edit_help")}</p>}
        >
          <div>
            <DoneButton
              name="btn-edit"
              icon={<Edit />}
              className="grey"
              disabled={loading}
              onClick={onEdit}
              label={t("proposal.details.actions.edit")}
            />
          </div>
        </LMTooltip>
      )}
    </div>
  );
}
export function CancelDialog({ proposal, onClose, doSelectNextProposal }) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cancelling, setCancelling] = React.useState(false);
  const dispatch = useDispatch();
  const hasUnknwon = React.useMemo(() => {
    if (!proposal) return false;
    let details = proposal.inviteesDetails || {};
    return Object.values(details).find((i) => i.isUnknown)
  }, [proposal])
  const cancelMeeting = React.useCallback(() => {
    setCancelling(true);
    dispatch({
      type: ACTIONS.WORKER_CANCEL_EVENT,
      payload: proposal,
      resolvers: {
        resolveOn: ACTIONS.WORKER_CANCEL_EVENT_SUCCESS,
        rejectOn: ACTIONS.WORKER_CANCEL_EVENT_ERROR
      }
    })
      .catch(err => {
        let payload = err ? err.payload : {};
        let status = payload ? payload.status : 500;
        let label = "errors.couldNotCancelMeeting";
        if (status === 403) {
          label = "errors.couldNotCancelMeetingNotAllowed";
        } else if (status === 404) {
          label = "errors.couldNotCancelMeetingNotFound";
        } else if (status === 409) {
          label = "errors.couldNotCancelMeetingConflict";
        }
        enqueueSnackbar(t(label), {
          variant: "error",
          preventDuplicate: true,
          className: "snack-error"
        });
        return { status: 500 }
      })
      .then((resp) => {
        // meeting is cancelled, reload and quit
        setCancelling(false);
        onClose();
        if (resp && resp.status === 299) {
          // cancel request was partially executed
          enqueueSnackbar(t("errors.cancelPartiallyExecuted"), {
            variant: "error",
            preventDuplicate: true,
            className: "snack-warning",
          });
        } else if (!resp || resp.status != 500) {
          enqueueSnackbar(<span><Trans i18nKey="proposal.details.actions.proposalDeletedSuccess"
            components={{ bold: <strong /> }}
            values={{ title: proposal.title }}>
            The status of <bold>{proposal.title}</bold> has been updated. You can view it in <bold>ARCHIVED</bold> tab
          </Trans></span>, {
            variant: "info",
            preventDuplicate: true,
            className: "snack-success",
          });
          doSelectNextProposal()

        }
      });
  }, [dispatch, proposal, enqueueSnackbar, t, onClose, doSelectNextProposal]);

  return (
    <div className="cancel-dialog" data-testid="cancel-event-dialog">
      <DialogTitle id="alert-dialog-title">
        {t("event.cancelDlg.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(hasUnknwon ? "event.cancelDlg.messageWithUnknownWarning" : "event.cancelDlg.message")}
        </DialogContentText >
      </DialogContent >
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={cancelling}
          className='confirm-cancel-destructive-action'
          data-testid="cancel-event-dialog-cancel"
        >
          {t("event.cancelDlg.dismiss")}
        </Button>
        <Button
          onClick={cancelMeeting}
          className='confirm-destructive-action'
          autoFocus
          disabled={cancelling}
          data-testid="cancel-event-dialog-ok"
        >
          {t("event.cancelDlg.ok")}
        </Button>
      </DialogActions>

      {cancelling && <Loader />}
    </div >
  );
}
