import React from 'react'
import { connect } from 'react-redux';
import './donechip.scss'
import Avatar from './avatar.with.states';
import { IconCheck, IconDelete } from '../assets/icons';
import * as CONSTS from './consts';


/**
 * 
 * @param {String} name name of the user
 * @param {function} crossClicked callback when chip remove button clicked
 */
export const DoneChip = ({
  contact, // contact to display 
  crossClicked,
  size = CONSTS.AVATAR_NORMAL,

  mark = '',
  noMark = false,
     }) => {

    if(!contact) return null;
    let { name, email, avatar_url } = contact;
    
    let displayableName = name || email;
    if (!displayableName) return null;

    return (
        <div className="DoneChip" tabIndex={-1}>
            <div className="container">
                <Avatar name={name}
                    size={size}
                    imageSrc={avatar_url}
                    mark = {mark}/>
                {!noMark && mark !== '' && mark !== 'unknown' &&
                    <div className="answer">
                        {mark === CONSTS.MARK_YES &&
                            <IconCheck className="yes" />}
                        {mark === CONSTS.MARK_NO &&
                            <IconDelete className="no" />}
                        {mark === CONSTS.MARK_MAYBE &&
                            <IconCheck className="maybe" />}
                    </div>}
                <div data-testid="chip-name" className="name">{displayableName}</div>
                {crossClicked &&
                    <IconDelete
                        className="pills-cross"
                        onClick={crossClicked} />
                }
            </div>
        </div>
    );
}

const mstp = (state, props) => {
    let c = (props.email === state.user.email) ? state.user : state.contacts[props.email] || {email: props.email, name: props.email};
    return {
        contact: c, // Todo: how do we store contacts?
    }
}
export default connect(mstp)(DoneChip);