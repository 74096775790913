/* istanbul ignore file mocked */
import React from 'react';
import './Tooltip.scss';
import { Tooltip as MaterialTooltip, withStyles } from '@material-ui/core';
import NOOP from '../../utils/noop';

// const PopperPropsOpts = 
export function LMTooltip({
  children,
  content,
  colorTheme = 'dark',
  interactive = false,
  arrow = false,
  enterDelay,
  childrenClassName = "",
  open,
  placement = "bottom-start",
  className = "",
  onClick = NOOP,
  followCursor = false,
  ...props
}) {
  const child = React.useRef(null)
  const mouseMove = React.useCallback((e) => {
    if (followCursor && child.current && child.current.popper) {
      let current = e;

      // position tooltip above?
      let { clientX, clientY } = current;
      //let { height, width } = current.target.getBoundingClientRect();
      // let width = 360;
      //console.log("mouseMove", current.target.getBoundingClientRect(), document.body.clientWidth)
      //clientX = (clientX + width) > (document.body.clientWidth) ? document.body.clientWidth - 10 - width : clientX;
      child.current.popper.style.transform = `translate3d(${clientX}px,${clientY}px,0)`;

    }
  }, [followCursor]);
  if (!content) return <span className={"LMTooltip-children " + childrenClassName}>
    {children}
  </span>;
  return (
    <Tool
      // open={true}
      colorTheme={colorTheme}
      interactive={interactive}
      placement={placement}
      arrow={arrow}
      className={className}
      id={className}
      PopperProps={{
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: "viewport" // where "window" is the boundary
            }
          }
        },
        popperRef: child
      }}
      open={open} // Uncomment to debug style of tooltip
      {...props}
      enterDelay={enterDelay}
      onClick={onClick}
      title={
        <div className={"LMTooltip " + className}>
          {content}
        </div>
      }>
      <span onMouseMove={mouseMove} className={"LMTooltip-children " + childrenClassName}>
        {children}
      </span>
    </Tool>
  )
}

// Not ideal. I could not find a nice CSS way for this...
const StyledTooltipDark = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#1b1b1b',
    color: 'white',
    padding: 0,
    maxWidth: 360,
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)"
  },
}))(MaterialTooltip);

const StyledTooltipLight = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#1b1b1b',
    padding: 0,
    maxWidth: 800,
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)"
  },
}))(MaterialTooltip);

const Tool = ({ colorTheme, children, ...props }) => {
  return colorTheme === 'dark' ? <StyledTooltipDark {...props}>{children}</StyledTooltipDark> : <StyledTooltipLight {...props}>{children}</StyledTooltipLight>;
}