import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAccountMails, getAccountsIds } from "../../../../reducers/accounts";
import * as ACTIONS from "../../../../actions";
import { LoadingButton } from '../../../LoadingButton';
import getAttendeeName from '../../../../utils/get.attendee.name';
import moment from 'moment'
/**
 * Confirm dialog that makes a guest optional
 * 
 * @param {string} proposal Proposal id
 * @param {[]} email Guest email to make optional
 * @param {function} onClose Called when dialog is closed
 */
export function OptionalizeAttendeeDialog({ proposalId, organizerEmail, attendee, onClose, reloadProposal,
  completeSlot
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [doing, setDoing] = React.useState(false);
  const dispatch = useDispatch();
  const accountsEmails = useSelector(getAccountMails);
  const accountsIds = useSelector(getAccountsIds);
  const optionalizeAttendee = React.useCallback(() => {
    setDoing(true);
    dispatch({
      type: ACTIONS.WORKER_OPTIONALIZE_ATTENDEES,
      payload: {
        emails: [attendee.email],
        proposalId: proposalId
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_OPTIONALIZE_ATTENDEES_SUCCESS,
        rejectOn: ACTIONS.WORKER_OPTIONALIZE_ATTENDEES_ERROR
      }
    }).then(() => {
      // reload proposal
      return dispatch({
        type: ACTIONS.WORKER_GET_PROPOSAL,
        payload: {
          id: proposalId,
          checkBusyTime: organizerEmail, // proposal.organizer.email || user.email,
          userEmail: accountsEmails,
          userAccounts: accountsIds,
          addProposalIDToBusyRequest: proposalId,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
          // requestId: uuid.v4(),    //  ----> @TODO cancel proposals on replay (after merge heatmap)
        }
      })
    }).then((dt) => {
      // attendee deleted
      if (dt && dt.payload && dt.payload.length > 0) reloadProposal(dt.payload[0]);
      enqueueSnackbar(t('proposal.optionalizeAttendee.success'), {
        variant: 'success', className: "snack-success",
        action: (key) => <Button onClick={() => { closeSnackbar(key) }}>{t('common.ok')}</Button>
      })
      setDoing(false)
      onClose();
    }).catch(error => {
      // failed to optionalize attendee
      setDoing(false)
      console.error("Error while optionalizing attendee: ", error);
      enqueueSnackbar(t('proposal.optionalizeAttendee.failure'), {
        variant: 'error', className: "snack-error",
        action: (key) => <Button onClick={() => { closeSnackbar(key) }}>{t('common.ok')}</Button>
      })
    });
  }, [dispatch, enqueueSnackbar, t, closeSnackbar, proposalId, attendee, onClose, reloadProposal]);

  return (
    <div className="optionalize-attendee-dialog" data-testid="optionalize-attendee-dialog">
      <DialogTitle id="alert-dialog-title">
        {t("event.optionalizeAttendeeDlg.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("event.optionalizeAttendeeDlg.message", { guestEmail: getAttendeeName(attendee) })}

          {
            completeSlot && <p className="complete-info">
              <Trans i18nKey="event.optionalizeAttendeeDlg.messageComplete"
                components={{ b: <b /> }} values={{ date: moment(completeSlot.start).format(t('common.dateFormatShorter')) }}>

              </Trans>
            </p>
          }
        </DialogContentText >
      </DialogContent >
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={doing}
          className='confirm-cancel-destructive-action'
          data-testid="optionalize-attendee-dialog-cancel">
          {t("event.optionalizeAttendeeDlg.dismiss")}
        </Button>
        <LoadingButton
          onClick={optionalizeAttendee}
          className='confirm-destructive-action'
          disabled={doing}
          data-testid="optionalize-attendee-dialog-ok"
          isLoading={doing}>
          {t(doing ? "event.optionalizeAttendeeDlg.optionalizing" : "event.optionalizeAttendeeDlg.ok")}
        </LoadingButton>
      </DialogActions>
    </div>
  );
}
