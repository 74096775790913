import React from 'react';
import {
  useDispatch, useSelector
} from 'react-redux';
import {
  useTranslation
} from 'react-i18next';
import * as ACTIONS from '../actions';
import hduration from "humanize-duration";
import moment from "moment";
import "./rewards.scss";
import { LMTooltip } from '../components/tooltip/Tooltip';

export default function Rewards() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rewards, setRewards] = React.useState(undefined);
  const user = useSelector((state) => state.user);
  React.useEffect(() => {
    if (!user || !user.isAuth) return;
    dispatch({
      type: ACTIONS.WORKER_GET_STATS,
      payload: {
        stats: "all"
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_STATS_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_STATS_ERROR,
      }
    }).then((e) => {
      if (e.payload.total_saved_time > 0) setRewards(hduration(e.payload.total_saved_time * 1000, { language: moment.locale() }));
    }).catch((err) => {
      console.error(err)
    })
  }, [user, dispatch]);
  return rewards !== undefined && (
    <LMTooltip content={<p>{t("menu.rewardsExplanation")}</p>} childrenClassName="rewards">
      <>{t("menu.rewards", { count: rewards })}</>
    </LMTooltip>
  );

}