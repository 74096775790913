import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as ACTIONS from '../../../actions';
import ROUTES from '../../../routes/routes.name';
import { useSnackbar } from 'notistack';
import { useDialog } from '../../dialogs/dialog.provider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneButton from '../../DoneButton';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { useSpring, animated, easings } from 'react-spring';
import {
  Group,
  Close,
  GroupOutlined,
} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { FIREBASE_VALUES } from '../../../middlewares/analytics';

import FastGuestSelector from '../create-event/guests/fast.guest.selector';
import "./network.scss";

export default function UserNetwork() {
  const { t } = useTranslation();
  const [network, setnetwork] = React.useState(null)
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: ACTIONS.WORKER_GET_NETWORK,
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_NETWORK_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_NETWORK_ERROR
      }
    }).then((res) => {
      setnetwork(res.payload)
    }).catch((err) => {
      // show promote
      setnetwork({ total: 0 })
    })
  }, []);

  if (!network) return <LinearProgress />;

  return <div className="profile-network">
    <div className="title"><GroupOutlined />{t("profile.network.title")}</div>
    <NetworkContacts network={network} />
  </div>
}

const MAX_NB_CONTACTS_IN_SUMMARY = 3;

export function NetworkContacts({ network }) {
  const { t } = useTranslation();

  const { enqueueDialog, closeDialog } = useDialog();
  const viewAllContactsList = React.useCallback(() => {
    const onClose = () => closeDialog();
    enqueueDialog({
      content: <ContactsListDialog onClose={onClose} />
    });
  }, [closeDialog, enqueueDialog]);
  const contacts = network.last_contacts || network.contacts || [];
  return <div className="network-content">
    {(contacts.length === 0)
      ? <Promote />
      : <>
        <BigNumber total={network.total} onClick={viewAllContactsList} />
        <div className="last-contacts">
          <div className="last-contacts-title">{t('profile.network.lastContactsTitle')}</div>
          <List>
            {contacts.slice(0, MAX_NB_CONTACTS_IN_SUMMARY).map((c) => {
              return <ContactItem key={c.contact_id} contact={c} />
            })}
          </List>
          {network.total > MAX_NB_CONTACTS_IN_SUMMARY &&
            <div
              data-testid="btn-view-all"
              className="button-like-a-link"
              onClick={viewAllContactsList}>{t('profile.network.viewAll')}</div>
          }
        </div>
      </>}
    <GrowNetwork count={network.total} />
  </div>
}
function ContactsListDialog({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contacts, setContacts] = React.useState(null);
  React.useEffect(() => {
    dispatch({
      type: ACTIONS.WORKER_GET_NETWORK,
      payload: {
        stats: 'all'
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_NETWORK_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_NETWORK_ERROR
      }
    }).then((res) => {
      setContacts(res.payload)
    }).catch((err) => {
      // show promote
      setContacts([])
    })
  }, [dispatch]);

  if (contacts === null) return <div className="contacts-list loading"><CircularProgress /></div>
  if (contacts && contacts.length === 0) return null; // @mat maybe doing a custom graph here with "no results"?
  return <>
    <DialogTitle className="title-with-close">{t('profile.network.list.title')}
      <IconButton data-testid='btn-close' onClick={onClose}>
        <Close />
      </IconButton></DialogTitle>
    <DialogContent className='fancy-scroll network-contact-dialog' dividers data-testid="contacts-list">
      {
        contacts === null ?
          <CircularProgress /> :
          <List>
            {contacts.map((c, i) => <ContactItem key={i} contact={c} />)}
          </List>
      }
    </DialogContent>
  </>
}
function ContactItem({ contact, key }) {
  if (!contact || !contact.label || !contact.emails) return null;
  return <ListItem key={key} >
    <ListItemText primary={contact.label} secondary={contact.emails.join(', ')} />
  </ListItem>
}
function Promote() {
  // ul/li in Trans: https://github.com/i18next/react-i18next/issues/1135
  return (
    <div className="network-promote">
      <Trans i18nKey="profile.network.promote">
        Your network is made of people you know who are on Letsmeet:
        <ul>
          <li>your guests who decided to join Letsmeet</li>
          <li>people you invited to join Letsmeet (see below)</li>
        </ul>
      </Trans>
    </div>);
}
function BigNumber({ total = 0, onClick }) {
  const { t } = useTranslation();
  const animatedProps = useSpring({
    val: total, from: { val: 0 }, config: {
      duration: 1500,
    }
  });
  if (total === 0) return null;
  return <div className="network-big-number">
    <animated.div className="big-number" onClick={onClick}>{animatedProps.val.interpolate(val => Math.floor(val))}</animated.div>
    <div className="big-number-desc">{t("profile.network.bigNumberDesc")}</div>
  </div>
}
function GrowNetwork({ count = 0 }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const contacts = useSelector((state) => state.contacts || [])
  const [invitees, setInvitees] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [inviteesDetail, setInviteesDetail] = React.useState([]);
  const onChange = React.useCallback((v) => {
    if (v.target.value) {
      // check it's not a LM user
      if (contacts[v.target.value] && contacts[v.target.value].isDoner) {
        enqueueSnackbar(t('settings.sections.invite.content.alreadyLMUser', { email: v.target.value }), {
          variant: 'success',
          className: "snack-success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          }
        })
      } else {
        setInvitees(v.target.value);
      }

    }

  }, [invitees, contacts]);
  const doConnectContacts = React.useCallback(() => {
    history.push(ROUTES.APP_SETTINGS_ACCOUNTS);
  }, []);
  const sendInvites = React.useCallback(() => {
    if (invitees.length > 0) {
      setLoading(true);
      let m = invitees;
      dispatch({
        type: ACTIONS.WORKER_INVITE,
        payload: {
          emails: m
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_INVITE_SUCCESS,
          rejectOn: ACTIONS.WORKER_INVITE_ERROR,
        }
      })
        .then(() => {
          // analytics for K-Factor
          dispatch({
            type: ACTIONS.ANALYTICS_INVITE_SENT,
            payload: {
              invitedEmails: m,
              source: FIREBASE_VALUES.INVITE_SENT_FROM_INVITE
            }
          });
          // clear UI? or clear Panel?
          setInvitees([]);
          enqueueSnackbar(t('settings.sections.invite.content.success'), {
            variant: 'success',
            className: "snack-success",
            action: (key) => {
              return <>
                <Button onClick={() => { closeSnackbar(key) }}>
                  {t('common.ok')}
                </Button>
              </>;
            }
          })

        })
        .catch((err) => {
          console.error(err)
          // display error popup
          enqueueSnackbar(t('settings.sections.invite.content.error'), {
            variant: 'error',
            className: "snack-error",
            action: (key) => {
              return <>
                <Button onClick={() => { closeSnackbar(key) }}>
                  {t('common.ok')}
                </Button>
              </>;
            }
          })
        }).then(() => {
          setLoading(false);
        });
    }
  }, [invitees])
  return <div className='grow-network'>
    <div className="grow-title">{t(count === 0 ? 'profile.network.growTitleEmpty' : 'profile.network.growTitle')}</div>
    <div className="grow-subtitle">{t('profile.network.growSubtitle')}</div>
    <FastGuestSelector
      invitees={invitees}
      inviteesDetails={contacts}
      setInvitees={onChange}
      doConnectContacts={doConnectContacts}
      simpleContactSelector={true} />
    <DoneButton label={
      loading ?
        <>
          <CircularProgress />
          <div className="sending-invite">
            {t('settings.sections.invite.content.ctaLoading')}
          </div>
        </>
        : t('settings.sections.invite.content.cta')}
      disabled={loading} onClick={sendInvites}
      name="btn-invite" />
  </div>
}