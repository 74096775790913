import * as Yup from 'yup';

const VALID_DAYS = ['7', '1', '2', '3', '4', '5', '6'];
const FRAMES = Yup.array(Yup.object({
  start: Yup.number().min(0).max(24),
  end: Yup.number().min(0).max(24),
})).required()
  .min(1)
  .max(2);
const PROFILE_VALIDATOR = Yup.object().shape({
  enabled: Yup.boolean().required(),
  timezone: Yup.string().required(),
  workingHours: Yup.object({
    frames: Yup.array().of(FRAMES)
  }).test('working-hours', 'invalid WH', (v) => {
    // keys must be between 1 - 7  (Change for isoWeek indexes)
    let keys = Object.keys(v);
    return keys.reduce((acc, key) => {
      return acc && VALID_DAYS.includes(key);
    }, true);
  }),
  lastCalendar: Yup.object().shape({
    provider: Yup.string(),
    user_id: Yup.string(),
    cal_id: Yup.string(),
  }),
});
export default PROFILE_VALIDATOR;