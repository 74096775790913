import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./foldable.input.scss";
export default function FoldableInput({
  name = '',
  title = "",
  open = 0,
  children
}) {
  const [isOpen, setIsOpen] = React.useState(open > 0);
  // when count (of optionals) change, open
  React.useEffect(() => {
    if (open > 0) {
      setIsOpen(open)
    }
  }, [open])
  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])
  return <Accordion expanded={isOpen} onChange={toggle} className='done-input foldable'>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id={name}
    >
      {title}
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
}