import { CircularProgress } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../../api/validators/proposal';
import { ArrowRight, HasNotVoted, CannotVote, NotAvailable, Spinner } from '../../../../assets/icons';
import orderedInvitees, { attendeeHasVoted } from '../../../../utils/get.attendee.score'
import './ProposalAttendees.scss';

export default function ProposalAttendees({ proposal, userEmail, isVoting }) {

  const { t } = useTranslation();

  const attendees = useMemo(() => {
    if (!proposal || !proposal.organizer || !proposal.inviteesDetails) return [];
    let organizer = {
      email: proposal.onBehalfOf ? `${proposal.onBehalfOfName || proposal.onBehalfOf}` : proposal.organizer.email,
      name: proposal.organizer.name,
      __isHost: true
    }
    return [organizer, ...orderedInvitees(proposal.inviteesDetails, userEmail)];
  }, [proposal, userEmail]);

  const userCannotVote = useMemo(() => {
    if (!proposal || !proposal.inviteesDetails) return false;
    if (proposal.status === STATUS.NO_AVIABILITY || proposal.status === STATUS.CANCELLED || proposal.status === STATUS.CONFIRMED) return true;
    let attendees = Object.values(proposal.inviteesDetails);
    for (let i = 0; i < attendees.length; i++) {
      const attendee = attendees[i];
      const someOneNotAvailable = attendee.response === 'yes' && (!attendee.slots || attendee.slots.length === 0) && attendee.email !== userEmail
        && !attendee.optional;
      if (someOneNotAvailable && !proposal.type === "poll") return true;
    }
    return false;
  }, [proposal, userEmail]);

  if (!proposal || !proposal.organizer || !proposal.inviteesDetails) return null;

  return (
    <div className="ProposalAttendees" data-testid="proposal-attendees">
      {attendees.map((attendee) => {

        const hasVoted = attendeeHasVoted(attendee);
        const votedNotAvailable = attendee.response === 'yes' && (!attendee.slots || attendee.slots.length === 0);
        const isSelected = userEmail === attendee.email;
        const isOptional = attendee.optional;
        const statusIcon = hasVoted ?
          votedNotAvailable ?
            <NotAvailable />
            :
            <Done className="done" />
          : userCannotVote ?
            // <HasNotVoted />
            <CannotVote className="cannot-vote" />
            : isSelected ?
              isVoting ?
                // <Spinner className="spin" />
                <CircularProgress className="spin" />
                : <ArrowRight />
              : <HasNotVoted />;
        return (
          <div key={attendee.email} className={"attendee" + (isSelected ? " selected" : "")}>
            {statusIcon}
            <span>{attendee.isUnknown ? attendee.label : attendee.email}</span>
            {attendee.__isHost && <span className="org">({t('createEvent.form.organizer').toLowerCase()})</span>}
            {isOptional && <span className="optional">&nbsp;{t('proposal.details.optional').toLowerCase()}</span>}
          </div>
        );
      })
      }
    </div >
  );
}