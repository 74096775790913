import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import * as ACTIONS from '../actions';
import moment from 'moment';

const defaultState = [];
const reducer = handleActions(
  {
    [ACTIONS.WORKER_GET_USER_EVENTS]: (state, action) => {
      return defaultState;
    },
    [ACTIONS.WORKER_GET_USER_EVENTS_SUCCESS]: (state, action) => {
      return Object.values((action.payload || []).reduce((acc, evt = {}) => {
        let tmp = {
          ...evt,
          // momentize evt dates
          start: moment(evt.start),
          end: moment(evt.end)
        }
        // filter out duplicates among events
        if (!acc[evt.icaluid]) {
          acc[evt.icaluid] = tmp
        } else {
          if (evt.recurring) {
            acc[evt.icaluid + "-" + evt.id] = tmp
          }
        }
        return acc;
      }, {}));
    },
  },
  defaultState
);
export default reducer;

export const getAllDayEvents = createSelector(
  (state) => state.userEvents || [],
  (events) => events.filter((evt) => evt.allday)
)
export const getUserEvent = (id) => (state) => {
  return state.userEvents.find((evt) => evt.id === id)
}