import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { STATUS, PROPOSAL_TYPE } from "../../../../../api/validators/proposal";
import { allMandatoryAnswered } from "../../../event/ProposalStatus";
import DoneButton from "../../../../DoneButton";
import { Event } from '@material-ui/icons';
import NOOP from '../../../../../utils/noop';
import "./status.scss";



const REPONSE_STATUS = {
  yes: "yes",
  no: "no",
  maybe: "maybe"
};
export default function Status({ proposal, isActive = false, onShowSlots = NOOP }) {
  const { t } = useTranslation();
  const { isPoll, isPollOver } = React.useMemo(() => {
    if (!proposal) return { isPoll: false, isPollOver: false };
    const iAmHost = proposal.iAmHost;
    const isPoll = iAmHost && proposal.type === PROPOSAL_TYPE.POLL;
    const isPollOver = isPoll && allMandatoryAnswered(proposal);
    return { isPoll, isPollOver }
  }, [proposal])
  let status = React.useMemo(() => {
    if (!proposal || !proposal.status) return "no status";
    const iAmHost = proposal.iAmHost;

    switch (proposal.status) {
      case STATUS.EVT_CANCELLED:
      case STATUS.CONFIRMED: {
        // meeting is set
        let when = moment(proposal.start).format(t("common.fullDateFormat"));
        // let raw_details = proposal.raw_invitees_details || {};
        return (
          <div data-testid="confimed-event" className="status-confirmed">
            <div>{t("proposal.timeline.status.confirmed")}</div>
            <div className="strong">{when}</div>
          </div>
        );
      }
      case STATUS.CANCELLED: {
        // did someone say no, or date is past?        
        let label = t(
          iAmHost
            ? "proposal.timeline.status.canceledHost"
            : "proposal.timeline.status.canceled"
        );
        return (
          <div data-testid="cancelled-event" className="status-canceled">
            {label}
          </div>
        );
      }
      case STATUS.NO_AVIABILITY: {
        let label = t(
          iAmHost
            ? "proposal.timeline.status.noavailHost"
            : "proposal.timeline.status.noavail"
        );
        return (
          <div data-testid="no-av-event" className="status-canceled">
            {label}
          </div>
        );
      }
      default: {
        let msg = "proposal.timeline.status.pendingHost";
        if (isPoll) { msg = isPollOver ? "proposal.timeline.status.pendingHostPollFinished" : "proposal.timeline.status.pendingHostPoll" }
        return (
          <div className="status-pending">
            {t(
              iAmHost
                ? msg
                : "proposal.timeline.status.pending"
            )}
            {/* {isPollOver && <DoneButton
              name="btn-force"
              icon={<Event />}
              className="grey small"
              onClick={() => onShowSlots(true, false)}
              label={t("proposal.timeline.status.pendingHostPollFinishedForce")}
            />} */}
          </div>
        );
      }
    }
  }, [proposal, onShowSlots, isPoll, isPollOver]);
  isActive = isPollOver || (proposal.status !== STATUS.EVT_CANCELLED && isActive);
  return (
    <div
      className={
        "timeline-item status " + (isActive ? "active " : "") + proposal.status
        + (proposal.iAmHost ? ' iamhost ' : '')
      }
    >
      {status}
    </div>
  );
}
