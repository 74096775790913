import React from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../../reducers/user';
import ROUTES from '../../../routes/routes.name';
import Avatar from '../../avatar';
import DoneButton from '../../DoneButton';
import './menu.scss';
import * as ACTIONS from '../../../actions';
import { MenuItem, Popover, Divider } from '@material-ui/core';
import i18next from 'i18next';
import { LANDING_URL } from '../../../conf/general';
import { useDialog } from '../../dialogs/dialog.provider';
import { ConnectMenu } from '../../headers/connect.menu';
import { KeyboardArrowDown, Menu } from "@material-ui/icons";
import OnlineHelp from '../../online.help';
import { clearCache, loadFromCache } from '../../../utils/load.proposal.from.cache';
import { PREFILL_EDIT } from '../../../reducers/proposals';

const redirectToHome = () => window.location = LANDING_URL;
const dlgOpts = { maxWidth: "lg", fullWidth: true };

export default function DashboardMenu() {

  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(null);
  const { enqueueDialog, closeDialog } = useDialog(dlgOpts);

  const signOut = React.useCallback(() => {
    // clear LS
    dispatch({
      type: ACTIONS.WORKER_LOGOUT,
      silent: true,
    });
    redirectToHome();
  }, [dispatch]);

  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const toggleMenu = (event) => {
    setAnchorMenuEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };
  const handleClose = (event) => {
    setAnchorMenuEl(null);
    setOpenMenu(false);
  };
  const createMeeting = React.useCallback(() => {
    dispatch({
      type: ACTIONS.WORKER_CREATE_EVENT_INIT
    }).then(() => {
      // #edit_mode
      // remove saved proposal from LS cache if in edit mode
      let prop = loadFromCache(false);
      if (prop && prop.prefill_type === PREFILL_EDIT) {
        clearCache();
      }
      // go to page
      history.push(ROUTES.CREATE_A_MEETING)
    });
  }, [dispatch, history]);
  const openDashboard = () => {
    history.push(ROUTES.APP);
  }
  const showOnlineHelp = React.useCallback((e) => {
    e.preventDefault();
    const doClose = () => {
      closeDialog();
    }
    enqueueDialog({
      content: <OnlineHelp doClose={doClose} />,
      className: "big-screen"
    })
  }, [dispatch, enqueueDialog, closeDialog]);
  return user.isAuth ?
    (
      <nav id="main-nav">

        {/* <a href={t('menu.chromeExtensionLinkURL')} target="_blank" className="ext-link">
          <div className="extension">
            <GmailIcon />
            <div>{t('menu.chromeExtensionLinkText')}</div>
          </div>
        </a> */}
        {true &&
          <div onClick={openDashboard}
            className={"hide-on-mobile nav-link " + (pathname === ROUTES.APP ? "selected " : '')}>
            <span className="desktop">{t('dashboard.navLink')}</span>
          </div>
        }
        <ConnectMenu />
        {(pathname.startsWith(ROUTES.CREATE_A_MEETING_LINK) || !pathname.startsWith(ROUTES.CREATE_A_MEETING)) &&
          <DoneButton onClick={createMeeting} label={
            <>
              <span className="desktop">{t('createEvent.title')}</span>
              <span className="mobile">{t('createEvent.titleMini')}</span>
            </>
          } className="no-margin create-meeting item force-in-header" />
        }


        {mobileMenuVisible &&
          <div className="mobile-menu-overlay"
            onClick={() => setMobileMenuVisible(!mobileMenuVisible)}></div>}
        <div className={"menu-items" + (mobileMenuVisible ? " mobile-menu-visible" : "")}
          onClick={() => (mobileMenuVisible !== null) && setMobileMenuVisible(!mobileMenuVisible)}>
          <Link to={ROUTES.APP} className={"item in-burger-only "}>{t('dashboard.title')}</Link>
          <Link to={ROUTES.APP_PROFILE} className={"item in-burger-only "}>{t('dashboard.profile')}</Link>

          {/* only visible in menu on mobile */}
          <Link to={ROUTES.APP_SETTINGS} className="item in-burger-only ">{t('dashboard.settings')}</Link>
          <a className="item in-burger-only"
            onClick={showOnlineHelp} href='#'>
            {t('menu.onlineTutorial')}
          </a>
          {/* only visible in menu on mobile */}
          {i18next.exists('menu.helpFeedback') &&
            <a href={t('menu.helpFeedbackLink')} className="item in-burger-only">
              {t('menu.helpFeedback')}
            </a>
          }

          {/* hidden on mobile */}
          <div onClick={toggleMenu} className="profile-avatar">
            <Avatar name={user.name} imageSrc={user.avatar_url} />
            <KeyboardArrowDown />
          </div>

          {/* only visible in menu on mobile */}
          <a onClick={signOut} href="#" className="in-burger-only">
            {t('common.signout')}
          </a>

          <Popover
            className="profile-menu"
            open={!!anchorMenuEl}
            anchorEl={anchorMenuEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} >
            <Link to={ROUTES.APP} className={"item in-burger "} onClick={handleClose}><MenuItem>{t('dashboard.title')}</MenuItem></Link>
            <Link to={ROUTES.APP_PROFILE} className={"item in-burger "} onClick={handleClose}><MenuItem>{t('dashboard.profile')}</MenuItem></Link>
            <Link to={ROUTES.APP_SETTINGS} className={"item in-burger "} onClick={handleClose}><MenuItem>{t('dashboard.settings')}</MenuItem></Link>
            <Divider />
            <a href={t('menu.helpFeedbackLink')} target="_blank" rel="noopener noreferrer" className="item in-burger">
              <MenuItem>
                {t('menu.helpFeedback')}
              </MenuItem>
            </a>
            <a className="item in-burger"
              onClick={showOnlineHelp}>
              <MenuItem>
                {t('menu.onlineTutorial')}
              </MenuItem>
            </a>
            <a href={t('menu.youtubeLink')} target="_blank" rel="noopener noreferrer" className="item in-burger">
              <MenuItem>
                {t('menu.youtube')}
              </MenuItem>
            </a>
            <a href={t('menu.blogLink')} target="_blank" rel="noopener noreferrer" className="item in-burger">
              <MenuItem>
                {t('menu.blog')}
              </MenuItem>
            </a>
            <Divider />
            <MenuItem onClick={signOut}>{t('common.signout')}</MenuItem>
          </Popover>
        </div>
        <div className="BurgerMenu" onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
          <Menu />
        </div>
      </nav >
    )
    : <Link to={ROUTES.AUTH}><DoneButton label={t('common.signin')} className="no-margin grey small" /></Link>;

}

