import constants from '../components/styles/_constants.scss';

/**
 * Returns the colors for the monogram avatar
 * 
 * @param {string} fullName name of the contact
 * @returns {string} CSS hexa color
 */
export function getColorForName(fullName) {
  // const COLORS = [constants.colorIcterine, constants.colorPear, constants.colorAcideGreen, constants.colorAquamarine, constants.colorMedAquamarine, constants.colorTiffanyBlue, constants.colorPeach, constants.colorCarminePink, constants.colorDeepChesnut];
  const COLORS = [
    constants.colorRobinEggBlue,
    constants.colorBitterSweet,
    constants.colorBananaYellow,
    constants.colorPeach,
    constants.colorAquamarine,
    constants.colorRajah,
    constants.colorPear
  ];
  if (!fullName) return constants.colorRobinEggBlue;
  if (fullName === 'none') return undefined;
  return COLORS[fullName.length % COLORS.length];
}