import * as Yup from 'yup';
import moment from 'moment';
import { linearize } from '../../worker/handlers/generate.slots';
export const UNKNOWN_EMAIL_DOMAIN = '@null-letsmeet.network';
export function isUnknownDomain(mail = "") {
  return mail.endsWith(UNKNOWN_EMAIL_DOMAIN)
}
export const STATUS = {
  PENDING: 'sent',
  CANCELLED: 'canceled',
  CONFIRMED: 'complete',
  NO_AVIABILITY: 'no-avail',
  EVT_CANCELLED: 'evt-cancelled'
}
export const PROPOSAL_TYPE = {
  POLL: "poll",
  FUNNEL: "funnel"
}
export const CALENDAR = {
  provider: Yup.string().oneOf(['google', 'microsoft']).required(),
  accountId: Yup.string().required(),
  calendarId: Yup.string().required(),
};
export const PROPOSAL_HOST = Yup.object().shape({
  calendar: Yup.object().shape(CALENDAR).required()

});
// validator for API
// NON-organizer
export const PROPOSAL = Yup.object().shape({
  id: Yup.string().required(),
  icaluid: Yup.string().when(
    'status',
    {
      is: STATUS.CONFIRMED,
      then: Yup.string().required(),
      otherwize: Yup.string(),
    }
  ), // only if proposal accepted
  status: Yup.string().oneOf([STATUS.PENDING, STATUS.CANCELLED, STATUS.CONFIRMED, STATUS.NO_AVIABILITY]).required(),
  name: Yup.string().required(),
  "important": Yup.boolean().default(false),
  "ignore_initial_avail": Yup.boolean().default(false),
  "location": Yup.string(),
  "proposal_type": Yup.string().oneOf(["funnel", 'poll']),
  "conference": Yup.string(),
  duration: Yup.number().required(),
  "note": Yup.string(),
  "reminders": Yup.array().of(Yup.number()).default([]),
  slots: Yup.array().of(Yup.object().shape({
    start: Yup.date().required(),
    end: Yup.date().required()
  })).required(),
  attendees: Yup.array().of(Yup.object().shape({
    email: Yup.string().email().required(),
    "label": Yup.string(),
    "optional": Yup.boolean().default(false),
    "response": Yup.string().oneOf(["yes", 'no']),
    "slots": Yup.array().of(Yup.object().shape({
      start: Yup.date().required(),
      end: Yup.date().required()
    })),
    // if user had more slots
    "alternate_slots": Yup.array().of(Yup.object().shape({
      start: Yup.date().required(),
      end: Yup.date().required()
    })),
    organizer: Yup.bool().default(false),
    registered: Yup.bool().default(false),
    "created_at": Yup.date(),
    "updated_at": Yup.date(),
  })).required()
    .test('organizer_present', '', (v) => v && !!v.find((a) => a.organizer))
  ,
  "created_at": Yup.date(),
  "updated_at": Yup.date(),
  "remaining_slots": Yup.array().of(Yup.object().shape({
    start: Yup.date().required(),
    end: Yup.date().required()
  })),
  "final_slot": Yup.object({
    start: Yup.date(),
    end: Yup.date()
  }).nullable(true)

});

// transformers
// from app -> API
// for POST
/*
 *  "provider": "google",
    "user_id": "4t65er4t35df7tg6dr",
    "cal_id": "fsd54fs4ef4sd@google.com",
    "name": "new proposal",
    "notes": "Some notes describing the event proposed",
    "location": "some place",
    "duration": 30,
    "reminders": [
        5,
        60
    ],
    "note": "blabla -- the notes",
    "slots": [
        {
            "start": "2020-10-01T14:30:00Z",
            "end": "2020-10-01T15:00:00Z"
        },
        {
            "start": "2020-10-07T14:30:00Z",
            "end": "2020-10-07T15:30:00Z"
        }
    ],
    "attendees": [
        {
            "email": "yam@do.ne",
            "label": "Yamiche"
        }
    ]
} event 
 */
//next_2_days || next_week || next_month || custom
const CURRENT_TIME_FRAME = {
  "0": "48_hours",
  "1": "next_week",
  "2": "next_month",
  "-1": "heatmap",
}
export function toAPI(event, store = { profiles: {} }) {
  let slots = event.slots || [];
  let linearizedSlots = [];
  slots.map((sl) => {
    // linearizedSlots.push(...sl.slots)
    linearizedSlots.push(...sl.slots.map((s) => {
      return {
        start: moment(s.start).toISOString(true),
        end: moment(s.end).toISOString(true),
      }
    }));
    linearizedSlots = linearizedSlots.sort((a, b) => a.start.localeCompare(b.start))
  })
  const details = event.inviteesDetails || {};
  const when_type = CURRENT_TIME_FRAME[event.currenttimeframe] || CURRENT_TIME_FRAME["0"];
  const vote_required = event.vote_required;
  // only pass non-readonly datas
  return {
    provider: event.calendar.provider,
    user_id: event.calendar.accountId,
    cal_id: event.calendar.calendarId,
    proposal_type: event.proposal_type,
    name: event.title,
    important: event.important,
    ignore_initial_avail: event.ignore_initial_avail,
    note: event.notes || '',
    location: event.location || '',
    when_type: when_type,
    conference_provider: event.conference || '',
    reminders: (event.reminders || []).map((r) => +r),
    duration: event.duration > 0 ? event.duration : event.customduration,
    slots: linearizedSlots,
    hold_slots: event.holdSlots,
    attendees: [
      {
        email: event.calendar.email,
        label: event.calendar.name || ''
      },
      ...event.invitees.map((inv) => {
        let pr = store.profiles[inv] || { name: inv };
        let detail = details[inv] || { optional: false };
        return {
          email: inv,
          label: detail.name || pr.name,
          optional: detail.optional,
          vote_requested: !detail.optional && (detail.vote_requested || vote_required)
        }
      })],
    link_path: event.link_path, // undefined if not exists
  }
}

// from API => app
export const DURATIONS = [30, 45, 60];
export function fromAPI(event, userEmail = []) {
  let duration = event.duration;
  let customduration = 10;
  let evtattendees = event.attendees || [];
  // timeframes and invitees
  let organizer = evtattendees.find((a) => a.organizer) || {};
  // if organizer and ObO, add a custom info
  if (organizer && event.on_behalf_of && event.on_behalf_of_name) {
    organizer.onBehalfOf = event.on_behalf_of_name
  }
  let attendees = evtattendees.filter((a) => !a.organizer) || []; // maybe we will permit no attendees?


  // get user response
  let reponse = null;
  let att = (attendees.find((att) => userEmail.find((ue) => ue === att.email)) || {}); //.response;
  if (att) reponse = /*att.registered ? 'yes' :*/ att.response;

  if (event.status === STATUS.PENDING && event.slots) {
    for (let slot of event.slots) {
      slot.notInRemainingSlots = !(event.remaining_slots || []).find((sl) => sl.start === slot.start && sl.end === slot.end);
    }
  }
  let timeframes = transformTimeframes(event.slots, evtattendees, userEmail);
  let remaining = transformTimeframes(event.remaining_slots, evtattendees, userEmail, att ? att.registered : false);

  if (DURATIONS.indexOf(duration) === -1) {
    // custom
    customduration = duration;
    duration = -1;
  }

  // Check zoom status
  if (event.status === STATUS.CONFIRMED) {
    if (event.conference_provider && (!event.conference_url && !event.location)) {
      // we had a problem, mark location as error
      event._has_error_conference = true;
    }
  }
  let status = event.status;
  if (status === STATUS.CANCELLED && event.final_slot && event.final_slot.start) status = STATUS.EVT_CANCELLED;

  const invitees = attendees.map((a) => a.email);
  const details = attendees.reduce((acc, a) => {
    acc[a.email] = {
      ...a,
      name: a.label,
      isUnknown: (a.email || '').endsWith(UNKNOWN_EMAIL_DOMAIN),
    };
    return acc;
  }, {});
  const areNewSlotsProposed = event.proposal_type === 'poll' && !!attendees.find((a) => a.alternate_slots && a.alternate_slots.length > 0);
  return {
    id: event.id,
    icaluid: event.icaluid, // could possibly be undefined if not complete
    booking_link_id: event.booking_link_id, // made fron a booking link
    title: event.name,
    important: event.important,
    ignore_initial_avail: event.ignore_initial_avail,
    status: status,
    notes: event.note || '',
    onBehalfOf: event.on_behalf_of,
    onBehalfOfName: event.on_behalf_of_name,
    duration: duration,
    customduration: customduration || 10,
    holdSlots: event.hold_slots,
    "location": event.location || '',
    "conference": event.conference_provider || '',
    "conference_url": event.conference_url || '',
    __conference_not_generated: event._has_error_conference,
    "reminders": (event.reminders || []).map((r) => '' + r),
    "created_at": event.created_at,
    "updated_at": event.updated_at,
    organizer: {
      ...organizer,
      email: organizer.email,
      name: organizer.label || ''
    },
    calendar: {
      accountId: event.user_id,
      calendarId: event.cal_id,
      provider: event.provider,
    },
    invitees: invitees,
    inviteesDetails: details,
    slots: timeframes,
    remaining_slots: remaining,
    hadVoted: reponse === 'yes',
    hadCancelled: reponse === 'no',
    // final_slot: event.final_slot
    // just populate "start" as if got it from outlook/gmail
    start: event.final_slot ? event.final_slot.start : undefined,
    end: event.final_slot ? event.final_slot.end : undefined, // merge disaster?
    type: event.proposal_type === 'poll' ? 'poll' : 'funnel',
    maxVotersAvailable: linearize(timeframes).reduce((max, slot) => {
      const nbAvailable = (slot.selectedBy || []).length;
      return (nbAvailable > max ? nbAvailable : max);
    }, 0),
    company: event.company,
    link_path: event.link_path, // for info, window is not defined in the worker...
    are_slots_suggestion_allowed: event.are_slots_suggestion_allowed || false,
    areNewSlotsProposed,
  };
}
function transformTimeframes(frames = [], attendees = [], userEmail = [], forceVote = false) {
  let slots = frames.map((t) => ({ ...t, selectedBy: [], IAmFree: undefined, vote: forceVote })).sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());

  // add invitees answers
  // let attendees = event.attendees || [];
  // add owner of proposal in list
  for (let att of attendees) {
    let selected = att.slots || []; // take attendee response OR empty array
    let alternate = att.alternate_slots || [];

    // if registered did not answer, use default slots
    if ((att.registered || (att.related_to && att.related_to.user_id)) && !att.vote_requested) {
      if (att.response !== 'yes' && att.conflicting_slots && att.conflicting_slots.length > 0) selected = [];
      else selected = att.computed_slots || selected
    }
    // add to timeframes
    for (let stf of selected) {
      if (!stf) continue;
      // find slot index
      let i = slots.findIndex((s) => s && moment(s.start).valueOf() === moment(stf.start).valueOf() && moment(s.end).valueOf() === moment(stf.end).valueOf());

      if (i > -1 && slots[i]) {
        // if organizer, check for OBO
        if (att.organizer && att.onBehalfOf) {
          slots[i].selectedBy.push(att.onBehalfOf);
        } else {
          slots[i].selectedBy.push(att.email);
          // check if new slot?
          let ns = alternate.findIndex((s) => s && moment(s.start).valueOf() === moment(slots[i].start).valueOf() && moment(s.end).valueOf() === moment(slots[i].end).valueOf());
          if (ns > -1 && alternate[ns]) {
            slots[i].proposed_by = {
              email: att.email,
              label: att.label,
            }
          }
        }
        if (userEmail.find((e) => e === att.email)) {
          slots[i].vote = true;
        }
      }
    }
  }
  // set as day/slots
  // ie group slots by days
  // let groupedSlots = {};
  // for (let frame of slots) {
  //   // get start for day
  //   let day = moment(frame.start).format('YYYY MM DD');
  //   let sls = groupedSlots[day] || {
  //     day: moment(frame.start).startOf('day').toISOString(),
  //     slots: []
  //   };
  //   // add to list (sorted?)
  //   sls.slots.push(frame);
  //   groupedSlots[day] = sls;
  // }
  // return Object.values(groupedSlots);
  return groupSlots(slots)
}
// Take a linearized array of slots and group them by day
export function groupSlots(slots) {
  let groupedSlots = {};
  for (let frame of slots) {
    // get start for day
    let day = moment(frame.start).format('YYYY MM DD');
    let sls = groupedSlots[day] || {
      day: moment(frame.start).startOf('day').toISOString(),
      slots: []
    };
    // add to list (sorted?)
    sls.slots.push(frame);
    groupedSlots[day] = sls;
  }
  return Object.values(groupedSlots);
}