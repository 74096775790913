import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { DoneTextFieldRef as DoneTextField } from './Text';
import * as ACTIONS from '../../../../actions';
import { getDefaultCalendar } from '../../../../reducers/accounts';
import {
  EVENT_TITLE_MAX,
} from '../../../../validators/event';
import { compareNoSpace as compare } from '../../../../utils/compare.string';
import { RenderWithSearch } from './render.with.search';
import { PopperHOC } from './PopperHOC';
import TextBlock from '../../../TextBlock';
import Conference from '../../create-event/recap/conference';
import { durationFormatter } from '../../../../utils/duration.formatter';
import { isUnknownDomain } from '../../../../api/validators/proposal';
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  IconButton,
  InputLabel,
  TextField
} from '@material-ui/core';
import {
  PriorityHigh,
  HelpOutline
} from '@material-ui/icons';
import { LMTooltip } from '../../../tooltip/Tooltip';
import { conferenceUrlCompat } from '../../../../utils/compatibility.conference'
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from '../../../../utils/debounce'
import "./title.predictive.scss";

const getProposalByName = (data, search) => {
  let name = (data || { title: "" }).title;
  return compare(name, search);
}

export const ProposalItem = ({ item, popperselected, doSelect, value }) => {
  const { t } = useTranslation();
  if (!item) return null;
  const duration = item.duration === -1 ? durationFormatter(item.customduration, t) : durationFormatter(item.duration, t);
  const { conference_url, location_txt } = conferenceUrlCompat(item)
  const conference = item.conference ? <Conference id={item.conference} conference_url={conference_url} error={item.__conference_not_generated} />
    : null;
  const location = location_txt ? <TextBlock txt={location_txt} /> : null

  // display full emails or number of guests of too many
  const mx = 4;
  let inviteesTxt = t('proposal.details.invitees', { count: (item.invitees || []).length });
  if (item.invitees && item.invitees.length <= mx) {
    inviteesTxt = "";
    let details = item.inviteesDetails || {}
    for (let i = 0; i < item.invitees.length; i++) {
      // if unknown, display name only
      let attendee = details[item.invitees[i]] || {};
      let label = attendee.isUnknown ? attendee.label : attendee.email || item.invitees[i]
      inviteesTxt += label + (i === mx || i === (item.invitees.length - 1) ? "" : ", ");
      if (i === mx) break;
    }
  }

  return <div data-testid={"proposal-item-" + item.id} className={popperselected ? 'proposal-selector-item selected' : 'proposal-selector-item'} onClick={() => doSelect(item)}>
    <RenderWithSearch txt={item.title} srch={value} />
    <div>
      <span className="proposal-duration proposal-more">{duration}</span> • <span className="proposal-invitees proposal-more">{inviteesTxt}</span>
      {conference && <span className="proposal-location">{conference}</span>}
      {location && <span className="proposal-location proposal-location-text">{location}</span>}
    </div>
  </div>
};
export function TitlePredictiveWithAllData({
  value,
  isImportant = false,
  error,
  touched,
  onChange,
  disableImportant = false,
}) {
  const { t } = useTranslation();

  const inputRef = React.useRef();
  const filteredProposals = useSelector(getTemplatedProposals);

  return <PopperHOC value={value} onChange={onChange} inputRef={inputRef} filteredList={filteredProposals}
    searchFct={getProposalByName} ChildrenItems={ProposalItem}>
    {(txtValue, onKeyDown, onBlur, onFocus, onValueChange) => <DoneTextField
      label={
        <>{t('createEvent.form.name')}
          <LMTooltip
            childrenClassName="showHand"
            content={
              <>
                <p><Trans i18nKey="createEvent.form.title.tooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
              </>
            }
          >
            <HelpOutline />
          </LMTooltip></>}
      value={txtValue}
      className="with-help no-bottom"
      InputLabelProps={{ className: "with-help-button" }}
      name='title'
      onChange={onValueChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      errorText={error && t(error, { max: EVENT_TITLE_MAX })}
      placeholder={t('createEvent.form.namePlaceholder')}

      isTouched={txtValue || touched}
      isValid={(txtValue || !touched)}
      ref={inputRef}
      endAdornment={disableImportant ? null : <ImportantButton value={isImportant} onChange={onChange} />}
    />
    }
  </PopperHOC>;

}
const ImportantButton = ({ value, onChange }) => {
  const { t } = useTranslation();
  const onToggleImportant = React.useCallback((e) => {
    onChange({
      target: {
        name: "important",
        value: !value
      }
    })
  }, [value, onChange])
  return (
    <LMTooltip content={
      <>
        <p className="strong">
          {t('createEvent.form.importantTitle' + (value ? 'High' : 'Normal'))}
        </p>
        <p>
          {t('createEvent.form.importantDescription' + (value ? '' : 'Action'))}
        </p>
      </>}>
      <IconButton onClick={onToggleImportant} data-testid="proposal-important-button">
        <PriorityHigh className={value ? 'toggled' : 'normal'} />
      </IconButton>
    </LMTooltip>
  );
}
function cleanInvitees(proposal, accounts = []) {
  let invitees = proposal.invitees || [];
  let inviteesDetails = proposal.inviteesDetails || {};
  let accountsEmails = accounts.map((acc) => acc.email);
  let ri = [];
  let rid = {};
  for (let inv of invitees) {
    if (!accountsEmails.find((e) => e === inv)) {
      ri.push(inv);
      rid[inv] = { ...inviteesDetails[inv] };
    }
  }
  return [ri, rid];
}
export const getTemplatedProposals =
  (proposals = [], accounts) => {
    let r = {};
    proposals.map((p) => {
      // sort by creation date, most recent first
      if (accounts) {
        let acc = accounts.find((acc) => acc.id === p.calendar.accountId);
        if (!acc) {
          acc = getDefaultCalendar({ accounts })
        }
        let [inv, invD] = cleanInvitees(p, accounts);
        p.iAmHost = true; // mark as I am host
        // populate calendar data
        p.invitees = inv;
        p.inviteesDetails = invD;
        p.calendar = {
          ...p.calendar,
          ...acc,
          provider: p.calendar.provider,
        }
        if (!r[p.title] || r[p.title].created_at < p.created_at) {
          r[p.title] = p;
        }

      }
    });
    return Object.values(r).sort((a, b) => a.title < b.title);
  }
export function TitlePredictive({
  value,
  isImportant = false,
  error,
  touched,
  onChange,
  disableImportant = false,
  accounts,
  debounce_delay = 300,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const doChange = React.useCallback((e) => {
    // if select, clear list of propositions
    if (e.title) {
      // clear selector
      setFilteredProposals([])
    }
    onChange(e)
  }, [onChange])
  const inputRef = React.useRef();
  const debounce_cache = React.useRef({}); // as str => resp
  const [loading, setLoading] = React.useState(false)
  const [filteredProposals, setFilteredProposals] = React.useState([]); // useSelector(getTemplatedProposals);
  const debouncedLoad = React.useMemo(() => debounce((args) => {
    let accounts = args[1]
    const accountsEmails = (accounts || []).map((acc) => acc.email)
    const accountsIds = (accounts || []).map((acc) => ({ id: acc.provider.name + ':' + acc.id, email: acc.email }))
    const search = args[0]
    dispatch({
      type: ACTIONS.WORKER_GET_PROPOSAL,
      payload: {
        userEmail: accountsEmails,
        userAccounts: accountsIds,
        search: args[0],
        limit: 10,
        fast: true, // do not decorate content (ie: no get event if complete,...)
      },
      // requestId: uuid.v4(),
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR,
        workerID: 'LOAD_PROPOSAL_DEBOUNCED',
      }
    }).then((rep) => {
      if (rep && rep.payload) {
        let r = getTemplatedProposals(rep.payload, accounts)
        // append to cache
        let cache = debounce_cache.current;
        if (cache) cache[search] = r;

        setFilteredProposals(r)
      }
    }).catch((err) => {
      console.log(err)
      // ?
    }).finally(() => setLoading(false))
  }, { wait: debounce_delay, maxWait: 1000, maxCall: 100 }), [])
  const loadTitlePredictiveOptions = React.useCallback((search) => {
    let cache = debounce_cache.current;
    if (cache && cache[search]) {
      setFilteredProposals(cache[search]);
      setLoading(false)
      return;
    }
    // get emails and id from accounts
    debouncedLoad(search, accounts)
  }, [accounts, debouncedLoad, debounce_cache]);

  let endAdornment = [
    loading ? <CircularProgress className="small-loader" /> : null,
    disableImportant ? null : <ImportantButton value={isImportant} onChange={onChange} />
  ];

  return <PopperHOC value={value} onChange={doChange} inputRef={inputRef} filteredList={filteredProposals}
    searchFct={getProposalByName} ChildrenItems={ProposalItem}>
    {(txtValue, onKeyDown, onBlur, onFocus, onValueChange) => <DoneTextField
      label={
        <>{t('createEvent.form.name')}
          <LMTooltip
            childrenClassName="showHand"
            content={
              <>
                <p><Trans i18nKey="createEvent.form.title.tooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
              </>
            }
          >
            <HelpOutline />
          </LMTooltip></>}
      value={txtValue}
      className="with-help no-bottom"
      InputLabelProps={{ className: "with-help-button" }}
      name='title'
      onChange={(e) => {
        if (e && e.target && e.target.value !== "") {
          let fp = filteredProposals;
          // do we need to process a request:
          if (loading || (filteredProposals.length > 0 || (value === "" || !e.target.value.startsWith(value)))) {
            setLoading(true)
            if (typeof e.target.value === 'string') loadTitlePredictiveOptions(e.target.value)
          }
        }
        onValueChange(e);
      }}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      errorText={error && t(error, { max: EVENT_TITLE_MAX })}
      placeholder={t('createEvent.form.namePlaceholder')}

      isTouched={txtValue || touched}
      isValid={(txtValue || !touched)}
      inputRef={inputRef}
      endAdornment={endAdornment}
    />
    }
  </PopperHOC>;

}