import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

export default function Timezone({ obo, calendar }) {

  const profile = useSelector((state) => state.profile);
  const tz = React.useMemo(() => {
    if (obo && calendar) return calendar.timezone;
    return profile.timezone;

  }, [profile, obo, calendar]);

  return <>{tz ? tz + " " + moment().tz(tz).format("[GMT]Z") : ''}</>;
}