import { Button, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next';
import { FileCSV } from '../../../../assets/icons';
import { useDialog } from '../../../dialogs/dialog.provider';
import { LoadingButton } from '../../../LoadingButton';
import { MAIL_RX_SENTENCE } from '../invite/mailer';
import './seats.uploader.scss';

export default function SeatsUploader({ addSeats, assignedSeats, nbSeats }) {
  const { enqueueDialog, closeDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  // A file has been picked by the user
  const onDrop = useCallback((acceptedFiles) => {

    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      // Find emails in file
      let emails
      emails = getEmailsFromFileContents(reader.result);
      // Dedup emails from file using already assigned seats
      let newEmails = emails.filter(email => assignedSeats.find(seat => (seat.email || '').toLowerCase() === email) === undefined);
      if (emails.length === 0) {
        // No email found in the file
        enqueueSnackbar(t("settings.sections.billing.admin.errors.addSeats.noEmail"));
      } else if (newEmails.length === 0) {
        // No NEW email found
        enqueueSnackbar(t("settings.sections.billing.admin.errors.addSeats.noNewEmail", { nbEmailsFound: emails.length }));
      } else {
        const nbSeatsLeftToAssign = nbSeats - assignedSeats.length;
        if (newEmails.length > nbSeatsLeftToAssign) {
          // Trying to assign more seats than available
          enqueueSnackbar(t("settings.sections.billing.admin.errors.addSeats.notEnoughSeats"));
        } else {
          // Ask user for confirmation to assign licences
          enqueueDialog({
            content: <ConfirmEmailsDialog emails={newEmails} doClose={closeDialog} addSeats={addSeats} nbDuplicates={emails.length - newEmails.length} />,
            doClose: closeDialog,
            overrideDefaultProps: { maxWidth: "xs", fullWidth: true }
          });
        }
      }
    }
    reader.readAsText(file);
  }, [closeDialog, enqueueDialog, addSeats, assignedSeats, nbSeats, t, enqueueSnackbar]);
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop, maxFiles: 1 })

  return (
    <div {...getRootProps()} className={'seats-uploader' + (isDragAccept ? ' drag-accept' : '')}>
      <input {...getInputProps()} data-testid="uploader-input" onChange={(e) => onDrop(e.target.files)} type="file" />
      <FileCSV />
      <div className='primary-text'>
        <Trans i18nKey='settings.sections.billing.admin.addSeatsBrowse' components={{ b: <span /> }} />
      </div>
      <div className='secondary-text'>
        <Trans i18nKey='settings.sections.billing.admin.addSeatsBrowseHint' />
      </div>
    </div>
  );
}

function ConfirmEmailsDialog({ emails, doClose, addSeats, nbDuplicates }) {

  const { t } = useTranslation();
  const [inProgress, setInProgress] = React.useState(false);

  const sendEmails = () => {
    setInProgress(true);
    addSeats(emails).finally(() => {
      setInProgress(false);
      doClose();
    });
  }

  return (
    <>
      <DialogTitle className='confirm-emails-seats-popup-title'>
        <div>{t('settings.sections.billing.admin.addSeatsConfirmDialogTitle', { count: emails.length })}</div>
        {nbDuplicates > 0 && <div className='subtitle'>{t('settings.sections.billing.admin.addSeatsConfirmDialogSubtitle', { count: nbDuplicates })}</div>}
      </DialogTitle>
      <DialogContent className={"fancy-scroll"}>
        <div>
          {emails.map(
            (email) => <div key={email}>{email}</div>
          )}
        </div>
      </DialogContent>
      <DialogActions data-testid='confirm-emails-seats-popup-actions'>
        <Button
          onClick={doClose}
          className=''
          data-testid='confirm-emails-seats-popup-actions-close'>
          {t("settings.sections.billing.admin.addSeatsConfirmDialogActionCancel")}
        </Button>
        <LoadingButton
          onClick={sendEmails}
          className='confirm-positive-action'
          disabled={inProgress}
          data-testid='confirm-emails-seats-popup-actions-ok'
          isLoading={inProgress}>
          {t(inProgress ? "settings.sections.billing.admin.addSeatsConfirmDialogActionInProgress" : "settings.sections.billing.admin.addSeatsConfirmDialogActionOK", { count: emails.length })}
        </LoadingButton>
      </DialogActions>
    </>
  );
}

function getEmailsFromFileContents(fileContents) {
  let emails = []
  const rows = fileContents.split('\n');
  rows.forEach(rowData => {
    let row = rowData.replace(/"/g, '');
    const emailsInRow = row.toLowerCase().match(MAIL_RX_SENTENCE)
    if (emailsInRow) {
      emails = emails.concat(emailsInRow);
    }
  });
  // dedup
  return [...new Set(emails)];
}