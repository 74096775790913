import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import "./permissions.scss";

/*


*/
export default function Permissions() {
  const { t } = useTranslation();

  return (
    <div className="landing-permissions-panel">
      <Trans i18nKey="landing.permissions">
        <span className="txt margin-bottom">Hey,</span>
        <span className="txt margin-bottom">We value your privacy a lot.</span>
        <span className="txt margin-bottom">That’s why, to make your life easier, Letsmeet only needs two main permissions.</span>
        {/* <span className="txt strong with-icon icon-1">See and download contact information</span>
<span className="txt with-icon margin-bottom">Obviously Letsmeet will not download your contacts.  Letsmeet just wants to be able to suggest the exact email address of your guests as soon as you enter the first letter of his or her name.</span> */}
        <span className="txt strong">See, edit and delete all of your calendars </span>
        <span className="txt margin-bottom">When you schedule, cancel or move a meeting, Letsmeet just wants to update your agenda accordingly. So, if you cancel a meeting, Letsmeet will delete it from your agenda.</span>
        <span className="txt margin-top margin-bottom">And just to be sure,</span>
        <span className="txt strong with-icon margin-bottom">Letsmeet never ever downloads, stores or shares any data, even if you give Letsmeet the permission.</span>
        <span className="txt strong margin-bottom  with-icon margin-bottom">Letsmeet is just checking your free/busy slots. Letsmeet never checks the details of your agenda.</span>
        <span className="txt">That’s it.</span>
        <span className="footer">But if you want more, here is our </span><a className="link" href={t('settings.sections.privacy.content.sectionPrivacyPolicyURL')} target="_BLANK">Privacy Policy</a></Trans>
    </div>
  )
}