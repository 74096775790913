import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import GuestItem from '../../create-event/guests/guest.item';
import Popper from '@material-ui/core/Popper';
import usePagination from '../../../../hooks/use.pagination';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {
  MailIcon,
  IconDelete,
  IconCheck
} from '../../../../assets/icons';
import '../../create-event/inputs/inputs.scss';
export const MAIL_RX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
// export const MAIL_RX_SENTENCE = /(?:[a-zA-Z0-9_\-\.+]+)@(?:[a-zA-Z0-9_\-\.]+)\.(?:[a-zA-Z]{2,10})/g; // Fix letsmeet.network
export const MAIL_RX_SENTENCE = /(?:[a-z0-9_][a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
export default function MailComponent({ value = '',
  addMail,
  removeMail,
  setmail,
  index,
  focus,
  setFocus,
  showSelector = true,
  contacts = [],
  blacklist }) {
  const { t } = useTranslation();
  const [invalid, setInvalid] = useState(false);

  const isMyEmail = React.useCallback((email) => {
    return email && blacklist &&
      blacklist.find((bl) => email.email === bl);
  }, [])
  const pagination = usePagination(
    contacts, 10, undefined, value, isMyEmail
  )
  const [filteredContacts, setFilteredContacts] = React.useState([]);

  // const inputRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const scrollerRef = React.useRef(null);
  const [popperselected, setpopperSelected] = React.useState(0); // By default, first one is selected
  const [anchorElWidth, setAnchorElWidth] = React.useState({ width: 400 });
  // reset propositions
  const doReset = React.useCallback((focus = true) => {
    // remove popper
    pagination.reset();
    setFilteredContacts([]);
    setAnchorEl(null);
    // clear value
    setpopperSelected(0)

  }, [pagination])
  // enter to validate?
  const onKeyDown = React.useCallback((e) => {

    /* istanbul ignore else no work */
    if (e.keyCode === 13) {
      e.preventDefault();
      if (filteredContacts.length > 0) addMail(filteredContacts[popperselected].email, index)
      else if (MAIL_RX.test(value)) addMail(value, index);
      else return false;
      doReset();
      return false;
    } else if (e.keyCode === 40) {
      let index = popperselected + 1;
      if (index >= filteredContacts.length) index = filteredContacts.length - 1;
      setpopperSelected(index);
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = index * 52;
      }
      e.preventDefault();
      // ensure scroll to
    } else if (e.keyCode === 38) {
      let index = popperselected - 1;
      if (index < 0) index = 0;
      setpopperSelected(index);
      // ensure scroll to
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = index * 52;
      }
      e.preventDefault();
    }
  }, [value, index, addMail, filteredContacts, doReset, popperselected, scrollerRef.current]);

  const onFocus = React.useCallback((event) => {
    setFocus(index);
    /* istanbul ignore else no work */
    if (!anchorEl && event.currentTarget) {
      // calculate size of caller
      let rect = event.currentTarget.getBoundingClientRect() || { width: 400 };
      let w = rect.width;
      setAnchorElWidth({ width: w });
    }
    setAnchorEl(event.target)
  }, [anchorEl, setFocus])

  // scroll to bottom on list of contacts
  /* istanbul ignore next no scroll in test */
  const onScroll = React.useCallback((e) => {
    // if bottom, load more
    const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 5);
    if (bottom) setFilteredContacts(pagination.next(value))
  }, [value, pagination]);

  // select a contact in list
  const doSelect = React.useCallback((c) => {
    if (!c) c = value;
    addMail(c, index);
    // remove popper
    doReset();
  }, [addMail, value, index, doReset]);


  const addUserMail = React.useCallback((e) => {
    //validate and send
    if (value !== '' && !MAIL_RX.test(value)) {
      // show error
      setInvalid(t('invite.page.invalidMail'));
      return;
    }
    setInvalid(false);
    addMail(value, index);
    // if mail is not empty, try a dns lookup --------------------- Pure testing -----------------------
    /*if (value) {
      let domain = value.split('@')[1];
      fetch("https://dns.google/resolve?name=" + domain)
        .then((ret) => {
          if (ret.status === 200) return ret.json();
        }).then((ret) => {
          if (ret) {
            if (ret.Status !== 0) {
              setInvalid('invite.page.unknownDomain')
            }
          }
        }).catch((err) => {
          // don't care....
        })
    }*/
    // setMail('');
  }, [value, addMail, setInvalid, t]);
  const remove = React.useCallback(() => {
    if (value.email !== '') {
      removeMail(index)
      // setMail('');
    }
  }, [removeMail, value.email]);

  // console.log('Use pagination:',value,showSelector,focus,!!anchorEl, filteredContacts)
  return <div className="mail-item">
    <TextField placeholder={t('settings.sections.invite.content.mailerPlaceholder')}
      data-testid={"mailer-" + index}
      className="email-input done-input"
      autoComplete="none"
      margin="normal"
      type="text"
      variant="outlined"
      inputRef={input => {
        if (input && focus && input !== document.activeElement) {
          input.focus();
        }
      }}

      value={value}
      error={!!invalid}
      helperText={invalid}
      onChange={(e) => {
        // console.log('Change:',e.target.value, value)
        if (e.target.value === value) return;
        // console.log('Set filtered contact', e.target.value)
        setFilteredContacts(pagination.next(e.target.value))
        setmail(e.target.value, index);
        // check for datas in list?
        // handleChange(e)
        // need to test if mail is valid?
        if (invalid && MAIL_RX.test(e.target.value)) {
          setInvalid(false)
        }
      }}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={(e) => {
        addUserMail(); // Problem, if invalid and click on cross OR popup, trigger 
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MailIcon className="big" />
          </InputAdornment>),
        endAdornment: value !== '' && (<IconButton data-testid={"mailer-remove-" + index} aria-label="delete" color="secondary" onMouseDown={remove}>
          <IconDelete className="red" />
        </IconButton>),
        inputProps: { tabIndex: index }
      }} />
    {
      // if component got focus show tips
      showSelector && focus && value && filteredContacts.length > 0 && <ClickAwayListener onClickAway={() => setAnchorEl(null)}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart">
        <Popper className="popper" open={!!anchorEl} anchorEl={anchorEl}
          placement="bottom" className="done-input">
          <div className="selector-options guest-selector fancy-scroll" style={anchorElWidth}
            onScroll={onScroll}
            ref={scrollerRef}
            data-testid="guest-selector-popper"
          >
            {filteredContacts.map((c, i) => <div key={c.email} title={i + ',' + popperselected}>
              <GuestItem classes={(i === popperselected) ? 'selected' : ''} onMouseDown={() => doSelect(c.email)} contact={c} searchString={value} />
            </div>)}
          </div>
        </Popper >
      </ClickAwayListener>
    }
  </div>
}
