import {
  SERVER, VERSION,
  SUPPORTED_PROVIDERS,
  DOMAIN,
} from './config';
import {
  getAID
} from '../middlewares/segment';
import { parseSignupResponse } from './signup.parse';


export function signup(provider, login_host, login_hint, cible = '') {
  if (!provider || !SUPPORTED_PROVIDERS[provider]) return Promise.reject('Invalid provider');

  let url = SERVER + VERSION + '/auth/register/' + encodeURIComponent(provider) + `?return_uri=${encodeURIComponent(DOMAIN + '/' + cible)}`;
  if (login_host) url += '&tenant_hint=' + encodeURIComponent('organization');
  if (login_hint) url += '&login_hint=' + encodeURIComponent(login_hint);
  let anonymousId = getAID()
  return fetch(url, {
    headers: { "LM-Anonymous-ID": anonymousId, "LM-Client": "web" }
  })
    .then((rep) => {
      if (rep.status !== 200) return Promise.reject({ error: rep.status })
      return rep.json()

    }).then(parseSignupResponse);
}