import * as Yup from 'yup';
import { DURATIONS } from './proposal';
import { WH_DAY, wh_toapi, wh_fromapi } from './profile';
const CALENDAR = {
  provider: Yup.string().oneOf(['google', 'microsoft']).required(),
  user_id: Yup.string().required(),
  cal_id: Yup.string().required(),
};
export const LINK = Yup.object().shape({
  id: Yup.string().required(),
  name: Yup.string().required(),
  timezone: Yup.string(),
  slug: Yup.string().matches(/[-a-z0-9_]{3,255}/).required(),
  duration: Yup.number().required(),
  ...CALENDAR,
  "note": Yup.string(),
  "location": Yup.string(),
  "conference_provider": Yup.string(),
  is_active: Yup.boolean().default(false),
  "validity_start_time": Yup.date(),
  "validity_duration": Yup.number(),
  business_hours: Yup.object().shape({
    days: Yup.object(WH_DAY)
  }).required(),
  start_inc: Yup.number().min(0).max(1440),
  notice_duration: Yup.number().min(0).max(129600).default(0),
  before_duration: Yup.number().min(0).max(1440),
  after_duration: Yup.number().min(0).max(1440),
  "created_at": Yup.date(),
  "updated_at": Yup.date(),
});
export function toApi(link) {
  // dumb checkd
  if (!link || !link.calendar || !link.validity || !link.configuration) return;

  return {
    id: link.id,
    is_active: link.is_active,
    slug: link.slug,
    timezone: link.timezone,
    name: link.title,
    note: link.notes || '',
    duration: link.duration > 0 ? link.duration : link.customduration,
    "location": link.location || '',
    "conference_provider": link.conference || '',

    user_id: link.calendar.accountId,
    cal_id: link.calendar.calendarId,
    provider: link.calendar.provider,


    validity_start_time: link.validity.start,
    validity_duration: +link.validity.duration,

    business_hours: wh_toapi(link.business_hours),

    start_inc: +link.configuration.start_inc, // step for event (ex: 30min)
    minimum_notice: +link.configuration.notice_duration * +link.configuration.notice_multiplier, // how many time from now is booking possible (ex: start booking in 2 hours)
    minutes_before_event: +link.configuration.before_duration, // time laps after a meeting
    minutes_after_event: +link.configuration.after_duration, // time laps before a meeting ex: let 10 min after a meeting for coffee and 5min after to cool down

    // optional
    onBehalfOf: link.on_behalf_of,
    onBehalfOfName: link.on_behalf_of_name,
  }
}
export function fromApi(link) {
  // check calendar informations
  let duration = link.duration;
  let customduration = 10;
  if (DURATIONS.indexOf(duration) === -1) {
    // custom
    customduration = duration;
    duration = -1;
  }
  const [noticeDuration, noticeMultiplier] = getDurationAndMultiplierFromMinutes(link.minimum_notice || 0);
  return {
    is_link: true, // because we don't know if slug or other will be in proposals one day, so
    // force it
    is_active: link.is_active || false,
    id: link.id,
    slug: link.slug,
    title: link.name,
    timezone: link.timezone,
    notes: link.note || '',
    duration: duration,
    customduration: customduration,
    "location": link.location || '',
    "conference": link.conference_provider || '',
    calendar: {
      accountId: link.user_id,
      calendarId: link.cal_id,
      provider: link.provider,
    },
    validity: {
      start: link.validity_start_time,
      duration: link.validity_duration,
    },
    business_hours: wh_fromapi(link.business_hours),
    configuration: {
      start_inc: link.start_inc, // step for event (ex: 30min)
      notice_duration: noticeDuration,
      notice_multiplier: noticeMultiplier,
      before_duration: link.minutes_before_event, // time laps after a meeting
      after_duration: link.minutes_after_event, // time laps before a meeting ex: let 10 min after a meeting for coffee and 5min after to cool down
    },
    // optional
    onBehalfOf: link.on_behalf_of,
    onBehalfOfName: link.on_behalf_of_name,
    "created_at": link.created_at,
    "updated_at": link.updated_at,
  }
}

export function getDurationAndMultiplierFromMinutes(minutes) {
  const multiplier = getMultiplierFromMinutes(minutes);
  const duration = minutes / multiplier;
  return [duration, multiplier];
}
function getMultiplierFromMinutes(minutes) {
  if (minutes < 60) {
    return 1; // a minute
  } else if (minutes < (24 * 60) && ((minutes % 60) === 0)) {
    return 60; // an hour
  } else if ((minutes % (24 * 60)) === 0) {
    return 24 * 60; // a day
  }
  return 1; // a minute
}