import React from 'react';
import { shallowEqual, useSelector } from 'react-redux'
import { getTheme } from '../reducers/app';
/**
 * Get the theme of the application depending on
 * app state. Default to light
 */
export default function useTheme(){
  const theme = useSelector(getTheme);
  // add to body
  return theme;
};
