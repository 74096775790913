export function hasAnswer(invitee) {
  return !!(invitee.response === "yes"
    || (invitee.registered && !invitee.conflicting_slots && !invitee.vote_requested)
    || (invitee.related_to && invitee.related_to.provider && invitee.related_to.user_id && !invitee.conflicting_slots && !invitee.vote_requested));
}
export function notYetAnswered(proposal) {
  if (!proposal.inviteesDetails) return [];
  const notAnswers =
    Object.values(proposal.inviteesDetails)
      .filter(
        (invitee) => {
          // if MUST answer and has not, return true
          return !hasAnswer(invitee) && !invitee.optional;
        });
  return notAnswers;
}
export function allMandatoryAnswered(proposal) {
  if (!proposal.inviteesDetails) return false;
  const notAnswers = notYetAnswered(proposal)
  return notAnswers.length === 0;
}
