/**
 * Simply recalculate click position depending on parent
 * position
 * @param {MouseEvent} e event
 */
export function getEventPosition(e){
  if(!e || !e.target){
    // not an event, return 0 for all
    return {
      top: 0,
      left: 0,
      gbcr:{
        top: 0,
        left: 0,
        width: 0,
        height: 0
      }
    }
  }
  let clientX = e.clientX /*|| (e.targetTouches[0] ? e.targetTouches[0] : {pageX: 0}).pageX*/; //the same syntax for the x value
  let clientY = e.clientY /*|| (e.targetTouches[0] ? e.targetTouches[0] : {pageY: 0}).pageY*/; //the same syntax for the x value
  let gbcr = e.target.getBoundingClientRect();
  // console.log('GBCR', gbcr)
  return {
    top: (clientY || 0) - gbcr.top,
    left: (clientX || 0) - gbcr.left,
    gbcr
  }
}

export function getEventPositionFromTouchEnd(e, gbcr){
  if(!e){
    // not an event, return 0 for all
    return {
      top: 0,
      left: 0,
      gbcr:{
        top: 0,
        left: 0,
        width: 0,
        height: 0
      }
    }
  }
  
  let clientX = e.clientX || (e.changedTouches ? e.changedTouches[0] : {clientX: 0}).clientX; //the same syntax for the x value
  let clientY = e.clientY || (e.changedTouches ? e.changedTouches[0] : {clientY: 0}).clientY; //the same syntax for the x value
  // let gbcr = e.target.getBoundingClientRect();
  
  return {
    top: (clientY || 0) - gbcr.top,
    left: (clientX || 0) - gbcr.left,
    gbcr
  }
}
export function getEventPositionFromTouch(e, gbcr){
  if(!e){
    // not an event, return 0 for all
    return {
      top: 0,
      left: 0,
      gbcr:{
        top: 0,
        left: 0,
        width: 0,
        height: 0
      }
    }
  }
  let clientX = (e.targetTouches ? e.targetTouches[0] : {clientX: 0}).clientX; //the same syntax for the x value
  let clientY = (e.targetTouches ? e.targetTouches[0] : {clientY: 0}).clientY; //the same syntax for the x value
  // let gbcr = e.target.getBoundingClientRect();
  // console.log('TOUCH FINDSLOT START', clientX, clientY, gbcr)
  return {
    top: (clientY || 0) - gbcr.top,
    left: (clientX || 0) - gbcr.left,
    gbcr
  }
}