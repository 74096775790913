export const NONE = undefined;// no args merge
export const PUSH = (stack, ...args) => {
  let s = stack || [];
  s.push(...args);
  return s;
}
/**
 * A simple debouncing method
 * @param {Function} func the executive function
 * @param {Object} opts debounce options
 */
export default function debounce(func, opts = {
  wait: 100, // wait 100ms before calling
  maxWait: 1000, // maximum delay before 1st call and effective call
  maxCall: 1000, // max calls before flush exec func (inclusive)
  mergeArgs: NONE, // how to merge calls arguments, can be NONE, PUSH, or a custom function
}) {
  let timeout;
  let stack = null; // stacked calls arguments
  let callsCount = 0;
  let firstCall = 0;
  const { wait, maxWait, maxCall, mergeArgs } = opts;
  return function () {
    let context = this, args = arguments;
    if (typeof mergeArgs === 'function') {
      // stack args
      stack = mergeArgs(stack, ...args);
    } else stack = args;

    let later = function () {
      timeout = null;
      callsCount = 0;
      firstCall = 0;
      // args must be an array...
      func.apply(context, [stack]);
      stack = null;
    };
    // clear precedent timeout if any
    if (timeout) clearTimeout(timeout);
    firstCall = firstCall === 0 ? Date.now() : firstCall;
    if (callsCount >= maxCall || (Date.now() - firstCall) >= maxWait) {
      later();
    } else {
      callsCount++;
      timeout = setTimeout(later, wait);
    }

  };
}