import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PeriodSelector from './inputs/periods/period.selector';
import { isOneOfUserAccounts, getCalendarForEdit } from '../../../reducers/accounts'
import { EventSchemaStep2, isValidDuration } from '../../../validators/event';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import generateTimeFrames, { NEXT_48H } from '../../../utils/gen.timeframes';
import { LinearProgress } from '@material-ui/core';
import { useDialog } from '../../dialogs/dialog.provider';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../../reducers/profile';
import { saveInCache, loadFromCache, getInitialFBDate, clearCache } from '../../../utils/load.proposal.from.cache';
import * as ACTIONS from '../../../actions';
import ROUTES from '../../../routes/routes.name';
import useToolStatus from '../../../hooks/use.tool.status';
// import Success from './success';
import { getUser } from '../../../reducers/user';
import { getDefaultCalendar } from '../../../reducers/accounts';
import { FIREBASE_VALUES } from '../../../middlewares/analytics';
import moment from 'moment';
import useBravo from '../../../hooks/use.bravo';
import Welcome from '../settings/billing/welcome';
import useDevSettings from '../../../hooks/use.dev.settings';
import useOnBehalfOf from '../../../hooks/use.onBehalfOf'
import Preview from './Preview';
import { linearize } from '../../../worker/handlers/generate.slots';
import { v4 } from 'uuid'
import { PREFILL_EDIT } from '../../../reducers/proposals'
import FormRenderer202306, { getShowHeatmapInForm } from './form.renderer.202306';
import { BillingDlg } from './BillingDlg';
import FreeUserExpired from './FreeUserExpired';
import { isMobilePlateform } from '../../../utils/browser';
import './simpleform.scss';
import { getContacts } from '../../../reducers/contacts';
import { hotjar_id } from '../../../middlewares/segment';
import { slot_overlapp, proposalsToSlotsRT } from '../../../worker/handlers/generate.slots';

// import {
//   useABTesting,
//   AB_TESTING_EXPERIMENTS_IDS,
//   AB_SIGN_UP_FLOW_VARIANT_1_SIMPLE_FORM,
//   AB_SIGN_UP_FLOW_VARIANT_1_COMPOSITE_FORM,
// } from '../../../providers/ga.optimize';
import NOOP from '../../../utils/noop';

const dlgOpts = { maxWidth: "lg", fullWidth: true };

export function getDefaultConferenceTool(calendar, tools) {
  if (tools && tools.find((tool) => tool.toolID === 'zoom' && tool.account_id && tool.third_party_status != "error")) return "zoom";
  if (calendar && calendar.supported_conferences && calendar.supported_conferences[0]) return calendar.supported_conferences[0];
  return ''
}

export function remapProposals(proposals, duration, overlapp) {
  // recalculate slots
  let results = [];
  if (proposals && proposals.length > 0) {
    // sort proposals
    proposals = proposals.sort((p1, p2) => p1.start.valueOf() - p2.start.valueOf())
      .filter((p) => p.start && p.end);// ensure valid
    // merge proposals
    let np = [{ ...proposals[0] }];
    let current = 0;
    for (let i = 1; i < proposals.length; i++) {
      let prop = proposals[i];
      let temp = np[current]
      if (slot_overlapp(temp, prop, true)) {
        // merge
        temp.end = prop.end;
      } else {
        // new one
        np.push({ ...prop });
        current++;
      }
    }
    for (let newProps of np) {
      let tmp = proposalsToSlotsRT([newProps], [], duration, false, overlapp);
      if (tmp && tmp.length > 0) results.push(...tmp)
    }

  }
  return results;
}
export default function SimpleForm({
  proposal, // (Optional) form can init from a proposal
  prefillType, // (Optional) type of form prefill (reschedule, follow up, edit...)
  test_donotoverride = false, // for tests
  debounce_delay, // for tests
}) {
  const { t } = useTranslation();
  // const abtest = useABTesting();
  // const use_form_abtest = abtest(AB_TESTING_EXPERIMENTS_IDS.SIMPLE_FORM_2023_05)
  //const use_form_202305 = ( === AB_SIGN_UP_FLOW_VARIANT_1_SIMPLE_FORM);
  const [isLoading, setLoading] = React.useState(false);
  const [lastValue, setLastValue] = React.useState(0);
  const [isGeneratingLink, setisGeneratingLink] = React.useState(false);
  const [canSubmit, setcanSubmit] = React.useState(true);
  const [isInit, setIsInit] = React.useState(true); // by default, app is initializing
  // keep free busy date
  const [initialFreeBusyDate, setInitialFreeBusyDate] = React.useState(moment())

  const { enqueueDialog, closeDialog } = useDialog(dlgOpts);
  const snackbar = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  // form datas
  const user = useSelector(getUser);
  const accounts = useSelector((state) => state.accounts);
  const profile = useSelector(getProfile);
  const defaultCalendar = useSelector(getDefaultCalendar);

  const contacts = useSelector(getContacts);

  const { getDevSettingItem, DEV_FLAGS } = useDevSettings();
  const __dev_heatmap_only_slots = getDevSettingItem(DEV_FLAGS.HEATMAP_USE_ONLY_SLOTS);
  const __dev_july_form = getDevSettingItem(DEV_FLAGS.JULY_FORM);
  const useNewDashboard = !isMobilePlateform();
  // we check user just subscribed and show a welcome popup if needed
  const bravo = useBravo();
  React.useEffect(() => {
    if (bravo) {
      enqueueDialog({
        content: (
          <Welcome doClose={() => closeDialog()} />
        ),
        mustConfirm: true,
        overrideDefaultProps: { maxWidth: 'md' },
        doClose: () => closeDialog()
      });
    }
  }, [bravo]);

  // display toast if there was an error connecting a tool
  useToolStatus();

  // initialize the form data
  const init = React.useMemo(() => {

    // use proposal passed to the form (optional)
    let tmp = proposal;
    let isNewUser = __dev_july_form || (profile && profile.newFormUser);
    let showHeatmapInForm = getShowHeatmapInForm() && isNewUser;
    if (!tmp) {
      // do we have a gmeet/teams or zoom?
      // if yes, auto select

      // check if we have form data saved in cache
      let cache = loadFromCache(true); // get + remove cache data
      // About cache: this is not indepotent (ie call twice will lead to different results
      // as localstorage is deleted ) => Removed strict mode for dev
      if (cache) {
        // Ensure the calendar lodaded from cache is one of user's calendars
        if (isOneOfUserAccounts(accounts, cache.calendar)) {
          setInitialFreeBusyDate(getInitialFBDate(cache))
          // update calendar data
          if (cache.calendar) cache.calendar = getCalendarForEdit(accounts, cache.calendar)
          // check conference is OK
          if (cache.conference) {

            let tools = (profile || {}).tools || [];
            if (!tools.find((t) => {
              return t.toolID === cache.conference
            })) {
              console.log("Clear conference")
              cache.conference = "";
            }
          }
          // get the latest "overlapp" value from profile?
          cache.overlapp = profile.overlapp;
          if (showHeatmapInForm) cache.currenttimeframe = -1; // force heatmap
          // if no conference was set, select default
          if (!cache.conference) cache.conference = getDefaultConferenceTool(cache.calendar, profile.tools)

          return cache;
        } else {
          console.debug("Inconsistent data loaded from cache")
        }
      }
      // return default empty form data
      const conference = getDefaultConferenceTool(defaultCalendar, profile.tools)
      return {
        title: '',
        important: false,
        location: '',
        conference: conference,
        duration: 60,
        customduration: 15,
        currenttimeframe: showHeatmapInForm ? -1 : 0, // note: depend on do we display in heatmap?
        reminders: ['15'],
        notes: '',
        calendar: defaultCalendar,
        organizer: user,
        invitees: [],
        inviteesDetails: {},
        proposals: [],
        duringWH: true,
        slots: [],
        generateLink: false, // if true, generate a link only
        holdSlots: false,
        ignore_initial_avail: false,
        vote_required: false,
        overlapp: profile.overlapp, // default, no overlapp?
      };
    }
    // form is in edit mode
    if (prefillType === PREFILL_EDIT) {
      tmp.prefill_type = PREFILL_EDIT; // save it for later and reload
      tmp.__originalProposal = Object.assign({}, proposal); // used to compute the diff when needed (recap)
      // set time frame to custom
      tmp.currenttimeframe = -1;
      // recreate proposals from remainings
      // if degraded (ie: funnel started), use slots only
      let funnelStarted = !!Object.values(tmp.inviteesDetails || {}).find((i) => i.response === 'yes')
      tmp.proposals = linearize(funnelStarted ? tmp.slots : tmp.remaining_slots).map((slot) => {
        slot.start = moment(slot.start);
        slot.end = moment(slot.end);
        slot.uuid = v4();
        return slot;
      });
      if (tmp.proposals.length > 0) setInitialFreeBusyDate(tmp.proposals[0].start.clone())
      // calendar
      tmp.calendar = getCalendarForEdit(accounts, tmp.calendar)

      tmp.vote_required = false; // do not use global vote required (new form)

      tmp.organizer = user;
      return tmp;
    }

    let wh = (profile.enabled) ? profile.workingHours : false;

    tmp.organizer = user;
    tmp.calendar = defaultCalendar;
    if (!test_donotoverride && !showHeatmapInForm) {
      tmp.currenttimeframe = 0; // next 2 day
      tmp.proposals = generateTimeFrames(NEXT_48H, wh);
    }
    return tmp;
  }, [proposal, prefillType, profile])

  // get invitees profiles
  React.useEffect(() => {
    if (init && init.invitees && init.invitees.length > 0) {
      dispatch({
        type: ACTIONS.WORKER_GET_PROFILES,
        payload: init.invitees,
      })
    }
  }, [init]);

  const [isEditing, hasFunnelStarted] = React.useMemo(() => {
    let isEditing = init.prefill_type === PREFILL_EDIT;
    let funnel = isEditing && !!Object.values(init.inviteesDetails || {}).find((i) => i.response === 'yes')
    return [isEditing, funnel]
  }, [init]);

  const formik = useFormik({
    initialValues: init,
    validationSchema: EventSchemaStep2,
    onSubmit: (values, ...args) => {
      /* istanbul ignore if no work */
      if (isGeneratingLink) {
        return;
      }
      let opts = values.opts || {};
      let slots = opts.forced_slot ? [{ slots: [opts.forced_slot] }] : values.slots;
      let forcePoll = opts.forcePoll ? 'poll' : 'funnel';
      /* istanbul ignore if no work */
      if (!slots || slots.length === 0) {
        // no valid
        formik.setSubmitting(false)
        return false;
      }

      // check number of slots
      let isProposal = slots.length === 1 ?
        "createEvent.form.sendingInvite" :
        "createEvent.form.sendingOptions";

      setisGeneratingLink(isProposal);

      let p = Promise.resolve(true);

      const linearizedSlots = linearize(slots);
      const nbSlots = linearizedSlots.length;
      const prefill_type = values.prefill_type;
      let vote_required = nbSlots > 1 && values.vote_required;
      p.then(() => {
        // perform save/edit proposal
        dispatch({
          type: prefill_type === PREFILL_EDIT ? ACTIONS.WORKER_EDIT_PROPOSAL : ACTIONS.WORKER_SAVE_PROPOSAL,
          payload: JSON.stringify({
            id: init.id,
            title: values.title,
            proposal_type: forcePoll,
            important: values.important,
            ignore_initial_avail: values.currenttimeframe === -1,
            location: values.location,
            conference: values.conference,
            duration: values.duration,
            customduration: +values.customduration,
            reminders: values.reminders,
            notes: values.notes,
            calendar: values.calendar,
            organizer: values.organizer,
            invitees: values.invitees,
            vote_required: vote_required,
            inviteesDetails: values.inviteesDetails,
            proposals: values.proposals,
            slots: slots,
            currenttimeframe: values.currenttimeframe,
            duringWH: values.duringWH,
            sendEmail: !values.generateLink,
            holdSlots: values.holdSlots,
          }),
          resolvers: {
            resolveOn: ACTIONS.WORKER_SAVE_PROPOSAL_SUCCESS,
            rejectOn: ACTIONS.WORKER_SAVE_PROPOSAL_ERROR
          }
        })
          .then((dt = {}) => {
            // Clear LS now
            clearCache();
            let creatingEvent = slots && slots.length === 1
              && slots[0].slots
              && slots[0].slots.length === 1;

            dispatch({
              type: (creatingEvent ? ACTIONS.ANALYTICS_MEETING_CREATED : prefillType === PREFILL_EDIT ? ACTIONS.ANALYTICS_PROPOSAL_UPDATED : ACTIONS.ANALYTICS_PROPOSAL_CREATED),
              payload: {
                proposal: values,
              }
            });
            if (!creatingEvent) {
              // log invite sent
              dispatch({
                type: ACTIONS.ANALYTICS_INVITE_SENT,
                payload: {
                  invitedEmails: (values && values.invitees),
                  source: FIREBASE_VALUES.INVITE_SENT_FROM_PROPOSAL,
                }
              })
            }
            // redirect to meeting page
            let payload = dt.payload || {};
            // merge proposals details
            payload.form_invitees_details = values.inviteesDetails;
            // custom slots infos
            payload.form_slots = {
              total: nbSlots,
              timeframe: values.currenttimeframe,
            }
            /* istanbul ignore if no link */
            if (!creatingEvent) {
              let route = ROUTES.CREATE_A_MEETING_LINK;
              history.push(route, { proposal: payload, type: prefill_type });

            } else {
              // return directly to dashboard
              let route = ROUTES.APP;
              if (!useNewDashboard) route += "?id=" + payload.id;
              history.push(route);
            }
          })
          .then(() => {
            hotjar_id(user, { "mf_submitted": true });
          })
          .catch(
            /* istanbul ignore next no notistack */
            (err) => {
              console.error("Error submitting form: ", err)
              // show an error popup
              if (err && err.status === 402) {
                const unblock = history.block();
                const doClose = () => {
                  unblock();
                  closeDialog();
                };
                // need billing for this, show dialog
                enqueueDialog({
                  content: <BillingDlg values={formik.values} />,
                  doClose: doClose,
                  overrideDefaultProps: {}
                });
                return;
              }
              // show an error popup
              if (snackbar) snackbar.enqueueSnackbar(t('createEvent.form.recap.sendError'), { variant: 'error' })
            }).then(() => setisGeneratingLink(false));
      }).catch((err) => { console.error("Error submitting form: ", err) });
    }
  });

  const handleFormSubmit = React.useCallback((opts) => {
    if (opts) {
      formik.setFieldValue("opts", opts)
    }
    formik.handleSubmit()
  }, [formik.setFieldValue, formik.handleSubmit]);

  const oboCalendar = useOnBehalfOf(formik.values.calendar);

  // load user profile and update formik
  React.useEffect(() => {
    if (!user.isAuth) return;
    setLoading(true);
    dispatch({
      type: ACTIONS.WORKER_REFRESH_PROFILE,
      resolvers: {
        resolveOn: ACTIONS.WORKER_REFRESH_PROFILE_SUCCESS,
        rejectOn: ACTIONS.WORKER_REFRESH_PROFILE_ERROR,
      }
    })
      .then((dt) => {
        if (getShowHeatmapInForm()) {
          if (dt && dt.payload && (__dev_july_form || dt.payload.newFormUser))
            return;
        }
        // if init got some datas, we should not generate new timeframes
        // and check for the tools we got (if any)
        // to allow for any type of meeting, comment !prefillType....
        if (dt && dt.payload && !init.conference && !prefillType) {
          let profile = dt.payload;
          const conference = getDefaultConferenceTool(init.calendar, profile.tools)
          formik.setFieldValue('conference', conference)
        }
        /* istanbul ignore if no work */
        if (init.proposals && init.proposals.length > 0) return;
        if (dt && dt.payload) {
          let profile = dt.payload;
          if (profile) {
            let wh = (profile.enabled) ? profile.workingHours : false;

            let proposals = generateTimeFrames(NEXT_48H, wh);
            formik.setFieldValue('currenttimeframe', 0)
            formik.setFieldValue('proposals', proposals)

          }
        } else {
          // use by default
          let proposals = generateTimeFrames(NEXT_48H);
          formik.setFieldValue('currenttimeframe', 0)
          formik.setFieldValue('proposals', proposals)
        }
      })
      .catch((err) => {
        console.log(err)
      }).then(() => {
        setLoading(false);
      })
  }, [/*user.isAuth*/]);

  // change TZ for calendar
  React.useEffect(() => {
    // on load, must NOT change hour of proposals and slots
    let tz = profile.timezone;
    if (!formik.values.calendar) return;
    if (formik.values.calendar && oboCalendar) {
      tz = formik.values.calendar.timezone
    }
    if (!tz) return;
    if (moment().tz() === tz) return;
    // change app tz
    moment.tz.setDefault(tz);
    setInitialFreeBusyDate(moment()); // recompute optional or convert
    // send to worker too
    dispatch({
      type: ACTIONS.WORKER_SET_TZ,
      payload: {
        timezone: tz
      }
    });
    // remove slots if any, they will be recalculated
    formik.setFieldValue("slots", undefined);
    // if got proposals, recalculate with timezone
    if (formik.values.proposals && formik.values.proposals.length > 0) {
      formik.setFieldValue("proposals", // []
        formik.values.proposals.map((p) => {
          let st = moment(p.start.format());
          let ed = moment(p.end.format());
          if (!isInit) {
            st = st.days(p.start.days())
            st = st.hours(p.start.hours());
            st = st.minutes(p.start.minutes());
            ed = ed.days(p.end.days())
            ed = ed.hours(p.end.hours());
            ed = ed.minutes(p.end.minutes());
          }
          return {
            ...p,
            geometry: undefined,
            start: st,
            end: ed,
          }
        })
      );
      setIsInit(false);
    }
  }, [formik.values.calendar, oboCalendar, profile.timezone, isInit]);

  const saveFormInCache = React.useCallback((confId) => {
    // save content of page
    let values = {
      ...formik.values
    };
    values.location = "";
    if (confId) values.conference = confId;
    saveInCache(values);
  }, [formik.values])
  // each time form change, save in cache
  React.useEffect(() => {
    saveInCache(formik.values);
  }, [formik.values])
  // after touched a component, send the "start form" event in hotjar
  React.useEffect(() => {
    hotjar_id(user, { "mf_started": true })
  }, [Object.keys(formik.touched).length > 0, user])


  const [mustShowWarningPopup, setMustShowWarningPopup] = useState(false);

  // show heatmap
  const showcustomSlotsDlg = React.useCallback((clean, opts = {}) => {
    // add current data in closure cache
    let cache = {
      currenttimeframe: formik.values.currenttimeframe,
      values: clean ? opts.values : formik.values.proposals
    }

    const doCancel = () => {
      // reset
      formik.setFieldValue('currenttimeframe', cache.currenttimeframe, false);
      closeDialog();
    }
    const doClose = (p, lastSelectedDate, ignore_initial_avail = false) => {
      // check p and if p is same object (ie: no changes)
      if (!p || p.length === 0 || p === cache.values) {
        return doCancel();
      }

      let inviteesDetails = formik.values.inviteesDetails || {};
      let invitees = formik.values.invitees || [];

      // check if all guests' availability is known
      const allGuestsAvailabilityIsKnown = invitees.filter(invitee => !(contacts[invitee] || {}).isDoner).length === 0;
      // in this special case, switch all registered/hybrids to "don't use known availability"
      if (allGuestsAvailabilityIsKnown) {
        for (let inviteesEmail of invitees) {
          inviteesDetails[inviteesEmail] = {
            ...inviteesDetails[inviteesEmail],
            vote_requested: true
          }
        }
        formik.setFieldValue("inviteesDetails", inviteesDetails, false);
      }
      // ensure custom period is selected
      formik.setFieldValue('currenttimeframe', -1, false)
        .then(() => formik.setFieldValue('proposals', p));
      setInitialFreeBusyDate(lastSelectedDate)
      setMustShowWarningPopup(true);
      closeDialog();
    };


    // del optional invitees from list
    let details = formik.values.inviteesDetails || {};
    let invitees = formik.values.invitees.filter((inv) => {
      let d = details[inv] || {};
      return !d.optional;
    })
    const duration = formik.values.duration === -1 ? formik.values.customduration : formik.values.duration;
    enqueueDialog({
      content: <PeriodSelector title={formik.values.title} duration={duration}
        invitees={invitees}
        inviteesDetails={details}
        organizer={formik.values.organizer}
        calendar={formik.values.calendar}
        values={clean ? opts.values : formik.values.proposals}
        onValid={doClose} onClose={doCancel}
        initial={opts.initialDate || initialFreeBusyDate}
        oboCalendar={oboCalendar}
        isImportant={formik.values.important}
        ignoreInitialAvail={true /*formik.values.ignore_initial_avail*/}
        heatmap_slots_only={__dev_heatmap_only_slots}
        allowMultipleSlots={opts.allowMultipleSlots}
        overlapp={formik.values.overlapp}
      />,
      className: "no-overflow theme--light ",
      overrideDefaultProps: { maxWidth: "lg", fullWidth: true, className: "create-event-heatmap" },
      doClose: doCancel,
    })
  }, [enqueueDialog, closeDialog, formik.values.title, formik.values.duration, formik.values.customduration,
    formik.values.organizer, formik.values.ignore_initial_avail, formik.values.invitees, formik.values.inviteesDetails, formik.values.proposals,
    initialFreeBusyDate, setInitialFreeBusyDate, oboCalendar, formik.values.calendar
  ]);

  // user or calendars change, refresh
  React.useEffect(() => {
    // clear all errors?
    /* istanbul ignore if no user is not mandatory */
    if (user) formik.setFieldValue('organizer', user, false);
    if (defaultCalendar && !formik.values.calendar) formik.setFieldValue('calendar', defaultCalendar, false);
  }, [user, defaultCalendar]);

  // DO NOT ACCEPT WITHOUT A TEST!
  React.useEffect(() => {
    if (formik.values.calendar && formik.values.currenttimeframe !== -1) {
      let whours = profile.workingHours;
      let tz = profile.timezone;

      if (oboCalendar != "") {
        whours = formik.values.calendar.working_hours;
        tz = formik.values.calendar.timezone;
      }
      let proposals = generateTimeFrames(formik.values.currenttimeframe, whours, moment(), tz);
      formik.setFieldValue('proposals', proposals)
    }
  }, [formik.values.calendar, formik.values.currenttimeframe, oboCalendar, profile.workingHours]);

  // if got an error, scroll to it
  React.useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      let name = Object.keys(formik.errors)[0];
      if (name === 'invitees') name = "search-contact"
      let input = document.getElementsByName(name)[0];
      if (input && input.scrollIntoView) {
        // focus on first error?
        input.focus();
        input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }

    }
  }, [formik.isSubmitting, formik.errors]);



  const handleTitlePredicitiveChange = React.useCallback((e) => {
    // populate form from proposal
    // remove unmeaningfull data
    let isNewUser = __dev_july_form || (profile && profile.newFormUser);
    let showHMInForm = isNewUser && getShowHeatmapInForm()
    if (e && e.title) {
      // remove invitees & proposals
      let wh = (profile.enabled) ? profile.workingHours : false;
      // must force refresh of data
      formik.setValues({
        ...formik.values,
        "title": e.title,
        "important": e.important,
        "ignore_initial_avail": false, // ??? e.ignore_initial_avail,
        "location": e.location, // !e.conference ? e.location : "",
        "conference": e.conference,
        "notes": e.notes,
        "calendar": getCalendarForEdit(accounts, e.calendar) || e.calendar,
        "duration": e.duration,
        "customduration": e.customduration,
        "currenttimeframe": showHMInForm ? -1 : 0,
        "proposals": showHMInForm ? [] : generateTimeFrames(NEXT_48H, wh),
        "invitees": [...(e.invitees || [])],
        "inviteesDetails": copyGuests(e.inviteesDetails),
        "slots": [],
      }, true);
      dispatch({
        type: ACTIONS.WORKER_GET_PROFILES,
        payload: e.invitees || [],
      })
    } else formik.handleChange(e); // there might be a better way than triggering
    formik.setFieldTouched("title", true)
  }, [formik.values, formik.handleChange, formik.setValues, profile])

  // const showPreview = React.useCallback((options = {}) => {
  //   // show dialog?

  // }, [formik.values, enqueueDialog, closeDialog]);
  const showPreview = React.useCallback((options = {}) => {
    // show dialog?
    if (options.showAsDialog) {
      enqueueDialog({
        content: <Preview proposal={formik.values} options={options} doClose={() => closeDialog()} />,
        className: "asDialog",
        overrideDefaultProps: { scroll: 'paper' }
      });
    }
    return <Preview proposal={formik.values} options={options} doClose={NOOP} />
  }, [formik.values, enqueueDialog, closeDialog]);

  const showBillingPopup = React.useCallback(() => {
    // save content of page
    saveInCache(formik.values);
    history.push(ROUTES.APP_SETTINGS_BILLING, { backTo: ROUTES.CREATE_A_MEETING });
  }, [formik.values])

  const handleToggleInviteeOptional = React.useCallback((mail) => {
    let d = formik.values.inviteesDetails || {};
    let ed = d[mail] || {};
    ed.optional = !ed.optional;
    d[mail] = { ...ed };
    formik.handleChange({
      target: {
        name: "inviteesDetails",
        value: {
          ...d
        }
      }
    });
  }, [formik.values.inviteesDetails, formik.handleChange]);
  const handleToggleInviteeVoteRequested = React.useCallback((mail) => {
    let d = formik.values.inviteesDetails || {};
    let ed = d[mail] || {};
    ed = { ...ed };
    ed.vote_requested = !ed.vote_requested;
    d[mail] = { ...ed };
    formik.handleChange({
      target: {
        name: "inviteesDetails",
        value: {
          ...d
        }
      }
    }); // there might be a better way than triggering

  }, [formik.values.inviteesDetails, formik.handleChange]);
  const hndChange = React.useCallback((e, shouldValidate = false) => {
    formik.handleChange(e);
    formik.setFieldTouched(e.target.name, true)
    // formik change AND dispatch changes
    let custom = e.target.custom;
    /* istanbul ignore if no yet used */
    if (custom) {
      formik.handleChange({ target: custom }); // there might be a better way than triggering
    }
    let { name } = e.target;
    formik.setFieldTouched(name, true, shouldValidate)
    if (__dev_heatmap_only_slots && formik.values.currenttimeframe === -1
      && (name === "duration" || name === "customduration")) {
      // if duration change, reset proposals
      formik.setFieldValue('proposals', []);
    }

  }, [formik.handleChange, formik.setFieldTouched, formik.setFieldValue, __dev_heatmap_only_slots, formik.values.currenttimeframe]);
  const hndDurationChange = React.useCallback((e) => {
    let { name, value } = e.target;
    formik.setFieldValue(name, value, true)
      .then(() => {
        formik.setFieldTouched(e.target.name, true);
        //formik.setFieldTouched(name, true, shouldValidate)
        if (__dev_heatmap_only_slots && formik.values.currenttimeframe === -1
          && (name === "duration" || name === "customduration")) {
          if (getShowHeatmapInForm()) {
            // if duration change, reset proposals
            // Test: try to recreate proposals from old ones
            let proposals = formik.values.proposals;
            // custom params needed
            let duration = value === -1 ? formik.values.customduration : value;
            if (!isValidDuration(duration)) return;
            let results = remapProposals(proposals, duration, formik.values.overlapp)
            formik.setFieldValue('proposals', results);
          } else {
            formik.setFieldValue('proposals', []);
          }
        }
      });

  }, [formik.handleChange, formik.setFieldTouched, formik.setFieldValue, __dev_heatmap_only_slots, formik.values.currenttimeframe,
  formik.values.proposals, formik.values.overlapp, formik.values.customduration]);
  // on customduration/duration change, we reset proposals (dumb, but we will try harder next time)
  const handleCalendarChange = React.useCallback((e) => {
    // if got proposals, change timezone if needed
    formik.handleChange(e);
    // check if got some meeting?
    // if choose a provider-depend one
    if (formik.values.conference != "zoom" && formik.values.conference != "") {
      const conference = getDefaultConferenceTool(e.target.value, profile.tools);
      if (conference) {
        formik.setFieldValue("conference", conference, false)
      }
    }
  }, [formik.handleChange, formik.values.conference, profile.tools]);

  const hndChangeAndDispatch = React.useCallback((e) => {
    let { value } = e.target;
    // change ui value
    // if same value as before
    if (value === null || value === formik.values.currenttimeframe) {
      if (formik.values.currenttimeframe === -1) {
        // trigger form validation,
        // if no error, process
        return formik.validateForm()
          .then((err) => {
            let errs = Object.keys(err);
            if (errs.length > 0
              && !isAllowedError(err)) { // we allow proposals empty for this action
              // error
              formik.setErrors(err)
              return Promise.reject(err);
            }
          }).then(() => {
            // show dialog
            showcustomSlotsDlg(false);
          })
          .catch((err) => { })

      }
      return;
    } else {
      // value changed, reinit ignore
      formik.setFieldValue('ignore_initial_avail', false);
    }
    // if value is custom time, must check first
    let p = Promise.resolve();
    if (e.target.value === -1) {
      // validate before processing
      // clear all frames
      formik.setFieldValue("proposals", [], false)
      p = formik.validateForm()
        .then((err) => {
          let errs = Object.keys(err);
          if (errs.length > 0
            && !isAllowedError(err)) { // we allow proposals empty for this action
            formik.setErrors(err)
            return Promise.reject(err);
          }
        });
    }
    p.then(() => {
      formik.handleChange(e); // there might be a better way than triggering
      formik.setFieldTouched(e.target.name, true)
      // let wh = (profile.enabled && formik.values.duringWH) ? profile.workingHours : false;
      // let tz = '';
      // if (oboCalendar !== '') {
      //   wh = formik.values.calendar.workingHours; // Null result to default
      //   tz = formik.values.calendar.timezone; // default will result in user one
      // }
      // save
      setLastValue(formik.values.currenttimeframe);
      // generate new frames
      if (e.target.value === -1) {
        // Show dialog
        showcustomSlotsDlg(true);
      }
    }).catch((err) => {
      // form do not validate!
      console.error('No validate:', err)
    })
  }, [formik.values, formik.validateForm, formik.setFieldValue, formik.handleChange, profile, oboCalendar]);

  const doConnectContacts = React.useCallback(() => {
    // save form
    saveFormInCache()
    // redirect to settings
    history.push(ROUTES.APP_SETTINGS_ACCOUNTS);
  }, [saveFormInCache]);

  const setAttendeesStatus = React.useCallback((status = [], forbiddens = []) => {
    // get a list of emails for forced attendees
    let atts = formik.values.inviteesDetails || {};
    let inv = formik.values.invitees || [];
    let detailed = formik.values.inviteesDetails || {};
    for (let att of inv) {
      // optional are not compute here, keep old values
      let detail = detailed[att] || {}
      if (detail.optional) {
        atts[att] = detail
      } else {
        let isForced = status.includes(att);
        let isForbidden = forbiddens.includes(att)
        atts[att] = {
          ...detailed[att],
          isForced,
          isForbidden,
        }
      }

    }
    // same with forbiddens attendees
    formik.setFieldValue("inviteesDetails", atts, false);
    //formik.values.inviteesDetails = atts;
  }, [formik.values.invitees, formik.values.inviteesDetails]);

  const freeUserExpired = useMemo(() => {
    if (profile.billing && !profile.billing.customer_id && profile.monthly_quota_remaining <= 0) {
      return <FreeUserExpired profile={profile} showBillingPopup={showBillingPopup} />
    }
  }, [profile, showBillingPopup])

  const cancelEditing = () => {
    clearCache();
    history.go(0);
  };

  if (isLoading) return (
    <LinearProgress />
  );
  return (
    <FormRenderer202306 isEditing={isEditing}
      hasFunnelStarted={hasFunnelStarted} cancelEditing={cancelEditing}
      formik={formik}
      profile={profile}
      canSubmit={canSubmit}
      setcanSubmit={setcanSubmit}
      oboCalendar={oboCalendar}
      showPreview={showPreview}
      isGeneratingLink={isGeneratingLink}
      freeUserExpired={freeUserExpired}
      handleCalendarChange={handleCalendarChange} handleTitlePredicitiveChange={handleTitlePredicitiveChange}
      setAttendeesStatus={setAttendeesStatus} handleToggleInviteeOptional={handleToggleInviteeOptional}
      handleToggleInviteeVoteRequested={handleToggleInviteeVoteRequested} hndChange={hndChange} hndDurationChange={hndDurationChange}
      doConnectContacts={doConnectContacts} hndChangeAndDispatch={hndChangeAndDispatch}
      saveFormInCache={saveFormInCache} showcustomSlotsDlg={showcustomSlotsDlg}
      handleFormSubmit={handleFormSubmit}
      user={user}
      accounts={accounts}
      mustShowWarningPopup={mustShowWarningPopup}
      setMustShowWarningPopup={setMustShowWarningPopup}
      debounce_delay={debounce_delay}
      initialFreeBusyDate={initialFreeBusyDate}
      proposalId={init.id} />);


};

// ******************************************** 
// ************** Local Utils ***************** 
// ********************************************  


const ALLOWED_ERRORS = { "slots": 1, "proposals": 1, "title": 1, "invitees": 1 };
const isAllowedError = (error) => {
  // change: allow all errors to open the heatmap
  // except custom duration
  return Object.keys(error).reduce((acc, errorKey) => {
    return (ALLOWED_ERRORS[errorKey] === 1) && acc;
  }, true)
}

/**
 * Make fresh attendees from a known list of attendees, with no slots and no reponse.
 * @param {{'guest-email1': {...}, 'guest-email2': {...} }} guests objects
 * @returns same guests as in param but with no response
 */
function copyGuests(guests = {}) {
  let tmp = {};
  for (let k of Object.keys(guests)) {
    tmp[k] = {
      ...guests[k],
      response: 'no',
      slots: [],
      __cpy: v4() // to ensure unicity
    }
  }
  return tmp;
}