// creation of an event in multi-step form
import { handleActions } from 'redux-actions';
import * as ACTIONS from '../actions';
// import generateTimeFrames, { NEXT_48H } from '../utils/gen.timeframes';
import {
  CREATE_EVENT_STEP_1,
  WORKER_GEN_SLOTS_SUCCESS
} from '../actions';
import ROUTES from '../routes/routes.name';
import {
  EventSchemaCalendar,
  EventSchemaStep2
} from '../validators/event';
import { removeTooSmall } from '../utils/remove.too.small';

const VALIDATORS = {
  [ROUTES.CREATE_A_MEETING_ADD_GUESTS]: {
    validate: EventSchemaCalendar,
    redirect: ROUTES.CREATE_A_MEETING
  },
  [ROUTES.CREATE_A_MEETING_SEND_INVITE]: {
    validate: EventSchemaStep2,
    redirect: ROUTES.CREATE_A_MEETING
  },
}
const defaultState = {};
const reducer = handleActions(
  {
    [ACTIONS.WORKER_CREATE_EVENT_INIT]: () => {
      return defaultState;
    },
    // 'REINIT_CREATE_EVENT_FORM': () => {
    //   console.log('check Reinit form datas')
    //   return defaultState;
    // },
    [ACTIONS.WORKER_LOGOUT]: () => defaultState,
    [CREATE_EVENT_STEP_1]: (state, action) => {
      let payload = action.payload;
      // if customduration, ensure it is a number
      if (payload.customduration) payload.customduration = +payload.customduration;

      // check if duration has changed
      let oldDuration = state.duration > 0 ? state.duration : state.customduration;
      let newDuration = payload.duration > 0 ? payload.duration : payload.customduration;
      if (oldDuration && newDuration && newDuration > oldDuration) {
        // need to remove too small periods*/
        payload.proposals = removeTooSmall(payload.proposals || state.proposals, newDuration);
        // state.proposals = [...payload.proposals];
      }
      let ns = {
        ...state,
        ...payload
      };
      return ns;
    },
    [WORKER_GEN_SLOTS_SUCCESS]: (state, action) => {
      return {
        ...state,
        slots: JSON.parse(action.payload).slots
      }
    },

    '__DEV__CONNECTED': (state) => ({})
  },
  defaultState
);
export default reducer;

// const defaultProposals = generateTimeFrames(NEXT_48H);

/**
 * simply get event datas
 */
export function getEventFormDatas(state) {
  let c = state.event;
  if (Object.keys(c).length > 0) return c;

}
/**
 * check if can access to this path
 * if true, return path
 * if false, return max valid pah
 */
export function getValidPathname(path) {
  return (state) => {
    let event = state.event;
    if (VALIDATORS[path]) {
      // route exists, check if datas are valid for this step
      //for(let k of Object.keys(VALIDATORS)){

      let v = VALIDATORS[path];
      if (v.validate && !v.validate.isValidSync(event)) {
        return v.redirect;
      }
      //}

    }
    return path;
  }
}

