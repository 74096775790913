import logger from '../../logger';
import * as ACTIONS from '../../actions';
import freeBusy from './free.busy';
import moment from 'moment'
import { periodToSlots, deduplicateBusySlots } from '../../utils/period.to.slots';
import { v4 } from 'uuid';
import { getDiff } from '../../utils/to.offset';
import { hasUnknownInvitees } from '../../utils/has.unknown.attendees';
import { getPollInfo } from '../../api/poll.info';
import findFirstSlot from '../../utils/find.first.slot'
const USE_IMPROVED = true;

export default (event, cllbck, store) => {
  let { type, payload, workerID, requestId, options = {} } = event;
  // check only what interest me in formdatas
  if (!payload || !payload.invitees || !payload.proposals || !payload.duration) return cllbck({
    type: ACTIONS.WORKER_GEN_SLOTS_ERROR,
    reason: 'no invitees or periods or duration',
    workerID: workerID,
    requestId: requestId
  });
  // God mod requested
  const god_mod = payload.use_god_mod || false;
  // New: do we use the "global" vote requested?
  const useGlobalVoteRequested = payload.useGlobalVoteRequested; // defaut, yes

  // get freeBusy for invitees
  // get extrem start and end for periods
  // divide to weeks to get from cache?
  let period = payload.proposals.reduce((acc, v) => {
    let { start, end } = v;
    if (!acc.start || acc.start > start) acc.start = start;
    if (!acc.end || acc.end < end) acc.end = end;
    return acc;
  }, { start: false, end: false });

  // get free busy
  let busycllbck = (result) => {
    if (result.type === ACTIONS.WORKER_FREE_BUSY_ERROR) {
      // cannot load busy, return datas
      result = {};
      // return cllbck({...result, type: ACTIONS.WORKER_GEN_SLOTS_ERROR, workerID: workerID});
    }
    // calculate slots
    let d = payload.duration > 0 ? payload.duration : payload.customduration;
    // overlapp: if -1 or less than duration, pass
    let overlapp = payload.overlapp || -1;
    if (overlapp > d) overlapp = -1;

    // force meeting: has proposals will be re-sized with busys, I need to save some informations
    // before computing
    let force_infos = {
      total: 0,
      slot: null
    }
    // final slot is the first one
    let final_slot = undefined;


    if (payload.proposals.length === 1) {
      let sl = payload.proposals[0] || { start: moment(), end: moment() };
      force_infos.total = payload.proposals.length;
      force_infos.slot = {
        start: moment(sl.start).clone(),
        end: moment(sl.end).clone(),
      }
    }
    // substract busy from proposals then generate slots
    let slots, forcedAttendees = [];

    if (payload.ignore_initial_avail) {
      let tmp = getSlotsAndForcedAttendees(payload.proposals, d, result.payload, overlapp); // no busys are kept
      slots = tmp.slots;
      forcedAttendees = tmp.forcedAttendees;
      // check for forced attendees
    } else {
      // delete slots based on FB
      slots = USE_IMPROVED ? getPossibleSlotsImproved(payload.proposals, d, result.payload, overlapp) : getPossibleSlots(payload.proposals, d, result.payload)
    }
    // remove check if all invitees are MM users, if so,
    // slots = slots[0];
    let invitees = payload.invitees;
    let contacts = store.profiles || {}; // set as store main datas
    // 1st invitee is me, do ot take into account

    let allDoners = true;
    // change, if we had a 403 in request, not all invitees are doners

    for (let i = 1; i < invitees.length; i++) {
      let invitee = contacts[invitees[i]]
      if (!(invitee && invitee.email)) { // need to check email because of cache
        allDoners = false;
        break;
      }
    }
    // some do not give FB, do not calculate 1 slot only
    if (result.forbiddens.length > 0) allDoners = false;
    if (god_mod && !hasUnknownInvitees(invitees)) {
      // Rule: always calculate the first slot except if some are unknwon
      // we do not check for optional as they are already removed from request on WORKER_GEN_SLOT call (period/index.js)
      final_slot = findFirstSlot(slots)
    } else {
      // historical: if only doners, reduce to first one
      if (allDoners && slots.length > 0) {
        // keep only first one, look for results
        let sl = slots.find((sl) => sl.slots && sl.slots.length > 0) || slots[0];
        sl.slots = sl.slots.length > 0 ? [sl.slots[0]] : [];
        slots = [sl];
      }
    }
    // map for rendering as a tableau
    let max_row = 0;
    let total = slots.reduce((acc, slot) => {
      if (slot.slots.length > max_row) max_row = slot.slots.length;
      return acc + slot.slots.length;
    }, 0);

    // do we force slots?
    let isForced = false;
    if (payload.forceSlot
      && total === 0
      && force_infos.total === 1) {
      // only permit if exact match?
      let { start, end } = force_infos.slot;
      if (end.clone().subtract(start.valueOf(), 'ms').valueOf() === (d * 60 * 1000)) {
        // only 1 proposal, calculate forced slot?
        let p = force_infos.slot;
        let start = moment(p.start);
        // get overlapping FB
        let tmp = {
          start: start.clone(),
          end: start.clone().add(d, 'minutes'),
        };

        let busy = (result.payload || []).filter((b) => {
          let bstart = moment(b.start);
          let bend = moment(b.end);
          if (bstart <= tmp.end && bend >= tmp.start) {
            // overlap, end
            return true;
          }
          return false;
        }).reduce((acc, b) => ({ ...acc, user: { ...acc.user, ...b.user } }), {});
        let forced_slot = {
          day: start.clone().startOf('day'),
          slots: [
            {
              // start: start.clone(),
              // end: start.clone().add(d, 'minutes'),
              ...tmp,
              isForced: true,
              busy
            }
          ]
        }
        // change
        slots = [forced_slot];
        total = 1;
        isForced = true;
        max_row = 1; // for display
      } else {
        console.debug('Force meeting abort: too long')
      }
    }

    // get PollInfo
    var pollInfo = undefined;
    return getPollInfo(payload, slots, forcedAttendees, result.forbiddens, store, useGlobalVoteRequested)
      .then((info) => {
        pollInfo = info;
      }).catch(() => {
        // assume no info, will be a funnel
        pollInfo = { will_turn_to_poll: false };
      }).then(() => {
        return cllbck({
          type: ACTIONS.WORKER_GEN_SLOTS_SUCCESS,
          // note: always send back informations from request
          payload: JSON.stringify({
            slots, total: total, max_row: max_row, allDoners,
            final_slot, // god mod
            // forced slots related
            isForced,
            forcedAttendees,
            forbiddens: result.forbiddens,
            pollInfo,
          }),// to clean moment.js datas
          workerID: workerID,
          requestId: requestId
        });
      })

  }
  // load free busy datas
  // do not check start and end of week, this needs localisation datas
  // only check from 1st start to last end period
  // get extras infos from store?
  // if (payload.ignore_initial_avail) {
  //   // do not check for FB
  //   return busycllbck({
  //     type: ACTIONS.WORKER_FREE_BUSY_SUCCESS,
  //     payload: []
  //   });
  // }

  return freeBusy({
    payload: {
      invitees: payload.invitees,
      period: {
        start: moment(period.start)/*.startOf('week')*/.toISOString(),
        end: moment(period.end)/*.endOf('week')*/.toISOString()
      },
      cal_id: payload.cal_id,
      important: payload.important,
    },
    options: {
      loadProfileIfUnknown: !!options.loadProfileIfUnknown
    }
  }, busycllbck, store);
}
// 1st calculus: gen slots then discard overlapping busy
function getPossibleSlots(proposals, duration, busys) {
  let slots = periodToSlots(proposals, duration);
  return removeBusySolts(slots, busys)
}

// 2nd calculus: substract busy from proposals then gen slots
function getPossibleSlotsImproved(proposals, duration, busys, overlapp) {
  // console.log('[ENHANCE] before:', JSON.stringify(proposals), JSON.stringify(busys))
  return periodToSlots(substractBusyFromProposals(proposals, busys), duration, false, false, overlapp);
}
export function getSlotsAndForcedAttendees(proposals, duration, busys, overlapp) {
  let sl = periodToSlots(proposals, duration, false, false, overlapp);
  return {
    slots: sl,
    forcedAttendees: getForcedAttendees(sl, busys)
  }
}
function getForcedAttendees(slots, busys) {
  let forced = {};
  let linerarizedSlots = linearize(slots);
  for (let busy of busys) {
    for (let sl of linerarizedSlots) {
      if (slot_overlapp(sl, busy)) {
        for (let att of Object.keys(busy.user || {})) {
          forced[att] = 1;
          // populate slot extra data
          sl.isForced = true
          let tmp = (sl.busy || { user: {} });
          tmp.user[att] = 1
          sl.busy = tmp
        }
      }
    }
  }
  return Object.keys(forced);
}
export function linearize(slots) {
  let tmp = [];
  /* istanbul ignore else dummy check */
  if (slots) {
    for (let s of slots) {
      tmp.push(...(s.slots || []))
    }
  }
  return tmp;
}
export function substractBusyFromProposals(proposals = [], busys = []) {
  // convert all to moment data
  // + deduplicate proposals if needed


  proposals = deduplicateSlots(proposals);
  // for ( let busy of busys) {
  //   busy.start = moment(busy.start);
  //   busy.end = moment(busy.end);
  // }
  // assure all busys are on 1 day (not necessary)
  busys = deduplicateBusySlots(busys);
  let tmp = []
  for (let busy of busys) {
    for (let i = 0; i < proposals.length; i++) {
      let prop = proposals[i];
      if (slot_overlapp(prop, busy)) {
        // 1st: busy start before: cut
        if (busy.start < prop.start) {
          // check end
          if (busy.end < prop.end) {
            // split
            prop.start = busy.end;
            tmp.push(prop)

          } // else  discard slot
        } else {
          if (busy.end < prop.end) {
            let oldend = prop.end;
            prop.end = busy.start
            if (prop.start < busy.start) tmp.push(prop);
            tmp.push({
              start: busy.end,
              end: oldend,
            });

          } else {
            prop.end = busy.start;
            tmp.push(prop);
          }
        } // else, don't care

      } else {
        tmp.push(prop)
      }
    }
    // set new proposals array
    proposals = tmp;
    tmp = [];
  }
  return proposals

}
export function slot_overlapp(slot, busy, equalOverlapp = false) {
  let start = moment(slot.start);
  let end = moment(slot.end);
  let bstart = moment(busy.start);
  let bend = moment(busy.end);
  return equalOverlapp ? (bstart <= end && bend >= start) : (bstart < end && bend > start);
}

function deduplicateSlots(periods) {
  let clean = [];
  for (let period of periods) {
    if (period.start >= period.end) continue; // invalid, would break all
    period.start = moment(period.start);
    period.end = moment(period.end);
    // get day
    let startPeriod = period.start.clone();
    let day = startPeriod.clone().startOf('day');
    let endPeriod = period.end.clone();


    // if more than 1 day
    let diff = getDiff(startPeriod.clone(), endPeriod.clone()); // diff in minutes for period ON SAME DAY
    let daysDiff = endPeriod.diff(day, 'minutes') / (24 * 60)

    if (daysDiff >= 1) {
      // divide in days
      let s = startPeriod.clone();
      let e = startPeriod.clone().add(diff, 'minutes');
      while (e <= endPeriod) {
        clean.push({
          start: s.clone(),
          end: e.clone(),
        })
        startPeriod.add(1, 'day');
        // e.add(1,'day')
        s = startPeriod.clone();
        e = startPeriod.clone().add(diff, 'minutes');
      }
    } else {
      // just add
      clean.push(period);
    }
  }
  return clean;
}

/**
 * Remove busy from generated slots
 * Naive implementation, this could surely be optimised (later)
 */
function removeBusySolts(slots, busy) {
  /* istanbul ignore if no work */
  if (!busy || busy.length === 0) return slots;

  let cleanedSlots = [];
  for (let day of slots) {
    let cleanedDay = {
      ...day,
      slots: []
    }
    for (let sl of day.slots) {
      // check if slot is free, ie, slot do not overlap a busy time
      if (!overlapp(sl, busy)) cleanedDay.slots.push(sl);
    }
    // do day have slot, if yes, add it
    if (cleanedDay.slots.length > 0) cleanedSlots.push(cleanedDay)
  }

  return cleanedSlots;
}
export function overlapp(slot, busy = []) {
  let start = moment(slot.start);
  let end = moment(slot.end);
  for (let i = 0; i < busy.length; i++) {
    let b = busy[i];
    let bstart = moment(b.start);
    let bend = moment(b.end);
    if (bstart < end && bend > start) {
      // overlap, end
      return true;
    }
  }
  return false;
}
export function getOverlappBusysGuests(slot, busy = []) {
  let start = moment(slot.start);
  let end = moment(slot.end);
  return Object.keys(busy.reduce((acc, b) => {
    let bstart = moment(b.start);
    let bend = moment(b.end);
    if (bstart < end && bend > start) {
      // overlap, end
      return {
        ...acc,
        ...(b.user || {})
      }
    }
    return acc;
  }, {}));
}

// Same as the handler, but meant to be used in
// the heatmap with realtime calculus
export function proposalsToSlotsRT(
  proposals = [],
  busys = [],
  duration = 60,
  padStartToQuarter = false,
  step = -1,
) {
  if (proposals.length === 0) return [];
  // get proposals start and end
  let cpy = proposals
    .filter((p) => p && p.start && p.end)
    .map((p) => ({
      ...p,
      start: p.start.clone(),
      end: p.end.clone()
    }));
  let nobusys = substractBusyFromProposals(cpy, busys);
  let slots = periodToSlots(nobusys, duration, false, padStartToQuarter, step)
  let lnSlots = linearize(slots)
  return lnSlots.map(s => ({
    ...s,
    //noResize: true,
    uuid: v4(),
  }))
}
export function roundToNearestXXMinutes(start, roundTo) {
  let remainder = roundTo - (start.minute() + start.second() / 60) % roundTo;
  return moment(start).add(remainder, "minutes").seconds(0);
}