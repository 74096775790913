/* istanbul ignore file */
import * as ACTIONS from '../actions';
import { DEV_FLAGS, getDevSettingItem } from '../hooks/use.dev.settings';

export default store => next => action => {
    try {
        if (action
            && (action.type === ACTIONS.WORKER_CONNECT
                || action.type === ACTIONS.WORKER_REFRESH_ACCOUNTS
                || action.type === ACTIONS.WORKER_UNREGISTER_ACCOUNT)) {

            // add dev settings to get contacts differently
            if (getDevSettingItem(DEV_FLAGS.CONTACTS_USE_PEOPLE_CONNECTIONS_LIST)) {
                let payload = action.payload || {};
                let options = payload.opts || {};
                options.contactsUseConnectionsList = true;
                payload.opts = options;
                action.payload = payload;
            }
        }
    } catch (err) { }

    return next(action);
}