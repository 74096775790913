import { Checkbox, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useAccounts from '../../../../hooks/use.accounts';
import { AccountSummary } from './Accounts';
import './FreeBusyCalendars.scss';
import * as ACTIONS from '../../../../actions';

export default function FreeBusyCalendars() {
  const { t } = useTranslation();
  const accounts = useAccounts();
  const dispatch = useDispatch();

  const [updating, setUpdating] = React.useState(false);

  const onCalendarSelectionChange = (event, accountId) => {
    // calendar clicked
    const calendarId = event.target.name;
    // calendar account
    const modifiedAccount = accounts.find(acc => acc.user_id === accountId);
    if (!modifiedAccount) {
      return;
    }

    setUpdating('' + accountId + calendarId);
    let settings = modifiedAccount.settings || {};
    let DEFAULT = (modifiedAccount.calendars || []).filter(cal => cal.primary === true).map(cal => cal.id);
    let freeBusyCalendars = settings.freebusy_calendars ? [...settings.freebusy_calendars] : [...DEFAULT];
    if (event.target.checked) {
      // add calendar to freebusy_calendar in account settings
      if (!freeBusyCalendars.includes(calendarId)) {
        freeBusyCalendars.push(calendarId);
      }
    } else {
      // remove calendar from freebusy_calendar in account settings
      freeBusyCalendars = freeBusyCalendars.filter(fbCal => fbCal !== calendarId);
    }

    // save new list of freebusy calendars
    dispatch({
      type: ACTIONS.WORKER_SAVE_ACCOUNT_SETTINGS,
      payload: {
        accountId: modifiedAccount.provider.name + ':' + modifiedAccount.user_id,
        settings: { freebusy_calendars: freeBusyCalendars },
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_SAVE_ACCOUNT_SETTINGS_SUCCESS,
        rejectOn: ACTIONS.WORKER_SAVE_ACCOUNT_SETTINGS_ERROR,
      }
    }).catch((err) => {
      console.error(err);
      // refresh accounts to get "real" state
      dispatch({
        type: ACTIONS.WORKER_REFRESH_ACCOUNTS,
        resolvers: {
          resolveOn: ACTIONS.WORKER_REFRESH_ACCOUNTS_SUCCESS,
          rejectOn: ACTIONS.WORKER_REFRESH_ACCOUNTS_ERROR
        }
      }).catch(() => { }).then(() => {
        setUpdating(false);
      });
    }).then(() => {
      setUpdating(false);
    })
  }
  const hasOneGoogleAccount = (accounts || []).find(account => account.provider.name === 'google');
  if (!hasOneGoogleAccount) return null;
  return (
    <div className="FreeBusyCalendars section">
      <div className="title">
        {t('settings.sections.accounts.freebusyCalendars.sectionTitle')}
      </div>
      <div className="subtitle">
        {t('settings.sections.accounts.freebusyCalendars.sectionSubTitle')}
      </div>
      <div className="subtitle">
        {t('settings.sections.accounts.freebusyCalendars.googleOnly')}
      </div>
      <div className="accounts">
        {accounts && accounts.map(account => {
          const calendars = account.calendars || [];
          const isGoogleAccount = account.provider.name === 'google';
          if (!isGoogleAccount) return null;
          const writableCalendars = calendars.filter(cal => cal.primary ).map(cal => cal.id)
          const freebusyCalendars = (account.settings && account.settings.freebusy_calendars) || writableCalendars;
          return (
            <div className={"item account "} key={"fb" + account.user_id}>
              <div className="container">
                <AccountSummary account={account} />
                {calendars
                  .sort((a, b) => {
                    if (a.readonly) {
                      if (b.readonly) return 0;
                      else return 1;
                    } else {

                      if (b.readonly) return -1;
                      else return 0;

                    }
                  })
                  .map(calendar => {
                    const calendarIsSelected = freebusyCalendars.find(fbCal => fbCal === calendar.id);
                    const isCalendarUpdating = updating && updating === ('' + account.user_id + calendar.id);
                    return (
                      <div className="calendar-item" key={"fb-" + calendar.id}>
                        {!isCalendarUpdating &&
                          <Checkbox
                            className="done-input"
                            // data-testid={"checkbox-cal-" + account.user_id + calendar.id}
                            inputProps={{
                              "data-testid": "checkbox-cal-" + account.user_id + calendar.id
                            }}
                            disabled={!!updating}
                            checked={!!calendarIsSelected}
                            onChange={(event) => onCalendarSelectionChange(event, account.user_id)}
                            name={calendar.id} />
                        }
                        {isCalendarUpdating &&
                          <CircularProgress />
                        }
                        <div className={calendarIsSelected ? "selected" : ''}>{calendar.name}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}