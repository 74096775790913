import { STATUS } from "../api/validators/proposal";
import { gotLeftSlots } from "./got.left.slots";
import {
  allMandatoryAnswered
} from './/all.mandatory.answered';
import { isAboutToExpire } from './is.about.to.expire';
import { isWaitingForYou } from "./is.waiting.for.you";
import {
  hasAnswer
} from './/all.mandatory.answered'
import {
  isUnknownDomain
} from '../api/validators/proposal'

export function computeMenus(proposal) {
  if (!proposal) return;

  const isHost = proposal.iAmHost;
  const isPending = proposal.status === STATUS.PENDING;
  if (proposal.is_link) {
    let is_active = proposal.is_active;
    return {
      edit: true,
      delete: true,
      active: true,
      view_blink: is_active,
      link: is_active,
    }
  }
  if (!isHost) {
    // guest special menu
    // do I need to vote?
    if (isWaitingForYou(proposal)) {
      return {
        vote_requested: true
      }
    } else return false; // no need for menu
  }
  // Host menu
  // check if can force a meeting, ie, has remaining slots
  const hasRemainingSlots =
    isPending ||
    (proposal.status === STATUS.NO_AVIABILITY && gotLeftSlots(proposal));
  const waitingForVotes = isHost && isPending && !allMandatoryAnswered(proposal);
  let canRemind = waitingForVotes;
  if (canRemind) {
    // check we got at leat one email to remind
    canRemind = !!Object.values(proposal.inviteesDetails || {}).find((inv) => {
      // find one that is not unknown
      return !hasAnswer(inv) && !isUnknownDomain(inv.email)
    });
  }
  let menus = {
    create: hasRemainingSlots, // host + pending
    edit: isPending && hasRemainingSlots,
    remind: canRemind,
    link: isPending && waitingForVotes, // host + pending
    cancel: !(proposal.status === STATUS.CANCELLED || proposal.status === STATUS.EVT_CANCELLED), // host + cancel || evt_cancel
    reschedule: (proposal.status === STATUS.CANCELLED || proposal.status === STATUS.EVT_CANCELLED || proposal.status === STATUS.NO_AVIABILITY), // host + cancel || evt_cancel || no avail
  }
  return menus;
}
export function importantNotifications(proposal) {
  if (!proposal) return false;
  const isHost = proposal.iAmHost;
  const isPending = proposal.status === STATUS.PENDING;
  const isPoll = proposal.type === 'poll';
  const proposedNewSlots = isHost && isPending && isPoll && proposal.areNewSlotsProposed;
  const waitingForVotes = isHost && isPending && !allMandatoryAnswered(proposal);
  const isExpiring = waitingForVotes && isAboutToExpire(proposal);
  const isWaitingForYourVote = !isHost && isWaitingForYou(proposal);
  return {
    // for important message
    poll_complete: isHost && isPoll && isPending && !waitingForVotes,
    expiring: isExpiring,
    wainting_vote: isWaitingForYourVote,
    proposedNewSlots,
  }
}