import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProposal } from '../../../../reducers/proposals';
import { useTranslation } from 'react-i18next';
import * as ACTIONS from '../../../../actions'
import { parseQuery } from '../../../../utils/parse.query';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import Slots from './Slots';
import WhoAreYou from '../../event/simple/WhoAreYou';
import { CircularProgress } from '@material-ui/core';
import './anonymous.scss';
import ProposalInfo from './ProposalInfo';
import ProposalAttendees from './ProposalAttendees';
import { getAttendeeScore } from '../../../../utils/get.attendee.score'
import { LetsmeetSignature, LetsmeetSignatureLight } from '../../../../assets/icons';
import ProposalStatus, { allSlotsExpired, hasNotVoted } from './Status';
import { STATUS } from '../../../../api/validators/proposal';
import { useDialog } from '../../../dialogs/dialog.provider';
import ROUTES from '../../../../routes/routes.name';
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import DoneButton from '../../../DoneButton';
import { LS_KEY_BACK_HERE_AFTER_SIGNUP } from '../../event/simple';
import moment from 'moment';
export const lastToVote = (proposal, email) => {
  if (!proposal || !proposal.inviteesDetails || !email) return false;
  if (proposal.type === 'poll') return false; // ensure no last to vote for poll
  if (proposal.inviteesDetails[email] && proposal.inviteesDetails[email].optional) return false; // optional MUST not be last to vote
  let invitees = Object.values(proposal.inviteesDetails).filter((invitee) => {
    return !invitee.optional && getAttendeeScore(invitee) === 0; // we don't know their availability AND optional are not taken into account
  })

  // only one invitee left to vote and it's me
  return invitees.length === 1 && invitees[0].email === email;
}

export function Event() {
  const dispatch = useDispatch();
  const [lightTheme, setLightTheme] = useState(true);
  const { enqueueDialog, closeDialog } = useDialog();
  const [loading, setLoading] = React.useState(true);
  const [isVoting, setIsVoting] = React.useState(false);
  // const [whoAreYou, setWhoAreYou] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const urlparams = useParams();
  // const [authenticating, setAuthenticating] = React.useState(false);
  const [exists, setExists] = React.useState(true); // be optimist!
  const [error, setError] = React.useState(false); // be optimist!
  const proposal = useSelector(getProposal(urlparams.id));
  // const [transition, setTransition] = React.useState('');
  // const [hadVoted, setHadVoted] = React.useState(0); // 0: no, 1: voted, 2: cancel
  let query = parseQuery(location.search);
  React.useEffect(() => {
    /* istanbul ignore if router */
    if (!urlparams.id) {
      setExists(false);
      return undefined;
    }
    // check if got a sig AND a mail, if not => error
    if (query.sig && !query.email) {
      // Anonymous link connection
      // setWhoAreYou(true);
    } else if (!query.sig || !query.email) {
      // if no sig, should try to log user?
      // showWhoAreYouDlg = false;
      // setAuthenticating(true)
      setLoading(false);
      dispatch({
        type: ACTIONS.ASK_4_LOGIN,
        payload: 'modal',
        redirectTo: `${ROUTES.EVENT}${urlparams.id}`
      })
      return undefined;
    }
    setLoading(true);
    dispatch({
      type: ACTIONS.WORKER_GET_PROPOSAL,
      payload: {
        id: urlparams.id,
        signature: query.sig,
        email: query.email,
        bestSuggestions: false,
        userEmail: [query.email],
        hadFakeSlots: true,
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR,
      }
    })
      .then((res) => {
        dispatch({
          type: ACTIONS.ANALYTICS_VIEW_PROPOSAL,
          payload: {
            proposal: res.payload && res.payload[0],
          }
        })
        return res;
      })
      .then((res) => {
        if (!res.payload || !res.payload[0]) setExists(false);
        return res;
      })
      .catch((err) => {
        if (err && err.status === 404) {
          setExists(false);
        } else {
          // display error
          console.log(err)
          setError(true)
        }
      })
      .then((res) => {
        if (query.sig && !query.email && res && res.payload && res.payload[0]) {
          // who are you mode
          let proposal = res.payload[0];
          // if not in state "sent", pass
          if (proposal.status !== STATUS.PENDING) return; // no display

          const doSelect = (invitee, registered = false) => {
            if (registered) {
              try { localStorage.setItem(LS_KEY_BACK_HERE_AFTER_SIGNUP, ROUTES.APP + '?id=' + proposal.id); } catch (err) { }
              history.replace(ROUTES.APP + '?id=' + proposal.id);
            } else {
              history.replace(location.pathname + location.search + '&email=' + encodeURIComponent(invitee.email) + "&anon=1")
            }
            closeDialog();
          }
          // Chg: show only non registered users
          // Chg: do not show if only 1 invitee
          // Chg: do not show hybrids
          //  check invitees
          let invitees = Object.values(proposal.inviteesDetails || {}).filter((inv) => {
            // const isHybrid = inv.related_to && inv.related_to.provider && inv.related_to.user_id;
            // return !inv.registered && !isHybrid;
            return !inv.organizer;
          });

          if (invitees.length < 2) {
            // one invitee only, assume it's him/her
            let invitee = invitees[0];
            if (invitee && !invitee.registered) {
              history.replace(location.pathname + location.search + '&email=' + encodeURIComponent(invitee.email) + "&anon=1");
              return;
            } else if (invitee && invitee.registered) {
              history.replace(ROUTES.APP + '?id=' + proposal.id);
              return;
            }
          }
          enqueueDialog({
            content: <WhoAreYou
              invitees={invitees}
              organizer={proposal.organizer}
              onSelectAction={doSelect} />,
            mustConfirm: true,

          })
        }
      }).then(() => {
        setLoading(false)
      })
  }, [urlparams.id, dispatch/*, query.sig, query.email*/])

  const isLastToVote = React.useMemo(() => lastToVote(proposal, query.email), [proposal, query.email])

  // Should I reply?
  // not good for anonymous link!
  const IMustReply = React.useMemo(() => {
    if (!proposal) return false;
    if (!proposal.inviteesDetails || !proposal.inviteesDetails[query.email]) return false;
    // had voted: if user add it's email (param as), then query.email is not the one we must check
    const addVoted = proposal.hadVoted || (proposal.inviteesDetails[query.email] || {}).response === 'yes';
    return proposal && !addVoted && proposal.status === STATUS.PENDING;
  }, [proposal, query.email]);
  const IAmUnknown = React.useMemo(() => {
    if (!proposal) return false;
    return (proposal.inviteesDetails[query.email] || {}).isUnknown;

  }, [proposal, query.email])
  const showActionPanel = IMustReply && !isVoting;

  return <AnonymousProposalVotePage
    lightTheme={lightTheme} setLightTheme={setLightTheme}
    showActionPanel={showActionPanel}
    loading={loading} exists={exists} error={error}
    proposal={proposal}
    isVoting={isVoting}
    query={query}
    IMustReply={IMustReply}
    isLastToVote={isLastToVote}
    setIsVoting={setIsVoting}
    IAmUnknown={IAmUnknown} />;
}


export function AnonymousProposalVotePage({
  lightTheme, setLightTheme,
  showActionPanel,
  loading, exists, error,
  proposal,
  isVoting,
  query,
  IMustReply,
  isLastToVote,
  setIsVoting,
  IAmUnknown,
  isFake = false,
}) {
  const { t } = useTranslation();
  return (
    <div className={"proposal-page " + (lightTheme ? "light " : "")}>

      <div className={"proposal-info-panel fancy-scroll " + (!showActionPanel ? "full" : "")}
        /*onTransitionEnd={() => setTransition('inner-slots')}*/
        data-testid="proposal-info-panel">
        <div className="inner">
          {loading &&
            <Loading />}
          {!loading && exists && !error && proposal &&
            <InnerProposal proposal={proposal} isVoting={isVoting} email={query.email} isFake={isFake} />
          }
          {(error || !exists) &&
            <div
              className="error"
              data-testid={!exists ? "no-event" : "error-event"}>
              <h1>
                {!exists && t('event.errors.notFound')}
                {error && t('event.errors.500')}
              </h1>
            </div>}
        </div>
        {!loading &&
          <>
            <div className="secret" onClick={() => setLightTheme(!lightTheme)}>&nbsp;</div>
            <div className="signature">
              <a href="https://letsmeet.network?utm_source=proposal_powered_by&utm_medium=web">
                {lightTheme ? <LetsmeetSignatureLight /> : <LetsmeetSignature />}
              </a>
            </div>
          </>}
      </div>

      {IMustReply /*&& transition*/ &&
        <div id="slots-container" className="proposal-action-panel fancy-scroll" >
          <InnerVotes isLastToVote={isLastToVote} proposal={proposal} setIsVoting={setIsVoting} email={query.email} />
          {!IAmUnknown && !isFake && <ConnectRegister />}
        </div>
      }
    </div>
  );
}
const InnerProposal = ({ proposal, isVoting, email, isFake = false }) => {
  const styles = useSpring({
    to: [
      { opacity: 1 },
    ],
    from: { opacity: 0 },
    delay: 300
  })
  const hideInvitees = useMemo(() => {
    if (isFake) return false;
    const slotsExpiredAndUserHasNotVoted =
      proposal.status === STATUS.NO_AVIABILITY
      && hasNotVoted(proposal, email)
      && allSlotsExpired(proposal);
    return slotsExpiredAndUserHasNotVoted;
  }, [proposal, email, isFake]);

  return <animated.div style={styles}>
    <ProposalInfo proposal={proposal} />
    <ProposalStatus proposal={proposal} isVoting={isVoting} email={email} />
    {
      !hideInvitees &&
      <ProposalAttendees proposal={proposal} userEmail={email} isVoting={isVoting} />
    }
    {(proposal.status === STATUS.CONFIRMED
      || (proposal.status === 'sent' && (proposal.hadVoted || (proposal.inviteesDetails[email] || {}).response === 'yes')))
      && <ConnectRegister below={true} />
    }
  </animated.div>;
}
const InnerVotes = ({ proposal, setIsVoting, email, isLastToVote }) => {
  const { t } = useTranslation();
  const styles = useSpring({
    to: [
      { opacity: 1, left: 0 },
    ],
    from: { opacity: 0, left: 500 },
    // delay: 300
  });

  return <animated.div style={styles} className="relative">
    <Slots
      proposal={proposal}
      setIsVoting={setIsVoting}
      userEmail={email} />
  </animated.div>
}

const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="loading" >
      <CircularProgress />
      <div>
        {t('common.loading')}
      </div>
    </div>
  );
}
export const ConnectRegister = ({ below = false }) => {
  const { t } = useTranslation();
  const handleClick = React.useCallback(() => {
    try { localStorage.setItem(LS_KEY_BACK_HERE_AFTER_SIGNUP, ROUTES.APP); } catch (err) { }
  }, []);
  return <div className={"connection" + (below ? " below" : "")}>
    <div className="connection-content">
      <div className="title">{t('event.anonymous.connection.title')}</div>
      <div className="text">{t('event.anonymous.connection.desc')}</div>
    </div>
    <Link to={ROUTES.APP} onClick={handleClick}><DoneButton label={t('event.anonymous.connection.connect')} className="blue-outlined" /></Link>
  </div>
}

