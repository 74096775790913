/* istanbul ignore file */
// feature not activated yet
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as ACTIONS from '../../../actions'
import DoneButton from '../../DoneButton';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import copyLinkToClipboard from 'copy-to-clipboard';
import {
  Copy as FileCopy
} from '../../../assets/icons';
import { useSnackbar } from 'notistack';

import { CircularProgress } from '@material-ui/core';
import "./gen.link.scss";

export default function GenLink({id}){
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [link, setLink] = React.useState();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const doGenLink = React.useCallback(()=>{
    if(loading){
      // already generating link, pass
      return;
    }
    setLoading(true);
    setError('');

    // just generate a dummy link?
    let link = makeProposalLink(id);
    let p;
    if(link) p = Promise.resolve({payload: link});
    else p = dispatch({
        type: ACTIONS.WORKER_GEN_LINK,
        payload: id,
        resolvers:{
          resolveOn: ACTIONS.WORKER_GEN_LINK_SUCCESS,
          rejectOn: ACTIONS.WORKER_GEN_LINK_ERROR
        }
      });
    p.then((lnk)=>{
      setLink(lnk.payload);
      return lnk.payload;
    }).then((link)=>{
       if (copyLinkToClipboard(link)) {
        enqueueSnackbar(t('event.linkSuccess'), {
          variant: 'success', className: "snack-success",
          action: (key) => {
            return <>
              <Button onClick={() => { closeSnackbar(key) }}>
                {t('common.ok')}
              </Button>
            </>;
          }
        })
      } else {
        enqueueSnackbar(t('event.linkError'), { variant: 'error', className: "snack-error" })
      }
    }).catch((err)=>{
      setError(t('event.genLinkError'))
    }).then(()=> setLoading(false))

  }, [id, loading,link, setLoading, setLink, dispatch, enqueueSnackbar]);

  if(!id) return null;

  return <CopyLinkAction loading={loading} error={error} doGenLink={doGenLink}/>
}
export function CopyLinkAction({loading, error, doGenLink}){
  const {t} = useTranslation();
  return [error ? <div key="lnk-error" className="lnk-error">{error}</div> : null,
    <DoneButton icon={loading? <CircularProgress/> : <ShareIcon/>} key="lnk-action" className="genlinkbtn small fullwidth light" label={
    loading? <div key="lnk-action" className="loading-link">{t('event.loading')}</div> : t('event.genLink')} onClick={doGenLink}/>]
}
export function CopiableLink ({link}){
  const {t} = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /* istanbul ignore next lib take care of all shit */
  const copyToClipboard = React.useCallback(() => {
    if (copyLinkToClipboard(link)) {
      enqueueSnackbar(t('event.linkSuccess'), {
        variant: 'success', className: "snack-success",
        action: (key) => {
          return <>
            <Button onClick={() => { closeSnackbar(key) }}>
              {t('common.ok')}
            </Button>
          </>;
        }
      })
    } else {
      enqueueSnackbar(t('event.linkError'), { variant: 'error', className: "snack-error" })
    }
  }, [link, enqueueSnackbar]);


  return <div className="link-copy">
    <div className="link-value">{link}</div>
    <DoneButton className="small grey fullwidth"
          name="share-btn"
          onClick={copyToClipboard}
          icon={<FileCopy />}
          label={t('sharelink.copy')}

        ></DoneButton>
  </div>;
}

export const makeProposalLink = (id) => {
  return `${window.location.origin}/v1/r/${id}`;
}