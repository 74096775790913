import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import * as ACTIONS from "../../../../actions";
import DoneButton from "../../../DoneButton";
import Slot from "./Slot";
import { useFormik } from "formik";
import { Button } from "@material-ui/core";
import "./Slots.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAppTZ } from "../../../../reducers/app";
import moment from "moment";
import { useDialog } from "../../../dialogs/dialog.provider";
import ConfirmOneSlotOnlyDialog from "../../event/simple/confirm.one.slot";
import { parseQuery } from "../../../../utils/parse.query";
import { useLocation, useParams } from "react-router-dom";
import { DeclineDialog } from "../../event/Proposals";
import { lastToVote } from ".";
import { throttle } from "../../../../utils/throttle";
import DoneTextField from "../../create-event/inputs/Text";
import { UNKNOWN_EMAIL_DOMAIN } from '../../create-event/guests/guest.selector';
import { RESPONSE, UNKNOWN_RESPONSE } from '../../../../validators/response';
import { isUnknownDomain, PROPOSAL_TYPE } from "../../../../api/validators/proposal";
import GuestConsent from "../../../form/GuestConsent";
import useGuestConsent, { GUEST_CONSENT_ORIGINS } from "../../../../hooks/use.guest.consent";
import SimpleSwitch from "../../../simple.switch";
import PeriodSelector from '../../create-event/inputs/periods/period.selector'
const EMPTY_OBJECT = {};
const EMPTY_STYLE = 0;
////// IMPORTANT ///////////
// in order to known how/when to redraw, we assume heights are constants
// if height get changed (via css for exemple), we MUST update those values here
const HEADER_HEIGHT = 19; // date height in px
const ELEM_HEIGHT = 48 + 10; // in px, height of 1 slot element
const ELEM_WIDTH = 160 + 10; // in px
const CONTAINER_PADDING = 20; // in px
// const BREAKPOINT_LG = 1555, BREAKPOINT_MD = 768, BREAKPOINT_XS = 0; // breakpoints in pixels UNUSED
/* istanbul ignore next no resize on UT */
const getBreakpointRowsLength = w => {
  let width = document.body.clientWidth || 200;

  // case mobile, 1 by line
  if (width <= 575) return 1;
  // if (width > 767) width *= 0.6
  // problem: width is not set yet to final?
  let s = Math.floor((w - CONTAINER_PADDING) / ELEM_WIDTH);
  return s;
};
const getScrollerElem = () => {
  let scroller;
  let width = document.body.clientWidth || 200;
  if (width <= 767) {
    // send back a "fake" elem from window
    scroller = {
      getBoundingClientRect: () => ({
        width,
        height: document.body.clientHeight || 200
      }),
      addEventListener: (type, fct) => window.addEventListener(type, fct),
      removeEventListener: (type, fct) => window.removeEventListener(type, fct)
    };
  } else scroller = document.getElementById("slots-container");

  return scroller;
};
export default function Slots({
  proposal = EMPTY_OBJECT,
  setIsVoting,
  userEmail
}) {
  const location = useLocation();
  const urlparams = useParams();
  const topRef = React.useRef();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { enqueueDialog, closeDialog } = useDialog();
  const [viewMore, setViewMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [topFakeStyle, setTopFakeStyle] = React.useState(0);
  const cache = React.useRef([]); // cache for heights
  const [displayHeatmap, setDisplayHeatmap] = useState(false);
  let query = React.useMemo(() => parseQuery(location.search));
  const isLastToVote = React.useMemo(() => {
    return proposal.type !== PROPOSAL_TYPE.POLL && lastToVote(proposal, userEmail);
  }, [
    proposal,
    userEmail
  ]);
  React.useEffect(() => {
    setViewMore(proposal && proposal.type === 'poll')
  }, [proposal])
  const isUnknownEmail = React.useMemo(() => {
    if (userEmail) {
      return userEmail.endsWith(UNKNOWN_EMAIL_DOMAIN)
    }
    return false;
  }, [userEmail])
  const nbRemainingSlots = (proposal.remaining_slots || []).reduce((acc, slot) => acc + slot.slots.length, 0);
  const initialSlots = useMemo(() => {
    if (nbRemainingSlots === 1) return [true];
    return (proposal.remaining_slots || []).reduce((acc, v) => {
      acc.push(...(v.slots || []).map(vs => false));
      return acc;
    }, []);
  }, [proposal.remaining_slots, nbRemainingSlots]);

  const confirmEvent = React.useCallback(
    (event, opts) => {
      /* istanbul ignore if */
      if (loading) return;
      setIsVoting(true);
      setLoading(true);
      dispatch({
        type: ACTIONS.WORKER_VOTE_PROPOSAL,
        payload: {
          proposal: event,
          opts: opts
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_VOTE_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_VOTE_PROPOSAL_ERROR
        }
      })
        .catch(
          /* istanbul ignore next */ err => {
            // error message
            let error = "event.errors.couldNotVote"
            if (err && err.status) {
              switch (err.status) {
                case 404: {
                  error = "event.errors.couldNotVoteNotFound";
                  break
                }
                case 409: {
                  error = "event.errors.couldNotVoteMultipleTime";
                  break
                }
                case 417: {
                  error = "event.errors.couldNotVoteAsLMUser";
                  break
                }
                default: {
                  break;
                }
              }
            }
            enqueueSnackbar(t(error), {
              variant: "error"
            });
          }
        )
        .then(() => {
          setLoading(false);
          setIsVoting(false);
        });
    },
    [dispatch, enqueueSnackbar, loading, setIsVoting, t]
  );

  const onVote = useCallback(
    // if guest had no email, opts == {as: email}
    (votes, opts = {}) => {
      // setIsVoting(true);
      let votedSlots = [];
      let total_voted_count = 0;
      let slots;
      if (opts.proposedSlots) {
        slots = opts.proposedSlots;
        total_voted_count = slots.length;
      } else {
        slots = proposal.remaining_slots.reduce((acc, v) => {
          acc.push(...v.slots);
          return acc;
        }, []);
        // set proposal slots
        // only got an array of true/false to remap to slots
        for (let i = 0; i < slots.length; i++) {
          slots[i].vote = votes[i];
          if (votes[i]) votedSlots.push(slots[i]);
          total_voted_count += votes[i] ? 1 : 0;
        }
      }

      let p = Promise.resolve(true);

      // how many people will have to vote after me?
      let nbGuestsLeftToVoteAfterMe =
        Object.values(proposal.inviteesDetails).reduce((acc, invitee) => {
          let isHybrid = invitee.related_to && invitee.related_to.provider;
          let hasConflicts = (invitee.conflicting_slots || []).length > 0;
          let vote_required = invitee.vote_requested;
          if (
            !invitee.optional &&
            (!invitee.registered || hasConflicts || vote_required) &&
            (!isHybrid || hasConflicts || vote_required) &&
            invitee.response !== "yes"
          )
            acc = acc + 1;
          return acc;
        }, 0) - 1;

      // magic formulae for minimum ideal nb of selected slots
      let nbSlotsRecommended = nbGuestsLeftToVoteAfterMe * 2;
      // pad to max number of slots
      nbSlotsRecommended = nbSlotsRecommended > slots.length ? slots.length : nbSlotsRecommended;

      // show a warning popup if needed
      // except if optional attendee
      let detail = (proposal.inviteesDetails[userEmail]) || {};
      let isOptional = detail.optional;

      if (
        proposal.type !== 'poll' &&
        !isOptional &&
        (nbGuestsLeftToVoteAfterMe > 0 && // there is at least one guest to vote after me
          slots.length > total_voted_count && // user could have selected more slots (ie. did not select all slots)
          total_voted_count < nbSlotsRecommended) // the user did not select "enough" options
      ) {
        p = new Promise((resolve, reject) => {
          // need to confirm first
          // const unblock = history.block();
          const doClose = () => {
            // unblock();
            closeDialog();
            resolve(false);
            setLoading(false);
            setIsVoting(false);
            if (!viewMore) handleViewMore();
          };
          const doConfirm = () => {
            // unblock();
            closeDialog();
            resolve(true);
          };
          enqueueDialog({
            content: (
              <ConfirmOneSlotOnlyDialog
                doConfirm={doConfirm}
                doClose={doClose}
                nbSlotsVoted={total_voted_count}
                votedSlots={votedSlots}
                nbSlotsRecommended={nbSlotsRecommended}
                nbGuestsLeftToVoteAfterMe={nbGuestsLeftToVoteAfterMe}
                organizerLabel={proposal.organizer.label || proposal.organizer.email}
              />
            ),
            mustConfirm: true,
            doClose
          });
        });
      }
      p.then(confirm => {
        if (confirm === true) {
          // do vote for datas
          let query = parseQuery(location.search);
          confirmEvent(slots, {
            ...query,
            signature: query.sig,
            id: urlparams.id,
            userEmail: [query.email],
            ...opts
          });
          sendGuestConsent(opts.as || query.email, undefined, GUEST_CONSENT_ORIGINS.PROPOSAL_LINK);
        }
      });

      return false;
    },
    [proposal.remaining_slots, proposal.inviteesDetails,
    proposal.organizer,
      enqueueDialog, closeDialog, setIsVoting,
    location.search, confirmEvent, urlparams.id, userEmail]
  );

  const formik = useFormik({
    initialValues: {
      slots: initialSlots,
      asemail: isUnknownEmail ? "" : userEmail,
    },
    validationSchema: isUnknownEmail ? UNKNOWN_RESPONSE : RESPONSE,
    // validationSchema: EventSchemaStep1,
    enableReinitialize: true,
    onSubmit: (values) => {
      // check at least 1 slot selected
      let isValid = values.slots.find(v => v);
      if (!isValid) {
        // show error
        let label = "event.errors.noSlotSelected";
        enqueueSnackbar(t(label), {
          variant: "error",
          preventDuplicate: true,
          className: "snack-error",
          action: key => {
            return (
              <>
                <Button
                  onClick={() => {
                    closeSnackbar(key);
                  }}
                >
                  {t("common.ok")}
                </Button>
              </>
            );
          }
        });
        return;
      }
      // check email is valid?
      if (isUnknownEmail && values.asemail === "") {
        // show error
        formik.setFieldError("asemail", 1)
        let el = document.getElementById("asemail");
        /* istanbul ignore if not on jsdom */
        if (el) {
          el.focus();
          el.scrollIntoView();
        }
        return
      }
      if (values.asemail === userEmail) values.asemail = ""; // if same as email, then pass
      // if is anonyme, must have a name
      // dispatch to state
      onVote(values.slots, { isUnknownEmail, as: values.asemail });
    }
  });
  const doProposeNewSlots = useCallback((slots) => {
    let isValid = slots.length > 0;
    if (!isValid) {
      // show error
      let label = "event.errors.noSlotSelected";
      enqueueSnackbar(t(label), {
        variant: "error",
        preventDuplicate: true,
        className: "snack-error",
        action: key => {
          return (
            <>
              <Button
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                {t("common.ok")}
              </Button>
            </>
          );
        }
      });
      return;
    }
    // check email is valid?
    let asemail = formik.values.asemail;
    if (isUnknownEmail && asemail === "") {
      // show error
      formik.setFieldError("asemail", 1)
      let el = document.getElementById("asemail");
      /* istanbul ignore if not on jsdom */
      if (el) {
        el.focus();
        el.scrollIntoView();
      }
      return
    }
    if (asemail === userEmail) asemail = ""; // if same as email, then pass
    // if is anonyme, must have a name
    // dispatch to state
    onVote([], {
      isUnknownEmail, as: asemail, proposedSlots: slots.map((sl) => {
        return {
          start: sl.start.toISOString(),
          end: sl.end.toISOString(),
          vote: true, // mark as voted
        }
      })
    });
  }, [isUnknownEmail, formik.values.asemail]);
  const { isGuestConsentChecked, guestConsentChanged, sendGuestConsent } = useGuestConsent();

  const onDecline = React.useCallback(() => {
    // do vote for datas
    let query = parseQuery(location.search);
    confirmEvent([], { ...query, signature: query.sig, id: urlparams.id, isUnknownEmail, as: formik.values.asemail });
    const queryKnownEmail = (query.email && !isUnknownDomain(query.email)) ? query.email : undefined; // query email can be '@null-letmeet.network'
    sendGuestConsent(formik.values.asemail || queryKnownEmail, undefined, GUEST_CONSENT_ORIGINS.PROPOSAL_LINK);
  }, [location.search, confirmEvent, urlparams.id, formik.values.asemail, isUnknownEmail, sendGuestConsent]);

  const doDecline = React.useCallback(
    (e) => {
      // if no mail set, refuse
      if (isUnknownEmail && formik.values.asemail === "") {
        // show error
        formik.setFieldError("asemail", 1)
        // let el = document.getElementById("asemail");
        // /* istanbul ignore if not on jsdom */
        // if (el) {
        //   el.focus();
        //   el.scrollIntoView();
        // }

        // return
      }
      formik.validateForm()
        .then((err) => {
          if (err && err.asemail) {
            formik.setFieldTouched("asemail", true)
            formik.setErrors(err)
            let el = document.getElementById("asemail");
            /* istanbul ignore if not on jsdom */
            if (el) {
              el.focus();
              el.scrollIntoView();
            }
            return
          }
          // ask for confirmation
          // const unblock = history.block();
          const doClose = () => {
            //   unblock();
            closeDialog();
          };
          const doDecline = () => {
            //   unblock();
            onDecline();
            closeDialog();
          };
          const doProposeNewSlots = () => {
            //   unblock();
            closeDialog();
            setDisplayHeatmap(true);
            setTimeout(() => {
              let el = document.getElementById("slots-container");
              if (el && el.scrollTo) {
                el.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                })
              }
            }, 100)
          };
          if (proposal.inviteesDetails
            && proposal.inviteesDetails[userEmail]
            && proposal.inviteesDetails[userEmail].optional) {
            doDecline();
            return;
          }
          enqueueDialog({
            content: <DeclineDialog onDecline={doDecline} onClose={doClose} onProposeNewSlots={proposal.are_slots_suggestion_allowed ? doProposeNewSlots : undefined} />,
            doClose: doClose
          });
        }).catch((err) => {

        })

    },
    [onDecline, enqueueDialog, closeDialog, proposal.inviteesDetails, proposal.are_slots_suggestion_allowed, userEmail, formik.validateForm, formik.setFieldTouched, formik.setErrors,
      formik.values.asemail]
  );
  const handleChange = React.useCallback(
    e => {
      if (isLastToVote) {
        formik.setFieldValue("slots", initialSlots);
      }
      formik.handleChange(e);
    },
    [formik, initialSlots, isLastToVote]
  );
  const handleDayChange = React.useCallback(
    (index, count, dayIndex) => {
      if (isLastToVote) {
        return; // not possible if last to vote
      }
      // change all slots for day - use best suggestions if sets
      let best = proposal.bestSuggestions;
      if (viewMore) best = undefined; // reset best suggestions, we display all

      let v = [...formik.values.slots];
      // note: if one set, toggle off, else toggle on
      let newValue = true;
      for (let i = 0; i < count; i++) {
        if (v[index + i]) {
          newValue = false;
          break;
        }
      }

      for (let i = 0; i < count; i++) {
        if (!best || !best[dayIndex] || best[dayIndex].find((b) => b === i) !== undefined) v[index + i] = newValue;
      }
      formik.setFieldValue("slots", v);
      return newValue;
    },
    [formik.values.slots, formik.setFieldValue, isLastToVote, proposal.bestSuggestions, viewMore]
  );

  const handleViewMore = React.useCallback(() => {
    setViewMore(!viewMore);
    setTimeout(() => {
      const scroller = document.getElementById("slots-container");
      if (scroller) scroller.scrollTo(0, 0);
    }, 0);
  }, [viewMore]);
  /* istanbul ignore next no resize event on UT */
  React.useEffect(() => {
    const doResize = e => {
      cache.current = []; // remove all cache
      // force redraw?
    };
    window.addEventListener("resize", doResize);
    return () => window.removeEventListener("resize", doResize);
  }, []);
  /* istanbul ignore next no scrolling test */
  React.useEffect(() => {
    let scroller = getScrollerElem();
    // const scroller = document.getElementById('slots-container');
    if (!scroller) return;
    let curr = scroller; // add t closure
    const doScroll = throttle(e => {
      // check position top of fake scroll

      let elem = document.getElementById("top-scroll-fake");
      if (!elem) return;
      let topbounds = elem.getBoundingClientRect();
      if (topbounds.top < 0) {
        // means, list start beeing out of window
        // set new height
        let nh = Math.ceil(-topbounds.top / ELEM_HEIGHT) * ELEM_HEIGHT;
        setTopFakeStyle(nh);
      } else {
        if (topFakeStyle != EMPTY_STYLE) setTopFakeStyle(EMPTY_STYLE);
      }
    }, 20);
    curr.addEventListener("scroll", doScroll);
    return () => curr.removeEventListener("scroll", doScroll);
  }, [topFakeStyle]);
  React.useEffect(() => {
    if (!isUnknownEmail || !formik.isSubmitting || !formik.errors.asemail) return;
    let el = document.getElementById("asemail");
    /* istanbul ignore if not on jsdom */
    if (el && el.scrollIntoView) {
      el.focus();
      el.scrollIntoView();
    }
  }, [formik.isSubmitting, formik.errors.asemail, isUnknownEmail])
  const slotsToDisplay = useMemo(() => {
    const slots = [];
    let best = viewMore ? undefined : proposal.bestSuggestions;
    let remaining = proposal.remaining_slots;
    let formikValueIndex = 0;
    const isPoll = proposal.type === 'poll';

    if (!topRef.current) return [];
    if (!remaining) return [];
    const scroller = getScrollerElem();
    if (!scroller) return [];
    let top = -topFakeStyle;
    let { width, height } = scroller.getBoundingClientRect();
    // if got window scroller
    width = width || 800;
    height = height || 600; // UT cases

    // calculate how many fit
    // update, slot.selectedBy.length get all voters now + maxVotersAvailable give how many vote
    const NbGuestsWhoVoted = proposal.maxVotersAvailable; // getNbGuestsWhoVoted(proposal)
    let slotInRows = getBreakpointRowsLength(width);
    // virtualisation init
    let pos = top; // position of 1st element in pixels
    let topHeight = 0; // heights of fakers div
    let bottomHeight = 0;

    const MIN_POS_X = -5 * ELEM_HEIGHT;
    const MAX_POS_X = height + 5 * ELEM_HEIGHT;

    for (let i = 0; i < remaining.length; i++) {
      let day = remaining[i];
      let slotsForDay = day.slots || [];
      if (slotsForDay.length === 0) continue;
      // skip day if no best slot this day
      if (best && !best[i]) {
        formikValueIndex += slotsForDay.length;
        continue;
      }

      // check if we can add 1 more day
      // calculate height of day depending on number of slots
      if (!best) {
        // problem: fake slots make heights inaccurates!
        // AND, I do not know yet....
        let dayHeight = 0;
        if (cache.current[i]) dayHeight = cache.current[i];
        else {
          let daySlotCount = slotsForDay.length; // No fakes are displayed now!!!!!!!!
          dayHeight =
            Math.ceil(daySlotCount / slotInRows) * ELEM_HEIGHT + HEADER_HEIGHT;
          cache.current[i] = dayHeight;
        }

        if (pos + dayHeight < MIN_POS_X) {
          // add to top
          // console.log('VIRTUAL add to top',pos, pos + dayHeight,MIN_POS_X, dayHeight, slotInRows)
          topHeight += dayHeight;
          pos += dayHeight;
          formikValueIndex += slotsForDay.length;
          continue;
        } else if (pos > MAX_POS_X) {
          // console.log('VIRTUAL add to bottom',day, pos, MAX_POS_X, dayHeight, slotInRows)
          // add to bottom
          bottomHeight += dayHeight + 100; // to be sure....
          pos += dayHeight;
          formikValueIndex += slotsForDay.length;
          // should not continue, find a way to kill rendering
          // precalculate all left?
          // continue;
          break;
        }
        pos += dayHeight;
      }

      slots.push(
        <Day
          date={day.day}
          key={day.day}
          slots={slotsForDay}
          best={best}
          formikValueIndex={formikValueIndex}
          values={formik.values.slots}
          submit={formik.handleSubmit}
          handleChange={handleChange}
          handleDayChange={handleDayChange}
          isLastToVote={isLastToVote}
          index={i}
          isPoll={isPoll}
          inviteesCount={NbGuestsWhoVoted || 1}
        />
      );
      formikValueIndex += slotsForDay.length;
    }
    // fake scroll elements
    slots.unshift(
      <div
        key="top-scroll-fake"
        id="top-scroll-fake"
        style={{ height: topHeight }}
      ></div>
    );
    slots.push(
      <div key="bottom-scroll-fake" style={{ height: bottomHeight }}></div>
    );

    return slots;
  }, [viewMore, proposal.bestSuggestions, proposal.remaining_slots, topFakeStyle, formik.values.slots, formik.handleSubmit, handleChange, handleDayChange, isLastToVote, proposal.type]);

  const confirmSlotsButtonDisabled = useMemo(() => {
    for (let i = 0; i < formik.values.slots.length; i++) {
      let slot = formik.values.slots[i];
      if (slot) {
        return false;
      }
    }
    return true;
  }, [formik.values.slots]);


  const content = displayHeatmap ? <GuestHeatmap proposal={proposal} onValid={doProposeNewSlots} onClose={() => setDisplayHeatmap(false)} signature={query.sig} /> : <>
    {nbRemainingSlots > 1 && slotsToDisplay}
    {!viewMore && proposal.bestSuggestions && (
      <div className="view-more">
        <div className="button-like-a-link" onClick={handleViewMore}>
          {t("event.anonymous.viewMoreOptions")}
        </div>
      </div>
    )}
    <div className="slots-actions">
      <GuestConsent
        checked={isGuestConsentChecked}
        onChange={guestConsentChanged} />
      {(!isLastToVote || nbRemainingSlots === 1) &&
        <>
          <DoneButton
            label={
              t((nbRemainingSlots === 1) ? "event.buttonConfirmAvailabilityOneSlotLeft" : "event.buttonConfirmAvailability")
            }
            name={"btn-vote"}
            className={
              "density-medium no-margin " +
              (nbRemainingSlots === 1 ? "" : " margin-top ")
            }
            disabled={confirmSlotsButtonDisabled}
            onClick={formik.handleSubmit}
          />
        </>
      }

      {(viewMore || !proposal.bestSuggestions) && (
        <DoneButton
          label={t("event.decline")}
          name={"btn-decline"}
          className="density-medium grey no-margin margin-top"
          onClick={doDecline}
        />
      )}
    </div>
  </>
  return (<div>
    <div className="slots" ref={topRef}>
      <form action="javascript:void(0)" className={displayHeatmap ? "as-heatmap" : ''}>
        <AsEmail proposal={proposal} userEmail={userEmail} isLastToVote={isLastToVote} isUnknownEmail={isUnknownEmail} nbRemainingSlots={nbRemainingSlots}
          asemail={formik.values.asemail} handleChange={formik.handleChange}
          touched={formik.touched} errors={formik.errors} suggestNewSlots={displayHeatmap} />
        {content}
      </form>
    </div>

  </div>
  );
}

const Day = ({
  slots,
  best,
  formikValueIndex,
  values,
  handleChange,
  handleDayChange,
  submit,
  isLastToVote,
  date,
  index,
  isPoll = false,
  inviteesCount = 1,
  key
}) => {
  const timezone = useSelector(getAppTZ);
  const { t } = useTranslation();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const doHandleChange = React.useCallback((e) => {
    handleChange(e);
    if (e.target.checked) {
      setIsAllSelected(true);
      return;
    }

    // check value of all selected for day
    let total = e.target.checked ? 1 : -1;
    let dayIndex = index;
    let sindex = formikValueIndex;
    let count = slots.length;
    for (let i = 0; i < count; i++) {
      if (!best || !best[dayIndex] || best[dayIndex].find((b) => b === i) !== undefined) total += +values[sindex + i];
    }
    // if at least 1 set, show unselect
    setIsAllSelected(total > 0);
  }, [handleChange, formikValueIndex, slots, index,
    best, values,
  ])
  const doHandleDayChange = React.useCallback(() => {
    if (!slots || !slots.length) return;
    setIsAllSelected(handleDayChange(formikValueIndex, slots.length, index));
  }, [handleDayChange, formikValueIndex, slots, index]);
  const elems = React.useMemo(() => {
    let fvi = formikValueIndex;
    fvi -= 1;

    return slots.map((slot, j) => {
      fvi++;
      // skip slot if not best
      if (best && best[index] && best[index].find(b => b === j) === undefined)
        return null;
      // Changes: only 1 possible
      let fakeBeforeComp = null;
      let fakeAfterComp = null;

      if (slot.__fakes) {
        // fakeBeforeComp = slot.__fakes[0] && (
        //   <Slot
        //     slot={{ start: 1 }}
        //     selected={false}
        //     multiselect={true}
        //     canSelect={false}
        //     key={"before:" + fvi}
        //   />
        // );
        // fakeAfterComp = slot.__fakes[1] && (
        //   <Slot
        //     slot={{ start: 1 }}
        //     selected={false}
        //     multiselect={true}
        //     canSelect={false}
        //     key={"after:" + fvi}
        //   />
        // );
      }
      return [
        isPoll ? null : fakeBeforeComp,
        <Slot
          slot={slot}
          selected={values[fvi]}
          onChange={doHandleChange}
          submit={submit}
          multiselect={!isLastToVote}
          canSelect={true}
          name={"slots[" + fvi + "]"}
          key={fvi}
          isPoll={isPoll}
          inviteesCount={inviteesCount}
        />,
        isPoll ? null : fakeAfterComp
      ];
    });
  }, [
    slots,
    best,
    formikValueIndex,
    values,
    doHandleChange,
    submit,
    isLastToVote,
    isPoll,
    inviteesCount
  ]);
  return (
    <div className="slots-for-day" key={key}>
      <div className="date">
        {moment(date).format(t("common.dayFormat"))}{" "}
        <span className="timezone">
          {moment().format("[GMT]Z") + " " + timezone}
        </span>
        {!isLastToVote && <SimpleSwitch className="all-link" name="day-vote" onClick={doHandleDayChange}
          labelWhenChecked={t('event.anonymous.uncheckAllDay')}
          labelWhenNotChecked={t('event.anonymous.checkAllDay')}
          checked={isAllSelected}
        ></SimpleSwitch>}
      </div>
      <div className="row">{elems}</div>
    </div>
  );
};

export function AsEmail({
  proposal, userEmail, isLastToVote,
  isUnknownEmail,
  nbRemainingSlots,
  asemail,
  handleChange,
  touched,
  errors,
  suggestNewSlots,
}) {
  const { t } = useTranslation();
  const doChangeEmail = React.useCallback((e) => {
    let v = e.target.value;
    handleChange({ target: { name: "asemail", value: v } })
  }, [handleChange])
  const detail = (proposal.inviteesDetails[userEmail]) || {};
  const isPoll = proposal.type === 'poll';
  let slotDate;
  if (nbRemainingSlots === 1) {
    let slots = (proposal.remaining_slots || []).reduce((acc, v) => {
      acc.push(...v.slots);
      return acc;
    }, []);
    slotDate = formatUniqueSlotDate(slots[0], t);
  }
  if (suggestNewSlots) {
    return (
      <div className={"unknwon-email" + (nbRemainingSlots === 1 ? " padding-bottom" : "")}>
        <div className="header">
          <h1>
            {t('event.anonymous.suggestNewSlots.voteTitle')}
            <EmailEdit asemail={asemail} attendee={detail} handleChange={doChangeEmail}
              touched={touched} errors={errors} isUnknownEmail={isUnknownEmail} />
          </h1>
        </div>
      </div>
    )
  }
  return <div className={"unknwon-email" + (nbRemainingSlots === 1 ? " padding-bottom" : "")}>
    {isPoll ? (<div className="header">
      <h1>
        {t('event.anonymous.voteTitle')}<br />
        <EmailEdit asemail={asemail} handleChange={doChangeEmail}
          touched={touched} errors={errors} isUnknownEmail={isUnknownEmail} attendee={detail} />
      </h1>
    </div>)
      : (<div className="header">
        <h1>
          {nbRemainingSlots === 1 && <>{t('event.anonymous.oneSlotLeft.voteTitle', { slotDate })}
            <EmailEdit asemail={asemail} attendee={detail} handleChange={doChangeEmail}
              touched={touched} errors={errors} isUnknownEmail={isUnknownEmail} /></>}
          {nbRemainingSlots > 1 && isLastToVote && <>{t("event.anonymous.lastToVote.voteTitle")}
            <EmailEdit asemail={asemail} attendee={detail} handleChange={doChangeEmail}
              touched={touched} errors={errors} isUnknownEmail={isUnknownEmail} /></>}
          {nbRemainingSlots > 1 && !isLastToVote && <>{t('event.anonymous.voteTitle')}
            <EmailEdit asemail={asemail} attendee={detail} handleChange={doChangeEmail}
              touched={touched} errors={errors} isUnknownEmail={isUnknownEmail} /></>}
        </h1>
      </div>)}

    {/* <ConnectRegister propid={proposal.id} asEmail={userEmail} /> */}
  </div>
}
export function EmailEdit({ asemail,
  handleChange, isUnknownEmail, attendee,
  touched,
  errors }) {
  const { t } = useTranslation();
  const [focused, setFocused] = React.useState(false);
  const stAdor = (!focused && isUnknownEmail && asemail === '') ?
    <span style={{ pointerEvents: 'none' }}>
      <Trans i18nKey={'event.anonymous.emailPlaceholderWithName'} values={{ name: attendee.label }}>
        enter your email Here
      </Trans></span>
    : null;
  return <DoneTextField
    label={t('event.anonymous.email')}
    startAdornment={stAdor}
    errorText={t('event.errors.noEmail')}
    value={asemail} name="asemail" onChange={handleChange}
    isValid={touched.asemail && !errors.asemail}
    onBlur={() => setFocused(false)}
    onFocus={() => setFocused(true)} />

}
export const ConnectRegister = ({ propid, asEmail }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = React.useCallback(() => {
    dispatch({
      type: ACTIONS.ASK_4_LOGIN,
      payload: {
        state: asEmail,
      }
    });
  }, [dispatch, propid, asEmail]);
  return <div className="connection">
    <span className="title" onClick={handleClick} data-testid="vote-registered">{t("event.anonymous.gotAnAccount")} <a href="#" onClick={handleClick} className="link">{t("event.anonymous.connect")}</a></span>
    {/* <DoneButton name="vote-registered" label={t("event.anonymous.connect")} onClick={handleClick} className="blue-outlined" /> */}

  </div>
}
const formatUniqueSlotDate = (slot, t) => {
  if (!slot) return '';
  else {
    const start = moment(slot.start);
    const end = moment(slot.end);
    return t('event.anonymous.oneSlotLeft.voteTitleDateFormatter', {
      slotDay: start.format(t('common.dayFormat')),
      slotStartTime: start.format(t('common.hourMinutesFormat')),
      slotEndTime: end.format(t('common.hourMinutesFormat')),
      slotTimezone: end.format(t('common.timezoneAlone')),
    })
  }
}
/*
title,
  isImportant,
  duration,
  organizer,
  ignoreInitialAvail,
  calendar,
  oboCalendar = '',
  invitees = [], // event to display
  inviteesDetails, // infos about invitees
  values = [], // values selected
  onValid, onClose,
  initial, // which date to start for?
  heatmap_slots_only = false, // dev settings: precompute slots from proposals on mouse up/touch up
  allowMultipleSlots = true,
  */
export function GuestHeatmap({ proposal, onValid, onClose, signature }) {
  return <div>
    <PeriodSelector title={proposal.title}
      duration={proposal.duration}
      organizer={proposal.organizer}
      calendar={proposal.calendar}
      oboCalendar={proposal.onBehalfOf}
      invitees={proposal.invitees}
      inviteesDetails={proposal.inviteesDetails}
      heatmap_slots_only={true}
      guestView={true}
      onValid={onValid}
      onClose={onClose}
      overlapp={30}
      selectorTitle={null}
      showTimezone={false}
      selectorOK='event.anonymous.suggestNewSlots.heatMap.saveButton'
      selectorCancel='common.back'
      signature={signature}
      proposalId={proposal.id}
    />
  </div>;
}