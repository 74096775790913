/* istanbul ignore file logger techytalky */
// /**
//  * @fileOverview Winston configuration for logging
//  * @author stephane@do.ne
//  * @module utilities
//  */
// /**
//  * Winston logger
//  */
// import winston from 'winston';
// // creating a custom format for displaying logs
// const { combine, timestamp, printf } = winston.format;

// const logger = winston.createLogger({
//   level: 'silly',
//   // defaultMeta: { service: 'user-service' },
//   transports: [
//     //
//     // - Write all logs error (and below) to somewhere we will find.
//     //

//   ]
// });

// //
// // If we're not in production then log to the `console` with the format:
// // `${info.level}: ${info.message} JSON.stringify({ ...rest }) `
// // 
// if (process.env.NODE_ENV !== 'production') {
//   logger.add(new winston.transports.Console({
//     format: combine(
//       timestamp({
//         format: 'YYYY-MM-DD HH:mm:ss'
//       }),
//       printf(info => `[${info.timestamp}] [${info.level}]: ${info.message}`)
//     ),
//     level: 'silly'
//   }));

// } else {
//   logger.add(new winston.transports.Console({
//     level: 'error'
//   }))
// }


let simpleLogger = {
  silly: (...args)=> console.log(args),
  debug: (...args)=> console.log(args),
  info: (...args)=> console.log(args),
  warn: (...args)=> console.warn(args),
  error: (...args)=> console.error(args),
}
if(process.env.NODE_ENV === 'production'){
  simpleLogger = {
    silly: (...args)=>{},
    info: (...args)=>{},
    debug: (...args)=> {},
    warn: (...args)=> {},
    error: (...args)=> console.error(args),
  }
}
export default simpleLogger;