/* global analytics */
import * as ACTIONS from '../actions';
import jwt_decode from "jwt-decode";
import { getUserDatas } from '../reducers/user';
import { isProd } from '../api/config';
import {
  getHybridCount, getLinkCount, getLMUsersCount
} from './analytics'

let AID = '';
let SEGMENT_WRITE_KEY = '';

SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY;

const SEGMENT_EVENTS = {
  CONV_SIGN_UP: 'CONV_SIGN_UP',
  CONV_SIGN_IN: 'CONV_SIGN_IN',
  CONV_MEETING_FORM_SUB: 'CONV_MEETING_FORM_SUB',
  CONV_LINK_COPIED: "Link Copied",
  CONV_LINK_COPIED_BOOKING_LINK: "Scheduling Link Copied",
  CONV_PLAN_NOW: "Clicked Plan Now",
  CONV_PLAN_LATER: "Clicked Plan Later",
}

export const getAID = () => { return AID || ''; }

let COOKIES = {};
if (document.cookie) {
  // Notes: 
  // document.cookie is a ';'-separated string.
  // each cookie is 'key=value'
  // mapping them to COOKIES = {key1: value2, key2: value2 ...}
  COOKIES = document.cookie
    .split(';')
    .map(cookie => cookie.split('=').map(v => v.trim()))
    .reduce((acc, [key, value]) => { acc[key] = value; return acc; }, {});
}

const segmentAnalytics = (store) => {
  // when analytics are ready, dispatch anonmynousId
  if (analytics) {
    analytics.load(SEGMENT_WRITE_KEY);
    analytics.ready(function () {
      // get anonymous id
      AID = analytics.user().anonymousId();
      console.debug('[SEGMENT] Ready', AID);
      if (store && store.dispatch) {
        store.dispatch({
          type: ACTIONS.WORKER_ANALYTICS_ANON_ID,
          payload: {
            anonymousId: AID,
          }
        })
      }
      // log page view in Segment
      analytics.page("Webapp Start", { Client: "web" });
    });
  }

  return next => action => {
    // console.log(JSON.stringify(action))
    if (action) {
      // add anonymous id to all payload
      action.anonymousId = getAID();
      if (action.type === ACTIONS.WORKER_DONE_TOKEN_RECEIVED && action.payload) {

        // -----------------------------------
        // -------- user signed in -----------
        // -----------------------------------

        // get the "user ID" from the Done token and setUserId
        const doneToken = action.payload && action.payload.done;
        /* istanbul ignore else no work */
        if (doneToken) {
          const decodedDoneToken = jwt_decode(doneToken);
          const userId = decodedDoneToken && decodedDoneToken.sub;
          /* istanbul ignore else no work */
          if (userId) {
            /* istanbul ignore else no work */
            if (analytics) {
              const userInfos = getUserDatas(action.payload.provider);
              const identifyParams = {
                email: userInfos.email, // OK on both Google and MS
                firstName: userInfos.given_name, // OK on both Google and MS
                lastName: userInfos.family_name, // undefined on my MS account
                name: userInfos.name,
                // Facebook Advanced Matching
                // Source: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
                // Note: we think this is useless because it's caught automatically by Facebook pixel
                fbc: COOKIES._fbc,
                fbp: COOKIES._fbp,
              };
              console.log("[SEGMENT] identify with user ID:", userId);
              analytics.identify(userId, identifyParams);
              // facebook pixel call init
              if (window.fbq) {
                window.fbq("init", "362652255769991", {
                  em: userInfos.email, // OK on both Google and MS
                  fn: userInfos.given_name, // OK on both Google and MS
                  ln: userInfos.family_name, // undefined on my MS account
                  external_id: userId,
                });
                // window.fbq('track', 'PageView');
              }
              // Hotjar identify
              if (window.hj) {
                window.hj('identify', userId, {});
              }
            }
          }
        }
      } else if (action.type === ACTIONS.WORKER_CONNECT_SUCCESS) {
        track(SEGMENT_EVENTS.CONV_SIGN_IN);
        if (window.fbq) {
          window.fbq('trackCustom', SEGMENT_EVENTS.CONV_SIGN_IN);
        }
      } else if (action.type === ACTIONS.WORKER_SAVE_PROPOSAL_SUCCESS) {
        track(SEGMENT_EVENTS.CONV_MEETING_FORM_SUB);
        if (window.fbq) {
          window.fbq('trackCustom', SEGMENT_EVENTS.CONV_MEETING_FORM_SUB);
        }
      } else if (action.type === ACTIONS.ANALYTICS_SIGNED_UP) {
        track(SEGMENT_EVENTS.CONV_SIGN_UP);
        if (window.fbq) {
          window.fbq('track', "CompleteRegistration");
        }
      } else if (action.type === ACTIONS.WORKER_LOGOUT) {

        // -----------------------------------
        // -------- user signed out ----------
        // -----------------------------------

        console.log("[SEGMENT] reset");
        if (analytics) {
          analytics.reset();
        }

      } else if (action.type === ACTIONS.ANALYTICS_AB_VARIANTS) {
        analytics.identify(action.payload);
      } else if (action.type === ACTIONS.ANALYTICS_GET_LINK) {
        // Copy link from dashboard or matrix or end of proposal
        const payload = action.payload || {};
        const proposal = payload.proposal || {};
        if (!proposal.id) return;
        // Booking link copied
        if (proposal.is_link) {
          const link = proposal;
          if (analytics) analytics.track(SEGMENT_EVENTS.CONV_LINK_COPIED_BOOKING_LINK, {
            "Client": "web",
            "Scheduling Link ID": link.id
          });
        } else {
          // Regular meeting proposal link copied
          const nbGuests = proposal.invitees && proposal.invitees.length; // excludes organizer
          const hybrids = getHybridCount(proposal);
          const link = getLinkCount(proposal);
          const lm = getLMUsersCount(proposal)
          const eventParams = {
            "Client": "web",
            "Meeting Proposal ID": proposal.id,
            "Nb Participants": nbGuests + 1,
            "Nb Non LM Guests": nbGuests - (lm + hybrids + link),
            "Nb Hybrid Guests": hybrids,
            "Nb LM Guests": lm,
            "Nb Link Guests": link,
          };
          if (analytics) analytics.track(SEGMENT_EVENTS.CONV_LINK_COPIED, eventParams);
        }
      } else if (action.type === ACTIONS.WORKER_GET_STATS_SUCCESS) {
        // Hotjar identify

        let user = store.getState().user;
        hotjar_id(user, { proposals_count: action.payload.complete_proposal_count || 0 })

      } else if (action.type === ACTIONS.ANALYTICS_PLAN_LATER) {
        if (analytics) analytics.track(SEGMENT_EVENTS.CONV_PLAN_LATER);

      } else if (action.type === ACTIONS.ANALYTICS_PLAN_NOW) {
        if (analytics) analytics.track(SEGMENT_EVENTS.CONV_PLAN_NOW);
      }

      return next(action);
    }
  }
}

/* istanbul ignore next */
export default () => {
  return segmentAnalytics;
}

const track = (eventName) => {
  console.log("[SEGMENT] track:" + eventName);
  /* istanbul ignore else no work */
  if (analytics) analytics.track(eventName);
  // linked in conversion tracking
  sendLinkedInConversionTracking(eventName);
}

const sendLinkedInConversionTracking = (eventName) => {
  if (isProd() || process.env.REACT_APP_MONSTER_ENV === 'tests') {
    if (window.lintrk) {
      let conversionId = '';
      if (eventName === SEGMENT_EVENTS.CONV_SIGN_IN) conversionId = 8088337;
      else if (eventName === SEGMENT_EVENTS.CONV_SIGN_UP) conversionId = 8361833;
      else if (eventName === SEGMENT_EVENTS.CONV_MEETING_FORM_SUB) conversionId = 8088353;
      console.log("[SEGMENT] lintrk:", conversionId)
      window.lintrk('track', { conversion_id: conversionId });
    } else {
      // not ready, retry later
      setTimeout(sendLinkedInConversionTracking, 200, eventName);
    }
  }
}
export function hotjar_id(user, payload) {
  if (window.hj && user && user.token && user.token.done && payload) {
    const decodedDoneToken = jwt_decode(user.token.done);
    const userId = decodedDoneToken && decodedDoneToken.sub;
    if (userId) window.hj('identify', userId, payload);
  }
}