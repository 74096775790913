import { createActions, handleActions, combineActions } from 'redux-actions';
import * as ACTIONS from '../actions';
import moment from 'moment';
const defaultState = [];



const reducer = handleActions(
  {
    "__DEV__MAKE_DUMMY_BUSY": 
    /* istanbul ignore next dev only */
    (state, action) => {
      let { invitees, period } = action.payload;
      console.log('Generating dumb datas for ', invitees, period);
      /*
      Api return stuff like:
      [{
          "start": "2020-08-07T09:15:00.000Z",
          "end": "2020-08-09T012:15:00.000Z"
        }, {
          "start": "2020-08-06T09:15:00.000Z",
          "end": "2020-08-06T012:15:00.000Z"
        }]*/
      let busy = {
        invitees:[]
      };
      for(let invitee of invitees){
        // if known, pass
        let known = state.invitees.find((i)=> i.email === invitee);
        if(known){
          busy.invitees.push(known);
          continue;
        }
        let b = {
          email: invitee,
          busytime:[],
        }
        // generate from 0 to 5 busy time
        let st = period.start.clone();
        let diff = period.end.clone().subtract(st).unix();
        while(st < period.end){
          // add a period
          let bt = {
            start: st.add(Math.random() * diff, 's').clone(),
            end: st.add(Math.ceil(Math.random() * 2), 'h').clone(),
          }
          b.busytime.push(bt);

        }
        busy.invitees.push(b)
      }
      return busy;
    },
    [ACTIONS.WORKER_FREE_BUSY_SUCCESS]: (state, action)=>{
      return action.payload.map((t)=>({
        ...t,
        start: moment(t.start),
        end: moment(t.end)
      }));
    }
  },
  defaultState
);


export default reducer;