import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { ProposalContent } from "./proposal.content";
import { ProposalActions } from "./proposal.actions";
import { Timeline } from "./timeline";
import Slots from "./slots";
import * as ACTIONS from '../../../../actions';
import DoneButton from "../../../DoneButton";
import { useDialog } from "../../../dialogs/dialog.provider";
import { useHistory } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import ROUTES from "../../../../routes/routes.name";
import LinearProgress from "@material-ui/core/LinearProgress";
import { isMobilePlateform } from '../../../../utils/browser';
import Funnel, { FunnelAsDialog } from "./funnel";
import "./proposal.scss";
import { ArrowBack } from "@material-ui/icons";
import { getAccountsIds } from "../../../../reducers/accounts";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../reducers/user";
import { useSnackbar } from "notistack";
import NOOP from '../../../../utils/noop';
const DLG_STYLE = { maxWidth: "lg" };
const REVEAL = {
  from: { opacity: 0 },
  to: { opacity: 1 }
};
export default function Proposal({
  proposal,
  loading = false,
  showAsMobile = false,
  setSelected = NOOP,
  accountsEmails,
}) {
  const isEventEditable = proposal && proposal.iAmHost;
  const reveal = useSpring(REVEAL);
  const { t } = useTranslation();
  const { enqueueDialog, closeDialog } = useDialog(DLG_STYLE);
  const accountsIds = useSelector(getAccountsIds);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const doSelectNextProposal = React.useCallback(() => {
    if (proposal && !isMobilePlateform()) {
      setSelected()
    }
  }, [proposal, setSelected]);
  const doForceMeeting = React.useCallback((event, slot) => {
    return dispatch({
      type: ACTIONS.WORKER_CREATE_PROPOSAL,
      payload: {
        proposal: event,
        opts: { slot: slot }
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_CREATE_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_CREATE_PROPOSAL_ERROR
      }
    }).then(() => {
      // reload datas?
      return dispatch({
        type: ACTIONS.WORKER_GET_PROPOSAL,
        payload: {
          id: event.id,
          checkBusyTime: user.email,
          userEmail: accountsEmails,
          userAccounts: accountsIds,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
        }
      })
    })
      .then(() => {
        // change current selection? On mobile?
        enqueueSnackbar(<span><Trans i18nKey="proposal.details.actions.proposalScheduleSuccess"
          components={{ bold: <strong /> }}
          values={{ title: event.title }}>
          The status of <bold>{event.title}</bold> has been updated. You can view it in <bold>PLANNED</bold> tab
        </Trans></span>, {
          variant: "info",
          preventDuplicate: true,
          className: "snack-success",
        });
        doSelectNextProposal();
      }).catch((err) => {
        console.log(err)
        // error message
        /* istanbul ignore if storybook */
        enqueueSnackbar(t('event.errors.couldNotVote'), {
          variant: "error"
        })
      })
  }, [accountsEmails, accountsIds, user, enqueueSnackbar, dispatch, t, doSelectNextProposal]);

  const setShowSlots = React.useCallback((open, forVote) => {
    // enqueue dialog in it
    // ask for confirmation first
    const unblock = history.block();
    const doClose = () => {
      unblock();
      closeDialog();
    };
    enqueueDialog({
      content: proposal.iAmHost ?
        <FunnelAsDialog doForceMeeting={doForceMeeting} doClose={doClose} proposal={proposal} /> :
        <Slots proposal={proposal} onClose={doClose} forVote={forVote} />,
      doClose: doClose,
      mustConfirm: true,
      className: "no-overflow theme--light ",
      overrideDefaultProps: {
        maxWidth: "lg",
        className: "create-event-heatmap",
        fullWidth: true
      }
    });
  }, [closeDialog, enqueueDialog, history, proposal]);

  const doBack = React.useCallback(() => {
    // go to dashboard
    history.push(ROUTES.APP + "?" + proposal.id);
  }, [history, proposal]);
  if (!proposal) return null;

  return (
    <animated.div
      style={reveal}
      data-testid="event-proposal"
      className={
        "proposal-content fancy-scroll " + (showAsMobile ? "mobile" : "")
      }
    >
      <div className="proposal-data">
        <div className="proposal-details">
          <ProposalContent proposal={proposal} />
          {isEventEditable && (
            <ProposalActions
              loading={loading}
              proposal={proposal}
              setShowSlots={setShowSlots}
              doSelectNextProposal={doSelectNextProposal}
            />
          )}
        </div>
      </div>
      <div className="loader">{loading && <LinearProgress />}</div>
      <Timeline
        proposal={proposal}
        loading={loading}
        showSlots={isEventEditable ? undefined : setShowSlots}
        forceSlot={setShowSlots}
        accountsEmails={accountsEmails}
      />
      {!loading && <Funnel proposal={proposal} doSelectNextProposal={doSelectNextProposal} />}
      <div className="proposal-mobile-title">{proposal.title}</div>
      <DoneButton
        name="btn-clear-proposal"
        className="white no-margin back-btn"
        label={t("common.back")}
        onClick={doBack}
        icon={<ArrowBack />}
      />
    </animated.div>
  );
}
