import * as Yup from 'yup';
import {
  SUPPORTED_PROVIDERS
} from './consts';

export const EVENT_TITLE_MAX = 500;
export const EVENT_LOCATION_MAX = 1000;
export const EVENT_DURATION_MIN = 10;
export const EVENT_DURATION_MAX = 480;
export function isValidDuration(d) {
  return d >= EVENT_DURATION_MIN && d <= EVENT_DURATION_MAX
}
// 1st step
const EVENT_INFOS = {
  title: Yup.string().trim()
    .max(EVENT_TITLE_MAX, 'createEvent.form.errors.titleTooLong')
    .required('createEvent.form.errors.titleRequired'),
  // location: Yup.string()
  //   .max(EVENT_LOCATION_MAX, 'createEvent.form.errors.locationTooLong'),
  duration: Yup.number()
    .required('createEvent.form.errors.durationRequired'),
  customduration: Yup.number()
    .when('duration', {
      is: -1,
      then: Yup.number()
        .min(EVENT_DURATION_MIN, 'createEvent.form.errors.durationInvalid')
        .max(EVENT_DURATION_MAX, 'createEvent.form.errors.durationInvalid')
        .required(),
      otherwise: Yup.number().notRequired(),
    }),

  reminders: Yup.array()
    .test('reminder-validation', 'createEvent.form.errors.reminderInvalid',
      function (value) {
        // get calendar provider
        let cp = this.parent.calendar;
        if (cp && cp.provider)
          return value && value.length <= SUPPORTED_PROVIDERS[cp.provider];
        else return false;
      }),
  calendar: Yup.object()
    .test('calendar-validation', 'createEvent.form.errors.calendarInvalid',
      function (value) {
        return value && value.accountId && value.calendarId && SUPPORTED_PROVIDERS[value.provider];
      }),
  organizer: Yup.object({
    email: Yup.string().email().required()
  }).required(),
  // location: Yup.string().required('createEvent.form.errors.locationRequired'),
}
export const CALENDAR = Yup.object().test('calendar-validation', 'createEvent.form.errors.calendarInvalid',
  function (value) {
    return value && value.accountId && value.calendarId && SUPPORTED_PROVIDERS[value.provider];
  })
export const EventSchemaCalendar = Yup.object().shape({ calendar: CALENDAR });
const INVITEES_AND_TIMEFRAMES = {
  invitees: Yup.array().required('createEvent.forms.errors.guestsRequired')
    .of(Yup.string().email().max(512, 'createEvent.forms.errors.emailTooLong')),
  proposals: Yup.array().of(Yup.object({
    start: Yup.date().required(),
    end: Yup.date().required()
  })).required('createEvent.form.errors.missingCustomOptions'),
}
export const InviteesAndTimeframes = Yup.object().shape(INVITEES_AND_TIMEFRAMES);


export const EventSchemaStep1 = Yup.object().shape(EVENT_INFOS);

export const EventSchemaStep2 = Yup.object().shape({
  ...EVENT_INFOS,
  ...INVITEES_AND_TIMEFRAMES,
  location: Yup.string().max(1024),
  slots: Yup.array().of(Yup.object({
    day: Yup.date().required(),
    slots: Yup.array().of(
      Yup.object({
        start: Yup.date().required(),
        end: Yup.date().required()
      }).required()
    ).min(1)
  })).min(1)
});

export const MAIL = Yup.object().shape({
  mail: Yup.string().required().email(),
})

// Event proposal
const EVENT_PROPOSAL = {
  id: Yup.string().required(),
  status: Yup.string().oneOf(['pending', "confirmed", "cancelled"]), // and the rest
  duration: Yup.number().required(),
  organizer: Yup.object({
    account_id: Yup.string().required(),
    cal_id: Yup.string().required(),
    email: Yup.string().required(),
  }).required(),
  timeframes: Yup.array()
    .of(Yup.object({
      start: Yup.date().required(),
      end: Yup.date().required(),
    })).required(),
  attendees: Yup.array().of(Yup.object({
    email: Yup.string().required(),
    label: Yup.string(),
    response: Yup.string().oneOf(['yes', 'no', 'maybe']),
    selected_timeframes: Yup.array().of(Yup.number()),
  })).required(), // ????
  subject: Yup.string().required(), // is it title?
  note: Yup.string(),
  remnders: Yup.array().of(Yup.number()),
  location: Yup.string(),
  created_at: Yup.date().required(),
  updated_at: Yup.date().required(),
  expires_at: Yup.date().required(),
};
export const EventProposal = Yup.object().shape(EVENT_PROPOSAL);