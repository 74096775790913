import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DoneButton from '../../../../DoneButton';
import { ProposalContent } from '../proposal.content';
import "./created.scss";

export default function Created({ proposal }) {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = React.useState(false);
  const toggleDetails = React.useCallback(() => {
    setShowDetails(!showDetails);
  }, [showDetails]);
  if (!proposal || !proposal.created_at) return null;
  const iAmHost = proposal.iAmHost;
  const obo = proposal.onBehalfOfName; // is it an obo event?
  const createdAt = moment(proposal.created_at);

  const details = <div className="show-details">
    <DoneButton name="btn-details" className="white" onClick={toggleDetails} label={t(showDetails ? 'proposal.timeline.created.details_hide' : 'proposal.timeline.created.details')} />
    {showDetails && <ProposalContent className="timeline-details" proposal={proposal} />}
  </div>
  if (proposal.booking_link) {
    const firstGuest = Object.values(proposal.inviteesDetails || {})[0] || {};
    const by = firstGuest.name || firstGuest.email;
    return (
      <div className="timeline-item created invitee">
        <div className="as-invitee">{t('proposal.timeline.created.bookingLink' + (iAmHost ? 'Host' : 'Guest'), { by })}</div>
        <div className="created-at">{createdAt.format(t('common.fullDateFormat'))}</div>
        {details}
      </div>
    );
  }
  return iAmHost ? (<div className="timeline-item created owner">
    <div className="as-owner">{t(obo ? 'proposal.timeline.created.obo' : 'proposal.timeline.created.organizer', { obo })}</div>
    <div className="created-at">{createdAt.format(t('common.fullDateFormat'))}</div>
    {details}
  </div>) : (<div className="timeline-item created invitee">
    <div className="as-invitee">{t('proposal.timeline.created.invitee', { by: obo || proposal.organizer.name || proposal.organizer.email })}</div>
    <div className="created-at">{createdAt.format(t('common.fullDateFormat'))}</div>
    {details}
  </div>);
}