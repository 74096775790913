import moment from 'moment';
import { getDiff } from './to.offset';
/**
 * Remove periods too small for new duration
 * @param {Array} periods periods already savec
 * @param {number} duration in minutes, duration of event
 */
export function removeTooSmall( periods = [], duration = 60){
  let r = [];
  for(let p of periods){
    let {start, end} = p;
    let diff = getDiff(start, end);
    if(diff >= duration) r.push(p);
  }
  return r;
}