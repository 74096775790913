import React from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { parseQuery } from '../utils/parse.query';
export default function UseToolStatus(){
  const {t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(()=>{
    // check URL for status
    const query = parseQuery(window.location.search);
    if(query && query.tool_status && query.tool_status !== 'success'){
      // problem
      enqueueSnackbar(t('settings.sections.tools.errorInstall'),
      {
        variant: 'error',
        className: 'snack-error',
      })
    }
  }, [])
}