import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Title
} from '@material-ui/icons';
import './inputs.scss'
import NOOP from '../../../../utils/noop';

const DEFAULT_START = <Title />;

/**
 * Simple wrapper around a textfield to add custom styles
 * and functionalities
 * @param {string} label label for the input
 * @param {string} name name of the input REQUIRED
 * @param {string} placeholder helper for the input, default null
 * @param {string} helperText helper for the input, default null
 * @param {string} errorText error for the input, default null
 * @param {any} startAdornment icon or whatever to display as start adorment
 * @param {boolean} isRequired dispay required check if true
 * @param {boolean} isTouched Do this input get changed
 * @param {boolean} isValid dispay valide state if true
 * @param {boolean} multiline dispay multiline if true
 * @param {string} value value to display
 * @param {Function} onXXX change callback
 */
export default function DoneTextField({
  label = '',
  name = '',
  select = false,
  placeholder = '',
  helperText = '',
  errorText = '',
  startAdornment,
  isRequired = false,
  endAdornment,
  isTouched = false,
  isValid = true,
  multiline = false,
  value = '',
  onChange = NOOP,
  onBlur = NOOP,
  onKeyDown = NOOP,
  onKeyUp = NOOP,
  onFocus = NOOP,
  onPaste = NOOP,
  onDrag = NOOP,
  inputRef, // for some magic inside, not documented
  autoComplete = 'off',
  focus = false,
  type = 'text',
  rowsMax = 10,
  rowsMin = 2,
  inputProps,
  InputProps,
  className = "",
  min, max,
  children,
  autofocus = false,
}) {
  // Thanks, guy of MUI for this...
  let multi = multiline;
  if (process.env.NODE_ENV === 'test') multi = false;
  return (
    <TextField
      label={label}
      select={select}
      type={type || 'text'}
      autoComplete={autoComplete}
      id={name}
      name={name}
      placeholder={placeholder}
      multiline={multi ? true : undefined}
      rows={multi ? rowsMin : undefined}
      rowsMin={multi ? rowsMin : undefined}
      rowsMax={multi ? rowsMax : undefined}

      className={"done-input " + className + (isTouched && isValid ? ' done-input-valid' : '')}
      helperText={!isValid ? errorText : helperText}
      margin="normal"
      variant="outlined"
      value={value}
      min={min}
      max={max}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      onPaste={onPaste}
      onDragEnter={NOOP}
      onDragOver={NOOP}
      onDrop={onDrag}
      error={!isValid || undefined}
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...InputProps,
        'data-testid': "txt-" + name,
        autoFocus: autofocus ? true : undefined,
        startAdornment: startAdornment ? <InputAdornment position="start" >{startAdornment}</InputAdornment> : undefined,
        endAdornment: isRequired ? <InputAdornment position="end"><span className="required">*</span></InputAdornment> : endAdornment
      }}
      inputProps={inputProps}
      inputRef={inputRef}

    >{children}</TextField>
  );
}

export const DoneTextFieldRef = React.forwardRef((props, ref) => <DoneTextField inputRef={ref} {...props} />);