import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as ACTIONS from '../../../../actions'
import NOOP from '../../../../utils/noop';
import { LMTooltip } from '../../../tooltip/Tooltip';
import { HelpOutline, KeyboardArrowRight, } from '@material-ui/icons';
import { MAIL_RX } from "../invite/mailer";
import DoneButton from '../../../DoneButton';
import WelcomeMessage from "./welcome";
import DoneTextField from '../../create-event/inputs/Text';
import "./premium.scss";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useSnackbar } from 'notistack';
import { useDialog } from '../../../dialogs/dialog.provider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'
import SeatsUploader from './seats.uploader';
import { Trash } from '../../../../assets/icons';
import moment from 'moment';

export default function PremiumUser({
  profile, // user profile with billing information
  successfullSubscription = false, // if status = success in URL
  goToPortal = NOOP, // click handler, redirect to portal stripe
  loadingCustomerSession,
}) {

  const { t } = useTranslation();
  const isAdmin = !!(profile && profile.billing && profile.billing.is_admin);
  const admin_label = useMemo(() => {
    let label = '';
    if (profile && profile.billing && !isAdmin) {
      if (profile.billing.admin_name) {
        label = profile.billing.admin_name + (profile.billing.admin_email ? " (" + profile.billing.admin_email + ")" : "");
      } else if (profile.billing.admin_email) {
        label = profile.billing.admin_email;
      }
    }
    return label;
  }, [isAdmin, profile]);
  return [successfullSubscription ? <WelcomeMessage key="welcome" /> : null,
  <div key="page" className="section billing-premium-user" data-testid="billing-premium-user">
    <div className="title">{t('settings.sections.billing.premium.title')}</div>
    {isAdmin ? <>
      <div className="promo">{t('settings.sections.billing.premium.text1')}</div>
      <div className="promo">{t('settings.sections.billing.premium.text2')}</div>
      <DoneButton
        name="go-to-portal"
        className="premium footer"
        endIcon={loadingCustomerSession ? <CircularProgress size="1.5rem" className="no-margin" /> : <KeyboardArrowRight />}
        label={t('settings.sections.billing.premium.goToPortal')}
        onClick={goToPortal} />
    </>
      : admin_label && <div className="promo">
        <Trans i18nKey='settings.sections.billing.user.managed' values={{ admin_label }} />
      </div>}
  </div>,
  isAdmin ? <PremiumSeats profile={profile} key="premium-seats" goToPortal={goToPortal} /> : null];
}

function PremiumSeats({ profile, goToPortal }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { enqueueDialog, closeDialog } = useDialog();
  const isAdmin = profile && profile.billing && profile.billing.is_admin;
  const dispatch = useDispatch();
  const [seats, setSeats] = React.useState([])
  const [addMail, setaddMail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  // load seats
  React.useEffect(() => {
    if (!profile) return
    dispatch({
      type: ACTIONS.WORKER_GET_PREMIUM_SEATS,
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PREMIUM_SEATS_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PREMIUM_SEATS_ERROR
      }
    }).then((seats) => {
      setSeats(seats ? seats.payload : [])
    }).catch((err) => {
      console.error(err)
    })
  }, [profile]);
  const addSeats = React.useCallback((emails) => {
    if (!emails || !emails.length) return Promise.resolve();
    // if only one email if given, check it's an email
    if (emails.length === 1 && (!emails[0].toLowerCase() || !MAIL_RX.test(emails[0].toLowerCase()))) {
      // invalid email
      enqueueSnackbar(t("settings.sections.billing.admin.errors.notAnEmail"));
      return Promise.resolve();
    }
    return dispatch({
      type: ACTIONS.WORKER_ADD_PREMIUM_SEATS,
      payload: emails,
      resolvers: {
        resolveOn: ACTIONS.WORKER_ADD_PREMIUM_SEATS_SUCCESS,
        rejectOn: ACTIONS.WORKER_ADD_PREMIUM_SEATS_ERROR
      }
    }).then(() => {
      return dispatch({
        type: ACTIONS.WORKER_GET_PREMIUM_SEATS,
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PREMIUM_SEATS_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PREMIUM_SEATS_ERROR
        }
      })
    }).then((seats) => {
      setSeats(seats ? seats.payload : [])
      setaddMail("");
      enqueueSnackbar(t("settings.sections.billing.admin.success.addSeat"));
    }).catch((err) => {
      console.error(err)
      enqueueSnackbar(t("settings.sections.billing.admin.errors.addSeat"));
    })
  }, [dispatch, t, enqueueSnackbar]);
  const removeSeat = React.useCallback((id) => {
    return new Promise((resolve, reject) => {
      const doClose = () => {
        closeDialog();
        resolve();
      }
      const doConfirm = () => {
        closeDialog();
        dispatch({
          type: ACTIONS.WORKER_DEL_PREMIUM_SEATS,
          payload: id,
          resolvers: {
            resolveOn: ACTIONS.WORKER_DEL_PREMIUM_SEATS_SUCCESS,
            rejectOn: ACTIONS.WORKER_DEL_PREMIUM_SEATS_ERROR
          }
        }).then(() => {
          return dispatch({
            type: ACTIONS.WORKER_GET_PREMIUM_SEATS,
            resolvers: {
              resolveOn: ACTIONS.WORKER_GET_PREMIUM_SEATS_SUCCESS,
              rejectOn: ACTIONS.WORKER_GET_PREMIUM_SEATS_ERROR
            }
          })
        }).then((seats) => {
          setSeats(seats.payload)
          resolve();
        }).catch((err) => {
          console.error(err);
          enqueueSnackbar(t("settings.sections.billing.admin.errors.deleteSeat"));
          reject();
        })
      }
      enqueueDialog({
        content: <ConfirmDlg onClose={doClose} onConfirm={doConfirm} />,
        doClose: doClose,
      })
    })
  }, [enqueueDialog, dispatch, t, enqueueSnackbar, closeDialog])
  const onKeyDown = React.useCallback((e) => {
    if (e.keyCode === 13) {
      let email = addMail.trim().toLowerCase();
      if (email === '') return false;
      addSeats([email]);
    }
  }, [addMail, addSeats]);
  const totalSeats = useMemo(() => {
    if (profile && profile.billing && profile.billing.seats) return profile.billing.seats;
    else return 0;
  }, [profile])
  const occupiedSeats = (seats || []).length;
  const freeSeats = totalSeats - occupiedSeats;

  return (
    <div className='section premium-seats'>
      {/* only one seat, user cannot manage seats */}
      {totalSeats === 1 &&
        <>
          <div className="title">{t('settings.sections.billing.admin.title', { count: totalSeats, nbSeats: totalSeats })}</div>
          <div><Trans i18nKey='settings.sections.billing.admin.purchaseForTeam' components={
            {
              "strong": <a className="strong gottoportal" href='#' onClick={goToPortal} />
            }
          } /></div>
        </>
      }
      {/* more than one seats, user can manage seats */}
      {totalSeats > 1 &&
        <div className="seats">
          <div className='manage-seats'>
            <div className='title-container'>
              <div className="title">{t('settings.sections.billing.admin.title', { count: totalSeats, nbSeats: totalSeats })}</div>
              {freeSeats > 0 &&
                <div><Trans i18nKey='settings.sections.billing.admin.canAssign' count={freeSeats} /></div>}
            </div>
            {freeSeats > 0 &&
              <div className='add-seat'>
                <div className='input-and-upload'>
                  <div className='form'>
                    <DoneTextField
                      value={addMail}
                      onChange={(e) => setaddMail(e.target.value)}
                      onKeyDown={onKeyDown}
                      label={t('settings.sections.billing.admin.assignInputLabel')}
                      className='assign'></DoneTextField>
                    <DoneButton label={t("settings.sections.billing.admin.assignCTA")} onClick={() => addSeats([(addMail || '').toLowerCase()])} className='assign'></DoneButton>
                  </div>
                  <div className="upload">
                    <SeatsUploader addSeats={addSeats} assignedSeats={seats} nbSeats={profile && profile.billing && profile.billing.seats} />
                  </div>
                </div>
              </div>}
          </div>
          {/* display list of seats */}
          <div className='seats-list'>
            <div className="title">{t('settings.sections.billing.admin.assignedSeats')}</div>
            <TableContainer className="LMTable">
              <Table>
                <TableHead aria-label="Assigned Licenses Table">
                  <TableRow>
                    <TableCell>{t('settings.sections.billing.admin.addSeatsTable.columnUser')}</TableCell>
                    <TableCell>{t('settings.sections.billing.admin.addSeatsTable.columnAssigned')}</TableCell>
                    <TableCell>{t('settings.sections.billing.admin.addSeatsTable.columnRegistered')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    seats
                      .sort((a, b) => (a.email).localeCompare(b.email))
                      .sort((a, b) => {
                        return a.user_id ? -1 : 1;
                      })
                      .map((seat) => {
                        let isMe = seat.user_id === profile.billing.admin_id && isAdmin;
                        return (
                          <TableRow key={seat.user_id || seat.email}>
                            <TableCell>
                              {
                                isMe ? (<span>
                                  <strong>{t('common.you')}</strong>
                                  <LMTooltip content={<p>{t('settings.sections.billing.admin.seatYours')}</p>} ><HelpOutline className='help' /></LMTooltip>
                                </span>)
                                  : (
                                    seat.user_id ?
                                      (
                                        seat.email ?
                                          <strong>{seat.name ? (seat.name + " ") : seat.email} {seat.name && seat.email}</strong>
                                          : seat.user_id + ' (revoked)'
                                      )
                                      : <>{seat.name ? (seat.name + " ") : seat.email} {seat.name && seat.email}</>
                                  )
                              }</TableCell>
                            <TableCell className='date-day'>{moment(seat.created_at).format(t('common.dayFormat'))}</TableCell>
                            <TableCell>{(isMe || seat.user_id) ? t('common.yes') : ''}</TableCell>
                            <TableCell>
                              {!isMe && <RemoveButton removeSeat={() => removeSeat(seat.user_id || seat.email)} />}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      }
      {totalSeats > 1 && freeSeats === 0 &&
        <div className="section">
          <div>{t('settings.sections.billing.admin.allAssigned')}</div>
          <div className='add-licenses'><Trans i18nKey='settings.sections.billing.admin.addSeats'></Trans></div>
        </div>
      }
    </div>
  );
}
const ConfirmDlg = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  return <div className="confirm-dialog" data-testid="confirm-dialog-dialog">
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {t('settings.sections.billing.admin.deleteDlg.message')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary" data-testid="confirm-event-dialog-cancel">
        {t('settings.sections.billing.admin.deleteDlg.dismiss')}
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus data-testid="confirm-event-dialog-ok">
        {t('settings.sections.billing.admin.deleteDlg.ok')}
      </Button>
    </DialogActions>

  </div>
}

function RemoveButton({ removeSeat }) {

  const [isLoading, setIsLoading] = useState(false);

  const removeCallback = () => {
    setIsLoading(true);
    removeSeat().finally(() => setIsLoading(false));
  }

  return (
    <IconButton aria-label="delete" onClick={removeCallback} size='small'>
      {isLoading ? <CircularProgress size="1rem" /> : <Trash />}
    </IconButton>);
}
