export const FLOOR = 0, CEIL = 1, BEST = 2;
/**
 * Pad a date to quarter (ie 15mins)
 * @param {moment} date date to pad
 * @return {moment} padded date 
 */
export const padToQuarter = (date, approx = BEST) => {
  let dt = date.clone();
  let m = dt.minutes() % 15;

  if (approx === FLOOR) dt.minutes(dt.minutes() - m);
  else if (approx === CEIL) dt.minutes(dt.minutes() + ( m > 0 ? 15 - m : 0));
  else {
    // best of 2
    if (m > 7.5 || (m === 7 && dt.seconds() >= 30))
      dt.minutes(dt.minutes() + (15 - m));
    else dt.minutes(dt.minutes() - m);
  }
  
  // remove seconds
  dt.seconds(0);
  dt.milliseconds(0);

  return dt;
}