/**
 * Returns the monogram for a full name.
 * 
 * Examples: 
 *      "Mathieu Leddet" returns "ML"
 *      "Mathieu de Leddet" return "ML"
 *      "Mathieu" return "MA"
 *      undefined return ""
 * 
 * @param {string} fullName 
 * @returns {string} 2-letter monogram
 */
export function monogram(fullName) {


  if (!fullName) return "";
  let fn = fullName.replace(/\(.*\)$/, '').trim();// remove you, me, vous...
  return fn.split(' ')
      .filter((w) => !w.startsWith('+'))
      .map((word, index, words) => {
          // remove non alphab char
          word = word.replace(/\W/, '');
          if (words.length === 1 && word.length > 1) return word.substring(0, 2);
          if (index === 0 || index === (words.length - 1)) return word.charAt(0);
          return null;
      }).join('').toUpperCase();

}