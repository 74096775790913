import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import DoneTextField from '../../create-event/inputs/Text';
import {
  useDispatch,
} from 'react-redux';
import { LMTooltip } from '../../../tooltip/Tooltip';
import { username_as_slug } from '../../create-event/booking.link';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from '../../../../utils/debounce'
import * as ACTIONS from '../../../../actions';
import {
  HelpOutline,
} from '@material-ui/icons';
import './booking.link.scss';

export default function LinkSlug({ profile = {}, debounce_delay = 300 }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // generic when more options will come...
  const [loading, setLoading] = React.useState(false)
  const [userSlug, setUserSlug] = React.useState(profile.link_name)
  const [isSlugInUse, setisSlugInUse] = React.useState(false);
  const __hndSlugChange = React.useMemo(() => debounce((e) => {
    let sl = e[0]
    if (sl && sl.length >= 3 && sl.length < 255) {
      setLoading(true)
      dispatch({
        type: ACTIONS.WORKER_USER_VALIDATE_SLUG,
        payload: {
          slug: sl,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_USER_VALIDATE_SLUG_SUCCESS,
          rejectOn: ACTIONS.WORKER_USER_VALIDATE_SLUG_ERROR
        }
      }).then(() => {
        // valide, save
        setisSlugInUse(false)
      }).catch(err => {
        // invalid, show error
        setisSlugInUse("settings.sections.advanced.bookingLink.errors.slugAlreadyInUse")
      }).finally(() => {
        setLoading(false)
      })
    } else {
      setisSlugInUse(false);
    }

  }, { wait: debounce_delay, maxWait: 1000, maxCall: 100 }), [])
  const hndSlugChange = React.useCallback((e) => {
    let chaboured_slug = e.target.value || ''
    chaboured_slug = username_as_slug({ label: chaboured_slug })

    if (chaboured_slug != "") {
      setisSlugInUse(false)
      __hndSlugChange(chaboured_slug);
    }
    setUserSlug(chaboured_slug)
  }, [__hndSlugChange]);
  const hndSlugBlur = React.useCallback(() => {
    if (isSlugInUse || profile.link_name === userSlug) return;
    profile.link_name = userSlug;
    // save profile
    dispatch({
      type: ACTIONS.WORKER_SAVE_WH,
      payload: {
        ...profile
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_SAVE_WH_SUCCESS,
        rejectOn: ACTIONS.WORKER_SAVE_WH_ERROR
      }
    }).catch(err => {
      // invalid, show error
      enqueueSnackbar(t("settings.sections.advanced.bookingLink.errors.errorOnSave"), {
        variant: 'error',
        className: 'snack-error',
      })

    }).finally(() => {
      setLoading(false)
    })
  }, [userSlug, isSlugInUse, profile]);
  return (
    <div className={"section advanced-item " + (loading ? 'inactive' : '')}>
      <div className="title">{t('settings.sections.advanced.bookingLink.title')}</div>
      <div className="subtitle">{t("settings.sections.advanced.bookingLink.subtitle")}</div>
      <div className="bookingLink">
        <div className="bookingLink-item">
          <DoneTextField
            label={
              <>{t('settings.sections.advanced.bookingLink.linkLabel')}
                <LMTooltip
                  childrenClassName="showHand"
                  content={
                    <>
                      <p><Trans i18nKey="settings.sections.advanced.bookingLink.linkHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
                    </>
                  }
                >
                  <HelpOutline />
                </LMTooltip></>}
            value={userSlug}
            className="with-help no-bottom slug-link"
            InputLabelProps={{ className: "with-help-button" }}
            name='slug'
            onChange={hndSlugChange}
            onBlur={hndSlugBlur}
            errorText={isSlugInUse && t(isSlugInUse)}
            startAdornment={`letsmeet.network/`}
            endAdornment={loading ? <CircularProgress /> : null}
            isValid={!isSlugInUse}
            inputProps={
              {
                "data-testId": "slug"
              }
            }
          />
        </div>
      </div>
    </div>
  )
}