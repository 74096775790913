import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
  ExpandMore,
  ExpandLess,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Today,
} from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import NOOP from '../../../../../utils/noop';
import DonePicker from '../../../../donePicker';
import './week.selector.scss';

const ANCHOR = {
  vertical: 'bottom',
  horizontal: 'right',
};

const TRANSFORM = {
  vertical: 'top',
  horizontal: 'center',
};

export default function WeekSelector({
  selectedDate = moment(),
  onChange = NOOP,
  storybookFormat, // storybook only
}) {

  const { t } = useTranslation();
  const [showPopup, setShowPopup] = React.useState(false);

  const handleClose = React.useCallback(() => setShowPopup(false), [setShowPopup]);

  const nextWeek = React.useCallback(() => {
    onChange(selectedDate.clone().add(1, 'week'));
  }, [onChange, selectedDate])

  const prevWeek = React.useCallback(() => {
    onChange(selectedDate.clone().subtract(1, 'week'));
  }, [onChange, selectedDate])

  const week = React.useMemo(() => ({
    start: selectedDate.clone().startOf('week'),
    end: selectedDate.clone().endOf('week')
  }), [selectedDate]);

  const isPrevInPast = week.start < moment();

  // is now in period
  const showTodayButton = React.useMemo(() => {
    const now = moment();
    return now < week.start || now > week.end;
  }, [week]);

  const periodStr = React.useMemo(() => {
    return periodToString(week, t, storybookFormat)
  }, [week, t, storybookFormat])

  return (
    <div className="week-selector">
      <div>
        {showTodayButton &&
          <Button data-testid='btn-today' size="small" className="todayBtn" onClick={() => onChange(moment())}><Today /> {t('common.today')}</Button>}
        <div onClick={(e) => { setShowPopup(e.currentTarget) }}>
          <span className="periodStr">{periodStr}
            <IconButton data-testid='btn-popup' size="small" >{showPopup ? <ExpandLess /> : <ExpandMore />}</IconButton></span>
        </div>
        <div className='prev-next'>
          <IconButton data-testid='btn-prev' size="small" disabled={isPrevInPast} onClick={prevWeek}><KeyboardArrowLeft /></IconButton>
          <IconButton data-testid='btn-next' size="small" onClick={nextWeek}><KeyboardArrowRight /></IconButton>
        </div>
      </div>
      {!!showPopup &&
        <Popover
          open={!!showPopup}
          anchorEl={showPopup}
          onClose={handleClose}
          anchorOrigin={ANCHOR}
          transformOrigin={TRANSFORM}
          className="pick-a-period"
        >
          <DonePicker mini={true} onChange={onChange} current={selectedDate} disableNextMonth={true} />
        </Popover>}
    </div>
  );
}

export function periodToString(week, t, storybookFormat = false) {
  let { start, end } = week;
  let isSameYear = start.year() === end.year();
  let isSameMonth = start.month() === end.month();

  let rep = '';
  if (!isSameYear) {
    // rep = `${start.format(storybookFormat || t('common.dayFormat'))}-${end.format(t(storybookFormat || 'common.dayFormat'))}`;
    rep = `${start.format(storybookFormat || t('MMMM YY'))} - ${end.format(t(storybookFormat || 'MMMM YY'))}`;
  } else if (!isSameMonth) {
    // rep = `${start.format(t(storybookFormat || 'common.monthFormat'))}-${end.format(t(storybookFormat || 'common.monthFormat'))}, ${start.format('YYYY')}`;
    rep = `${start.format(t(storybookFormat || 'MMMM'))} - ${end.format(t(storybookFormat || 'MMMM'))}`;
  } else {
    // rep = `${start.format('MMM DD')}-${end.format('DD')}, ${start.format('YYYY')}`;
    rep = `${start.format('MMMM')}`;
  }
  return rep;
}