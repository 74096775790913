import moment from 'moment';

export const LS_KEY = "current_form_values"
export const LS_LINK_KEY = "current_link_form_values"
export const LS_VALIDITY = 1 * 60 * 60 * 1000
export function saveInCache(proposal, key = LS_KEY) {
  // add now as extra info, will be discard if more than 1hour?
  // console.log('Save in cache')
  proposal.__created_at__ = Date.now()
  try { localStorage.setItem(key, JSON.stringify(proposal)); } catch (err) {/* we've done what we could, too bad! */ }
}
export function loadFromCache(remove = true, useGlobalVoteRequired = true) {
  try {
    let proposal = localStorage.getItem(LS_KEY);
    /* istanbul ignore else */
    if (remove) {
      localStorage.removeItem(LS_KEY);
    }

    if (proposal != null && proposal != undefined) {
      proposal = JSON.parse(proposal)
      // check validity -too old are discared
      let createdAt = proposal.__created_at__ || 0;
      if (createdAt + LS_VALIDITY < Date.now()) return false;
      /* istanbul ignore else no work */
      if (proposal.proposals) {
        for (let p of proposal.proposals) {
          p.start = moment(p.start);
          p.end = moment(p.end);
        }
      }
      /* istanbul ignore else no work */
      if (proposal.slots) {
        for (let day of proposal.slots) {
          day.day = moment(day.day)
          for (let slot of day.slots) {
            slot.start = moment(slot.start);
            slot.end = moment(slot.end);
          }
        }

      }
      proposal.opts = undefined; // we do not save this
      if (useGlobalVoteRequired) {
        proposal.vote_required = proposal.vote_required || false;
      }
      // else take infos from inviteesDetails...
      proposal.proposal_type = '';
      // if (proposal.conference != "") proposal.location = "";
      proposal.__load_from_cache = true;
      return proposal;
    }
  } catch (err) {
    return false;
  }
}
export function loadLinkFromCache() {
  try {
    let link = localStorage.getItem(LS_LINK_KEY);
    localStorage.removeItem(LS_LINK_KEY);

    if (link != null && link != undefined) {
      link = JSON.parse(link)
      // check validity -too old are discared
      let createdAt = link.__created_at__ || 0;
      if (createdAt + LS_VALIDITY < Date.now()) return false;
      return link
    }
  } catch (err) {
    return false
  }
}
// return the 1st slot date if exists, now otherless
export function getInitialFBDate(proposal = {}) {
  if (proposal.slots && proposal.slots.length > 0) {
    return proposal.slots[0].day;
  } else return moment();
}
export function clearCache(key = LS_KEY) {
  try { localStorage.removeItem(key) } catch (err) { }
}