/**
 * @fileOverview Main application routing
 * @author stephane@letsme.et :P
 * @module routing
 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
  Link,
} from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTooltop } from './components/dialogs/tooltop.provider';
import { IconDelete } from './assets/icons'
import ROUTES from './routes/routes.name';
import Landing from './components/pages/landing';
import CreateEvent from './routes/create.event';
import BookingPage from './components/pages/booking'
import CreateBookingLink from './routes/create.link'
import DashBoard from './routes/dashboard';
import Proposal from './components/pages/proposal';
import useTheme from './hooks/use.theme';
import { isAuth } from './reducers/user';
import { getDefaultCalendar, areAllAccountsRevoked } from './reducers/accounts'
import './App.scss';
import { LS_KEY_BACK_HERE_AFTER_SIGNUP } from './components/pages/event/simple';
import routes from './routes/routes.name';


function ErrorTooltop({ doClose, error }) {
  const { t } = useTranslation();
  return <div className="tooltop-error">
    <IconDelete onClick={doClose} />
    <span>{t('common.error.' + error)}</span>
  </div>
}
function ReconnectTooltop() {
  const { t } = useTranslation();
  const location = useLocation();
  const isAccountsPage = location && location.pathname && location.pathname.startsWith(routes.APP_SETTINGS_ACCOUNTS);
  return <div className="tooltop-error" data-testId="reconnect-error">
    <span>{t('errors.allAccountsRevoked')}</span>
    {!isAccountsPage && <Link to={ROUTES.APP_SETTINGS_ACCOUNTS}>{t("settings.sections.accounts.tooltopErrorLink")}</Link>}
  </div>
}

export function App({ isAuth, hasCalendars, allAccountRevoked, test }) {
  let location = useLocation();
  let { t } = useTranslation();
  const [originalRoute, setOriginalRoute] = useState(location.pathname);
  const register = useSelector((state) => state.register);
  const { enqueueTooltop, closeTooltop } = useTooltop();
  const theme = useTheme(); // theme of application

  React.useEffect(() => {
    // add class to body of element
    /* istanbul ignore else test environement */
    if (document) document.body.classList.add(theme)
  }, [theme])

  React.useEffect(() => {
    if (register) {
      const close = () => {
        closeTooltop();
      }
      enqueueTooltop({ content: <ErrorTooltop doClose={close} error={register} /> })
    }
  }, [register])
  React.useEffect(() => {
    if (allAccountRevoked) enqueueTooltop({ content: <ReconnectTooltop /> });
  }, [allAccountRevoked])
  if (!navigator.cookieEnabled) {
    // show dedicated cookie mandatory page
    return <div className={"App " + theme}>You must enable cookies to use this site</div>
  }
  // change: if all revoked, let user enter in case of
  if (isAuth && !hasCalendars && !allAccountRevoked) {
    return <div className={"App " + theme}>
      <div className="no-calendar">
        <span><Trans i18nKey='common.noCalendar'>message <a href="mailto:support@letsme.et">support</a></Trans></span>
        <a href='.'>{t('common.reloadPage')}</a>
      </div>
    </div>
  }

  return (
    <div className={"App " + theme}>
      <Switch location={location}>
        {/* Main entry point: do nothing except redirect*/}
        <Route
          path="/"
          exact={true}
          render={(props) => <Redirect
            to={
              {
                pathname: ROUTES.AUTH,
                search: location.search
              }}
          />
          } />
        {/* User is not auth or ready to go (ie: db not loaded)*/}
        <Route
          path={ROUTES.AUTH}
          exact={false}
          render={(props) => {
            let route = originalRoute;
            if (!originalRoute.startsWith(ROUTES.APP)) route = ROUTES.APP;
            // originalRoute = undefined;
            return isAuth ? <Redirect to={
              {
                pathname: route,
                search: location.search
              }
            } /> : <Landing />;
          }} />
        {/* user is connected AND db is ready*/}
        <Route
          path={ROUTES.APP}
          exact={false}
          render={(props) => {
            // should have a connection status to STATUS_AUTHENTICATED
            if (!isAuth) return <Redirect to={
              {
                pathname: ROUTES.AUTH,
                search: location.search
              }} />
            else return <DashBoard />;
          }} />
        <Route
          path={ROUTES.CREATE_A_MEETING}
          exact={false}
          render={(props) => {
            // should have a connection status to STATUS_AUTHENTICATED
            if (!isAuth) {
              try {
                const urlToGoBackTo = window.location && window.location.href; //location.pathname;
                localStorage.setItem(LS_KEY_BACK_HERE_AFTER_SIGNUP, urlToGoBackTo);
              } catch (err) {
                // could not access LS, we will not be able to redirect later.
              }
            }
            return <CreateEvent />;
          }} />
        <Route
          path={ROUTES.CREATE_A_LINK}
          exact={false}
          render={(props) => {
            // should have a connection status to STATUS_AUTHENTICATED
            if (!isAuth) {
              try {
                const urlToGoBackTo = window.location && window.location.href; //location.pathname;
                localStorage.setItem(LS_KEY_BACK_HERE_AFTER_SIGNUP, urlToGoBackTo);
              } catch (err) {
                // could not access LS, we will not be able to redirect later.
              }
            }
            return <CreateBookingLink />;
          }} />
        <Route
          path={ROUTES.EVENT + ':id'}
          exact={true}
          render={(props) => {
            // should have a connection status to STATUS_AUTHENTICATED
            return <Proposal />;
          }} />
        <Route
          path="/:username/:slug"

          render={(props) => {
            return <BookingPage />
          }} />
        {/* whatever the unknown route, go back to root */}
        <Route path="*" render={(props) => <Redirect to={ROUTES.APP} />} />
      </Switch>
      {/*<CookieConsent cookieName={COOKIE_NAME} overlay onAccept={setCookie}>{t('common.cookies')}</CookieConsent>*/}
    </div>);
}


/* istanbul ignore next redux conf */
const mstp = (state) => ({
  isAuth: isAuth(state),
  hasCalendars: getDefaultCalendar(state),
  allAccountRevoked: areAllAccountsRevoked(state)
});
/* istanbul ignore next redux conf */
const mdtp = (dispatch) => ({
})
/* istanbul ignore next redux conf */
export default connect(mstp, mdtp)(App);
