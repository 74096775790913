/*

--------------- THIS IS UNSUED REFACTO NEEDED ---------------

*/

import React from "react";
import { BROWSER } from "../../../../utils/browser";
import { LogoDone } from "../../../../assets/icons";
import DashboardMenu from "../../dashboard/menu";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ROUTES from "../../../../routes/routes.name";
import { useSelector, useDispatch } from "react-redux";
import * as ACTIONS from "../../../../actions";
import { getProposal } from "../../../../reducers/current.proposal";
import { getUser } from "../../../../reducers/user";
import { useLocation, useParams } from "react-router-dom";
import { getAccountMails, getAccountsIds } from "../../../../reducers/accounts";
import Proposal from "./proposal";
import ProposalError from "./proposal.error";
import ProposalNotFound from "./proposal.not.found";
import "./connected.scss";
export function Event() {
  const { t } = useTranslation();
  const location = useLocation();
  const urlparams = useParams();
  const dispatch = useDispatch();
  // How to handle status?
  const [loading, setLoading] = React.useState(false);
  // check if user already vote?
  const [exists, setExists] = React.useState(true); // be optimist!
  const [error, setError] = React.useState(false); // be optimist!
  const user = useSelector(getUser);
  const accountsEmails = useSelector(getAccountMails);
  const accountsIds = useSelector(getAccountsIds);
  const accounts = useSelector(state => state.accounts);
  const [reload, setReload] = React.useState(false);
  // get id
  let path = location.pathname.split("/");
  let id = path[path.length - 1];
  // for now, get mocks
  const proposal = useSelector(getProposal(id));
  const isMobile =
    BROWSER.getPlatformType() === "mobile" ||
    BROWSER.getPlatformType() === "tablet";

  React.useEffect(() => {
    // dummy check for id and user
    /* istanbul ignore if */
    if (!user || !user.isAuth) return;
    if (accountsEmails.length === 0 || accountsIds.length === 0) return;

    /* istanbul ignore if */
    if (!urlparams.id) {
      setExists(false);
      return;
    }

    // check if got a sig AND a mail, if not => error
    setLoading(true);
    dispatch({
      type: ACTIONS.WORKER_GET_PROPOSAL,
      payload: {
        id: urlparams.id,
        checkBusyTime: user.email,
        addProposalIDToBusyRequest: urlparams.id,
        userEmail: accountsEmails,
        userAccounts: accountsIds,
        bestSuggestions: true
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
      }
    })
      .then(res => {
        dispatch({
          type: ACTIONS.ANALYTICS_VIEW_PROPOSAL,
          payload: {
            proposal: res.payload && res.payload[0]
          }
        });
        return res;
      })
      .then(res => {
        if (!res.payload || !res.payload[0]) {
          setExists(false);
        }
      })
      .catch(err => {
        console.log("Error:", err);
        if (err && err.status === 404) {
          setExists(false);
        } else {
          // display error
          console.log("Error loading event datas:", err);
          setError(true);
        }
      })
      .then(() => setLoading(false));
  }, [
    urlparams.id,
    user.isAuth,
    user.email,
    accountsEmails,
    accountsIds,
    dispatch,
    user,
    reload
  ]);

  // TODO REFACTO: remove this as it's unused. Double check in all children.
  const reloadProposal = React.useCallback(() => {
    setReload(!reload);
  }, [reload])

  // once proposal is loaded, check TZ
  React.useEffect(() => {
    if (proposal && proposal.onBehalfOf) {
      // get corresponding account
      let obo = proposal.onBehalfOf.split(":");
      let acc = (accounts || []).find(a => a.id === obo[0]);
      if (acc) {
        let c = (acc.calendars || []).find(c => c.id === obo[1]);
        if (c && c.timezone) {
          // set current TZ
          moment.tz.setDefault(c.timezone);
          dispatch({
            type: ACTIONS.WORKER_SET_TZ,
            payload: {
              timezone: c.timezone
            }
          });
        }
      }
    }
  }, [proposal, accounts, dispatch]);

  // need to check, depending on state of error/not found what to display
  let inner = error ? (
    <ProposalError />
  ) : !exists ? (
    <ProposalNotFound />
  ) : (
    <Proposal
      proposal={proposal}
      loading={loading}
      accountsEmails={accountsEmails}
      reloadProposal={reloadProposal}
    />
  );

  return (
    <div data-testid="user-event-page" className={"dashboard clean" + (isMobile ? " mobile" : "")}>
      <header>
        <div>
          <Link to={ROUTES.APP}>
            <LogoDone />
          </Link>
          <DashboardMenu />
        </div>
      </header>
      <section>
        <div
          className={
            "page-content page-proposal " + (isMobile ? " mobile" : "")
          }
        >
          {loading && <LinearProgress className="send-vote-progress" />}
          {!loading && inner}
        </div>
      </section>
    </div>
  );
}
