import { DEFAULT_START_HOUR, DEFAULT_END_HOUR } from './gen.timeframes';
export function split(start, end){
  // if start and end are distant enough, keep them
  if((end - start) > 1.5){
    let middle = start + (end - start) / 2;
    return {
            frames: [{
              start: start,
              end: middle - 0.5,
            }, {
              start: middle + 0.5,
              end: end ,
            }]
          }
  }
  return {
            frames: [{
              start: DEFAULT_START_HOUR,
              end: 12,
            }, {
              start: 14,
              end: DEFAULT_END_HOUR,
            }]
          }
}