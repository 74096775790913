export function parseQuery(q = '?', decode = true) {
  let query = q.substring(1);
  let pairs = query.split('&');
  let res = {};
  for (let pair of pairs) {
    let [name, value] = pair.split('=');
    if (!name) continue
    res[name] = decode ? decodeURIComponent(value) : value
  }
  return res;
}