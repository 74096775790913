import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import { getAccountMails, getAccountsIds } from "../../../reducers/accounts";
import { useDispatch, useSelector } from 'react-redux';
import * as ACTIONS from "../../../actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getDecoratedProposals } from '../../../reducers/proposals';
import { parseQuery } from "../../../utils/parse.query";
import moment from 'moment';
import Preview from '../create-event/Preview'
import { useSnackbar } from "notistack";
import copyLinkToClipboard from "copy-to-clipboard";
import {
  CalendarToday,
  ExpandMore, ChevronRight, Link,
} from '@material-ui/icons';
import DoneButton from '../../DoneButton';
import Button from '@material-ui/core/Button'
import ProposalCard from "./proposal.card";
import ROUTES from '../../../routes/routes.name';
import { useDialog } from "../../dialogs/dialog.provider";
import {
  PREFILL_EDIT
} from '../../../reducers/proposals';
import { attendeeHasVoted } from '../../../utils/get.attendee.score';
import { FunnelAsDialog } from "../proposal/connected/funnel";
import Drawer from '@material-ui/core/Drawer';
import ProposalDetails from './proposal.details';
import { CancelDialog } from '../proposal/connected/proposal.actions';
import Slots from "../proposal/connected/slots";
import {
  isMis, isOngoing, getProposalTab
} from '../../../utils/proposals.sort'
import ProposalsHeader from './header';
import {
  CircularProgress
} from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { isMobilePlateform } from '../../../utils/browser';

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Loader } from "../../Loader";
import "./dashboard.scss";
import { clearCache, LS_LINK_KEY } from '../../../utils/load.proposal.from.cache';
import { getLink } from '../../../utils/get.proposal.link';
const ITEMS_PER_PAGE = isMobilePlateform() ? 3 : 6;
const DLG_STYLE = { maxWidth: "lg" };
let INITIAL_LOAD = false; // do we already load some proposals?
export default function Dashboard() {
  const { t } = useTranslation();
  // const proposals = useSelector(getProposals);
  const proposals = useSelector(getDecoratedProposals);
  const links = useSelector((state) => state.links);

  const accountsEmails = useSelector(getAccountMails);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { enqueueDialog, closeDialog } = useDialog(DLG_STYLE);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const profile = useSelector(state => state.profile);
  const accountsIds = useSelector(getAccountsIds);
  // have an id?
  let query = parseQuery(location.search);
  const [selected, _setSelected] = React.useState(query.id);
  const [showDrawer, setshowDrawer] = React.useState(!!query.id);
  const setSelected = React.useCallback((id) => {
    _setSelected(id)
    /* istanbul ignore else */
    if (id) {
      setshowDrawer(true);
      // ensure we are scrolled (if paginated
      // if not, it will loose the scroll position)
      let prop = proposals[id];
      if (!prop) return;
      let tab = getProposalTab(prop);
      let srch = ['', '', ''];
      srch[tab] = id;
      setSearch(srch)
    }
  }, [proposals]);
  const closeDrawer = React.useCallback(() => {
    setshowDrawer(false)
    // let drawer close before reseting
    setTimeout(() => _setSelected(null), 195); // default closing transition time
  }, [])
  const [search, setSearch] = React.useState(["", "", ""]);
  // utility: load a proposal content and return a Promise
  const loadProposal = React.useCallback((proposal) => {
    // dummy check for id and user
    /* istanbul ignore if */
    if (!user || !user.isAuth) return;
    /* istanbul ignore if */
    if (accountsEmails.length === 0 || accountsIds.length === 0) return;

    /* istanbul ignore if */
    if (!proposal) return;
    // check if got a sig AND a mail, if not => error
    let cbtEmail;
    if (proposal.iAmHost) cbtEmail = proposal.organizer.email || user.email;
    const id = proposal.id;
    return dispatch({
      type: ACTIONS.WORKER_GET_PROPOSAL,
      payload: {
        id: id,
        checkBusyTime: cbtEmail, // proposal.organizer.email || user.email,
        userEmail: accountsEmails,
        userAccounts: accountsIds,
        addProposalIDToBusyRequest: id,
        bestSuggestions: true
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
        // requestId: uuid.v4(),    //  ----> @TODO cancel proposals on replay (after merge heatmap)
      }
    })
      .then(res => {
        dispatch({
          type: ACTIONS.ANALYTICS_VIEW_PROPOSAL,
          payload: {
            proposal: res.payload && res.payload[0]
          }
        });
        return res;
      });
  }, [
    user.isAuth,
    user.email,
    accountsEmails,
    accountsIds,
    dispatch,
    user,
  ]);
  // callbacks
  /* istanbul ignore next */
  const navigateToForm = React.useCallback(() => {
    clearCache();
    history.push(ROUTES.CREATE_A_MEETING);
  }, []);
  /* istanbul ignore next */
  const navigateToLinkForm = React.useCallback(() => {
    //clearCache(LS_LINK_KEY);
    history.push(ROUTES.CREATE_A_LINK);
  }, []);
  const onLink = React.useCallback((proposal) => {
    /* istanbul ignore if no work */
    if (!proposal || !proposal.id) return Promise.resolve();
    let id = proposal.id;
    // just generate a dummy link?
    let link = getLink(proposal);
    if (proposal.is_link) {
      // generate via slug, how to do that?
      // not no slug in profile for now....
      link = `${window.location.origin}/${profile.link_name}/${proposal.slug}`;
    }
    // dispatch analytics
    dispatch({
      type: ACTIONS.ANALYTICS_GET_LINK,
      payload: {
        proposal: proposal
      }
    });
    /* istanbul ignore else no work */
    if (copyLinkToClipboard(link)) {
      enqueueSnackbar(t("event.linkSuccess"), {
        variant: "success",
        className: "snack-success",
        action: key => {
          return (
            <>
              <Button
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                {t("common.ok")}
              </Button>
            </>
          );
        }
      });
    } else {
      enqueueSnackbar(t("event.linkError"), {
        variant: "error",
        className: "snack-error"
      });
    }

    return Promise.resolve();
  }, [profile]);
  const doForceMeeting = React.useCallback((event, slot) => {

    return dispatch({
      type: ACTIONS.WORKER_CREATE_PROPOSAL,
      payload: {
        proposal: event,
        opts: { slot: slot }
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_CREATE_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_CREATE_PROPOSAL_ERROR
      }
    }).then(() => {
      // reload datas?
      return dispatch({
        type: ACTIONS.WORKER_GET_PROPOSAL,
        payload: {
          id: event.id,
          checkBusyTime: user.email,
          userEmail: accountsEmails,
          userAccounts: accountsIds,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
        }
      })
    }).catch((err) => {
      console.log(err)
      // error message
      /* istanbul ignore if storybook */
      enqueueSnackbar(t('event.errors.couldNotVote'), {
        variant: "error"
      })
    })
  }, [])
  const onCreate = React.useCallback((proposal) => {
    return new Promise((resolve, reject) => {
      // block history navigation
      const unblock = history.block();
      const doClose = () => {
        unblock();
        closeDialog();
      };
      const doValid = (proposal, slot) => {
        return doForceMeeting(proposal, slot)
          .then(() => resolve())
          .catch((err) => reject(err));
      }
      enqueueDialog({
        content: <FunnelAsDialog reloadProposal={loadProposal} doForceMeeting={doValid} doClose={doClose} proposal={proposal} />,
        doClose: doClose,
        mustConfirm: true,
        className: "no-overflow theme--light ",
        overrideDefaultProps: {
          maxWidth: "lg",
          className: "create-event-heatmap",
          fullWidth: true
        }
      });
    });
  }, [doForceMeeting, loadProposal]);
  const onEdit = React.useCallback((proposal) => {
    // open form and prefill
    // save data in LS
    try {
      localStorage.setItem("PREFILL_FROM_ID", proposal.id);
      localStorage.setItem("PREFILL_TYPE", PREFILL_EDIT)
    } catch (err) { }
    // navigate
    history.push(ROUTES.CREATE_A_MEETING);
    return Promise.resolve();
  }, []);
  const onRemind = React.useCallback((proposal) => {
    if (!proposal || !proposal.id) return Promise.resolve();
    // get all left to vote
    // reminds them all
    let emails = Object.values(proposal.inviteesDetails || {})
      .filter((att) => {
        return !attendeeHasVoted(att)
      }).map((att) => att.email);
    if (emails.length === 0) return Promise.resolve();
    return dispatch({
      type: ACTIONS.WORKER_REMIND_ATTENDEES,
      payload: {
        emails: emails,
        proposalId: proposal.id
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_REMIND_ATTENDEES_SUCCESS,
        rejectOn: ACTIONS.WORKER_REMIND_ATTENDEES_ERROR,
      }
    }).then(() => {
      // attendee reminded
      enqueueSnackbar(t('proposal.remindAttendees.success'), {
        variant: 'success', className: "snack-success",
        action: (key) => <Button onClick={() => { closeSnackbar(key) }}>{t('common.ok')}</Button>
      })
    }).catch((error) => {
      // failed to remind attendee
      console.error("Error while reminding user: ", error);
      enqueueSnackbar(t('proposal.remindAttendees.failure'), {
        variant: 'error', className: "snack-error",
        action: (key) => <Button onClick={() => { closeSnackbar(key) }}>{t('common.ok')}</Button>
      })
    })
  }, []);
  const onCancel = React.useCallback((proposal) => {
    return new Promise((resolve, reject) => {
      const unblock = history.block();
      const doClose = () => {
        unblock();
        closeDialog();
      };
      const doSelectNextProposal = () => resolve();
      enqueueDialog({
        content: <CancelDialog proposal={proposal} onClose={doClose} doSelectNextProposal={doSelectNextProposal} />,
        doClose: doClose,
        mustConfirm: true
      });
    })

  }, []);
  const onReschedule = React.useCallback((proposal) => {
    if (!proposal || !proposal.id) return Promise.resolve();
    try {
      localStorage.setItem('PREFILL_FROM_ID', proposal.id);
    } catch (err) { }
    // go to form
    history.push(ROUTES.CREATE_A_MEETING);
    return Promise.resolve();
  }, [history]);
  const onVote = React.useCallback((proposal, vote) => {
    // enqueue dialog in it
    // ask for confirmation first
    return new Promise((resolve) => {
      const unblock = history.block();
      const doClose = () => {
        unblock();
        closeDialog();
        resolve();
      };
      enqueueDialog({
        content: <Slots proposal={proposal} onClose={doClose} forVote={true} />,
        doClose: doClose,
        mustConfirm: true,
        className: "no-overflow theme--light ",
        overrideDefaultProps: {
          maxWidth: "lg",
          className: "create-event-heatmap",
          fullWidth: true
        }
      });
    })

  }, []);
  const onShowReminder = React.useCallback((proposal) => {
    return new Promise((resolve) => {
      const doClose = () => {
        closeDialog();
        resolve();
      }
      const options = {
        show_reminders: true
      }
      enqueueDialog({
        content: <Preview proposal={proposal} options={options} doClose={doClose} />,
        overrideDefaultProps: { scroll: 'paper' },
        className: 'asDialog theme--light'
      })
    })
  }, [])
  const onActiveLink = React.useCallback((link) => {
    return dispatch({
      type: ACTIONS.WORKER_ACTIVATE_LINK,
      payload: link,
      resolvers: {
        resolveOn: ACTIONS.WORKER_ACTIVATE_LINK_SUCCESS,
        rejectOn: ACTIONS.WORKER_ACTIVATE_LINK_ERROR
      }
    }).catch((err) => {
      enqueueSnackbar(t("dashboard.section.schedulingLinks.errors.couldNotActivate"), {
        variant: "error",
        className: "snack-error"
      });
    })
  }, []);
  const onDeleteLink = React.useCallback((link, index) => {
    return new Promise((resolve, reject) => {
      const unblock = history.block();
      const doClose = () => {
        unblock();
        closeDialog();
      };
      enqueueDialog({
        content: <DeleteLinkDialog link={link} profile={profile} onClose={doClose} />,
        doClose: doClose,
        mustConfirm: true
      });
    })

  }, [profile]);
  const onViewLinkPage = React.useCallback((link) => {
    window.open(`${window.location.origin}/${profile.link_name}/${link.slug}`, "_blank")
    return Promise.resolve()
  }, []);
  const onEditLink = React.useCallback((link) => {
    history.push(ROUTES.CREATE_A_LINK, { link });
    return Promise.resolve();
  }, [])
  const onSelectLink = React.useCallback((id) => {
    let link = links.find((lnk) => lnk.id === id);
    if (link) {
      history.push(ROUTES.CREATE_A_LINK, { link });
    }
    return Promise.resolve();
  }, [links])

  // links specific callbacks
  React.useEffect(() => {
    if (window) {
      function hndFocus() {
        if (!user.email || !user.isAuth || !accountsIds || accountsIds.length === 0)
          return;
        Promise.all([
          dispatch({
            type: ACTIONS.WORKER_GET_PROPOSAL,
            payload: {
              user: user.email,
              userEmail: accountsIds.map(a => a.email), //accounts || [],
              userAccounts: accountsIds,
            },
            resolvers: {
              resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
              rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
            }
          }), dispatch({
            type: ACTIONS.WORKER_GET_MY_LINKS,
            payload: {
            },
            resolvers: {
              resolveOn: ACTIONS.WORKER_GET_MY_LINKS_SUCCESS,
              rejectOn: ACTIONS.WORKER_GET_MY_LINKS_ERROR
            }
          })]).catch((err) => {

          })
      }
      window.addEventListener("focus", hndFocus);
      return () => {
        window.removeEventListener("focus", hndFocus);
      }
    }
  }, [user.email, user.isAuth, accountsIds])
  // load all proposals and links
  React.useEffect(() => {
    if (!user.email || !user.isAuth || !accountsIds || accountsIds.length === 0)
      return;
    // load proposals by category?
    setLoading(true);
    let p = Promise.resolve();
    if (INITIAL_LOAD || selected) {
      p = Promise.all([
        dispatch({
          type: ACTIONS.WORKER_GET_PROPOSAL,
          payload: {
            user: user.email,
            userEmail: accountsIds.map(a => a.email), //accounts || [],
            userAccounts: accountsIds,
            fullLoad: selected
          },
          resolvers: {
            resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
            rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
          }
        }), dispatch({
          type: ACTIONS.WORKER_GET_MY_LINKS,
          payload: {
          },
          resolvers: {
            resolveOn: ACTIONS.WORKER_GET_MY_LINKS_SUCCESS,
            rejectOn: ACTIONS.WORKER_GET_MY_LINKS_ERROR
          }
        })]);
    } else {
      p = Promise.all([dispatch({
        type: ACTIONS.WORKER_GET_PROPOSAL,
        payload: {
          user: user.email,
          userEmail: accountsIds.map(a => a.email), //accounts || [],
          userAccounts: accountsIds,
          category: 'SENT', limit: ITEMS_PER_PAGE * 2
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
        }
      }),
      dispatch({
        type: ACTIONS.WORKER_GET_PROPOSAL,
        payload: {
          user: user.email,
          userEmail: accountsIds.map(a => a.email), //accounts || [],
          userAccounts: accountsIds,
          category: 'COMPLETED', limit: ITEMS_PER_PAGE * 2
          // sort: true,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
        }
      }),
      dispatch({
        type: ACTIONS.WORKER_GET_PROPOSAL,
        payload: {
          user: user.email,
          userEmail: accountsIds.map(a => a.email), //accounts || [],
          userAccounts: accountsIds,
          category: 'ARCHIVED', limit: ITEMS_PER_PAGE * 2
          // sort: true,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
        }
      }),
      dispatch({
        type: ACTIONS.WORKER_GET_MY_LINKS,
        payload: {
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_MY_LINKS_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_MY_LINKS_ERROR
        }
      })]).then(([sent, completed, archived]) => {
        // just to check
        // set initial load
        INITIAL_LOAD = {
          'SENT': sent.continuation,
          'COMPLETED': completed.continuation,
          'ARCHIVED': archived.continuation
        };
        // make an array of responses
        let r = {
          payload: [
            ...(sent.payload || []),
            ...(completed.payload || []),
            ...(archived.payload || [])
          ]
        }
        return r;
      });
    }

    /* istanbul ignore else UT specific */
    if (p && p.then) {
      p.then(evt => {
        // do we have a selected element?
        if (selected && evt.payload) {
          // do the stuff
          let prop = evt.payload.find(p => p.id === selected);
          if (prop) {
            // must select prop ------------------------------------> TODO
            let tab = getProposalTab(prop);
            let srch = ['', '', ''];
            srch[tab] = selected;
            setSearch(srch)
          }
        }
      })
        .catch(err => {
          console.error(err);
        })
        .then(() => setLoading(false));
    }
  }, [user.isAuth, user.email, accountsIds]);
  const sorted_proposals = React.useMemo(() => {
    let o = [], p = [], a = [];
    for (let proposal of Object.values(proposals)) {
      // check category
      if (isOngoing(proposal)) o.push(proposal);
      else if (isMis(proposal)) p.push(proposal);
      else a.push(proposal);
    }
    return [o.sort((a, b) => {
      // sort by creation date, most recent first
      return a.updated_at < b.updated_at ? 1 : -1;
    }), p.sort((a, b) => {
      // sort by creation date, most recent first
      return a.updated_at < b.updated_at ? 1 : -1;
    }), a.sort((a, b) => {
      // sort by creation date, most recent first
      return a.updated_at < b.updated_at ? 1 : -1;
    })];
  }, [proposals]);
  const [ongoing, planned, archived] = sorted_proposals;
  const sorted_links = React.useMemo(() => {
    return links.sort((a, b) => {
      // sort by creation date, most recent first
      return a.created_at < b.created_at ? 1 : -1;
    })
  }, [links]);
  // change TZ for calendar
  React.useEffect(() => {
    /* istanbul ignore if not possible here */
    if (!profile) return;
    let tz = profile.timezone;
    // change app tz
    moment.tz.setDefault(tz);
    // send to worker too
    dispatch({
      type: ACTIONS.WORKER_SET_TZ,
      payload: {
        timezone: tz
      }
    });
  }, [dispatch, profile, profile.timezone]);

  const doLoadNextProposals = React.useCallback((continuationToken) => {
    return dispatch({
      type: ACTIONS.WORKER_GET_PROPOSAL,
      payload: {
        user: user.email,
        userEmail: accountsIds.map(a => a.email), //accounts || [],
        userAccounts: accountsIds,
        continuation: continuationToken
        // sort: true,
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
      }
    })
  }, [user.email, accountsIds]);
  return <div className="home fadein dashboardv2" data-testid="home">
    {loading && (
      <div className="home-loader">
        <LinearProgress />
      </div>
    )}
    {!loading && <div className="content">
      <div className="dashboardv2-container">
        <ProposalsHeader title={t("dashboard.section.schedulingLinks.title")}
          ctaLabel={t(isMobilePlateform() ? 'dashboard.section.schedulingLinks.createMobile' : 'dashboard.section.schedulingLinks.create')}
          titleIcon={<Link />}
          ctaIcon={<Link />}
          helpKey={"dashboard.section.schedulingLinks.help"}
          hndClick={navigateToLinkForm} >
          {links.length === 0 ? <span className="empty-msg"><Trans i18nKey='dashboard.section.schedulingLinks.noLinks'
            components={{
              b: <b />
            }}>
            With scheduling links, <b>let people schedule meetings with you.</b>
          </Trans></span> : <MockedNetworkProposalsCategory proposals={sorted_links}
            label="link" foldable={false} setSelected={onSelectLink}
            onActive={onActiveLink} onDelete={onDeleteLink} onViewPage={onViewLinkPage} onEdit={onEditLink} onLink={onLink} continuation={'link'} doLoadNextProposals={doLoadNextProposals} />}
        </ProposalsHeader>
        <ProposalsHeader title={t("dashboard.section.meetings.title")} ctaLabel={t(isMobilePlateform() ? 'dashboard.section.meetings.createMobile' : 'dashboard.section.meetings.create')} titleIcon={<CalendarToday />} ctaIcon={<CalendarToday />}
          helpKey={"dashboard.section.meetings.help"}
          hndClick={navigateToForm} className="no-border">
          {(ongoing.length === 0 && planned.length === 0) && <span className="empty-msg"><Trans i18nKey="dashboard.section.meetings.noMeeting" components={{
            b: <b />,
            ul: <ul />,
            li: <li />,
            // br: <br />
          }}>
            This is the place to follow your meeting requests.<br />
            <b>Anytime you schedule a meeting</b> with Letsmeet you can see the progress here.<br />
            <b>Anytime someone schedule meeting with you</b> using one of your links it appears here.
          </Trans></span>}
          {<>
            {ongoing.length > 0 && <ProposalsCategory proposals={ongoing}
              folded={ongoing.length === 0}
              search={search[0]} selected={selected} setSelected={setSelected}
              label="ongoing"
              onCreate={onCreate} onEdit={onEdit} onLink={onLink} onRemind={onRemind} onCancel={onCancel} onReschedule={onReschedule}
              onVote={onVote} continuation={INITIAL_LOAD.SENT} doLoadNextProposals={doLoadNextProposals} />}
            {planned.length > 0 && <ProposalsCategory proposals={planned}
              search={search[1]} selected={selected} setSelected={setSelected}
              label="planned" onCreate={onCreate} onEdit={onEdit} onLink={onLink} onRemind={onRemind} onCancel={onCancel} onReschedule={onReschedule}
              onVote={onVote} continuation={INITIAL_LOAD.COMPLETED} doLoadNextProposals={doLoadNextProposals} />}
          </>}
          {archived.length > 0 && <CachedNetworkProposalsCategory proposals={archived} folded={true}
            search={search[2]} selected={selected} setSelected={setSelected}
            label="archive" onCreate={onCreate} onEdit={onEdit} onLink={onLink} onRemind={onRemind} onCancel={onCancel} onReschedule={onReschedule}
            onVote={onVote} continuation={INITIAL_LOAD.ARCHIVED} doLoadNextProposals={doLoadNextProposals} />}
        </ProposalsHeader>
      </div>
      <Drawer className="full-height" anchor="right" open={!!selected && showDrawer} onClose={closeDrawer}>
        <ProposalDetails id={selected} setSelected={setSelected} closeDrawer={closeDrawer}
          proposals={sorted_proposals}
          onCreate={onCreate}
          onEdit={onEdit} onLink={onLink} onRemind={onRemind}
          onCancel={onCancel} onReschedule={onReschedule} onVote={onVote}
          onShowReminder={onShowReminder} />
      </Drawer>
    </div>
    }
  </div >
}
/* istanbul ignore next no scroll */
function scrollTo(scrollerRef, selected, proposals) {
  let elem = document.getElementById(selected);
  let scroller = scrollerRef.current;
  // Note: scrollIntoView is not implemented is JSDom (ie Jest)
  // so check it exists
  if (elem && elem.scrollIntoView && proposals) {
    // if elem is one of mine!
    // else do not scroll!
    let p = proposals.find((i) => i.id === selected);
    if (p) {
      elem.scrollIntoView();
    }
  }
}
// for links, we mock the network access to allow navigating
// like the others elements
export function MockedNetworkProposalsCategory(props) {

  const [links, setLinks] = React.useState({
    display: props.proposals.slice(0, ITEMS_PER_PAGE),
    continuation: props.proposals.length > ITEMS_PER_PAGE ? 2 * ITEMS_PER_PAGE : undefined,
    current: ITEMS_PER_PAGE
  });
  React.useEffect(() => {
    setLinks({ display: props.proposals.slice(0, links.current), current: links.current });
  }, [props.proposals])
  const doLoadNextLinks = React.useCallback((token) => {
    // return next page of results?
    if (token) {
      setLinks({ display: props.proposals.slice(0, token), current: token });
      if (props.proposals.length > token) return Promise.resolve({ continuation: token + ITEMS_PER_PAGE });
    }
    return Promise.resolve({});
  }, [props.proposals]);

  return <ProposalsCategory {...props} proposals={links.display} continuation={links.continuation} doLoadNextProposals={doLoadNextLinks} />
}
// for others, we cache network results to handle the
// "we load everything upfront" when coming with an id in the request
const cached_reducer = (state, action) => {
  switch (action.type) {
    case 'UPD_DISPLAY':
      return { ...state, display: action.payload.display, current: action.payload.current };
    case 'UPD_TOKEN':
      return { ...state, continuationToken: action.payload };
    default:
      throw new Error();
  }
}
export function CachedNetworkProposalsCategory(props) {

  const [proposals, dispatch] = React.useReducer(cached_reducer, {
    display: props.proposals.slice(0, ITEMS_PER_PAGE),
    continuation: props.proposals.length > ITEMS_PER_PAGE ? 2 * ITEMS_PER_PAGE : undefined,
    continuationToken: props.continuation,
    current: ITEMS_PER_PAGE
  });
  // update content when list of proposals change
  React.useEffect(() => {
    dispatch({ type: 'UPD_DISPLAY', payload: { display: props.proposals.slice(0, proposals.current), current: proposals.current } });
  }, [props.proposals]);
  const doLoadNext = React.useCallback((e) => {
    let continuationToken = proposals.continuationToken;
    let doLoadNextProposals = props.doLoadNextProposals;
    /* istanbul ignore else */
    if (continuationToken) {
      return doLoadNextProposals(continuationToken).then((rep) => {
        dispatch({ type: "UPD_TOKEN", payload: rep.continuation });
        return rep.continuation;
      }).catch(err => {
        // ?
        console.log(err)
      })
    }
    return Promise.resolve();
  }, [proposals.continuationToken, props.doLoadNextProposals]);

  const doLoadNextLinks = React.useCallback((token) => {
    // return next page of results?
    /* istanbul ignore else */
    if (token) {
      // try to load next round of data
      dispatch({ type: 'UPD_DISPLAY', payload: { display: props.proposals.slice(0, token), current: token } });
      return doLoadNext()
        .then((continuation) => {
          if (props.proposals.length > token || continuation) return Promise.resolve({ continuation: token + ITEMS_PER_PAGE });
          else return {}
        })

    }
    return Promise.resolve({});
  }, [props.proposals, dispatch, doLoadNext]);

  return <ProposalsCategory {...props} proposals={proposals.display} continuation={proposals.continuation} doLoadNextProposals={doLoadNextLinks} />
}
function ProposalsCategory({ proposals, selected, setSelected,
  label, foldable = true, folded = false,
  onCreate, onEdit, onLink, onRemind, onCancel, onReschedule, onVote,
  onActive, onDelete, onViewPage, // link specials
  continuation, doLoadNextProposals }) {
  const { t } = useTranslation();
  const [expanded, _setExpanded] = React.useState(!folded);
  const setExpanded = React.useCallback((e, expanded) => {
    if (foldable) _setExpanded(expanded)
  }, [foldable, _setExpanded])

  const [continuationToken, setContinuationToken] = React.useState(continuation);
  const [loadingNext, setLoadingNext] = React.useState(false);
  React.useLayoutEffect(() => {
    // ensure selected is visible
    scrollTo({ current: window }, selected, proposals)
  }, [selected, proposals]);//only frst time
  const doLoadNext = React.useCallback((e) => {
    /* istanbul ignore if */
    if (loadingNext) return;
    /* istanbul ignore else */
    if (continuationToken) {
      setLoadingNext(true)
      doLoadNextProposals(continuationToken).then((rep) => {
        setContinuationToken(rep.continuation)
      }).catch(err => {
        // ?
        console.log(err)
      }).finally(() => setLoadingNext(false))
    }
  }, [continuationToken, doLoadNextProposals, loadingNext]);

  return (<Accordion expanded={expanded} onChange={setExpanded}>
    {foldable && <AccordionSummary >
      <div className="tab-panel-header">{expanded ? <ExpandMore /> : <ChevronRight />}<span>{t('dashboard.v2.headers.' + label)}</span></div>
    </AccordionSummary>}
    <AccordionDetails>
      <div className="tab-panel">
        {proposals.length === 0 && <span>{t('dashboard.v2.headers.' + label + 'None')}</span>}
        {proposals && (proposals.length > 0) &&
          proposals.map((p) => {
            return <div key={p.id} id={p.id} onClick={() => setSelected && setSelected(p.id)} className="proposal-card-container-parent">
              <ProposalCard proposal={p} setSelected={setSelected} selected={selected === p.id}
                onCreate={onCreate} onEdit={onEdit} onLink={onLink} onRemind={onRemind}
                onCancel={onCancel} onReschedule={onReschedule} onVote={onVote}
                onActive={onActive} onDelete={onDelete} onViewPage={onViewPage} />
            </div>
          })}

      </div>
      {
        continuationToken ? <DoneButton name="btn-load-next" className="grey" onClick={doLoadNext} label={
          loadingNext ? <><CircularProgress />{t('dashboard.section.loading')}</> : t('dashboard.section.loadMore')
        } /> : null
      }
    </AccordionDetails>
  </Accordion>)
}

export function DeleteLinkDialog({ link, profile, onClose }) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cancelling, setCancelling] = React.useState(false);
  const dispatch = useDispatch();
  const cancelLink = React.useCallback(() => {
    setCancelling(true);
    return dispatch({
      type: ACTIONS.WORKER_DELETE_LINK,
      payload: link,
      resolvers: {
        resolveOn: ACTIONS.WORKER_DELETE_LINK_SUCCESS,
        rejectOn: ACTIONS.WORKER_DELETE_LINK_ERROR
      }
    }).catch((err) => {
      enqueueSnackbar(t("dashboard.section.schedulingLinks.errors.couldNotDelete"), {
        variant: "error",
        className: "snack-error"
      });
    }).finally(() => {
      setCancelling(false);
      onClose();
    })
  }, [dispatch, link, enqueueSnackbar, t, onClose]);
  const linkUrl = `${window.location.origin}/${profile.link_name}/${link.slug}`
  return (
    <div className="cancel-dialog link-delete-dialog" data-testid="cancel-link-dialog">
      <DialogTitle id="alert-dialog-title">
        {t("dashboard.schedulingLinks.deleteDlg.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('dashboard.schedulingLinks.deleteDlg.message', { linkUrl })}
        </DialogContentText >
      </DialogContent >
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={cancelling}
          className='confirm-cancel-destructive-action'
          data-testid="cancel-event-dialog-cancel"
        >
          {t("dashboard.schedulingLinks.deleteDlg.dismiss")}
        </Button>
        <Button
          onClick={cancelLink}
          className='confirm-destructive-action'
          autoFocus
          disabled={cancelling}
          data-testid="cancel-event-dialog-ok"
        >
          {t("dashboard.schedulingLinks.deleteDlg.ok")}
        </Button>
      </DialogActions>

      {cancelling && <Loader />}
    </div >
  );
}
