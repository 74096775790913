import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import './selected.calendar.scss';
import { MAIL } from '../../../../../validators/event';
const NO_PROFIL = {}
export default function SelectedCalendar({
  account,
  calendar,
  profile = NO_PROFIL,
}) {
  const { t } = useTranslation();
  const tz = React.useMemo(() => {
    if (!account || !calendar || !profile) return null;
    let fb = account.settings ? account.settings.freebusy_calendars : account.calendars.filter((c) => c.primary).map((c) => c.id);
    if (fb.find((v) => v === calendar.id)) return profile.timezone || null;
    return calendar.timezone || null;
  }, [account, calendar, profile])
  if (!account || !account.provider
    || !calendar) return null;

  const tzComp = tz ? <span className="tz">({tz + " " + moment().tz(tz).format("[UTC]Z")})</span> : null;
  let name = <div className="title">
    <span className="calname">{calendar.name}{tzComp}</span>
    <span className="accountname">{t('createEvent.form.agenda.selectedAccount')} {account.email}</span>
  </div>;

  if (MAIL.isValidSync({ mail: calendar.name })) {
    // If calendar name is an email
    // => display calendar name (cause an email should be enough info)
    name = <div className="title"><span className="calname">{calendar.name}{tzComp}</span></div>
  } else if (account.calendars.filter((c) => !c.readonly).length < 2) {
    // if only one writable calendar in selected account
    // => display account email
    name = <div className="title"><span className="calname">{account.email}{tzComp}</span></div>
  }

  return (
    <div className="selected-calendar">
      <div className="content">
        {name}
      </div>
    </div>);

}