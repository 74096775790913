import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import DoneButton from '../../DoneButton';
import { ChevronRight, FileCopyOutlined, Launch } from '@material-ui/icons';
import { isUnknownDomain } from '../../../api/validators/proposal';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ROUTES from '../../../routes/routes.name'
import { Link } from 'react-router-dom';
import { EmailWithAvatar } from '../create-event/guests/guest.selector.v3';
import './success.202305.scss';
import { Copy } from '../../../assets/icons';
export function SuccessRenderer202305({ proposal, type, link, doGenLink }) {
  const { t } = useTranslation();

  const { invitees, lnks } = React.useMemo(() => {
    if (proposal && proposal.id && proposal.invitees) {
      let tmp = [proposal.invitees.filter(invitee => {
        return isUnknownDomain(invitee);
      }),
      proposal.invitees.filter(invitee => {
        return !isUnknownDomain(invitee);
      }),
      ];
      let hasLink = tmp[0].length > 0;
      let lnks = [
        hasLink ? link : undefined,
        hasLink ? undefined : link,
      ]
      return { invitees: tmp, lnks }
    } return { invitees: [], lnks: [] };
  }, [proposal])

  const genLink = React.useCallback(() => {
    doGenLink(false);
  }, [doGenLink]);
  const visitLink = React.useCallback((link) => {
    // open
    window.open(link, '_BLANK', 'norel noopener')
  }, []);

  return (
    <div className="success-toast-2023-05" data-testid="success-toast">
      <div className="invitees-list-groups">
        {invitees.map((inviteeList, i) => <InviteesGroup proposal={proposal} invitees={inviteeList} inviteesType={i} link={lnks[i]} doGenLink={genLink} visitLink={visitLink} />)}
      </div>
      <ProposalDetails proposal={proposal} />
      <Link className="link-to-dashboard" data-testid="gotodash" to={ROUTES.APP}>{t('createEvent.successPage.toDashboard')}<ChevronRight /></Link>
    </div>
  );
}

function InviteesGroup({
  proposal,
  invitees, // list of invitees
  inviteesType, // unknown or emails
  link,
  doGenLink, // show link action
  visitLink, // go to 
}) {

  const { t } = useTranslation();
  if (!proposal || invitees.length === 0) return null;
  // default for emails
  let title = 'createEvent.successPage.titleMail';
  let subTitle = 'createEvent.successPage.subTitleMail'
  if (inviteesType === 0) {
    title = 'createEvent.successPage.titleLink';
    subTitle = 'createEvent.successPage.subTitleLink';
  }
  return <div className="invitees-list">
    <div className="invitees-list-title">{t(title)}</div>

    {(link && doGenLink) ? <>
      <div className="invitees-list-subtitle">{t(subTitle + "BeforeLink")}</div>
      <div className='copy-link-component'>
        <span className="link"><a href={link} target='_blank' rel="noopener noreferrer">{link}</a></span>
        <DoneButton
          name="copylink"
          color="secondary"
          aria-label="copy link"
          onClick={doGenLink}
          className="grey"
          icon={<Copy />}
          label={t('proposal.details.actions.link')}>
        </DoneButton>
        <DoneButton
          name="visitlink"
          color="primary"
          aria-label="visit link"
          onClick={() => visitLink(link)}
          endIcon={<Launch />}
          label={t('proposal.details.actions.visitlink')}>

        </DoneButton>
      </div>
      <div className="invitees-list-subtitle">{t(subTitle + "AfterLink")}</div> </> : <div className="invitees-list-subtitle">{t(subTitle)}</div>}
    <div className="invitees-list-details">
      <div className="guest-list-container">
        {invitees.map((invitee) => {
          let details = {
            ...(proposal.form_invitees_details || {}),
            ...(proposal.inviteesDetails || {})
          };
          let guest = details[invitee];
          if (!guest) return null;
          const isUnknown = isUnknownDomain(guest.email);
          const isDoner = guest.registered || false; // is LM USer or Hybrid
          // const name = guest.name || guest.label || guest.email;
          const guestEmail = !isUnknown && guest.email;
          const guestName = (isDoner || isUnknown) ? (guest.name || guest.label) : undefined;
          const isOptional = guest.optional || false;// will be asked to vote?
          return <div key={guestEmail}><EmailWithAvatar guestName={guestName} guestEmail={guestEmail} avatar_url={guest.avatar_url} isDoner={isDoner} isOptional={isOptional}
            fallBackColor={"#F5F8FF"} /></div>;
        })}</div>
    </div>
  </div>;
}
function ProposalDetails({ proposal }) {
  const { t } = useTranslation();
  if (!proposal) return null;
  const where = proposal.conference ? t('createEvent.successPage.conference_' + proposal.conference) : proposal.location;
  const slots = proposal.form_slots || { total: 0, timeframe: -1 }
  const when = t('createEvent.successPage.timeframe' + slots.timeframe, { count: slots.total });
  const duration = proposal.duration === -1 ? proposal.customduration : proposal.duration
  return <div className="success-proposal-details">

    <div className="invitees-list-details-title">{t('createEvent.successPage.what')}</div>
    <div className="value">{proposal.title}</div>
    <div className="invitees-list-details-title">{t('createEvent.successPage.duration')}</div>
    <div className="value"><span>{" " + t('common.minutes', { count: duration })}</span></div>

    {where && <><div className="invitees-list-details-title">{t('createEvent.successPage.where')}</div>
      <div className="value">{where}</div></>}
    <div className="invitees-list-details-title">{t('createEvent.successPage.when')}</div>
    <div className="value">{when}</div>
    <div className="invitees-list-details-title">{t('createEvent.successPage.how')}</div>
    <div className="value">{t('createEvent.successPage.type' + proposal.type)}</div>
  </div>
}
