
export default function orderedInvitees(inviteesDetails, userEmail) {
  return Object.values(inviteesDetails)
    .sort((a, b) => (a.email || '').localeCompare(b.email || ''))
    .sort((a, b) => {
      return getAttendeeScore(b, userEmail) - getAttendeeScore(a, userEmail);
    });
}
// check if user has voted
export function attendeeHasVoted(attendee) {
  // host do not vote
  if (attendee.__isHost) return true;
  // if got a response, done
  if (attendee.response === 'yes') return true;
  // if registered or related to AND have conflicting slots
  const isRegisteredOrRelatedTo = attendee.registered || (attendee.related_to && attendee.related_to.user_id);
  if (isRegisteredOrRelatedTo) return !(attendee.conflicting_slots && attendee.conflicting_slots.length > 0) && !attendee.vote_requested;
  // if nothing, then must vote
  return false;
}
export function getAttendeeScore(attendee, userEmail) {
  let score = 0;
  const isNotAvailable = attendee.response === 'yes' && (!attendee.slots || attendee.slots.length === 0);
  const hadVoted = attendeeHasVoted(attendee);
  score += (attendee.registered && hadVoted && !isNotAvailable) ? 4 : 0;
  score += (attendee.related_to && attendee.related_to.user_id && hadVoted) ? 4 : 0; //3.5
  score += (attendee.response === 'yes' && attendee.email !== userEmail && !isNotAvailable) ? 2.7 : 0;
  score += attendee.email === userEmail ? 1 : 0;
  score += isNotAvailable ? 1.5 : 0;
  return score;
}