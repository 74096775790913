export function initProposalFrom(p) {
  if (!p) return {};
  return {
    ...p,
    id: undefined,
    icaluid: undefined,
    important: false,
    location: '',
    conference: '',
    duration: 60,
    customduration: 10,
    currenttimeframe: 0,
    reminders: ['15'],
    notes: '',
    proposals: [],
    duringWH: true,
    slots: [],
    generateLink: false,
    holdSlots: false,
    vote_required: false,
    invitees: [...(p.invitees || [])],
    inviteesDetails: reinitInviteesDetails(p.inviteesDetails)
  }
}
export function copyFromProposal(p, edit = false) {
  if (!p) return {};
  if (edit) {
    // first, deep copy
    let tmp = JSON.parse(JSON.stringify(p));
    return tmp;
  }
  return {
    ...p,
    id: undefined,
    icaluid: undefined,
    proposals: [],
    slots: [],
    vote_required: p.vote_required === true,
    invitees: [...(p.invitees || [])],
    inviteesDetails: reinitInviteesDetails(p.inviteesDetails),
    location: p.location || "",
  }
}
function reinitInviteesDetails(guests = {}) {
  let tmp = {};
  for (let k of Object.keys(guests)) {
    tmp[k] = {
      ...guests[k],
      response: 'no',
      slots: [],
    }
  }
  return tmp;
}