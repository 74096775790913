import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import DoneButton from '../../../DoneButton';
import NOOP from '../../../../utils/noop';
import CircularProgress from '@material-ui/core/CircularProgress'
import "./promo.card.scss";
import { Done, KeyboardArrowRight } from '@material-ui/icons';
export default function PromoCard({
  doSubscribe = NOOP,
  loadingCustomerSession = false
}) {
  const { t } = useTranslation();

  return <div className="billing-promo-card">
    <div className="title">{t('settings.sections.billing.free.card.title')}</div>
    <hr />
    <ul>
      {[1, 2, /*3,*/ 4, 5].map(i =>
        <li key={"offer-" + i} className="offer-item">
          <Done />
          {t('settings.sections.billing.free.card.offer' + i)}
        </li>)}
    </ul>
    <div className="title offer">
      <div>
        <Trans i18nKey="settings.sections.billing.free.card.price1">
          <span className="bold">X</span> Y - launching offer
        </Trans>
      </div>
      <div>
        <Trans i18nKey="settings.sections.billing.free.card.price2">
          <span className="bold">X</span> Y - launching offer
        </Trans>
      </div>
    </div>
    <DoneButton
      name="subscribe-to-premium"
      className="premium"
      label={t('settings.sections.billing.free.card.subscribe')}
      endIcon={loadingCustomerSession ? <CircularProgress className='no-margin' size="1.5rem" /> : <KeyboardArrowRight />}
      onClick={doSubscribe} />
    {/* <div className="more">{t('settings.sections.billing.free.card.more')}</div> */}
  </div>
}