import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { STATUS } from "../../../../../api/validators/proposal";
import "./status.scss";

export default function EventCancelledStatus({ proposal, isActive = true }) {
  const { t } = useTranslation();
  if (!proposal || proposal.status !== STATUS.EVT_CANCELLED) return null;
  const iAmHost = proposal.iAmHost;
  const status = "canceled"
  return (
    <div
      className={
        "timeline-item status " + (isActive ? "active " : "") + status
        + (proposal.iAmHost ? ' iamhost ' : '')
      }
    >
      {t(
        iAmHost
          ? "proposal.timeline.status.canceledHost"
          : "proposal.timeline.status.canceled"
      )}
    </div>
  );
}
