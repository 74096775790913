import React from "react";
import {
  MeetIcon,
  ZoomIcon,
  TeamsIcon,
  PollCardIcon,
  FunnelCardIcon
} from '../../../assets/icons';
import moment from 'moment';
import { Trans, useTranslation } from "react-i18next";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHIcon from '@material-ui/icons/MoreHorizOutlined';
import {
  Event,
  Share,
  Refresh,
  Cancel,
  LocationOn,
  CalendarToday,
  CheckCircle,
  HourglassEmpty,
  Mail,
  GroupOutlined,
  NotificationsActiveOutlined,
  EditOutlined,
  Clear,
  Link,
  Launch,
  VisibilityOffOutlined,
  VisibilityOutlined,
  DeleteOutline,
  Restore,
} from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import {
  STATUS
} from '../../../api/validators/proposal';
import {
  computeMenus, importantNotifications
} from '../../../utils/compute.menus';
import { NO_BUBBLES } from '../../../utils/noop';
import LMSwitch from '../../LMSwitch';
import "./proposal.card.scss";

export default function ProposalCard({ proposal, selected = false,
  onCreate, onEdit, onLink, onRemind,
  onCancel, onReschedule, onVote,
  onActive, onDelete, onViewPage, // links specifics
}) {
  /* istanbul ignore if no work */
  if (!proposal) return null;
  let is_host = proposal.iAmHost || proposal.is_link;
  let is_active = !proposal.is_link || proposal.is_active;
  return <div role="card-item" className="proposal-card-container" data-testid={"event" + proposal.id}>
    <div className={"proposal-card " + (is_host ? "card-host" : "card-guest") + (selected ? " selected" : "")
      + ((!is_active) ? " link-unactive" : "") + (proposal.is_link ? " link-card" : "")}>
      <ProposalGuestInfo proposal={proposal} />
      <ProposalContent proposal={proposal}
        onCreate={onCreate} onEdit={onEdit} onLink={onLink} onRemind={onRemind}
        onCancel={onCancel} onReschedule={onReschedule} onVote={onVote}
        onActive={onActive} onDelete={onDelete} onViewPage={onViewPage} />
      <ProposalFooter proposal={proposal} onActive={onActive} onViewPage={onViewPage} onLink={onLink} />
    </div>
  </div>;
}
function ProposalAction({ proposal }) {
  const { t } = useTranslation();
  /* istanbul ignore if no work */
  if (!proposal || proposal.is_link) return null;
  let actions = importantNotifications(proposal);
  if (actions) {
    if (actions.proposedNewSlots) {
      return <div className="proposal-card-alert poll-complete">
        <NotificationsActiveOutlined />
        <span className="alert-msg">{t('dashboard.cards.proposedNewSlotsMsg')}</span>
      </div>
    } else if (actions.poll_complete) {
      return <div className="proposal-card-alert poll-complete">
        <NotificationsActiveOutlined />
        <span className="alert-msg">{t('dashboard.cards.pollCompleteMsg')}</span>
      </div>
    } else if (actions.expiring) {
      return <div className="proposal-card-alert expiring">
        <NotificationsActiveOutlined />
        <span className="alert-msg">{t('dashboard.cards.expiringMsg')}</span>
      </div>
    } else if (actions.wainting_vote) {
      return <div className="proposal-card-alert must-vote-alert">
        <NotificationsActiveOutlined />
        <span className="alert-msg">{t('dashboard.cards.mustVoteMsg')}</span>
      </div>
    }
  }
  return null;

}
function ProposalContent({ proposal, onCreate, onEdit, onLink, onRemind, onCancel, onReschedule, onVote,
  onActive, onDelete, onViewPage, }) {
  return <div className="proposal-card-content">
    <ProposalLogo proposal={proposal} />
    <ProposalTitle proposal={proposal} />
    <div className="proposal-card-actions">
      <ProposalMenu proposal={proposal} onCreate={onCreate}
        onEdit={onEdit} onLink={onLink} onRemind={onRemind}
        onCancel={onCancel} onReschedule={onReschedule} onVote={onVote}
        onActive={onActive} onDelete={onDelete} onViewPage={onViewPage} />
      <ProposalAction proposal={proposal} />
    </div>
  </div>
}

function ProposalLogo({ proposal }) {
  /* istanbul ignore if no work */
  if (!proposal || proposal.is_link) return null;
  if (proposal.status === STATUS.CONFIRMED) return <ProposalCompleteLogo proposal={proposal} />;
  else return <ProposalPendingLogo proposal={proposal} />
}
function ProposalCompleteLogo({ proposal }) {
  /* istanbul ignore if no work */
  if (!proposal || !proposal.status === STATUS.CONFIRMED
    || !proposal.start) return null;
  const fdate = moment(proposal.start);
  let className = (proposal.type === 'poll') ? "poll-card" : "funnel-card";
  className += proposal.booking_link ? " link-card" : "";
  return <div className={"proposal-card-type-logo complete " + className}>
    <div className="month-of-year">{fdate.format("MMM")}</div>
    <div className="day-of-week">{fdate.format("D")}</div>
  </div>
}
function ProposalPendingLogo({ proposal }) {
  /* istanbul ignore if no work */
  if (!proposal) return null;
  let className = "funnel-card";
  let logo = <FunnelCardIcon />
  if (proposal.type === 'poll') {
    className = "poll-card";
    logo = <PollCardIcon />
  }
  return <div className={"proposal-card-type-logo " + className}>
    {logo}
    <div className="proposal-card-type-label">{proposal.type}</div>
  </div>
}
function ProposalTitle({ proposal }) {
  const { t } = useTranslation();
  /* istanbul ignore if no work */
  if (!proposal) return null;
  if (proposal.is_link) return <ProposalLinkTitle link={proposal} />;

  let isConfirmed = proposal.status === STATUS.CONFIRMED && proposal.start;
  let dt = proposal.start ? moment(proposal.start) : moment();
  let ed = proposal.end ? moment(proposal.end) : moment();
  const fromBookingLink = proposal.booking_link;
  const firstGuest = Object.values(proposal.inviteesDetails || {})[0] || {};
  return <div className="proposal-card-title">
    {isConfirmed && <div className="proposal-date">
      <div className="meeting-hour">{dt.format(t('common.hourMinutesFormat'))}-{ed.format(t('common.hourMinutesFormat'))}</div>
      <div className="meeting-tz">({dt.format('zz [GMT]Z')})</div>
    </div>}
    <div className="proposal-title">{fromBookingLink ? proposal.booking_link.title : proposal.title}</div>
    {fromBookingLink && <div className="proposal-from-link-with">{t('dashboard.cards.linkGuest')} {firstGuest.name || firstGuest.email}</div>}
  </div>
}
function ProposalLinkTitle({ link }) {
  const { t } = useTranslation();
  /* istanbul ignore if no work */
  if (!link || !link.is_link) return null;
  let duration = link.duration === -1 ? link.customduration : link.duration;
  return <div className="proposal-card-title link-card-title">
    <div className="link-duration">{t('common.minCount', { count: duration })}</div>
    <div className="proposal-title">{link.title}</div>
  </div>
}
function ProposalMenu({ proposal,
  onCreate, onEdit, onLink, onRemind, onCancel, onReschedule, onVote,
  onActive, onDelete, onViewPage }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = React.useCallback((event) => {
    NO_BUBBLES(event)
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback((cllbck) => {
    setAnchorEl(null);
    if (cllbck) cllbck(proposal).finally(() => {

    })
  }, [proposal]);

  if (!proposal) return null;

  const isPoll = proposal.type === "poll";

  let menus = computeMenus(proposal);
  if (menus) {
    return <div className="proposal-card-menu">
      <IconButton
        data-testid="menu-card"
        onClick={handleClick}
      >
        <MoreHIcon />
      </IconButton>
      <Menu
        data-testid="proposal-menu"
        className="proposal-card-menu-popper"
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => { NO_BUBBLES(e); handleClose() }}
      >
        {menus.create && <MenuItem key={"create-opt"} data-testid="create" onClick={(e) => {
          NO_BUBBLES(e)
          handleClose(onCreate);
        }}>
          <ListItemIcon>
            <Event fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t(isPoll ? 'proposal.details.actions.forcePoll' : 'proposal.details.actions.force')}
          </Typography>
        </MenuItem>}
        {menus.link && <MenuItem key={"link-opt"} data-testid="link" onClick={(e) => { NO_BUBBLES(e); handleClose(onLink) }}>
          <ListItemIcon>
            <Share fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('proposal.details.actions.link')}
          </Typography>
        </MenuItem>}
        {menus.remind && <MenuItem key={"remind-opt"} data-testid="remind" onClick={(e) => { NO_BUBBLES(e); handleClose(onRemind) }}>
          <ListItemIcon>
            <NotificationsActiveOutlined fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('proposal.details.actions.remind')}
          </Typography>
        </MenuItem>}
        {menus.edit && <MenuItem key={"edit-opt"} data-testid="edit" onClick={(e) => { NO_BUBBLES(e); handleClose(onEdit) }}>
          <ListItemIcon>
            <EditOutlined fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('proposal.details.actions.edit')}
          </Typography>
        </MenuItem>}
        {menus.cancel && <MenuItem key={"cancel-opt"} data-testid="cancel" onClick={(e) => { NO_BUBBLES(e); handleClose(onCancel) }}>
          <ListItemIcon>
            <Clear fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('proposal.details.actions.cancel')}
          </Typography>
        </MenuItem>}
        {menus.reschedule && <MenuItem key={"reschedule-opt"} data-testid="reschedule" onClick={(e) => { NO_BUBBLES(e); handleClose(onReschedule) }}>
          <ListItemIcon>
            <Refresh fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('proposal.details.actions.reschedule')}
          </Typography>
        </MenuItem>}
        {menus.vote_requested && <MenuItem key={"vote_requested-opt"} data-testid="vote_requested" onClick={(e) => { NO_BUBBLES(e); handleClose(onVote) }}>
          <ListItemIcon>
            <Event fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('proposal.details.actions.vote')}
          </Typography>
        </MenuItem>}
        {menus.view_blink && <MenuItem key={"view-page-opt"} data-testid="active_link" onClick={(e) => { NO_BUBBLES(e); handleClose(onViewPage) }}>
          <ListItemIcon>
            <Launch fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('proposal.details.actions.viewPage')}
          </Typography>
        </MenuItem>}
        {menus.active && <MenuItem key={"active-opt"} data-testid="active_link" onClick={(e) => { NO_BUBBLES(e); handleClose(onActive) }}>
          <ListItemIcon>
            {proposal.is_active ?
              <VisibilityOffOutlined fontSize="small" />
              : <VisibilityOutlined fontSize="small" />
            }
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t(proposal.is_active ? 'proposal.details.actions.unactive' : 'proposal.details.actions.active')}
          </Typography>
        </MenuItem>}
        {menus.delete && <MenuItem key={"delete-opt"} data-testid="delete_link" onClick={(e) => { NO_BUBBLES(e); handleClose(onDelete) }}>
          <ListItemIcon>
            <DeleteOutline fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('proposal.details.actions.delete')}
          </Typography>
        </MenuItem>}
      </Menu>
    </div>
  } else {
    return null;
  }

}

// footer of card
function ProposalFooter({ proposal, onViewPage, onLink, onActive }) {
  /* istanbul ignore if no work */
  if (!proposal) return null;
  if (proposal.is_link) return <ProposalLinkFooter link={proposal} onViewPage={onViewPage} onLink={onLink} onActivate={onActive} />;

  return <div className="proposal-card-footer">
    <Location proposal={proposal} />
    <Guests proposal={proposal} />
    <ProposalStatus proposal={proposal} />
  </div>
}
function ProposalLinkFooter({ link, onViewPage, onLink, onActivate }) {
  const { t } = useTranslation();
  const doLink = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onLink(link)
  }, [link, onLink])
  const doActivateLink = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onActivate(link)
      .catch((err) => {
        // display error
      })
  }, [link, onActivate])
  const doViewPage = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onViewPage(link)
  }, [link, onViewPage])
  /* istanbul ignore if no work */
  if (!link || !link.is_link) return null;
  return <div className="proposal-card-footer link-card-footer">
    <a href="#" className="link-view-page" onClick={doViewPage} >{t('dashboard.section.schedulingLinks.viewPage')}</a>
    <div className="link-actions">
      <a className="link-get-link" data-testid="get-link" href="#" onClick={doLink} >
        <Link />
        {t('dashboard.section.schedulingLinks.getLink')}
      </a>
      <LMSwitch data-testid="switch-activate" labelsVisible={false} size="small" onChange={doActivateLink} onClick={NO_BUBBLES} checked={link.is_active} />
    </div>
  </div>
}
function ProposalGuestInfo({ proposal }) {
  if (!proposal || proposal.iAmHost) return null;
  let host = proposal.organizer || {};
  let hostName = host.label || host.name || host.email;
  if (!hostName) return null;
  return <span className="proposal-card-guest-msg"><Trans i18nKey='dashboard.cards.iAmGuest' values={{ hostName }}>You are invited</Trans></span>
}
// get location icon
const CONFERENCES = {
  "gmeet": <MeetIcon />,
  "teams": <TeamsIcon />,
  "zoom": <ZoomIcon />,
  "zoom:personal": <ZoomIcon />
}
function Location({ proposal }) {
  /* istanbul ignore if no work */
  if (!proposal) return null;
  let icon = null;
  if (proposal.conference && CONFERENCES[proposal.conference]) {
    icon = CONFERENCES[proposal.conference];
  } else if (proposal.location) icon = <LocationOn />
  return <div className="card-location">{icon}</div>
}
// just number of guests in proposal
// do we need to add the host
function Guests({ proposal }) {
  const { t } = useTranslation();
  /* istanbul ignore if no work */
  if (!proposal) return null;
  return <div className="card-guests">
    <GroupOutlined />
    {t('dashboard.cards.guests', { count: (proposal.invitees || []).length })}
  </div>
}
function ProposalStatus({ proposal }) {
  /* istanbul ignore if no work */
  if (!proposal) return null;
  switch (proposal.status) {
    case STATUS.PENDING: {
      // if poll complete, special
      let actions = importantNotifications(proposal);
      console.log("ACTIONS", actions)
      if (actions && actions.poll_complete) return <StatusPollComplete proposal={proposal} />
      return <StatusSent proposal={proposal} />
    }
    case STATUS.CANCELLED:
    case STATUS.EVT_CANCELLED: {
      return <StatusCancel proposal={proposal} />
    }
    case STATUS.NO_AVIABILITY: {
      return <StatusNoAvail proposal={proposal} />
    }
    case STATUS.CONFIRMED: {
      return <StatusComplete proposal={proposal} />
    }
    default: {
      return null;
    }
  }
}
function StatusCancel({ proposal }) {
  const { t } = useTranslation();
  return <div className="card-status cancel">
    <Cancel />
    <span>{t('dashboard.cards.statusCancel')}</span>
  </div>
}
function StatusNoAvail({ proposal }) {
  const { t } = useTranslation();
  return <div className="card-status no-avail">
    <CalendarToday />
    <span>{t('dashboard.cards.statusNoAvail')}</span>
  </div>
}
function StatusComplete({ proposal }) {
  const { t } = useTranslation();

  const {
    yes,
    no,
    unknow
  } = React.useMemo(() => {
    if (!proposal
      || !proposal.iAmHost
      || !proposal.raw_invitees_details) return { yes: 0, no: 0, unknow: (proposal.invitees || []).length };
    const details = Object.values(proposal.raw_invitees_details || {});
    return {
      yes: details.filter((inv) => inv.response === 'yes' || inv.response === 'maybe').length,
      no: details.filter((inv) => inv.response === 'no').length,
      unknow: details.filter((inv) => !inv.response).length,
    }
  }, [proposal]);
  if (moment(proposal.end) < moment()) {
    return (
      <div className="card-status confirmed past">
        <Restore />
        <span>{t('dashboard.cards.statusConfirmedPast')}</span>
      </div>);
  }
  if (!proposal.iAmHost || (yes === 0 && no === 0)) {
    return (
      <div className="card-status confirmed">
        <Mail />
        <span>{t('dashboard.cards.statusConfirmed')}</span>
      </div>);
  }
  return <div className="card-replies">
    {(yes > 0) && <span className="card-vote card-vote-yes"><CheckCircle />{yes}</span>}
    {(no > 0) && <span className="card-vote card-vote-no"><Cancel />{no}</span>}
    {(unknow > 0) && <span className="card-vote card-vote-unknow"><HourglassEmpty />{unknow}</span>}
  </div>
}
function StatusPollComplete({ proposal }) {
  const { t } = useTranslation();

  return (
    <div className="card-status confirmed">
      <NotificationsActiveOutlined />
      <span>{t(proposal.areNewSlotsProposed ? 'dashboard.cards.proposedNewSlotsMsg' : 'dashboard.cards.pollCompleteMsg')}</span>
    </div>);

}
// get replies from invitees
function StatusSent({ proposal }) {
  const {
    yes,
    no,
    unknow
  } = React.useMemo(() => {
    if (!proposal || !proposal.inviteesDetails) return { yes: 0, no: 0, unknow: 0 };
    const details = proposal.inviteesDetails;
    return {
      yes: withYesAnswers(details),
      no: withNoAnswers(details),
      unknow: withUnknownAnswers(details)
    }
  }, [proposal])
  return <div className="card-replies">
    {(yes > 0) && <span className="card-vote card-vote-yes"><CheckCircle />{yes}</span>}
    {(no > 0) && <span className="card-vote card-vote-no"><Cancel />{no}</span>}
    {(unknow > 0) && <span className="card-vote card-vote-unknow"><HourglassEmpty />{unknow}</span>}
  </div>
}
const withYesAnswers = (inviteesDetails) => {
  return Object.values(inviteesDetails)
    .filter(
      invitee => {
        // first case: has answered yes and no slots
        let tmp = (invitee.slots && invitee.slots.length > 0);
        if (invitee.response === "yes") return tmp;
        // check auto-voters
        const registeredOrHybrid = invitee.registered || (invitee.related_to && invitee.related_to.provider && invitee.related_to.user_id);
        return registeredOrHybrid && !invitee.conflicting_slots && !invitee.vote_requested
      }).length;

}
const withUnknownAnswers = (inviteesDetails) => {
  return Object.values(inviteesDetails)
    .filter(
      invitee => {
        const registeredOrHybrid = invitee.registered || (invitee.related_to && invitee.related_to.provider && invitee.related_to.user_id);
        return !invitee.response // no response
          && (!registeredOrHybrid || (invitee.conflicting_slots || invitee.vote_requested)) // registered with conflict or must vote;
      }
    ).length;
}
const withNoAnswers = (inviteesDetails) => {
  return Object.values(inviteesDetails)
    .filter(
      invitee =>
        invitee.response === "yes"
        && (!invitee.slots || invitee.slots.length) === 0)
    .length;
}