import React, { useCallback } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { position } from '../../../../agenda/utils/position';
import { getEventCalendarLF } from '../../../../../reducers/accounts';
import TextBlock from '../../../../TextBlock';
import { placePoppup } from './Busy';
import { shiftLeft } from '../../../../../utils/to.offset';
import './events.scss';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
const EMPTY_ARR = [];
export function Event({
  selectedDate = moment(),
  events = EMPTY_ARR,
  interactive = true,
}) {


  const busyContainer = React.useRef(null);
  // place times on grid
  const busyCmp = React.useMemo(() => {
    // if (!busyContainer || !busyContainer.current) return [];
    const { width } = busyContainer.current ? busyContainer.current.getBoundingClientRect() : { width: 100, height: 100 };
    let st = moment(selectedDate.format()).startOf('week');
    let ed = moment(selectedDate.format()).endOf('week');
    const period = {
      start: st,
      end: ed,
      numOfDays: 7, // for now, only weeks
    }
    let noAllday = (events || []).filter((evt) => !evt.allday)
    let p = position(period, noAllday || [], true, true);
    if (!p || !p.events) return [];
    return p.events
      .map((e) => {
        let shift = shiftLeft(e.position.x);
        return {
          ...e,
          position: {
            ...e.position,
            left: `calc(${e.position.x}% + ${shift}px)`,
            top: e.position.y,
            width: `calc(${e.position.width}% - ${shift}px)`,
            // top: e.position.y,
            // height: e.position.height - 2,

          },
          background: {
            backgroundPositionX: `-${width * e.position.x / 100}px`,
            backgroundPositionY: `-${e.position.y}px`
          },
        }
      });
  }, [events, selectedDate, busyContainer.current]);
  // },[times, selectedDate, busyContainer.current]);



  return (
    <div data-testid="user-events" className="event-times" ref={busyContainer}>
      {
        busyCmp.map((b, i) => {
          // tooltip position -----------------------------
          let position = placePoppup(b);
          let id = undefined;
          return (
            <div id={id} data-testid={'evt-' + i} key={b.id + '-' + i} className={"event-slots evttooltip " + (interactive ? '' : 'non-interactive')} style={b.position}>
              <div className="dot" style={b.background}>
                <EventItem event={b} />
              </div>
              {(b.isPast !== true) && <EventPopup event={b} position={position} container={busyContainer} />}
            </div>
          )
        })
      }
    </div>
  );
}

export function EventPopup({ event, position, container }) {
  const { t } = useTranslation();
  const tooltip = React.useRef(null);
  const [calculated_position, setPosition] = React.useState(undefined);
  React.useLayoutEffect(() => {
    if (container && tooltip) {
      let c = container.current;
      let t = tooltip.current;
      if (!c || !t) return;
      let cbox = c.getBoundingClientRect();
      let tbox = t.getBoundingClientRect();
      // check if left/right OK
      if (tbox.left <= cbox.left) {
        setPosition({
          left: 55
        })
      }
      if (tbox.right >= cbox.right) {
        setPosition({
          left: -10
        })
      }
    }
  }, [container, tooltip])
  /* istanbul ignore if no work */
  if (!event) return null;
  return <div className={"eventTooltippopup " + position} style={calculated_position} ref={tooltip}>
    <i></i>
    <div className="tooltip-title" data-hj-suppress>
      {event.title}
    </div>
    {event.location && <div data-hj-suppress className="tooltip-location">
      <TextBlock txt={event.location} />
    </div>}
    <div className="tooltip-view fancy-scroll">
      {event.organizer && <div key={event.organizer.email}>{event.organizer.email} ({t('createEvent.form.organizer').toLowerCase()})</div>}
      {
        (event.attendees || []).map((invitee, i) => {
          //return <div key={invitee}><DoneChip email={invitee} size={CONSTS.AVATAR_SMALL} /*mark={isBusy}*/ /*noMark={true}*/ /></div>
          return <div data-hj-suppress key={invitee}>{invitee}</div>
        })
      }
    </div>
  </div>
}
export function EventItem({ event }) {
  const { t } = useTranslation();
  const eventCalendar = useSelector(getEventCalendarLF(event));
  const [isHovered, setIsHovered] = React.useState(false);
  const hoveredCb = React.useCallback(() => {
    setIsHovered(true);
  }, []);
  const noHoveredCb = React.useCallback(() => {
    setIsHovered(false);
  }, []);
  const style = React.useMemo(() => {
    let c = chroma(eventCalendar.bgcolor).darken();
    let tc = c.alpha(0.64).css();
    let bc = c.alpha(0.01).css();
    let color = isHovered ? c.css() : tc;
    return {
      border: "1px solid " + color,
      borderLeft: "4px solid " + color,
      backgroundColor: bc,
      color: c.darken().css(),
    }
  }, [eventCalendar, isHovered])
  // special CSS class based on event duration
  const durationClass = React.useMemo(() => {
    let duration = event && event.end && event.start && event.end.diff(event.start, 'minutes');
    return duration > 15 ? (duration <= 30 ? "duration-s" : (duration <= 60 ? "duration-m" : "")) : "duration-xs";
  }, [event]);
  /* istanbul ignore if no work */
  if (!event) return null;
  let refused = event.status === 'refused';
  return (
    <div className={
      "event-heatmap-item "
      + (event.status === 'free' ? ' free ' : '')
      + (event.end <= moment() ? ' past ' : '')
      + durationClass
      + (refused ? " refused" : "")
    }
      style={style}
      onMouseEnter={hoveredCb}
      onMouseLeave={noHoveredCb}>
      <div className="event-title" data-hj-suppress>{event.title + (event.status === 'free' ? t('createEvent.heatmap.eventFree') : '')}</div>
      {event.location && <div data-hj-suppress className="event-location">{event.location}</div>}
    </div>
  )
}
export default React.memo(Event)