/**
 * re-done of promise-retry package with some modifications
 * - no catch chain, pass resolve and reject from main Promise
 * - add special methods (reset and restart) to add some actions after
 * replay start
 */
export const ERR_REPLAY='ERR_REPLAY'; // error code for replaying promise
export const FOREVER = -1; // special: will replay promise endlessly
const defaultOpts = {
    count: 10, // number of time to replay
    minTimeout: 1000, // delay between 2 replays
    __id: 0, // internal: timeout id
    __retries: 0, // number if retries
};
const Operations = {
    reset: function(){
        // reset retries count and timers
        this.__retries = 0;
    },
    restart: function(){
        this.reset();
        // restart timer immediatly
        /* istanbul ignore else*/
        if(this._id) {
            clearTimeout(this._id);
        }
        resolver(this);
    }
}
export default function replay(promise, opts){
    // main promise for replay
    if(!opts) opts = defaultOpts;
    opts = {
        ...defaultOpts,
        ...opts,
        __promise: promise
    };
    Object.setPrototypeOf(opts, Operations)
    // create some operations on opts
    // ie: reset or restart
    return new Promise((resolve, reject) => {
        opts.__resolver = {resolve, reject};
        resolver(opts);
    });
}
function retryError(msg = ''){
    return {
        code: ERR_REPLAY,
        message: msg
    }
}
function resolver( opts ){
    let { __promise, __resolver } = opts;
    let {resolve, reject} = __resolver;

    __promise(retryError, opts.__retries, opts)
    .then((res)=> resolve(res))
    .catch((err)=>{
        if(opts._id){
            clearTimeout(opts._id);
            opts._id = 0;
        }
        let count = opts.count;
        if(count === FOREVER || (count - opts.__retries) > 0){
            // replay after delay
            let error = err || {code: ERR_REPLAY}
            if(error.code === ERR_REPLAY){
                opts.__retries= opts.__retries + 1
                opts._id = setTimeout(()=>{
                    resolver(opts)
                }, opts.minTimeout);
            } else {
                reject(err);
            }
        } else {
            reject(err);
        }
    })
}