import React, { useMemo } from "react";
import moment from "moment";

import "./month.scss";
// get month names from locale
export default ({
  date = moment(), // seelcted date
  onMonthChange, // change cllbck
  disablePast = true, // allow selection in past
  mini = false // display small
}) => {
  const cmonth = date.month();
  const now = moment().startOf("day");
  const cp = date.clone();
  const monthNames = useMemo(() => {
    const MONTHS = moment.monthsShort();
    if (mini) return MONTHS.map(m => m[0]);
    return MONTHS;
  }, [mini]);
  
  return (
    <div className={"months " + (mini ? 'mini' : '')}>
      {monthNames.map((month, i) => {
        const disabled = disablePast && cp.month(i).unix() < now.unix();
        return (
          <span
            data-testid={"month-" + i}
            key={"month-" + i}
            className={
              "month" +
              (disabled ? " disabled" : "") +
              (cmonth === i ? " selected" : "")
            }
            onClick={() => onMonthChange(i)}
          >
            {month}
          </span>
        );
      })}
    </div>
  );
};
