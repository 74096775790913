import React from 'react';
// import * as google /*{ useGoogleLogin, useGoogleLogout }*/ from './google/use.google.login';
import { useDispatch } from 'react-redux';
import * as ACTIONS from '../actions';
import { signInAction } from '../reducers/user';
import { DOMAIN } from '../api/config';
import { signup } from '../api/signup';
import { useLocation } from 'react-router-dom';
import { parseQuery } from '../utils/parse.query';
import NOOP from '../utils/noop';
import * as uuid from 'uuid'

// const clientId ="604214382067-3pno1r9j3f92hrbij47kekfdn3fdl86b.apps.googleusercontent.com";
const clientId = process.env.REACT_APP_GOOGLE_ID || "604214382067-mv4bfl3j4i0397ev8smfclgjno2lf8sb.apps.googleusercontent.com";
let refreshID = 0;
/* istanbul ignore next dev only */
export function __test_stop() {
  if (refreshID) clearTimeout(refreshID);
  refreshID = 0;
}


// Open-id want an api description
const doc_url = 'https://accounts.google.com/.well-known/openid-configuration';
let google_api;
function get_doc() {
  if (google_api) return Promise.resolve(google_api);
  return fetch(doc_url)
    .then((rep) => rep.json())
    .then((doc) => {
      google_api = doc;
      return doc;
    })
}
function _loadUserProfileRest(dt) {
  return get_doc()
    .then((doc) => {
      return fetch(doc.userinfo_endpoint, {
        headers: {
          Authorization: 'Bearer ' + dt.access_token
        }
      })
    }).then((rep) => rep.json())

}
function useGoogle(success, error, fromEventPage = false) {

  const location = useLocation();
  const _onFailure = (res) => {
    /* istanbul ignore else */
    if (error) error(1)
  };
  const _onSuccess = (res) => {
    /* istanbul ignore if */
    if (!res) return _onFailure();

    dispatch(signInAction('google', res))
      .then(() => {
        /* istanbul ignore else */
        if (success) success();
        // no more refresh token!
      })
      .catch((err) => {
        let tmp;
        console.error('Login Error, check if acount exists', err)
        if (!tmp && err && err.payload && err.payload.code === 'UNREGISTERED_ACCOUNT') {

          console.log('ACCOUNT NOT REGISTERED, sign up');
          // save idToken for next reload


          let email;
          if (res && res.profileObj) email = res.profileObj.email;

          // save current connection datas for auto-login
          dispatch({
            type: ACTIONS.PRE_CONNECTION_TOKEN,
            payload: res,
            name: 'google'
          });

          // save current form and add redirect infos for next loading....
          // update: try to use the post api to register instead
          signup('google', null, email)
            .then((link) => {
              // dispatch({
              //   type: ACTIONS.ANALYTICS_SIGNED_UP,
              //   payload: {
              //     provider: 'google',
              //     fromEventPage: fromEventPage,
              //   }
              // });
              window.location.assign(link.link)

            }).catch((err) => {
              // @TODO manage error when signing up
              console.error('sign up error', err);
              _onFailure();
            })
        } else {
          // log out because.
          console.error('Login out', err)
          /* istanbul ignore else */

          dispatch({
            type: ACTIONS.WORKER_PROVIDER_TOKEN_REFRESH_ERROR,
            payload: res
          });
          /* istanbul ignore else */
          if (error) error(1, '', err)
        }

      })

  };
  React.useEffect(() => {
    // check url
    let hash = location.hash;
    if (hash) {
      // get datas
      let dt = parseQuery(hash);
      /* istanbul ignore else */
      if (dt.id_token && !(dt.state && dt.state.startsWith('microsoft'))) {
        // go!
        _loadUserProfileRest(dt)
          .then((profile) => {
            // save and try signin?
            if (!profile || !profile.email) {

              return _onFailure();
            }
            // create a dummy-like google auth object
            _onSuccess({
              tokenId: dt.id_token,
              profileObj: {
                ...profile,
                imageUrl: profile.picture,
              }
            })
          }).catch((err) => {
            if (error) error(1)
          })
      } else if (error) error(1, 'silent')
    }
    // not a connection or not google concern, return
    /* istanbul ignore else */
    else if (error) error(1, 'silent')

  }, []);



  const dispatch = useDispatch();
  const signIn = React.useCallback((login_hint) => {
    get_doc()
      .then((doc) => {
        const scopes = "openid" +
          "+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile" +
          "+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email" +
          "+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar";
        // "+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcontacts.readonly";
        const registerURL = DOMAIN
        // https://accounts.google.com/o/oauth2/auth permission%20id_token%20token
        // https://accounts.google.com/o/oauth2/v2/auth  => cannot ask for response_type ?
        let url = `${doc.authorization_endpoint}?redirect_uri=${registerURL}&response_type=permission%20id_token%20token&scope=${scopes}&openid.realm=&prompt=select_account&client_id=${clientId}&ss_domain=${encodeURIComponent(DOMAIN)}&fetch_basic_profile=true&gsiwebsdk=2&nonce=${uuid.v4()}`;
        if (login_hint) url += "&login_hint=" + encodeURIComponent(login_hint);
        window.location.assign(url)
      })
      .catch((err) => {
        console.error(err);
        _onFailure();
      })
  })
  return signIn;
}

export default useGoogle;