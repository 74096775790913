import { substractBusyFromProposals } from '../worker/handlers/generate.slots';
// search if period overlap one of busy
export function isFreeSlotPeriod(period, busys, duration = 60){
  // perpare period, ie: cut by days
  let dp =substractBusyFromProposals([{
    start: period.start.clone(),
    end: period.end.clone()}], busys);
  // pass each slots and check if got time for it
  const durationInMS = duration * 60 * 1000;
  return !!dp.find((e)=>{
    return (e.end.valueOf() - e.start.valueOf()) >= durationInMS;
  })
}