// work in progress
import React, { useCallback } from 'react';
import { DialogProvider } from '../../../dialogs/dialog.provider';
import { STATUS, groupSlots } from "../../../../api/validators/proposal";
import { isMobilePlateform } from "../../../../utils/browser";
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next';
import { linearize } from '../../../../worker/handlers/generate.slots'
import { isUnknownDomain } from '../../../../api/validators/proposal';
import { useSnackbar } from 'notistack';
import { Loader } from "../../../Loader";
import * as ACTIONS from '../../../../actions';
import { useDispatch, useSelector } from "react-redux";
import { attendeeHasVoted, getAttendeeScore } from '../../../../utils/get.attendee.score'
import { isSameSlot, isOneOfSlots } from '../../../../utils/same.slot';
import { LMTooltip } from '../../../tooltip/Tooltip';
import { useDialog } from '../../../dialogs/dialog.provider';
import { useHistory } from 'react-router-dom';
import { Button, ClickAwayListener } from '@material-ui/core';
import DoneButton from '../../../DoneButton';
import { FlipConfirm } from '../FlipConfirm';
import NOOP, { NO_BUBBLES, NOOP_RESOLVE } from '../../../../utils/noop';
import { getAccountMails, getAccountsIds } from "../../../../reducers/accounts";
import { getUser } from "../../../../reducers/user";
import { DialogTitle } from './slots';
import copyLinkToClipboard from 'copy-to-clipboard';
import DialogContent from '@material-ui/core/DialogContent';
import "./funnel.scss";
import { DeleteAttendeesDialog } from './delete.attendees.dialog';
import { OptionalizeAttendeeDialog } from './optionalize.attendee.dialog';
import { makeProposalLink } from '../../event/gen.link';
import getAttendeeName from '../../../../utils/get.attendee.name';
import { Switch } from '../../../LMSwitch';
import AvatarWithStates from '../../../avatar.with.states';
import { notYetAnswered } from '../../../../utils/all.mandatory.answered'
import {
  EnlargeIcon
} from '../../../../assets/icons'
const EMPTY_PROPOSAL = {};
const DLG_STYLE = { maxWidth: "lg" };

function orderedInvitees(inviteesDetails, userEmail) {
  return Object.values(inviteesDetails)
    // .sort((a, b) => {
    //   console.log(a, b);
    //   let a_computed_slots_count = 0, b_computed_slots_count = 0;
    //   if (a.registered || a.related_to.user_id) a_computed_slots_count = (a.computed_slots || []).length;
    //   else a_computed_slots_count = (a.slots || []).length;

    //   if (b.registered || b.related_to.user_id) b_computed_slots_count = (b.computed_slots || []).length;
    //   else b_computed_slots_count = (b.slots || []).length;


    //   return b_computed_slots_count - a_computed_slots_count;
    // })
    .sort((a, b) => (a.email || '').localeCompare(b.email || ''))
    .sort((a, b) => {
      let score = getAttendeeScore(b, userEmail) - getAttendeeScore(a, userEmail);
      if (score !== 0) return score;
      if (!a.updated_at || !b.updated_at) return score;
      return a.updated_at.localeCompare(b.updated_at);
    });
}

export default function FunnelContainer({ proposal = EMPTY_PROPOSAL, reloadProposal, isInitialFilterON = false, doSelectNextProposal }) {

  const { t } = useTranslation();
  const { enqueueDialog, closeDialog } = useDialog(DLG_STYLE);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const accountsEmails = useSelector(getAccountMails);
  const accountsIds = useSelector(getAccountsIds);
  const user = useSelector(getUser);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const isMobile = isMobilePlateform()

  const doForceMeeting = React.useCallback((event, slot) => {
    /* istanbul ignore if */
    if (loading) return Promise.resolve();
    setLoading(true);
    return dispatch({
      type: ACTIONS.WORKER_CREATE_PROPOSAL,
      payload: {
        proposal: event,
        opts: { slot: slot }
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_CREATE_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_CREATE_PROPOSAL_ERROR
      }
    }).then(() => {
      // reload datas?
      return dispatch({
        type: ACTIONS.WORKER_GET_PROPOSAL,
        payload: {
          id: event.id,
          checkBusyTime: user.email,
          userEmail: accountsEmails,
          userAccounts: accountsIds,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
        }
      })
    }).then((prop) => {
      setLoading(false);
      enqueueSnackbar(<span><Trans i18nKey="proposal.details.actions.proposalScheduleSuccess"
        components={{ bold: <strong /> }}
        values={{ title: event.title }}>
        The status of <bold>{event.title}</bold> has been updated. You can view it in <bold>PLANNED</bold> tab
      </Trans></span>, {
        variant: "info",
        preventDuplicate: true,
        className: "snack-success",
      });
      if (doSelectNextProposal) doSelectNextProposal()
      return prop;
    }).catch((err) => {
      console.log(err)
      setLoading(false);
      // error message
      /* istanbul ignore if storybook */
      enqueueSnackbar(t('event.errors.couldNotVote'), {
        variant: "error"
      })
    })
  }, [loading, accountsEmails, accountsIds, user, dispatch, t, enqueueSnackbar, doSelectNextProposal]);

  // View the matrix in a popup
  const doDisplayFullScreen = React.useCallback(() => {
    // enqueue dialog in it
    // ask for confirmation first
    if (loading) return Promise.resolve();
    const unblock = history.block();
    const doClose = () => {
      unblock();
      closeDialog();
    };
    enqueueDialog({
      content: <FunnelAsDialog proposal={proposal} doClose={doClose} doForceMeeting={doForceMeeting} />,
      doClose: doClose,
      className: "no-overflow theme--light ",
      overrideDefaultProps: {
        maxWidth: "lg",
        className: "create-event-heatmap portrait-orientation",
        fullWidth: true
      }
    });
  }, [loading, closeDialog, enqueueDialog, history, proposal, doForceMeeting]);

  const isDirectMeetingOrInPast = React.useMemo(() => {
    return isInPastOrDirect(proposal);
  }, [proposal]);
  React.useLayoutEffect(() => {
    if (!proposal) return;
    const isComplete = proposal.status === STATUS.CONFIRMED;
    if (isComplete) {
      // scroll to date
      let elem = document.getElementById("final-slot");
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
      }
    }
  }, [proposal]);
  if (!proposal.iAmHost || isDirectMeetingOrInPast) return null;

  return (
    <div className={"funnel-cmp " + (isFunnelInteractive(proposal) ? '' : 'non-interactive')} data-testid="funnel-cmp">
      {
        isMobile ? <DoneButton onClick={doDisplayFullScreen} label={t("funnel.viewFullScreenMobile")} name="open-fullscreen"></DoneButton> :
          <Funnel proposal={proposal} spaceAvailable='small' doForceMeeting={proposal.status === STATUS.PENDING ? doForceMeeting : NOOP_RESOLVE}
            reloadProposal={reloadProposal} isInitialFilterON={isInitialFilterON}
            doDisplayFullScreen={doDisplayFullScreen} />
      }
    </div>
  );

}

function isFunnelInteractive(proposal) {
  if (!proposal) return false;
  if (proposal.status === STATUS.CONFIRMED) return false;
  if (proposal.status === STATUS.CANCELLED) return false;
  return true;
}
export const isInPastOrDirect = (proposal) => {
  if (!proposal) return true;
  let slts = linearize(proposal.slots)
  // if on slot, return true IF no invitee is an unknown domain
  if (!slts || slts.length === 0) return true;
  let last = moment(slts[slts.length - 1].start);
  const isInPast = last < moment();
  if (isInPast) return true;
  if (slts.length < 2) return !someInviteesAreUnknown(proposal);
  return false;
}
const someInviteesAreUnknown = (proposal) => {
  return !!(proposal.invitees || []).find((att) => isUnknownDomain(att));
}
export function Funnel({ proposal = EMPTY_PROPOSAL, spaceAvailable = 'large', doForceMeeting,
  target = "date-header", reloadProposal = NOOP, isInitialFilterON = false,
  doDisplayFullScreen }) {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [isFilterON, _setIsFilterON] = React.useState(isInitialFilterON);
  const [sortedSlots, setSortedSlots] = React.useState(proposal.slots || []); // what to display
  React.useEffect(() => {
    if (proposal) setSortedSlots(proposal.slots)
  }, [proposal])
  const setIsFilterON = React.useCallback((e) => {
    // filter all slots depending on proposal info
    _setIsFilterON(e);
    if (e) {
      // must sort
      setSortedSlots(doFilter(proposal));
    } else {
      // take back all slots
      setSortedSlots(proposal.slots);
    }
  }, [proposal])
  const isDirectMeetingOrInPast = React.useMemo(() => {
    return isInPastOrDirect(proposal);
  }, [proposal])

  const orderedAttendees = React.useMemo(() => {
    if (!proposal.iAmHost) return null;
    if (isDirectMeetingOrInPast) return null;
    const organizer = (proposal.organizer || { email: '' }).email
    if (!organizer) return [];
    // list attendees by score
    // need to order via update first?
    return orderedInvitees(proposal.inviteesDetails || {}, organizer);
  }, [proposal, isDirectMeetingOrInPast]);
  const doForce = React.useCallback((event, slot) => {
    if (loading) return Promise.resolve();
    setLoading(true);
    return doForceMeeting(event, slot)
      .then(() => setLoading(false))
  }, [loading, doForceMeeting])

  const display = (() => {
    if (!proposal.iAmHost) return null;
    if (!orderedAttendees) return null;
    if (isDirectMeetingOrInPast) return null;
    const organizer = proposal.organizer
    if (!organizer) return null;
    let confirmedSlot = undefined;
    if (proposal.status === STATUS.CONFIRMED) {
      confirmedSlot = {
        start: moment(proposal.start).valueOf(),
        end: moment(proposal.end).valueOf()
      }
    }
    const slots = sortedSlots;
    const half = linearize(slots).length / 2;
    let count = 0;
    const someUnknownHaveNotVotedYet = Object.values(proposal.inviteesDetails || {})
      .filter((inv) => {
        return isUnknownDomain(inv.email) && inv.response != "yes"
      })
    return slots.map((day) => {
      let slots = day.slots || [];
      return slots.map((slot, index) =>
        <FunnelGroup key={index} slot={slot} index={index} orderedAttendees={orderedAttendees} proposal={proposal} confirmedSlot={confirmedSlot}
          ltr={count++ > half} doForceMeeting={doForce} someUnknownHaveNotVotedYet={someUnknownHaveNotVotedYet} />);
    });
  })();

  // Don't show the funnel
  // - to guests
  // - for past meetings
  // - direct meetings
  if (!proposal.iAmHost || !orderedAttendees || isDirectMeetingOrInPast) return null;

  return <div data-testid="funnel" className="funnel-container">

    <div className={"funnel fancy-scroll " + spaceAvailable + " "}> {/*onScroll={doScroll}*/}
      <FunnelAttendees attendees={orderedAttendees} proposal={proposal} reloadProposal={reloadProposal} />
      <div id="funnel-slots-container-with-dates" className="funnel-votes-with-dates"
        onScroll={NO_BUBBLES}>
        <FunnelHeader proposal={proposal} sortedSlots={sortedSlots} target={target} isFilterON={isFilterON} />
        <div id="funnel-slots-container" className="funnel-votes" >{display}</div>
      </div>

      {loading && <Loader className="centered-loader"></Loader>}
    </div>
    <FunnelLegend isFilterON={isFilterON} setIsFilterON={setIsFilterON} doDisplayFullScreen={doDisplayFullScreen}
      proposal={proposal} />
  </div>
}

const FunnelHeader = function ({ proposal, sortedSlots, target }) {

  const isDirectMeetingOrInPast = React.useMemo(() => {
    return isInPastOrDirect(proposal);
  }, [proposal])

  const display = React.useMemo(() => {
    if (!proposal.iAmHost) return null;
    if (isDirectMeetingOrInPast) return null;
    const now = moment();
    const isPoll = proposal.type === 'poll';
    let slots = sortedSlots;
    return slots.map((day, index) => {
      let slots = day.slots || [];
      const numberOfSlotInDay = slots.length;
      const isEven = index % 2 === 0;

      let firstSlotInRow = -1;
      return slots.map((slot, i) => {
        let value = moment(slot.start).valueOf()
        if (value < now) return null; // do not display past slot
        firstSlotInRow++;
        return <FunnelDates key={index + "-" + value} slot={slot} isPoll={isPoll} index={firstSlotInRow} large={numberOfSlotInDay > 1} isEven={isEven} />;
      })
    });
  }, [proposal, sortedSlots, isDirectMeetingOrInPast]);

  return <div className={"funnel-date-container "} id={target}>
    {display}
  </div>;
}

const FunnelDates = ({ slot, isPoll, someOneSaysNo = false, index, isEven }) => {
  const { t } = useTranslation();
  return (
    <>
      {
        (isPoll || !someOneSaysNo) &&
        <span className={"funnel-date " + (isEven ? 'even' : '')}>
          {index === 0 &&
            <span className={"funnel-day large "}><span> {moment(slot.start).format(t('common.dateFormatFunnel'))}</span></span>}
          <span className="the-date">
            &nbsp;
          </span>
        </span>
      }
    </>
  );
}

const FUNNEL_VALUES = [
  'none', // empty
  "open", // open to vote
  'cross', // not selected
  'vote', // present
  'set', // set
]

const FunnelSlot = ({ value, optional = false, slotIsSet = false }) => {
  return <div className={"funnel-slot " + FUNNEL_VALUES[value] + (optional ? " optional" : "") + (slotIsSet ? " set " : "")}></div>
}
export function FunnelLegend({ isFilterON, setIsFilterON, doDisplayFullScreen, proposal }) {
  const { t } = useTranslation();
  return (
    <div className='FunnelLegend'>
      <div className="funnel-icons">
        <div className='legend-item'>
          <div className='legend-shape vote'></div>
          <div className='legend-label'>{t('funnel.legend.available')}</div>
        </div>
        <div className='legend-item'>
          <div className='legend-shape cross'></div>
          <div className='legend-label'>{t('funnel.legend.notAvailable')}</div>
        </div>
        <div className='legend-item'>
          <div className='legend-shape open'></div>
          <div className='legend-label'>{t('funnel.legend.waiting')}</div>
        </div>
        <div className='legend-item'>
          <div className='legend-shape none'></div>
          <div className='legend-label'>{t('funnel.legend.closed')}</div>
        </div>
      </div>
      <div className="funnel-cmds">
        <div className='marge'></div>
        <div className="cmds">
          <Filter proposal={proposal} isFilterON={isFilterON} setIsFilterON={setIsFilterON} />
          {doDisplayFullScreen && (<div className='title'>
            <Button onClick={doDisplayFullScreen} data-testid="open-fullscreen">
              <EnlargeIcon />
              {t('funnel.viewFullScreen')}</Button>
          </div>)}
        </div>
      </div>
    </div>
  );
}

const FunnelGroup = ({ slot, index, proposal, orderedAttendees,
  confirmedSlot, ltr = false,
  doForceMeeting,
  someUnknownHaveNotVotedYet }) => {
  const [isHover, setIsHover] = React.useState(false);
  const { t } = useTranslation();
  const hoveringStart = React.useCallback((e) => {
    setIsHover(true)
  }, [slot])
  const hoveringEnd = React.useCallback((e) => {
    setIsHover(false)
  }, [slot])
  const doForce = React.useCallback(() => {
    return doForceMeeting(proposal, slot)
  }, [proposal, slot, doForceMeeting]);
  let i = 0;
  const isPoll = proposal.type === 'poll';
  const now = moment().valueOf();
  const organizer = proposal.organizer
  const organizer_conflicting_slots = organizer.conflicting_slots || [];
  if (moment(slot.start).valueOf() < now) return null; // do not display past slot
  const slotIsSet = confirmedSlot && isSameSlot(confirmedSlot, slot);
  if (slotIsSet) {
    return (
      <LMTooltip placement="top-center" content={
        <p>
          <Trans i18nKey='funnel.tooltip.confirmed' values={{ when: moment(proposal.start).format(t('common.fullDateFormat')) }}>
            This time slot has been chosen, invitations were sent.
          </Trans>
        </p>
      }>
        <div className={"funnel-group meeting-set"} id="final-slot" data-testid="final-slot">
          <span className="the-date meeting-set">
            {moment(slot.start).format(t('common.hourMinutesFormatFunnelSpace'))}
          </span>
          <FunnelSlot key={"meeting-host"} value={4} />
          {
            orderedAttendees.map((att, i) => {
              return <FunnelSlot key={"meeting-" + att.email} value={4} />
            })}
        </div>
      </LMTooltip>);
  } else {
    let isHostFree = slot.IAmFree || isOneOfSlots(slot, organizer_conflicting_slots)
    let someOneSaysNo = !isHostFree;
    // get value for each attendee?
    let ok = slot.selectedBy || []
    let proposed_by = slot.proposed_by; // can be undefined
    let next = isHostFree ? 1 : 0; // if not free, do not display next votes
    let tmp = orderedAttendees.map((att) => {
      let isUserOrHybrid = (att.registered || (att.related_to && att.related_to.user_id));
      let mustVote = ((att.conflicting_slots || []).length > 0 || att.vote_requested)
      // funnel mode, host not avail anymore on this slot => slot excluded from funnel
      if (!isPoll && !isHostFree)
        return <FunnelSlot key={slot.start + '-' + i++} value={0} slotIsSet={slotIsSet} />
      // the guest replied and selected this slot 
      // OR does not have to reply and is avail => guest available on slot 
      if ((att.response === 'yes' && ok.find((inv) => inv === att.email))
        || (att.response !== 'yes' && isUserOrHybrid && !mustVote && ok.find((inv) => inv === att.email)))
        return <FunnelSlot key={slot.start + '-' + i++} value={3} optional={att.optional} slotIsSet={slotIsSet} />;
      // if add voted or do not need
      if ((att.response === 'yes' || (isUserOrHybrid && !mustVote)) && (isPoll || next === 1)) {
        // guest not available on slot
        let t = <FunnelSlot key={slot.start + '-' + i++} value={2} optional={att.optional} slotIsSet={slotIsSet} />;
        if (!isPoll && !att.optional) {
          next = 0;
          someOneSaysNo = true;
        }
        return t;
      } else {
        let t = <FunnelSlot key={slot.start + '-' + i++} value={!isPoll ? next : 1} optional={att.optional} slotIsSet={slotIsSet} />;
        return t;
      }
    });
    // is organizer free?
    tmp.unshift(<FunnelSlot key={i++} value={isHostFree ? 3 : 2} slotIsSet={slotIsSet} />)
    const title = moment(slot.start).format(t('common.fullDateFormat'));
    const alert = (someUnknownHaveNotVotedYet && someUnknownHaveNotVotedYet.length > 0) ?
      <p><span className="flip-warning">{t('proposal.slots.warningUnknwon')}</span></p> : null;
    return <div className={"hoverable-group " + (proposed_by ? "proposed-by" : '')} title={title}>
      {proposed_by && <span className="proposed-by-att">{t('common.new')}</span>}
      <FlipConfirm name={"funnel-slots-container"} confirm={doForce} start={slot.start} dataTestid={moment(slot.start).format("X")} header={alert}>
        <div className={"funnel-group " + ((!isPoll && someOneSaysNo && !confirmedSlot) ? "alpha" : "")
          + (isHover ? " hovering" : "") + (slotIsSet ? " set " : "") + (confirmedSlot ? " confirmed " : "")}
          onMouseEnter={hoveringStart} onMouseLeave={hoveringEnd}
        >
          <span className="the-date">
            {moment(slot.start).format(t('common.hourMinutesFormatFunnelSpace'))}
          </span>
          {tmp}
        </div></FlipConfirm>
    </div>;
  }
}

// check if proposal will complete, if yes, send back the 
// complete slot, else return falsy
export function willComplete(proposal, change = {}) {
  if (!proposal
    || proposal.type !== "funnel") return false;
  let type = change.type;
  switch (type) {
    case 'delete_att':
    case 'opt_att': {
      // get reminding voters
      let notAnswer = notYetAnswered(proposal);

      if (notAnswer && notAnswer.length === 1
        && notAnswer[0].email === change.attendee.email) {
        // will resolve
        // find first slot
        let days = proposal.remaining_slots || [];
        let sl = undefined;
        for (let day of days) {
          if (day && day.slots && day.slots.length > 0
            && day.slots[0]) return day.slots[0]
        }
        return proposal.remaining_slots[0];
      }
    }
    default: {
      return false;
    }
  }
}
const FunnelAttendees = ({ attendees = [], proposal, reloadProposal }) => {

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { enqueueDialog, closeDialog } = useDialog();
  const user = useSelector((state) => state.user)
  // Remind guests
  const doRemind = useCallback((email) => {
    dispatch({
      type: ACTIONS.WORKER_REMIND_ATTENDEES,
      payload: {
        emails: [email],
        proposalId: proposal.id
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_REMIND_ATTENDEES_SUCCESS,
        rejectOn: ACTIONS.WORKER_REMIND_ATTENDEES_ERROR,
      }
    }).then(() => {
      // attendee reminded
      enqueueSnackbar(t('proposal.remindAttendees.success'), {
        variant: 'success', className: "snack-success",
        action: (key) => <Button onClick={() => { closeSnackbar(key) }}>{t('common.ok')}</Button>
      })
    }).catch((error) => {
      // failed to remind attendee
      console.error("Error while reminding user: ", error);
      enqueueSnackbar(t('proposal.remindAttendees.failure'), {
        variant: 'error', className: "snack-error",
        action: (key) => <Button onClick={() => { closeSnackbar(key) }}>{t('common.ok')}</Button>
      })
    })
  }, [proposal.id, closeSnackbar, enqueueSnackbar, t, dispatch]);

  // On click to delete attendee
  const doDelete = useCallback((attendee) => {
    /* istanbul ignore if */
    if (!attendee || !attendee.email) return;
    const doClose = () => {
      closeDialog();
    };
    // Ask for confirmation before deleting attendee
    let wc = willComplete(proposal, { type: 'delete_att', attendee })
    enqueueDialog({
      content: <DeleteAttendeesDialog proposalId={proposal.id}
        completeSlot={wc}
        organizerEmail={proposal.organizer.email} attendees={[attendee]} onClose={doClose} reloadProposal={reloadProposal} />,
      doClose: doClose,
      mustConfirm: true
    });
  }, [proposal, closeDialog, enqueueDialog, reloadProposal]);

  // On click to make attendee optional
  const doMakeOptional = useCallback((attendee) => {
    /* istanbul ignore if */
    if (!attendee || !attendee.email) return;
    const doClose = () => {
      closeDialog();
    };
    // Ask for confirmation before making attendee optional
    let wc = willComplete(proposal, { type: 'opt_att', attendee })
    enqueueDialog({
      content: <OptionalizeAttendeeDialog proposalId={proposal.id}
        completeSlot={wc}
        organizerEmail={proposal.organizer.email} attendee={attendee} onClose={doClose} reloadProposal={reloadProposal} />,
      doClose: doClose,
      mustConfirm: true
    });
  }, [proposal.id, reloadProposal, closeDialog, enqueueDialog]);

  // On click to copy proposal link
  const doCopyLink = useCallback(() => {
    const link = makeProposalLink(proposal.id);
    // dispatch analytics
    dispatch({
      type: ACTIONS.ANALYTICS_GET_LINK,
      payload: {
        proposal: proposal
      }
    });
    copyLinkToClipboard(link);
    enqueueSnackbar(t('event.linkSuccess'), {
      variant: 'success', className: "snack-success",
      action: (key) => {
        return <>
          <Button onClick={() => { closeSnackbar(key) }}>
            {t('common.ok')}
          </Button>
        </>;
      }
    });
  }, [proposal, enqueueSnackbar, closeSnackbar, t]);
  const me = {
    ...user,
    label: t('common.organizer') + " (" + t('common.you') + ")",

  }

  return (
    <div className={"funnel-attendees " + ((proposal.status === STATUS.CONFIRMED) ? "inactive" : "")}>
      <div className="attendee-item placeholder"><div className="">{moment().format(t('common.hourMinutesFormatFunnelSpace'))}</div></div>
      <div className='organizer attendee-item with-border'><AttendeeWithAvatar attendee={me} /></div>
      {attendees.map((att) => <div key={att.email} data-testid={"attendee-" + att.email}><FunnelAttendee att={att} doRemind={doRemind} doCopyLink={doCopyLink} doMakeOptional={doMakeOptional}
        doDelete={doDelete} proposal={proposal} /></div>)}
    </div>
  );
}
export function FunnelAttendee({
  att, proposal,
  doRemind, doCopyLink, doMakeOptional, doDelete
}) {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const isMobile = isMobilePlateform()
  const hasMultipleInvitee = proposal.invitees.length > 1;
  const doOpenTooltip = React.useCallback(() => {
    setMenuOpen(true)
  }, []);
  const doCloseTooltip = React.useCallback(() => {
    setMenuOpen(false)
  }, []);
  // TODO map all events to close popup
  const doDoRemind = React.useCallback((e) => {
    setMenuOpen(false)
    doRemind(att.email);
  }, [att, doRemind])
  const doDoCopyLink = React.useCallback((e) => {
    setMenuOpen(false)
    doCopyLink(e);
  }, [doCopyLink])
  const doDoMakeOptional = React.useCallback((e) => {
    setMenuOpen(false)
    doMakeOptional(att);
  }, [att, doMakeOptional])
  const doDoDelete = React.useCallback((e) => {
    setMenuOpen(false)
    doDelete(att);
  }, [att, doDelete])
  const handleTooltipClose = React.useCallback(() => {
    setMenuOpen(false)
  }, []);
  const hasVoted = attendeeHasVoted(att);
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div onMouseOver={() => {
        doOpenTooltip(att.Email);
      }} onMouseOut={() => {
        doCloseTooltip();
      }}><LMTooltip
        open={menuOpen}
        enterDelay={100}
        disableHoverListener={true}
        content={
          proposal.status === STATUS.PENDING &&
          <div className='matrix-guest-menu'>
            <div className="attendee-email">{getAttendeeName(att)}</div>
            {!hasVoted && !att.isUnknown && <div className='item' onClick={doDoRemind} data-testid={"action-remind-" + att.email}>
              <div>{t('proposal.matrix.guestActions.remind.title')}</div>
              <div>{t('proposal.matrix.guestActions.remind.subtitle')}</div>
            </div>}
            {!hasVoted && att.isUnknown && <div className='item' onClick={doDoCopyLink} data-testid={"action-copylink-" + att.email}>
              <div>{t('proposal.matrix.guestActions.copyLink.title')}</div>
              <div>{t('proposal.matrix.guestActions.copyLink.subtitle')}</div>
            </div>}
            {!att.optional && hasMultipleInvitee &&
              <div className='item' onClick={doDoMakeOptional} data-testid={"action-optionalize-" + att.email}>
                <div>{t('proposal.matrix.guestActions.makeOptional.title')}</div>
                <div>{t('proposal.matrix.guestActions.makeOptional.subtitle')}</div>
              </div>}
            {hasMultipleInvitee && <div className='item' onClick={doDoDelete} data-testid={"action-delete-" + att.email}>
              <div>{t('proposal.matrix.guestActions.delete.title')}</div>
              <div>{t('proposal.matrix.guestActions.delete.subtitle')}</div>
            </div>}
          </div>
        } arrow={true}
        interactive={true}
        placement={isMobile ? 'bottom' : 'left'}>
          <div onClick={() => {
            doOpenTooltip(att.Email);
          }} className="attendee-item" data-testid={"attendee-item-" + att.email}>
            <span key={att.email} className={(att.optional ? " optional" : "") + (hasVoted ? " has-voted " : "")}>
              <AttendeeWithAvatar attendee={att} />
            </span>
          </div>
        </LMTooltip></div ></ClickAwayListener >);
}
function AttendeeWithAvatar({ attendee }) {

  const contacts = useSelector((state) => state.contacts);
  const guestName = attendee.name || attendee.label;
  const guestEmail = isUnknownDomain(attendee.email) ? guestName : attendee.email;
  const avatar_url = attendee.avatar_url || (contacts[guestEmail] || {}).avatar_url;
  return <div className="email-avatar">
    <AvatarWithStates imageSrc={avatar_url} name={guestName || guestEmail} size={2} withBorder={true} />
    <div className='info'>
      {guestName && <span>{guestName}</span>}
      {guestEmail !== guestName && <span>{guestEmail}</span>}
    </div>
  </div>
}
export const FunnelAsDialog = ({ doClose, proposal, doForceMeeting = NOOP, reloadProposal }) => {
  const { t } = useTranslation();
  const [reloading, setReloading] = React.useState(false)
  const [displayProposal, _setDisplayProposal] = React.useState(proposal);
  const setDisplayProposal = React.useCallback((prop = {}) => {
    _setDisplayProposal({
      ...displayProposal,
      ...prop
    })
  }, [displayProposal]);
  const _doForceMeeting = React.useCallback((p, s) => {
    return doForceMeeting(p, s)
      .then((prop) => {
        doClose() // close actual dialog
      });
  }, [proposal, doForceMeeting, doClose]);
  React.useEffect(() => {
    // if reload proposal is set, reload
    if (reloadProposal) {
      setReloading(true)
      reloadProposal(proposal)
        .then((res) => {
          if (res && res.payload && res.payload[0]) setDisplayProposal(res.payload[0]);

        }).catch((err) => {
          // what to do?
        }).finally(() => {
          setReloading(false)
        })
    }
  }, [])
  if (!displayProposal) return null;
  const isSent = !isFunnelInteractive(displayProposal);
  let when = moment(displayProposal.start).format(t("common.fullDateFormat"));
  return <>
    <DialogTitle id="alert-dialog-title" onClose={doClose} className="slots-dlg-title" data-testid="FunnelAsDialog">
      <div>{isSent ? <span>{t('proposal.timeline.status.confirmed')}<div className="strong">{when}</div></span> : t('funnel.title')}</div>
    </DialogTitle>
    <DialogContent className={"funnel-cmp funnel-dlg " + (isSent ? 'inactive' : '')}>
      <DialogProvider>
        {!isSent && !reloading && <div>{t('proposal.matrix.dialogSubtitle')}</div>}
        {!reloading && <Funnel loading={reloading} proposal={displayProposal} doForceMeeting={_doForceMeeting} reloadProposal={setDisplayProposal}
          target='date-fullscreen' />}
        {reloading && <FunnelSkeleton />}
      </DialogProvider>
    </DialogContent>
  </>;
}
// a dummy skeleton for the funnel...
export function FunnelSkeleton({ }) {

  return <div className="skeleton">
    <div className="skeleton-text">skeleton text placeholder for funnel</div>
    <div className="funnel-cmds">
      <div className="cmds"><div></div></div>
    </div>
    <div className="funnel large">
      <div className="funnel-attendees">
        <div className="organizer attendee-item skeleton-text">skeleton text</div>
        <div className="organizer attendee-item skeleton-text">skeleton text</div>
        <div className="organizer attendee-item skeleton-text">skeleton text</div>
      </div>
      <div className="funnel-votes-with-dates">
        <div className="funnel-date-container ">
          <div className="funnel-date"></div>
          <div className="funnel-date"></div>
          <div className="funnel-date"></div>
          <div className="funnel-date"></div>
          <div className="funnel-date"></div>
        </div>
        <div className="funnel-votes">
          <div className="funnel-group ">
            <div className="funnel-slot" />
            <div className="funnel-slot" />
            <div className="funnel-slot" />
          </div>
          <div className="funnel-group ">
            <div className="funnel-slot" />
            <div className="funnel-slot" />
            <div className="funnel-slot" />
          </div>
          <div className="funnel-group ">
            <div className="funnel-slot" />
            <div className="funnel-slot" />
            <div className="funnel-slot" />
          </div>
          <div className="funnel-group ">
            <div className="funnel-slot" />
            <div className="funnel-slot" />
            <div className="funnel-slot" />
          </div>
          <div className="funnel-group ">
            <div className="funnel-slot" />
            <div className="funnel-slot" />
            <div className="funnel-slot" />
          </div>
          <div className="funnel-group ">
            <div className="funnel-slot" />
            <div className="funnel-slot" />
            <div className="funnel-slot" />
          </div>
          <div className="funnel-group ">
            <div className="funnel-slot" />
            <div className="funnel-slot" />
            <div className="funnel-slot" />
          </div>
        </div>
      </div>
    </div>
    <div className="FunnelLegend">
      <div className="legend-item">
        <div className="legend-shape"></div>
        <div className="legend-label skeleton-text">skeleton text</div>
      </div>
      <div className="legend-item">
        <div className="legend-shape"></div>
        <div className="legend-label skeleton-text">skeleton text</div>
      </div>
      <div className="legend-item">
        <div className="legend-shape"></div>
        <div className="legend-label skeleton-text">skeleton text</div>
      </div>
      <div className="legend-item">
        <div className="legend-shape"></div>
        <div className="legend-label skeleton-text">skeleton text</div>
      </div>
    </div>
    <Loader className="centered-loader"></Loader>
  </div>
}
const Filter = ({ proposal, isFilterON, setIsFilterON }) => {

  const { t } = useTranslation();

  const filterLabel = proposal.type === 'poll' ?
    'proposal.matrix.filter.pollPopularTimeSlots' :
    'proposal.matrix.filter.funnelRemainingTimeSlots';

  const onChange = React.useCallback(() => {
    setIsFilterON(!isFilterON)
  }, [setIsFilterON, isFilterON]);

  return (
    <div
      className='MatrixFilter'
      onClick={onChange}>
      <Switch
        checked={isFilterON}
        size="small"
        onChange={onChange} />
      {t(filterLabel)}
    </div>
  );

}
// filter all slots from proposal depending on status and type
const doFilter = (proposal = EMPTY_PROPOSAL) => {
  const now = moment().valueOf();
  const isComplete = proposal.status === STATUS.CONFIRMED;
  const finalSlot = { start: moment(proposal.start || 0).valueOf(), end: moment(proposal.end || 0).valueOf() };
  const isPoll = proposal.type === 'poll';
  const maxVoters = isPoll ? (proposal.maxVotersAvailable || 1) / 2.0 : (proposal.maxVotersAvailable || 1) - 1; // half of voters are OK OR everyone (less 1 because strictly sup)
  const slots = linearize(proposal.slots).filter((sl) => {
    if (!sl) return false;
    // filter out slots from past
    if (moment(sl.start).valueOf() < now) return false;
    // filter out slots based on nbr of voters
    if (isComplete && isSameSlot(finalSlot, sl)) {
      sl.__is_final_slot__ = true; // Python way, never rely on this prop anywhere, this algo can change it whenever it wants!
      return true; // always final slot
    }
    if (isPoll) return true;
    return (sl.selectedBy || []).length > maxVoters;
  });
  // recreate stucture for display
  let grouped = [];
  if (isPoll) {
    // sort by score and date
    const orderedslots = slots.sort((a, b) => {
      // final slot first
      if (a.__is_final_slot__) return -1;
      if (b.__is_final_slot__) return 1;
      let avoters = (a.selectedBy || []).length;
      let bvoters = (b.selectedBy || []).length;
      if (avoters === bvoters) {
        // get dates
        if (a.start > b.start) return 1;
        else return 1;
      }
      return bvoters - avoters;
    })
    grouped = groupSlotForPoll(orderedslots);
  } else {
    // use normal
    grouped = groupSlots(slots);
  }
  return grouped;
}
// group slots but keep order
function groupSlotForPoll(slots) {
  let groupedSlots = [];
  if (slots.length === 0) return groupedSlots
  // init current with 1st slot
  let current = {
    day: moment(slots[0].start).format('YYYY MM DD'),
    slots: [],
  };
  for (let frame of slots) {
    // get start for day
    let day = moment(frame.start).format('YYYY MM DD');
    if (day !== current.day) {
      // new current
      groupedSlots.push(current);
      current = {
        day,
        slots: [],
      }
    }
    // add to list (sorted?)
    current.slots.push(frame);
  }
  // check last one
  if (current.slots.length > 0) groupedSlots.push(current)
  return groupedSlots;
}