import React from 'react';
import GuestsSelector from './guest.selector';
// import GuestsList from './guests.list';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContacts } from '../../../../reducers/contacts';
import { areContactsLoading } from '../../../../reducers/app';
import Guest from './guest';
import { Email, HelpOutline, Link } from '@material-ui/icons';
import { LMTooltip } from '../../../tooltip/Tooltip';
/**
 * Fast guest selector for 1st view
 * display invitees as Pills
 */
import './fast.guest.selector.scss';
const EMPTY = {};
const EMPTY_ARR = [];
export default function FastGuestSelector({
  invitees = EMPTY_ARR,
  inviteesDetails = EMPTY,
  setInvitees,
  errorText,
  setcanSubmit,
  toggleOptional,
  doConnectContacts,
  oboCalendar,
  showPreview, // function to show preview, may be undefined
  simpleContactSelector = false,
  disabled = false,
}) {

  const { t } = useTranslation();
  const contacts = useSelector(getContacts);
  const contactsLoading = useSelector(areContactsLoading)
  const [focused, setFocused] = React.useState(false);

  const onAddContact = React.useCallback((c) => {
    if (!Array.isArray(c)) c = [c]
    // if not already present
    let nv = invitees;
    // filter emails already present
    c = c.filter((m) => !nv.find((i) => m.toLowerCase() === i.toLowerCase()))
    /* istanbul ignore else no work */
    // if (!invitees.find((i) => i === c)) {
    if (c.length > 0) {
      nv = [...invitees, ...c];
      setInvitees({
        target: {
          name: 'invitees',
          value: nv
        }
      });
    }
  }, [invitees]);
  const onAddUnknownContact = React.useCallback((c) => {
    let nv = invitees;
    if (!invitees.find((i) => i === c.email)) {
      nv = [...invitees, c.email];
      setInvitees({
        target: {
          name: 'inviteesDetails',
          value: {
            ...inviteesDetails,
            [c.email]: c
          }
        }
      });
      setInvitees({
        target: {
          name: 'invitees',
          value: nv
        }
      });

    }



  }, [invitees, inviteesDetails])
  const onRemoveContact = React.useCallback((c) => {
    let nv = invitees;
    /* istanbul ignore else no work */
    if (invitees.find((i) => i === c)) {
      nv = invitees.filter((i) => i !== c);
      // only delete for form
      if (!simpleContactSelector) {
        delete inviteesDetails[c];
        setInvitees({
          target: {
            name: 'inviteesDetails',
            value: { ...inviteesDetails },
          }
        });
      }
      setInvitees({
        target: {
          name: 'invitees',
          value: nv,
        }
      });
    }

    // dispatch to the store fo validity check in navigation

    /*dispatch({
      type: CREATE_EVENT_STEP_1,
      payload: {
        invitees: nv,
        // @TODO periods of time
      },
    })*/


  }, [invitees, inviteesDetails, simpleContactSelector/*dispatch*/]);

  const startAdornment = React.useMemo(() => {
    if (invitees && invitees.length > 0) {
      return invitees.map((i) => {
        let details = inviteesDetails[i] || {};
        return (<div className="chips" key={i}>
          <Guest displayAsChips={true} onClick={onRemoveContact}
            toggleOptional={toggleOptional} email={i} details={details}
          />
        </div>);
      })
    } else return null;
  }, [invitees, inviteesDetails, onRemoveContact, toggleOptional, simpleContactSelector]);
  const someInviteesAreNotDoners = React.useMemo(() => {
    if (!invitees || invitees.length === 0) return false;
    for (let invitee of invitees) {
      let unknown = (inviteesDetails[invitee] || {}).isUnknown === true;
      if (unknown) continue; // unknown are NOT not doners
      if (contacts[invitee]) {
        if (!contacts[invitee].isDoner) return true;
      } else return true;
    }
    return false;
  }, [invitees, inviteesDetails, contacts]);
  const someInviteesAreUnknown = React.useMemo(() => {
    for (let inv of Object.values(inviteesDetails)) {
      if (inv.isUnknown) return true;
    }
    return false;
  }, [inviteesDetails]);

  const doFocus = React.useCallback((e) => {
    setFocused(e);
  }, []);
  return <>
    <fieldset className={`done-input done-calendar-selector fast-selector ${focused && 'Mui-focused'} ${errorText && 'Mui-error'} ${disabled ? 'disabled' : ''}`}>
      {!simpleContactSelector && <legend className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined done-input-label  with-help-button">
        {t(contactsLoading ? 'createEvent.form.invitees_loading' : 'createEvent.form.invitees')}
        {!simpleContactSelector && <LMTooltip
          childrenClassName="showHand"
          content={
            <>
              <p><Trans i18nKey="createEvent.form.guests.tooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
            </>
          }
        >
          <HelpOutline />
        </LMTooltip>}
      </legend>}

      <GuestsSelector setcanSubmit={setcanSubmit} onAddContact={onAddContact} startAdornment={startAdornment} doFocus={doFocus} oboCalendar={oboCalendar}
        doConnectContacts={doConnectContacts} onAddUnknownContact={onAddUnknownContact} simpleContactSelector={simpleContactSelector} />
      {/*invitees && invitees.length > 0 && <GuestsList displayAsChips={true} invitees={invitees} onRemoveContact={onRemoveContact} />*/}
      <div className='legend'>
        {!simpleContactSelector && someInviteesAreUnknown &&
          <div className="not-all-doners">
            <Link />
            <div>
              {t('createEvent.form.unknowninviteesTip')}
              {/* {showPreview &&
            <span className="preview-link" data-testid="preview" onClick={showPreview}>
              {t("createEvent.preview.label")}
            </span>} */}
            </div>
          </div>}
        {!simpleContactSelector && someInviteesAreNotDoners &&
          <div className="not-all-doners">
            <Email />
            <div>
              {t('createEvent.form.externalinviteesTip')}
              {/* {showPreview &&
            <span className="preview-link" data-testid="preview" onClick={showPreview}>
              {t("createEvent.preview.label")}
            </span>} */}
            </div>
          </div>}
      </div>
    </fieldset>
    {errorText &&
      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errorText}</p>}
  </>;
}
