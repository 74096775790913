import React from 'react';
import { useTranslation } from 'react-i18next';
import './Availability.scss';
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";// browser one
import { CONFIG } from '../../../../utils/gen.timeframes';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DaySelector from './day.selector';
import DayAvailability from './day.availability';
import TimezonePicker from './timezone.picker';
import DoneButton from '../../../DoneButton';
import * as ACTIONS from '../../../../actions';
import { Loader } from '../../../Loader';
import NOOP from '../../../../utils/noop';
import { useSnackbar } from 'notistack';
import '../../create-event/inputs/inputs.scss';
import PROFILE_VALIDATOR from '../../../../validators/profile';
import { getWorkingHours } from '../../../../reducers/profile';
import { Switch } from '../../../LMSwitch';
export default function Availability({ className = '', onClose = NOOP }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const current = useSelector(getWorkingHours);
  // Some custom datas
  const DAYS_OF_WEEK = React.useMemo(() => {
    // get locale dow
    let DAYS = ['1', '2', '3', '4', '5', '6']; // iso day indexes without sunday
    let sow = moment(current).startOf("week");
    // pass sunday at end of week
    // Or at first day depending on locale
    if (sow.day() === 1) DAYS.push('7');
    else DAYS.unshift('7'); // sunday is first day

    return DAYS;
  }, []);

  const [loading, setLoading] = React.useState(true);
  const [saving, isSaving] = React.useState(false);
  React.useEffect(() => {
    // load user working hours infos
    dispatch({
      type: ACTIONS.WORKER_REFRESH_PROFILE,
      resolvers: {
        resolveOn: ACTIONS.WORKER_REFRESH_PROFILE_SUCCESS,
        rejectOn: ACTIONS.WORKER_REFRESH_PROFILE_ERROR,
      }
    }).catch((err) => {
      // silently fail?
      console.log(err)
    }).then(() => {
      setLoading(false);
    })
  }, []);
  const formik = useFormik({
    initialValues: current,
    enableReinitialize: true,
    validationSchema: PROFILE_VALIDATOR,
    onSubmit: (values) => {
      // save on server
      // if autoTZ === yes, set to current timezone
      if (values.autoTZ) {
        values.timezone = moment.tz.guess()
      }
      isSaving(true);
      dispatch({
        type: ACTIONS.WORKER_SAVE_WH,
        payload: values,
        resolvers: {
          resolveOn: ACTIONS.WORKER_SAVE_WH_SUCCESS,
          rejectOn: ACTIONS.WORKER_SAVE_WH_ERROR,
        }
      })
        .then(() => {
          enqueueSnackbar(t('settings.sections.availability.saveSuccess'), {
            variant: 'success',
            preventDuplicate: true,
            className: 'snack-success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        })
        .catch((err) => {
          // silently fail?
          enqueueSnackbar(t('settings.sections.availability.saveFail'), {
            variant: 'error',
            preventDuplicate: true,
            className: 'snack-error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        }).then(() => {
          // do close?
          isSaving(false);
          onClose();
        })
      return;
    }
  });
  const handleEnableChange = React.useCallback((e) => {
    // if no more results, desable WH
    /* istanbul ignore else no work */
    if (e.target.checked === true) {
      // was re-enabled, check values
      if (Object.keys(formik.values.workingHours).length === 0) {
        // set default
        formik.setFieldValue('workingHours', JSON.parse(JSON.stringify(CONFIG)))
      }
    }
    formik.handleChange(e);
  }, [formik.values, formik.handleChange]);
  // custom change methods
  const handleDaysChange = React.useCallback((e) => {
    // if no more results, desable WH
    /* istanbul ignore else no work */
    if (Object.keys(e.target.value).length === 0) {
      formik.setFieldValue('enabled', false)
    }
    formik.handleChange(e);
  }, [formik.handleChange]);
  const copyAll = React.useCallback((day) => {
    // copy frame to all
    let wh = {};
    for (let d of Object.keys(formik.values.workingHours)) {
      wh[d] = day;
    }
    formik.handleChange({
      target: {
        name: 'workingHours',
        value: wh
      }
    });
  }, [formik.values.workingHours, formik.handleChange]);


  if (loading) return <Loader />
  return (
    <>
      {saving && <Loader />}
      <form className={className} data-testid="wh-form">
        <div className="settings-availability">
          <div className="section">
            <div>
              <div className="title">
                {t('settings.sections.availability.title')}
              </div>
              <div className="subtitle">
                {t('settings.sections.availability.subtitle')}
              </div>
            </div>
            <div className="item">
              <FormControlLabel
                control={<Switch data-testid="wh-enable" value={true} checked={formik.values.enabled} color="primary" name="enabled" onChange={handleEnableChange} />}
                label={t("settings.sections.availability.content.enable")}
                labelPlacement="end"
                className="no-padding"
              />
            </div>
            {formik.values.enabled &&
              <>
                <div className="item">
                  <DaySelector values={formik.values.workingHours} onChange={handleDaysChange} dow={DAYS_OF_WEEK} />
                </div>
                {
                  DAYS_OF_WEEK.map((d) => {
                    if (formik.values.workingHours[d]) {
                      return <div key={'day-' + d}><DayAvailability day={d} values={formik.values.workingHours[d]} onChange={formik.handleChange} onCopyAll={copyAll} /></div>
                    } else return null;
                  })
                }

              </>}
            <div className="item">
              <TimezonePicker value={formik.values.timezone} auto={formik.values.autoTZ} onChange={formik.handleChange} />
            </div>
          </div>
          <div className="item save">
            <DoneButton type="submit" label={t('settings.sections.availability.content.save')}
              onClick={formik.handleSubmit}
              data-testid="wh_submit"
              name="valid" className="form" />
          </div>
        </div>
      </form>
    </>
  )
}