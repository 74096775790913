import React from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../../../api/validators/proposal';
import { useSelector } from 'react-redux';
import { getAccountMails } from '../../../../../reducers/accounts';
import NOOP from '../../../../../utils/noop';
import DoneButton from '../../../../DoneButton';
import "./response.scss";

export default function Response({ proposal, showSlots = NOOP, loading }) {
  const { t } = useTranslation();
  const accountsEmails = useSelector(getAccountMails);

  if (!proposal) return null;

  const iAmHost = proposal.iAmHost;
  if (iAmHost || (proposal.status !== STATUS.PENDING)) return null;

  // if was not registered, must display waiting for you!
  const registeredWhenProposalWasCreated = accountsEmails.find(userEmail => {
    return (proposal.inviteesDetails[userEmail] || {}).registered;
  });
  const hasAnswer = accountsEmails.find(userEmail => {
    return (proposal.inviteesDetails[userEmail] || {}).response === 'yes';
  });
  const hasConflicts = accountsEmails.find(userEmail => {
    let u = (proposal.inviteesDetails[userEmail] || {});
    return (u.conflicting_slots && u.conflicting_slots.length > 0);
  });
  const voteRequired = accountsEmails.find(userEmail => {
    const dt = (proposal.inviteesDetails[userEmail] || {})
    return dt.vote_requested && dt.response !== 'yes';
  });
  if (hasAnswer) {
    return <div className="timeline-item response">
      <span className="covered">{t('event.successVoteTitle')}</span>
    </div>
  }

  // check if must answer to this proposal
  if (hasConflicts || voteRequired) return null; // will be trated in unknown responses

  return registeredWhenProposalWasCreated ? (<div className="timeline-item response autoresponse">
    <span className="covered">{t('proposal.timeline.response.gotYouCovered')}</span>
    <span className="covered-more">{t('proposal.timeline.response.gotYouCovered_edit')}</span>
    {!iAmHost && <DoneButton name="btn-edit" disabled={loading} className="white" onClick={(e) => showSlots(true)} label={t('proposal.timeline.response.edit')} />}
  </div>) : (<div className="timeline-item response">
    <span className="covered">{t('proposal.timeline.response.waitingForYou')}</span>
    {!iAmHost && <DoneButton name="btn-edit" className="white" disabled={loading} onClick={(e) => showSlots(true)} label={t('proposal.timeline.response.edit')} />}
  </div>);
}