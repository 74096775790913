import * as Yup from 'yup';
import moment from 'moment';
import { CONFIG } from '../../utils/gen.timeframes';
/*
exemple api:
{
  data:{
    ...user infos,
    id: string,
    timezone: string,
    last_calendar: {
        "provider": "google",
        "user_id": "suzy@gmail.com",
        "cal_id": "alternate-calendar@gmail.com"
    },
    working_hours:{
      enabled: true,
      days:{
        "sunday":{
          from: "11:00",
          to: "12:00"
        }
      }
    }
  }
}
exemple app
{
  enabled: true,
  timezone: string,
  workingHours:{
    '0':{
      start: 11,
      end: 12
    }
  },
  lastCalendar: {
        "provider": "google",
        "user_id": "suzy@gmail.com",
        "cal_id": "alternate-calendar@gmail.com"
    },
}
*/
const DAYS = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']; // isoweek sunday = 7
function noOverlapp(value) {
  let [v1, v2] = value;
  return (v1.to < v2.from) || (v1.from > v2.to)
}
const FRAME = Yup.object().shape({
  from: Yup.string().matches(/\d{2}:\d{2}/).required(), // ex: 09:00
  to: Yup.string().matches(/\d{2}:\d{2}/).required(), // ex: 09:00
}).test('api-frames', 'invalid frames from api', (v) => {
  return v.from < v.to;
});
const FRAMES = Yup.array().of(FRAME).min(1).max(2)
  .test('no-overlap-wh-api', 'Frames overlap', (value) => {
    if (!value) return true;
    if (value.length === 1) return true;
    return noOverlapp(value);
  });
// generate days
let wh = {};
for (let day of DAYS) {
  if (day) wh[day] = FRAMES.default(undefined);
}
export const WH_DAY = wh;
// Api data validator
export const PROFILE = Yup.object().shape({
  // user infos @TODO
  id: Yup.string(),
  timezone: Yup.string().required(),
  working_hours: Yup.object().shape({
    enabled: Yup.boolean().default(false),
    days: Yup.object(wh)
  }),
  last_calendar: Yup.object().shape({
    provider: Yup.string(),
    user_id: Yup.string(),
    cal_id: Yup.string(),
  }).default(undefined),
  tools: Yup.array(Yup.object({
    toolId: Yup.string().required()
  })),
  fb_visibility: Yup.number().min(0).max(31).default(0)
});


export function wh_toapi(workingHours = {}) {
  let wh = {
    days: {}
  };
  for (let day of Object.keys(workingHours)) {
    let frames = workingHours[day].frames;
    if (frames.length === 0) continue;
    let frms = [];
    for (let frame of frames) {
      // CHANGE: now got float values
      // ie: 1.5 => 01:30
      frms.push({
        from: `${Math.floor(frame.start)}`.padStart(2, '0') + ':' + (frame.start - Math.floor(frame.start) > 0 ? '30' : '00'),
        to: `${Math.floor(frame.end)}`.padStart(2, '0') + ':' + (frame.end - Math.floor(frame.end) > 0 ? '30' : '00'),
      })
    }
    wh.days[DAYS[day]] = frms;
  }
  return wh;
}
export function wh_fromapi(working_hours = {}) {
  let res = {};
  let w_h = (working_hours || { days: {} }).days || {};
  let keys = Object.keys(w_h).filter((key) => DAYS.find((d) => d === key));
  for (let key of keys) {
    let apiframes = w_h[key];
    let whframes = [];
    for (let apiframe of apiframes) {
      whframes.push({
        // only hours needed as number ==> CHANGE, use minutes (00 or 30)
        start: +(apiframe.from.split(':')[0] + '.' + (apiframe.from.split(':')[1] === '00' ? '0' : '5')),
        end: +(apiframe.to.split(':')[0] + '.' + (apiframe.to.split(':')[1] === '00' ? '0' : '5'))
      });
    }
    // sort
    whframes = whframes.sort((a, b) => (a.start < b.start) ? -1 : 1);
    // add with index
    let index = DAYS.findIndex((d) => d === key);
    res['' + index] = { frames: whframes };
  }
  return res;
}
export function toAPI(wh = {}) {
  let profile = {
    autoTZ: wh.autoTZ,
    notify_follow_up: wh.notify_follow_up,
    lang: wh.lang,
    timezone: wh.timezone,
    link_name: wh.link_name,
    working_hours: {
      enabled: wh.enabled,
      days: {}
    },
    fb_visibility: wh.fb_visibility,
    are_slots_suggestion_allowed: wh.are_slots_suggestion_allowed,
    overlapp: wh.overlapp,
  };
  profile.working_hours = wh_toapi(wh.workingHours);
  profile.working_hours.enabled = wh.enabled;
  return profile;
}
// inverse of preceding
export function fromAPI(wh, setTZ = true) {
  // at first, could be empty, so return default config
  if (!wh) return {
    enabled: true,
    default: true,
    autoTZ: true,
    notify_follow_up: (wh || { notify_follow_up: true }).notify_follow_up, // hum...
    timezone: moment.tz.guess(),
    workingHours: CONFIG,
    lastCalendar: undefined,
    tools: [],
    __tz_need_update: true,
    fb_visibility: 31,
    // premium user infos
    billing: {},
    // free user quota
    monthly_quota_remaining: 5,
    lang: "en",
    link_name: wh.link_name,
    are_slots_suggestion_allowed: true, // per default?
    overlapp: 30,
    newFormUser: true,
  };

  let working = {
    ...wh,
    // woking hours can be nil
    enabled: (wh.working_hours || { enabled: true }).enabled,
    autoTZ: wh.autoTZ,
    notify_follow_up: wh.notify_follow_up,
    timezone: wh.timezone,
    workingHours: {},
    lastCalendar: wh.last_calendar,
    tools: wh.tools || [],
    fb_visibility: wh.fb_visibility, //  || 0,
    monthly_quota_remaining: wh.monthly_quota_remaining || 0,
    billing: wh.billing || {},
    lang: wh.lang || 'en',
    link_name: wh.link_name,
    are_slots_suggestion_allowed: wh.are_slots_suggestion_allowed === undefined ? true : wh.are_slots_suggestion_allowed,
    overlapp: +wh.overlapp || 30,
    newFormUser: moment(wh.created_at) >= moment(process.env.REACT_APP_NEW_USER_SINCE || "1970-01-01")
  };
  // Update: if auto tz, get/set timezone from locale
  if (setTZ) {
    if (wh.autoTZ === true) {
      let locale = moment.tz.guess();
      working.__tz_need_update = wh.timezone !== locale;
      working.timezone = locale;
    }
    if (working.timezone) {
      moment.tz.setDefault(wh.timezone);
    }
  }
  // if got WH, parse them
  if (wh.working_hours) {
    working.workingHours = wh_fromapi(wh.working_hours)
  } else working.workingHours = CONFIG; // no WH, add default
  return working;
}

export function translateWH(working_hours = { days: {} }) {
  let w_h = (working_hours || { days: {} }).days || {};
  let keys = Object.keys(w_h).filter((key) => DAYS.find((d) => d === key));
  for (let key of keys) {
    let apiframes = working_hours.days[key];
    let whframes = [];
    for (let apiframe of apiframes) {
      whframes.push({
        // only hours needed as number ==> CHANGE, use minutes (00 or 30)
        start: +(apiframe.from.split(':')[0] + '.' + (apiframe.from.split(':')[1] === '00' ? '0' : '5')),
        end: +(apiframe.to.split(':')[0] + '.' + (apiframe.to.split(':')[1] === '00' ? '0' : '5'))
      });
    }
    // sort
    whframes = whframes.sort((a, b) => (a.start < b.start) ? -1 : 1);
    // add with index
    let index = DAYS.findIndex((d) => d === key);
    working_hours.days[index] = { frames: whframes };
  }
  return working_hours
}