import React from 'react';
import { useTranslation } from "react-i18next";
import "./tlc.scss";

export default function Tlc(){
  const {t} = useTranslation();
  return <div className="tlc">
    <span>{t('common.tlc')}</span>
    <a href={t('settings.sections.privacy.content.sectionTermsAndConditionsURL')} target='_BLANK'>{t('common.terms')}</a>
    <span>{t('common.and')}</span>
    <a href={t('settings.sections.privacy.content.sectionPrivacyPolicyURL')} target='_BLANK'>{t('common.privacy')}</a>
  </div>
}