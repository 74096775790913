import React, { useEffect, useMemo } from 'react';
// import {Proposal} from './Proposal'
import moment from 'moment';
import { Slot } from './Slot';
import { useTranslation } from 'react-i18next';
import { useFormik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import './proposal.scss';
import DoneButton from '../../DoneButton';
import { BROWSER } from '../../../utils/browser';
import Button from '@material-ui/core/Button'
import { useDialog } from '../../dialogs/dialog.provider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Update: calculate 5 "best slots"

const EMPTY_STYLE = 0;
const HEADER_HEIGHT = 30; // date height in px
const ELEM_HEIGHT = 64; // in px

export default function Proposals({ proposals, onSubmit, onDecline, onCancel, multiselect = true, _showAll = false, isRegistered = false }) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { enqueueDialog, closeDialog } = useDialog();
  const history = useHistory();
  const topRef = React.useRef();
  // show all slots or only a subset? If multiselect == false, do not apply
  //const [showAll, setShowAll] = React.useState(!multiselect || _showAll);
  const [topFakeStyle, setTopFakeStyle] = React.useState(0);
  // React.useEffect(()=>{
  //   // get page height
  //   console.log(document.body.clientHeight)  => call directly
  //   setwindowHeight(document.body.clientHeight)
  // }, []);
  // if not host, show remaining slots, else show all slots
  let sls = multiselect ? proposals.remaining_slots : proposals.slots;

  const init = sls.reduce((acc, v) => {
    acc.push(...v.slots.map((vs) => (multiselect ? vs.IAmFree : false)));
    return acc;
  }, []);


  const doDecline = React.useCallback((e) => {
    // ask for confirmation
    const unblock = history.block();
    const doClose = () => {
      unblock();
      closeDialog();
    };
    const doDecline = () => {
      unblock();
      onDecline();
      closeDialog();
    }
    enqueueDialog({
      content: <DeclineDialog onDecline={doDecline} onClose={doClose} />,
      doClose: doClose,
    })
  }, [history, onDecline, enqueueDialog, closeDialog])
  // listen to main scroll event
  React.useEffect(() => {
    const doScroll = (e) => {
      // check position top of fake scroll
      let top = document.getElementById('top-scroll-fake');
      let topbounds = top.getBoundingClientRect();

      if (topbounds.top < 0) {
        // means, list start beeing out of window
        // set new height
        let nh = Math.ceil(-topbounds.top / ELEM_HEIGHT) * ELEM_HEIGHT;
        setTopFakeStyle(nh);
      } else {
        setTopFakeStyle(EMPTY_STYLE)
      }
    };
    window.addEventListener('scroll', doScroll);
    return () => window.removeEventListener('scroll', doScroll);
  }, []);

  const formik = useFormik({
    initialValues: {
      slots: init,
    },
    // validationSchema: EventSchemaStep1,
    enableReinitialize: true,
    onSubmit: (values) => {
      // check at least 1 slot selected
      let isValid = values.slots.find((v) => v);
      if (!isValid) {
        // show errir
        let label = multiselect ? 'event.errors.noSlotSelectedForConfirm' : 'event.errors.noSlotSelected'
        enqueueSnackbar(t(label), {
          variant: 'error',
          preventDuplicate: true,
          className: 'snack-error',
          action: (key) => {
            return <>
              <Button onClick={() => { closeSnackbar(key) }}>
                {t('common.ok')}
              </Button>
            </>;
          }
        })
        return;
      }
      // dispatch to state
      onSubmit(values.slots)
    }
  });

  const confirmSlotsButtonDisabled = useMemo(() => {
    for (let i = 0; i < formik.values.slots.length; i++) {
      let slot = formik.values.slots[i];
      if (slot) { return false; }
    };
    return true;
  }, [formik.values.slots]);


  const handleChange = React.useCallback((e) => {

    if (!multiselect) {
      // clear all?
      formik.setFieldValue('slots', init);
    }
    formik.handleChange(e);
  }, [init, multiselect, formik.handleChange, formik.setFieldValue])

  const isMobile = BROWSER.getPlatformType() === 'mobile' || BROWSER.getPlatformType() === 'tablet';
  let best = (proposals.iAmHost || _showAll) ? undefined : proposals.bestSuggestions;
  const { topStyle, bottomStyle, elems } = React.useMemo(() => {
    if (!topRef.current) return { topStyle: {}, bottomStyle: {}, elems: [] };
    let topPos = topRef.current.getBoundingClientRect().top;
    let count = -1;
    let pos = topPos; // position of 1st element in pixels
    let elems = [];
    let topHeight = 0; // heights of fakers div
    let bottomHeight = 0;

    const MIN_POS_X = -5 * ELEM_HEIGHT;
    const MAX_POS_X = document.body.clientHeight + 10 * ELEM_HEIGHT;


    if (sls) {
      for (let i = 0; i < sls.length; i++) {
        let pro = sls[i];
        if (
          //!_showAll && // show olny best
          best && // add some best datas
          !best[i] // but not for this day
        ) {
          // do not show
          // console.log('REMOVE DAY FROM PROPOSALS', _showAll, best);
          count += pro.slots.length;
          continue;
        }

        if (pos + HEADER_HEIGHT < MIN_POS_X) {
          // add to top
          topHeight += HEADER_HEIGHT;
        } else if (pos > MAX_POS_X) {
          // add to bottom
          bottomHeight += HEADER_HEIGHT;
        } else {
          // affiche a l'ecran
          elems.push(<div key={pro.day} className="proposal-day-date">{moment(pro.day).format(t('common.dateFormat'))}</div>)
        }
        pos += HEADER_HEIGHT; // add an header

        elems.push(pro.slots.map((slot, j) => {
          count++;
          if (best && best[i] && best[i].find((b) => b === j) === undefined) return null;

          // same as for header
          if (pos + ELEM_HEIGHT < MIN_POS_X) {
            // add to top
            topHeight += ELEM_HEIGHT;
            pos += ELEM_HEIGHT;
            return null;
          } else if (pos > MAX_POS_X) {
            // add to bottom
            bottomHeight += ELEM_HEIGHT;
            pos += ELEM_HEIGHT;
            return null;
          }
          pos += ELEM_HEIGHT;
          return (
            <div className="proposal-items" key={"slot-" + proposals.id + '-' + count}>
              <Slot
                slot={slot}
                value={formik.values.slots[count]}
                name={'slots[' + count + ']'}
                onChange={handleChange}
                multiselect={multiselect}
              />
            </div>
          );
        }));
      }
    }

    return { topStyle: { height: topHeight }, bottomStyle: { height: bottomHeight }, elems };

  }, [sls, topFakeStyle, best, topRef.current, formik.values]);




  return (
    <form className="proposal-day">
      <div id="top-scroll-fake" ref={topRef} style={topStyle}></div>
      {
        elems
      }
      <div id="bottom-scroll-fake" style={bottomStyle}></div>
      <div className="event-proposal-actions mobile">
        <div className="actions">
          {multiselect ?
            <React.Fragment>
              <DoneButton name={"btn-vote"}
                label={t(isRegistered ? 'event.buttonConfirmAvailabilityRegistered' : 'event.buttonConfirmAvailability')} onClick={formik.handleSubmit} />
              <DoneButton label={t('event.decline')} name={"btn-decline"}
                className="no-margin grey small decline"
                // className="white secondary"
                onClick={doDecline} />
            </React.Fragment> :
            <React.Fragment>
              {!confirmSlotsButtonDisabled && <DoneButton name={"btn-vote"}
                label={t('event.buttonConfirmMeeting')} onClick={formik.handleSubmit} />}
              <DoneButton label={t('event.cancel')} name={"btn-cancel"}
                className="no-margin grey small decline"
                // className="white secondary"
                onClick={onCancel} />
            </React.Fragment>}
        </div>
      </div>
      {
        // how ugly is that? yeah!
        isMobile &&
        <div className={"event-proposal-actions mobile fake-block"}>
          <div className="actions">
            <DoneButton name={"btn-vote"} className={(confirmSlotsButtonDisabled ? "grey" : "")} disabled={confirmSlotsButtonDisabled}
              label={t(multiselect ? 'event.buttonConfirmAvailability' : 'event.buttonConfirmMeeting')} onClick={formik.handleSubmit} />
            {multiselect ?
              <DoneButton label={t('event.decline')} name={"btn-decline"}
                className="no-margin grey small decline"
                // className="white secondary"
                onClick={doDecline} /> :
              <DoneButton label={t('event.cancel')} name={"btn-cancel"}
                className="no-margin grey small decline"
                // className="white secondary"
                onClick={onCancel} />}
          </div>
        </div>}
    </form>
  );

}
// <Proposal proposal={pro} index={i} values={formik.values.slots[i]} onChange={formik.onChange}/>
export function DeclineDialog({ onDecline, onClose, onProposeNewSlots }) {
  const { t } = useTranslation();
  const proposeNewSlots = onProposeNewSlots !== undefined;

  return <div className="cancel-dialog" data-testid="cancel-event-dialog">

    <DialogTitle id="alert-dialog-title">{t(proposeNewSlots ? 'event.declineDlg.title' : 'event.declineDlg.titleAreYouSure')}
    </DialogTitle>
    {/* <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {t('event.declineDlg.message')}
      </DialogContentText>
    </DialogContent> */}
    <DialogActions>
      <Button onClick={onClose} data-testid="decline-event-dialog-cancel">
        {t(proposeNewSlots ? 'event.declineDlg.dismiss' : 'common.no')}
      </Button>
      <Button onClick={onDecline} color="secondary" autoFocus data-testid="decline-event-dialog-ok">
        {t(proposeNewSlots ? 'event.declineDlg.ok' : 'common.yes')}
      </Button>
      {onProposeNewSlots && <Button onClick={onProposeNewSlots} color="primary" data-testid="propose-new-slots-dialog-cancel">
        {t('event.declineDlg.proposeNewSlots')}
      </Button>}
    </DialogActions>

  </div>
}