
import React from 'react';
import DoneButton from '../../../DoneButton'
import NOOP from '../../../../utils/noop';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress
} from '@material-ui/core'
export default function CTA({ label, name = "valid", handleSubmit, canSubmit, isGeneratingLink, className = "", endIcon }) {
  const { t } = useTranslation();
  return <DoneButton
    label={
      isGeneratingLink ?
        <>
          <CircularProgress data-testid="loading-cta" />
          <div className="removing">
            {t('createEvent.form.loading')}
          </div>
        </>
        : t(label)}
    onClick={canSubmit ? handleSubmit : NOOP}
    disabled={handleSubmit == undefined}
    name={name} className={"form " + className}
    endIcon={endIcon} />
}