import React from "react";
import { useTranslation } from "react-i18next";

import "./refused.scss";

const EMPTY = [];
export default function Refused({ invitees,
  inviteesDetails, accountsEmails = EMPTY }) {
  const { t } = useTranslation();
  if (!invitees || invitees.length === 0) return null;
  const accused = invitees[0];
  const iAmAccused = accountsEmails.find(a => a === accused);
  const details = (inviteesDetails || {});
  const accusedDetails = details[accused] || {};
  return (
    <div className="timeline-item refused-answer ">
      <div className="title">
        {t(
          iAmAccused
            ? "proposal.timeline.refused.titleYou"
            : "proposal.timeline.refused.title",
          { email: accusedDetails.isUnknown ? accusedDetails.label : invitees[0] }
        )}
      </div>
      {iAmAccused && <span>{t("proposal.timeline.refused.note")}</span>}
    </div>
  );
}
