/**
 * An utility to use fetch with tokens
 * 
 * fetch => 401 => refresh provider Token -> Sign in Done => retry (3 times)
 * fetch => 403 => dispatch error for sign up
 */
// @DEV ONLY init mocks

import * as STORE from '../worker/worker.store';
import replay from '../utils/replay';
import * as ACTIONS from '../actions';
import { cachedFetch } from './cached.fetch'
// new token in header
const MM_TOKEN = "MeetingsMonster-Authorization";
const CLIENT = "web"

export function doneFetch(url, opts = {}, addToken = true) {
  let p = () => __decoratedFetch(url, opts, addToken);
  return replay(p, { count: 2, minTimeout: 500 });
}
// export for test
export function __decoratedFetch(url, opts = {}, addToken = true) {
  const store = STORE.getStore();

  if (addToken) {
    let token = store.token || {};
    token = token.done;
    if (token) {
      let hd = opts.headers || {};
      hd.Authorization = `Bearer ${token}`;
      opts.headers = hd;
    }
  }
  if (store.anonymousId) {
    let token = store.anonymousId;
    let hd = opts.headers || {};
    hd["LM-Anonymous-ID"] = token;
    opts.headers = hd;
  }
  {
    let hd = opts.headers || {};
    hd["LM-Client"] = CLIENT;
    opts.headers = hd;
  }
  return global.fetch(url, opts)
    .then((rep) => {
      let mm_header = rep.headers.get(MM_TOKEN);
      if (mm_header) {
        // change token for access
        let tk = mm_header.replace(/^Bearer /, '');
        let old = store.token ? store.token.done : '';
        if (old !== tk) {

          store.token = {
            done: tk,
          };
          // dispatch to app for saving in LS
          postMessage({
            type: ACTIONS.DONE_TOKEN_REFRESH,
            payload: store.token,
          })
        }
      }
      return rep;
    })
    .then((rep) => {
      let status = rep.status;

      if (status < 300) {
        if (status === 204) return Promise.resolve({})
        let type = rep.headers.get("Content-Type")
        if (type === 'application/json') return rep.json().then((dt) => {
          if (!dt) {
            dt = {};
          }
          dt.__request_status__ = status;
          return dt;
        })
        else return rep.text();
      }

      if (status === 401) { // hack dev only
        // Done token is no more valid, refresh provider and resign
        if (store.token) store.token.done = undefined; // reset token

        // dispatch login screen TODO reactivate!!!!
        postMessage({
          type: ACTIONS.INVALID_CREDENTIALS,
        })
        return Promise.reject({ code: 'INVALID_CREDENTIALS', msg: 'Provider token is invalid' });
      }

      // non supported status, reject
      let typ = (rep.headers.get("Content-Type") === 'application/json') ? rep.json() : rep.text();
      return typ.then(value => {

        // post message to main event loop (used by sentry)
        postMessage({
          type: ACTIONS.FETCH_ERROR_IN_WORKER,
          payload: { httpStatus: status, httpResponse: value, fetchUrl: url, fetchOpts: opts },
        });

        return Promise.reject({
          code: 'EPIC_FAIL',
          msg: 'server error',
          status,
          details: value
        })
      });
    })
}

export const decoratedFetch = cachedFetch(doneFetch);
