import React from "react";
import moment from "moment";
import NOOP from "../../../../../utils/noop";
import { useTranslation } from "react-i18next";
import "./unknown.scss";
import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { useDialog } from "../../../../dialogs/dialog.provider";
import { MAX_INVITEES_LEGIT_TO_DISPLAY } from "../../../../../conf/general";

export default function Unknwons({
  invitees,
  inviteesDetails,
  remainingCount = 0,
  isActive = false,
  isSmaller = false,
  isPoll = false
}) {
  const { t } = useTranslation();
  const { enqueueDialog, closeDialog } = useDialog();

  let mand = Object.values(inviteesDetails || {}).filter((inv) => {
    return invitees.includes(inv.email) && !inv.optional;
  }).map((inv) => inv.isUnknown ? inv.label : inv.email);
  let opt = Object.values(inviteesDetails || {}).filter((inv) => {
    return invitees.includes(inv.email) && inv.optional;
  }).map((inv) => inv.isUnknown ? inv.label : inv.email/* + ' ' + t('proposal.details.optional')*/);

  const showAllInvitees = React.useCallback(() => {
    const onClose = () => {
      closeDialog();
    }
    enqueueDialog({
      content: <>
        <DialogTitle className='lm-guests-popup-title'>
          {t('proposal.details.guestsPopupTitle')}
          <IconButton aria-label="close" onClick={onClose} className="close-button">
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"lm-guests-popup fancy-scroll"}>
          <div className='section'>
            <div>
              {mand.map(
                (inv) => <div key={inv}>{inv}</div>
              )}
            </div>
          </div>
          {opt.length > 0 &&
            <div className='section'>
              <div>Optional:</div>
              <div>{opt.map(
                (inv) => <div key={inv}>{inv}</div>
              )}</div>
            </div>}
        </DialogContent>
      </>,
      doClose: onClose,
    })
  }, [enqueueDialog, closeDialog, mand, opt, t])

  const nbGuests = React.useMemo(() => {
    return (invitees || []).length;
  }, [invitees]);

  const tooManyInvitees = React.useMemo(() => {
    return nbGuests > MAX_INVITEES_LEGIT_TO_DISPLAY;
  }, [nbGuests])

  if (!invitees || invitees.length === 0) return null;
  const shrink = invitees.length > 5;
  return (
    <div
      className={"timeline-item unknwon-answer " + (isActive ? "active" : "") + (isSmaller ? ' small ' : '') + (shrink ? " shrink-invitees" : "")}
    >
      <div className="title">
        {t(
          isActive
            ? "proposal.timeline.unknown.title"
            : "proposal.timeline.unknown.no_response",
          { count: nbGuests }
        )}
      </div>
      {tooManyInvitees ?
        <span><a href='#' onClick={showAllInvitees}>{t('proposal.timeline.unknown.viewFullList', { count: nbGuests })}</a></span>
        :
        invitees.map(invitee => {
          let detail = (inviteesDetails[invitee] || {});
          let email = detail.isUnknown ? detail.label : invitee
          return (<div key={invitee} className="invitee unknown">
            <span>{email}</span>{(inviteesDetails[invitee] || {}).optional && <span className="optional">&nbsp;{t('proposal.timeline.eventStatus.optional')}</span>}
          </div>)
        })}
      {remainingCount > 0 && !isPoll && (
        <div className="remaining">
          {t("proposal.timeline.unknown.remaining", { count: remainingCount })}
        </div>
      )}
    </div>
  );
}