import React from 'react';
import { useTranslation } from 'react-i18next';
import Availability from './Availability';

import './Calendars.scss';
export default function Calendars() {
  const { t } = useTranslation();
  return (
    <div className="settings-calendars">
      <Availability />
    </div>
  )
}