/* istanbul ignore file development tools */
/**
 * @fileoverview Dev settings hook for application
 * @module tools
 */
import { useState, useCallback } from 'react';
// import DoneDB from 'util/DoneDatabase';
/**
 * @property localstorage key for dev settings
 */
const DEV_SETTINGS_KEY = '__dev__'; // python way :)
/**
 * @property dev settings property names
 */
export const DEV_FLAGS = {
  CLEAR_TOKEN: 'clearToken',
  CONTACTS_USE_PEOPLE_CONNECTIONS_LIST: 'contactUsePeopleConnectionsList',
  HEATMAP_USE_ONLY_SLOTS: 'heatmapUseOnlySlots',
  JULY_FORM: "julyForm"
}
const DEFAULT_VALUES = {
  [DEV_FLAGS.CLEAR_TOKEN]: false,
  [DEV_FLAGS.CONTACTS_USE_PEOPLE_CONNECTIONS_LIST]: false,
  [DEV_FLAGS.HEATMAP_USE_ONLY_SLOTS]: true,
  [DEV_FLAGS.JULY_FORM]: false,
}

export const isDevSettingsEnabled = () => {
  try {
    return localStorage.hasOwnProperty(DEV_SETTINGS_KEY);
  } catch (e) { return false; }
};

// load dev Setting from localStorage
let DEV_SETTINGS;
try {
  DEV_SETTINGS = JSON.parse(localStorage.getItem(DEV_SETTINGS_KEY)) || DEFAULT_VALUES;
} catch (err) { DEV_SETTINGS = DEFAULT_VALUES; }

// a bunch of actions todo
const DEV_ACTIONS = {
  [DEV_FLAGS.CLEAR_TOKEN]: (value) => {
    //clear auth token for next time
    localStorage.setItem('OAUTH_CREDENTIALS', '')
  },
}



/**
 * @method dev settings custom hook
 * will take care of saving/updating localstorage with values
 * @return {any} dev settings accessors and setters
 * - DEV_FLAGS: code for settings
 * - devSettings: all the app settings (debug)
 * - setDevSettingItem (code, newValue): set value for a setting, will be created if needed
 * - getDevSettingItem (code): get a setting value by code, return undefined if not exists
 */
const useDevSettings = () => {
  let [ds, setDs] = useState(DEV_SETTINGS);

  // save devSettings to localstorage
  const setDevSetting = useCallback((name, value) => {
    ds[name] = value;
    DEV_SETTINGS = ds;
    localStorage.setItem(DEV_SETTINGS_KEY, JSON.stringify(ds));
    setDs(Object.assign({}, ds));
    if (DEV_ACTIONS[name]) return DEV_ACTIONS[name](value);
  }, [ds]);

  const getDevSetting = useCallback((name) => {
    if (ds[name]) return ds[name];
  }, [ds]);

  return {
    setDevSettingItem: setDevSetting,
    getDevSettingItem: getDevSetting,
    DEV_FLAGS,
    devSettings: ds
  };

}
export default useDevSettings;

export const getDevSettingItem = (key) => {
  return (DEV_SETTINGS || {})[key];
}