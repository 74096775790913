import moment from 'moment';
// import logger from '../logger';
import { getDiff } from './to.offset';
/**
 * Convert heatmap periods to events slots
 * @param {Array} periods periods selected in heatmap as [{start: moment, end: moment}]
 * @param {number} duration in minutes of the event
 *  @param {boolean} merge = false // allow to overlap 
 * @return generated slots as:[{day: moment, slots:[{start: moment, end: moment}]}]
 */
export function periodToSlots(periods = [], duration = 60, merge = false, padStartToQuarter = false, step = -1) {
  /* istanbul ignore if no work */
  if (duration < 1) {
    //logger.warn('Invalid duration:', duration);
    return [];
  }
  let slots = {};
  // get timeframes by days (deduplicated)
  for (let period of periods) {

    /* istanbul ignore if assertion */
    if (period.start >= period.end) continue; // invalid, would break all
    // get day
    let startPeriod = moment(period.start).clone();
    let day = startPeriod.clone().startOf('day');
    let endPeriod = moment(period.end).clone();


    // if more than 1 day
    let diff = getDiff(startPeriod, endPeriod); // diff in minutes for period ON SAME DAY
    let daysDiff = endPeriod.diff(day, 'minutes') / (24 * 60)
    if (daysDiff >= 1) {
      let s = startPeriod.clone();
      let e = startPeriod.clone().add(diff, 'minutes');
      // while remine times, add periods
      while (e <= endPeriod) {
        let cs = slots[s.format('YYYY-MM-DD')] || {
          day: s.clone().startOf('day'),
          timeframes: [],
          slots: [],
        };
        //// console.log('Period', s.toISOString(), e.toISOString())
        // add period to timeframes
        cs.timeframes = addPeriod(cs.timeframes, { start: s.clone(), end: e.clone() }, merge);
        slots[s.format('YYYY-MM-DD')] = cs;
        // next day
        startPeriod.add(1, 'day');
        // e.add(1,'day')
        s = startPeriod.clone();
        e = startPeriod.clone().add(diff, 'minutes');
      }
    } else {
      // not a multiday timeframe, do it simply
      let cs = slots[day.format('YYYY-MM-DD')] || {
        day,
        timeframes: [],
        slots: [],
      };
      // add period to timeframes
      cs.timeframes = addPeriod(cs.timeframes, { start: moment(period.start).clone(), end: moment(period.end).clone() }, merge);
      slots[day.format('YYYY-MM-DD')] = cs;
      // console.log(slots[day.format('YYYY-MM-DD')].timeframes)
    }
  }
  // create slots
  let r = [];
  let dates = Object.keys(slots).sort();
  for (let k of dates) {
    let slot = slots[k];
    const slowts = makeSlots(slot.timeframes, duration, step);
    if (slowts.length > 0) {
      r.push({
        day: slot.day,
        slots: slowts
      })
    }
  }
  return r;
}


function addPeriod(timeframes, period, merge = true) {
  // check all timeframes to see if overlapp
  let frames = [];
  let isPlaced = false;
  for (let t of timeframes) {
    // if period is placed, just push other frames in...
    if (isPlaced) frames.push(t);
    else {
      if ((period.end < t.start) || (!merge && (period.end.unix() === t.start.unix()))) {
        // if period is before timeframe, add period and timeframe
        frames.push({ start: period.start, end: period.end });
        frames.push(t); // add frame
        isPlaced = true; // place left frames in result
      } else if ((period.start > t.end) || (!merge && (period.start.unix() === t.end.unix()))) {
        // if period is after, add timeframe only
        frames.push(t); // add current timeframe
        // and continue with next frame

      } else {
        // period overlap
        // if start is before, change timeframe start
        if (merge) {
          period = {
            start: (period.start < t.start) ? period.start : t.start,
            end: (period.end > t.end) ? period.end : t.end,
          }
        } else {
          frames.push(t); // add frame
        }

        /*if(period.start < t.start){
          t.start = period.start;
        }
        // if period end after timeframe,more tricky
        if(period.end > t.end) t.end = period.end;
        
        frames.push(t);
        isPlaced=true;*/
      }
    }

  }
  // if last period
  if (!isPlaced) frames.push({ start: period.start, end: period.end });
  return frames;
}

function makeSlots(timeframes = [], duration, step = -1) {
  // make slots from timeframes
  let inter = step === -1 ? duration : step;
  inter = step >= duration ? 15 : inter;
  // au cas ou gros probleme...
  if (inter < 15) inter = 15
  let slots = [];
  for (let frame of timeframes) {
    let { start, end } = frame;
    // add slots here, from start to end
    let current = start.clone();
    let currentend = current.clone().add(duration, 'minutes');
    // probleme, for 23:59:59.999...
    while (before(currentend, end)) {
      slots.push({
        start: current.clone(),
        end: currentend.clone()
      });
      current = current.clone().add(inter, 'minutes')
      currentend = currentend.clone().add(inter, 'minutes')
    }
  }
  return slots;
}
// check if date is before, with a second allowed
function before(date, limit) {
  if (date > limit) {
    // check seconds
    return (date.valueOf() - limit.valueOf()) <= 1000; // in ms
  }
  return true;
}
// deduplicate busy slots: keep full days in between!
export function deduplicateBusySlots(periods) {
  let clean = [];
  for (let period of periods) {
    // console.log('Deduplication:', period)
    if (period.start >= period.end) continue; // invalid, would break all
    period.start = moment(period.start);
    period.end = moment(period.end);
    // get day
    let startPeriod = period.start.clone();
    let day = startPeriod.clone().startOf('day');
    let endPeriod = period.end.clone();


    // if more than 1 day
    // let diff = getDiff(startPeriod.clone(), endPeriod.clone()); // diff in minutes for period ON SAME DAY
    let daysDiff = endPeriod.diff(day, 'minutes') / (24 * 60)
    // console.log('Deduplication:', startPeriod, endPeriod,daysDiff);
    // if between 2 days, can have negative diff!!!
    if (daysDiff >= 1) {
      // divide in days
      // must recalculate diffs?
      let diff = endPeriod.diff(endPeriod.clone().startOf('day'), 'minutes'); // diff in minutes for period ON SAME DAY
      let s = startPeriod.clone();
      let e = startPeriod.clone().endOf('day');
      while (e <= endPeriod) {
        clean.push({
          ...period,
          start: s.clone(),
          end: e.clone(),
        })
        // console.log('Deduplication: add period', s, e,);
        startPeriod = e.clone().add(1, 'day').startOf('day');
        // e.add(1,'day')
        s = startPeriod.clone();
        e = startPeriod.clone().endOf('day'); //.add(diff, 'minutes');
      }
      // add what left
      // console.log('Deduplication: add last period', startPeriod.clone(), startPeriod.clone().add(diff, 'minutes'), diff);
      clean.push({
        ...period,
        start: startPeriod.clone(),
        end: startPeriod.clone().add(diff, 'minutes'),
      });
      // console.log('END-')
    } else {
      // just add
      clean.push(period);
      // console.log('Deduplication: same day');
    }
  }
  return clean;
}