export function compare(a = '', b = '') {
  let n = a.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  let m = b.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return n.toUpperCase().includes(m.toUpperCase());
}
export function compareFullStr(a = '', b = '') {
  let n = a.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  let m = b.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return n.toUpperCase() > m.toUpperCase() ? 1 : -1;
}
export function compareNoSpace(a = '', b = '') {
  let n = a.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
  // m is searched string
  let m = b.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
  // split search
  let searchs = m.split(' ');
  let search = searchs.map((str) => escapeRegExp(str)).filter((str) => !!str).join('.*\\s+');
  let rgx = new RegExp(search);
  return rgx.test(n);
}
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}