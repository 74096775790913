import moment from 'moment';
export function isAboutToExpire(proposal) {
  if (!proposal) return false;
  if (!proposal.iAmHost) return false;

  let now = moment();
  let createdAt = proposal.created_at ? moment(proposal.created_at) : now;
  let firstSlot = (proposal.slots || [])[0];
  firstSlot = firstSlot.slots || [];
  firstSlot = (firstSlot[0] || { start: now }).start || now;
  firstSlot = moment(firstSlot);

  return createdAt < moment().subtract(12, 'hour') || firstSlot < now;
}