import React from 'react';
import { Select } from '@material-ui/core';
import { DEFAULT_START_HOUR, DEFAULT_END_HOUR } from '../../../../utils/gen.timeframes';
import { Copy } from '../../../../assets/icons';
import { Add, DeleteOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { split } from '../../../../utils/split.timeframes';
import { IconButton } from '@material-ui/core';
import "./day.availability.scss";

const HOURS_OF_THE_DAY = Array.from({ length: 49 }, (_, hour) => hour / 2);

export default function DayAvailability({
  day,
  values = {},
  onChange,
  onCopyAll,
  name = "workingHours",
  Renderer = DefaultRenderer,
}) {

  let DAYS = moment.weekdays();
  DAYS.push(DAYS[0])
  let dayName = DAYS[day];

  const onSplit = React.useCallback(() => {
    if (values.frames.length === 0) {
      // generate default
      onChange({
        target: {
          name: `${name}.${day}`,
          value: { frames: [{ start: DEFAULT_START_HOUR, end: DEFAULT_END_HOUR }] },
        }
      })
    } else if (values.frames.length > 1) {
      // unsplit
      // take 1st start and last end
      onChange({
        target: {
          name: `${name}.${day}`,
          value: {
            frames: [{
              start: values.frames[0].start,
              end: values.frames[1].end
            }]
          },
        }
      })
    } else {
      // split time in 2?
      // Regenerate default datas
      let start = values.frames[0].start,
        end = values.frames[0].end;
      let splitted = split(start, end);
      onChange({
        target: {
          name: `${name}.${day}`,
          value: splitted,
        }
      })
    }
  }, [values, day, onChange])

  return <Renderer
    name={name}
    day={day}
    dayName={dayName}
    values={values}
    onChange={onChange}
    onSplit={onSplit}
    onCopyAll={onCopyAll}
  />
}

function DefaultRenderer({
  name,
  day,
  dayName,
  values,
  onChange,
  onSplit,
  onCopyAll
}) {
  const { t } = useTranslation();
  return <div className="done-input-day-availability">
    <div className="day-name">{dayName}</div>
    <div className="item done-input-time-item">
      {values.frames.map((frm, i) => {
        return <div key={'day-' + i}><DayFrame name={`${name}.${day}.frames[${i}]`} frame={frm} onChange={onChange}
          prec={(i > 0) ? values.frames[i - 1] : null} next={i < (values.frames.length - 1) ? values.frames[i + 1] : null} />
        </div>
      })}
      <div className="actions">
        <IconButton data-testid={'split-'} onClick={onSplit} title={t((values.frames.length > 1) ? 'settings.sections.availability.content.unsplit' : 'settings.sections.availability.content.split')}>
          {
            (values.frames.length > 1) ? <DeleteOutline /> : <Add />
          }
        </IconButton>
        <IconButton data-testid={'copy-'} onClick={() => onCopyAll(values)} className="copy-" title={t('settings.sections.availability.content.copyToAll')}>{
          <Copy />
        }</IconButton>
      </div>
    </div>
  </div>;
}

export function DayFrame({ name, frame, onChange, prec, next, selectVariant }) {
  const { t } = useTranslation();
  let { start, end } = frame;

  // get diff in hour with ust
  const min = (prec ? prec.end + 0.5 : 0) * 2;
  const max = (next ? next.start : 24.5) * 2;
  const changeStartHour = React.useCallback((e) => {
    // let t = moment.utc(0).add(e.target.value, 'hours');
    // check if must recalculate end hour?
    let v = +e.target.value;
    if (frame.end <= v) {
      onChange({
        target: {
          name: name + '.end',
          value: v + 0.5
        }
      });
    }
    onChange({
      target: {
        name: e.target.name,
        value: v,
      }
    });
  }, [onChange, frame, name])
  const changeHour = React.useCallback((e) => {
    onChange({
      target: {
        name: e.target.name,
        value: +e.target.value
      }
    })
  }, [onChange])

  return <div className="timeframe">
    <div className="hour-proposal">
      <span>{t('settings.sections.availability.content.availabilityFrom')}</span>
      <Select
        native
        value={start}
        variant={selectVariant}
        name={name + '.start'}
        onChange={changeStartHour}
      >
        {HOURS_OF_THE_DAY.slice(min, max - 1).map(hour => {
          let time = moment.utc(moment.duration(hour, 'h').asMilliseconds());
          return (
            <option key={'to' + hour} value={hour} >{time.format('LT')}</option>
          );
        })}
      </Select>
    </div>
    <div className="hour-proposal"><span>{t('settings.sections.availability.content.availabilityTo')}</span>
      <Select
        native
        name={name + '.end'}
        value={end}
        variant={selectVariant}
        onChange={changeHour}
      >
        {HOURS_OF_THE_DAY.slice((start * 2) + 1, max).map(hour => {
          let time = moment.utc(moment.duration(hour, 'h').asMilliseconds());
          return (
            <option key={'to' + hour} value={hour} >{time.format('LT')}</option>
          );
        })}
      </Select>
    </div>
  </div>
}

