import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import * as ACTIONS from '../actions';
import { DEFAULT_COLOR } from '../utils/transparentize';
const defaultState = [];

const reducer = handleActions(
  {
    [ACTIONS.WORKER_CONNECT_SUCCESS]: (state, action) => {
      return action.payload.accounts;
    },
    [ACTIONS.WORKER_UNREGISTER_ACCOUNT_SUCCESS]: (state, action) => {
      return action.payload.accounts;
    },
    [ACTIONS.WORKER_REFRESH_ACCOUNTS_SUCCESS]: (state, action) => {
      return action.payload.accounts;
    },
    [ACTIONS.WORKER_LOGOUT]: () => defaultState,
    [ACTIONS.INVALID_CREDENTIALS]: () => defaultState,
    [ACTIONS.WORKER_SAVE_ACCOUNT_SETTINGS_SUCCESS]: (state, action) => {
      const { settings, accountId } = action.payload;
      const account = state.find(acc => ('' + acc.provider.name + ":" + acc.id) === accountId);
      if (account) {
        account.settings = settings;
      }
      return [...state];
    },
  },
  defaultState
);

/**
 * Get all accounts
 */
export const getAccounts = (state) => state.accounts;

/**
 * return list of accounts
 * @TODO if used in multiple component, will need to add memoization/custom selection
 */
export const getAccount = createSelector(
  getAccounts,
  (state, props) => props.accountId,
  (accounts, id) => {
    return accounts.find((a) => a.id === id)
  });

/**
 * return a calendar for an account
 * @param {any} accid account id
 * @param {any} cid calendar id
 * @TODO
 */
export const getCalendar = createSelector(
  getAccount,
  (state, props) => props.calendarId,
  (account, cid) => {
    if (account && account.calendars) return account.calendars.find((c) => c.id === cid);
  }
);

/**
 * return a calendar for an account
 * @param {any} cid calendar id
 * @TODO see how to do this?
 */
export const getDefaultCalendar = (state) => {
  let accounts = state.accounts;
  if (!accounts || accounts.length === 0) return undefined;
  let lastCalendar = (state.profile || {}).lastCalendar;
  // Problem with return undefined: you MUST return a default calendar even
  // if lastCalendar do not pint on a valid one
  if (lastCalendar) {
    // last used calendar
    let acc = accounts.find((acc) => acc && acc.user_id === lastCalendar.user_id);
    if (acc) {
      let cal = (acc.calendars || []).filter((c) => c.id === lastCalendar.cal_id)[0];
      if (cal) {
        return formatCalendar(acc, cal);
      }

    }

  }
  // fallback to default account
  let acc = accounts.find((acc) => acc && acc.calendars && acc.calendars.length > 0);
  if (!acc) return undefined;
  let cal = (acc.calendars || []).filter((c) => c.primary)[0];
  if (!cal) {
    // take first not read only
    cal = (acc.calendars || []).find((c) => !c.readonly);
    if (!cal) return undefined
  }
  return formatCalendar(acc, cal);

};
/**
 * return list of accounts with enabled calendars
 * @TODO
 */
export const getAccountsWithCalendar = createSelector(
  getAccounts,
  (accounts) => {
    let accs = [];
    for (let acc of accounts) {
      if (!acc.revoked && acc.calendars && acc.calendars.length > 0) {
        // @TODO check if revoked?
        let cs = acc.calendars.filter((c) => !c.readonly);
        accs.push({
          ...acc,
          calendars: cs
        });
      }
    }
    return accs;
  });

// get number of calendars used fot FB
export const getFBCalendarCount = createSelector(
  getAccounts,
  (accounts) => {
    return (accounts || []).reduce((acc, account) => {
      if (account && !account.revoked && account.settings && account.settings.freebusy_calendars) return acc + account.settings.freebusy_calendars.length;
      return acc + 1;
    }, 0);
  }
);
export const getAccountAndCalendarInfos = (accountId, calendarId) => (state) => {
  let d;
  const accounts = (state.accounts || []);
  let account = accounts.find((a) => a.id === accountId);
  if (account && account.calendars) {
    let calendar = account.calendars.find((c) => c.id === calendarId);
    if (!calendar) return;
    // populate datas
    d = {
      account,
      calendar
    }
  }
  return d;
}

export const getAccountMails = createSelector(
  getAccounts,
  (accounts) => (accounts || []).map((acc) => acc.email)
)
export const getAccountsIds = createSelector(
  getAccounts,
  (accounts) => (accounts || []).map((acc) => ({ id: acc.provider.name + ':' + acc.id, email: acc.email }))
)


export const DEFAULT_CALENDAR_LF = {
  bgcolor: DEFAULT_COLOR
}
export const getEventCalendarLF = (event) => {
  return (state) => {
    if (!event) return DEFAULT_CALENDAR_LF;
    if (!event.calendar) return DEFAULT_CALENDAR_LF;
    const { accountId, calendarId } = event.calendar;
    const id = (accountId || ":").split(":")[1]
    const account = (state.accounts || []).find((a) => a.id === id);
    if (!account) return DEFAULT_CALENDAR_LF;
    const calendar = (account.calendars || []).find((c) => c.id === calendarId)
    if (!calendar) return DEFAULT_CALENDAR_LF;
    if (!calendar.bgcolor) calendar.bgcolor = DEFAULT_COLOR;
    return calendar;
  }


}
export const hasConnectedContactsSomeContacts = createSelector(
  getAccounts,
  (accounts) => !!(accounts || []).find((acc) => (acc.permissions || []).find((p) => p === 'contact'))
)

export const isOneOfUserAccounts = (accounts = [], c) => {
  if (!c || !c.accountId || !c.calendarId) return false;
  return !!accounts.find((acc) => {
    if (acc.id === c.accountId) return true;
    return false;
  })
}
export const getCalendarForEdit = (accounts = [], c) => {
  if (!c || !c.accountId || !c.calendarId) return false;
  // get in form calendar as
  // return { accountId: acc.id, calendarId: cal.id, provider: acc.provider.name, email: acc.email, name: acc.name, timezone: cal.timezone };
  let acc = accounts.find((acc) => {
    if (acc.id === c.accountId) return true;
    return false;
  });
  if (!acc || !acc.calendars) return getDefaultAccountCalendar(accounts);
  let cal = acc.calendars.find((cal) => cal.id === c.calendarId);
  if (!cal) return getDefaultAccountCalendar(accounts);
  return formatCalendar(acc, cal);
}
const getDefaultAccountCalendar = (accounts = []) => {
  let acc = accounts.find((a) => !a.revoked)
  if (!acc || !acc.calendars || acc.calendars.length === 0) return null;
  let cal = acc.calendars.find((cal) => cal.primary) || acc.calendars[0];
  return formatCalendar(acc, cal)
}
export const areAllAccountsRevoked = createSelector(
  getAccounts,
  (accounts) => (accounts || []).find((acc) => {
    return !acc.revoked;
  }) === undefined && (accounts || []).length > 0
)
export default reducer;

function formatCalendar(acc, cal) {
  return { accountId: acc.id, calendarId: cal.id, provider: acc.provider.name, email: acc.email, name: acc.name, timezone: cal.timezone, supported_conferences: cal.supported_conferences || [] }
}