import * as ACTIONS from '../actions';
import * as Sentry from '@sentry/browser';

export default store => next => action => {
  try {
    // HTTP errors are sent to Sentry
    // Codes: 400 + 5XX
    if (action
      && (action.type === ACTIONS.FETCH_ERROR_IN_WORKER
        && action.payload
        && (
          action.payload.httpStatus === 400
          || action.payload.httpStatus === 405
          || action.payload.httpStatus >= 500
        ))) {
      // payload: {httpStatus: status, httpResponse: value, fetchUrl: url, fetchOpts: opts},
      if (action.payload.httpStatus
        && action.payload.httpResponse !== undefined) {
        const errorString = action.payload.httpStatus + " - " + JSON.stringify(action.payload.httpResponse);
        if (action.payload.fetchUrl) console.debug("Fetch URL: " + action.payload.fetchUrl);
        try {
          if (action.payload.fetchOpts) console.debug("Fetch Options: " + JSON.stringify(action.payload.fetchOpts));
        } catch (e) { console.debug("Could not JSON stringify fetch options.", e) };
        console.debug("Fetch status: " + action.payload.httpStatus);
        console.debug("Fetch response: " + action.payload.httpResponse);
        Sentry.captureException(new Error(errorString));
      }
    }
  } catch (err) {
    Sentry.captureException(err);
  }
  return next(action);
}