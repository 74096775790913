import React from 'react';
import {
  Title,
  LocationOn,
  Notes,
  CalendarToday,
  Timer,
  KeyboardArrowRight,
  KeyboardArrowDownSharp
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ROUTES from '../../../../routes/routes.name';
import {
  useTranslation
} from 'react-i18next';
import {
  useSelector
} from 'react-redux';
import {
  getAccountAndCalendarInfos
} from '../../../../reducers/accounts';
import TextBlock from '../../../TextBlock';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowUp as ArrowUp
} from '@material-ui/icons';
import { durationFormatter } from '../../../../utils/duration.formatter';
import Conference from './conference';
import { strip } from '../../../../utils/strip.html';
import './details.scss';

const DETAILS = [
  {
    name: 'title',
    icon: <Title />,
    className: "strong"
  },
  {
    name: 'location', // no need for arrays
    icon: <LocationOn />,
    computeValue: (f) => <TextBlock txt={f.location} />
  },
  {
    name: 'conference', // no need for arrays
    icon: <></>,
    computeValue: (f) => f.location ? <></> : <Conference id={f.conference} location={f.location} error={f.__conference_not_generated}/>
  },
  {
    name: 'duration',
    icon: <Timer />,
    computeValue: (f, t) => {
      if (f.duration === -1) return durationFormatter(f.customduration, t)
      return durationFormatter(f.duration, t);
    }
  },
  {
    name: 'notes',
    icon: <Notes />,
    className: 'no-center',
    computeValue: /* istanbul ignore next not in v2 */(f, t, kickNotes) => {
      if (kickNotes) {
        // add expand btn?
        return <PreviewNotes notes={f.notes} />;
      }
      return <div className="notes-txt fancy-scroll"><TextBlock txt={f.notes} /></div>;
    }
  },
  {
    name: 'calendar',
    icon: <CalendarToday />,
    computeValue: /* istanbul ignore next not in v2 */(f) => {
      return (
        <div className="selected-calendar recap-calendar">
          <div className="content">
            <div className="title"><span className="capitalize">{f.calendar.provider}</span> <KeyboardArrowRight /> <span>{f.calendar.calendarName}</span></div>
            <div className="email">{f.calendar.accountEmail}</div>
          </div>
        </div>
      );
    }
  }
];

const TO = {
  pathname: ROUTES.CREATE_A_MEETING,
  myProps: {
    from: ROUTES.CREATE_A_MEETING_SEND_INVITE // ??? that'as a test
  }
}
/**
 * Display form information for creating event
 * on recap page
 * 
 * @param {Object} formDatas datas to display
 */
export default function Details({
  formDatas = {},
  isEditable = true,
  kickNotes = false, // display only first line of notes + button expand?
}) {

  // should get some informations first
  // calendar/account
  const account = useAccountAndCalendar(formDatas.calendar)
  const { t } = useTranslation();
  // user infos?
  const details = React.useMemo(() => {
    if (!formDatas || !account) return { ...formDatas, calendar: undefined };
    // populate datas
    return {
      ...formDatas,
      calendar: undefined,
      notes: undefined,
      // location: undefined,

    }
  }, [formDatas, account]);
  if (!details) return null;
  return (
    <div className="recap-details-infos">
      {isEditable && <Link to={TO} className="edit-btn"><Button>{t('createEvent.form.edit')}</Button></Link>}
      <EventDetails details={details} kickNotes={kickNotes} />
    </div>
  )
}
// memoized useSelector
function useAccountAndCalendar(calendar = {}) {
  return useSelector(getAccountAndCalendarInfos(calendar.accountId,
    calendar.calendarId));
}


export function SimpleDetailsView({ details, kickNotes = false }) {
  // remove some datas
  let tmp = React.useMemo(() => {
    return {
      ...details,
      calendar: undefined, // no calendar display
      reminders: undefined, // no reminders
    }
  }, [details])
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <div
      className={"recap-details-infos collapsable " + (isExpanded ? "expanded" : "collapsed")}
      onClick={() => setIsExpanded(!isExpanded)}>
      <EventDetails details={tmp} kickNotes={kickNotes} />
    </div>
  )
}

export function EventDetails({ details, kickNotes = false }) {
  const { t } = useTranslation();
  return DETAILS.map((d, i) => {
    let { name, icon, className = '', computeValue = (f) => details[name] } = d;
    let isSet = false;
    if (Array.isArray(name)) {
      for (let nm of name) {
        if (details[nm]) {
          isSet = true;
          break;
        }
      }
    } else isSet = details[name]
    if (isSet) {
      return (
        <div className={"recap-item " + className} key={'item-' + i}>
          {icon}
          <div className="recap-item-content">{computeValue(details, t, kickNotes)}</div>
          <KeyboardArrowDownSharp className="arrow-toggle" />
        </div>
      );
    }
    return null; // no datas to display
  });

}
/* istanbul ignore next not in v2 */
function PreviewNotes({ notes = '' }) {
  const [anchorEl, setAnchorEl] = React.useState(false);

  const cleanedNotes = React.useMemo(() => {
    return strip(notes)
  }, [notes])
  const toggleText = React.useCallback(() => {
    if (anchorEl) setAnchorEl(false);
    else setAnchorEl(true);
  }, [anchorEl]);

  const open = Boolean(anchorEl);
  return (
    <div className="notes-txt-container">
      <div className={"notes-txt preview " + (open ? 'fullsize fancy-scroll' : '')}
      >{cleanedNotes}</div>
      <IconButton size="small" onClick={toggleText}>{
        open ? <ArrowUp /> : <ArrowDown />
      }</IconButton>
    </div>
  )
}