import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as LinkIcon
} from '@material-ui/icons';
import { SharedAvailability, PublicAvailability } from '../../profile/accounts';
// import ShareWithGuest from './shareWithGuest';
export default function Privacy() {
  const { t } = useTranslation();
  return (
    <>
      <div className="section item">
        <SharedAvailability asDialog={false} />
      </div>
      <div className="section item">
        <PublicAvailability asDialog={false} />
      </div>
      {/* <div className="section item">
        <ShareWithGuest />
      </div> */}
      <div className="section item link">
        <a href={t('settings.sections.privacy.content.sectionTermsAndConditionsURL')} target="_blank" rel="noopener noreferrer">
          {t('settings.sections.privacy.content.sectionTermsAndConditionsTitle')}<LinkIcon />
        </a>
      </div>
      <div className="section item link">
        <a href={t('settings.sections.privacy.content.sectionPrivacyPolicyURL')} target="_blank" rel="noopener noreferrer">
          <span>{t('settings.sections.privacy.content.sectionPrivacyPolicyTitle')}</span><LinkIcon />
        </a>
      </div>
    </>
  )
}