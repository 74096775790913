const routes = {
  CREATE_A_MEETING: '/create-a-meeting',
  SIGN_IN: '/sign-in',
  AUTH: '/auth',
  APP: '/dashboard',
  CREATE_A_MEETING_ADD_GUESTS: '/create-a-meeting/add-guests',
  CREATE_A_MEETING_SEND_INVITE: '/create-a-meeting/send-invite',
  CREATE_A_MEETING_LINK: '/create-a-meeting/link',
  CREATE_A_LINK: "/booking-link",
  CREATE_A_LINK_RESULT: '/booking-link/link',
  APP_SCHEDULER: '/dashboard/scheduler',
  APP_SETTINGS: '/dashboard/settings',
  EVENT: '/event/',
  GUESTS: '/users/:email/event-proposals/:id',
  APP_SETTINGS_PRIVACY: '/dashboard/settings/privacy',
  APP_SETTINGS_ACCOUNTS: '/dashboard/settings/accounts',
  APP_SETTINGS_CALENDARS: '/dashboard/settings/calendars',
  APP_SETTINGS_INVITE: '/dashboard/settings/invite',
  APP_SETTINGS_BILLING: '/dashboard/settings/billing',
  APP_SETTINGS_WH: '/dashboard/settings/working-hours',
  APP_SETTINGS_TOOLS: '/dashboard/settings/tools',
  APP_SETTINGS_DEV: '/dashboard/settings/dev',
  APP_SETTINGS_ADVANCED: '/dashboard/settings/advanced',
  APP_SETTINGS_PERSO: '/dashboard/settings/pro',
  APP_PROFILE: '/dashboard/profile',
}
// deep linkable pages
export const DEEP_LINKS = {
  [routes.CREATE_A_MEETING]: 1,
  [routes.SIGN_IN]: 1
}
export default routes;
export const PAGE_VIEW_ROUTES = [
  routes.CREATE_A_MEETING, routes.APP, routes.CREATE_A_LINK
]