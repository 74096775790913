import logger from '../../logger';
import * as ACTIONS from '../../actions';
import { getStats } from '../../api/stats';
import moment from 'moment'
export default (event, cllbck) => {
  let { payload, workerID } = event;

  return getStats(payload)
    .then((acc) => {
      // do we have a usage, if so precalculate everthing
      if (acc && acc.usage) {
        // acc.usage = preProcessUsage(acc.usage)
        let [usage, step] = preProcessUsage(acc.usage);
        acc.usage = usage;
        acc.step = step;
      }
      cllbck({
        type: ACTIONS.WORKER_GET_STATS_SUCCESS,
        payload: acc,
        workerID: workerID
      })
    }).catch((err) => {
      let status = err ? err.status || 500 : 500;
      cllbck({
        type: ACTIONS.WORKER_GET_STATS_ERROR,
        payload: {
          status: status
        },
        workerID: workerID
      })
    })

}
// Preprocess usage for rendering in graph of form
// {
//   name: 'month name', or week number or day/date
//   value: 590, // nbr for this month or week number or day/date
//   cumul: 800, // cumul total for 6 months or week number or day/date
// },
export function preProcessUsage(usage) {
  if (!usage || usage.length === 0) return [];
  try {
    // ensure sort by date, or it will crash the algo
    usage = usage.sort((a, b) => a.date < b.date ? -1 : 1)
    // determine step from data
    let step = detStep(usage);
    // precalculate
    let preprocessed = precalculate(usage, step);
    // fill the gaps
    preprocessed = fillGaps(preprocessed, step);
    // add step information
    return [preprocessed, step];
  } catch (err) {
    console.log(err);
    throw err;
  }
}
export const MONTHS = 4 * 30 * 24 * 60 * 60 * 1000; // 4 months, show month view
export const WEEKS = 30 * 24 * 60 * 60 * 1000; // 1 months, show week view
export const DAYS = 1;

const MIN_USAGE_LENGTH = 7;

function detStep(usage) {
  let first = moment(usage[0].date, 'YYYY-MM-DD');
  let now = moment();
  let diff = now.valueOf() - first.valueOf();
  if (diff >= MONTHS) return MONTHS;
  else if (diff >= WEEKS) return WEEKS;
  else return DAYS;
}
function precalculate(usage, step = DAYS) {
  switch (step) {
    case MONTHS: return __precalculateMonth(usage);
    case WEEKS: return __precalculateWeek(usage);
    default: return __precalculateDay(usage);
  }
}
export function fillGaps(usage, step = DAYS) {
  switch (step) {
    case MONTHS: return doFillGap(usage, MONTHS_GAP);
    case WEEKS: return doFillGap(usage, WEEKS_GAP);
    default: return doFillGap(usage, DAYS_GAP);
  }
}
function __precalculateMonth(usage) {
  let first = usage[0];
  let current = {
    date: first.date,
    name: first.month,
    week: first.week,
    accu: 0,
    total: 0,
    month: first.month
  };
  let monthAccu = 0;
  let months = [];
  for (let us of usage) {
    if (us.month === current.month) {
      // add to current
      current.total += us.total;
      current.accu += us.total;
    } else {
      months.push(current);
      current = {
        date: us.date,
        week: us.month,
        name: us.month,
        accu: monthAccu + us.total,
        total: us.total,
        month: us.month
      };
    }
    monthAccu += us.total;
  }
  // add last one
  months.push(current);
  return months;
}
function __precalculateWeek(usage) {
  let first = usage[0];
  let current = {
    date: first.date,
    name: first.week,
    week: first.week,
    accu: 0,
    total: 0,
  };
  let weekAccu = 0;
  let weeks = [];
  for (let us of usage) {
    if (us.week === current.week) {
      // add to current
      current.total += us.total;
      current.accu += us.total;
    } else {
      weeks.push(current);
      current = {
        date: us.date,
        week: us.week,
        name: us.week,
        accu: weekAccu + us.total,
        total: us.total,
      };
    }
    weekAccu += us.total;
  }
  // add last one
  weeks.push(current);
  return weeks;
}
function __precalculateDay(usage) {
  // just get accumulator for each day
  let current = 0;
  for (let us of usage) {
    current += us.total;
    let date = moment(us.date, 'YYYY-MM-DD')
    us.name = DAYS_GAP.formatName(date);
    us.accu = current;
  }
  return usage;
}


export const DAYS_GAP = {
  unit: 'day',
  formatName: (date) => {
    const lang = (navigator.languages || ["en-US"])[0];
    let frm = moment(date).locale(lang).format("L").split('/'); // use locale based formatting
    return `${frm[0]}-${frm[1]}`; // return only part needed
  },
}
export const WEEKS_GAP = {
  unit: 'week',
  formatName: (date) => date.format("Wo")
}
export const MONTHS_GAP = {
  unit: 'month',
  formatName: (date) => date.format("MMM")
}
export function doFillGap(usages, step) {

  // if last day is not today, add today
  let now = moment();
  let last = usages[usages.length - 1];
  let last_date = moment(last.date, 'YYYY-MM-DD');
  if (!now.isSame(last_date, step.unit)) {
    usages.push({
      date: now.format('YYYY-MM-DD'),
      name: step.formatName(now),
      total: 0,
      accu: last.accu,
    })
  }

  let first = usages[0];
  let prev = moment(first.date, 'YYYY-MM-DD');
  first.name = step.formatName(prev)
  let tmp = [first]; // result set


  for (let i = 1; i < usages.length; i++) {
    let c = usages[i];
    let date = moment(c.date, 'YYYY-MM-DD');
    if (!date.isSame(prev, step.unit)) {
      // go to next unit
      let day = prev.clone();
      let prevUnit = date.clone().subtract(1, step.unit + 's')

      while (!prevUnit.isSame(day, step.unit)) {
        day.add(1, step.unit + 's')
        // fill some gaps
        let t = {
          date: day.format("YYYY-MM-DD"),
          name: step.formatName(day),
          total: 0,
          accu: usages[i - 1].accu,
        }
        tmp.push(t);

      }
    }
    // add item
    c.name = step.formatName(date)
    tmp.push(c);
    prev = date;
  }

  // Hack, if less than 3 elements in usage, add some dumb 0 before (max 6)
  if (tmp.length < MIN_USAGE_LENGTH) {
    const missing = MIN_USAGE_LENGTH - tmp.length;
    const first_date = moment(first.date, 'YYYY-MM-DD');
    for (let i = 0; i < missing; i++) {
      first_date.subtract(1, step.unit)
      tmp.unshift({
        date: first_date.format("YYYY-MM-DD"),
        name: step.formatName(first_date),
        total: 0,
        accu: 0,
      })
    }
  }
  return tmp;
}