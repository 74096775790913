import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Trans, useTranslation } from 'react-i18next';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import getTZ from '../../../../utils/timezones.list';
import './timezone.picker.scss';
import { Switch } from '../../../LMSwitch';
import moment from 'moment'
const ZONES = getTZ();
export default function TZPicker({
  value,
  auto = true,
  canBeAuto = true,
  onChange,
  className = "keep-space",
  inputPropsParams = {},
  label,
}) {
  const { t } = useTranslation();
  const v = React.useMemo(() => {
    if (auto) value = moment.tz.guess();
    // found selected value
    let v = ZONES.find((z) => z.value === value)
    return v
  }, [value, auto]);

  const autoChanged = React.useCallback((e) => {
    // is checked?
    // let v = e.target.checked ? "yes" : "no";
    onChange({
      target: {
        name: "autoTZ",
        value: e.target.checked
      }
    });
  });
  return (<div className="tz-autocomplete">
    <div className="tz-label">{label || t('agenda.timezones.placeholder')}</div>
    {canBeAuto && <FormGroup aria-label="position" row>
      <FormControlLabel
        control={<Switch data-testid='auto-tz-check' color="primary" checked={auto} onChange={autoChanged} />}
        label={<Trans i18nKey='agenda.timezones.auto'>Automatic timezone</Trans>}
        labelPlacement="end"
      />
    </FormGroup>}
    <Autocomplete
      value={v}
      onChange={(event, newValue) => {
        onChange({
          target: {
            name: "timezone",
            value: newValue.value
          }
        })
      }}
      ListboxProps={{ className: 'MuiAutocomplete-listbox lm-tz-picker-listbox' }}
      disabled={canBeAuto && auto}
      autoHighlight
      // fullWidth
      className={`tz-autocomplete-input ${className}`}
      disableClearable
      id="grouped-tz"
      options={ZONES.sort((a, b) => -b.tz.localeCompare(a.tz))}
      groupBy={(option) => option.tz}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(opt) => opt.name === (v || {}).name}
      renderInput={(params) => <TextField {...params} {...inputPropsParams} />}
    />
  </div>
  );
}