import React from 'react';
import { useTranslation } from 'react-i18next';
import { durationFormatter } from '../../../../utils/duration.formatter';
import { conferenceUrlCompat } from '../../../../utils/compatibility.conference';
import TextBlock from '../../../TextBlock';
import Conference from '../../create-event/recap/conference';
import './ProposalInfo.scss';

export default function ProposalInfo({ proposal }) {

  const { t } = useTranslation();

  if (!proposal || !proposal.organizer || !proposal.id) return null;

  let organizerName = proposal.onBehalfOf
    ? `${proposal.onBehalfOfName || proposal.onBehalfOf}`
    : proposal.organizer.name || proposal.organizer.email;

  const duration = proposal.duration === -1 ? durationFormatter(proposal.customDuration, t) : durationFormatter(proposal.duration, t)
  const { conference_url, location_txt } = conferenceUrlCompat(proposal)
  const conference = proposal.conference ? <Conference id={proposal.conference} conference_url={conference_url} error={proposal.__conference_not_generated} />
    : null;
  const location = location_txt ? <TextBlock txt={location_txt} /> : null
  return (<>
    {proposal.company && proposal.company.logo_url && <img className="company_logo" src={proposal.company.logo_url} />}
    <div className="ProposalInfo" data-testid="proposal-info">
      <div className="left-deco" />
      <div className="from">{t('event.from', { name: organizerName })}</div>
      <div className="title">{proposal.title}</div>
      <div className="subinfo">
        <span className="duration">{duration}</span>
        {conference && <span className="location">{conference}</span>}
        {location && <span className="location location-text">{location}</span>}
      </div>
      {proposal.notes && <div className="notes fancy-scroll"><TextBlock txt={proposal.notes} /></div>}
    </div ></>
  );
}