import { KeyboardArrowRight } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DoneButton from '../../DoneButton';

export default function FreeUserExpired({ profile, showBillingPopup }) {

  const { t } = useTranslation();

  if (profile.billing && (profile.billing.customer_id || profile.monthly_quota_remaining > 0)) return null;

  return (
    <div className="form-footer free-user-expire">
      <div className="content">
        <div className="title">{t('createEvent.form.freeUserExpireTitle')}</div>
        <div className="message">{t('createEvent.form.freeUserExpireMessage')}</div>
        <DoneButton
          className="premium end-endornment"
          label={t('settings.sections.billing.free.card.subscribe')}
          name="form-subscribe"
          onClick={showBillingPopup}
          iconAfter={<KeyboardArrowRight />} />
      </div>
    </div>
  );
}