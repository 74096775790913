import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import NOOP from '../../../../utils/noop';
import PromoCard from './promo.card';
import {
  Lock
} from '@material-ui/icons';
import "./free.user.scss";


export default function FreeUser({
  profile, // user profile with monthly_quota, ....
  doSubscribe = NOOP, // click handler, redirect to stripe sub form
  loadingCustomerSession = false, // loader info
}) {
  const { t } = useTranslation();
  if (!profile) return null;
  return <div className="billing-free-user" data-testid="free-user">
    <div className="title">{t('settings.sections.billing.free.title')}</div>
    <div className="subtitle">{profile.monthly_quota_remaining ? t("settings.sections.billing.free.subtitle", { count: profile.monthly_quota_remaining || 0 }) : t("settings.sections.billing.free.subtitleZero")}</div>
    <PromoCard doSubscribe={doSubscribe} loadingCustomerSession={loadingCustomerSession} />
    <div className="stripe-infos">
      <div className="stripe-title">
        <Lock /><div className="msg"><Trans i18nKey='settings.sections.billing.free.stripe.message'> text <span className="strong">text</span> text <span className="powered">text</span></Trans></div>
      </div>
      <div className="stripe-support"></div>
    </div>
  </div>;
}
