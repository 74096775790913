import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ProposalError from "../proposal/connected/proposal.error";
import ProposalNotFound from "../proposal/connected/proposal.not.found";
import * as ACTIONS from "../../../actions";
import { getUser } from "../../../reducers/user";
import { getAccountMails, getAccountsIds } from "../../../reducers/accounts";
import {
  Clear,
  Link,
  Event,
  Refresh,
  KeyboardArrowLeft as ArrowLeft,
  KeyboardArrowRight as ArrowRight,
  NotificationsActiveOutlined,
  EditOutlined,
} from '@material-ui/icons';
import {
  CoffeeIcon as Coffee
} from '../../../assets/icons'
import { LMTooltip } from '../../tooltip/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneButton from '../../DoneButton'
import { STATUS } from '../../../api/validators/proposal';
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from '@material-ui/core/IconButton';
import Funnel, { isInPastOrDirect } from "../proposal/connected/funnel";
import { Timeline } from '../proposal/connected/timeline'
import TextBlock from '../../TextBlock';
import Conference from '../create-event/recap/conference';
import { durationFormatter } from '../../../utils/duration.formatter';
import NOOP from '../../../utils/noop'
import { getProposalTab, isArchived, isMis, isOngoing } from '../../../utils/proposals.sort'
import { computeMenus, importantNotifications } from '../../../utils/compute.menus';
import { conferenceUrlCompat } from '../../../utils/compatibility.conference';
import {
  hasAnswer
} from '../../../utils/all.mandatory.answered'
import {
  isUnknownDomain
} from '../../../api/validators/proposal'

import { notYetAnswered } from '../../../utils/all.mandatory.answered';
import './proposal.details.scss';

// load proposal from id
export default function ProposalFromID({ id, proposals, setSelected, closeDrawer,
  onCreate, onEdit, onLink, onRemind,
  onCancel, onReschedule, onVote, onShowReminder }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [exists, setExists] = React.useState(true); // be optimist!
  const [error, setError] = React.useState(false); // be optimist!
  const [loading, setLoading] = React.useState(false);
  const user = useSelector(getUser);
  const accountsEmails = useSelector(getAccountMails);
  const accountsIds = useSelector(getAccountsIds);
  const proposal = useSelector((state) => state.proposals[id]);
  const lastUpdatedAt = React.useRef(null)
  React.useEffect(() => {

    // dummy check for id and user
    /* istanbul ignore if */
    if (!user || !user.isAuth) return;
    /* istanbul ignore if */
    if (accountsEmails.length === 0 || accountsIds.length === 0) return;
    if (!id) {
      setExists(false);
      return;
    }
    /* istanbul ignore if */
    if (!proposal || !proposal.organizer) return;
    if (lastUpdatedAt.current == proposal.updated_at) return;
    lastUpdatedAt.current = proposal.updated_at;
    setLoading(true);
    // check if got a sig AND a mail, if not => error
    let cbtEmail;
    if (proposal.iAmHost) cbtEmail = proposal.organizer.email || user.email;

    dispatch({
      type: ACTIONS.WORKER_GET_PROPOSAL,
      payload: {
        id: id,
        checkBusyTime: cbtEmail, // proposal.organizer.email || user.email,
        userEmail: accountsEmails,
        userAccounts: accountsIds,
        addProposalIDToBusyRequest: id,
        bestSuggestions: true
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
        // requestId: uuid.v4(),    //  ----> @TODO cancel proposals on replay (after merge heatmap)
      }
    })
      .then(res => {
        dispatch({
          type: ACTIONS.ANALYTICS_VIEW_PROPOSAL,
          payload: {
            proposal: res.payload && res.payload[0]
          }
        });
        return res;
      })
      .then(res => {
        if (!res.payload || !res.payload[0]) {
          setExists(false);
        }
      })
      .catch(err => {
        console.log('err:', err)
        if (err && err.status === 404) {
          setExists(false);
        } else {
          // display error
          console.log("Error loading event datas:", err);
          setError(true);
        }
      })
      .then(() => setLoading(false));
  }, [
    id,
    user.isAuth,
    user.email,
    accountsEmails,
    accountsIds,
    dispatch,
    user,
    lastUpdatedAt,
    proposal,
  ]);
  return error ? (
    <ProposalError />
  ) : !exists ? (
    <ProposalNotFound />
  ) : (
    <ProposalDetails
      loading={loading}
      proposal={proposal}
      proposals={proposals}
      setSelected={setSelected}
      onClose={closeDrawer}
      onEdit={onEdit} onLink={onLink} onRemind={onRemind}
      onCreate={onCreate} onCancel={onCancel} onReschedule={onReschedule} onVote={onVote}
      onShowReminder={onShowReminder}
    />
  );
};
export function ProposalDetails({ proposal, loading, proposals, setSelected,
  onClose,
  onCreate, onEdit, onLink, onRemind,
  onCancel, onReschedule, onVote,
  onShowReminder }) {
  /* istanbul ignore if no work */
  if (!proposal) return null;
  return <div data-testid={"proposal-details-panel"} className="proposal-details-panel">
    <ProposalContent proposal={proposal} proposals={proposals} setSelected={setSelected} loading={loading} onVote={onVote} />
    <ProposalMenu proposal={proposal} loading={loading}
      onEdit={onEdit} onLink={onLink} onRemind={onRemind}
      onCreate={onCreate} onCancel={onCancel} onReschedule={onReschedule} onVote={onVote}
      onShowReminder={onShowReminder} />
    <IconButton onClick={onClose}><Clear /></IconButton>
  </div>
}

function ProposalContent({ proposal, loading = false, onVote, proposals, setSelected }) {
  if (!proposal) return null;
  return <div className="full-height-details-container"><div className="proposal-details-container">
    <ProposalHeader proposal={proposal} />
    <div className="details-content fancy-scroll">
      {loading && <div className="updating-content"><CircularProgress /></div>}
      <ProposalData proposal={proposal} onVote={onVote}></ProposalData>
    </div>
  </div>
    <ProposalNavigation proposal={proposal} proposals={proposals} setSelected={setSelected} />
  </div>
}
function ProposalHeader({ proposal }) {
  const { t } = useTranslation();
  if (!proposal) return null;
  const duration = proposal.duration === -1 ? durationFormatter(proposal.customduration, t) : durationFormatter(proposal.duration, t)
  const { conference_url, location_txt } = conferenceUrlCompat(proposal)
  const conference = proposal.conference ? <Conference id={proposal.conference} conference_url={conference_url} error={proposal.__conference_not_generated} />
    : null;
  const location = location_txt ? <TextBlock txt={location_txt} /> : null
  const firstGuest = Object.values(proposal.inviteesDetails || {})[0] || {};
  const firstGuestNameAndOrEmail = firstGuest.name ? `${firstGuest.name} (${firstGuest.email})` : firstGuest.email;

  return <div className="proposal-details-header-container">
    <div className="general">
      <div className={"type type-" + proposal.type}>{proposal.booking_link ? proposal.booking_link.title : proposal.type}{proposal.start && <><ArrowRight />{t('proposal.meetingScheduled')}</>}</div>
      <div className="created-at">{t('proposal.v2.createdAt', { when: moment(proposal.created_at).format(t('common.fullDateFormat')) })}</div>
    </div>
    <span className="proposal-title">{proposal.title}</span>
    <div className="proposal-time-and-location">
      <span className="proposal-duration">{duration}</span>
      {conference && <span className="location">{conference}</span>}
      {location}
    </div>
    <span className='proposal-participant'>{t('common.organizer') + ': ' + proposal.organizer.name + ' (' + proposal.organizer.email + ')'}</span>
    {proposal.booking_link && firstGuestNameAndOrEmail && <span className='proposal-participant'>{t('common.guest') + ': ' + firstGuestNameAndOrEmail}</span>}
    {proposal.notes && <span className="proposal-notes with-icon"><TextBlock txt={proposal.notes} /></span>}
    {proposal.booking_link && firstGuest.note && <span className="proposal-notes with-icon">{t('proposal.details.schedulingLink.guestNotes', { guest: firstGuestNameAndOrEmail })}<TextBlock txt={proposal.notes} /></span>}
  </div>
}
function ProposalData({ proposal, onVote }) {
  const { t } = useTranslation();
  const doVote = React.useCallback(() => {
    onVote(proposal)
  }, [proposal, onVote]);
  if (!proposal) return null;
  const ispod = !proposal.iAmHost || isInPastOrDirect(proposal)

  return <div className="proposal-details-data-container">
    {!ispod && <>
      <h1>{t('proposal.details.sections.matrix')}</h1>
      <div className='section-subtext'>{t('proposal.details.sections.matrixSubText')}</div>
      <Funnel proposal={proposal} />
    </>}
    <h1>{t('proposal.details.sections.timeline')}</h1>
    <Timeline proposal={proposal} showSlots={doVote} />
  </div>
}
function ProposalMenu({ proposal, loading,
  onCreate, onEdit, onLink, onRemind,
  onCancel, onReschedule, onVote,
  onShowReminder }) {
  const { t } = useTranslation();
  const doAction = React.useCallback((action, ...args) => {
    if (action) {
      action(proposal, ...args)
        .then(() => {

        }).catch((err) => {

        })
    }
  }, [proposal])
  /* istanbul ignore if no work */
  if (!proposal) return null;
  const isPoll = proposal.type === "poll";
  const isProposalComplete = proposal.status === STATUS.CONFIRMED;
  const menus = computeMenus(proposal);
  let actionsCmp = null;
  const actions = importantNotifications(proposal);
  if (actions) {
    if (actions.poll_complete) {
      actionsCmp = (<div className="action-important">
        <div className="message">
          <span className="alert"><NotificationsActiveOutlined /></span>
          <span>{t('proposal.details.actions.forcePoll_important')}</span>
        </div>
        <DoneButton disabled={loading} label={t('proposal.details.actions.forcePoll')}
          onClick={() => doAction(onCreate)} icon={<Event />} />
      </div>)
    } else if (actions.expiring) {
      const hasOneEmailWhoNotAnswerYet = Object.values(proposal.inviteesDetails || {}).find((inv) => {
        // find one that is not unknown
        return !hasAnswer(inv) && !isUnknownDomain(inv.email)
      })
      actionsCmp = (<div className="action-important">
        <div className="message">
          <span className="alert"><NotificationsActiveOutlined /></span>
          <span>{t(hasOneEmailWhoNotAnswerYet ? 'proposal.details.actions.remind_important' : 'proposal.details.actions.remind_important_link')}</span>
        </div>
        {hasOneEmailWhoNotAnswerYet && <><a href="#" className="grey" disabled={loading}
          onClick={() => doAction(onShowReminder)}>{t('proposal.details.actions.showReminder')}</a>
          <DoneButton disabled={loading} label={t('proposal.details.actions.remind')}
            onClick={() => doAction(onRemind)} icon={<NotificationsActiveOutlined />} /></>}
        {!hasOneEmailWhoNotAnswerYet && <DoneButton disabled={loading} label={t('proposal.details.actions.link')}
          onClick={() => doAction(onLink)} icon={<Link />} />}
      </div>)
    } else if (actions.wainting_vote) {
      let subtitle = t('proposal.details.actions.vote_important');
      // compute title from proposal state
      let details = Object.values(proposal.inviteesDetails)
        .find((d) => d.email === proposal.me);
      // decorate
      if (details && !proposal.iAmHost) {
        const lastToVote = notYetAnswered(proposal).length === 1;
        const hasconflicts = details.conflicting_slots && details.conflicting_slots.length > 0;
        // I am last to vote?
        // Do I have conflicting slots?
        const organizer = proposal.organizer || {};
        subtitle = `proposal.slots.titleNotHost${isPoll ? 'Poll' : 'Funnel'}${hasconflicts ? 'Conflicts' : 'Free'}${lastToVote ? 'Last2Vote' : 'Vote'}`; // default one
        subtitle = t(subtitle, { host: organizer.label || organizer.email });
      }


      actionsCmp = (<div className="action-important">
        <div className="message">
          <span className="alert"><NotificationsActiveOutlined /></span>
          <span>{subtitle}</span>
        </div>
        <DoneButton disabled={loading} label={t('proposal.details.actions.vote')}
          onClick={() => doAction(onVote)} icon={<Event />} />
      </div>)
    }
  }
  const host = proposal.organizer || {};
  const hostName = host.label || host.name || host.email;
  return <div className="proposal-details-actions">
    {menus && <List component="nav" aria-label="proposal actions">
      {menus.create && (
        <LMTooltip
          key="force"
          content={<p>{t("proposal.details.actions.force_help")}</p>}
        >
          <ListItem name="btn-force" disabled={loading}
            data-testid="create-mnu"
            onClick={() => doAction(onCreate)} button>
            <ListItemIcon>
              <Event />
            </ListItemIcon>
            <ListItemText primary={t(isPoll ? "proposal.details.actions.forcePoll" : "proposal.details.actions.force")} />
          </ListItem>
        </LMTooltip>
      )}
      {menus.link &&
        <LMTooltip content={<p>{t("proposal.details.actions.link_help")}</p>}>
          <ListItem name="btn-link" disabled={loading}
            data-testid="link-mnu"
            onClick={() => doAction(onLink)} button>
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            <ListItemText primary={t("proposal.details.actions.link")} />
          </ListItem>
        </LMTooltip>}
      {menus.remind && <LMTooltip
        key="remind"
        content={<p>{t("proposal.details.actions.remind_help")}</p>}>
        <ListItem name="btn-cancel" disabled={loading}
          data-testid="remind-mnu"
          onClick={() => doAction(onRemind)} button>
          <ListItemIcon>
            <NotificationsActiveOutlined />
          </ListItemIcon>
          <ListItemText primary={t("proposal.details.actions.remind")} />
        </ListItem>
      </LMTooltip>}
      {menus.edit && (
        <LMTooltip
          key="edit"
          content={<p>{t("proposal.details.actions.edit_help")}</p>}
        >
          <ListItem name="btn-edit" disabled={loading}
            data-testid="edit-mnu"
            onClick={() => doAction(onEdit)} button>
            <ListItemIcon>
              <EditOutlined />
            </ListItemIcon>
            <ListItemText primary={t("proposal.details.actions.edit")} />
          </ListItem>
        </LMTooltip>
      )}
      {menus.cancel && <LMTooltip
        key="cancel"
        content={<p>{t(isProposalComplete ? "proposal.details.actions.cancel_help_confirmed" : "proposal.details.actions.cancel_help")}</p>}>
        <ListItem name="btn-cancel" disabled={loading}
          data-testid="cancel-mnu"
          onClick={() => doAction(onCancel)} button>
          <ListItemIcon>
            <Clear />
          </ListItemIcon>
          <ListItemText primary={t("proposal.details.actions.cancel")} />
        </ListItem>
      </LMTooltip>}

      {menus.reschedule &&
        <LMTooltip
          key="reschedule"
          content={<p>{t("proposal.details.actions.reschedule_help")}</p>}
        >
          <ListItem name="btn-reschedule" disabled={loading}
            data-testid="reschedule-mnu"
            onClick={() => doAction(onReschedule)} button>
            <ListItemIcon>
              <Refresh />
            </ListItemIcon>
            <ListItemText primary={t("proposal.details.actions.reschedule")} />
          </ListItem>
        </LMTooltip>}
    </List>}
    {actionsCmp}
    {!menus && actionsCmp === null && <CoffeeCmp hostName={hostName} proposal={proposal} />}
  </div>;

}
function CoffeeCmp({ hostName, proposal }) {
  const isInPast = proposal && proposal.status === STATUS.CONFIRMED
    && moment(proposal.start) < moment();
  return <div className="action-important">
    <div className="message coffee">
      <span className="simple-icon"><Coffee /></span>
      <span><Trans i18nKey='proposal.details.actions.takeACoffee'
        values={{ hostName }}
        components={{
          t: <h1 />,
          s: <h2 className={isInPast ? 'hidden' : ''} />
        }}>takeACoffee</Trans></span>
    </div>
  </div>
}
const SORTS = [
  isOngoing,
  isMis,
  isArchived
]
function ProposalNavigation({ proposal, proposals, setSelected }) {
  const { t } = useTranslation();
  const { prev, next } = React.useMemo(() => {
    // changed: proposals come from sorted_proposals
    if (!proposal || !proposals) return {};
    let res = {};
    // get current proposal tab
    let tab = getProposalTab(proposal);
    // get proposals from category
    let sorted_proposals = proposals[tab];
    if (!sorted_proposals) return {};
    // get index of proposal
    let i = sorted_proposals.findIndex((p) => p.id === proposal.id);
    res.next = sorted_proposals[i + 1];
    res.prev = sorted_proposals[i - 1];
    return res;
  }, [proposal, proposals]);
  const onNext = React.useCallback(() => {
    if (next) setSelected(next.id);
  }, [next, setSelected])
  const onPrev = React.useCallback(() => {
    if (prev) setSelected(prev.id);
  }, [prev, setSelected])
  /* istanbul ignore if no work */
  if (!proposal) return null;
  return <div className="proposal-details-navigation">
    {prev ? <div className="navigation-prev" onClick={onPrev}>
      <ArrowLeft /><span className="title" title={prev.title}>{t('common.prevMeeting')}</span>
    </div> : <div className="placeholder"></div>}
    {next && <div className="navigation-next" onClick={onNext}>
      <span className="title" title={next.title}>{t('common.nextMeeting')}</span><ArrowRight />
    </div>}
  </div>
}
