import { createMuiTheme } from "@material-ui/core";
import constants from './_constants.scss'


const DoneMaterialUITheme = createMuiTheme({
  palette: {
    primary: {
      main: constants.colorRobinEggBlue,
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  typography: {
    fontFamily: ['Montserrat']
  },
  overrides: {
    MuiButton: {
      text: {
        textTransform: "none",
        color: constants.colorLicorice,
        '&:hover': {
          backgroundColor: "#FFFFFF",
        }
      },
      contained: {
        backgroundColor: constants.colorRobinEggBlue,
      }
    },
    MuiIconButton: {
      root: {
        color: constants.colorLicorice,
        '&:hover': {
          backgroundColor: constants.colorButtonBgHover,
        },
      }
    },
    MuiInput: {
      input: {
        color: constants.colorLicorice
      }
    },
    MuiMenuItem: {
      root: {
        color: constants.colorLicorice,
        '&:hover': {
          //backgroundColor: constants.colorRobinEggBlue
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: "32px"
      }
    },
    MuiDialogTitle: {
      root: {
        color: constants.colorLicorice,
      }
    },
    MuiDialogContentText: {
      root: {
        color: constants.colorLicorice,
      }
    },
    MuiSwitch: {
      switchBase: {
        color: constants.colorIsabelline
      }
    },
  }
});

export default DoneMaterialUITheme;
