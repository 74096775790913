import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";
import locales from './locales';
import LanguageDetector from 'i18next-browser-languagedetector';

import 'moment/locale/fr';
import 'moment/locale/en-gb';

// set locale
let locale = window.navigator.userLanguage || window.navigator.language;
moment.locale([locale, 'en']);
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    ns: ["all"],
    defaultNS: "all",
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      caches: []
    },
    resources: locales,
  });


export default i18n;