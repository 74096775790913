import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../reducers/user";

// This URL is used to load Referral Factory script/widget
// it contains a code, a title and a color for the widget
export const REFERRAL_FACTORY_URL = 'https://referral-factory.com/assets/js/widget.js?code=zcOKCC&title=Refer+a+friend&color=3d2daa&color_light=a69de7';

/**
 * This hook loads and shows the Referral Factory widget.
 * When the hook is "destroyed", the widget gets removed.
 */
export default function useReferralFactory() {

  // get connected user info
  const user = useSelector(getUser);

  useEffect(() => {

    // set user info in the referral factory widget
    if (window && user.email) {
      window.RF = {
        user: {
          first_name: `${user.first_name || user.name}`,
          email: `${user.email}`
        }
      };
    }

    // create and load Referral Factory script
    const script = document.createElement('script');
    script.id = 'rf-script';
    script.src = REFERRAL_FACTORY_URL;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // unload SCRIPT
      document.body.removeChild(script);
      // remove widget launcher DIV
      let rfLauncher = document.getElementById('rf-launcher');
      if (rfLauncher) rfLauncher.remove();
    }
    
  }, [user.email, user.first_name, user.name]);

}

/*

// For reference, this is the script that Referral Factory asks us to include before </body>

<script type='text/javascript'>
    window.RF = {
        user: {
            first_name: `{loggedInUserFirstName}`,
            email: `{loggedInUserEmail}`
        }
    };
</script>
<script id="rf-script" src="https://referral-factory.com/assets/js/widget.js
      ?code=zcOKCC&title=Refer+a+friend
      &color=3d2daa&color_light=a69de7"></script>
*/