import moment from 'moment';

export const NEXT_48H = 0;
export const NEXT_WEEK = 1;
export const NEXT_MONTH = 2;
export const NEXTS = [NEXT_48H, NEXT_WEEK, NEXT_MONTH];

// default user do not disturb configuration
export const DEFAULT_START_HOUR = 9;
export const DEFAULT_END_HOUR = 18;
export const DEFAULT_DAY_WH = {
  frames: [{
    start: DEFAULT_START_HOUR,/*moment.utc(0).add(DEFAULT_START_HOUR, 'hours'), end: moment(0).add(12, 'hours') },
    { start: moment(0).add(14, 'hours'),*/ end: DEFAULT_END_HOUR, // moment.utc(0).add(DEFAULT_END_HOUR, 'hours')
  }
  ]
};
export const CONFIG = {
  '1': DEFAULT_DAY_WH,
  '2': DEFAULT_DAY_WH,
  '3': DEFAULT_DAY_WH,
  '4': DEFAULT_DAY_WH,
  '5': DEFAULT_DAY_WH,
}
const ALL_DAY = {
  frames: [{ start: 0, end: 24 }
  ]
};
export const NO_WH = {
  '7': ALL_DAY,
  '1': ALL_DAY,
  '2': ALL_DAY,
  '3': ALL_DAY,
  '4': ALL_DAY,
  '5': ALL_DAY,
  '6': ALL_DAY,
}
// calculate timeframes for a given time
/*
  RULES/
  next 48 (0) => start next day + day after
  next week (1) => start next week
  next month (2)

  Only between 9AM-12AM and 2PM-6PM
  Not on Saturday/Sunday
*/
export function generateTimeFrames(frame, opts = CONFIG, now = moment(), timezone = false) {
  let frames = [];
  if (frame === -1) return frames;
  if (opts === false) opts = NO_WH;
  if (Object.keys(opts).length === 0) opts = NO_WH;
  timezone = timezone || moment.tz.guess();

  if (frame === NEXT_48H) {
    // get next 2 open days
    let dayFrames = __getNextDayFrames(now, opts, timezone);
    frames.push(...dayFrames.frames);
    dayFrames = __getNextDayFrames(dayFrames.day, opts, timezone);
    frames.push(...dayFrames.frames);

    return frames;
  }
  else if (frame === NEXT_WEEK) {
    let period = {
      start: now.clone().startOf('week').add(1, 'week').subtract(1, 'day'), // because first thing is adding 1 day
      end: now.clone().startOf('week').add(1, 'week').endOf('week')
    }
    return _getFramesForPeriod(period, opts, timezone);
  }
  else {
    let period = {
      start: now.clone().startOf('month').add(1, 'month').subtract(1, 'day'), // because first thing is adding 1 day
      end: now.clone().startOf('month').add(1, 'month').endOf('month')
    }
    return _getFramesForPeriod(period, opts, timezone);
  }
}
export default generateTimeFrames;
// for now, use a config object, later will use a user preference?

export function __getNextDayFrames(date, opts, timezone) {
  let nextDay = __getNextValidDay(date, opts);
  let frames = opts[nextDay.isoWeekday()];
  return {
    day: nextDay,
    frames: __makeFrames(nextDay, frames, timezone)
  }
}
function _getFramesForPeriod(period, opts, timezone) {
  let { start, end } = period;
  let frames = [];
  while (start < end) {
    let dayFrames = __getNextDayFrames(start, opts, timezone);
    if (dayFrames.day >= end) break;
    frames.push(...dayFrames.frames);
    start = dayFrames.day;
  }
  return frames;
}
function __makeFrames(day, frames, timezone) {
  let frm = frames.frames || [];
  // do not care abour TZ for now
  let tmp = [{
    start: day.clone(),
    end: day.clone().endOf("day")
  }];
  // for (let f of frm) {
  //   tmp.push({
  //     start: day.clone()/*.tz(timezone)*/.add(f.start, 'hours'),
  //     end: day.clone()/*.tz(timezone)*/.add(f.end, 'hours') // problem in 24?
  //   })
  // }
  return tmp;
}
// return next valid day between monday and friday
export function __getNextValidDay(date = moment(), opts = CONFIG) {
  let origin = date.clone().startOf('day').add(1, 'day');
  let day = origin.isoWeekday();
  while (!opts[day]) {
    // add 1 day
    day = origin.add(1, 'day').isoWeekday();
  }
  return origin;
}

