import React from 'react';
import { useTranslation } from 'react-i18next';
import DoneButton from '../../../DoneButton';

export default function Promote({ 
    promoteTextKey = 'event.anonymous.promoteText', 
    ctaTextKey = 'event.anonymous.promoteCTA',
    ctaLink = '/?utm_source=proposal_promote_learn_more&utm_medium=web',
}) {
    const { t } = useTranslation();
    return (
        <div className="promote">
            <p>{t(promoteTextKey)}</p>
            <DoneButton
                name="promote-cta"
                label={t(ctaTextKey)}
                className="ondark small blue-outlined"
                onClick={() => { window.location.assign(ctaLink); }} />
        </div>
    );
}