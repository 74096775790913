import React from 'react';
import { ZoomIcon, MeetIcon, TeamsIcon } from '../../../../assets/icons'

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import ROUTES from '../../../../routes/routes.name'
import DoneButton from '../../../DoneButton'
import './conference.scss'
const CONFERENCES = {
  "zoom": {
    conference_id: "zoom",
    icon: <ZoomIcon />
  },
  "zoom:personal": {
    conference_id: "zoom",
    icon: <ZoomIcon />
  },
  "gmeet": {
    conference_id: "gmeet",
    icon: <MeetIcon />
  },
  "teams": {
    conference_id: "teams",
    icon: <TeamsIcon />
  }
}
export default function Conference({ id, conference_url, error, showIcon = true }) {
  let conf = CONFERENCES[id];
  let cmp = null;
  const { t } = useTranslation();
  if (conf) {
    // check for error
    if (error) {
      cmp = <div className="conference-error">
        <div className="conference">{showIcon && conf.icon}
          <span><Trans i18nKey="createEvent.form.recap.conference.errorGenerating">Could not generate link. Check your <Link className="link" to={ROUTES.APP_SETTINGS_TOOLS}>settings</Link>.</Trans></span>
        </div>

      </div>
    } else {
      let txt = t('createEvent.form.recap.conference.' + id.replace(':', ''));
      cmp = (
        <div className="conference">
          {showIcon && conf.icon}
          <span>
            {conference_url ?
              <a href={conference_url} target='_blank' rel='noopener noreferrer'>
                {txt}
              </a>
              :
              txt
            }
          </span>
        </div>
      )
    }

  }
  return cmp;
}