import { IconButton } from '@material-ui/core';
import { LockOutlined, LockOpenOutlined } from '@material-ui/icons';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LMTooltip } from '../../../tooltip/Tooltip';
import './periods.scss';


export const MAX_NB_HELD_SLOTS = 10;

export function TinyHoldSlotOption202212({
  onChange, // called when switch value changes
  disabled = true, // option might not be enabled
  checked,
}) {
  const { t } = useTranslation();

  if (!onChange) return null;

  const clicked = () => {
    if (!disabled) {
      onChange({ target: { name: "holdSlots", value: !checked } });
    }
  }

  return <div className={"tiny-holdslot v202212" + (disabled ? " disabled " : "") + (checked ? " checked " : "")} onClick={clicked}>
    <LMTooltip
      content={
        <>
          <p><strong>{t('createEvent.form.recap.holdOptions.text')}</strong></p>
          {disabled &&
            <p>{t('createEvent.form.recap.holdOptions.disabledTooltip', { MAX_NB_HELD_SLOTS })}</p>
          }
          {!disabled &&
            <Trans i18nKey="createEvent.form.recap.holdOptions.tooltip">
              <p>
                line 1
              </p>
              <p>
                line 2
              </p>
              <p>
                line 3
              </p>
              <p>
                line 4
              </p>
            </Trans>}
        </>
      }>
      {t('createEvent.form.holdSlots')}
      <IconButton size="small" data-testid="holdSlots-switch" disabled={disabled} >
        {checked ? <LockOutlined /> : <LockOpenOutlined />}
      </IconButton>
    </LMTooltip>
  </div>
}