import React from 'react';
import Avatar from './avatar';
import * as CONSTS from './consts';
import CircularProgress from '@material-ui/core/CircularProgress';

import './avatar.with.states.scss';

const STYLES = ['', 'big', 'small']
export default function AvatarWithStates({ name, size, imageSrc, mark, defaultTo, fallBackColor, withBorder }) {
  return <div className={"avatar-state " + mark}><Avatar name={name}
    size={size}
    imageSrc={imageSrc}
    defaultTo={defaultTo}
    fallBackColor={fallBackColor}
    withBorder={withBorder} />
    {mark === CONSTS.MARK_LOADING && <CircularProgress className={"decorator " + STYLES[size]} />}</div>
}