import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProposal } from '../../../../reducers/proposals';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SimpleDetailsView as Details } from '../../create-event/recap/details'
import Invitees from '../user/invitees';
import Proposals from '../Proposals';
import * as ACTIONS from '../../../../actions';
import LinearProgress from '@material-ui/core/LinearProgress'
import DoneButton from '../../../DoneButton';
import { parseQuery } from '../../../../utils/parse.query';
import { NotFoundEvent, ErrorEvent } from '../errors';
import { SuccessVote } from './success';
import { STATUS } from '../../../../api/validators/proposal';
import * as EventStatus from '../event.states';
import '../event.scss';
import './simple.scss';
import ProposalStatus from '../ProposalStatus';
import { BROWSER } from '../../../../utils/browser';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Help } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { Loader } from '../../../Loader';
import ROUTES from '../../../../routes/routes.name';
import PromoRegister from './PromoRegister';
import { useDialog } from '../../../dialogs/dialog.provider';
import WhoAreYou from './WhoAreYou';
import ConfirmOneSlotOnlyDialog from './confirm.one.slot';



export const LS_KEY_BACK_HERE_AFTER_SIGNUP = 'LS_KEY_BACK_TO_URI';
export function Event({
  confirmEvent,
  currentState = 0,
}) {
  const location = useLocation();
  const history = useHistory();
  const urlparams = useParams();
  const dispatch = useDispatch();
  const { enqueueDialog, closeDialog } = useDialog();

  const [loading, setLoading] = React.useState(true);
  const [authenticating, setAuthenticating] = React.useState(false);
  const [exists, setExists] = React.useState(true); // be optimist!
  const [error, setError] = React.useState(false); // be optimist!
  // get id
  React.useEffect(() => {
    /* istanbul ignore if router */
    if (!urlparams.id) {
      setExists(false);
      return;
    }
    // check if got a sig AND a mail, if not => error
    let query = parseQuery(location.search);
    let showWhoAreYouDlg = false;
    if (query.sig && !query.email) {
      // Anonymous link connection
      showWhoAreYouDlg = true;
    } else if (!query.sig || !query.email) {
      // if no sig, should try to log user
      showWhoAreYouDlg = false;
      setAuthenticating(true)
      dispatch({
        type: ACTIONS.ASK_4_LOGIN,
        payload: 'modal',
        redirectTo: `${ROUTES.EVENT}${urlparams.id}`
      })
      return;
    }
    setLoading(true);
    dispatch({
      type: ACTIONS.WORKER_GET_PROPOSAL,
      payload: {
        id: urlparams.id,
        signature: query.sig,
        email: query.email,
        bestSuggestions: true,
        userEmail: [query.email]
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR,
      }
    })
      .then((res) => {
        dispatch({
          type: ACTIONS.ANALYTICS_VIEW_PROPOSAL,
          payload: {
            proposal: res.payload && res.payload[0],
          }
        })
        return res;
      })
      .then((res) => {
        if (!res.payload || !res.payload[0]) setExists(false);
        return res;
      })
      .catch((err) => {
        if (err && err.status === 404) {
          setExists(false);
        } else {
          // display error
          console.log(err)
          setError(true)
        }
      })
      .then((res) => {
        if (showWhoAreYouDlg && res && res.payload && res.payload[0]) {
          let proposal = res.payload[0];

          const doSelect = (invitee, registered = false) => {
            if (registered) {
              history.push(location.pathname);
            } else {
              history.push(location.pathname + location.search + '&email=' + encodeURIComponent(invitee.email) + "&anon=1")
            }
            closeDialog();
          }
          enqueueDialog({
            content: <WhoAreYou
              invitees={Object.values(proposal.inviteesDetails || {}).sort((a, b) => {
                if (a.registered) {
                  if (b.registered) {
                    return -1;
                  } else {
                    return 1;
                  }
                } else {
                  if (!b.registered) {
                    return 1;
                  } else {
                    return -1;
                  }
                }
              })}
              organizer={proposal.organizer}
              onSelectAction={doSelect} />,
            mustConfirm: true,

          })
        }
      }).then(() => {
        setLoading(false)
      })
  }, [urlparams.id, location.search, dispatch])
  // for now, get mocks
  const proposal = useSelector(getProposal(urlparams.id));
  // const { enqueueDialog, closeDialog } = useDialog();
  const [hadVoted, setHadVoted] = React.useState(0); // 0: no, 1: voted, 2: cancel
  /*React.useEffect(()=>{
    // load proposal?
    console.log('loading ', id);
    return 
  }, [id]); // only first time*/
  const onVote = React.useCallback((votes) => {
    // set proposal slots
    // only got an array of true/false to remap to slots
    let slots = proposal.remaining_slots.reduce((acc, v) => {
      acc.push(...v.slots);
      return acc;
    }, []);
    let total_voted_count = 0;
    for (let i = 0; i < slots.length; i++) {
      slots[i].vote = votes[i];
      total_voted_count += votes[i] ? 1 : 0;
    }
    let p = Promise.resolve(true);

    // how many people will have to vote after me?
    let nbGuestsLeftToVoteAfterMe = Object.values(proposal.inviteesDetails).reduce((acc, invitee) => {
      if (!invitee.registered && invitee.response !== 'yes') acc = acc + 1;
      return acc;
    }, 0) - 1;

    // magic formulae for minimum ideal nb of selected slots
    let nbSlotsRecommended = nbGuestsLeftToVoteAfterMe * 2;

    // show a warning popup if needed
    if (
      nbGuestsLeftToVoteAfterMe > 0 // there is at least one guest to vote after me
      && slots.length > total_voted_count // user could have selected more slots (ie. did not select all slots)
      && total_voted_count < nbSlotsRecommended // the user did not select "enough" options
    ) {
      p = new Promise((resolve, reject) => {
        // need to confirm first
        const unblock = history.block();
        const doClose = () => {
          unblock();
          closeDialog();
          resolve(false);
        };
        const doConfirm = () => {
          unblock();
          closeDialog();
          resolve(true);
        }
        enqueueDialog({
          content: <ConfirmOneSlotOnlyDialog
            doConfirm={doConfirm}
            doClose={doClose}
            nbSlotsVoted={total_voted_count}
            nbSlotsRecommended={nbSlotsRecommended}
            nbGuestsLeftToVoteAfterMe={nbGuestsLeftToVoteAfterMe} />,
          mustConfirm: true,
          doClose,
        })
      })

    }
    p.then((confirm) => {
      if (confirm === true) {
        // do vote for datas
        setHadVoted(1);
        let query = parseQuery(location.search);
        confirmEvent(slots, { ...query, signature: query.sig, id: urlparams.id, userEmail: [query.email] });
      }
    })

  }, [proposal, urlparams.id, location.search, confirmEvent]);

  const onDecline = React.useCallback(() => {
    // do vote for datas
    setHadVoted(2);
    let query = parseQuery(location.search);
    confirmEvent([], { ...query, signature: query.sig, id: urlparams.id });
  }, [location.search, confirmEvent, urlparams.id]);

  // user click on connect my calendar from event page
  const connectAccount = React.useCallback(() => {
    try {
      // save the URL to go back to once sign up is complete
      // URL will be removed from LS is popup is closed (providers.id)
      // @stephane: I am NOT sure it's the best place to do this
      const urlToGoBackTo = window.location && window.location.href; //location.pathname;
      localStorage.setItem(LS_KEY_BACK_HERE_AFTER_SIGNUP, urlToGoBackTo);
    } catch (e) { }
    // show sign in popup
    dispatch({
      type: 'ASK_4_LOGIN',
      payload: true,
    })
  }, [dispatch]);

  let inner = null;

  if (!exists) {
    inner = <NotFoundEvent />;
  } else if (error) {
    inner = <ErrorEvent />;
  } else {
    if (proposal && proposal.id) {
      if (proposal.hadVoted === true && proposal.status === STATUS.PENDING) inner = <SuccessVote proposal={proposal} />;
      else inner = <EventDisplay proposal={proposal} connectAccount={connectAccount} onVote={onVote} onDecline={onDecline} />
    }
  }

  return (<>
    <div className="event-container">
      {loading && <LinearProgress />}
      {inner}
    </div>
    {authenticating && <Loader className="darker" />}
  </>
  );
}

export function EventDisplay({ proposal, connectAccount, onVote, onDecline }) {
  const { t } = useTranslation();
  const allowSignin = BROWSER.getPlatformType() !== 'mobile' && BROWSER.getPlatformType() !== 'tablet';

  const [showAll, setShowAll] = React.useState(false);
  if (!proposal || !proposal.id
    || !proposal.organizer) return null;


  let inner = null;
  if (proposal.status === STATUS.PENDING) {
    inner = (<div className="event-proposals">
      <div className="event-proposal-header">
        <div className="event-proposal-title">
          <div>{t('event.confirmAvailability')}</div>
          <div>{t('event.confirmAvailabilitySubtext')}</div>
        </div>
        {/* <FormControlLabel
          control={<Switch className="done-input" color="primary" checked={showAll} onChange={(e) => setShowAll(!showAll)} name="checkedA" />}
          label={t(showAll ? 'event.showBest' : 'event.showAll')} /> */}
        {proposal && proposal.bestSuggestions &&
          <div className="showBest-container">
            <ToggleButtonGroup
              className="showBestToggle"
              value={showAll}
              exclusive
              onChange={(e) => setShowAll(!showAll)}>
              <ToggleButton value={false}>{t('event.showBest')}</ToggleButton>
              <ToggleButton value={true}>{t('event.showAll')}</ToggleButton>
            </ToggleButtonGroup>
            <HelpToolTip
              text={t('event.showBestTooltip',
                {
                  nbSuggestedSlots: proposal.bestSuggestions.reduce((acc, dailySlots) => {
                    if (dailySlots && dailySlots.length > 0) acc += dailySlots.length;
                    return acc;
                  }, 0)
                })} />
          </div>}
      </div>
      <div className="event-proposal-slots fancy-scroll">
        <Proposals proposals={proposal} onSubmit={onVote} onDecline={onDecline} _showAll={showAll} />
      </div>
    </div>);
  } else if (proposal.status === STATUS.CANCELLED) {
    inner = <div data-testid="cancelled-event" className="event-proposals">
      < div className="event-proposal-slots fancy-scroll">
        <EventStatus.CancelledEvent />
      </div>
    </div>

  } else if (proposal.status === STATUS.CONFIRMED) {
    inner = <div data-testid="confimed-event" className="event-proposals">
      <div className="event-proposal-slots fancy-scroll">
        <EventStatus.ConfirmedEvent date={proposal.start} />
      </div>
    </div>
  } else /*if (proposal.status === STATUS.NO_AVIABILITY)*/ {
    inner = <div data-testid="no-av-event" className="event-proposals">
      <div className="event-proposal-slots fancy-scroll">
        <EventStatus.NoAviabilityEvent />
      </div></div>
  }


  return (
    <>
      {allowSignin && (proposal.status === STATUS.PENDING && proposal.hadVoted !== true) &&
        <div className="event-announce" data-testid="event-proposal">
          <div className="announce-title">{t('event.announceOrganizer', { name: proposal.organizer.name || proposal.organizer.email })}</div>
          <div className="announce-connect">
            <span className="announce-hint">{t('event.announceHint')}</span>
            <DoneButton label={t('event.announceConnect')} onClick={connectAccount} />
          </div>
        </div>}
      <div data-testid="event-view" className="event">
        <div className="recap-details">
          <div className="event-status"><span>{t('event.status')}</span><ProposalStatus proposal={proposal} /></div>
          <Details details={proposal} kickNotes={true} />
          {/* <Invitees invitees={proposal.invitees} /> */}
          <Invitees formDatas={proposal} isEditable={false} status={proposal.inviteesDetails} />
        </div>
        <div className="right-container">
          {inner}
          {(allowSignin && (proposal.status !== STATUS.PENDING || proposal.hadVoted === true)) && <PromoRegister />}
        </div>
      </div>
    </>
  );
}


export const HelpToolTip = ({ text }) => {
  const [showTool, setShowTool] = useState(false);
  const showOnClick = BROWSER.getPlatformType() === 'mobile' || BROWSER.getPlatformType() === 'tablet';
  if (showOnClick) {
    return (
      <Tooltip
        open={showTool}
        onOpen={() => setShowTool(true)}
        onClose={() => setShowTool(false)}
        title={text}
        leaveTouchDelay={10000}>
        <Help onClick={() => setShowTool(!showTool)} />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        title={text}>
        <Help />
      </Tooltip>
    );
  }
}
