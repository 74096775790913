/* istanbul ignore file */
// no test here as it's mainly display in dashboard
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAuth } from '../../../reducers/user';
import { Redirect, useLocation } from 'react-router-dom';
import ROUTES from '../../../routes/routes.name';
import { BROWSER } from '../../../utils/browser';
import { Event as SimpleEvent } from './anonymous';
import { Event as UserEvent } from './connected';
import useDefaultTZ from '../../../hooks/use.default.tz';
import { Loader } from '../../Loader'
import * as ACTIONS from '../../../actions';
export default function Proposal({ allowRedirectToDashBoard = true }) {
  const is_auth = useSelector(isAuth);
  const user = useSelector(state => state.user)
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  let path = location.pathname.split('/');
  let appstate = location.state;
  let id = path[path.length - 1];
  const isMobile = BROWSER.getPlatformType() === 'mobile' || BROWSER.getPlatformType() === 'tablet';
  useDefaultTZ();

  React.useEffect(() => {
    if (is_auth && appstate && id) {
      // parse url
      dispatch({
        type: ACTIONS.WORKER_REGISTER_TO_PROPOSAL,
        payload: {
          id: id,
          as: appstate,
          account: user.email// user connected email
        },
        // if we do not add this property, we will not wait for the register!
        resolvers: {
          resolveOn: ACTIONS.WORKER_REGISTER_TO_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_REGISTER_TO_PROPOSAL_ERROR,
        }
      }).catch((err) => {
        console.error(err)
      }).then(() => {
        setLoading(false);
      });
      return;
    }
    setLoading(false);
  }, [id, appstate, is_auth, user])
  if (loading) return <Loader className="no-transform" />
  return is_auth ?
    (allowRedirectToDashBoard && !isMobile) ? <Redirect to={ROUTES.APP + "?id=" + id} /> : <UserEvent /> : <SimpleEvent />;
}