import React from 'react';
import { useStripe } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { saveInCache } from '../../../utils/load.proposal.from.cache';
import * as ACTIONS from '../../../actions';
import PromoCard from '../settings/billing/promo.card';
import { HOME } from '../../../api/config';
import routes from '../../../routes/routes.name';
export function BillingDlg({ values, version = 'MFS' }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const snackbar = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const doSubscribe = React.useCallback(() => {
    if (loading) return;
    setLoading(true)
    // save values in LS
    if (values) saveInCache(values)
    dispatch({
      type: ACTIONS.WORKER_BILLING_SETUP,
      payload: {
        url: HOME + routes.CREATE_A_MEETING
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_BILLING_SETUP_SUCCESS,
        rejectOn: ACTIONS.WORKER_BILLING_SETUP_ERROR
      }
    }).then((data) => {
      if (!data) return Promise.reject()
      // redirect to new page
      return stripe
        .redirectToCheckout({
          sessionId: data.payload
        })
    }).catch((err) => {
      console.log('[error]', err);
      snackbar.enqueueSnackbar(t("errors.stripeError"))
    })
      .then(() => setLoading(false));
  }, [values, loading])
  return <div className="billing-dialog">
    <div className="title">{t('settings.sections.billing.dialogTitle')}</div>
    <div className="promo">{t('settings.sections.billing.free.promo1')}</div>
    <div className="promo">{t('settings.sections.billing.free.promo2')}</div>
    <PromoCard doSubscribe={doSubscribe} loading={loading} />
  </div>
}