import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputBase from '@material-ui/core/InputBase';
import { useTranslation, Trans } from 'react-i18next';
import {
  HelpOutline
} from '@material-ui/icons';
import { LMTooltip } from '../../../tooltip/Tooltip';
import "./duration.selector.scss"
import './inputs.scss';
import InputLabel from '@material-ui/core/InputLabel';
import { EVENT_DURATION_MAX, EVENT_DURATION_MIN } from '../../../../validators/event';
import { isMobilePlateform } from '../../../../utils/browser';
export const DURATIONS = [30, 45, 60];
const CUSTOM_PROPS = { type: 'number', min: '' + EVENT_DURATION_MIN, max: '' + EVENT_DURATION_MAX, 'data-testid': "custom-input" };

export default function DurationSelector({
  value = 60,
  customValue,
  name,
  onChange,
  errorText = '',
  isValid = true,
  disabled = false,
  labels = true,
  durations = DURATIONS
}) {
  const { t } = useTranslation();
  const customInput = React.useRef();

  const onCustomFocused = React.useCallback((e) => {
    if (customInput && customInput.current && customInput.current.children) {
      customInput.current.children[0].focus();
      // prevent keyboard to mask input on mobile
      if (isMobilePlateform()) {
        try {
          // scrolling does not seem to be working fine on Android
          customInput.current.children[0].scrollIntoView({ block: "center" });
        } catch (e) { }
      }
    }
    // trigger change
    onChange({
      target: {
        name: 'customduration',
        value: +customValue
      }
    })
  }, [customInput, customValue, onChange]);
  const isNotValid = (!isValid) && value === -1;
  return (
    <div className={"duration-padding duration-cmp " + (disabled ? "disabled" : "")}>
      {labels && <InputLabel className="fake-label with-help-button">
        {t('common.duration')}
        <LMTooltip
          childrenClassName="showHand"
          content={
            <>
              <p><Trans i18nKey="createEvent.form.duration.tooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
            </>
          }
        >
          <HelpOutline />
        </LMTooltip></InputLabel>}
      <ToggleButtonGroup
        className={"done-input " + (isNotValid ? 'Mui-error' : '')}
        name={name}
        value={value}
        exclusive

        onChange={(e, v) => {
          if (v) {
            onChange({
              target: {
                name: name,
                value: v
              }
            });
          };
        }}
        aria-label="Meeting duration"
      >


        {
          durations.map((d) => (
            <ToggleButton value={d} size="large" key={d}>
              <div className="strong">{d}</div>{t('common.min')}
            </ToggleButton>
          ))
        }
        <ToggleButton data-testid="custom-time" value={-1} size="large" className="no-ripple custom-duration-input shrinkalittlebit"
          onClick={onCustomFocused}>
          <InputBase
            name={'custom' + name}
            placeholder='__'
            fullWidth
            // readOnly={value === -1 ? undefined : true} // iphone does not like that. @STEPH: consequences of this comment?
            ref={customInput}
            value={value === -1 ? customValue : ''}
            onChange={(e) => {
              onChange({
                target: {
                  name: 'custom' + name,
                  value: e.target.value === '' ? '' : Number.parseInt(e.target.value)
                }
              })
            }}
            inputProps={CUSTOM_PROPS}
          />
          <div>{t('common.min')}</div>
        </ToggleButton>
      </ToggleButtonGroup>
      {isNotValid && <p className="done-Mui-error align-right" id="customduration-helper-text">{errorText}</p>}
    </div>
  );
}