import * as ACTIONS from '../actions';
import jwt_decode from "jwt-decode";
// import * as firebase from "firebase/app";
// import "firebase/analytics";
import { isUnknownDomain } from '../api/validators/proposal';
import routes from '../routes/routes.name';
import sha1 from 'sha1';

/**
 * Anything logged in Analytics goes here.
 * Useful links:
 * https://stackoverflow.com/questions/59330467/how-to-track-page-view-with-firebase-analytics-in-a-web-single-page-app
 * 
 */

const analytics = store => next => action => {
  try {
    if (action) {
      if (action.type === ACTIONS.WORKER_DONE_TOKEN_RECEIVED && action.payload) {

        // -----------------------------------
        // -------- user signed in -----------
        // -----------------------------------

        // get the "user ID" from the Done token and setUserId
        const doneToken = action.payload && action.payload.done;
        if (doneToken) {
          const decodedDoneToken = jwt_decode(doneToken);
          const userId = decodedDoneToken && decodedDoneToken.sub;
          if (userId) {
            console.log("Analytics - setUserId", userId);
            //firebase.analytics().setUserId(userId);
            // if (window.ga) window.ga('set', 'userId', userId);
            if (window.gtag) window.gtag('set', { 'user_id': userId });
          }
        }

      } else if (action.type === ACTIONS.WORKER_CONNECT_SUCCESS || action.type === ACTIONS.WORKER_UNREGISTER_ACCOUNT_SUCCESS) {

        // -----------------------------------
        // -------- user info fetched --------
        // -----------------------------------

        const nbConnectedAccounts = action.payload && action.payload.accounts && action.payload.accounts.length;
        const properties = {
          'nb_accounts': nbConnectedAccounts || 0,
        }
        console.log("Analytics - set user property nb_accounts", properties, action.payload);
        // firebase.analytics().setUserProperties(properties);
        // calling both 'set' command because I saw both ways in the doc...
        if (window.gtag) { window.gtag('set', 'user_properties', properties); window.gtag('set', properties); }

      } else if (action.type === ACTIONS.WORKER_LOGOUT) {

        // -----------------------------------
        // -------- user signed out ----------
        // -----------------------------------

        // @TODO: it is unclear how to do this... What are the consequences? hard to tell
        console.log("Analytics - User logged out -> unset userId and user properties (?)");
        // firebase.analytics().setUserId(null);
        // firebase.analytics().setUserProperties({
        //   nb_accounts: null,
        // });
        if (window.gtag) window.gtag('set', { 'user_id': undefined });

      } else if (action.type === ACTIONS.ANALYTICS_SIGNED_UP) {

        // -----------------------------------
        // -------- user signed up -----------
        // -----------------------------------

        const calProvider = action.payload && action.payload.provider;
        // const fromEventPage = action.payload && action.payload.fromEventPage;
        const fromUrl = action.payload && action.payload.fromUrl;
        const eventParams = {
          method: calProvider,
          from: ((fromUrl && fromUrl.includes(routes.EVENT)) ? "page_proposal" : "other"),
        }
        // use UTM params to add params to event
        // this is to know where the user came from when he signed up
        if (fromUrl && fromUrl.includes('?')) {
          const qs = new URLSearchParams(fromUrl.split('?')[1]); // get query string
          const utmSource = qs.get('utm_source');
          const utmMedium = qs.get('utm_medium');
          if (utmMedium) {
            eventParams.mm_medium = utmMedium;
          }
          if (utmSource) {
            eventParams.mm_source = utmSource;
          }
        }
        console.log("Analytics - " + FIREBASE_KEYS.USER_SIGNED_UP, eventParams);
        // firebase.analytics().logEvent(FIREBASE_KEYS.USER_SIGNED_UP, eventParams)
        if (window.gtag) window.gtag('event', FIREBASE_KEYS.USER_SIGNED_UP, eventParams);
        // if (window.ga) window.ga('send', 'event', 'webapp', FIREBASE_KEYS.USER_SIGNED_UP);

      } else if (action.type === ACTIONS.ANALYTICS_INVITE_SENT) {

        // -----------------------------------
        // - invite sent (email or proposal) -
        // -----------------------------------

        if (action.payload) {
          const invitedEmails = action.payload.invitedEmails;
          if (invitedEmails && Array.isArray(invitedEmails)) {
            const source = action.payload.source;
            if (source) {
              invitedEmails.forEach((invitedEmail) => {
                if (invitedEmails) {
                  let eventParams = {
                    invited_hash: sha1(invitedEmail + '-mm-invited'),
                    mm_source: source,
                  };
                  // firebase.analytics().logEvent(FIREBASE_KEYS.INVITE_SENT, eventParams);
                  if (window.gtag) window.gtag('event', FIREBASE_KEYS.INVITE_SENT, eventParams);
                  // if (window.ga) window.ga('send', 'event', 'webapp', FIREBASE_KEYS.INVITE_SENT, source);
                }
              }
              );
            }
          }

        }

      } else if (action.type === ACTIONS.ANALYTICS_PROPOSAL_CREATED) {

        // -----------------------------------
        // ------- proposal created ----------
        // -----------------------------------

        const proposal = action.payload.proposal;
        if (proposal) {
          const whenType = getWhenTypeFromCurrentTimeframeValue(proposal.currenttimeframe);
          const nbGuests = proposal.invitees && proposal.invitees.length; // excludes organizer
          const calProvider = proposal.calendar && proposal.calendar.provider;
          const withLocation = !!proposal.location;
          const looksLikeZoomLocation = !!(proposal.location && proposal.location.includes("zoom.us"));
          const withNotes = !!proposal.notes;
          const nbSlots = proposal.slots && proposal.slots.reduce((acc, slot) => acc + slot.slots.length, 0);
          const duration = (proposal.duration < 0) ? proposal.customduration : proposal.duration;
          const eventParams = {
            when_type: whenType,
            nb_guests: nbGuests,
            cal_provider: calProvider, // "google" or "microsoft"
            with_location: withLocation,
            looks_like_zoom_location: looksLikeZoomLocation,
            with_notes: withNotes,
            nb_slots: nbSlots,
            duration: duration,
          };
          console.log("Analytics - " + FIREBASE_KEYS.PROPOSAL_CREATED, eventParams);
          // firebase.analytics().logEvent(FIREBASE_KEYS.PROPOSAL_CREATED, eventParams);
          if (window.gtag) window.gtag('event', FIREBASE_KEYS.PROPOSAL_CREATED, eventParams);
          // if (window.ga) window.ga('send', 'event', 'webapp', FIREBASE_KEYS.PROPOSAL_CREATED);
        } else {
          console.error("Analytics - Unable to log proposal created")
        }

      } else if (action && action.type === ACTIONS.ANALYTICS_MEETING_CREATED) {

        // -----------------------------------
        // -------- meeting created ----------
        // -----------------------------------
        // Note: when host creates a meeting on ONE slot

        const proposal = action.payload.proposal;
        if (proposal) {
          const whenType = getWhenTypeFromCurrentTimeframeValue(proposal.currenttimeframe);
          const nbGuests = proposal.invitees && proposal.invitees.length; // excludes organizer
          const calProvider = proposal.calendar && proposal.calendar.provider;
          const withLocation = !!proposal.location;
          const looksLikeZoomLocation = !!(proposal.location && proposal.location.includes("zoom.us"));
          const withNotes = !!proposal.notes;
          const duration = (proposal.duration < 0) ? proposal.customduration : proposal.duration;
          const eventParams = {
            when_type: whenType,
            nb_guests: nbGuests,
            cal_provider: calProvider,
            with_location: withLocation,
            looks_like_zoom_location: looksLikeZoomLocation,
            with_notes: withNotes,
            duration: duration,
          };
          console.log("Analytics - " + FIREBASE_KEYS.MEETING_CREATED, eventParams);
          // firebase.analytics().logEvent(FIREBASE_KEYS.MEETING_CREATED, eventParams);
          if (window.gtag) window.gtag('event', FIREBASE_KEYS.MEETING_CREATED, eventParams);
          // if (window.ga) window.ga('send', 'event', 'webapp', FIREBASE_KEYS.MEETING_CREATED);
        }

      } else if (action && action.type === ACTIONS.WORKER_CREATE_PROPOSAL_SUCCESS) {


        // -----------------------------------
        // ------- meeting confirmed ---------
        // -----------------------------------
        // Note: when host forces a meeting from proposal

        const proposal = action.payload;
        if (proposal) {
          //const whenType = getWhenTypeFromCurrentTimeframeValue(proposal.currenttimeframe);
          const nbGuests = proposal.invitees && proposal.invitees.length; // excludes organizer
          const calProvider = proposal.calendar && proposal.calendar.provider;
          const withLocation = !!proposal.location;
          const looksLikeZoomLocation = !!(proposal.location && proposal.location.includes("zoom.us"));
          const withNotes = !!proposal.notes;
          const duration = (proposal.duration < 0) ? proposal.customduration : proposal.duration;
          const eventParams = {
            //when_type: whenType,
            nb_guests: nbGuests,
            cal_provider: calProvider,
            with_location: withLocation,
            looks_like_zoom_location: looksLikeZoomLocation,
            with_notes: withNotes,
            duration: duration,
          };
          console.log("Analytics - " + FIREBASE_KEYS.PROPOSAL_CONFIRMED, eventParams);
          // firebase.analytics().logEvent(FIREBASE_KEYS.PROPOSAL_CONFIRMED, eventParams);
          if (window.gtag) window.gtag('event', FIREBASE_KEYS.PROPOSAL_CONFIRMED, eventParams);
          // if (window.ga) window.ga('send', 'event', 'webapp', FIREBASE_KEYS.PROPOSAL_CONFIRMED);
        }

      } else if (action && action.type === ACTIONS.ANALYTICS_VIEW_PROPOSAL) {

        // -----------------------------------
        // -------- proposal viewed ----------
        // -----------------------------------

        const isRegisteredUser = (store.getState().user && store.getState().user.isAuth) ? true : false;
        const proposal = action.payload && action.payload.proposal;

        if (proposal) {
          const nbRemainingSlots = proposal.remaining_slots.reduce((acc, slot) => acc + slot.slots.length, 0);
          const isHost = !!proposal.iAmHost;
          const eventParams = {
            is_registered: isRegisteredUser,
            is_host: isHost,
            nb_slots: nbRemainingSlots
          };
          console.log("Analytics - " + FIREBASE_KEYS.PROPOSAL_VIEWED, eventParams);
          // firebase.analytics().logEvent(FIREBASE_KEYS.PROPOSAL_VIEWED, eventParams);
          if (window.gtag) window.gtag('event', FIREBASE_KEYS.PROPOSAL_VIEWED, eventParams);
          // if (window.ga) window.ga('send', 'event', 'webapp', FIREBASE_KEYS.PROPOSAL_VIEWED);
        } else {
          console.error("Analytics - Unable to log proposal viewed")
        }

      } else if (action && action.type === ACTIONS.WORKER_VOTE_PROPOSAL_SUCCESS) {

        // -----------------------------------
        // ------- proposal answered ---------
        // -----------------------------------

        const isRegisteredUser = (store.getState().user && store.getState().user.isAuth) ? true : false;
        const payload = action.payload;
        const slots = payload.proposal;
        const answeredSlots = slots && slots.filter(slot => slot.vote === true);

        // const proposalId = payload.opts.id;
        // const proposalDetailsAfterVote = store.getState().proposals[proposalId];

        const eventParams = {
          is_registered: isRegisteredUser,
          nb_slots: slots && slots.length,
          nb_answers: answeredSlots && answeredSlots.length,
        };
        console.log("Analytics - " + FIREBASE_KEYS.PROPOSAL_ANSWERED, eventParams);
        // firebase.analytics().logEvent(FIREBASE_KEYS.PROPOSAL_ANSWERED, eventParams);
        if (window.gtag) window.gtag('event', FIREBASE_KEYS.PROPOSAL_ANSWERED, eventParams);
        // if (window.ga) window.ga('send', 'event', 'webapp', FIREBASE_KEYS.PROPOSAL_ANSWERED);

      }
    }
  } catch (error) {
    // silently fail in case firebase crashes
    console.error("Failed to use Firebase analytics", error);
  }
  next(action);
}

export default analytics;

const FIREBASE_KEYS = {
  USER_SIGNED_UP: 'sign_up',
  INVITE_SENT: 'invite_sent',
  ACCOUNT_REGISTERED: '',
  PROPOSAL_CREATED: 'proposal_created',
  PROPOSAL_VIEWED: 'proposal_viewed',
  PROPOSAL_ANSWERED: 'proposal_answered',
  PROPOSAL_CONFIRMED: 'proposal_confirmed',
  MEETING_CREATED: 'meeting_created',
  WHEN_TYPES: {
    FORTY_EIGHT_HOURS: '48_hours',
    NEXT_WEEK: 'next_week',
    NEXT_MONTH: 'next_month',
    HEATMAP: 'heatmap',
  },
}

export const FIREBASE_VALUES = {
  INVITE_SENT_FROM_INVITE: 'invite',
  INVITE_SENT_FROM_PROPOSAL: 'proposal',
}

export const CURRENT_TIMEFRAME_VALUE = {
  FORTY_EIGHT_HOURS: 0,
  NEXT_WEEK: 1,
  NEXT_MONTH: 2,
  HEATMAP: -1,
}

export const getWhenTypeFromCurrentTimeframeValue = (currenttimeframe) => {
  switch (currenttimeframe) {
    case CURRENT_TIMEFRAME_VALUE.FORTY_EIGHT_HOURS:
      return FIREBASE_KEYS.WHEN_TYPES.FORTY_EIGHT_HOURS;
    case CURRENT_TIMEFRAME_VALUE.NEXT_WEEK:
      return FIREBASE_KEYS.WHEN_TYPES.NEXT_WEEK;
    case CURRENT_TIMEFRAME_VALUE.NEXT_MONTH:
      return FIREBASE_KEYS.WHEN_TYPES.NEXT_MONTH;
    case CURRENT_TIMEFRAME_VALUE.HEATMAP:
      return FIREBASE_KEYS.WHEN_TYPES.HEATMAP;
    default:
      return undefined;
  }
}

export function getLMUsersCount(proposal) {
  if (!proposal || !proposal.inviteesDetails) return 0;
  return Object.values(proposal.inviteesDetails)
    .filter((inv) => {
      return inv.registered;
    }).length;
}
export function getHybridCount(proposal) {
  if (!proposal || !proposal.inviteesDetails) return 0;
  return Object.values(proposal.inviteesDetails)
    .filter((inv) => {
      return !!(inv.related_to && inv.related_to.provider && inv.related_to.user_id);
    }).length;
}
export function getLinkCount(proposal) {
  if (!proposal || !proposal.inviteesDetails) return 0;
  return Object.values(proposal.inviteesDetails)
    .filter((inv) => {
      return isUnknownDomain(inv.email);
    }).length;
}