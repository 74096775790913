import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Check, Close
} from '@material-ui/icons';
import "./simple.switch.scss";

export default function SimpleSwitch({
  className = '',
  name, onClick, labelWhenChecked, labelWhenNotChecked, checked
}) {
  const { t } = useTranslation();
  labelWhenChecked = labelWhenChecked || t('common.yes')
  labelWhenNotChecked = labelWhenNotChecked || t('common.no');
  return (
    <div
      className={
        'SimpleSwitch ' + className}
      onClick={onClick}>
      {checked ? <Close /> : <Check />}
      <span data-testid={name} className='label'>
        {checked ? labelWhenChecked : labelWhenNotChecked}
      </span>
    </div>
  );
}