import React from 'react';
import Notifications from './notifications';
import LinkSlug from './booking.link'
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import * as ACTIONS from '../../../../actions';
import Lang from './lang'
import { ConfigStartSettings } from './overlapp'

export default function AdvancedSettings({
  showConfigStartSettings = false
}) {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const profile = useSelector((state => state.profile))
  React.useEffect(() => {
    // load settings from server in case someting change
    dispatch({
      type: ACTIONS.WORKER_REFRESH_PROFILE,
      resolvers: {
        resolveOn: ACTIONS.WORKER_REFRESH_PROFILE_SUCCESS,
        rejectOn: ACTIONS.WORKER_REFRESH_PROFILE_ERROR,
      }
    })
      .catch((err) => {
        // nothing for now
      }).then(() => {
        setLoading(false);
      })
  }, []);
  return (
    <div className="settings-advanced  fancyscroll " data-testid="advanced-panel">
      <Notifications profile={profile} />
      <Lang profile={profile} />
      <LinkSlug profile={profile} />
      {showConfigStartSettings && <ConfigStartSettings profile={profile} />}
    </div>
  )
}
