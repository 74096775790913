import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import * as ACTIONS from '../../../../actions';
import { LinearProgress } from '@material-ui/core';
import DoneButton from '../../../DoneButton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardArrowRight } from "@material-ui/icons";
import { ZoomIcon as Zoom } from '../../../../assets/icons'
import useToolStatus from '../../../../hooks/use.tool.status';
import './tools.scss';

const TOOLS_ICONS = {
  'zoom': <Zoom />
}
export default function Tools({ }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [tools, setTools] = React.useState(false);

  useToolStatus();

  React.useEffect(() => {
    // load integrations datas from server
    dispatch({
      type: ACTIONS.WORKER_GET_TOOLS,
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_TOOLS_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_TOOLS_ERROR,
      }
    })
      .then((tls) => {
        setTools(tls.payload);
      }).catch((err) => {
        setTools(null)
      })
  }, []);
  const doRemove = React.useCallback((tool) => {
    dispatch({
      type: ACTIONS.WORKER_REMOVE_TOOLS,
      payload: tool.toolID,
      resolvers: {
        resolveOn: ACTIONS.WORKER_REMOVE_TOOLS_SUCCESS,
        rejectOn: ACTIONS.WORKER_REMOVE_TOOLS_ERROR,
      }
    })
      .then((tls) => {
        setTools(tls.payload);
      }).catch((err) => {

      })
  }, [tools]);
  return (
    <div className="settings-tools" data-testid="tools-settings">
      <div className="section">
        <div className="title">
          {t('settings.sections.tools.subtitle')}
        </div>
        {tools === false && <div className="home-loader"><LinearProgress /></div>}
        {tools === null && <div className="error">{t('settings.sections.tools.error')}</div>}
        {tools && tools.map((tool) => tool.oauthURL ? <ToolConnector key={tool.toolID} tool={tool} /> : <Tool key={tool.toolID} tool={tool} doRemove={doRemove} />)}
      </div>
    </div>
  );
}

export function Tool({ tool, doRemove }) {
  const { t } = useTranslation();

  const email = (tool.user_settings || {}).email || '';
  return <div className="tool-connector connected withoptions">
    <div>
      <div className="tool-connector-details">
        <div className="tool-connector-details-title">
          {TOOLS_ICONS[tool.toolID]}<span>{t('settings.sections.tools.' + tool.toolID + '.name')}</span>
        </div>
        <div className="tool-connector-details-desc">
          <Trans i18nKey={'settings.sections.tools.' + tool.toolID + '.connected'}
            values={{ email }}>
            <b>Connected</b> — {email}
          </Trans>
        </div>
      </div>
      <DoneButton label={t('settings.sections.tools.' + tool.toolID + '.remove')}
        className="small grey"
        name={"tool-remove" + tool.toolID}
        onClick={() => doRemove(tool)} />
    </div>
    {tool.settings &&
      TOOL_OPTIONS[tool.toolID]
      && <div className="tool-connector-options">
        {TOOL_OPTIONS[tool.toolID](tool)}
      </div>}
  </div>
}
export function ToolConnector({ tool }) {
  const { t } = useTranslation();
  const doConnect = React.useCallback(() => {
    window.location.assign(tool.oauthURL)
  }, [tool]);
  // can be in error
  if (tool.third_party_status === 'error') {
    return <div className="tool-connector connected">
      <div className="tool-connector-details">
        <div className="tool-connector-details-title">
          {TOOLS_ICONS[tool.toolID]}<span>{t('settings.sections.tools.' + tool.toolID + '.problems')}</span>
        </div>
      </div>
      <DoneButton label={t('settings.sections.tools.' + tool.toolID + '.reconnect')}
        name={"tool-connect" + tool.toolID}
        className="small grey"
        endIcon={<KeyboardArrowRight />}
        onClick={doConnect} />
    </div>
  }
  return <div className="tool-connector">
    <div className="tool-connector-details">
      <div className="tool-connector-details-title">
        {TOOLS_ICONS[tool.toolID]}<span>{t('settings.sections.tools.' + tool.toolID + '.name')}</span>
      </div>
      <div className="tool-connector-details-desc">{t('settings.sections.tools.' + tool.toolID + '.desc')}</div>
    </div>
    <DoneButton label={t('settings.sections.tools.' + tool.toolID + '.connect')}
      name={"tool-connect" + tool.toolID}
      className="small grey"
      endIcon={<KeyboardArrowRight />}
      onClick={doConnect} />
  </div>
}
const TOOL_OPTIONS = {
  'zoom': (tool) => <ZoomToolOptions tool={tool} />
}
export function ZoomToolOptions({ tool }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const onChange = React.useCallback((e) => {
    const nv = e.target.checked;
    if (loading) return;
    setLoading(true);
    tool.settings.endcall_notification = nv;
    dispatch({
      type: ACTIONS.WORKER_PATCH_TOOL_SETTINGS,
      payload: {
        toolID: tool.toolID,
        setting: {
          name: 'endcall_notification',
          value: nv,
        }
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_PATCH_TOOL_SETTINGS_SUCCESS,
        rejectOn: ACTIONS.WORKER_PATCH_TOOL_SETTINGS_ERROR,
      }
    }).then(() => {
      // OK
    }).catch((err) => {
      console.log(err)
      // rollback
      tool.settings.endcall_notification = !nv;
    }).then(() => setLoading(false));
    // change setting
  }, [tool, loading]);
  return <div className="tool-options">
    <FormControlLabel
      value={tool.settings.endcall_notification}
      control={<Switch name={"endcall_notification"}
        data-testid="zoom-endcall"
        className="done-input" color="primary" checked={tool.settings.endcall_notification} onChange={onChange} />}
      label={t('settings.sections.tools.zoom.settings.endCallNotification')}
      labelPlacement="start"
    />
  </div>
}