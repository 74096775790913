/**
 * calculate how many events overlapp + get overlapping position
 * @param {string} id id of event
 * @param {moment} start
 * @param {moment} end
 * @param {Array} events
 */
export const getOverlappingEventsCount = (id, start, end, events) => {
  /* istanbul ignore if no events no work */
  if (!events || events.length === 0) return []; // at least one overlapping, the event himself

  let periods = [];
  events.map(evt => {
    // if overlap
    // note: must check max overlapping event count...
    if (
      evt.id !== id &&
      evt.start.unix() < end.unix() &&
      evt.end.unix() > start.unix()
    ) {
      // overlap
      // add a new period
      let np = {
        s: evt.start,
        e: evt.end,
        events: [evt]
      };
      // check if got any overlapping in known periods
      periods.map(p => {
        if (evt.start.unix() < p.e.unix() && evt.end.unix() > p.s.unix()) {
          // add event to the list
          addUniq(p.events, evt);
          addUniq(np.events, ...p.events);
        }
        return p;
      });
      periods.push(np);
    }
    return evt;
  });
  // return max count of events?
  let max = [];
  periods.map(p => {
    if (p.events.length > max.length) max = p.events;
    return p;
  });
  return max;
};

/**
 * utility add to list of overlapping events
 * ensure each event is added only once
 */
function addUniq(arr, ...evt) {
  evt.map(e => {
    if (!arr.find(a => a.id === e.id)) arr.push(e);
    return e;
  });
}