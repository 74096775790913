import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";// browser one
/**
 * @fileoverview utility: get timezones
 * as OFFSET: [ list of country/zones]
 */
const timeZones = moment.tz.names();
const zones = timeZones.map((name) => {
  // some rules:
  if (name.startsWith('Etc/')) return null;
  let cmps = name.split('/');
  if (cmps.length !== 2) return null;

  if (cmps[1].startsWith('GMT')) return null;
  return { value: name, name: name.replace(/_/g, ' '), tz: `GMT ${moment.tz(name).format('Z')}` };

}).filter((t) => t !== null);
// hack for moment: see https://momentjs.com/timezone/docs/#/using-timezones/formatting/
// fallback to Intl for localized time zone long name
// Need to update labels: zz => long name, Z => +XX
// ex: LLLL zz [GMT]Z => jeudi 19 août 2021 11:00 heure d’été d’Europe centrale GMT+02:00
moment.fn.zoneName = function () {
  return this._z ? this._z.name : this.zoneAbbr();
}
export default function getTimezones() { return zones };
export function tz_exists(tz) {
  return !!zones.find((z) => z.value === tz)
}