import * as Yup from 'yup';

const BOOKING_INFOS = Yup.object({
  name: Yup.string().required("schedulingPage.form.errors.nameRequired"),
  recaptcha: Yup.string().required("schedulingPage.form.errors.recaptchaRequired"),
  email: Yup.string().email("schedulingPage.form.errors.emailInvalid").required("schedulingPage.form.errors.emailRequired"),
  notes: Yup.string(),
  slot: Yup.object({
    start: Yup.date().required(),
    end: Yup.date().required()
  }).required('schedulingPage.form.errors.slotRequired'),
});

export default BOOKING_INFOS;