import { createActions, handleActions, combineActions } from 'redux-actions';
import { periodToSlots } from '../utils/period.to.slots';
import * as ACTIONS from '../actions'
import { v4 } from 'uuid';
import moment from 'moment';
import { createSelector } from 'reselect';
import { STATUS } from '../api/validators/proposal';
const defaultState = {};

const reducer = handleActions(
  {
    // when ask for proposal(s), clear data
    [ACTIONS.WORKER_GET_PROPOSAL]: (state, action)=>{
      if(action.payload.id) return {[action.payload.id]: {}};
      return {}; 
    },
    // if remove account, clear data
    [ACTIONS.WORKER_UNREGISTER_ACCOUNT_SUCCESS]: (state, action)=>{
      return {}; 
    },
    [ACTIONS.WORKER_GET_PROPOSAL_SUCCESS]: (state, action) => {
      console.log('ACTIONS.WORKER_GET_PROPOSAL_SUCCESS', action);
      if(action.request && action.request.id && state[action.request.id]){
        // add datas in state
        return {[action.request.id] : action.payload[0]};
      }
      return state;
    },
    [ACTIONS.WORKER_VOTE_PROPOSAL_SUCCESS]: (state, action) => {
      // save object with new state for slots?
      let proposal = (action.payload || {}).opts;
      // console.log('User had voted', action.payload, proposal, proposal.id, state[proposal.id])
      if (!proposal || !proposal.id || !state[proposal.id]) return state;

      // 2 cases: I hd made a lookup, then I have the latest version
      // or i don't, and I must complete by hand
      let p;
      if (action.proposal) {
        // just replace datas
        p = action.proposal;
      } else {
        // mark in details that I Had vote
        let st = state[proposal.id].inviteesDetails;
        /* istanbul ignore else no work */
        if (st) {
          // update status of user
          let att = st[proposal.email];
          if (att) att.response = 'yes'
        }
        p = {
          ...state[proposal.id],
          hadVoted: true,
        };
      }


      return {
        ...state,
        [proposal.id]: p
      }
    },
    [ACTIONS.WORKER_CANCEL_EVENT_SUCCESS]: (state, action) => {
      // save object with new state for slots?
      let proposal = action.payload;
      if (!proposal || !proposal.id || !state[proposal.id]) return state;
      let np = /*state[proposal.id] ||*/ proposal;
      np.status = STATUS.CANCELLED;
      return {
        ...state,
        [proposal.id]: np
      }
    },
    [ACTIONS.WORKER_CREATE_PROPOSAL_SUCCESS]: (state, action) => {
      // save object with new state for slots?
      let proposal = action.payload;
      if (!proposal || !proposal.id || !state[proposal.id]) return state;
      let np = /*state[proposal.id] ||*/ proposal;
      np.status = STATUS.CONFIRMED;
      return {
        ...state,
        [proposal.id]: np
      }
    },
    [ACTIONS.WORKER_LOGOUT]: () => defaultState,
    [ACTIONS.INVALID_CREDENTIALS]: () => defaultState, // in case?
  },
  defaultState
);

export function getProposal(id, fallback = false) {
  return (state) => {
    let p = state.currentProposal[id];
    if(!p || !p.id) p = state.proposals[id];
    if (p && p.id) {
      // check if I am host
      if (state.accounts && state.accounts.find((acc) => acc.id === p.calendar.accountId)) p.iAmHost = true;
    }
    /*if(!p){
      // generate a dummy proposal for test
      return dev_generateDummyProposal(id)
    }*/
    return p;
  }
}

export default reducer;