
import { STATUS } from "../api/validators/proposal";
export const REPONSE_STATUS = {
  yes: "yes",
  no: "no",
  maybe: "maybe"
};
export const EVENT_STATUS_WAITING = STATUS.PENDING,
  EVENT_STATUS_CONFIRMED = STATUS.CONFIRMED,
  EVENT_STATUS_CONFIRMED_NO = 'complete_with_no',
  EVENT_STATUS_WAITING_NO = 'sent_with_no',
  EVENT_STATUS_NO = STATUS.CANCELLED;
export function completeEventStatus(proposal) {
  if (!proposal || !proposal.iAmHost || !proposal.start) return false;
  let raw_details = proposal.raw_invitees_details || {};
  // event status:
  // everyone yes => confirmed
  // all no => cncelled
  // else sent
  let status = EVENT_STATUS_WAITING;
  let responses = {
    status: EVENT_STATUS_WAITING,
    count: (proposal.invitees || []).length,
    yes: 0,
    no: 0,
    maybe: 0,
    unknown: 0,
  };
  Object.keys(raw_details).forEach((inv) => {
    // chg: do not take optionals into account
    let details = (raw_details[inv] || {});
    if (details.optional) return;
    let r = (REPONSE_STATUS[details.response] ||
      "unknown");
    if (responses[r] !== undefined) responses[r] += 1;
  });
  // if all answered no => cancel
  status = responses.no === responses.count ? EVENT_STATUS_NO
    // if some no and 0 yes => waiting with no
    : responses.no > 0 && responses.yes === 0 ? EVENT_STATUS_WAITING_NO
      // if some no and some yes => complete no
      : responses.no > 0 && responses.yes > 0 ? EVENT_STATUS_CONFIRMED_NO
        // if 0 no and 1 yes
        : responses.no === 0 && responses.yes > 0 ? EVENT_STATUS_CONFIRMED
          // else
          : EVENT_STATUS_WAITING;
  if (proposal.status === STATUS.EVT_CANCELLED) status = EVENT_STATUS_NO;
  // special case: auto invite on link
  if (!proposal.invitees || proposal.invitees.length === 0) status = EVENT_STATUS_CONFIRMED;
  return status;
}