/* istanbul ignore file */
import * as Sentry from '@sentry/browser';
import { Integrations } from "@sentry/tracing";

import { isProd } from '../api/config';

if (isProd()) {
  Sentry.init({
    dsn: "https://3fc727293b414804a1673e1b43874a48@o546908.ingest.sentry.io/5668820",
    environment: 'production',
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'], // only for production
  });
} else if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://3fc727293b414804a1673e1b43874a48@o546908.ingest.sentry.io/5668820",
    environment: 'staging',
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}