import { IconButton, Popover } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { HelpOutline, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LMTooltip } from '../../../../tooltip/Tooltip';
import CalendarList from './CalendarList';
import { NoAccountForUser } from './NoAccountForUser';
import SelectedCalendar from './SelectedCalendar';

const POPPER_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'left',
};
const POPPER_TRANSFORM = {
  vertical: 'top',
  horizontal: 'left',
};

export default function CalendarSelectorRenderer({
  isValid,
  disabled,
  isAuth,
  handleClick,
  doSign,
  name,
  profile,
  calendar,
  account,
  anchorEl,
  handleClose,
  processItemClicked,
  selectedId,
}) {

  const { t } = useTranslation();

  return (
    <fieldset className={`done-input ${isValid ? 'done-input-valid' : 'Mui-error'} done-calendar-selector calendar-selector-simple ${disabled ? "disabled" : ""}`}>
      <legend className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined done-input-label">
        {t('createEvent.form.agenda.title')}
        <LMTooltip
          childrenClassName="showHand"
          content={
            <>
              <p><Trans i18nKey="createEvent.form.agenda.tooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
            </>
          }
        >
          <HelpOutline />
        </LMTooltip>
      </legend>
      {
        !isAuth ? (<div className="no-account-user">
          <Button variant="contained" className="white-btn" onClick={doSign}>{t('createEvent.form.agenda.connect')}</Button>
          <div className="no-account-text">{t('createEvent.form.agenda.noCalendarUser')}</div>
        </div>)
          : !isValid ? <NoAccountForUser /> : null
      }
      {
        isValid && <>
          <div className="selector" onClick={handleClick} data-testid={name}>
            <SelectedCalendar profile={profile} calendar={calendar} account={account} />
            {!disabled &&
              <IconButton >{
                anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />
              }</IconButton>
            }
          </div>
          {!!anchorEl && <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}
            anchorOrigin={POPPER_ORIGIN} transformOrigin={POPPER_TRANSFORM} className="done-input">
            <div>
              <CalendarList processItemClicked={processItemClicked} selectedId={selectedId} />
            </div>
          </Popover>}
        </>
      }
    </fieldset>);
}