import * as Yup from 'yup';
import {
  CALENDAR,
  EVENT_DURATION_MIN,
  EVENT_DURATION_MAX,
  EVENT_TITLE_MAX,
  EVENT_LOCATION_MAX
} from './event';
const SLOT = {
  start: Yup.date(),
  end: Yup.date(),
}
export const MAX_NOTICE_DURATION = 129600;
export const MAX_DEFAULT_CONFIG_DURATION = 1440;

export const BOOKING = Yup.object().shape({
  invitees: Yup.array().of(Yup.object().shape({
    email: Yup.string().required().email(),
    label: Yup.string().required(),
    note: Yup.string().required(),
  })).required(),
  slots: Yup.array().of(Yup.object().shape(SLOT)).required(),

});
const BH = Yup.object().shape({
  frames: Yup.array().of(Yup.object().shape({
    start: Yup.number().min(0).max(24),
    end: Yup.number().min(0).max(24),
  }))
})
export const BUSINESS_HOURS = Yup.object().shape({
  '1': BH,
  '2': BH,
  '3': BH,
  '4': BH,
  '5': BH,
  '6': BH,
  '7': BH,
})
export const LINK = Yup.object().shape({
  organizer: Yup.object({
    email: Yup.string().email().required()
  }).required(),
  title: Yup.string().max(EVENT_TITLE_MAX, 'createEvent.form.errors.titleTooLong').required('createEvent.form.errors.titleRequired'),
  slug: Yup.string().test('slug-validation', 'schedulingLink.form.errors.slugInvalid',
    function (value = "") {
      return /^[a-z0-9-_]{3,255}$/.test(value)
    }),
  timezone: Yup.string().required('schedulingLink.form.errors.timezonerequired'),
  duration: Yup.number(),
  customduration: Yup.number()
    .when('duration', {
      is: -1,
      then: Yup.number()
        .min(EVENT_DURATION_MIN, 'createEvent.form.errors.durationInvalid')
        .max(EVENT_DURATION_MAX, 'createEvent.form.errors.durationInvalid')
        .required(),
      otherwise: Yup.number().notRequired(),
    }),

  location: Yup.string().max(1024, 'createEvent.form.errors.locationTooLong'),
  conference: Yup.string(),
  note: Yup.string(),
  calendar: CALENDAR,
  business_hours: BUSINESS_HOURS.test('bh-validation', 'schedulingLink.form.errors.businessHourInvalid',
    function (value = {}) {
      // should have at least one set
      for (let k of Object.values(value)) {
        if (k && k.frames && k.frames.length > 0) return true;
      }
      return false;
    }),
  validity: Yup.object().shape({
    start: Yup.date(),
    duration: Yup.number().min(-1)
  }),
  configuration: Yup.object().shape({
    start_inc: Yup.number().min(0).max(MAX_DEFAULT_CONFIG_DURATION, 'schedulingLink.form.errors.startIncInvalid'),
    notice_duration: Yup.number().min(0).test('notice_duration_max', 'schedulingLink.form.errors.noticeInvalid', function (noticeDurationValue) {
      return (noticeDurationValue * this.parent.notice_multiplier) <= MAX_NOTICE_DURATION;
    }),
    before_duration: Yup.number().min(0).max(MAX_DEFAULT_CONFIG_DURATION),
    after_duration: Yup.number().min(0).max(MAX_DEFAULT_CONFIG_DURATION),
  }).required(),
});