// global __lm_anonymous_token

import 'core-js/actual';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './conf/i18n';
import './conf/sentry';
import './conf/firebase';
import './index.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import { DialogProvider } from './components/dialogs/dialog.provider';
import { TooltopProvider } from './components/dialogs/tooltop.provider';
import { Provider } from 'react-redux'
import {
  /*BrowserRouter as*/ Router,
} from "react-router-dom";
import DoneMaterialUITheme from './components/styles/done.ui.theme';
import { MuiThemeProvider } from '@material-ui/core';
import makeHistory from './history'
import configureStore from './configure.store';
import { ProvidersID } from './components/providers.id';
// load langs for moment
import { parseQuery } from './utils/parse.query';
import './conf/gtag'; // Analytics - gtag init
// import './conf/facebookpixel';
import { BROWSER } from './utils/browser';
import smoothscroll from 'smoothscroll-polyfill';

// for notistack: define for all to be consistent
// through the app
const anchorOrigin = {
  vertical: 'top',
  horizontal: 'center'
};
// kick off the polyfill!
smoothscroll.polyfill();
if (BROWSER.getBrowserName() === 'Internet Explorer') {
  document.getElementById('notsupported').classList.remove('invisible');
  document.getElementById('root').classList.add('invisible');
} else {

  const STATE = {
    register: ''
  };
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
  const query = parseQuery(window.location.search);
  if (query && query.status && query.status !== '200') {
    // add an error in state
    STATE.register = query.status;
  }

  const history = makeHistory();

  let token = undefined;
  try {
    // check if got a donetoken in hash
    const hash = parseQuery(window.location.hash);
    if (hash && hash.donetoken) {
      token = hash.donetoken;
      let email = hash.email || 'letsmeet-token-email'; // known accounts
      let provider = 'letsmeet-token';
      localStorage.setItem("done_token", JSON.stringify({ done: token }));
      localStorage.setItem('known_accounts', JSON.stringify([email]));
      let profileObj = { profileObj: { email } }; // google style
      localStorage.setItem('user_datas', JSON.stringify({ name: provider, all: profileObj }))
    } else if (window.__lm_anonymous_token) {
      console.log("Got LM Booking token")
      token = { done: window.__lm_anonymous_token }; // assume token is valid for booking?
    } else {
      token = localStorage.getItem('done_token');
      if (token) token = JSON.parse(token);
    }
    // check prefill info
    // http://localhost:3000/create-a-meeting/?prefill=455ef8786a7b46768d218bcdb89172e7&type=follow_up
    const params = parseQuery(window.location.search);
    if (params && params.prefill) {
      let prefill_from_id = params.prefill;
      let prefill_type = params.type;

      // save in LS? In case must reload
      try {
        localStorage.setItem("PREFILL_FROM_ID", prefill_from_id);
        localStorage.setItem("PREFILL_TYPE", prefill_type)
      } catch (err) { }
    }
  } catch (err) { }


  const store = configureStore(STATE);
  // get datas from LS

  store.dispatch({
    type: 'WORKER_INIT',
    payload: token
  })

  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <MuiThemeProvider theme={DoneMaterialUITheme}>
          <Elements stripe={stripePromise}>
            <SnackbarProvider maxSnack={3} hideIconVariant anchorOrigin={anchorOrigin}>
              <DialogProvider>
                  <Router history={history} >
                    <TooltopProvider>
                      <ProvidersID>
                        <App />
                      </ProvidersID>
                    </TooltopProvider>
                  </Router>
              </DialogProvider>
            </SnackbarProvider>
          </Elements>
        </MuiThemeProvider>
      </Suspense>
    </Provider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();

  // add our service worker for caching avatars
  if ('serviceWorker' in navigator) {
    /*window.addEventListener('load', function () {
      navigator.serviceWorker.register('/sw-2.js').then(function (registration) {
        // Registration was successful
        logger.silly('ServiceWorker registration successful with scope: ', registration);
      }, function (err) {
        // registration failed :(
        logger.silly('ServiceWorker registration failed: ', err);
      });
    });*/
  }

  // window.addEventListener('storage', () => {
  //   // another page acces my storage?
  //   // go back to meetings monster page
  //   window.location.assign(LANDING_URL);
  // })


}