// avatar/donchip size
export const AVATAR_BIG = 1;
export const AVATAR_SMALL = 2;
export const AVATAR_NORMAL = 0;
// event responses
export const MARK_YES = 'yes';
export const MARK_NO  ='no';
export const MARK_MAYBE = 'maybe';
export const MARK_LOADING = 'loading';
export const MARK_UNKNOWN = 'unknown';
// period unit for agenda
export const AGENDA_DISPLAY_WEEK = 'week';
export const AGENDA_DISPLAY_MONTH = 'month';
export const AGENDA_DISPLAY_DAY = 'day';
// breadcrumbs states
export const BREADCRUMB_STATE_INACTIVE = 0;
export const BREADCRUMB_STATE_ACTIVE = 2;
export const BREADCRUMB_STATE_DONE = 3;
