// global __lm_booking_link 
import { createBrowserHistory } from "history";
// import { DEEP_LINKS } from './routes/routes.name';
import { PAGE_VIEW_ROUTES } from "./routes/routes.name"
export default function makeHistory(pathname) {
  // check actual route and navigate
  // let url = pathname || window.location.pathname;

  // if we are on a link, do not add basename to the app
  const basname = window.__lm_booking_link ? undefined : { basename: 'app' }
  let history = createBrowserHistory(basname);
  if (history.listen) {
    // if(url && DEEP_LINKS[url]) history.push(url);
    history.listen((location) => {
      if (window &&
        window.analytics &&
        window.analytics.page &&
        PAGE_VIEW_ROUTES.find((r) => r === location.pathname)) window.analytics.page()
    })
  }
  return history;
}
