import { handleActions } from 'redux-actions';
import * as ACTIONS from '../actions';

const defaultState = {};

const reducer = handleActions(
  {
    [ACTIONS.ASK_4_LOGIN]: (state, action) => ({ ...state, ask4login: action.payload, redirectTo: action.redirectTo }),
    [ACTIONS.INVALID_CREDENTIALS]: (state, action) => ({ ...state, ask4login: 'modal' }),
    [ACTIONS.ON_NEW_CONTACTS]: (state, action)=>{
      if (action.continuate) return {...state, contacts_loading: true}
      else return {...state, contacts_loading: false}
    },
    [ACTIONS.WORKER_SET_TZ]:(state, action)=>{
      return {
        ...state,
        timezone: action.payload.timezone
      }
    } 
  },
  defaultState
);

/**
 * Get Theme for application
 * (no need for reselect as return a simple string)
 */
export function getTheme(state) {
  return state.app.theme || 'theme--light';
}
export function areContactsLoading(state){
  return state.app.contacts_loading;
}
export function getAppTZ(state) {
  return state.app.timezone;
}
export default reducer;