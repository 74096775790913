import { Switch as MuiSwitch } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './LMSwitch.scss';

export default function LMSwitch({
  labelWhenChecked, // string label when switch is checked
  labelWhenNotChecked, // string label when switch is not checked
  labelsVisible = false, // boolean true to show labels, false to hide them
  labelSamePlace = false,
  noMargin = false,
  ...props // all other props will be forwarded to the MUI Switch
}) {
  const { t } = useTranslation();
  labelWhenChecked = labelWhenChecked || t('common.yes')
  labelWhenNotChecked = labelWhenNotChecked || t('common.no');
  return (
    <div
      className={
        'LMSwitch '
        + (props.disabled ? ' disabled ' : '')
        + (props.size === 'small' ? ' small ' : '')
        + (noMargin ? ' no-margin ' : '')}>
      {labelsVisible && !labelSamePlace && <span className={'label left' + (props.checked ? ' invisible ' : '')}>{labelWhenNotChecked}</span>}
      <MuiSwitch color='primary' disableRipple data-testid={props.name} {...props} />
      {labelsVisible &&
        <div className='sameplace'>
          <span
            className={'right label' + (((props.checked || labelSamePlace) ? ' ' : ' invisible ') + ((props.checked ? '' : ' unchecked ')))}>
            {props.checked ? labelWhenChecked : labelWhenNotChecked}
          </span>
          {labelSamePlace && <span className='placeholder'>{labelWhenChecked.length > labelWhenNotChecked.length ? labelWhenChecked : labelWhenNotChecked}</span>}
        </div>
      }
    </div>
  );
}
export const Switch = LMSwitch;