import React from 'react';
import * as CONSTS from '../../../../consts';
import { useTranslation } from 'react-i18next';
import DoneChip from '../../../../donechip';
import '../../../../agenda/renderers/tooltip.scss';
import './busy.popup.scss';
import { Clear } from '@material-ui/icons';

const CLEAR_STYLE = { marginRight: "3px", fill: "red", width: "16px", height: "16px" }
export default function BusyPopup({
  invitees = {},
  position = 'top',
  organizer,
  calendar, // current calendar used
  inviteesList = [],
  oboCalendar
}) {
  const { t } = useTranslation();
  const fullInviteeList = React.useMemo(() => {
    if (!organizer || !organizer.email) return [];
    if (inviteesList.find((i) => i == organizer.email)) return inviteesList;
    return [organizer.email, ...inviteesList]
  }, [organizer, inviteesList]);
  /* istanbul ignore if */
  // get total of invitees
  let total = inviteesList.length + 1; // + organizer
  // get number of busys
  let busys = Object.keys(invitees).length;
  const clear = <Clear style={CLEAR_STYLE} />;
  const org = (calendar && calendar.email) ? calendar : organizer;

  return (
    <div className={"eventTooltippopup " + position}>
      {/* <i></i> */}
      <div className="tooltip-title">
        {t('createEvent.heatmap.slotBusy')}
      </div>
      <div className="tooltip-view fancy-scroll">
        {
          fullInviteeList.filter(invitee => !!invitees[invitee]).map((invitee, i) => {
            // if email is organizer and obo calendar, display obo datas
            if (invitee === organizer.email) {
              if (oboCalendar) {
                return <div className="busy-invitees" key={oboCalendar.name}>
                  {/* <DoneChip email={invitee} size={CONSTS.AVATAR_SMALL}  /> */}
                  {clear}
                  {oboCalendar.name}
                </div>
              } else {
                return <div className="busy-invitees" key={invitee}>
                  {/* <DoneChip email={invitee} size={CONSTS.AVATAR_SMALL}  /> */}
                  {clear}
                  {org.email}
                </div>
              }

            }
            // Am I in marks?
            // let isBusy = !!invitees[invitee] ? CONSTS.MARK_NO : CONSTS.MARK_YES;
            return <div className="busy-invitees" key={invitee}>
              {/* <DoneChip email={invitee} size={CONSTS.AVATAR_SMALL}  /> */}
              {clear}
              {invitee}
            </div>
          })
        }
      </div>

    </div>
  );
}