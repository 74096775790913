
export const DEFAULT_COLOR = "#00A2ED"; // a light blue
const RBG = /^#((([0-9a-fA-F]{2}){3,4})|([0-9a-fA-f]{3}))$/;
export default function transparentize(color = "", amount = 30) {
  if (!amount) amount = 30; // in percent
  let rgba = Math.round(0xFF * (amount / 100))
  rgba = Number(rgba).toString(16).padStart(2, '0')
  if (!color) return DEFAULT_COLOR + rgba;
  if (!RBG.test(color)) return DEFAULT_COLOR + rgba;

  if (!color || !RBG.test(color)) {
    return DEFAULT_COLOR + rgba;
  }

  color = color.substring(1); // remove '#'
  switch (color.length) {
    case 3: {
      return `#${color[0]}${color[0]}${color[1]}${color[1]}${color[2]}${color[2]}${rgba}`
    }
    case 6:
    case 8: {
      return `#${color[0]}${color[1]}${color[2]}${color[3]}${color[4]}${color[5]}${rgba}`
    }
    default:
      return DEFAULT_COLOR + rgba;
  }
}