import { GRID_HEIGHT } from '../components/agenda/config';
import moment from 'moment'
const DAY_NUMBER = 7;
const HOUR_UNIT = GRID_HEIGHT / 4;
const RELAX = 0 // for resize
const DAY_SIZE = 100 / 7;

export function getWholeProposalHeight(geometry, duration, from_bottom = false) {
  const min_height = duration * GRID_HEIGHT / 60; // height in px for 1 slot
  let height = Math.ceil(geometry.height / min_height);
  height = height * min_height

  let top = geometry.top;
  if (from_bottom) {
    // need to calculate top too
    // and ensure top + bottom is on lower quarter
    top = top - (height - geometry.height);
  }
  return { height, top };
}
/**
 * Calculate new offset for a D&D
 * @param {Object} current current object with geometry
 * @param {Moment} date selected date
 * @param {Number} duration duration of event
 * @param {Geometry} parent geometry of parent
 * @param {boolean} reverse start and end padding sens
 */
export function moveToOffset(current, date, duration = 60, parent, padStartToQuarter = false) {
  date = date || moment(); // we want a fresh moment
  if (!current || !parent) return false; // will make no event?
  // move only, do not change duration
  let period = {
    ...current,
    start: 0,
    end: 0,
    geometry: undefined
  }

  // problem, if left is string, then just add click on it? no dragging
  let { top, left, width, height, _originX, _originY } = current.geometry;
  if (typeof left === 'string') return false;

  let sof = date.clone().startOf('week');
  // let now = moment();

  // calcule new start position
  let dayUnit = parent.width / DAY_NUMBER;
  //let durationQuarters = Math.ceil(duration / 15); // convert duration to quarters
  let quarterUnit = GRID_HEIGHT / 4; // unit for a quarter
  let minutUnit = GRID_HEIGHT / 60;
  let origin = current._clickOrigin || { x: 20, y: 20 };


  let startQuarterOffset = Math.floor((top /*+ origin.y*/) / minutUnit/*quarterUnit*/); // number of quarters
  if (padStartToQuarter) {
    startQuarterOffset = Math.floor((top /*+ origin.y*/) / quarterUnit) * 15; // number of quarters
  }
  //let startDayOffset = Math.floor((left + origin.x) / dayUnit);
  // Due to JS way of dividing stuff, and width/unit having part of pixels,
  // pad startDay to nearest int
  let leftPos = left / dayUnit;
  let iLeftPos = Math.ceil(leftPos) - leftPos;
  let startDayOffset = iLeftPos < RELAX ? Math.ceil(leftPos) : Math.floor(leftPos);

  // get start end diff
  let endDayOffset = current.end.clone().diff(current.start, 'minutes') / 60;
  endDayOffset = Math.floor(endDayOffset / 24);


  // // get diff in minutes between start and end
  let diffOffset = getDiffInMinutes(current.start, current.end);
  let newEnd = sof.clone().add(startDayOffset, 'day').add(startQuarterOffset /** 15*/, 'minutes')
    .add(diffOffset /** 15*/, 'minutes')
  let endQuarterOffset = getMinutesFromSOD(newEnd) || 24 * 60;

  let padded = padStartEndToDay(startQuarterOffset, endQuarterOffset, diffOffset, startDayOffset, endDayOffset + startDayOffset);
  startQuarterOffset = padded.startQuarterOffset;
  endQuarterOffset = padded.endQuarterOffset;
  startDayOffset = padded.startDayOffset;
  endDayOffset = padded.endDayOffset;

  period.start = sof.clone().add(startDayOffset, 'day').add(startQuarterOffset /** 15*/, 'minutes');
  period.end = period.start.clone().add(endDayOffset - startDayOffset, 'day').add(diffOffset /** 15*/, 'minutes');
  // get offset diff too

  let sod = period.start.clone().startOf('day');
  let soff = getOffsetDiffInMin(sod, period.start)
  period.start = period.start.add(-soff, 'm');
  period.end = period.end.add(-soff, 'm')
  let ox = left;
  let oy = top;
  // calcul inverse, ie timestamp to coords, for geometry
  // create a dummy geometry here

  period.geometry = _calculateGeometry(period, sof, { ox, oy });
  if (period.geometry.height === 0) return null
  return period;
}
/**
 * Calculate new end offset for a resize
 * @param {Object} current current object with geometry
 * @param {Moment} date selected date
 * @param {Number} duration duration of event
 * @param {Geometry} parent geometry of parent
 */
export function resizeToOffset(current, date, duration, parent, heatmap_slots_only = false) {
  // convert top, left,.... to timestamps
  if (!current || !parent) return false; // will make no event?
  let period = {
    ...current,
    // start: 0,
    end: 0,
    geometry: undefined
  }
  let { top, left, _left, width, height, _originX, _originY, gbcr } = current.geometry;
  // if slot-flavour heatmap...
  if (heatmap_slots_only) {
    let recalculated = getWholeProposalHeight(current.geometry, duration);
    height = recalculated.height;
    top = recalculated.top;
  }


  let sof = date.clone().startOf('week');

  let dayUnit = parent.width / DAY_NUMBER;
  let durationQuarters = Math.ceil(duration / 15); // convert duration to quarters
  let quarterUnit = GRID_HEIGHT / 4; // unit for a quarter


  let startQuarterOffset = Math.floor(top / quarterUnit); // number of quarters
  // convert left to px
  let leftpx = _left * parent.width / 100;

  // Due to JS way of dividing stuff, and width/unit having part of pixels,
  // pad startDay to nearest int

  let endQuarterOffset = Math.ceil((top + height) / quarterUnit); // number of quarters
  // let endDayOffset = Math.floor((leftpx + width) / dayUnit);
  let leftPos = ((leftpx + width)) / dayUnit;
  let iLeftPos = leftPos - Math.floor(leftPos);
  // problem: if 0 length?
  let endDayOffset = (iLeftPos <= RELAX && width >= dayUnit) ? Math.floor(leftPos) - 1 : Math.floor(leftPos);
  // check endDayOffset is at least startDayOffset

  // console.log('INFOS', endDayOffset, width, dayUnit)


  // check that period by days are enought for an event (ie: duration)
  let padded = padStartEndToDay(startQuarterOffset, endQuarterOffset, durationQuarters);

  startQuarterOffset = padded.startQuarterOffset;
  endQuarterOffset = padded.endQuarterOffset;
  // console.log('End period', endQuarterOffset, endDayOffset)
  //period.start = sof.clone().add(startDayOffset, 'day').add(startQuarterOffset, 'h');
  period.end = sof.clone().add(endDayOffset, 'day').add(endQuarterOffset * 15, 'minutes');

  // if period end before start, return
  if (period.end <= period.start) return false;
  let ox = _originX || leftpx;
  let oy = _originY || top;


  // calcul inverse, ie timestamp to coords, for geometry
  period.geometry = _calculateGeometry(period, sof, { ox, oy });
  // console.log('coords to offset', period)
  return period;

}
// same as above, with top coord
export function resizeTopToOffset(current, date, duration, parent, heatmap_slots_only = false) {


  // convert top, left,.... to timestamps
  if (!current || !parent) return false; // will make no event?
  let period = {
    ...current,
    start: 0,
    geometry: undefined
  }
  let { top, left, _left, width, height, _originX, _originY, gbcr } = current.geometry;
  if (heatmap_slots_only) {
    let recalculated = getWholeProposalHeight(current.geometry, duration, true);
    height = recalculated.height;
    top = recalculated.top;
  }
  let sof = date.clone().startOf('week');

  let dayUnit = parent.width / DAY_NUMBER;
  let minutUnit = GRID_HEIGHT / 60;
  // calculate new start (only)
  let startQuarterOffset = Math.floor(top / minutUnit /*quarterUnit*/);
  let endQuarterOffset = Math.ceil((top + height) / minutUnit/*quarterUnit*/);
  let padded = padEndStartToDay(startQuarterOffset, endQuarterOffset, duration);// durationQuarters);

  startQuarterOffset = padded.startQuarterOffset;
  endQuarterOffset = padded.endQuarterOffset;
  let startOffset = startQuarterOffset; // * 15;

  // convert left to px
  let leftpx = _left * parent.width / 100;

  // Due to JS way of dividing stuff, and width/unit having part of pixels,
  // pad startDay to nearest int

  let leftPos = leftpx / dayUnit;
  let iLeftPos = leftPos - Math.floor(leftPos);
  // problem: if 0 length?
  let startDayOffset = Math.floor(leftPos); // (iLeftPos <= RELAX) ? Math.floor(leftPos) -1  :  Math.floor(leftPos);
  // check that period by days are enought for an event (ie: duration)

  period.start = sof.clone().add(startDayOffset, 'day').add(startOffset, 'minute');
  // if period end before start, return
  if (period.end <= period.start) {
    return false;
  }
  let ox = _originX || leftpx;
  let oy = _originY || top;
  // calcul inverse, ie timestamp to coords, for geometry
  period.geometry = _calculateGeometry(period, sof, { ox, oy });
  return period;

}
const DAY_APPROX = 0.001;
const QUARTER_APPROX = 0;
/**
 * get X,Y to timestamp?
 * @param {Object} current current event geometry {top,left,width,height,_originX,_originY}
 * @param {Moment} date selectedDate
 * @param {Number} duration event minimal duration
 * @param {Object} parent parent geometry for total dimensions
 * @param {boolean} reverse reverse padding to quarter for start and end
 */
export function getCoordsToOffset(current, date = moment(), duration = 60, parent, padToNearest = false, reverse = false, heatmap_slots_only = false) {

  let durationMinutes = duration;
  if (!current || !parent) return false; // will make no event?
  // convert top, left,.... to timestamps
  let period = {
    start: 0,
    end: 0,
    geometry: undefined
  }
  let { top, left, width, height, _originX, _originY } = current;
  if (heatmap_slots_only) {
    let recalculated = getWholeProposalHeight(current, duration);
    height = recalculated.height;
    top = recalculated.top;
  }


  let sof = date.clone().startOf('week');

  let dayUnit = parent.width / DAY_NUMBER; // unit for a day
  let minutUnit = GRID_HEIGHT / 60;

  // Must have a approx effect for unit? ---------------------------------------
  // if rest is less than XX, pad to precedent?
  let ed = top / minutUnit; //quarterUnit;
  let er = reverse ? Math.ceil(top / minutUnit/*quarterUnit*/) : Math.floor(top / minutUnit/*quarterUnit*/);
  if (padToNearest && ed - er > 1 - QUARTER_APPROX) er -= er % 15; // er += 1;
  let startQuarterOffset = Math.floor(er); // number of minutes
  let d = left / dayUnit;
  let r = Math.floor(left / dayUnit);

  if (d - r > 1 - DAY_APPROX) r += 1;
  let startDayOffset = r;


  // same for end
  // date calculus
  ed = (top + height) / minutUnit; // quarterUnit;
  er = reverse ? Math.floor((top + height) / minutUnit/*quarterUnit*/) : Math.ceil((top + height) / minutUnit/*quarterUnit*/);
  // Probleme, should not change from quarter...
  if ((er - ed) > QUARTER_APPROX && er > startQuarterOffset) er -= er % 15; // 1;

  let endQuarterOffset = er; // number of quarters
  d = (left + width) / dayUnit;
  r = Math.floor((left + width) / dayUnit);
  if (d - r < DAY_APPROX && r > startDayOffset) r -= 1;
  let endDayOffset = r;
  // check that period by days are enought for an event (ie: duration) ------------


  let padded = padStartEndToDay(startQuarterOffset, endQuarterOffset, durationMinutes, startDayOffset, endDayOffset);
  startQuarterOffset = padded.startQuarterOffset;
  endQuarterOffset = padded.endQuarterOffset;
  startDayOffset = padded.startDayOffset;
  endDayOffset = padded.endDayOffset;

  period.start = sof.clone().add(startDayOffset, 'day').add(startQuarterOffset /** 15*/, 'm');
  period.end = sof.clone().add(endDayOffset, 'day').add(endQuarterOffset /** 15*/, 'm');

  let sod = period.start.clone().startOf('day');
  let soff = getOffsetDiffInMin(sod, period.start)
  period.start = period.start.add(-soff, 'm');
  period.end = period.end.add(-soff, 'm')
  // const sod = sof.clone(); sod.add(startDayOffset, 'day');

  // let diffInMin = getOffsetDiffInMin(sod, period.start)
  // period.start.add(-diffInMin, 'm')
  // can happen if click in bad place
  if (period.start > period.end) return null;
  // need to set ORIGIN X&Y to left/top  -> BUG
  let ox = _originX || left;
  let oy = _originY || top;
  // calcul inverse, ie timestamp to coords, for geometry
  period.geometry = _calculateGeometry(period, sof, { ox, oy });
  if (period.geometry.height === 0) return null;
  return period;

}
/**
 * Ensure start and end are on same day
 * use Quarters to calculate (15 mins)
 * @param {*} startQuarterOffset 
 * @param {*} endQuarterOffset 
 * @param {*} duration 
 */
export function padStartEndToDay(startQuarterOffset, endQuarterOffset, duration, startDayOffset, endDayOffset) {
  // console.log('before',startQuarterOffset, endQuarterOffset, duration)
  const max = 24 * 60; // 24h * 4 quarters

  if ((endQuarterOffset - startQuarterOffset) < duration) {
    endQuarterOffset = Math.ceil(startQuarterOffset + duration);
    // @TODO: what if duration end up on other day? -------------------------------> !important
  }
  if (endQuarterOffset > max) {
    // must pad start
    endQuarterOffset = max;
    // startQuarterOffset = max - duration;
    if ((endQuarterOffset - startQuarterOffset) < duration) startQuarterOffset = max - duration;
  }
  // if end fall out window, repad
  //console.log('Padding to TODAY days', startDayOffset, endDayOffset)
  if ((startDayOffset + (endDayOffset - startDayOffset)) > 6) {
    // remove days
    startDayOffset = 6 - (endDayOffset - startDayOffset);
    endDayOffset = 6;
  }
  //console.log('after',startQuarterOffset, endQuarterOffset, duration, startDayOffset, endDayOffset)

  return { startQuarterOffset, endQuarterOffset, startDayOffset, endDayOffset }
}
export function padEndStartToDay(startQuarterOffset, endQuarterOffset, duration, startDayOffset, endDayOffset) {
  if ((endQuarterOffset - startQuarterOffset) < duration) {
    startQuarterOffset = Math.ceil(endQuarterOffset - duration);
    // @TODO: what if duration end up on other day? -------------------------------> !important
  }
  if (startQuarterOffset < 0) {
    // must pad start
    endQuarterOffset = duration;
    startQuarterOffset = 0;
  }
  return { startQuarterOffset, endQuarterOffset, startDayOffset, endDayOffset }
}
const DAY_MINUTES = 24 * 60;
// Probleme if more than 1 day (ex: minuit->minuit)
export function getDiff(start, end) {

  // problem, if start and end are not on same day due to offsets
  // Please keep this as a reminder
  // let sod = start.clone().startOf('day');
  // let eod = end.clone().startOf('day');
  // let sdiff = start.clone().diff(sod, 'minutes');
  // let ediff = end.clone().diff(eod, 'minutes') || 24 * 60;
  //return (ediff - sdiff);
  let diff = (end.clone().diff(start, 'minutes') % DAY_MINUTES) || 24 * 60;
  return diff;


}
export function getDiffInQuarter(start, end) {
  /*let sod = start.clone().startOf('day');
  let eod = end.clone().startOf('day');

  let sdiff = start.clone().diff(sod, 'minutes');
  let ediff = end.clone().diff(eod, 'minutes') || 24 * 60;*/

  return Math.ceil(getDiff(start, end) / 15);
}
export function getDiffInMinutes(start, end) {
  /*let sod = start.clone().startOf('day');
  let eod = end.clone().startOf('day');

  let sdiff = start.clone().diff(sod, 'minutes');
  let ediff = end.clone().diff(eod, 'minutes') || 24 * 60;*/

  return Math.ceil(getDiff(start, end));
}
export function getQuartersFromSOD(start) {
  let sod = start.clone().startOf('day');
  let sdiff = start.clone().diff(sod, 'minutes');
  return Math.floor(sdiff / 15)
}
export function getMinutesFromSOD(start) {
  let sod = start.clone().startOf('day');
  let sdiff = start.clone().diff(sod, 'minutes');
  return Math.floor(sdiff)
}
/**
 * Calculate event coords from timestamps
 * calculate by quarters
 * @param {*} period 
 * @param {*} sof 
 * @param {*} param2 
 */
export function _calculateGeometry(period, sof, { ox, oy }) {
  //let quarterUnit = GRID_HEIGHT / 4; // unit for a quarter
  let minutUnit = GRID_HEIGHT / 60; // unit for a minut

  let soffset = period.start.clone().diff(sof, 'day');

  // let eoffset = period.end.clone().diff(sof,'day') + 1; // because we want day to be green
  let eoffset = period.end.clone().diff(period.start, 'minutes') / 60;
  eoffset = Math.floor(eoffset / 24) + 1; // +1 because we want day to be green
  // calculate duration in quarters between start and end
  let sod = period.start.clone().startOf('day');
  let esod = period.end.clone().startOf('day');
  let soff = getOffsetDiffInMin(sod, period.start)

  // calculate diff in minutes
  let startDiff = Math.ceil(period.start.clone().diff(sod, 'minutes') /*/ 15*/);
  let endDiff = Math.ceil((period.end.clone().diff(esod, 'minutes') /*/ 15*/) || 24 * 60 /*/ 15*/);

  let _left = soffset * (100 / DAY_NUMBER);
  let left = (_left) + '%';
  // calculate top by quarters
  let top = (startDiff + soff) * minutUnit; // quarterUnit;
  let _width = (eoffset) * (100 / DAY_NUMBER);
  let width = (_width) + '%';

  let offsetDiff = getOffsetDiffInMin(period.start, period.end)

  let height = (endDiff - startDiff + offsetDiff) * minutUnit; // quarterUnit;
  return {
    left, top, width, height,
    _left, _width,
    _originX: ox, _originY: oy
  }
}
const IOTA = 0.01;
export function shiftLeft(x) {
  let shift = 1; // in pixels
  // add some margin for rounding calculs error
  if ((x + (IOTA / 10)) % DAY_SIZE < IOTA) {
    // first on line
    shift = 12;
  }
  return shift;
}

export function getOffsetDiffInMin(start, end) {
  let startoffset = start.utcOffset()
  let endoffset = end.utcOffset()
  return (endoffset - startoffset);
}