import React from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../../../api/validators/proposal';
import { useSelector } from 'react-redux';
import { getAccountMails } from '../../../../../reducers/accounts';
import NOOP from '../../../../../utils/noop';
import DoneButton from '../../../../DoneButton';
import "./user.vote.scss";

export default function UserVote({ proposal, showSlots = NOOP, loading }) {
  const { t } = useTranslation();
  const accountsEmails = useSelector(getAccountMails);

  if (!proposal) return null;

  const iAmHost = proposal.iAmHost;
  if (iAmHost || (proposal.status !== STATUS.PENDING)) return null;

  const hasAnswer = accountsEmails.find(userEmail => {
    return (proposal.inviteesDetails[userEmail] || {}).response === 'yes';
  });
  const voteRequired = accountsEmails.find(userEmail => {
    const dt = (proposal.inviteesDetails[userEmail] || {})
    return dt.vote_requested && dt.response !== 'yes';
  });

  const hasConflicts = accountsEmails.find(userEmail => {
    let u = (proposal.inviteesDetails[userEmail] || {});
    return (u.conflicting_slots && u.conflicting_slots.length > 0);
  });

  if (hasAnswer) {
    return null; // already answer do not show
  }

  if (!hasConflicts && !voteRequired) return null; // do not show
  let lbl = voteRequired ? 'proposal.timeline.uservote.waitingforyou_requested' : 'proposal.timeline.uservote.waitingforyou_set'
  return (<div className="timeline-item uservote autoresponse active">
    <span className="title">{t('proposal.timeline.uservote.waitingforyou')}</span>
    <span className="title-more">{t(lbl)}</span>
    <DoneButton name="btn-edit" disabled={loading} className="blue-outlined" onClick={(e) => showSlots(true, true)} label={t('proposal.timeline.uservote.set')} />
  </div>);
}