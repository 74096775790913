import { STATUS } from "../api/validators/proposal";
export function conferenceUrlCompat(proposal) {
  const res = {
    conference_url: proposal.conference_url || "",
    location_txt: proposal.location || "",
  };
  let curl = proposal.conference_url || "";
  let ltxt = proposal.location || "";
  if (proposal.status === STATUS.CONFIRMED && proposal.conference) {
    // check if old proposal, ie, location got the "url"
    if (curl === "" && ltxt.startsWith("https://")) {
      res.conference_url = ltxt;
      res.location_txt = "";
    }
  }
  return res;
}