import React from 'react';
import { ReactComponent as GoogleIcon } from './google.svg';
import { ReactComponent as MicrosoftIcon } from './microsoft.svg';
import { ReactComponent as OfficeIcon } from './office-365.svg';
/**
 * @fileoverview Icons for app as React components
 * @module assets
 */
export { ReactComponent as SettingsIco } from './picto_tab_settings.svg';
export { ReactComponent as MailIcon } from './picto_mail.svg';
export { ReactComponent as MailSuccessIcon } from './picto_mail_success.svg';
export { ReactComponent as IconCheck } from './picto_check.svg';
export { ReactComponent as IconDelete } from './picto_delete.svg';
export { ReactComponent as LogoMM } from './logo.svg';
export { ReactComponent as PotatoeIllus } from './illus_welcome.svg';
export { ReactComponent as LogoDone } from './logo_mm.svg';
export { ReactComponent as SuccessIllus } from './success.illus.svg';
export { ReactComponent as SuccessIllusBouchon } from './success.illus.bouchon.svg';
export { ReactComponent as SuccessFlow } from './success-flow.svg';
export { ReactComponent as IllusDelete } from './illus_delete.svg';
export { ReactComponent as IllusOK } from './img_ok.svg';
export { ReactComponent as IllusCancel } from './img_empty.svg';
export { ReactComponent as Logout } from './logout.svg';
export { ReactComponent as Empty } from './empty.svg';
export { ReactComponent as HasNotVoted } from './has-not-voted.svg';
export { ReactComponent as ArrowRight } from './arrow-right.svg';
export { ReactComponent as LetsmeetSignature } from './letsmeet-signature.svg';
export { ReactComponent as LetsmeetSignatureLight } from './letsmeet-signature-light.svg';
export { ReactComponent as NotAvailable } from './not-available.svg';
export { ReactComponent as CannotVote } from './cannot-vote.svg';
export { ReactComponent as Spinner } from './spinner.svg';

export { ReactComponent as Copy } from './picto.copy.svg';
export { ReactComponent as Close } from './close.svg';
export { ReactComponent as Help } from './question-mark.svg';
export { ReactComponent as GmailIcon } from './gmail.svg';
export { ReactComponent as GmailWelcomeIcon } from './gmail-welcome.svg';

export { ReactComponent as AppleIcon } from './google.svg';
export { ReactComponent as DownloadOnTheAppStore } from './Download_on_the_App_Store_Badge_US.svg';
export { ReactComponent as MsIcon } from './microsoft.svg';
export { ReactComponent as OutlookMailIcon } from './outlook.svg';
export { ReactComponent as PermissionsIcon } from './outlook.svg';
export { ReactComponent as OutlookIcon } from './outlook-2021.svg';
export { ReactComponent as ZoomIcon } from './zoom.svg';
export { ReactComponent as MeetIcon } from './meet.svg';
export { ReactComponent as TeamsIcon } from './teams.svg';

export { ReactComponent as StopIcon } from './stop.svg';

export { ReactComponent as StatusCancelledIcon } from './cancelled.svg';
export { ReactComponent as StatusNoAvailIcon } from './not_available.svg';
export { ReactComponent as StatusSuccessIcon } from './success.svg';
export { ReactComponent as StatusMaybeIcon } from './success-no.svg';
export { ReactComponent as StatusSuccessNoIcon } from './success-with-no.svg';
export { ReactComponent as StatusPendingIcon } from './waiting.svg';
export { ReactComponent as StatusPendingNoIcon } from './waiting-with-no.svg';
export { ReactComponent as StatusPollIcon } from './poll.svg';

export { ReactComponent as Trash } from './Trash.svg';
export { ReactComponent as IllusFunnel } from './Illustration Funnel Source.svg';
export { ReactComponent as IllusPoll } from './Illustration Poll Source.svg';

export { ReactComponent as FileCSV } from './CircleFileCsv.svg';
export { ReactComponent as FunnelLogo } from './CircleFileCsv.svg';
export { ReactComponent as PollLogo } from './CircleFileCsv.svg';

export { ReactComponent as RocketIcon } from './Icon Rocket.svg';
export { ReactComponent as PollCardIcon } from './poll_card.svg';
export { ReactComponent as FunnelCardIcon } from './funnel_card.svg';
export { ReactComponent as CoffeeIcon } from './coffee.svg';
export { ReactComponent as EnlargeIcon } from './enlarge.svg';
export { ReactComponent as ClockSetTime } from './ClockSetTime.svg';
export { ReactComponent as LinkNote } from './Note.svg';
export { ReactComponent as MapPineLine } from './MapPinLine.svg';
export { ReactComponent as CalendarBlank } from './CalendarBlank.svg';
export { ReactComponent as DonerBadge } from './Badge.svg';
export { ReactComponent as Envelope } from './Envelope.svg';
export { ReactComponent as CalendarCheck } from './CalendarCheck.svg';
export { ReactComponent as Link } from './Link.svg';

export { ReactComponent as UsedByCitrix } from './usedby_citrix.svg';
export { ReactComponent as UsedByAdobe } from './usedby_adobe.svg';
export { ReactComponent as UsedBySalesforce } from './usedby_salesforce.svg';
export { ReactComponent as UsedByTwilio } from './usedby_twilio.svg';
export { ReactComponent as UsedByZendesk } from './usedby_zendesk.svg';

export { ReactComponent as IllusTwoHands } from './LesGantsMappa.svg';

export const PROVIDERS = {
  'google': <GoogleIcon />,
  'microsoft': <MicrosoftIcon />,
  'office365': <OfficeIcon />,
  'outlook': <MicrosoftIcon />, // <OutlookIcon />
}