import React from 'react';
import { useTranslation } from 'react-i18next';
import NOOP from '../../../../utils/noop';
import './period.renderer.v2.scss';
import { IllusFunnel, IllusPoll } from '../../../../assets/icons';
import {
  SendInvites,
  NoResults,
  currentStateOfProposal,
  FunnelTips
} from './period.renderer.202212';
import { ChevronRight } from '@material-ui/icons';
import CTA from './cta.js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './period.renderer.202307.scss';
import { LinearProgress } from '@material-ui/core';
const TITLES = [
  'createEvent.form.periods.titles.noResultsNew',
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];
const RENDERERS = [
  NoResults,
  PollOrFunnel,
  PollOnly,
  SendInvites,
  OnlyOneGuestToVoteNew,
  OnlyOneGuestToVoteNew
]

export function PollOrFunnel({
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  const { t } = useTranslation();
  const [pollOrFunnel, setPollOrFunnel] = React.useState('funnel');
  const doHandleSubmit = React.useCallback(() => {
    handleSubmit({ forcePoll: pollOrFunnel === 'poll' })
  }, [handleSubmit, pollOrFunnel])

  if (!slots || !formDatas) return null;

  return <>
    <div className="form-item-titles">{t('createEvent.form.titles.how')}</div>
    <div className="period-cta poll-or-funnel">
      <RadioGroup aria-label="pollOrFunnel" name="pollOrFunnel" value={pollOrFunnel} onChange={(e) => setPollOrFunnel(e.target.value)}>
        <CTAOptions recommended={true} title={'createEvent.form.periods.funnel.titleSimple'}
          value="funnel"
          subtitle={'createEvent.form.periods.funnel.subtitle'}
          description={'createEvent.form.periods.funnel.description'}
          ctaLabel={'createEvent.form.periods.funnel.cta'}
          illus={<IllusFunnel />}
          ctaclassName="funnel-block"
          isGeneratingLink={isGeneratingLink && pollOrFunnel === 'funnel'}
          freeUserExpired={freeUserExpired} />
        <CTAOptions title={'createEvent.form.periods.poll.titleSimple'}
          value="poll"
          subtitle={'createEvent.form.periods.poll.subtitle'}
          description={'createEvent.form.periods.poll.description'}
          ctaLabel={'createEvent.form.periods.poll.cta'}
          illus={<IllusPoll />}
          ctaclassName="poll"
          className="poller"
          isGeneratingLink={isGeneratingLink && pollOrFunnel === 'poll'}
          handleSubmit={isGeneratingLink ? NOOP : doHandleSubmit}
          freeUserExpired={freeUserExpired}
        />
      </RadioGroup>
      <div className={"period-cta "}>
        {!freeUserExpired &&
          <CTA label={"createEvent.form.sendInvitation"}
            disabled={handleSubmit !== undefined}
            handleSubmit={doHandleSubmit}
            canSubmit={!isGeneratingLink}
            isGeneratingLink={isGeneratingLink}
            endIcon={<ChevronRight />} />}
      </div >
    </div>
  </>;
}


export function PollOnly({ slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  const { t } = useTranslation();
  const doHandlePollSubmit = React.useCallback(() => {
    handleSubmit({ forcePoll: true })
  }, [handleSubmit])
  const [pollOrFunnel, setPollOrFunnel] = React.useState('poll');
  if (!slots || !formDatas) return null;

  return <>
    <div className="form-item-titles">{t('createEvent.form.titles.how')}</div>
    <div className="period-cta poll-or-funnel">
      <RadioGroup aria-label="pollOrFunnel" name="pollOrFunnel" value={pollOrFunnel} onChange={NOOP}>
        <CTAOptions title={'createEvent.form.periods.funnel.titleSimple'}
          subtitle={'createEvent.form.periods.funnel.subtitle'}
          description={'createEvent.form.periods.cta.sendLetsmeetPollDesc'}
          ctaLabel={'createEvent.form.periods.funnel.cta'}
          illus={<IllusFunnel />}
          isGeneratingLink={false}
          freeUserExpired={freeUserExpired}
          className='inert'
          handleSubmit={NOOP}
          ctaclassName="funnel-block"
          value="funnel" />
        <CTAOptions recommended={true}
          tips={<FunnelTips slots={slots} pollInfo={pollInfo} onClick={showHeatMap} />}
          title={'createEvent.form.periods.poll.titleSimple'}
          subtitle={'createEvent.form.periods.poll.subtitle'}
          description={'createEvent.form.periods.cta.sendClassicPollDesc'}
          ctaLabel={'createEvent.form.periods.poll.cta'}
          illus={<IllusPoll />}
          className="poller"
          ctaclassName="poll"
          isGeneratingLink={isGeneratingLink}
          handleSubmit={doHandlePollSubmit}
          freeUserExpired={freeUserExpired}
          value="poll"
        />
      </RadioGroup>
    </div>
    <div className={"period-cta "}>
      {!freeUserExpired &&
        <CTA label={"createEvent.form.sendInvitation"}
          disabled={handleSubmit !== undefined}
          handleSubmit={doHandlePollSubmit}
          canSubmit={!isGeneratingLink}
          isGeneratingLink={isGeneratingLink}
          endIcon={<ChevronRight />} />}
    </div >
  </>;
}


export function CTAOptions({
  value,
  recommended = false,
  tips = null,
  title, subtitle, description, illus, ctaLabel,
  className = "",
  ctaclassName = "",
  isGeneratingLink,
  freeUserExpired,
  handleSubmit,
}) {
  const { t } = useTranslation();
  return <div className={"cta-poll " + className + (recommended ? " recommended " : "")}>
    {recommended && (tips || <div className="ribbon">{t('createEvent.form.periods.recommended')}</div>)}
    <div className="cta-title done-input"><FormControlLabel value={value} control={<Radio data-testid={"rd-" + value} />} label={t(title)} /></div>
    <div className="cta-subtitle">{t(subtitle)}</div>
    <div className="cta-desc">{t(description)}</div>
    <div className={"cta-illus " + ctaclassName}>{illus}</div>
  </div>
}


export function PeriodRenderer({
  loading = false,
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview,
  ctaLabel
}) {
  const { t } = useTranslation();
  // compute labels and informations
  const currentState = currentStateOfProposal(formDatas, slots, pollInfo, contacts);
  const resultRef = React.useRef();
  const [resultCmpHeight, setResultCmpHeight] = React.useState({ height: 'auto' })
  React.useLayoutEffect(() => {
    if (!loading && resultRef.current) {
      // get height of ref
      let cmp = resultRef.current;
      setResultCmpHeight({ height: cmp.getBoundingClientRect().height })
    }
  }, [loading, resultRef])
  let Content = RENDERERS[currentState];
  if (loading) {
    return (<div className="period-v2 period-202307" style={resultCmpHeight}>
    <div className="progress-label">{t('createEvent.form.recap.loading')}</div>
    <LinearProgress />
  </div>);
  }
  return <div className="period-v2 period-202307" ref={resultRef} data-testid="multi-slots">
    {TITLES[currentState] && <div className="period-title">{t(TITLES[currentState])}</div>}
    <Content formDatas={formDatas} slots={slots} handleSubmit={handleSubmit} freeUserExpired={freeUserExpired} isGeneratingLink={isGeneratingLink}
      showcustomSlotsDlg={showcustomSlotsDlg} showHeatMap={showHeatMap} onChange={onChange} disableSlots={disableSlots}
      showPreview={showPreview} contacts={contacts} pollInfo={pollInfo} />
  </div>;
}

function OnlyOneGuestToVoteNew({ slots = {},
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  if (!slots || !formDatas) return null;

  return <>
    <div className="period-cta">
      {!freeUserExpired &&
        <CTA label={"createEvent.form.sendInvitation"}
          handleSubmit={() => handleSubmit()}
          canSubmit={!isGeneratingLink}
          isGeneratingLink={isGeneratingLink}
          endIcon={<ChevronRight />} />}
    </div >
  </>;
}