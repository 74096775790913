import { Checkbox } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './GuestConsent.scss';

// component allowing guest to consent to subscribing to newsletter
export default function GuestConsent({
  ...props // props are forwarded to MUI Checkbox component
}) {
  const { t } = useTranslation();

  return (
    <div className='GuestConsent-container'>
      <Checkbox
        {...props}
        className='done-input'
        data-testid='GuestConsent-checkbox' />
      <div>
        {t('guestConsentCheckbox.title')}
      </div>
    </div>
  );
}