import React from 'react';
import { useLocation } from 'react-router-dom';
import { parseQuery } from '../utils/parse.query';
export default function useBravo(){
  const location = useLocation()
  const [successfullSubscription, setsuccessfullSubscription] = React.useState(false);
  React.useEffect(()=>{
      // get url and check status
      const query = parseQuery(location.search);
      setsuccessfullSubscription(query && query.subscribe_status && query.subscribe_status === "success");
    }, []);
  return successfullSubscription;
}