import React from 'react';
import { useTranslation } from 'react-i18next';
import './SettingsPage.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import routes from '../../../routes/routes.name';
import Privacy from './privacy/Privacy';
import Accounts from './accounts/Accounts';
import WorkingHours from './calendars/Calendars';
import Invite from './invite';
import Billing from './billing';
import Tools from './tools'
import i18next from 'i18next';
import { Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import DevSettings from './dev/DevSettings';
import Advanced from './advanced'
import Company from './company'

import { useSelector } from 'react-redux';
import { getProfile } from '../../../reducers/profile';
import { isDevSettingsEnabled } from '../../../hooks/use.dev.settings';

const SETTINGS = {
  ACCOUNTS: {
    labelKey: 'accounts',
    routeKey: routes.APP_SETTINGS_ACCOUNTS,
    component: <Accounts />,
  },
  WORKING_HOURS: {
    labelKey: 'calendars',
    routeKey: routes.APP_SETTINGS_WH,
    component: <WorkingHours />,
  },
  TOOLS: {
    labelKey: 'tools',
    routeKey: routes.APP_SETTINGS_TOOLS,
    component: <Tools />
  },
  PRIVACY: {
    labelKey: 'privacy',
    routeKey: routes.APP_SETTINGS_PRIVACY,
    component: <Privacy />,
  },
  INVITE: {
    labelKey: 'invite',
    routeKey: routes.APP_SETTINGS_INVITE,
    component: <Invite />,
  },

  ADVANCED: {
    labelKey: 'advanced',
    routeKey: routes.APP_SETTINGS_ADVANCED,
    component: <Advanced />,
  },
  BILLING: {
    labelKey: 'billing',
    routeKey: routes.APP_SETTINGS_BILLING,
    component: <Billing />,
  },
  // depending
  COMPANY: {
    labelKey: 'company',
    routeKey: routes.APP_SETTINGS_PERSO,
    component: <Company />,
  },
  DEV: isDevSettingsEnabled() ? {
    labelKey: 'dev',
    routeKey: routes.APP_SETTINGS_DEV,
    component: <DevSettings />,
  } : undefined,
}

export default function SettingsPage({ id }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const activeSectionKey = Object.keys(SETTINGS).find(key => key === id) || SETTINGS["ACCOUNTS"];
  const activeSection = SETTINGS[activeSectionKey];
  const profile = useSelector(getProfile);
  const isAdmin = (profile.billing && profile.billing.customer_id && profile.billing.is_admin)
  let location = useLocation();
  let history = useHistory();

  let activeSectionTitle = t('settings.sections.' + activeSection.labelKey + '.title');
  const activeSectionSubTitle = t('settings.sections.' + activeSection.labelKey + '.subtitle');

  const showSectionSelector = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  if (!activeSection) return null;

  const sectionSelected = (section) => {
    history.push(section.routeKey);
    setAnchorEl(null);
  }
  return (
    <div className="settings-page-container">
      <div className="header">

        <div className="section-selector">
          <div
            className="selected"
            onClick={showSectionSelector}>
            <div className="title">{activeSectionTitle}</div>
            <div className="subtitle">{activeSectionSubTitle}</div>
          </div>
          {anchorEl && <Menu
            id="sections-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            className="section-selector-menu"
          >
            {Object.values(SETTINGS).map(settingsSection => {
              if (settingsSection) {
                if (!isAdmin && settingsSection.labelKey === "company") return null; // no display
                return (
                  <MenuItem
                    key={settingsSection.labelKey}
                    onClick={() => sectionSelected(settingsSection)}>
                    <div className="title">{t('settings.sections.' + settingsSection.labelKey + '.title')}</div>
                    <div className="subtitle">{t('settings.sections.' + settingsSection.labelKey + '.subtitle')}</div>
                  </MenuItem>
                );
              } else return null;
            })}
          </Menu>}
          <KeyboardArrowDown />
        </div>
      </div>
      <div className="settings-page  fancy-scroll">
        <div>
          <div className="sections-list">
            {
              Object.values(SETTINGS).map(settingsSection => {
                if (settingsSection) {
                  if (!isAdmin && settingsSection.labelKey === "company") return null; // no display
                  const isActive = settingsSection.routeKey === location.pathname;
                  let title = t('settings.sections.' + settingsSection.labelKey + '.title');
                  const subTitleKey = 'settings.sections.' + settingsSection.labelKey + '.subtitle';
                  let subtitle = i18next.exists(subTitleKey) && t(subTitleKey);
                  return (
                    <Link key={settingsSection.labelKey} className={"section" + (isActive ? " active" : "")} to={settingsSection.routeKey}>
                      <div className="title">{title}</div>
                      {subtitle && <div className="subtitle">{subtitle}</div>}
                    </Link>
                  );
                } else {
                  return null;
                }
              })
            }
            <div className="version">Version {process.env.REACT_APP_VERSION}</div>
          </div>
          <div className="section-content">{activeSection && activeSection.component}</div>
        </div>
      </div>
    </div>
  );
}