import en from './en.json';
import es from './sp.json';
import fr from './fr.json';
import it from './it.json';

export default {
    en: { all: en },
    es: { all: es },
    fr: { all: fr },
    it: { all: it },
}