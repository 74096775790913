/* istanbul ignore file */
// Don't know test and iframe....
import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import "./onlinehelp.scss";
export default function OnlineHelp({doClose}){
  const {t} = useTranslation();
  return <div className="onlinehelp-frame">
    <iframe className="onlinehelp-iframe" src={t('menu.onlineTutorialLink')}></iframe>
    <IconButton className="close-btn" onClick={doClose}><Close/></IconButton>
  </div>
}