import React from 'react';
import Grid from './Grid';
import Busy, { placePoppup } from './Busy';
import BusyPopup from './Busy.popup';
import CalEvents from './events';
import Hour from './Hour';
import Drawing from './Drawing';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getWorkingHourStartForToday } from '../../../../../reducers/profile';
import { someInviteesAreNotLMUsers } from '../../../../../reducers/contacts';
import { useTranslation } from 'react-i18next';
import { moveToOffset } from '../../../../../utils/to.offset';
import { isValid } from '../../../../../utils/heatmap/is.slot.valid';
import NOOP from '../../../../../utils/noop';
import { getOverlappBusysGuests } from '../../../../../worker/handlers/generate.slots';
import './heatmap.scss';
import {
  GRID_HEIGHT
} from '../../../../agenda/config';
let scrollID = 0;
const EMPTY = [];
const NO_DATA = {};
export default function HeatMap({
  selectedDate, // actual selected date
  busy = {}, // Busy time datas
  periods = EMPTY, // user selected periods
  calendarEvents = EMPTY, // user events in it's calendars
  onChange,
  onRemoveProposal,
  onUpdateProposal,
  duration, title,
  organizer, invitees,
  oboaccounts,
  calendar,
  ignoreInitialAvail,
  heatmap_slots_only = false,
  inviteesDetails = NO_DATA,
  setHoveredDate, // used to transmit hovered date to parent
  allMultipleSlots = true,
  increment = -1, // drag and resize incrment
  inForm,
}) {

  const scroller = React.useRef();
  const touchHandler = React.useRef();
  const wh_day = useSelector(getWorkingHourStartForToday);
  // 
  // const __allMultipleSlots = useSelector(someInviteesAreNotLMUsers(invitees))
  // const allMultipleSlots = React.useMemo(() => {
  //   // check if some are forbiddens?
  //   return __allMultipleSlots || !!Object.values(inviteesDetails).find((i) => i.isForbidden);
  // }, [__allMultipleSlots, inviteesDetails])
  // const allMultipleSlots = true;
  const [startDrawing, setStartDrawing] = React.useState(false);
  const [autoScroll, setAutoScroll] = React.useState(false);
  const [currentShadow, __setCurrentShadow] = React.useState();
  const { t } = useTranslation();
  const setCurrentShadow = React.useCallback((cs) => {
    // check if valid
    if (cs && cs.end) {
      cs._is_shadow_valid = isValid(cs, duration);
      cs._is_shadow_in_past = !cs._is_shadow_valid;
      cs._duration = duration;
    }
    // if overlapp a busy
    if (cs && cs.start && cs.end) {
      const busyUsers = getOverlappBusysGuests(cs, busy);
      const isOverlapping = busyUsers.length > 0;
      if (!ignoreInitialAvail) {
        cs._is_shadow_valid = !isOverlapping;
      }
      cs._is_shadow_overlapping = isOverlapping;

      if (isOverlapping) {
        cs.busyUsers = busyUsers.reduce((acc, b) => {
          acc[b] = { email: b };
          return acc;
        }, {});
        cs.popup = placePoppup(cs)
      }
    }
    __setCurrentShadow(cs);
  }, [__setCurrentShadow, duration, busy, ignoreInitialAvail]);
  const [isAtTop, setIsAtTop] = React.useState(false);
  // const [lastscrollTop, setLastScrollTop] = React.useState(0);
  const [scrollTop, setScrollTop] = React.useState(0);
  /* istanbul ignore next cannot test */
  const autoScrollTop = React.useCallback((e) => {
    if (autoScroll && scroller && scroller.current) {
      // start scrolling
      // setScrollTop(0); // reinit offset
      scrollID = setInterval(() => {
        scroller.current.scrollTop = scroller.current.scrollTop - 2;
        setScrollTop(scroller.current.scrollTop);
      }, 12);
    }
  }, [scroller, autoScroll, setScrollTop, scrollTop]);
  /* istanbul ignore next cannot test */
  const autoScrollBottom = React.useCallback((e) => {
    if (autoScroll && scroller && scroller.current) {
      // start scrolling
      scrollID = setInterval(() => {
        scroller.current.scrollTop = scroller.current.scrollTop + 2;
        setScrollTop(scroller.current.scrollTop + scroller.current.getBoundingClientRect().height - 50)
      }, 12);
    }
  }, [scroller, autoScroll, setScrollTop])
  /* istanbul ignore next not testable */
  const autoScrollStop = React.useCallback((e) => {
    if (scrollID) clearInterval(scrollID);
    setScrollTop(0);
  }, [setScrollTop])
  const mouseDown = React.useCallback((e) => {
    // check if click on a already created timeframe
    // As I think allowing actions on disabled time is totally dumb,
    // I keep the code commented.
    /*if (e.target.id && e.target.id.startsWith('hours'))*/
    if (!touchHandler.current) return;
    let { clientX, clientY, target } = e;
    let { top, left } = touchHandler.current.getBoundingClientRect();
    // problem, inner drawing can have a padding on full screen
    // send back only the informations, calculus will be done on drawing component?
    // pad start to nearest quarter?
    let position = {
      // click position
      _originY: clientY - top,
      _originX: clientX - left,

      top: clientY - top,
      left: clientX - left,
      width: 1,
      height: 1,
    }
    setStartDrawing(position);
  }, [touchHandler]);
  const mouseUp = React.useCallback(() => setStartDrawing(false), []);
  const updateHoveredDate = React.useCallback((now) => {
    setHoveredDate(now);
  }, [setHoveredDate]);
  const moveShadow = React.useCallback((e) => {
    if (startDrawing) {
      setCurrentShadow(null);
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    if (!touchHandler.current) return;
    let hack = 45; // between full screen and md screen
    if (document.body.offsetWidth < 991) hack = 0;

    let { clientX, clientY } = e;
    let devt = {
      clientX,
      clientY,
      target: touchHandler.current
    }
    let { top, left } = devt.target.getBoundingClientRect();
    // note: depending on view full screen on small screen  !importnt
    // problem: heatmap origin diff with content...
    let position = {
      // click position
      _originY: clientY - top,
      _originX: clientX - left - hack,

      top: clientY - top,
      left: clientX - left - hack,
      width: 1,
      height: 1,
    }
    // calculate final place and set shadow
    let d = position; // getNewDimensionsHacked({ left: clientX, top: clientY }, position);

    let evt = {
      start: moment(),
      end: moment().add(duration, 'minutes'),
      geometry: d
    }
    let targetBBox = devt.target.getBoundingClientRect();
    targetBBox.width -= hack;
    let offset = moveToOffset(evt, selectedDate, duration, targetBBox, true);
    updateHoveredDate(offset && offset.start); // transmit date to parent
    setCurrentShadow(offset);
  }, [startDrawing, touchHandler, setCurrentShadow, updateHoveredDate]);
  const clearShadow = React.useCallback(() => {
    updateHoveredDate(undefined); // transmit date to parent
    __setCurrentShadow(null);
  }, [__setCurrentShadow, updateHoveredDate])
  const onProposalDoAction = React.useCallback((v) => {
    if (v) __setCurrentShadow(false)
  })
  // on first render, scroll to hour
  React.useLayoutEffect(() => {

    /* istanbul ignore else no work */
    if (scroller && scroller.current) {
      let now = Math.floor(wh_day.start); // moment();// .hours();
      if (inForm) {
        scroller.current.scrollTop = now * GRID_HEIGHT;
        return;
      }
      let hour = document.getElementById('tz-' + now);
      /* istanbul ignore if no scroll on test */
      if (hour && hour.scrollIntoView) {
        setTimeout(() => {
          hour.scrollIntoView();
        }, 20);
      }
      // if(hour) elementScrollIntoViewPolyfill(hour)
    }
  }, [scroller, wh_day, inForm]);

  React.useEffect(() => {
    let target = document.getElementById('scroller');

    /* istanbul ignore else no DOM node on test */
    if (!target || !target.addEventListener) return;
    else {
      let scrolling = (e) => {
        let top = e.target.scrollTop < 5;
        setIsAtTop(top);
        if (top && scrollID) autoScrollStop();
        // clearInterval(scrollID);
      }
      target.addEventListener("scroll", scrolling);

      return () => {
        autoScrollStop();
        target.removeEventListener('scroll', scrolling);
      }
    }

  }, []);

  React.useEffect(() => {
    let current = touchHandler.current;
    /* istanbul ignore else no work */
    if (touchHandler.current) {
      touchHandler.current.addEventListener('touchStart', mouseDown, { passive: false });
      touchHandler.current.addEventListener('touchEnd', mouseUp, { passive: false });
    }

    return () => {
      if (current) {
        current.removeEventListener('touchStart', mouseDown);
        current.removeEventListener('touchEnd', mouseUp);
      }
    }
  }, [touchHandler, mouseDown, mouseUp]);

  const obocalendar = oboaccounts ? (oboaccounts[0] || { calendars: [] }).calendars[0] : undefined;

  return (
    <div id="scroller" className="heatmap-container fancy-scroll" ref={scroller}>
      {!isAtTop && <div className="autoscroll top" onMouseEnter={autoScrollTop}
        onMouseLeave={autoScrollStop} />}
      <div ref={touchHandler} data-testid="heatmap" onMouseDown={mouseDown} onMouseUp={mouseUp}
        onMouseMove={heatmap_slots_only ? moveShadow : NOOP}
        onMouseLeave={heatmap_slots_only ? clearShadow : NOOP}>
        <Grid />
        <Busy busy={busy} selectedDate={selectedDate} organizer={organizer} calendar={calendar} oboCalendar={obocalendar} invitees={invitees}
          interactive={false} />
        <CalEvents events={calendarEvents} selectedDate={selectedDate} interactive={false} />
        <Hour selectedDate={selectedDate} />
        {
          currentShadow && !startDrawing &&
          <div className="shadowing">
            <div key="shadow"
              className={
                "simple-hand-drawing  current shadow shadow-center"
                + (currentShadow._is_shadow_valid ? '' : ' invalid-shadow ')
                + (currentShadow._is_shadow_in_past ? ' invalid-shadow-in-past ' : '')
              }
              style={currentShadow.geometry}>
              <div className='container'>
                <span className="start">{currentShadow.start.format(t('common.hourMinutesFormat'))}</span>
                <span className="end">{currentShadow.end.format(t('common.hourMinutesFormat'))}</span>
                {/* <div className="shadow-text">
                  <div>
                    {currentShadow._is_shadow_in_past ?
                      t('createEvent.heatmap.slotInPast') :
                      ((currentShadow._is_shadow_valid && !currentShadow._is_shadow_overlapping) ? t('createEvent.heatmap.slotFree') : t('createEvent.heatmap.slotBusy'))}
                  </div>
                </div> */}
                {currentShadow.popup && <BusyPopup organizer={organizer} calendar={calendar} oboCalendar={obocalendar} inviteesList={invitees} invitees={currentShadow.busyUsers} position={currentShadow.popup}
                />}

              </div>
            </div>
          </div>
        }
        <Drawing periods={periods} onChange={onChange} selectedDate={selectedDate} duration={duration} title={title}
          onRemoveProposal={onRemoveProposal} onUpdateProposal={onUpdateProposal}
          onProposalDrag={onProposalDoAction}
          activeAutoScroll={setAutoScroll}
          active={startDrawing}
          parentScrollTop={scrollTop}
          allowMultiple={allMultipleSlots}
          freeBusys={busy}
          ignoreInitialAvail={ignoreInitialAvail}
          heatmap_slots_only={heatmap_slots_only}
          increment={increment} />
      </div>

      <div className="autoscroll bottom" onMouseEnter={autoScrollBottom}
        onMouseLeave={autoScrollStop} />
    </div>
  );
}