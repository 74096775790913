/**
 * A notistacjk-like dialog container 
 * make it possible to enqueueDialog to user ?
 * should make it evoluate depending on needs, keep it simple for now
 * 
 * 
 * I HAVE NO F*CKING CLUE ON HOW TO TEST THIS!!!!
 * 
 */
import React, { createContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import './dialog.scss';
export const DialogContext = createContext({});
// Context provider 
export function DialogProvider({ children }) {
  const dlg = React.useState();

  return <DialogContext.Provider value={dlg}>
    {children}
    {!!dlg[0] && dlg[0]}
  </DialogContext.Provider>
}
// add a dialog to the screen
export function useDialog(dialogProps = {}) {
  // only one stack for now?
  const dlg = React.useContext(DialogContext);

  const handleClose = React.useCallback(() => {
    dlg[1](false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dlg);

  return {
    enqueueDialog: ({
      content, // what to display in dialog
      doClose, // close callback if needed
      autoCommitDuration = 0, // a timer to autocommit dialog?
      modal = true, // is this dialog full screen lock?
      mustConfirm = false, // if true, will not close when click outside
      className = "",
      overrideDefaultProps,
      fullScreen = false,
    }) => {
      dlg[1](<Dialog onClose={() => {
        if (mustConfirm) return;
        handleClose();
        if (doClose) doClose();
      }} aria-labelledby="simple-dialog-title" open={true}
        scroll="paper"
        className={className}
        {...(overrideDefaultProps || dialogProps)}
        fullScreen={fullScreen ? true : undefined}
      >
        {content}
      </Dialog>)
    },
    closeDialog: () => {
      dlg[1](false)
    },
  }
}

