import React from 'react';
import DoneButton from '../../../DoneButton';
import { useTranslation, Trans } from 'react-i18next';
import FunnelPromote from '../../../funnelpromote/funnel.promote';
import { LMTooltip } from "../../../tooltip/Tooltip"
import "./confirm.scss";
import moment from 'moment';
import { StopIcon } from '../../../../assets/icons';

export default function ConfirmOneSlotOnlyDialog({
  doConfirm,
  doClose,
  nbSlotsVoted = 1,
  nbSlotsRecommended,
  nbGuestsLeftToVoteAfterMe,
  votedSlots = [],
  labelPrefix = '.notEnough',
  className = '',
  organizerLabel = "",
}) {
  const { t } = useTranslation();
  const isOrganizer = labelPrefix === ".organizer";
  /* This is not the case anymore:
  // there are 2 different sets of labels
  // one if there's only one slot selected
  // one if there's more than one but it's not enough
  */

  let date;
  if (votedSlots.length === 1) {
    date = moment(votedSlots[0].start).format(t('common.fullDateFormat'));
  }

  const title = "createEvent.form.confirmDlg" + labelPrefix + ".title";
  const message = "createEvent.form.confirmDlg" + labelPrefix + ".message";
  const ok = "createEvent.form.confirmDlg" + labelPrefix + ".ok";
  const cancel = "createEvent.form.confirmDlg" + labelPrefix + ".cancel";

  return (
    <div data-testid="confirm-1-slot-dlg" className={'confirm-one-slot-dlt ' + className + (votedSlots.length === 1 ? ' one ' : '')}>

      <div className="title-container">
        {votedSlots.length === 1 && <StopIcon />}
        <div className="title" data-testid={title}>{t(title)}</div>
      </div>

      <div className="message" data-testid={message}>
        <span>
          {isOrganizer
            ? <Trans
              i18nKey={nbSlotsRecommended ? "createEvent.form.confirmDlg.organizer.message" : "createEvent.form.confirmDlg.organizer.messageNoNbSlotsRecommended"}
              values={{ nbSlotsVoted, nbSlotsRecommended, nbGuestsLeftToVoteAfterMe, date }}>
              <p>textA<strong>gras</strong></p><p>textB</p>
            </Trans>
            : <Trans
              i18nKey={"createEvent.form.confirmDlg.notEnough.messageWithFunnel"}
              values={{ nbSlotsVoted, nbSlotsRecommended, nbGuestsLeftToVoteAfterMe, date, organizer: organizerLabel }}>
              <p>You've selected<strong>gras</strong>time slots. in a <LMTooltip interactive={true}
                content={<FunnelPromote />} childrenClassName="underline bold" colorTheme='light'>lmFunnel</LMTooltip> created by host</p><p>textB</p>
            </Trans>}
        </span>
      </div>

      <div className="actions">
        <DoneButton name="confirm-dlg-cancel" className="no-margin grey" onClick={doConfirm} label={t(ok)} />
        <DoneButton onClick={doClose} label={t(cancel)} name="confirm-dlg-cancel-close" />
      </div>

    </div >)
}