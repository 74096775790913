import { Checkbox, FormControlLabel, Radio } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { LMTooltip } from '../../../tooltip/Tooltip';
import './slot.scss';
/**
 * 
 * @param {Object} slot Object holding info about the slot (start, end, selectedBy)
 * @param {boolean} multiselect is the slot a check box or not
 * @param {boolean} canSelect can the user select this slot (used to display fake slots)
 * @param {boolean} selected true if the slot is selected
 * @param {function} onChange callback called when the value changes
 * @param {string} name used to identify the component
 * @param {function} submit callback called when user confirms a slot
 * 
 * @returns 
 */
export default function Slot({ slot, key, multiselect, canSelect, selected, onChange, name, submit, isPoll = false, inviteesCount = 1 }) {

  const { t } = useTranslation();

  if (!slot || !slot.start) return null;

  const start = moment(slot.start).format(t('common.slotHourNoZero'));
  const selectedByCount = (slot.selectedBy || []).length;
  const isBestChoice = isPoll && inviteesCount > 1 && selectedByCount === inviteesCount;
  const control = multiselect ?
    <Checkbox
      disabled={!canSelect}
      checked={selected}
      onChange={onChange}
      name={name}
      className="done-input"
      data-testid={"check-vote" + (canSelect ? '-canselect' : '-cannotselect')} />
    :
    <FlipConfirm
      name={name}
      selected={selected}
      confirm={submit}
      onChange={onChange}
      start={slot.start} />;
  return (
    <FormControlLabel key={key}
      className={"slot " +
        (!canSelect ? "disabled" : (selected ? "selected" : "")) +
        (multiselect ? "" : " radio") +
        (isPoll ? " poll" : "") +
        (isBestChoice ? " best-choice" : "")}
      control={control}
      label={<div id={name + '-label'} className="label">
        <span className="time">{start}</span>
        {isPoll && selectedByCount > 0 &&
          <LMTooltip
            childrenClassName="showHand"
            enterTouchDelay={0}

            content={
              <><p><b>They're available:</b></p><p>{(slot.selectedBy || []).map((attendee) =>
                <>
                  {attendee}<br />
                </>
              )}
              </p></>}
          ><div className="mark">
              <div style={{ /*backgroundColor: transparentize("#7060df", 50 + percent) */ }} className={"inner-mark "}>
                <Done />
                {selectedByCount + (selected ? 1 : 0)}
              </div>
            </div>
          </LMTooltip>}
        {/* {slot.availables && slot.availables.length > 0 && <span className="optionals">
          <LMTooltip
            childrenClassName="showHand"
            content={
             <Trans i18nKey="event.anonymous.optionalVotes"
             values={{ optionals: slot.availables.join(",") }}><p>Optionals who can join:</p><p>invitees</p><p>Mandatory are all dispos</p></Trans>
            }
          >
            <ThumbUp/>
            {slot.availables.length > 1 && <span className="badge">{slot.availables.length > 100 ? "99+" :  slot.availables.length}</span>}
          </LMTooltip>
          </span>} */}
      </div >} />
  );
}
function distance(x1, y1, x2, y2) {
  var dx = x1 - x2;
  var dy = y1 - y2;
  return Math.sqrt(dx * dx + dy * dy);
}

export const FlipConfirm = ({ name, selected, confirm, start, onChange }) => {
  const { t } = useTranslation();
  const [positions, setPositions] = React.useState({});
  const [askconfirm, setaskconfirm] = React.useState();
  React.useEffect(() => {
    if (!selected) { setPositions({}); setaskconfirm(false) }
  }, [selected]);
  const onFrontClick = React.useCallback((event) => {
    onChange(event);
    // if(!askconfirm) return;
    let lbl = document.getElementById(name + '-label');
    if (!lbl) return;


    let { width, height, top, left } = lbl.getBoundingClientRect();
    var mx = event.clientX - left,
      my = event.clientY - top;
    var w = width,
      h = height;
    var directions = [
      //{ id: 'top', x: w/2, y: 0 },
      { id: 'right', x: w, y: h / 2 },
      //{ id: 'bottom', x: w/2, y: h },
      { id: 'left', x: 0, y: h / 2 }
    ];
    directions.sort(function (a, b) {
      return distance(mx, my, a.x, a.y) - distance(mx, my, b.x, b.y);
    });
    // check if out of screen to reposition in it
    // get container position
    let container = document.getElementById("slots-container");
    let repos = { top: 0, left: 0 };
    if (container) {
      let pos = container.getBoundingClientRect();
      repos = {
        top: (top - pos.top - 80) > 0 ? 0 : 60,
        left: (left - pos.left - 150) > 0 ? 0 : -60
      }
    }

    setPositions({ dataDirection: directions.shift().id, className: "is-open", position: repos });
    setaskconfirm(true);
  }, [confirm, name]);
  const remove = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setPositions({})
    setaskconfirm(false);
    onChange({ target: { name: name, value: false } });
  }, [onChange, name]);
  return (
    <div className={"flip-btn " + (askconfirm && positions.className)} data-direction={positions.dataDirection} data-testid={"flip-btn-confirm" + name}>
      {askconfirm &&
        <div className={"flip-btn-back "} style={positions.position} data-testid={"flip-btn-back" + name}>
          <p><Trans i18nKey="event.anonymous.lastToVote.confirm" values={{ start: moment(start).format(t('common.slotHourZ')) }}>confirm <b>date</b></Trans></p>
          <div className="actions">
            <button className="no" onClick={remove} data-testid={"flip-btn-confirm-no" + name}>{t('common.cancel')}</button>
            <button className="yes" onClick={confirm} data-testid={"flip-btn-confirm-yes" + name}>{t('event.anonymous.lastToVote.confirmOk')}</button>
          </div>
        </div>}
      <Radio
        type="button"
        className="done-input flip-btn-front"
        value={true}
        checked={!!selected}
        // onChange={onChange}
        onClick={onFrontClick}
        data-testid="confirmable-slot"
        name={name} />

    </div>);
}