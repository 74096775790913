import React from 'react'
import { monogram } from '../utils/monogram';
import { getColorForName } from '../utils/color.for.names';
import './avatar.scss';

import {
  AVATAR_BIG,
  AVATAR_SMALL,
  AVATAR_NORMAL
} from './consts';


const SIZES = [' ', ' profile-mode', ' pills-mode']
/**
 * Display the user Avatar or mail icon.
 * If no picture is provided, it uses a MONOGRAM and generates a color.
 * 
 * @param {string} name the name of the person
 * @param  {boolean} mini defines the size mode
 * @param  {string} imgSrc the path to user picture
 */
export default function Avatar({
  name, // name for monogram if needed REQUIRED
  className = "",
  size = AVATAR_NORMAL, // size to display
  imageSrc = '', // if provided, an avatar to display
  defaultTo, // if no avatar, icon to display
  fallBackColor, // if provided, a color for avatar background
  withBorder, // if true, had a colored border
}) {

  let icon = React.useMemo(() => {
    let icon;
    if (imageSrc) icon = <div className="avatar-pill"><img src={imageSrc} alt="" referrerPolicy="no-referrer" /></div>
    else icon = defaultTo ? defaultTo : monogram(name);

    return icon;
  }, [imageSrc, name, defaultTo]);

  let styles = React.useMemo(() => {
    const cl = (fallBackColor || getColorForName(/*!isGroup &&*/ name));
    return {
      avatar: (SIZES[size] || ' ') + (imageSrc ? ' avatar-img' : ''),
      style: {
        backgroundColor: imageSrc ? 'unset' : withBorder ? "white" : cl,
        border: withBorder ? '2px solid ' + cl : "none",
        // borderRadius: '50%',
      }
    }
  }, [imageSrc, fallBackColor, name, size]);
  return (

    <div className={"Avatar" + styles.avatar + " " + className}
      style={styles.style}>
      {icon}
    </div>
  );
}
/*
export default React.memo(Avatar, (prev, next)=>{
    return prev.imageSrc === next.imageSrc;
});*/