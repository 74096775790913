import React from 'react';
import { useSelector } from 'react-redux';
import { getAllDayEvents, getUserEvent } from '../../../../../reducers/user.events';
import moment from 'moment'
import { getEventCalendarLF } from '../../../../../reducers/accounts';
import { position } from '../../../../agenda/utils/position';
import transparentize from '../../../../../utils/transparentize';

import './all.day.scss'

const NOW = moment();
export default function AllDayEventBar({ selectedDate = NOW }) {

  const events = useSelector(getAllDayEvents)
  const allday = React.useMemo(() => {
    const period = {
      start: moment(selectedDate.format()).startOf('week'),
      end: moment(selectedDate.format()).endOf('week'),
      numOfDays: 7, // for now, only weeks
    }
    let p = position(period, events || [], true, true, true);
    if (!p || !p.allday) return [];
    return p.allday;

  }, [events]);
  if (!events || events.length === 0) return null;

  return <div className="all-day-bar">
    {allday.map((allday, i) => <div className="day-item fancy-scroll" key={i}>
      {
        allday.events.map((evt) => <AllDayItem key={evt} id={evt} count={allday.events.length} />)
      }
    </div>)}
  </div>
}

export const AllDayItem = ({ id, count }) => {
  const event = useSelector(getUserEvent(id))
  const eventCalendar = useSelector(getEventCalendarLF(event));
  const style = React.useMemo(() => {
    let color = transparentize(eventCalendar.bgcolor, 25);
    return {
      // borderColor: color,
      // borderLeftColor: color,
      backgroundColor: color,
    }
  }, [event])
  if (!event) return null;

  return <div className="all-day-event-item" style={style}>{event.title}</div>
}