import React, { useState } from 'react';
import { compareNoSpace as compare } from '../utils/compare.string';

// A simple rewrite needed for dashboard v2
// should be retro-compat with the "guest list" component

// Dummy compare emails for search in contacts
function compareEmails(data, search) {
  let email = data.email || ''; // not sure how email could be empty...
  let name = data.name || ''; // name CAN be empty (in Mathieu's / Google contacts)
  return (compare(email, search) || compare(name, search));
}

const slice = (data, itemsPerPage, blacklist) => {
  let items = data.slice(0, itemsPerPage);
  let offset = itemsPerPage;

  if (blacklist) {
    items = [];
    let i = 0;
    for (let j = 0; j < itemsPerPage && i < data.length; i++) {
      let c = data[i];
      // if not in blacklist
      if (blacklist && blacklist(c)) continue;
      j++;
      items.push(c);
    }
    offset = i;
  }
  return { result: items, offset: offset };
}

function doSearch(data, currentPage, itemsPerPage, blacklist, compare, search = '', forceOffset = false, scrollTo = undefined) {
  let tmp = [];
  let i = 0;
  let start = 0;
  // if objects are permitted, we should go something like this
  if (JSON.stringify(search) === JSON.stringify(currentPage.currentSearch)) {
    // next page
    // check if end of datas, if so, return currentResult
    if (currentPage.actualOffset === data.length) {
      // return currentPage.currentResult;
      return {
        offset: currentPage.actualOffset,
        tmp: currentPage.currentResult,
        i: currentPage.actualOffset
      }
    }
    if (!forceOffset) {
      tmp = [...currentPage.currentResult]; // preceding results
      i = currentPage.actualOffset; // where to start looking for
    }
  }
  // else, must search in datas
  let total = itemsPerPage;
  let offset = 1;
  if (forceOffset) {
    // keep same count of results
    total = tmp.length || itemsPerPage;
    offset = currentPage.currentOffset || 1;
    start = i; // as we keep position, do not pass datas
  }
  // we need to scroll to a particular result
  if (scrollTo) total = data.length; // take all possible datas
  for (let j = start; j < total && i < data.length; i++) {
    let c = data[i];
    if (compare(c, search)) {
      // if not in blacklist
      if (blacklist && blacklist(c)) continue;
      j++;
      tmp.push(c);
      if (scrollTo && (c === scrollTo || (c && c.id === scrollTo))) {
        // stop to next page
        total = Math.ceil(j / itemsPerPage) * itemsPerPage;
      }
    }
  }
  return {
    offset, tmp, i
  }
}
// TESTING ONLY: load 10000 emails
// import test from './test.json';
const EMPTY_ARR = []
// Pagination for long set results
function usePagination(data = EMPTY_ARR, itemsPerPage = 10, compare = compareEmails, initSearch, blacklist) {
  // the data we want to display
  const [currentPage, setCurrentPage] = useState({
    currentOffset: 1, // offset in results
    currentResult: [], // what to display
    currentSearch: initSearch || '', // current search
    actualOffset: 0, // offset in real array
  }, [data]);
  // next: look for the next page of data
  // search: a value to search for
  // forceOffset: if true, keep old results in response
  // scrollto: a search string to look for. Pagination will be unactive until we found the element (or reach end)
  const next = React.useCallback((search = '', forceOffset = false, scrollTo = undefined) => {
    const { offset, tmp, i } = doSearch(data, currentPage, itemsPerPage, blacklist, compare, search, forceOffset, scrollTo)
    setCurrentPage({
      currentOffset: offset,
      currentSearch: search,
      currentResult: tmp,
      actualOffset: i, // offset in real array
    });
    return tmp;
  }, [data, currentPage, itemsPerPage, blacklist, compare])
  // note: this method is of no use.... Maybe will remove it...
  const reset = React.useCallback((newSet, newSearch, keepPage = false, selected = undefined) => {
    let { currentOffset, currentSearch, currentResult } = currentPage;
    if (newSet && Array.isArray(newSet)) {
      data = newSet;
      let sliced = data;
      let offset = itemsPerPage;
      if (newSearch) {
        sliced = next(newSearch, keepPage, selected);
        return sliced;
      } else {
        let tmp = slice(data, itemsPerPage, blacklist);
        sliced = tmp.result;
        offset = tmp.offset;
      }
      // reinit all
      setCurrentPage({
        currentOffset: keepPage ? currentOffset : 1,
        currentSearch: '',
        currentResult: sliced,
        actualOffset: offset,
      });
      return sliced;
    }

    // if already rested, return result
    if (currentOffset === 1 && currentSearch === '') return currentResult;
    // set datas
    let tmp = slice(data, itemsPerPage, blacklist);
    setCurrentPage({
      currentOffset: 1,
      currentSearch: '',
      currentResult: tmp.result,
      actualOffset: tmp.offset,
    });
    return tmp.result;
  }, [currentPage, next])
  // Need to calculate first result?
  React.useMemo(() => {
    if (initSearch === undefined) {
      // get first non blacklisted items
      let t = slice(data, itemsPerPage, blacklist)
      setCurrentPage({
        currentOffset: 1, // offset in results
        currentResult: t.result, // what to display
        currentSearch: '', // current search
        actualOffset: t.offset, // offset in real array
      })
      return t
    } else {
      // create a first dumb search obj
      const cp = {
        currentOffset: 1, // offset in results
        currentResult: [], // what to display
        currentSearch: initSearch || '', // current search
        actualOffset: 0, // offset in real array
      }
      const { offset, tmp, i } = doSearch(data, cp, itemsPerPage, blacklist, compare, initSearch, false, initSearch)
      setCurrentPage({
        currentOffset: offset,
        currentSearch: initSearch,
        currentResult: tmp,
        actualOffset: i, // offset in real array
      });
    }
  }, [data, initSearch, blacklist, itemsPerPage, compare])


  return { next, reset, current: currentPage.currentResult };
}

export default usePagination;