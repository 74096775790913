/**
 * @fileoverview DonePicker: date picker, but Done style!
 * @module DonePicker
 */
import React, { useState, useMemo } from "react";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';
import {
  ExpandLess,
  ExpandMore
} from "@material-ui/icons";
import { Day } from './day';
import YearSelector from "./Year";
import MonthSelector from "./MonthSelector";
import MonthYearSelector from './month.year';
import { useTranslation } from 'react-i18next';
import "./picker.scss";
import './month.year.scss';


const EMPTY = [];
// must all start on monday?

export default ({
  current = moment(), // npw or current date for picker
  mini = false, // display small
  large = false, // display very large
  DayRenderer = Day, // display for days
  disablePast = true, // allow select date in past
  disableNextMonth = false, // allow next month cliquable
  useYearCtrl = true, // show ctrl for year
  useMonthCtrl = true, // show ctrl for month
  useDoneCtrl = true, // show month/year ctrl
  crunch = false, // display only current week + btn to open month
  onChange, // handler for date change event, not implemented yet... @steph: not implemented?
  events = EMPTY, // a list of events to display as dot @steph: not used?
  hoveredDate, // the date currently hovered by the user
  showDayNames = false,
  showTodayBtn = true,
  forceSixLines = false, // ensure we always display 6 lines
  className = "",
  overlay, // display an overlay above the date
  selectOnUserInteractionOnly = false // if true, only click will select a "date"
}) => {

  const { t } = useTranslation();
  const [userInteract, setUserInteract] = React.useState(!selectOnUserInteractionOnly);
  const doChange = React.useCallback((date, reason) => {
    setUserInteract(false); // reset interactions
    onChange(date, reason)
  }, [onChange])
  const [displayOnlyCW, setDisplayOnlyCW] = useState(crunch || false);
  const prevYear = React.useCallback(() => doChange(current.clone().subtract(1, "year"), 'prevYear'), [doChange, current]);
  const nextYear = React.useCallback(() => doChange(current.clone().add(1, "year").clone(), 'nextYear'), [doChange, current]);
  const changeMonth = React.useCallback(m => doChange(current.clone().month(m).clone(), 'chgMonth'), [doChange, current]);
  // const changeDay = d => updateDate(current.clone().dayOfYear(d).clone());

  const prevMonth = React.useCallback(d => doChange(current.clone().subtract(1, 'months'), 'prevMonth'), [doChange, current]);
  const nextMonth = React.useCallback(d => doChange(current.clone().add(1, 'months'), 'nextMonth'), [doChange, current]);
  const changeDate = React.useCallback(d => {
    setUserInteract(true); // user click on a day
    onChange(d.clone(), 'date');
  }, [onChange]);

  const now = moment().startOf("day");

  // start and end of display
  let { firstDay, endDay } = React.useMemo(() => {
    let f = moment(current).startOf("month").startOf("week");
    let e = moment(current).endOf("month").endOf("week");


    if (displayOnlyCW) {
      // need to get start of week depending on locale
      // Monday OR Sunday? 
      f = moment(current).startOf("week");
      e = moment(current).endOf("week");
    }
    return { firstDay: f, endDay: e };
  }, [current, displayOnlyCW]);


  // names of day locale aware
  const dayNames = useMemo(() => {
    let DAYS = moment.weekdays();
    let sow = moment(current).startOf("week");
    // pass sunday at end of week
    // Or at first day depending on locale
    if (sow.day() === 1) DAYS = [...DAYS.slice(1, 7), DAYS[0]];
    if (mini || showDayNames === 1) {
      // only first letter
      return DAYS.map(d => d[0].toUpperCase());
    } else if (large) return DAYS;
    return DAYS.map(d => d.slice(0, 3));
  }, [mini, showDayNames, large, current]);


  // get weeks for month
  // if crunch, only current week

  const weeks2 = useMemo(() => {
    const weeks = [];
    // Get the first and last day of the week(s)

    // get first/end day of period weeks
    // if crunched, will be the same date
    // get current month
    let som = current.clone().startOf('month');
    let eom = current.clone().endOf('month')
    eom = endDay < eom ? endDay : eom;
    // get index of first and last day
    // before start of month
    let w = []; // for current week

    // Get all the weeks during the current month
    let fd = firstDay.clone();
    // extra days at start (not selectables)
    // let theDayBefore = som.subtract(1,'day')
    while (fd < som) {
      let d = fd.clone();
      fd.add(1, 'day');
      d.unselectable = disableNextMonth;
      w.push(d);
    }

    // get days by week
    // return to firstday
    while (fd < eom) {
      // while same week...
      while (w.length < 7 && fd < eom) {
        let m = fd.clone()
        w.push(m);
        // search if got some events on that day, if yes, add mark
        if (events.find((slots) => {
          if (!slots || !slots.day) return false;
          return slots.day.isSame(m, 'day')
        })) {
          m.has_events = true;
        }
        fd.add(1, "d");
      }
      // add a new week
      // if complete
      if (w.length === 7) {
        weeks.push(w);
        w = [];
      }
    }
    // days at of month (unselectable)
    if (fd < endDay) {
      while (fd < endDay) {

        let d = fd.clone();
        fd.add(1, "d");
        d.unselectable = disableNextMonth;
        w.push(d);
      }
      weeks.push(w);
    }

    // end of month
    return weeks;
  }, [firstDay, endDay, current, disableNextMonth, events]);

  const hasEndLine = forceSixLines && weeks2.length < 6;
  return (
    <div data-testid="done-picker" className={"done-picker " + className}>
      <div className={"calendar-base" + (mini ? " condensed" : "")}>
        {
          useDoneCtrl && <div className="done-ctrl">
            <MonthYearSelector
              date={current}
              onPrevMonth={prevMonth}
              onNextMonth={nextMonth}
              mini={mini}
              disablePast={disablePast}
            />
            {showTodayBtn && <Button data-testid="btn-today" onClick={() => changeDate(moment())}>{t('common.today')}</Button>}
            {crunch && (
              <IconButton onClick={() => setDisplayOnlyCW(!displayOnlyCW)}>
                {displayOnlyCW ? <ExpandMore /> : <ExpandLess />}
              </IconButton>
            )}
          </div>
        }
        {!useDoneCtrl && useYearCtrl && <div className="year-ctrl">
          <YearSelector
            date={current}
            onPrevYear={prevYear}
            onNextYear={nextYear}
            mini={mini}
            disablePast={disablePast}
          />
          {crunch && (
            <IconButton onClick={() => setDisplayOnlyCW(!displayOnlyCW)}>
              {displayOnlyCW ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          )}
        </div>}
        {!useDoneCtrl && useMonthCtrl && <MonthSelector date={current} onMonthChange={changeMonth} mini={mini} disablePast={disablePast} />}
        {/* <div className="days">
          {dayNames.map((d, i) => {
            return (
              <span key={d + i} className="dayOfWeek">
                {d}
              </span>
            );
          })}
        </div> */}
        <div className="dates">
          {showDayNames && <div className="week">{dayNames.map((day, i) => <div key={day + '-' + i} className="donepicker-date dayname">{day}</div>)}</div>}
          {weeks2.map((week, w) => {
            return (
              <div className="week" key={"week-" + w}>
                {week.map(day => {
                  return (
                    <DayRenderer
                      day={day}
                      selected={userInteract && current}
                      hovered={hoveredDate}
                      disabled={day.unselectable || (disablePast && day < now)}
                      unselectable={day.unselectable}
                      hasEvents={day.has_events}
                      key={day.unix()}
                      onSelected={d => changeDate(d)}
                    />
                  );
                })}

              </div>
            );
          })}
          {hasEndLine && <div className="week">{dayNames.map((day, i) => <div key={"more-" + day + '-' + i} className="donepicker-date dayname unselectable">&nbsp;</div>)}</div>}
          {overlay}
        </div>
      </div>
    </div>
  );
};
