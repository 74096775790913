import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import * as ACTIONS from '../../../../actions';

import './lang.scss';
export default function Lang({ profile = {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // generic when more options will come...
  const [loading, setLoading] = React.useState(false);

  const onChange = React.useCallback((e) => {
    /* istanbul ignore if */
    if (loading) return;
    const newValue = e.target.value;
    setLoading(true);
    dispatch({
      type: ACTIONS.WORKER_SAVE_WH,
      payload: {
        ...profile,
        lang: newValue
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_SAVE_WH_SUCCESS,
        rejectOn: ACTIONS.WORKER_SAVE_WH_ERROR,
      }
    }).catch((err) => {
      // rollback
      enqueueSnackbar(t('settings.sections.advanced.lang.error'), { variant: 'error' });
    }).then(() => {
      setLoading(false);
    })
  }, [loading, profile]);
  return (
    <div className={"section advanced-item " + (loading ? 'inactive' : '')}>
      <div className="title">{t('settings.sections.advanced.lang.title')}</div>
      <div className="subtitle">{t("settings.sections.advanced.lang.subtitle")}</div>
      {!loading && <div className="test-only" data-testid="loader" />}
      <div className="lang">
        <div className="lang-item">
          <FormControl>
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={profile.lang}
              onChange={onChange}
            >
              <FormControlLabel value="en" control={<Radio data-testid="lang-en" className='done-input' />} label={t("settings.sections.advanced.lang.en")} />
              <FormControlLabel value="fr" control={<Radio data-testid="lang-fr" className='done-input' />} label={t("settings.sections.advanced.lang.fr")} />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  )
}