import React from 'react';
import './text.block.scss';
const Linkify = require('linkifyjs/react');
const NL = /(?:\r?\n|\r|\n)/g;
const LINK_OPTIONS = {

}
/**
 * Display a block of text
 * Should respect new lines feed
 */
export default function TextBlock({ txt = '' }) {
  let content = React.useMemo(() => {
    let lines = txt.split(NL);

    if (lines[0] === '')
      lines.shift();
    if (lines[lines.length - 1] === '')
      lines.pop();

    return lines.map((t, i) => {
      if (t === '') return <br key={i} />;

      return (
        <span key={i} className="text-block">
          <Linkify tagName="span" options={LINK_OPTIONS}>
            {t.split(' ').map(foo => <>{foo}&nbsp;<wbr /></>)}
          </Linkify>
        </span>
      );
    })
  }, [txt]);
  return content;
}