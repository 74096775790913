import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import DonePicker from '../../../../donePicker';
import WeekSelector from './week.selector';
import AllDayBar from './all.day';
import HeatMap from './heatmap';
import DoneButton from '../../../../DoneButton';
import { getAccounts } from '../../../../../reducers/accounts'
import * as ACTIONS from '../../../../../actions';
import { Loader } from '../../../../Loader';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import Timezone from '../../timezone';
import { Button } from '@material-ui/core';
import { Clear, DeleteOutline, HelpOutline } from '@material-ui/icons';
import { isValid } from '../../../../../utils/heatmap/is.slot.valid';
import { proposalsToSlotsRT } from '../../../../../worker/handlers/generate.slots';
import './period.selector.scss';
import { Help, IllusTwoHands } from '../../../../../assets/icons';
import HeatmapTutoOverlap from '../../../../../assets/heatmap_tuto_overlap.gif';

const removeOverlapping = (proposals = []) => {
  // remove proposals with same start and end
  const tmp = [];
  for (let p of proposals) {
    if (tmp.find((t) => t.start.valueOf() === p.start.valueOf())) {
      // already present, pass

    } else {
      //unknown, add
      tmp.push(p)
    }

  }
  return tmp;
}
export default function PeriodSelector({
  title,
  isImportant,
  duration,
  organizer,
  ignoreInitialAvail,
  calendar,
  oboCalendar = '',
  invitees = [], // event to display
  inviteesDetails, // infos about invitees
  values = [], // values selected
  onValid, onClose,
  initial, // which date to start for?
  heatmap_slots_only = false, // dev settings: precompute slots from proposals on mouse up/touch up
  allowMultipleSlots = true,
  signature, // in case of anonymous
  overlapp = -1,
  showTimezone = true,
  selectorTitle = 'createEvent.guests.agenda.title',
  selectorOK = 'common.save',
  selectorCancel = 'common.cancel',
  proposalId, // force proposal for holdslot
  disabled = false,
  inForm = false,
  onProposalChanged,
}) {

  const [selectedDate, setSelectedDate] = React.useState(initial || moment());
  React.useEffect(() => {
    if (initial && initial.valueOf() !== selectedDate.valueOf()) setSelectedDate(moment(selectedDate.format()))
  }, [initial])
  const [hoveredDate, setHoveredDate] = React.useState(undefined);

  const [loading, setLoading] = React.useState(0);

  const [showHeatmapTuto, setShowHeatmapTuto] = React.useState(false);
  const hideHeatmapTuto = () => {
    setShowHeatmapTuto(false);
  }

  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const [proposals, __setProposals] = React.useState(values);
  React.useEffect(() => {
    if (inForm) {
      __setProposals(values)
    }
  }, [values, inForm])
  const busy = useSelector((state) => state.busy || {});
  const [ignore_initial_avail, setignore_initial_avail] = React.useState(ignoreInitialAvail);
  const ignoreAvailRef = React.useRef();


  const setProposals = React.useCallback((p, old = [], useBusy = false) => {
    if (heatmap_slots_only) {
      // remove overlapping proposals ie: same start and end
      let cleaned = removeOverlapping(p)
      // custom
      let calculated = proposalsToSlotsRT(cleaned, useBusy ? busy : [], duration, false, overlapp);
      // only keep first slot if multiple slots are not allowed
      if (!allowMultipleSlots && calculated.length > 0) calculated = calculated.slice(0, 1);
      else {
        calculated = [...old, ...calculated];
      }
      __setProposals(calculated);
      if (onProposalChanged) onProposalChanged(calculated)
    } else {
      __setProposals(p)
    }

  }, [__setProposals,
    heatmap_slots_only, duration, busy, ignore_initial_avail,
    allowMultipleSlots, overlapp, onProposalChanged]);

  const toggleDayProposals = React.useCallback((d) => {
    // if got some proposals on that day, remove them
    // assume no timeframes
    if (!heatmap_slots_only) return;
    let tmp = [];
    let old = [];
    let someDeleted = false;
    for (let p of proposals) {
      if (d.isSame(p.start, 'day')) {
        someDeleted = true;
      } else tmp.push(p);
    }
    if (!someDeleted) {
      const day = {
        start: d.clone().startOf("day"),
        end: d.clone().endOf('day')
      };
      // assume we want to toggle all
      if (allowMultipleSlots) {
        old = tmp;
        tmp = [day];
      } else {
        tmp = [day];
      }
      setProposals(tmp, old, true);
      return;
    }
    // set proposals no busy
    setProposals(tmp, old, false);

  }, [setProposals, proposals, heatmap_slots_only, allowMultipleSlots]);
  const clearProposals = React.useCallback(() => {
    __setProposals([]);
    if (onProposalChanged) onProposalChanged([])
  }, [__setProposals, onProposalChanged])
  // const [periods, setPeriods] = React.useState(values);
  const onRemoveProposal = React.useCallback((p) => {
    // get from coords?
    let np = proposals.filter((proposal) => proposal.start.unix() !== p.start.unix() || proposal.end.unix() !== p.end.unix())
    setProposals(np);

  }, [proposals, setProposals])
  const onUpdateProposal = React.useCallback((p) => {
    // get from coords?
    // if period is valid
    let np = proposals.findIndex((proposal) => p.uuid === proposal.uuid);
    if (np > -1) {
      let ps = [...proposals];
      if (isValid(p, duration)) ps[np] = p;
      setProposals(ps);
    }

  }, [proposals, duration, setProposals])

  const allAccounts = useSelector(getAccounts);
  const calendarEvents = useSelector((state) => state.userEvents);
  // get FB accounts OR obo account
  const accounts = React.useMemo(() => {
    let obo = oboCalendar
    const accounts = allAccounts
    /* istanbul ignore else no work */
    if (obo) {
      // get this particular calendar
      let dt = obo.split(':');
      /* istanbul ignore else no work */
      if (dt.length >= 2) {
        // get account
        let acc = accounts.find((a) => a.id === dt[0]);
        /* istanbul ignore else no work */
        if (acc) {
          let cal = (acc.calendars || []).find((c) => c.id === dt[1]);
          /* istanbul ignore else no work */
          if (cal) {
            return [
              {
                ...acc,
                calendars: [cal]
              }
            ]
          }
        }
      }
    }
    // if obo is not set or not a valid calendar...
    // problem, if never set, no infos
    let tmp = (accounts || []).reduce((acc, account) => {
      // Rule: fb OR primary
      // if(account.provider.name !== 'google') {
      //   acc.push(account);
      //   return acc;
      // }
      let writableCalendars = (account.calendars || []).filter(cal => cal.primary).map(cal => cal.id)
      let fbcalendars = (account.settings && account.settings.freebusy_calendars) || writableCalendars;
      let a = {
        ...account,
        calendars: (account.calendars || []).filter((c) => fbcalendars.find((fb) => c.id === fb))
      }
      // if got calendars
      if (a.calendars.length > 0) acc.push(a)
      return acc;
    }, [])
    return tmp;
  }, [allAccounts, oboCalendar]);

  const dispatch = useDispatch();

  const handlePeriodChange = React.useCallback((props, forceOnlyOneSlot = false, shouldShake = false) => {
    if (!Array.isArray(props)) {
      props = [props]
    }
    let res = [];

    for (let p of props) {
      // get from coords?
      // console.log('HandlePeriodChange', proposals, p)
      // check if a period already exists
      if (proposals.find((proposal) => proposal.start.unix() === p.start.unix() && proposal.end.unix() === p.end.unix())) {
        continue;
      }
      // if period is valid
      if (!isValid(p, duration)) {
        // slot is in past (totally or partially)
        continue;
      }
      // add a proposal uuid
      p.uuid = v4();
      res.push(p)

    }
    let np = (forceOnlyOneSlot) ?
      res[0] ? [res[0]] : proposals
      : [...proposals, ...res]; // simplyAdd for now
    setProposals(np);
    if (shouldShake && ignoreAvailRef && ignoreAvailRef.current) {
      // no slot to draw (invalid placement)
      ignoreAvailRef.current.classList.remove("shaker");
      setTimeout(() => { ignoreAvailRef.current.classList.add("shaker"); });
    }

    // return [p, np.length - 1];
  }, [proposals, duration, setProposals])


  React.useEffect(() => {
    if (!invitees || !organizer) return;
    let checkAccounts = accounts;
    // load more datas
    let isAnonymous = !!signature;
    setLoading(true);
    let stdate = moment(selectedDate.format()); // ask for USER week

    // check invitees profiles, if known, add to list, else, nothing
    let fbinvitees = isAnonymous ? [organizer.email] : [organizer.email, ...invitees]
    let p = [
      // get freebusy
      dispatch({
        type: ACTIONS.WORKER_FREE_BUSY,
        payload: {
          invitees: fbinvitees,
          period: {
            start: stdate.startOf('week').toISOString(),
            end: stdate.endOf('week').toISOString()
          },
          cal_id: oboCalendar,
          timezone: calendar ? calendar.timezone : '',
          important: isImportant,
          signature,
          id: proposalId,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_FREE_BUSY_SUCCESS,
          rejectOn: ACTIONS.WORKER_FREE_BUSY_ERROR
        }
      }).catch((res) => {
        /* istanbul ignore else no work */
        if (res.type === ACTIONS.WORKER_FREE_BUSY_ERROR) {
          // add an error popup
          /* istanbul ignore else */
          if (res.code && res.code === "INVALID_PROVIDER_TOKEN") {
            // should reask for a toke,
            return dispatch({
              type: ACTIONS.ASK_4_LOGIN,
              payload: true,
              // could add some labels too?
            })
          } else {
            if (snackbar) snackbar.enqueueSnackbar(t('createEvent.form.errors.fetchBusy'))
          }
        }
      }),
      // get events
      isAnonymous ? Promise.resolve() : dispatch({
        type: ACTIONS.WORKER_GET_USER_EVENTS,
        payload: {
          accounts: checkAccounts,
          from: stdate.startOf('week').toISOString(),
          to: stdate.endOf('week').toISOString(),
          timezone: calendar ? calendar.timezone : '',

        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_USER_EVENTS_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_USER_EVENTS_ERROR
        }
      }).catch((err) => {
        console.error("Error on Events:", err)
      })

    ];
    /* istanbul ignore if dont know how to handle this in dummy provider */
    Promise.all(p).then(() => {
      setLoading(false);
    })
  }, [invitees, organizer, selectedDate, accounts, calendar, isImportant, signature,
    proposalId]); // only reload FB and Events on selectedDate change...

  return (<>
    <div className={"add-guests-panel" + (inForm ? ' in-form' : '') + (disabled ? ' disabled' : '')}>
      <div className="add-guests-agenda">
        <div className="agenda-head">
          {
            inForm &&
            <div className="textual">
              <span className="title">{t('createEvent.guests.agenda.titleInForm')}</span>
            </div>
          }
          {!inForm && <div className="textual">
            <span className="title">{selectorTitle && t(selectorTitle)}</span>
            {showTimezone && <span className="description">{t('agenda.timezones.placeholder')}: <Timezone obo={oboCalendar} calendar={calendar} /></span>}
          </div>}
          <div className="toolbar">
            {proposals && proposals.length > 0 &&
              <Button data-testid='btn-today' size="small" onClick={clearProposals}><Clear /> <Trans i18nKey='createEvent.heatmap.clear'></Trans></Button>}
            <Button data-testid='btn-today' size="small" onClick={() => setShowHeatmapTuto(true)}><HelpOutline /></Button>
          </div>
          <WeekSelector selectedDate={selectedDate}
            onChange={(d) => setSelectedDate(d.clone())} />
        </div>
        <div className="heatmap">
          <div className="agenda">
            {/* <WeekSelector selectedDate={selectedDate} onChange={(d) => setSelectedDate(d.clone())} /> */}
            <DonePicker current={selectedDate} hoveredDate={hoveredDate} onChange={toggleDayProposals} crunch={true} useYearCtrl={false} useMonthCtrl={false} useDoneCtrl={false} mini={true} />
            <AllDayBar selectedDate={selectedDate}></AllDayBar>

            <HeatMap busy={busy} selectedDate={selectedDate} setHoveredDate={setHoveredDate} periods={proposals}
              onChange={handlePeriodChange} duration={duration} title={title}
              onRemoveProposal={onRemoveProposal} onUpdateProposal={onUpdateProposal}
              organizer={organizer} calendar={calendar} invitees={invitees} inviteesDetails={inviteesDetails}
              calendarEvents={calendarEvents} oboaccounts={oboCalendar ? accounts : undefined}
              ignoreInitialAvail={true} allMultipleSlots={allowMultipleSlots}
              heatmap_slots_only={heatmap_slots_only}
              increment={overlapp}
              inForm={inForm} />
            {
              loading && <Loader />
            }
          </div>
        </div>
      </div>
      {disabled &&
        <div className='disabled'>
          <IllusTwoHands />
          <div>{t('createEvent.heatmap.disabled')}</div>
        </div>}
      {showHeatmapTuto &&
        <div className='disabled hand' onClick={hideHeatmapTuto}>
          <div>{t('createEvent.heatmap.tutoText')}</div>
          <img src={HeatmapTutoOverlap} alt="Overlap slots tutorial" />
          <div className='link'>{t('createEvent.heatmap.tutoTextClose')}</div>
        </div>}
    </div>
    {!inForm && <div className="agenda-actions">
      <DoneButton
        label={t(selectorCancel)}
        onClick={onClose}
        className="small grey"
        name="heatmap-cancel" />
      <DoneButton
        label={t(selectorOK, { count: proposals.length === 0 ? 1 : proposals.length })}
        onClick={() => {
          return onValid(proposals, selectedDate, ignore_initial_avail)
        }}
        name="heatmap-valid" />
    </div>}
  </>
  );
}