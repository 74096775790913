import React from 'react';
import ROUTES from './routes.name';
import { Redirect, useLocation } from 'react-router-dom';
import {
  Switch,
  Route
} from 'react-router-dom';
import './create.event.scss';
import AppHeader from './app.header';
import { SuccessBookingLnkToast as Success } from '../components/pages/success/success';
import BookingLinkForm from '../components/pages/create-event/booking.link';

export default function CreateBookingLink({ debounce_delay = 300 }) {

  let location = useLocation();
  let link;
  let prefill;
  
  // get link from state?
  let lnk = location.state;
  if (lnk && lnk.link) {
    link = lnk.link;
    prefill = 'edit'
  }

  const [bookingLink, setBookingLink] = React.useState(link);
  const [prefillType, setprefillType] = React.useState(prefill);

  const reset = React.useCallback(() => {
    setBookingLink()
    setprefillType()
  }, [setBookingLink])

  return (<div className='create-event dashboard' >
    <AppHeader />
    <section>
      <div className="page-content create-link">
        <Switch location={location}>
          <Route
            path={ROUTES.CREATE_A_LINK}
            exact={true}
            render={() => <BookingLinkForm bookinglink={bookingLink} prefillType={prefillType} debounce_delay={debounce_delay} />
            } />
          <Route
            path={ROUTES.CREATE_A_LINK_RESULT}
            exact={true}
            render={() => <RenderLinkPage location={location} reset={reset} />} />
        </Switch>
      </div>
    </section>
  </div>
  );
}

function RenderLinkPage({ location, reset }) {

  React.useEffect(() => {
    if (reset) reset()
  }, [reset])

  if (location && location.state && location.state.bookinglink)
    return <Success />;
  else
    return <Redirect to={{ pathname: ROUTES.APP }} />
}