import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux';
import * as ACTIONS from '../../../actions'
import BOOKING_INFOS from '../../../validators/booking'
import DonePicker from '../../donePicker';
import TZPicker from '../settings/calendars/timezone.picker';
import { LMTooltip } from '../../tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import {
  ArrowBack
} from '@material-ui/icons';
import {
  CalendarBlank
} from '../../../assets/icons'
import DoneTextField from '../create-event/inputs/Text';
import {
  HelpOutline
} from '@material-ui/icons'
import { CircularProgress } from '@material-ui/core';
import moment from 'moment'
import ReCAPTCHA from "react-google-recaptcha";
import CTA from '../create-event/periods/cta.js';
import {
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import "./link.form.scss"
import GuestConsent from '../../form/GuestConsent';
import useGuestConsent, { GUEST_CONSENT_ORIGINS } from '../../../hooks/use.guest.consent';

function linearize(slots) {
  let tmp = [];
  /* istanbul ignore else dummy check */
  if (slots) {
    for (let slot of slots) {
      /* istanbul ignore if */
      if (!slot || !slot.slots) continue;
      for (let frame of slot.slots) {
        tmp.push({ start: frame.start.toISOString(), end: frame.end.toISOString() })
      }

    }
  }
  return tmp;
}

export function transformSlots(link) {

  let slots = [];
  if (link && link.slots && link.slots[0]) {
    const rawSlots = link.slots
    // create first day
    let day = {
      day: moment(rawSlots[0].start),
      slots: []
    }
    slots.push(day)
    for (let rslot of rawSlots) {
      /* istanbul ignore if */
      if (!rslot || !rslot.start || !rslot.end) continue;
      let m = moment(rslot.start);
      if (!m.isSame(day.day, 'day')) {
        // create new day
        day = {
          day: m,
          slots: [],
        };
        slots.push(day);
      }
      day.slots.push({
        start: moment(rslot.start),
        end: moment(rslot.end)
      })
    }
  }

  return slots;
}

// component showing a calendar picker from which the user can select a day
// clicking on a day will show a list of slots to choose from.
function Picker({ values, link, handleChange, setNavigation, slots, setSlots }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const firstday = slots[0] || {};
  const currentdate = values.current_selected_date || firstday.day || moment();
  const [d, _setd] = React.useState(currentdate);
  const [tz, __setTz] = React.useState(moment.tz.guess());
  const [daySlots, setDaySlots] = React.useState();
  const [loadingFB, setLoadingFB] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const [showSlots, _setShowSlots] = React.useState(false); // mainly for mobile usage

  const doSelectSlot = React.useCallback((e) => {
    // set date selected
    let sl = e.target.value;
    handleChange({
      target: {
        name: "current_selected_date",
        value: sl.start,
      }
    })
    // wait next tick, Formik behaviour....
    setTimeout(() => {
      handleChange(e);
      setNavigation(1);
    }, 1)
  }, [handleChange, setNavigation])
  const setShowSlots = React.useCallback((v) => {
    _setShowSlots(v);
    if (!v) return;
    setTimeout(() => {
      const element = document.getElementById("selected-day-title")
      if (element && element.scrollIntoView) {
        element.scrollIntoView(false);
      }
    }, 0)
  }, [])

  // called when a day is selected
  const setd = React.useCallback((day, reason) => {
    setShowSlots(false)
    let p = Promise.resolve({ day, slots });
    // if user navigates to a different month, 
    // ask server for the list of available slots
    if (!day.isSame(d, 'month')) {
      setLoadingFB(true)
      setHasError(false);
      setDaySlots();
      setSlots([]);
      p = dispatch({
        type: ACTIONS.WORKER_GET_BOOKABLE_SLOTS,
        payload: {
          id: link.id,
          signature: link.signature,
          slot: {
            start: day.clone().startOf("month").toISOString(),
            end: day.clone().endOf("month").toISOString()
          }
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_BOOKABLE_SLOTS_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_BOOKABLE_SLOTS_ERROR
        }
      }).then((dt) => {
        let slots = dt.payload || []
        slots = transformSlots({ slots });
        setSlots(slots)
        return { day, slots }
      })
    }
    p.then(({ day, slots }) => {
      if (reason === 'date' && slots) {
        // search if day got slots
        let gotit = false;
        for (let d of slots) {
          if (d && d.day.isSame(day, 'day')) {
            setShowSlots(true)
            setDaySlots(d);
            gotit = true;
          }
        }
        if (!gotit) setDaySlots();
      } else setDaySlots();

    }).catch((err) => {
      setHasError(true);
      setDaySlots();
    }).finally(() => setLoadingFB(false));
    // set current day in picker
    _setd(day)
  }, [slots, d]);

  // function called when the timezone is changed
  //
  // Note: I can not (do not know) how to test changing TZ with
  // mocked moment... It will at best do nothing, at worst, can not
  // predict what
  /* istanbul ignore next moment mocking */
  const setTz = React.useCallback((tz) => {
    // change moment tz
    if (!tz) return
    moment.tz.setDefault(tz);
    // recreate all moments?
    let transformedSlots = []
    if (slots) {
      let sls = linearize(slots);
      transformedSlots = transformSlots({ slots: sls })
      setSlots(transformedSlots);
    }
    let newDay = moment(d.format())
    _setd(newDay)
    __setTz(tz)
    // must reselect if needed
    setShowSlots(false)
    for (let day of transformedSlots) {
      if (day && day.day.isSame(newDay, 'day')) {
        setShowSlots(true)
        setDaySlots(day);
        console.log("GOT:", day)
        return
      }
    }
    setDaySlots([]);

  }, [__setTz, slots, d,]);

  // function called when user asks for page reload after an error
  // occurred loading the slots.
  /* istanbul ignore next */
  const reloadPage = React.useCallback(() => {
    window.history.go(0);
  })

  if (!link || !slots) return null;

  const isLoading = loadingFB;
  const isEmpty = (!slots || slots.length === 0);

  return <div className="booking-picker">

    <div className="booking-slot-picker-container">

      <div className="picker-container">
        {isLoading && <div className="waiter"><CircularProgress /></div>}
        {!isLoading && !hasError && isEmpty && <div className="waiter">{t('schedulingPage.form.noSlots')}</div>}
        <h1 className="picker-title">{t('schedulingPage.form.title')}</h1>
        <DonePicker className={"lm-booking-calendar " + (loadingFB ? 'loading' : '') + (isEmpty ? ' invalid' : '')}
          current={d}
          onChange={setd}
          disableNextMonth={true}
          showDayNames={1}
          events={slots || []}
          showTodayBtn={false}
          forceSixLines={true}
          selectOnUserInteractionOnly={true}
          overlay={hasError && <div className="error-fb" data-testid="error-fb">
            <div className="error-msg"><Trans i18nKey='schedulingPage.form.errors.fberror'>Oh! An error occured.</Trans></div>
            <div className="error-help"><Trans i18nKey='schedulingPage.form.errors.fberrorReload'>
              try to <a href="#" onClick={reloadPage}>reload the page</a></Trans></div>
          </div>}
        />
        <TZPicker auto={false} value={tz} canBeAuto={false} onChange={(e) => {
          setTz(e.target.value)
        }} className="inline"
          inputPropsParams={{ variant: "outlined" }}
          label={<div className="title inline">{t("schedulingLink.form.timezone")}<LMTooltip
            childrenClassName="showHand"
            content={
              <>
                <p><Trans i18nKey="schedulingPage.form.timezoneHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
              </>
            }
          >
            <HelpOutline />
          </LMTooltip></div>} />
      </div><div className={"slots-container " + (showSlots ? 'mobile-slots-showing' : "mobile-slots-hidden")}>
        <SlotSelector day={daySlots} onChange={doSelectSlot} doHide={setShowSlots} />
      </div></div>
  </div>;
}

// component showing the list of slots for a selected day
function SlotSelector({ day, onChange, doHide }) {
  const { t } = useTranslation();
  const [toConfirm, setToConfirm] = React.useState();

  if (!day || !day.slots) return null;

  return <div className="slots-selector" data-testid="slot-selector">
    <h1 id="selected-day-title"><IconButton className="grey mobile-only" onClick={() => doHide(false)}>
      <ArrowBack />
    </IconButton>{day.day.format(t('common.dayFormat'))}</h1>
    <h2 className="mobile-only">{t('schedulingPage.form.mobileSelectSlotTitle')} </h2>
    <div className="slots-list fancy-scroll">
      {day.slots.map((slot) => {
        return <div key={slot.start.format()} data-testid={"day-" + slot.start.format()} onClick={() => setToConfirm(slot)} className={"slot-picker-item " + ((slot === toConfirm) ? 'confirm' : '')}>
          <span>{slot.start.format(t('common.hourMinutesFormatFunnel'))}</span>
          {slot === toConfirm && <div className="slot-confirmation" data-testid={"confirm-" + slot.start.format()} onClick={() => {
            onChange({
              target: {
                name: 'slot',
                value: slot
              }
            })
          }}>{t('schedulingPage.form.confirm')}</div>}
        </div>
      })}
    </div>
  </div>
}

// component showing a form where the user can enters their details
// before booking the meeting
function Information({ values, isBooking, errors, touched, handleSubmit, handleChange, setNavigation, link, onSuccessFullBooking }) {
  const { t } = useTranslation();
  const captchaRef = React.useRef(null)

  const doBack = React.useCallback(() => {
    handleChange({
      target: {
        name: "slot",
        value: undefined,
      }
    });
    setNavigation(0);
  }, [handleChange, setNavigation]);

  const doSubmit = React.useCallback(() => {
    const c = captchaRef.current;
    if (c) {
      const token = c.getValue();
      handleSubmit(token)
    }
  }, [handleSubmit, captchaRef]);

  React.useEffect(() => {
    const c = captchaRef.current;
    return () => {
      if (c) {
        c.reset();
      }
    }
  }, [captchaRef])

  const { isGuestConsentChecked, guestConsentChanged } = useGuestConsent();

  if (!values.slot || !values.slot.start) return null;

  return <div className="user-informations" data-testid="user-informations">
    <div className="back desktop-only">
      <IconButton className="grey" onClick={doBack}>
        <ArrowBack />
      </IconButton>
      <span className="button-back-label">{t('common.back')}</span>
    </div>
    <h1 className='link-form-title mobile-only'>{t('schedulingPage.form.formTitle')}</h1>
    <div className="confirmed-slot">
      <CalendarBlank /><span className="selected-date">
        <Trans i18nKey='schedulingPage.form.selectedDate' values={{
          start: values.slot.start.format(t('common.hourMinutesFormatFunnel')),
          end: values.slot.end.format(t('common.hourMinutesFormatFunnel')),
          day: values.slot.start.format(t('common.dateFormatWirthYearNoTZ')),
          tz: values.slot.start.format(t('common.timezoneAlone'))
        }}>The selected date.</Trans>
      </span>
    </div>
    <DoneTextField
      label={t('schedulingPage.form.nameLabel')}
      value={values.name}
      className="with-help"
      InputLabelProps={{ className: "with-help-button" }}
      name='name'
      onChange={handleChange}
      errorText={errors.name && t(errors.name)}
      isValid={(values.name || !touched.name)}
    />
    <DoneTextField
      label={
        <>{t('schedulingPage.form.emailLabel')}
          <LMTooltip
            childrenClassName="showHand"
            content={
              <p><Trans i18nKey="schedulingPage.form.emailHelp">You will receive the meeting invite on this email address.</Trans></p>
            }
          >
            <HelpOutline />
          </LMTooltip></>}
      value={values.email}
      className="with-help"
      InputLabelProps={{ className: "with-help-button" }}
      name='email'
      onChange={handleChange}
      errorText={errors.email && t(errors.email)}
      isValid={!(errors.email && touched.email)}
    />
    <DoneTextField
      label={
        <>{t('schedulingPage.form.notesLabel')}
          <LMTooltip
            childrenClassName="showHand"
            content={
              <>
                <p><Trans i18nKey="schedulingPage.form.notesHelp" values={{ name: link.organizer.name }}>A message that we'll send to your host with the meeting invite.</Trans></p>
              </>
            }
          >
            <HelpOutline />
          </LMTooltip></>}
      value={values.notes}
      className="with-help"
      InputLabelProps={{ className: "with-help-button" }}
      name='notes'
      onChange={handleChange}
      rows={2}
      rowsMax={15}
      rowsMin={1}
      multiline={true}
    />
    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} className="lm-recaptcha" />
    {(errors.recaptcha && touched.recaptcha) && <div className="recaptcha-error done-input"><p className="Mui-error MuiFormHelperText-root">{t(errors.recaptcha)}</p></div>}
    <GuestConsent
      checked={isGuestConsentChecked}
      onChange={guestConsentChanged} />
    <CTA label={"schedulingPage.form.book"}
      name="valid-book"
      handleSubmit={doSubmit}
      canSubmit={!isBooking}
      isGeneratingLink={isBooking} />
  </div>;
}

// list of pages components
const PAGES = [
  (props) => <Picker {...props} />,
  (props) => <Information {...props} />,
];

// Component shown when the link is invalid
function BookingError() {
  return <div className="bookinglink-error"><Trans i18nKey="schedulingLink.errors.invalidLink"><span className="oups">oups!</span><span className="strong">This Letsmeet link is not valid.</span><span className="details">Double check the URL or contact its owner.</span></Trans></div>
}

export function BookingForm({ link, onBooked }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { username, slug } = useParams()
  const history = useHistory();
  const dispatch = useDispatch();
  const [isBooking, setIsBooking] = React.useState(false);
  const [slots, setSlots] = React.useState(transformSlots(link));

  const setNavigation = React.useCallback((navig) => {
    history.push({
      pathname: `/${username}/${slug}`,
      state: {
        target: navig ? '/infos' : ""
      }
    })
  }, [])

  // init formik from link data
  const init = React.useMemo(() => {
    return {
      name: '',
      email: '',
      notes: '',
      slot: '',
      recaptcha: '',
      // non validating form content
      current_selected_date: '',
    };
  }, [link]);

  const { sendGuestConsent } = useGuestConsent();

  // init formik
  const formik = useFormik({
    initialValues: init,
    validationSchema: BOOKING_INFOS,
    onSubmit: (values) => {
      if (isBooking) return;
      setIsBooking(true);
      dispatch({
        type: ACTIONS.WORKER_BOOK_LINK,
        payload: {
          id: link.id,
          signature: link.signature,
          attendee: {
            recaptcha: values.recaptcha,
            invitees: [
              {
                email: values.email,
                label: values.name,
                note: values.notes,
              }
            ],
            slots: [
              {
                start: values.slot.start.toISOString(),
                end: values.slot.end.toISOString()
              }
            ]
          }

        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_BOOK_LINK_SUCCESS,
          rejectOn: ACTIONS.WORKER_BOOK_LINK_ERROR
        }
      }).then(() => {
        // meeting booked
        // send guest consent
        sendGuestConsent(values.email, values.name, GUEST_CONSENT_ORIGINS.BOOKING_LINK);
        // diplay finish page
        onBooked(values)
      }).catch((err) => {
        // crash
        enqueueSnackbar(t("schedulingPage.form.errors.creationError"), {
          variant: 'error',
          className: 'snack-error',
        })
      }).finally(() => setIsBooking(false))
    }
  });

  const doSubmit = React.useCallback((token) => {
    if (token) {
      formik.setFieldValue('recaptcha', token);
    }
    setTimeout(() => formik.handleSubmit(), 1);
  }, [formik.handleSubmit]);

  React.useEffect(() => {
    const state = location.state || {};
    const page = (state.target === '/infos' && formik.values.slot) ? 1 : 0;
    if (page === 0 && state.target === '/infos') {
      history.replace({
        pathname: `/${username}/${slug}`,
      })
    }
  }, [])

  const state = location.state || {};
  const page = (state.target === '/infos' && formik.values.slot) ? 1 : 0;

  return PAGES[page]({
    values: formik.values, link: link,
    isBooking: isBooking,
    errors: formik.errors, touched: formik.touched, handleSubmit: doSubmit, handleChange: formik.handleChange, setNavigation: setNavigation,
    slots, setSlots
  })

}

export default function Booking({ link, onBooked }) {
  if (!link || !link.is_link) {
    return <BookingError />
  }
  return <BookingForm link={link} onBooked={onBooked} />
}
