import React from 'react';
import {
  GRID_HEIGHT
} from '../../../../agenda/config';
import Period from './period';
import { isFreeSlotPeriod } from '../../../../../utils/is.free.slot';
import NOOP, { NO_BUBBLES } from '../../../../../utils/noop';
import { BROWSER } from '../../../../../utils/browser';
import {
  getEventPosition, getEventPositionFromTouchEnd,
  getEventPositionFromTouch
} from '../../../../../utils/get.event.position';

import { overlapp } from '../../../../../worker/handlers/generate.slots';
import { getNewDimensions } from '../../../../../utils/get.new.dimensions';
import {
  getCoordsToOffset, resizeToOffset, moveToOffset,
  resizeTopToOffset, _calculateGeometry, getWholeProposalHeight
} from '../../../../../utils/to.offset';
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isValid } from '../../../../../utils/heatmap/is.slot.valid';
import { proposalsToSlotsRT } from '../../../../../worker/handlers/generate.slots';
import './drawing.scss';

const INACTIVE = { pointerEvents: 'none' };
const ACTIVE = { pointerEvents: 'all' };

const ACTION_DRAW = 0;
const ACTION_DRAG = 1;
const ACTION_RESIZE = 2;
const ACTION_RESIZE_TOP = 3; // tmp, to see the diff in code
let DELETE_ID;
let SCROLL_ID;

let THROTTLE_TOUCH = 0;
let THROTTLE_MIN = 30;
let DELETE_TIMER = 1000; // in ms
const EMPTY = [];
const NOW = moment();
export function debugSetTHrottleMin(v) {
  THROTTLE_MIN = v;
}
export function debugSetDeleteTimer(v) {
  DELETE_TIMER = v;
}
const AUTOSCROLL_AMOUNT = 10;
// For the new heatmap with slots only
export function proposalsFromCalculatedSlots(
  cs, // the geometry to work with
  drawer, // parent ref
  selectedDate, // current date from heatmap
  duration = 60,
  freeBusys = [], // free busy
  reverse = false, // do we reverse calculus?s
  ignoreInitialAvail = false, // no busys check
  padStartToQuarter = false,
  increment = -1
) {
  let calculated = null;
  let inPast = false;
  if (cs && drawer.current) {
    let bbox = drawer.current.getBoundingClientRect()
    let st = moment(selectedDate.format());
    // ensure height is at least multiplicator of duration?
    let upd = getWholeProposalHeight(cs, duration, reverse);
    let ccs = {
      ...cs,
      top: upd.top,
      height: upd.height,
    }
    if (typeof ccs.left == 'string') {
      // calculate absolute left
      ccs = absoluteGeometry(ccs, bbox);
    }
    let cp = getCoordsToOffset(ccs, st, duration, bbox, false);
    if (!cp || cp === null) return {};
    //@TODO use cp.start and cp.end
    let slots = proposalsToSlotsRT([cp], ignoreInitialAvail ? [] : freeBusys, duration, padStartToQuarter, increment)
    calculated = slots;
    inPast = cp.start < moment();
    // calculate geometry to place in heatmap
    calculated = calculated.map((c) => {
      c.geometry = _calculateGeometry(c, selectedDate.clone().startOf('week'), { ox: 0, oy: 0 });
      c._is_shadow_valid = true;
      return c;
    })
  }
  return { props: calculated, isSlotInPast: inPast };
}
const MIN_DISPLACEMENT_FOR_DRAG = 2 * 2; // mim displacement of mouse (squared)
function considerItIsAClick(evt, pos) {
  let top = evt.top - pos.top;
  let left = evt.left - pos.left;
  return (top * top + left * left) < MIN_DISPLACEMENT_FOR_DRAG;
}
export default function Drawing({
  selectedDate,
  duration, title,
  periods = EMPTY,
  freeBusys = EMPTY,
  onChange,
  onUpdateProposal,
  onRemoveProposal,
  onProposalDrag = NOOP,
  activeAutoScroll,
  active = false,
  parentScrollTop = 0, // position of scroll from parent
  allowMultiple = true, // if false, only 1 slot selectable (if all LM users)
  ignoreInitialAvail = false,
  heatmap_slots_only = false,
  increment = -1,
}) {
  const isMobile = BROWSER.getPlatformType() === 'mobile' || BROWSER.getPlatformType() === 'tablet';
  const { t } = useTranslation();
  const drawer = React.useRef();
  const moevRef = React.useRef();
  const [userPeriods, _setUserPeriods] = React.useState();
  // const [lastScrollTop, setlastScrollTop] = React.useState(0);
  const setUserPeriods = React.useCallback((p) => {
    _setUserPeriods(p.filter((p) => p.geometry && p.geometry.height > 0))
  }, [_setUserPeriods])
  React.useEffect(() => {
    // ensure periods are visibles
    // AND have geometry to display
    //if(!drawer.current) return;
    let sow = selectedDate.clone().startOf('week');
    let eof = selectedDate.clone().endOf('week');
    setUserPeriods(periods.map((p) => {
      // if period is out of display, pass
      if (p.end < sow) return p;
      if (p.start > eof) return p;
      if (!p.geometry) {
        p.geometry = _calculateGeometry(p, selectedDate.clone().startOf('week'), { ox: 0, oy: 0 });
      }
      p.noDisplay = false;
      return p;
    }))
  }, [drawer.current, periods, setUserPeriods, selectedDate]);


  const [current, setCurrent] = React.useState();
  const [currentShadow, __setCurrentShadow] = React.useState();
  const [currentShadows, __setCurrentShadows] = React.useState();
  const setCurrentShadow = React.useCallback((cs) => {
    // check if valid
    if (cs && cs.end) cs._is_shadow_valid = isValid(cs, duration);
    // if overlapp a busy
    if (cs && cs.start && cs.end && !ignoreInitialAvail) cs._is_shadow_valid = !overlapp(cs, freeBusys)
    __setCurrentShadow(cs);
  }, [__setCurrentShadow, duration, freeBusys, ignoreInitialAvail]);

  const setCurrentShadows = React.useCallback((cs, reverse = false, withMagic = false) => {
    // calculate slots from timeframe
    if (!heatmap_slots_only) return;
    // ctrl key to allow magic!!!!!
    let iia = ignoreInitialAvail;
    let fb = [];
    if (withMagic) {
      iia = false;
      fb = freeBusys;
    }
    let calculated = proposalsFromCalculatedSlots(cs,
      drawer,
      selectedDate,
      duration,
      fb,
      reverse,
      iia,
      true,
      increment).props

    if (calculated) calculated = calculated.filter((p) => p.geometry && p.geometry.height > 0)
    __setCurrentShadows(calculated)
  }, [__setCurrentShadows, duration, freeBusys, ignoreInitialAvail, selectedDate, drawer, heatmap_slots_only, increment]);
  const [dragactive, setDragactive] = React.useState(false);
  const [action, setAction] = React.useState(0);
  const [isTouch, setIsTouch] = React.useState(false);
  const [ctrls, setCtrls] = React.useState(false);
  const week = React.useMemo(() => ({
    start: selectedDate.clone().startOf('week'),
    end: selectedDate.clone().endOf('week'),
  }), [selectedDate]);
  React.useEffect(() => {
    // delete drag
    setDragactive(false)
  }, [week])

  React.useEffect(() => {
    // if active is set, add first point of drawing
    if (active && drawer.current) {
      let left = drawer.current.offsetLeft
      // remove position if needed
      // active._originY -= top;
      active._originX -= left;
      // active.top -= top;
      active.left -= left;
      // padd start to nearset quarter, ie
      let top = active.top;
      let quarter = GRID_HEIGHT / 4;
      top = Math.floor(top / quarter) * quarter;
      active.top = top;
      active._originY = top;
      setCurrent(active);
    }
  }, [active, drawer])

  const onMouseMove = React.useCallback((e) => {

    let isCtrlDown = e.ctrlKey || ctrls.ctrlKey;
    let isShiftDown = !isCtrlDown && (e.shiftKey || ctrls.shiftKey); // do not allow 2 keys at same time

    if (dragactive && action === ACTION_DRAG) {
      e.stopPropagation();
      e.preventDefault();
      // if target is not heatmap, pass?
      let position = getEventPosition(e);
      // top/left set on cursor position
      let geo = dragactive.geometry;
      let origin = dragactive._clickOrigin || { x: 20, y: 20 };

      let top = position.top - origin.y //20;
      let left = position.left - origin.x //20;
      let gbcr = position.gbcr;
      {
        // add boundaries check
        // get width and height in pixels of event
        let ewidth = geo._width * gbcr.width / 100;
        // bottom
        if (top + geo.height > gbcr.height) {
          top = gbcr.height - geo.height;
        }
        // top
        if (top < 0) top = 0;
        // left
        if (left + ewidth > gbcr.width) {
          left = gbcr.width - ewidth;
        }
        // right
        if (left < 0) left = 0;

      }
      // change top for position?
      let evt = {
        ...dragactive,
        geometry: {
          ...dragactive.geometry,
          top: isShiftDown ? dragactive.geometry.top : top,
          left: isCtrlDown ? dragactive.geometry.left : left
        }
      }
      setDragactive(evt)
      // calculate final place and set shadow
      let fx = evt.geometry.left + origin.x;
      let final_evt = {
        ...evt,
        geometry: {
          ...evt.geometry,
          left: fx > 0 ? fx : 0,
        }
      }
      let offset = moveToOffset(final_evt, selectedDate, duration, drawer.current.getBoundingClientRect(), true);
      setCurrentShadow(offset);
      return;
    } else if (dragactive && action === ACTION_RESIZE) {
      e.stopPropagation();
      e.preventDefault();

      let position = getEventPosition(e);

      let geo = dragactive.geometry;
      let gbcr = geo.gbcr;

      // calculate left to pixels
      let leftPx = dragactive.geometry._left * gbcr.width / 100;
      if (position.left < (leftPx + 1)) position.left = (leftPx + 1);
      if (position.top < (dragactive.geometry.top + 1)) position.top = (dragactive.geometry.top + 1);
      // center on cursor


      // calculate new size based on position
      let dunit = gbcr.width / 7;

      let w = position.left - dragactive.geometry._left * gbcr.width / 100; // problem, got left as percentages
      let h = position.top - dragactive.geometry.top;
      // change top for position?
      let evt = {
        ...dragactive,
        geometry: {
          ...dragactive.geometry,
          width: isCtrlDown ? dragactive.geometry.width : w,
          height: isShiftDown ? dragactive.geometry.height : h
        }
      }
      setDragactive(evt)
      // calculate final place and set shadow
      // let offset = resizeToOffset(evt, selectedDate, duration, drawer.current.getBoundingClientRect());
      // setCurrentShadow(offset);
      setCurrentShadows(evt.geometry)
      return;
    } else if (dragactive && action === ACTION_RESIZE_TOP) {
      e.stopPropagation();
      e.preventDefault();

      let position = getEventPosition(e);
      let geo = dragactive.geometry;
      let gbcr = geo.gbcr;

      // if go below bottom
      if (position.top > (geo.top + geo.height) - 10) {
        position.top = dragactive.geometry.top;
      }
      // if go to right
      let left = position.left * 100 / gbcr.width;

      // calculate width and height
      let height = (geo.top - position.top) + geo.height;
      let width = (geo._width + (geo._left - left));

      if (width < (90 / 7)) {
        left = dragactive.geometry._left;
        width = dragactive.geometry._width
      }
      // change top for position?

      let evt = {
        ...dragactive,
        geometry: {
          ...dragactive.geometry,

          left: isCtrlDown ? dragactive.geometry.left : left + "%",
          _left: isCtrlDown ? dragactive.geometry._left : left,
          width: isCtrlDown ? dragactive.geometry.width : width + '%',
          _width: isCtrlDown ? dragactive.geometry._width : width,

          top: isShiftDown ? dragactive.geometry.top : position.top,
          height: isShiftDown ? dragactive.geometry.height : height,
        }
      }
      setDragactive(evt)
      // calculate final place and set shadow
      setCurrentShadows(evt.geometry, true, false)
      return;
    }
    if (!active) {

      return;
    }
    else {
      if (!current) {
        let { clientX, clientY, target } = e;
        let { top, left } = e.target.getBoundingClientRect();
        let position = {
          // click position
          _originY: clientY - top,
          _originX: clientX - left,

          top: clientY - top,
          left: clientX - left,
          width: 1,
          height: 1,
        }
        setCurrent(position);
        return;
      };
      let d = getNewDimensions(e, current);
      setCurrent(d);
      // is reverse, ie, top is less than origin y?
      setCurrentShadows(d, d.top < d._originY, true)
    }
  }, [current, active, action, dragactive, isTouch, setDragactive, selectedDate, duration, drawer, ctrls, currentShadow,
    heatmap_slots_only])
  const onMouseUp = React.useCallback((e, touch = false) => {
    let activeTarget = active;
    let currentTarget = current;
    if (dragactive && action === ACTION_DRAG) {
      // let period = {...dragactive};
      dragactive.noDisplay = false;
      let evt = dragactive;
      let origin = evt._clickOrigin || { x: 20, y: 20 };
      let fx = evt.geometry.left + origin.x;
      let final_evt = {
        ...evt,
        geometry: {
          ...evt.geometry,
          left: fx > 0 ? fx : 0,
        }
      }
      // New: if drag is less than 20px?, consider it is a click
      let position = getEventPosition(e);
      let absClickPosition = evt._absoluteClickPosition || { top: 0, left: 0 }
      // if do not move by more than XX pixels, pass?
      if (!considerItIsAClick(position, absClickPosition)) {

        let st = moment(selectedDate.format())
        let offset = moveToOffset(final_evt, st, duration, drawer.current.getBoundingClientRect(), true);
        if (offset === null) return;

        // if gone bad, annul changing
        if (!ignoreInitialAvail && overlapp(offset, freeBusys)) {
          // check if got a __cpy in it?
          if (dragactive.__cpy) {
            let cpy = JSON.parse(dragactive.__cpy);
            offset = {
              ...cpy,
              start: moment(cpy.start),
              end: moment(cpy.end),
            }
          }
        }
        /* istanbul ignore else no changes */
        if (offset) onUpdateProposal(offset);
        else onUpdateProposal({ ...final_evt });
        setAction(ACTION_DRAW);
        setDragactive(false);
        activeAutoScroll(false);
        setCurrentShadow(null);
        return;
      } else {
        activeTarget = dragactive;
        //Pad to nearest quarter
        let currentgeo = {
          _originY: position.top,
          _originX: position.left,
          ...position,
          width: 1,
          height: 1,
        };
        // pad start to nearest quarter
        let top = currentgeo.top;
        let quarter = GRID_HEIGHT / 4;
        top = Math.floor(top / quarter) * quarter;
        currentgeo.top = top;
        currentgeo._originY = top;
        currentTarget = currentgeo;

        setAction(ACTION_DRAW);
        setDragactive(false);
        activeAutoScroll(false);
        setCurrentShadow(null);
      }
    } else if (dragactive && action === ACTION_RESIZE || action === ACTION_RESIZE_TOP) {
      dragactive.noDisplay = false;
      dragactive.__is_resized = false;
      let st = moment(selectedDate.format())
      let offset = action === ACTION_RESIZE ?
        resizeToOffset(dragactive, st, duration, drawer.current.getBoundingClientRect(), heatmap_slots_only)
        : resizeTopToOffset(dragactive, st, duration, drawer.current.getBoundingClientRect(), heatmap_slots_only);
      if (!offset) offset = { ...dragactive };

      // calculate slots
      if (heatmap_slots_only) {
        // make from slots
        let geo = offset.geometry;
        let iia = false; // ignoreInitialAvail;
        let fb = [];

        let { props } = proposalsFromCalculatedSlots(geo,
          drawer,
          selectedDate,
          duration,
          fb,
          false,
          iia,
          false,
          increment);
        if (props) onChange(props, !allowMultiple, false)
        // shake ignore avail option in heatmap if no slot is valid and slot is not in past
      }

      setAction(ACTION_DRAW);
      setDragactive(false);
      activeAutoScroll(false);
      setCurrentShadow(null);
      setCurrentShadows(null)
      setCtrls(false); // clean
      return;
    }

    /* istanbul ignore if no work */
    if (!activeTarget && !touch) return
    let cp = currentTarget;
    if (!cp) {

      // can happen if click fast
      let { clientX, clientY, target } = e;
      let { top, left } = e.target.getBoundingClientRect();

      cp = {
        // click position
        _originY: clientY - top,
        _originX: clientX - left,

        top: clientY - top,
        left: clientX - left,
        width: 1,
        height: 1,
      }
    }
    if (!allowMultiple) {
      cp = {
        ...cp,
        width: 1,
        height: 1
      }
    }


    setCurrent(null);
    setCurrentShadows(null)

    if (heatmap_slots_only) {
      // make from slots
      let geo = cp;
      let iia = false; // ignoreInitialAvail;
      let fb = freeBusys; //[];

      if (geo && (geo.width < 20 && geo.height < 20)) {
        iia = true;
        fb = [];
      }


      let { props, isSlotInPast } = proposalsFromCalculatedSlots(geo,
        drawer,
        selectedDate,
        duration,
        fb,
        geo.top < geo._originY,
        iia,
        true,
        increment);

      if (!props) return
      // shake ignore avail option in heatmap if no slot is valid and slot is not in past
      const shouldShake = props.length === 0 && !isSlotInPast;
      onChange(props, !allowMultiple, shouldShake)
    } else {
      let st = moment(selectedDate.format())
      let offset = getCoordsToOffset(cp, st, duration, drawer.current.getBoundingClientRect(), false);
      // check if already on a slot, if so, do not proceed
      if (!offset || offset == null) return;
      onChange(offset, !allowMultiple);
    }
    // convert from position to timestamp
  }, [dragactive, action, active, current, selectedDate, duration, onUpdateProposal, activeAutoScroll, onChange, userPeriods, allowMultiple,
    heatmap_slots_only, drawer, freeBusys, ignoreInitialAvail, increment]);

  React.useEffect(() => {
    if (!dragactive || !dragactive.geometry) return;
    if (parentScrollTop) {
      if (action === ACTION_DRAG) {
        // do a move with scrollTop offset?
        let evt = {
          ...dragactive,
          geometry: {
            ...dragactive.geometry,
            top: parentScrollTop,
          }
        }
        setDragactive(evt)
      } else if (action === ACTION_RESIZE) {
        // do resize with scrollTop offset
        // only to bottom?
        let newHeight = dragactive.geometry.height +
          (parentScrollTop - dragactive.geometry.top)
        let evt = {
          ...dragactive,
          geometry: {
            ...dragactive.geometry,
            height: newHeight,
          }
        }
        setDragactive(evt)
      }
    }
    // setlastScrollTop(parentScrollTop);
  }, [parentScrollTop, action])

  // click on an element
  const onDrag = React.useCallback((e, period, i, touch = false) => {

    e.stopPropagation();
    e.preventDefault();
    /* istanbul ignore if no-work */
    if (!touch && e.button !== 0) return; // only left mouse button // OR touch

    onProposalDrag(true);
    // make a copy of period
    let cpy = JSON.stringify(period);
    period.__cpy = cpy;
    // start dragging period
    setAction(ACTION_DRAG);
    // is it a touch event
    setIsTouch(touch);
    activeAutoScroll(true)
    period.index = i;
    // update period?
    // let p = userPeriods.filter((p)=> p.start !== period.start && p.end !== period.end );
    // setUserPeriods(p)

    // ...OR make period not displayable?
    period.noDisplay = true;
    let gbcr = drawer.current.getBoundingClientRect();
    // calculate position relative to top/left
    let position = getEventPosition(e);
    // calculate geometry in absolute pixels
    period.geometry = absoluteGeometry(period.geometry, gbcr);
    period._clickOrigin = {
      // let t = {
      x: position.left,
      y: position.top,
    }
    // get absolute position for click, relative to drawer
    let absPos = getEventPosition({
      clientX: e.clientX,
      clientY: e.clientY,
      target: drawer.current
    })
    period._absoluteClickPosition = absPos

    setDragactive(period);

  }, [drawer, setAction, setDragactive, activeAutoScroll, selectedDate, duration]);
  // Note: onProposalDrag is a callback that never change
  // DO NOT HAD IT TO periodsCmp Memo (perf)
  const onEnterSlot = React.useCallback(() => {
    onProposalDrag(true)
  }, [onProposalDrag])

  const onResize = React.useCallback((e, period, top = false, ctrls = false) => {
    e.stopPropagation();
    e.preventDefault();
    onProposalDrag(true)
    // console.log('DO RESIZE');
    /* istanbul ignore if no-work */
    if (e.button !== 0) return; // only left mouse button
    /* istanbul ignore if  no-work */
    if (!drawer || !drawer.current) return;
    // if no multiple, do not allow resize
    if (!allowMultiple) return;

    // calculate geometry as absolute
    let gbcr = drawer.current.getBoundingClientRect();
    let np = {
      ...period,
      geometry: {
        ...absoluteGeometry(period.geometry, gbcr),
        gbcr: gbcr
      }
    };
    // start dragging period
    setAction(top ? ACTION_RESIZE_TOP : ACTION_RESIZE);
    if (ctrls) setCtrls(ctrls);
    activeAutoScroll(true);
    // remove period from users one
    // let p = userPeriods.filter((p)=> p.start !== period.start && p.end !== period.end );
    // period.noDisplay = true;
    //setUserPeriods(p)
    // mark drag active as custom renderer?
    // or make a copy?
    np.__is_resized = true;
    setDragactive(np);
    setCurrentShadow(np)
  }, [drawer, userPeriods, allowMultiple]);

  // Touch custom specific
  const onTouchStart = React.useCallback((e) => {
    setIsTouch(true);
    THROTTLE_TOUCH = Date.now();
    // check if click on a slot
    // check where it happens
    let parent = drawer.current.getBoundingClientRect();
    // calculate position relative to top/left
    let position = getEventPositionFromTouch(e, parent);

    let clientY = position.top;// - parent.top;
    let clientX = position.left;// - parent.left;
    // for each event, calculate if click is in
    let slot = findSlot(userPeriods, week, parent, clientX, clientY);
    /* istanbul ignore else no work */
    if (slot) {
      // happens on a period, check if old dragged one

      slot._clickOrigin = {
        x: (slot.geometry._width * parent.width / 100) / 2, // size in pixels
        y: slot.geometry.height / 2,
      }
      slot.index = -1;
      slot.noDisplay = true;
      setDragactive(slot)
      // start timer for delete
      /* istanbul ignore next timer too painful to test */
      DELETE_ID = setTimeout(() => {
        // delete item
        setDragactive((da) => {
          if (!da) return da; // do nothing
          da.delete = true;
          return { ...da };
        });
      }, DELETE_TIMER);
      // }
      // and return
      return;
    }
  }, [userPeriods, week]);
  const onTouchEnd = React.useCallback((e) => {
    /* istanbul ignore if timer too painful to test */
    if (SCROLL_ID) {
      clearInterval(SCROLL_ID);
      SCROLL_ID = 0;
    }
    // block mouse event
    /* istanbul ignore next cancelable means swip event*/
    if (!e.cancelable) return true;
    e.preventDefault();
    e.stopPropagation();

    if (dragactive) {

      // check if delete?
      if (dragactive.delete === true) {
        onRemoveProposal(dragactive);
        setDragactive(false);
        return;
      }
      // end of drag event
      dragactive.noDisplay = false;
      let offset = moveToOffset(dragactive, selectedDate, duration, drawer.current.getBoundingClientRect(), true);
      // if gone bad, annul changing
      // console.log('Move to offset', dragactive, selectedDate, duration, offset)
      /* istanbul ignore else no changes */
      if (offset) onUpdateProposal(offset);
      else onUpdateProposal({ ...dragactive });
      setDragactive(false);

      setCurrentShadow(null);
    } else {
      // create new event
      // can happen if click fast
      let parent = drawer.current.getBoundingClientRect();
      // calculate position relative to top/left
      let position = getEventPositionFromTouchEnd(e, parent);

      let clientY = position.top;// - parent.top;
      let clientX = position.left;// - parent.left;

      let cp = {
        // click position
        _originY: clientY,
        _originX: clientX,

        top: clientY,
        left: clientX,
        width: 1,
        height: 1,
      }

      let offset = getCoordsToOffset(cp, selectedDate, duration, drawer.current.getBoundingClientRect());
      if (!offset || offset === null) return;

      // create a new slot and set active
      onChange(offset);
    }

  }, [dragactive, duration, onChange, onRemoveProposal, onUpdateProposal, selectedDate]);
  const onTouchMove = React.useCallback((e) => {
    // I move, do not delete
    if (DELETE_ID) {
      clearTimeout(DELETE_ID);
      DELETE_ID = 0;
    }

    /* istanbul ignore else no work */
    if (dragactive) {

      /* istanbul ignore if swip event */
      if (!e.cancelable) return;
      e.stopPropagation();
      e.preventDefault();

      let time = Date.now();
      /* istanbul ignore if Date is mocked */
      if (time - THROTTLE_TOUCH < THROTTLE_MIN) return;
      THROTTLE_TOUCH = time;
      // move element
      // if target is not heatmap, pass?
      let parent = drawer.current.getBoundingClientRect();
      let position = getEventPositionFromTouch(e, parent);
      // center on cursor
      let geo = dragactive.geometry;
      let origin = dragactive._clickOrigin || { x: 20, y: 20 };

      let top = position.top - origin.y //20;
      let left = position.left - origin.x //20;
      let gbcr = position.gbcr;
      {
        // add boundaries check
        // get width and height in pixels of event
        let ewidth = geo._width * gbcr.width / 100;
        // bottom
        if (top + geo.height > gbcr.height) {
          top = gbcr.height - geo.height;
        }
        // top
        if (top < 0) top = 0;
        // left
        if (left + ewidth > gbcr.width) {
          left = gbcr.width - ewidth;
        }
        // right
        if (left < 0) left = 0;

      }
      // change top for position?
      // get move direction (top false/bottom true)
      //let direction = dragactive.geometry.top - top < 0;
      let evt = {
        ...dragactive,
        geometry: {
          ...dragactive.geometry,
          top: top,
          left: left
        }
      }
      setDragactive(evt);
      // calculate final place and set shadow
      let offset = moveToOffset(evt, selectedDate, duration, drawer.current.getBoundingClientRect());
      setCurrentShadow(offset);
      // check boundaries of heatmap!!!!

      let scroller = document.getElementById('scroller');
      let scGbcr = scroller.getBoundingClientRect();
      /* istanbul ignore if no scroller in test */
      if (scroller) {
        // console.log('SCroll infos', scroller.scrollTop, scGbcr.height, scroller.scrollHeight)
        if (scroller.scrollTop > 0 // not already on top
          && position.top - scroller.scrollTop < 15) {
          // do scroll
          if (SCROLL_ID) {
            return; // already scrolling
          }

          // problem: set interval remember the dragactive
          // should take a new one each time...

          SCROLL_ID = setInterval(() => {
            let scroller = document.getElementById('scroller');
            if (!scroller) return;
            scroller.scrollTop = scroller.scrollTop - AUTOSCROLL_AMOUNT;
            if (scroller.scrollTop <= 0) {
              // stop auto
              clearInterval(SCROLL_ID);
              SCROLL_ID = 0;
              return;

            }
            setDragactive((dg) => {
              if (!dg) return dg;// nothing todo
              let ns = dg.geometry.top - AUTOSCROLL_AMOUNT;
              let evt2 = {
                ...dg,
                geometry: {
                  ...dg.geometry,
                  top: ns >= 0 ? ns : 0
                }

              }
              return evt2;
            })
          }, 50);
        } else if ((scroller.scrollTop + scGbcr.height) < scroller.scrollHeight &&// not already at bottom
          (scroller.scrollTop + scGbcr.height) - (dragactive.geometry.top + dragactive.geometry.height) < 15) {
          // scroll bottom
          if (SCROLL_ID) {
            //console.log('PASS')
            return; // already scrolling
          }
          // console.log('Start scroll')
          SCROLL_ID = setInterval(() => {
            let scroller = document.getElementById('scroller');
            if (!scroller) return;

            let scGbcr = scroller.getBoundingClientRect();
            scroller.scrollTop = scroller.scrollTop + AUTOSCROLL_AMOUNT;
            if ((scroller.scrollTop + scGbcr.height) >= scroller.scrollHeight) {
              // stop auto
              clearInterval(SCROLL_ID);
              SCROLL_ID = 0;
              //console.log('Stop scroller bottom')
              return;

            }
            setDragactive((dg) => {
              if (!dg) return dg;// nothing todo
              let ns = dg.geometry.top + AUTOSCROLL_AMOUNT;
              // check new position is in bounds
              let scroller = document.getElementById('scroller');
              if (!scroller) return;
              let scGbcr = scroller.getBoundingClientRect();
              if ((scroller.scrollTop + scGbcr.height) - (dg.geometry.top + dg.geometry.height) <= 0) {
                ns = scroller.scrollTop + scGbcr.height - dg.geometry.height; // rollback to old value
              }

              let evt2 = {
                ...dg,
                geometry: {
                  ...dg.geometry,
                  // check in bounds
                  top: ns,
                }

              }
              return evt2;
            })
          }, 50);
        }
        /* istanbul ignore if timer too painful to test */
        else if (SCROLL_ID) {
          clearInterval(SCROLL_ID);
          SCROLL_ID = 0;
        }
      }

    }
  }, [dragactive]);

  const periodsCmp = React.useMemo(() => {
    /* istanbul ignore if no work */
    if (!periods) return null;
    // calculate periods disponibility?
    // dumb first try

    return userPeriods && userPeriods.length > 0 && userPeriods.map((p, i) => {
      // is period on free slot?
      let isFreeSlot = ignoreInitialAvail || isFreeSlotPeriod(p, freeBusys, duration);
      return <InnerEvent key={'period-' + p.uuid}
        i={i} p={p} uuid={dragactive.uuid} title={title} active={active} week={week}
        onDrag={onDrag}
        onEnterSlot={onEnterSlot}
        onRemoveProposal={onRemoveProposal}
        onResize={onResize}
        allowResize={p.noResize ? false : allowMultiple}
        isFreeSlot={isFreeSlot} />
    })
  }, [userPeriods, dragactive.uuid, title, active, week, onDrag, onRemoveProposal, onResize, periods,
    allowMultiple, freeBusys, duration, ignoreInitialAvail])


  React.useEffect(() => {
    let current = drawer.current;
    /* istanbul ignore if no work */
    if (current) {
      current.addEventListener('touchmove', onTouchMove, { passive: false });
      current.addEventListener('touchend', onTouchEnd, { passive: false });
      current.addEventListener('touchstart', onTouchStart, { passive: false });
    }

    return () => {
      /* istanbul ignore if no work */
      if (current) {
        current.removeEventListener('touchmove', onTouchMove);
        current.removeEventListener('touchend', onTouchEnd);
        current.removeEventListener('touchstart', onTouchStart);
      }
    }
  }, [dragactive, drawer, onTouchEnd, onTouchMove, onTouchStart])

  const className = "heatmap-drawing "
    + (action === ACTION_DRAG ? 'dragging ' : '')
    + ((action === ACTION_RESIZE || action === ACTION_RESIZE_TOP) ? 'resizing ' : '')
    + ((ctrls && ctrls.ctrlKey) ? 'resizing-ctrl ' : '')
    + ((ctrls && ctrls.shiftKey) ? 'resizing-shift ' : '')
    + (isTouch ? 'inactive-slots ' : '');
  const shadowStart = currentShadow ? (currentShadow.start ? currentShadow.start.format(t('common.slotHour')) : '') : '';
  return (
    <div className={className} ref={drawer}
      id="heatmap"
      data-testid="drawer"
      style={(isMobile || (active || dragactive)) ? ACTIVE : INACTIVE}
      onMouseMove={onMouseMove}
      onMouseUp={(e) => {
        onMouseUp(e);
      }}
    >
      {
        // selected slots/proposals
        periodsCmp
      }
      {
        // shadow when dragging or resising
        currentShadow &&
        <div key="shadow"
          className={"simple-hand-drawing current shadow " + (currentShadow._is_shadow_valid ? '' : ' invalid-shadow ')}
          style={currentShadow.geometry}><div className="container"></div>
          {/* <span className="start">{currentShadow.start.format(t('common.hourMinutesFormat'))}</span>
          <span className="end">{currentShadow.end.format(t('common.hourMinutesFormat'))}</span> */}
          {/* <div className={"proposal-title "}>{currentShadow.start.format(t('common.slotHour'))}</div> */}
        </div>
      }
      {
        // shadows of slots when resising or free drag and select
        currentShadows &&
        currentShadows.map((currentShadow) =>
          <div key={"shadow-" + currentShadow.uuid}
            className={"simple-hand-drawing current shadow " + (currentShadow._is_shadow_valid ? '' : 'invalid-shadow')}
            style={currentShadow.geometry}>
            <div className='container'>
              <div className={"proposal-title "}>{currentShadow.start.format(t('common.slotHour'))}</div>
              {/* <span className="start">{currentShadow.start.format(t('common.hourMinutesFormat'))}</span>
            <span className="end">{currentShadow.end.format(t('common.hourMinutesFormat'))}</span> */}
            </div>
          </div>
        )
      }
      {
        // free form drawn with mouse on the heatmap
        current &&
        <div className="simple-hand-drawing current selector " style={current}>
          <div className="container">
            {!ignoreInitialAvail && <span className="findFreeSlots">{t('createEvent.heatmap.findFreeSlots')}</span>}
          </div>
        </div>
      }
      {
        // slot/proposal while dragging/resizing a slot/proposal
        dragactive && (
          <div
            ref={moevRef}
            key={'period-drag'}
            className={"simple-hand-drawing inactive grab " + (isTouch ? 'touch ' : '') + (dragactive.delete ? 'deletable' : '')}
            style={dragactive.geometry}>
            <div className="container">
              {dragactive.__is_resized ?
                (ignoreInitialAvail ? null : <span className="findFreeSlots">{t('createEvent.heatmap.findFreeSlots')}</span>)
                :
                <Period index={dragactive.index || 0}
                  title={shadowStart}
                  period={dragactive}
                  onRemoveProposal={onRemoveProposal}
                  setDragactive={setDragactive} />}
              {(action === ACTION_RESIZE) && <div id="period-resize" className="handlers angle  visible" />}
            </div>
          </div>
        )
      }

    </div>
  )
}

function InnerEvent({ i, p, title, onRemoveProposal, week, uuid, active,
  onDrag, onEnterSlot, onResize, allowResize, isFreeSlot = true }) {
  const { t } = useTranslation();
  const elemRef = React.useRef(null);
  let { start, end } = p;
  // if not in boundaries, pass
  if (end < week.start || start > week.end) return null;
  if (uuid === p.uuid || p.noDisplay) return null;
  const durationMinutes = p.end.diff(p.start, 'minutes');

  return <div ref={elemRef} key={'period-' + i} className={"simple-hand-drawing " + (durationMinutes < 20 ? 'tiny ' : '') + ((active || uuid) ? 'inactive ' : '')
    + (isFreeSlot ? '' : ' is-busy')} style={p.geometry}
    onMouseDown={(e) => onDrag(e, p, i)} data-testid="current-event"
    onMouseMove={NO_BUBBLES}
    onMouseEnter={onEnterSlot}>
    <div className="container">
      {allowResize && <div className="resizers">
        <div className="resize-top" onMouseDown={(e) => onResize(e, p, true, { ctrlKey: true })} />
        <div className="resize-right" onMouseDown={(e) => onResize(e, p, false, { shiftKey: true })} />
        <div className="resize-bottom" onMouseDown={(e) => onResize(e, p, false, { ctrlKey: true })} />
        <div className="resize-left" onMouseDown={(e) => onResize(e, p, true, { shiftKey: true })} />
      </div>}
      {allowResize && <div
        title={t("createEvent.heatmap.resizeHelp")}
        data-testid="period-resize-top" id="period-resize-top" className="handlers angle angle-top" onMouseDown={(e) => onResize(e, p, true)} />}
      <Period index={i} period={p}
        onRemoveProposal={onRemoveProposal} isFreeSlot={isFreeSlot} />
      {allowResize && <div
        title={t("createEvent.heatmap.resizeHelp")}
        data-testid="period-resize" id="period-resize" className="handlers angle" onMouseDown={(e) => onResize(e, p)} />}
    </div>
  </div>;

}

function findSlot(userPeriods, week, parent, clientX, clientY) {
  return (userPeriods || []).find((period) => {
    // period must be in week
    let { start, end } = period;
    // if not in boundaries, pass
    if (end < week.start || start > week.end) return false;
    if (period.geometry) {
      let left = period.geometry._left * parent.width / 100; // position in pixels
      let right = period.geometry._width * parent.width / 100; // size in pixels
      // if click is in period, selected!
      return (
        left <= clientX && clientX <= (left + right)
        &&
        period.geometry.top <= clientY && clientY <= (period.geometry.top + period.geometry.height)
      )
    } else return false;
  });
}

function absoluteGeometry(geo, parent) {
  // return absolute geometry
  return {
    ...geo,
    width: geo._width * parent.width / 100,
    left: geo._left * parent.width / 100
  }
}
