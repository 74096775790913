import { IconButton, Popover } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { HelpOutline, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LMTooltip } from '../../../../tooltip/Tooltip';
import CalendarList from './CalendarList';
import { NoAccountForUser } from './NoAccountForUser';
import './calendar.selector.202212.scss';
import { MAIL } from '../../../../../validators/event';
import moment from 'moment';

const POPPER_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'left',
};
const POPPER_TRANSFORM = {
  vertical: 'top',
  horizontal: 'left',
};

export default function CalendarSelectorRenderer202212({
  isValid,
  disabled,
  isAuth,
  handleClick,
  doSign,
  name,
  profile,
  calendar,
  account,
  anchorEl,
  handleClose,
  processItemClicked,
  selectedId,
  labelTitle,
  labelHelp,
  showTimezone = true
}) {

  const { t } = useTranslation();

  const tz = React.useMemo(() => {
    if (!account || !calendar || !profile) return null;
    // if selected calendar is part of Freebusy calendars, 
    // use profile timezone
    let fb = account.settings ? account.settings.freebusy_calendars : account.calendars.filter((c) => c.primary).map((c) => c.id);
    if (fb.find((v) => v === calendar.id)) return profile.timezone || null;
    return calendar.timezone || null;
  }, [account, calendar, profile])

  let selectedCalendarTZ, selectedCalendarName, selectedCalendarAccountName;
  if (isAuth && account && account.provider && calendar) {

    selectedCalendarTZ = tz ? <span className="tz">({tz + " " + moment().tz(tz).format("[UTC]Z")})</span> : null;
    selectedCalendarName = calendar.name;
    selectedCalendarAccountName = t('createEvent.form.agenda.selectedAccount') + ' ' + account.email;

    if (MAIL.isValidSync({ mail: calendar.name })) {
      selectedCalendarAccountName = null;
    } else if (account.calendars.filter((c) => !c.readonly).length < 2) {
      selectedCalendarAccountName = null;
      selectedCalendarName = account.email;
    }
  }

  return (
    <fieldset className={`done-input ${isValid ? 'done-input-valid' : 'Mui-error'} done-calendar-selector calendar-selector-202212 calendar-selector-simple ${disabled ? "disabled" : ""}`}>
      <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined done-input-label">
        {t(labelTitle || 'createEvent.form.agenda.title')}
        <LMTooltip
          childrenClassName="showHand"
          content={
            <>
              <p><Trans i18nKey={labelHelp || "createEvent.form.agenda.tooltip"}> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
            </>
          }
        >
          <HelpOutline />
        </LMTooltip>
      </label>
      {
        !isAuth ? (<div className="no-account-user">
          <Button variant="contained" className="white-btn" onClick={doSign}>{t('createEvent.form.agenda.connect')}</Button>
          <div className="no-account-text">{t('createEvent.form.agenda.noCalendarUser')}</div>
        </div>)
          : !isValid ? <NoAccountForUser /> : null
      }
      {
        isValid && <>
          <div className="selector" onClick={handleClick} data-testid={name}>

            <div className="selected-calendar">
              {/* <span role="img" aria-label='calendar'>🎯</span>&nbsp; */}
              {selectedCalendarName}
            </div>

            {!disabled &&
              <IconButton >{
                anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />
              }</IconButton>
            }
          </div>
          <div className='metadata'>
            {selectedCalendarAccountName && <span className='account'>{selectedCalendarAccountName}</span>}
            {showTimezone && <span>{selectedCalendarTZ}</span>}
          </div>
          {!!anchorEl && <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}
            anchorOrigin={POPPER_ORIGIN} transformOrigin={POPPER_TRANSFORM} className="done-input">
            <div>
              <CalendarList processItemClicked={processItemClicked} selectedId={selectedId} />
            </div>
          </Popover>}
        </>
      }
    </fieldset>);
}