import { handleActions } from 'redux-actions';
import * as ACTIONS from '../actions';
// for fun, we load dumb datas
const defaultState = {};

const reducer = handleActions(
  {
    /*'__DEV__CONNECTED': (state) => ({...state, isAuth: true}),
    [ACTIONS.WORKER_INIT]:/* istanbul ignore next dont know now (state, action)=>{
      console.log(action.payload);
      if(!action.payload) return state;
      // google: profileObj
      let provider = action.payload.provider; //profileObj;
      let userDatas = getUserDatas(provider);
      console.log('User datas:', userDatas)
      return {
        ...state,
        token: action.payload,
        ...userDatas,
        // isAuth: true, while dev
      }
    },*/
    [ACTIONS.WORKER_CONNECT_SUCCESS]: (state, action) => {
      // if got account_profile infos, merge with state
      if (action.payload.account_profile) {
        state = {
          ...state,
          ...action.payload.account_profile,
          avatar_url: action.payload.account_profile.avatar,
        }
      }
      // if got no email OR email is 'letsmeet-token-email', take first one in accounts
      if (!state.email || state.email === 'letsmeet-token-email') {
        let accounts = action.payload.accounts || [];
        // add email, name from account
        if (accounts.length > 0) return {
          ...state,
          email: accounts[0].email,
          name: accounts[0].name,
        }

      }
      return state;
    },
    [ACTIONS.WORKER_DONE_TOKEN_RECEIVED]: (state, action) => {
      // google: profileObj
      let provider = action.payload.provider; //profileObj;
      let userDatas = getUserDatas(provider);
      // localStorage.setItem('user_datas', JSON.stringify(provider));
      // console.log('User datas:', userDatas)
      return {
        ...state,
        // don't know if I should keep this....
        token: action.payload,
        ...userDatas,
        isAuth: true,
      }
    },
    [ACTIONS.WORKER_LOGOUT]: (state, action) => {
      if (action.silent === true) return { ...state, isAuth: false };
      return defaultState;
    },
    [ACTIONS.INVALID_CREDENTIALS]: (state, action) => {
      return defaultState;
    },
    [ACTIONS.ANALYTICS_SIGNED_UP]: (state, action) => {
      return {
        ...state,
        hadJustSignup: true,
      }
    },
    [ACTIONS.CHECK_EXTENSION]: (state, action) => {
      return {
        ...state,
        hadJustSignup: false,
      }
    }
  },
  defaultState
);

export default reducer;
export const getUser = (state) => state.user;
export const isAuth = (state) => state.user.isAuth; // @TODO

export const getCustomerID = (state) => state.profile.billing.customer_id;
// actions creators -----------
// start sign in process
export const signInAction = (provider, response) => ({
  type: ACTIONS.WORKER_CONNECT,
  payload: {
    provider,
    id_token: response.tokenId,
    all: response,
    loadProfile: true,
    loadProposals: true,
  },
  resolvers: {
    resolveOn: ACTIONS.WORKER_CONNECT_SUCCESS,
    rejectOn: [ACTIONS.WORKER_CONNECT_ERROR, ACTIONS.WORKER_CONNECT_ERROR_NO_TOKEN]
  }
})
export const getAccountsAction = () => ({
  type: ACTIONS.WORKER_GET_ACCOUNTS,
  resolvers: {
    resolveOn: ACTIONS.WORKER_GET_ACCOUNTS_SUCCESS,
    rejectOn: ACTIONS.WORKER_GET_ACCOUNTS_ERROR
  }
});


export function getUserDatas(datas = {}) {
  let dt = {};
  if (datas.all && datas.all.profileObj) { // assume google
    dt = datas.all ? datas.all.profileObj : {};
    // dt.avatar_url = dt.imageUrl;
  } else if (datas.all && datas.all.account) { // assume MS
    dt = datas.all ? dt = datas.all.account : {};
    dt = datas.all.account;
    // because microsoft
    dt.email = dt.userName;
    // dt.avatar_url = false; // dont know it
  }
  return dt;
}

export const askForLogin = (state) => {
  return !state.user.isAuth && (state.app.ask4login || '');
}