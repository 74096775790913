import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { LogoDone } from '../../../assets/icons';
import Potatoe from '../../animated/potatoes';
import ROUTES from '../../../routes/routes.name';
import Button from '@material-ui/core/Button'
import useGoogle from '../../../hooks/use.google.login';
import useMSALLogin from '../../../hooks/use.msal.login';
import Permissions from './permissions';
import {
  PROVIDERS
} from '../../../assets/icons';
import CircularProgress from '@material-ui/core/CircularProgress'
import TLC from '../../tlc';
import './landing.scss';

// Super potatoe animation
const ANIMATION = {
  width: 556,
  height: 556,
  viewBox: '0 0 556 556',
  duration: 5000,

  paths: [
    {
      id: 'p1',
      classname: 'st0welcome',
      keyframes: [
        //'M 292.36,77.46 C 410.72006,80.19787 506.7225,179.94798 513.5191,268.62921 c 6.7966,88.68123 -73.88471,208.567 -211.77977,209.2809 C 163.84427,478.62401 30.71536,342.97694 39.982022,265.50562 49.248684,188.0343 174.01702,74.732468 292.36854,77.465169 Z',
        'M 292.99,63.72 C 453.20096,48.337196 505.47306,176.82438 496.65168,265.50561 487.83031,354.18684 415.27035,494.06363 299.86517,496.02696 184.46,497.9903 50.706371,372.96346 56.224719,270.50337 61.743066,168.04329 132.78556,79.105502 292.99326,63.721349 Z',
        'M 266.13,93.08 C 367.61444,78.323713 492.97868,206.8109 505.39775,305.48764 517.81682,404.16437 463.37372,492.81419 327.97753,488.53033 192.58135,484.24648 46.333338,417.31851 47.478651,286.12135 48.623965,154.92419 164.64623,107.84258 266.13034,93.083147 Z',
        'M 261.75,78.71 C 363.86614,65.829332 480.4843,161.83112 487.90562,272.37753 495.32693,382.92393 437.76024,473.4479 302.36405,489.15505 166.96786,504.8622 15.722101,401.70053 36.858426,271.75281 57.994752,141.80509 159.64848,91.599883 261.75731,78.714608 Z',
        'M 277.37,62.47 C 390.72906,63.330456 489.85509,163.08056 502.89888,268.0045 515.94267,372.92843 415.89507,490.94003 284.2472,482.28314 152.59932,473.62624 51.33109,364.8421 48.10337,273.00225 44.87565,181.1624 164.02151,61.613367 277.37529,62.471911 Z'
      ]
    }, {
      id: 'p2',
      classname: 'st1welcome',
      keyframes: [
        'M 359.57889,44.461476 C 457.69292,75.637078 504.52675,168.49935 487.6844,289.18716 470.84205,409.87497 288.92494,517.01923 188.18257,483.55413 87.440199,450.08902 39.593858,365.77295 62.727522,245.01285 85.861186,124.25275 261.46486,13.285874 359.57889,44.461476 Z',
        'M 315.40458,42.694504 C 435.60577,44.71506 493.92492,237.41127 478.84954,320.99266 463.77416,404.57405 355.1864,514.36877 229.70642,492.38899 104.22644,470.40921 51.079179,391.39405 60.96055,267.10001 70.84192,142.80596 195.20339,40.673947 315.40458,42.694504 Z',
        'M 336.60825,53.296338 C 455.92595,63.268271 485.97354,212.67366 476.19908,308.62386 466.42462,404.57405 314.54604,510.83483 203.20183,491.5055 91.85763,472.17618 44.011289,391.39405 53.89266,267.10001 63.77403,142.80596 217.29055,43.324406 336.60825,53.296338 Z',

      ]
    }, {
      id: 'p3',
      classname: 'st2welcome',
      keyframes: ['M 272.99724,10.005513 C 415.07005,-3.7284415 553.18432,57.692543 529.20825,270.63395 505.23218,483.57536 418.71814,517.93642 319.82201,529.49541 220.92588,541.0544 8.95072,412.12873 19.436697,275.93486 29.922673,139.74099 130.92443,23.739467 272.99724,10.005513 Z',
        'M 287.13302,12.655971 C 427.43886,5.989907 535.51459,62.99346 529.20825,270.63395 522.90191,478.27444 431.97043,504.68413 312.75412,529.49541 193.53781,554.30669 20.436041,418.31313 22.087155,283.00275 23.73827,147.69237 146.82718,19.322036 287.13302,12.655971 Z',
        'M 279.18164,11.772485 C 400.05079,9.5238519 530.21368,94.798964 529.20825,270.63395 528.20282,446.46894 468.19336,509.10156 324.23944,525.96147 180.28552,542.82137 30.154389,405.06084 21.203669,268.86697 12.252949,132.67311 158.3125,14.021119 279.18164,11.772485 Z',
        'M 296.85137,10.888999 C 417.72051,8.6403657 535.51459,114.23566 527.44128,264.44955 519.36796,414.66343 461.12547,506.4511 317.17155,523.31101 173.21763,540.17091 21.319527,421.84708 22.087155,275.05138 22.854784,128.25567 175.98223,13.137632 296.85137,10.888999 Z']
    }
  ]
}
export default function LandingPage() {

  let { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  // test only
  const SuccessCllbck = React.useCallback(() => {
    setLoading(false);
    history.push(ROUTES.APP)
  }, [])
  const errorCllbck = React.useCallback((from) => {
    setLoading(false);
    // error
  }, [])
  const googleloggin = useGoogle(SuccessCllbck, errorCllbck, false); // no auto-login
  const mslogin = useMSALLogin(SuccessCllbck, errorCllbck, false); // no auto-login

  const login = React.useCallback((type) => {
    setLoading(true);
    if (type === 'google') googleloggin();
    else mslogin(type);
  }, [googleloggin, mslogin])

  return (
    <div className='landing fancy-scroll'>
      <header>
        <a href={t('landing.corporateUrl')}><LogoDone /></a>
      </header>
      <section>
        <div className="full-width">
          <div className="logo">
            {<Potatoe className="thirdPotatoe" animation={ANIMATION} />}

            {loading ? <CircularProgress /> :
              <div>
                <div className='slogan'><Trans i18nKey="landing.slogan0">Remember to use your corporate email address!</Trans></div>
                <div className="providers">
                  <Button
                    variant="contained"
                    startIcon={PROVIDERS.google}
                    data-testid="google-btn"
                    onClick={() => login('google')}
                  >
                    {t('dialog.signin.providerGoogle')}
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={PROVIDERS.outlook}
                    data-testid="microsoft-btn"
                    onClick={() => login('microsoft')}
                  >
                    {t('dialog.signin.providerMicrosoft')}
                  </Button>
                </div>
              </div>}
          </div>
          <TLC />
        </div>
        <Permissions />
      </section>

    </div>
  );
}