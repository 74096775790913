import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import FoldableInput from './inputs/foldable.input';
import DoneTextField from './inputs/Text';
import { isUnknownDomain } from "../../../api/validators/proposal"
import {
  EVENT_DURATION_MAX,
  EVENT_DURATION_MIN,
} from '../../../validators/event';
import {
  HelpOutline, Lock
} from '@material-ui/icons';
import { TitlePredictive } from './inputs/Title.predictive';
import FastGuestSelector from './guests/guest.selector.v3';
import TimeframeSelector from './inputs/Timeframe.selector';
import CalendarSelector from './inputs/calendars/CalendarSelector';
import DurationSelector from './inputs/DurationSelector';
import LocationSelector from './inputs/location/location.v3';
import Periods from './periods';
import { PeriodMessage } from './periods/period.renderer.202212'
import { SendChanges } from './periods/period.renderer.202212';
import ROUTES from '../../../routes/routes.name';
import multipleSlots from '../../../utils/multiple.slots';
import FreeUserTip from '../dashboard/free.user.tip';
import { LMTooltip } from '../../tooltip/Tooltip';
import NOOP from '../../../utils/noop';
import { getVoters } from '../../../utils/nbr.of.voters'
import { NEXT_48H, NEXT_WEEK } from '../../../utils/gen.timeframes';
import {
  PeriodRenderer,
  NoPeriod
} from './periods/period.renderer.202212';
import {
  PeriodRenderer as PeriodRenderer202307,
} from './periods/period.renderer.202307';
import PeriodSelector from './inputs/periods/period.selector';
import './form.renderer.202212.scss'
import CalendarSelectorRenderer202212 from './inputs/calendars/CalendarSelectorRenderer202212';
import { MAX_NB_HELD_SLOTS, TinyHoldSlotOption202212 } from './periods/HoldSlotsOption';
import { linearize } from '../../../worker/handlers/generate.slots';
import { isMobilePlateform } from '../../../utils/browser'
import ConnectYourCalendar202212 from './inputs/calendars/ConnectYourCalendar202212';
import useDevSettings from '../../../hooks/use.dev.settings';
const DURATIONS = [30, 60];
const TIMEFRAMES = [NEXT_48H, NEXT_WEEK];
let SHOW_HEATMAP_IN_FORM = !isMobilePlateform();
export function __test__setShowHeatmapInForm(v) {
  SHOW_HEATMAP_IN_FORM = v;
}
export function getShowHeatmapInForm() {
  return SHOW_HEATMAP_IN_FORM;
}
export default function UpdatedFormComponent({
  isEditing = false, hasFunnelStarted = false,
  cancelEditing,// editing props
  formik,
  profile, // host profile
  canSubmit = false, setcanSubmit = NOOP,
  oboCalendar = "",
  showPreview = NOOP,
  isGeneratingLink = false,
  freeUserExpired = null, // component for free user
  // cllbacks
  handleCalendarChange = NOOP,
  handleTitlePredicitiveChange = NOOP,
  setAttendeesStatus = NOOP,
  handleToggleInviteeOptional = NOOP, handleToggleInviteeVoteRequested = NOOP,
  hndChange = NOOP, // general change handler
  hndDurationChange = NOOP,
  doConnectContacts = NOOP,
  hndChangeAndDispatch = NOOP, // chng handler with slots special calculation
  saveFormInCache = NOOP,
  showcustomSlotsDlg = NOOP, // display heatmap
  handleFormSubmit = NOOP,
  user,
  accounts,
  mustShowWarningPopup = false,
  setMustShowWarningPopup = NOOP,
  debounce_delay,
  initialFreeBusyDate,
  proposalId
}) {
  const { t } = useTranslation();
  const [calculatingSlots, setCalculatingSlots] = React.useState(false);
  const isAuth = user && user.isAuth;
  const contacts = useSelector((state) => state.contacts);
  const { getDevSettingItem, DEV_FLAGS } = useDevSettings();
  const __dev_july_form = getDevSettingItem(DEV_FLAGS.JULY_FORM);
  const isNewUser = __dev_july_form || (profile && profile.newFormUser);
  const [pollInfo, setPollInfo] = React.useState({
    "will_turn_to_poll": false,
    "recommended_slot_count": 3
  });
  const frmkhndChange = React.useCallback((e) => {
    formik.handleChange(e);
    formik.setFieldTouched(e.target.name, true)
  }, [formik.handleChange])
  const canHoldRef = React.useRef(false); // Use to remember current state
  const canHoldSlots = React.useMemo(() => {
    if (calculatingSlots) return canHoldRef.current;
    // les cas:
    // - direct meeting (ie: 1 slot, no obligatory voters (ie: email only)) => disabled
    // - direct meeting with at least 1 anonymous => enabled
    // - meeting request less than MAX => enabled
    // - meeting request more than MAX => disabled
    const voters = getVoters(formik.values.invitees, formik.values.inviteesDetails, contacts);
    if (formik.values.slots) {
      let total = linearize(formik.values.slots).length;
      let noUnknownGuest = voters.filter((inv) => {
        return isUnknownDomain(inv);
      }).length === 0;

      if (
        total === 0 // no solt found
        || (total === 1 && noUnknownGuest) // direct meeting 1 slot
        || total > MAX_NB_HELD_SLOTS // too much slots
        || voters.length === 0 // all are optionals
      ) {
        // uncheck hold slots and unactive
        formik.handleChange({
          target: {
            name: 'holdSlots',
            value: false
          }
        })
        canHoldRef.current = false;
        return false;
      }
    }
    canHoldRef.current = true;
    return true;// hold slot can be activated
  }, [formik.values.slots, formik.handleChange, contacts, formik.values.invitees, formik.values.inviteesDetails, formik.values.vote_required,
    calculatingSlots])
  return <form autoComplete="off" className='create-event-form-container Version202212 Version202305'>
    <div className='title'>{t(isAuth ? 'createEvent.form.pageTitle' : 'createEvent.form.pageTitleNotAuth')}</div>
    {isEditing && <div className='FormNotice'>
      <p><Trans i18nKey={'createEvent.form.editNotice'} values={{ meetingName: (formik.values.__originalProposal || {}).title }}>
        Your are editing <Link className='goto' to={ROUTES.APP + '?id=' + formik.values.id}>meeting name</Link>.
      </Trans>
        <div><Link to={ROUTES.CREATE_A_MEETING} onClick={cancelEditing}>{t('createEvent.form.cancelEdit')}</Link></div>
      </p>
      {hasFunnelStarted &&
        <p>
          <Trans i18nKey='createEvent.form.editNoticeFunnelStarted'></Trans>
        </p>}
    </div>}
    {isAuth && <FreeUserTip />}
    {freeUserExpired}

    <div className={"create-event-form " + ((!isAuth || freeUserExpired) && "unactive")
      + (formik.values.holdSlots ? ' hold-slots' : '')} data-testid="mainform">

      <div className='form-header'>
        <CalendarSelector
          name='calendar'
          profile={profile}
          isAuth={isAuth}
          value={formik.values.calendar}
          onChange={handleCalendarChange}
          disabled={isEditing}
          Renderer={CalendarSelectorRenderer202212}
          ConnectYourCalendarRenderer={ConnectYourCalendar202212}
        />
      </div>
      <div className="form-content" id="form-content">
        <div className="form-item-titles">{t('createEvent.form.titles.what')}</div>
        <TitlePredictive value={formik.values.title} error={formik.errors.title}
          touched={formik.touched.title} onChange={handleTitlePredicitiveChange}
          showImportantCTA={false}
          isImportant={formik.values.important} disableImportant={true}
          accounts={accounts}
          debounce_delay={debounce_delay} />
        <FoldableInput name="foldable-notes" title={t('createEvent.form.description.title')} open={formik.values.notes === '' ? 0 : 1}>
          <DoneTextField label={
            <>{t('createEvent.form.notes')}
              <LMTooltip
                childrenClassName="showHand"
                content={
                  <>
                    <p><Trans i18nKey="createEvent.form.description.tooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
                  </>
                }
              >
                <HelpOutline />
              </LMTooltip></>
          }
            className="with-help"
            value={formik.values.notes}
            name='notes'
            onChange={hndChange}
            placeholder={t('createEvent.form.notesPlaceholder')}
            multiline={true}
            rows={2}
            rowsMax={10}
            rowsMin={1}
          />
        </FoldableInput>
        <div className="form-item-titles">{t('createEvent.form.titles.who')}</div>
        <FastGuestSelector
          invitees={formik.values.invitees}
          inviteesDetails={formik.values.inviteesDetails}
          toggleOptional={handleToggleInviteeOptional}
          toggleVoteRequested={handleToggleInviteeVoteRequested}
          setInvitees={hndChange} // no error on guest as we allow anything...
          errorText={(formik.touched.invitees && formik.errors.invitees) ? t('createEvent.forms.errors.guestsRequired') : undefined}
          setcanSubmit={setcanSubmit}
          oboCalendar={oboCalendar}
          doConnectContacts={doConnectContacts}
          showPreview={/*formik.isValid && canSubmit &&*/ multipleSlots(formik.values.slots)
            && showPreview}
          disabled={hasFunnelStarted}
          placeholder={(profile && (profile.hadSubmitForm || !profile.newFormUser)) ? 'createEvent.guests.list.search' : 'createEvent.guests.list.searchNamesOnly'}
          tooltip={(profile && (profile.hadSubmitForm || !profile.newFormUser)) ? 'createEvent.form.guests.tooltip' : 'createEvent.form.guests.tooltipNamesOnly'}
        />
        <div className="form-item-titles">
          {t('createEvent.form.titles.when')}
          {(canHoldSlots) && <TinyHoldSlotOption202212 onChange={formik.handleChange} disabled={!canHoldSlots} checked={formik.values.holdSlots} />}
        </div>
        <DurationSelector
          name="duration"
          value={formik.values.duration}
          customValue={formik.values.customduration}
          onChange={hndDurationChange}
          isValid={!formik.errors.customduration}
          errorText={t(formik.errors.customduration, { min: EVENT_DURATION_MIN, max: EVENT_DURATION_MAX })}
          disabled={hasFunnelStarted}
          durations={DURATIONS}
        />
        {SHOW_HEATMAP_IN_FORM && isNewUser ? <InFormHeatmap values={formik.values}
          initialFreeBusyDate={initialFreeBusyDate}
          disabled={hasFunnelStarted}
          oboCalendar={oboCalendar}
          contacts={contacts}
          setFieldValue={formik.setFieldValue}

          proposalId={proposalId}
        />
          : <TimeframeSelector
            proposals={formik.values.slots}
            uivalue={formik.values.currenttimeframe}
            onChange={hndChangeAndDispatch}
            // disabled={false /*!user.isAuth*/}
            errorText={formik.errors.proposals}
            disabled={hasFunnelStarted}
            timeframes={TIMEFRAMES}
          />}
        <div className="form-item-titles">{t('createEvent.form.titles.where')}</div>
        <LocationSelector
          value={formik.values.location}
          conference={formik.values.conference}
          profile={profile}
          calendar={formik.values.calendar}
          onChange={frmkhndChange}
          onRegister={saveFormInCache}
          error={formik.errors.location}
        />
        {!isNewUser && !hasFunnelStarted && <PeriodMessage loading={calculatingSlots} slots={formik.values.slots} formDatas={formik.values} freeUserExpired={freeUserExpired}
          showcustomSlotsDlg={showcustomSlotsDlg}
          contacts={contacts} showPreview={showPreview}
          pollInfo={pollInfo} />}
        {hasFunnelStarted ? <SendChanges isGeneratingLink={isGeneratingLink} handleSubmit={handleFormSubmit} formDatas={formik.values} /> :
          (formik.values.invitees && formik.values.invitees.length > 0) ?
            <>
              <Periods
                formDatas={formik.values}
                oboCalendar={oboCalendar}
                onChange={formik.handleChange}
                showcustomSlotsDlg={showcustomSlotsDlg}
                showPreview={formik.isValid && canSubmit && multipleSlots(formik.values.slots)
                  && showPreview}
                setAttendeesStatus={setAttendeesStatus}
                handleSubmit={handleFormSubmit}
                isGeneratingLink={isGeneratingLink}
                disableSlots={hasFunnelStarted}
                freeUserExpired={!!freeUserExpired}
                Renderer={isNewUser ? PeriodRenderer202307 : PeriodRenderer}
                setIsLoading={setCalculatingSlots}
                mustShowWarningPopup={mustShowWarningPopup}
                setMustShowWarningPopup={setMustShowWarningPopup}
                setPollInfoForm={setPollInfo}
              />
            </>
            : <NoPeriod isAuth={!!isAuth} formDatas={formik.values} onSubmit={handleFormSubmit} isGeneratingLink={isGeneratingLink} />}

      </div>


    </div>

  </form >
}
function InFormHeatmap({ values, contacts, initialFreeBusyDate, oboCalendar, setFieldValue, disabled, proposalId }) {

  const {
    duration,
    details,
    invitees
  } = React.useMemo(() => {
    const duration = values.duration === -1 ? values.customduration : values.duration;
    let details = values.inviteesDetails || {};
    let invitees = (values.invitees || []).filter((inv) => {
      let d = details[inv] || {};
      return !d.optional;
    });
    return {
      duration, details, invitees
    }
  }, [values.customduration, values.duration, values.inviteesDetails, values.invitees])

  const handlePeriodChange = React.useCallback((p) => {
    // check p and if p is same object (ie: no changes)
    if (!p || p.length === 0) {
      setFieldValue('proposals', [])
      return;
    }

    let inviteesDetails = details || {};

    // check if all guests' availability is known
    const allGuestsAvailabilityIsKnown = invitees.filter(invitee => !(contacts[invitee] || {}).isDoner).length === 0;
    // in this special case, switch all registered/hybrids to "don't use known availability"
    if (allGuestsAvailabilityIsKnown) {
      for (let inviteesEmail of invitees) {
        inviteesDetails[inviteesEmail] = {
          ...inviteesDetails[inviteesEmail],
          vote_requested: true
        }
      }
      setFieldValue("inviteesDetails", inviteesDetails, false);
    }
    // ensure custom period is selected
    setFieldValue('currenttimeframe', -1, false)
      .then(() => setFieldValue('proposals', p));
    // setMustShowWarningPopup(true); what to do whith that?????
  }, [details, invitees, contacts, setFieldValue]);

  React.useEffect(() => {
    let inviteesDetails = details || {};
    // check if all guests' availability is known
    const allGuestsAvailabilityIsKnown = invitees.filter(invitee => !(contacts[invitee] || {}).isDoner).length === 0;
    // in this special case, switch all registered/hybrids to "don't use known availability"
    if (allGuestsAvailabilityIsKnown) {
      for (let inviteesEmail of invitees) {
        inviteesDetails[inviteesEmail] = {
          ...inviteesDetails[inviteesEmail],
          vote_requested: true
        }
      }
      setFieldValue("inviteesDetails", inviteesDetails, false);
    }
  }, [invitees, contacts, details, setFieldValue])

  return <PeriodSelector
    inForm={true}
    selectorTitle='createEvent.guests.agenda.titleInForm'
    title={values.title} duration={duration}
    invitees={invitees}
    inviteesDetails={details}
    organizer={values.organizer}
    calendar={values.calendar}
    values={values.proposals}
    initial={initialFreeBusyDate}
    oboCalendar={oboCalendar}
    isImportant={values.important}
    ignoreInitialAvail={true /*formik.values.ignore_initial_avail*/}
    heatmap_slots_only={true}
    allowMultipleSlots={true}
    overlapp={values.overlapp}
    onProposalChanged={handlePeriodChange}
    disabled={disabled}
    proposalId={proposalId}
  />
}
