import { HelpOutline } from '@material-ui/icons';
import React from 'react';
import { Trans } from 'react-i18next';
import NOOP from '../../../utils/noop';
import DoneButton from '../../DoneButton';
import { LMTooltip } from '../../tooltip/Tooltip';

import "./header.scss";

export default function Header({
  titleIcon = null,
  title = "",
  ctaIcon = null,
  ctaLabel = "",
  hndClick = NOOP,
  children,
  className = "",
  helpKey
}) {
  return (<div className={"dashboard-v3-items " + className}><div className="dashboard-v3-header">
    <div className="header-title">
      <span className="header-icon">{titleIcon}</span>
      <span className='header-title-label'>{title}</span>
      <LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p>
              <Trans i18nKey={helpKey} components={{
                b: <b />,
                ul: <ul />,
                li: <li />,
              }}>This is an help message</Trans>
            </p>
          </>
        }
      >
        <HelpOutline />
      </LMTooltip>
    </div>
    <DoneButton onClick={hndClick}
      label={<span className="header-title-cta">
        {ctaIcon}
        {ctaLabel}
      </span>} />
  </div>
    <div className="dashboard-content">
      {children}</div>
  </div>)
}