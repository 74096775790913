import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PROVIDERS } from '../../../../../assets/icons';
import useGoogle from '../../../../../hooks/use.google.login';
import useMSALLogin from '../../../../../hooks/use.msal.login';
import routes from '../../../../../routes/routes.name';
import Tlc from '../../../../tlc';
import './ConnectYourCalendar202212.scss';

export default function ConnectYourCalendar202212({ doSign }) {
  const { t } = useTranslation();

  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const SuccessCllbck = React.useCallback(() => {
    setLoading(false);
    history.push(routes.APP)
  }, [history])
  const errorCllbck = React.useCallback(() => setLoading(false), [])

  const googleloggin = useGoogle(SuccessCllbck, errorCllbck, false); // no auto-login
  const mslogin = useMSALLogin(SuccessCllbck, errorCllbck, false); // no auto-login

  const login = React.useCallback((type) => {
    setLoading(true);
    if (type === 'google') googleloggin();
    else mslogin(type);
  }, [googleloggin, mslogin])

  return (
    <div className="done-calendar-selector v202212 user-not-auth">
      <div className='container'>
        <div className="providers">
          <Button
            variant="contained"
            startIcon={PROVIDERS.google}
            data-testid="google-btn"
            onClick={() => login('google')}>
            {t('dialog.signin.providerGoogle')}
          </Button>
          <Button
            variant="contained"
            startIcon={PROVIDERS.outlook}
            data-testid="microsoft-btn"
            onClick={() => login('microsoft')}>
            {t('dialog.signin.providerMicrosoft')}
          </Button>
        </div>
      </div>
      <Tlc />
    </div>)
}
