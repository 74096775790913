import React from 'react';
import { NO_PROPAGATION } from '../../../../utils/noop';
import Popper from '@material-ui/core/Popper';
import usePagination from '../../../../hooks/use.pagination.v2';
import { ClickAwayListener } from '@material-ui/core';
const EMPTY = [];
export const PopperHOC = ({ value, onChange, inputRef, filteredList = EMPTY, searchFct, ChildrenItems, children,
  name, decorateSelectWithTarget = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popperselected, setpopperSelected] = React.useState(0); // By default, first one is selected
  const scrollerRef = React.useRef(null);
  const [anchorElWidth, setAnchorElWidth] = React.useState({ width: 400 });

  let pagination = usePagination(filteredList, 10, searchFct);
  const [filteredProposals, setfilteredProposals] = React.useState([]);
  React.useEffect(() => {
    setfilteredProposals(pagination.current)
  }, [pagination.current])
  const doReset = React.useCallback((focus = true) => {
    // remove popper
    pagination.reset();
    setfilteredProposals([]);
    // clear value ?
    // setValue('');
    setpopperSelected(0);
    /* istanbul ignore if dummy test */
    if (focus && inputRef && inputRef.current) {
      inputRef.current.focus();
    }

  }, [pagination.reset, filteredProposals, inputRef]);

  const onValueChange = React.useCallback((e) => {
    if (e.target.value.trim() === '') {
      doReset();
    } else {
      let tmp = pagination.next(e.target.value) || [];
      //setfilteredProposals(tmp);

    }
    onChange(e);
  }, [onChange, pagination, doReset]);
  /* istanbul ignore next no scroll */
  const onScroll = React.useCallback((e) => {
    // if bottom, load more
    const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 5);
    if (bottom && filteredProposals) {
      let tmp = pagination.next(value) || [];
      //setfilteredProposals(tmp);
    }
  }, [value, filteredProposals, /*setfilteredProposals*/, pagination]);

  const doSelect = React.useCallback((c) => {

    onChange(c);
    // remove popper
    doReset();
  }, [onChange, doReset]);

  // // enter to validate?
  const onKeyDown = React.useCallback((e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      doReset();
      if (filteredProposals.length > 0 && !e.ctrlKey) {
        if (!decorateSelectWithTarget) onChange(filteredProposals[popperselected]);
        else onChange({ target: { name, value: filteredProposals[popperselected], reason: 'select-option' } }); // name?
      } else onChange(e);
      return false;
    } else if (e.keyCode === 9) {
      // tab key
      doReset();
    } else if (e.keyCode === 40) {
      let index = popperselected + 1;

      if (index >= pagination.current.length) index = pagination.current.length - 1;
      setpopperSelected(index);

      /* istanbul ignore if no scroll */
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = index * 52;
      }
      e.preventDefault();
      e.stopPropagation();
    } else if (e.keyCode === 38) {
      let index = popperselected - 1;
      if (index < 0) index = 0;
      setpopperSelected(index);
      // ensure scroll to
      /* istanbul ignore if no scroll */
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = index * 52;
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }, [value, popperselected, onChange, filteredProposals, doReset, pagination.current]);
  const onBlur = React.useCallback((e) => { // ---> See onBlur
    e.stopPropagation();
    e.preventDefault();
    // doFocus(false);
    onChange(e);
    // if no props when blur, remove anchor...
    if (filteredProposals.length === 0) setAnchorEl(null)
  }, [onChange, value, doReset, filteredProposals])
  const onFocus = React.useCallback((event) => {
    NO_PROPAGATION(event)
    setAnchorEl(event.target)
  }, [anchorEl]);
  React.useLayoutEffect(() => {
    /* istanbul ignore else no work */
    if (anchorEl) {
      // calculate size of caller
      let rect = anchorEl.getBoundingClientRect() || { width: 400 };
      let w = rect.width;
      setAnchorElWidth({ width: w });
    }
  }, [anchorEl]);
  /* istanbul ignore next ClickAwayListener mocked */
  const dismissPopup = React.useCallback((e) => {
    let src = e.srcElement;
    if (src && src === anchorEl) return;
    setAnchorEl(null);
  }, [anchorEl]);
  // const hasFocus = !!anchorEl || (inputRef && inputRef.current && inputRef.current === document.activeElement);

  return <>
    {children(value, onKeyDown, onBlur, onFocus, onValueChange)}
    {!!anchorEl &&
      filteredProposals.length > 0 ? <ClickAwayListener onClickAway={dismissPopup}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart">
      <Popper className="popper" open={!!anchorEl} anchorEl={anchorEl}
        placement="bottom" >
        <div className="selector-options template-selector fancy-scroll" style={{ width: anchorEl.getBoundingClientRect().width }}
          onScroll={onScroll}
          ref={scrollerRef}
          data-testid="template-selector-popper"
        >
          {filteredProposals.map((c, i) => <ChildrenItems key={c.id || c.email || c} item={c} popperselected={i === popperselected} doSelect={doSelect} value={value}></ChildrenItems>)}
        </div>
      </Popper ></ClickAwayListener>
      : null}
  </>
}