/* istanbul ignore file all will be redone */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getContact } from '../../../../reducers/contacts';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
// import Avatar from '../../../avatar';
import moment from 'moment';

import { LMTooltip } from '../../../tooltip/Tooltip';
// import {
//   AVATAR_SMALL
// } from '../../../consts';
// import SlotHintFreeBusy from './SlotHintFreeBusy';
import '../../create-event/inputs/inputs.scss';
import { Clear, Done, ErrorOutline } from '@material-ui/icons';
import { StatusPendingIcon } from '../../../../assets/icons';

// const avatar_max_count = 5;

export default function Slot({
  slot,
  name,
  value = false,
  onChange,
  // IAmFree, // if logged, the system knows if I am available or not for this slot
  multiselect = true,
  proposal,
  isForced = '',
}) {

  const { t } = useTranslation();

  let [answersOk, answersKo, notAnswered] = useMemo(() => {
    if (!proposal || !slot) return [[], [], []];
    let answersOk = slot.selectedBy || [];
    let answersKo = Object.values(proposal.inviteesDetails).filter(inv => {
      if (inv.organizer) return false;
      let isOK = answersOk.find(e => e === inv.email);
      if (inv.response === 'yes' && !isOK) return true;
      // case registered/hybrid not available anymore
      let isDoner = (inv.registered || (inv.related_to && inv.related_to.user_id)) && !inv.vote_requested;
      return (isDoner && !isOK);
    });
    let notAnswered = Object.values(proposal.inviteesDetails).filter(inv => {
      // not organizer
      if (inv.organizer) return false;
      // not in answer OK
      if (answersOk.find(e => e === inv.email)) return false;
      // not in KO
      if (answersKo.find(e => e.email === inv.email)) return false;
      return true;
    });
    return [answersOk, answersKo, notAnswered];
  }, [proposal, slot, isForced]);

  if (!slot || !slot.start || !slot.end || !proposal) return null;

  const now = moment();
  let startDate = moment(slot.start);
  // let endDate = moment(slot.end);

  const isInPast = startDate <= now;
  const isInRemainingSlot = !slot.notInRemainingSlots;
  const selectedByCount = (slot.selectedBy || []).length;
  const isPoll = proposal.type === 'poll';

  let date = startDate.format(t('common.slotHour')); // + ' - ' + endDate.format(t('common.slotHour'));
  let isEditable = onChange !== undefined;

  const radioDisabled = (isInPast || (!slot.IAmFree && !proposal.ignore_initial_avail)) ? true : undefined;
  const control = multiselect ? <Checkbox disabled={(isInPast) ? true : undefined} className="done-input" checked={value} onChange={onChange} name={name} />
    : <Radio disabled={radioDisabled} className="done-input  invisible" value={true} checked={!!value} onChange={onChange} name={name} />

  let tooltipContent = null;
  if (answersOk.length >= 0 && !isInPast) {
    tooltipContent = <>
      <div className="like-p">
        {!isInRemainingSlot && <div className='notInRemaining'>{t("event.slot.notInRemainingSlots")}</div>}
        {answersOk.length > 0 &&
          <>{answersOk.map((answer, i) => {
            return <Answered
              key={"ok-" + i}
              invitee={answer}
              isOptional={(proposal.inviteesDetails[answer] || {}).optional}
              available={true}
              isForced={isForced === answer} />;
          })}
          </>}
        {answersKo.length > 0 &&
          <>{answersKo.map(answer => {
            let detail = (proposal.inviteesDetails[answer.email] || {});
            return <Answered
              key={answer.email}
              invitee={detail.isUnknown ? detail.label : answer.email}
              isOptional={detail.optional}
              isUnknown={detail.isUnknown}
              available={false} />;
          })}
          </>}
        <>{notAnswered.map(answer => {
          let detail = (proposal.inviteesDetails[answer.email] || {});
          return <Answered
            key={answer.email}
            invitee={detail.isUnknown ? detail.label : answer.email}
            isOptional={detail.optional}
            isUnknown={detail.isUnknown}
          />;
        })}
        </>
      </div>
      {proposal.iAmHost && <div className="like-p"><Answered invitee={t(slot.IAmFree ? 'event.slot.available' : 'event.slot.notAvailable')} available={slot.IAmFree} className="org" /></div>}
    </>;
  }

  let cmp = proposal.iAmHost ?
    (<LMTooltip colorTheme="light" content={tooltipContent} interactive={false} enterDelay={0}>
      <FormControlLabel
        control={control}
        label={
          <div className="slot-label">
            <span>{date}</span>
            {!multiselect && !isInPast && slot.IAmFree && answersOk.length > 0 && <Answers answersOk={answersOk} nbInvitees={proposal.invitees.length || 1} />}
          </div>
        }
      />
    </LMTooltip>)
    : <FormControlLabel
      control={control}
      label={
        <div className="slot-label">
          <span>{date}</span>
          {proposal.iAmHost && !multiselect && !isInPast && slot.IAmFree && answersOk.length > 0 && <Answers answersOk={answersOk} nbInvitees={proposal.invitees.length || 1} />}
          {isPoll && selectedByCount > 0 &&
            <LMTooltip
              childrenClassName="showHand"
              enterTouchDelay={0}

              content={
                <><p><b>They're available:</b></p><p>{(slot.selectedBy || []).map((attendee) =>
                  <>
                    {attendee}<br />
                  </>
                )}
                </p></>}
            ><div className="mark">
                <div style={{ /*backgroundColor: transparentize("#7060df", 50 + percent) */ }} className={"inner-mark "}>
                  <Done />
                  {selectedByCount}
                </div>
              </div>
            </LMTooltip>}
        </div>
      }
    />

  return (
    <div className={"slot " + (value ? 'checked' : '') + (radioDisabled ? " disabled" : "") + (isInRemainingSlot ? '' : ' not-remaining')} data-testid={"check-vote"}>
      {
        isEditable && cmp
      }
      {!isEditable && <div className="date">{date}</div>}
      {(isForced) && <div className="forced-slot">
        <LMTooltip colorTheme="dark" content={<p>{t('event.slot.forcedDescription')}</p>} interactive={false} enterDelay={500}>
          <ErrorOutline /></LMTooltip></div>}
    </div>
  )
}

function Answers({ answersOk, nbInvitees }) {
  const percentage = Math.floor(answersOk.length / nbInvitees * 100);
  return (
    <div className="answers">
      {answersOk &&
        <div className={"nb-ok high"}>{percentage}%</div>
      }
    </div>
  )
}

function Answered({ invitee, available = undefined, className = '', isOptional = false, isForced = false, isUnknown = false }) {
  const contact = useSelector(getContact(invitee));
  const { t } = useTranslation();
  return (
    <div className={"answered " + className + (available === true ? " strong " : "")}>
      {available === true && (isForced ? <ErrorOutline className="forced" /> : <Done className="ok" />)}
      {available === false && <Clear className="ko" />}
      {available === undefined && <StatusPendingIcon className="waiting" />}
      {contact.email}
      {isOptional && <>&nbsp;{t('proposal.details.optional')}</>}
    </div>
  );
}