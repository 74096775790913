import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../../api/validators/proposal';
import { durationFormatter } from '../../../../utils/duration.formatter';
import TextBlock from '../../../TextBlock';
import Conference from '../../create-event/recap/conference';
import {
  Clear,
  PriorityHigh
} from '@material-ui/icons';
import './proposal.content.scss';
import { LMTooltip } from '../../../tooltip/Tooltip';
import { useDialog } from '../../../dialogs/dialog.provider';
import { conferenceUrlCompat } from '../../../../utils/compatibility.conference'
import { DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { MAX_INVITEES_LEGIT_TO_DISPLAY } from '../../../../conf/general';
export function ProposalContent({ proposal, className = "" }) {

  const { t } = useTranslation();

  if (!proposal || !proposal.id
    || !proposal.organizer) return null;

  const duration = proposal.duration === -1 ? durationFormatter(proposal.customduration, t) : durationFormatter(proposal.duration, t)
  const { conference_url, location_txt } = conferenceUrlCompat(proposal)
  const conference = proposal.conference ? <Conference id={proposal.conference} conference_url={conference_url} error={proposal.__conference_not_generated} showIcon={true} />
    : null;
  const location = location_txt ? <TextBlock txt={location_txt} /> : null

  return <div className={"proposal-details-content " + className}>
    <div className="proposal-header">{t('proposal.details.title')} <span className={"type type-" + proposal.type}>{t('proposal.type.' + proposal.type)}</span></div>
    <div className="proposal-title">{proposal.title}</div>
    <div className="proposal-time-and-location">
      <span className="proposal-duration">{duration}</span>
      {conference && <span className="proposal-location">{conference}</span>}
      {location && <span className="proposal-location-text">{location}</span>}
    </div>
    <div className="proposal-invitees"><Invitees proposal={proposal} /></div>
    {proposal.notes && <ProposalNotes notes={proposal.notes} />}
    {proposal.iAmHost && proposal.holdSlots && (proposal.status === STATUS.PENDING || proposal.status === STATUS.NO_AVIABILITY) && <div className="proposal-hold-slots" data-testid="proposal-hold-slots">
      {t('proposal.details.holdSlots')}
    </div>}
    {proposal.important &&
      <div className="proposal-important">
        <LMTooltip content={
          <>
            <p>
              {t('createEvent.form.importantDescription')}
            </p>
          </>}>
          {t('createEvent.form.importantTitleHigh')}
        </LMTooltip>
      </div>}
  </div>
}

const Invitees = ({ proposal }) => {

  const { t } = useTranslation();
  const { enqueueDialog, closeDialog } = useDialog();

  let host = proposal.onBehalfOfName || (proposal.organizer || {}).email;
  let mand = Object.values(proposal.inviteesDetails || {}).filter((inv) => {
    return !inv.optional;
  }).map((inv) => inv.isUnknown ? inv.label : inv.email);
  let opt = Object.values(proposal.inviteesDetails || {}).filter((inv) => {
    return inv.optional;
  }).map((inv) => inv.isUnknown ? inv.label : inv.email/* + ' ' + t('proposal.details.optional')*/);


  const showAllInvitees = React.useCallback(() => {
    if (!proposal) return;
    const onClose = () => {
      closeDialog();
    }
    enqueueDialog({
      content: <>
        <DialogTitle className='lm-guests-popup-title'>
          {t('proposal.details.guestsPopupTitle')}
          <IconButton aria-label="close" onClick={onClose} className="close-button">
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"lm-guests-popup fancy-scroll"}>
          <div className='section'>
            <div>
              {mand.map(
                (inv) => <div key={inv}>{inv}</div>
              )}
            </div>
          </div>
          {opt.length > 0 &&
            <div className='section'>
              <div>Optional:</div>
              <div>
                {opt.map(
                  (inv) => <div key={inv}>{inv}</div>
                )}
              </div>
            </div>
          }
        </DialogContent>
      </>,
      doClose: onClose,
    })
  }, [proposal, enqueueDialog, closeDialog])

  const nbGuests = React.useMemo(() => {
    if (!proposal) return;
    return (proposal.invitees || []).length;
  }, [proposal]);

  const tooManyInvitees = React.useMemo(() => {
    return nbGuests > MAX_INVITEES_LEGIT_TO_DISPLAY;
  }, [nbGuests])

  return <>
    <span key={host} className="strong">{host + " (" + t('createEvent.form.organizer').toLowerCase() + ")"}</span>
    {tooManyInvitees ?
      <span><a href='#' onClick={showAllInvitees}>{t('proposal.details.nbGuestsLink', { count: nbGuests })}</a></span>
      :
      <>
        <span key={'mandatories'}>{mand.join(', ')}</span>
        {opt.length > 0 && <span key={'optionals'} className="optional">{t('proposal.details.optionals')} {opt.join(', ')}</span>}
      </>}
  </>;
}

const ProposalNotes = ({ notes }) => {
  const [expandNotes, setExpandNotes] = useState(false);

  const showFull = useCallback(() => {
    setExpandNotes(!expandNotes);
  }, [expandNotes]);

  const tooLongToShow = notes.length < 200;

  return (
    <div className="proposal-notes">
      {(tooLongToShow || expandNotes) &&
        <TextBlock txt={notes} />
      }
      {!tooLongToShow &&
        <>
          {!expandNotes && <a href="#" onClick={showFull}>View full description</a>}
          {expandNotes && <a href="#" onClick={showFull} className="hide">Hide full description</a>}
        </>
      }
    </div>
  );
}