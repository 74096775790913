import { getEventPosition } from './get.event.position';


// get new dimensions based on positions
export function getNewDimensions(e, old){
  if(!e || !old){
    return {top: 0, left: 0, width: 0, height: 0};
  }
  //let { clientX, clientY, target } = e;
  let position = getEventPosition(e)
  
  // update width and height
  // position is:
  // check direction, ie, move throught starting point
  let ox = old._originX || 0;
  let oy = old._originY || 0;

  let t = oy;
  let l = ox;
  let w = position.left - ox;
  let h = position.top - oy;

  if(w < 0){
    l = position.left;
    w = -w;
  }
  if(h < 0){
    t = position.top;
    h = -h;
  }
  
  return {
    ...old,
    top: t,
    left: l,
    width: w,
    height: h,
  }
}