import { createActions, handleActions, combineActions } from 'redux-actions';
import * as ACTIONS from '../actions';

const defaultState = '';

const reducer = handleActions(
  {
    
  },
  defaultState
);

export default reducer;