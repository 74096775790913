/* istanbul ignore file */
// If no AB testing on this feature, we should remove
// this file
// NOTE: we will need to extract LocationMenuItem (use as renderer in new location
// widget)
// Location can be: zoom, google meet, teams, tel r anything
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack'
import NOOP, { NO_BUBBLES, NO_PROPAGATION } from '../../../../../utils/noop';
import * as ACTIONS from '../../../../../actions'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import Input from '@material-ui/core/Input'
import { useTranslation, Trans } from 'react-i18next';
import { PopperHOC } from '../PopperHOC';
import { RenderWithSearch } from '../render.with.search';
import { HelpOutline } from '@material-ui/icons';
import { LMTooltip } from '../../../../tooltip/Tooltip';
import { compareNoSpace as compare } from '../../../../../utils/compare.string';
import i18next from 'i18next';

import { useSelector } from 'react-redux';
import { getTemplatedLocations } from '../../../../../reducers/proposals';
import { MenuItem, Select } from '@material-ui/core';
import {
  KeyboardArrowDown,
  Notes,
} from '@material-ui/icons';
import { ZoomIcon as VideoCall, MeetIcon, TeamsIcon } from '../../../../../assets/icons';
import "./location.scss";

const TEST_ID = { "data-testid": "txt-location", type: "search" };
const LocationItem = ({ item, popperselected, doSelect, value }) => {
  const select = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    doSelect({ target: { name: "location", value: item } });
  }, [doSelect])
  return <div data-testid={"proposal-item-" + item}
    className={popperselected ? 'location-selector-item selected' : 'location-selector-item'}
    onMouseDown={select}>
    <RenderWithSearch className={/*"location-selector-item" +*/ (popperselected ? ' selected' : '')} txt={item} srch={value} />
  </div>;
}

export const FreeInput = ({ value, onChange, isMenuOpen, disabled }) => {
  const iref = React.useRef(null);
  const filteredLocations = useSelector(getTemplatedLocations);
  React.useLayoutEffect(() => {
    if (iref.current && isMenuOpen === false) {
      let r = iref.current;
      let t = setTimeout(() => {
        r.focus()
      }, 300)
      return (() => clearTimeout(t))
    }
  }, []);
  return <PopperHOC value={value} onChange={onChange} inputRef={iref} filteredList={filteredLocations}
    searchFct={compare} ChildrenItems={LocationItem}
    name="location" decorateSelectWithTarget={true}>
    {(txtValue, onKeyDown, onBlur, onFocus, onValueChange) => <Input inputRef={iref} value={txtValue} onChange={onValueChange}
      name="location"
      inputProps={TEST_ID}
      disabled={disabled}
      fullWidth
      onClick={NO_PROPAGATION}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseDown={NO_PROPAGATION}

      placeholder={i18next.t('createEvent.form.locationPlaceholder')}></Input>}
  </PopperHOC>;
}
const LOCATION_VALUES = [
  {
    id: 'free',
    conference_tool: '',
    icon: <Notes className="free" />,
    component: FreeInput,
    doSelectMenu: (onChange) => {
      // reset
      onChange({
        target: {
          name: 'conference',
          value: ''
        }
      })
    }
  },
  // {
  //   label: "address",
  //   desc: "Add an address or location",
  //   icon: <LocationOn/>
  // },
  // {
  //   label: "phone",
  //   desc: "Enter a phone number",
  //   icon: <Phone/>,
  //   component: ({value, onChange}) => {
  //   return <PhoneInput country={getLanguage()} value={value} onChange={(phone)=> onChange({target:{name:"location", value: phone}})}/>;
  //   }
  // },
  {
    id: 'zoom',
    conference_tool: 'zoom',
    icon: <VideoCall />,
    doSelectMenu: (onChange) => {
      onChange({
        target: {
          name: 'conference',
          value: 'zoom'
        }
      });
      onChange({
        target: {
          name: 'location',
          value: ''
        }
      }, true);
    }
  },
  {
    id: 'zoom:personal',
    conference_tool: 'zoom',
    icon: <VideoCall />,
    doSelectMenu: (onChange) => {
      onChange({
        target: {
          name: 'conference',
          value: 'zoom:personal'
        }
      });
      onChange({
        target: {
          name: 'location',
          value: ''
        }
      }, true);
    }
  },
  {
    id: 'gmeet',
    conference_tool: 'gmeet',
    icon: <MeetIcon />,
    doSelectMenu: (onChange) => {
      onChange({
        target: {
          name: 'conference',
          value: 'gmeet'
        }
      });
      onChange({
        target: {
          name: 'location',
          value: ''
        }
      }, true);
    }
  },
  {
    id: 'teams',
    conference_tool: 'teams',
    icon: <TeamsIcon />,
    doSelectMenu: (onChange) => {
      onChange({
        target: {
          name: 'location',
          value: ''
        }
      });
      onChange({
        target: {
          name: 'conference',
          value: 'teams'
        }
      })
    }
  },
];
const REGISTRATIONS = {
  "zoom": {
    id: 'zoom:install',
    conference_tool: 'zoom',
    register: true,
    icon: <VideoCall />,
  },
}
export default function Location({
  value, conference,
  profile,
  calendar,
  onChange = NOOP,
  onRegister = NOOP,
  error = "",
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isMenuOpen, setIsMenuOpen] = React.useState(undefined);
  const [isRegistering, setIsRegistering] = React.useState(false);
  const doRegistration = React.useCallback((toolId) => {
    setIsRegistering(true)
    // ask for oauth url
    // go
    onRegister(toolId);
    dispatch({
      type: ACTIONS.WORKER_GET_TOOL_OAUTH,
      payload: toolId,
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_TOOL_OAUTH_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_TOOL_OAUTH_ERROR
      }
    }).then((url) => {
      if (!url || !url.payload) return Promise.reject()
      window.location.assign(url.payload)
    }).catch((err) => {
      // could not
      enqueueSnackbar(t('createEvent.form.errors.register_error'), {
        variant: 'error'
      })
    }).then(() => {
      setIsRegistering(false)
    })
  }, [onRegister]);
  const menuItems = React.useMemo(() => {
    if (!profile) return LOCATION_VALUES;
    let tools = profile.tools || [];
    // check for providers dedicated online conference tools
    let gmeet = calendar && calendar.provider === 'google' && calendar.supported_conferences
      && calendar.supported_conferences.indexOf("gmeet") > -1;
    let teams = calendar && calendar.provider === 'microsoft' && calendar.supported_conferences
      && calendar.supported_conferences.indexOf("teams") > -1;
    // check if user have conference_tool = toolID
    let items = [LOCATION_VALUES[0]]; // everyone can free text!
    if (gmeet) items.push(LOCATION_VALUES[3])
    if (teams) items.push(LOCATION_VALUES[4])
    let registered = {}
    for (let tool of tools) {
      // has registerd tool?
      for (let t of LOCATION_VALUES) {
        if (t.conference_tool === tool.toolID) {
          if (tool.third_party_status === "error" || !tool.account_id) {
            if (REGISTRATIONS[tool.toolID]) {
              // check no other are here
              if (!items.find((tl) => tl.conference_tool === tool.toolID)) {
                items.push({
                  ...REGISTRATIONS[tool.toolID],
                  isError: tool.third_party_status === "error"
                })
              }
            }
          } else {
            items.push(t) // can have more than one, see zoom            
          }
          registered[t.conference_tool] = true;
        }
      }
    }
    // get unregistered tools
    for (let key of Object.keys(REGISTRATIONS)) {
      if (!registered[key]) {
        let tmp = REGISTRATIONS[key];
        items.push(tmp);
      }
    }
    return items
  }, [profile, doRegistration, calendar])// need calendar, gmeet only with google account

  React.useEffect(() => {
    if (conference) {
      // check value
      if (!menuItems.find((item) => item.id === conference)) {
        // no conference, maybe a bug when registrating?
        console.log('no conference, maybe a bug when registrating?')
        onChange({
          target: {
            name: "conference",
            value: ""
          }
        })
      }
    }
  }, [conference, menuItems, onChange])
  const onMenuOpen = React.useCallback((e) => {
    setIsMenuOpen(true)
  }, [])
  const onMenuClose = React.useCallback(() => setIsMenuOpen(false), [])
  const doSelectMenu = React.useCallback((i) => {
    if (!i || !i.target) return;
    let { name, value } = i.target;
    // if zoom or meet, set conference datas
    let type = menuItems.find((l) => l.id === value);
    if (type && type.doSelectMenu) type.doSelectMenu(onChange);
    else if (type && type.register) doRegistration(type.conference_tool)
  }, [onChange, doRegistration, menuItems]);

  return <fieldset fullWidth className={"done-input fast-selector location-selector " + (error ? 'Mui-error' : '')}>

    <label id="location-label" className="done-input-select-label with-help-button">
      {t('createEvent.form.location')}
      <LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p><Trans i18nKey="createEvent.form.locationTooltip"> <span className={"strong " + (error ? 'Mui-error' : '')}>aaaaThis is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
          </>
        }
      >
        <HelpOutline />
      </LMTooltip>
    </label >
    <Select
      disabled={isRegistering}
      data-testid="select-conference"
      labelId="location-label"
      value={conference || 'free'}
      name='conference'
      onChange={doSelectMenu}
      placeholder={t('createEvent.form.locationPlaceholder')}
      select
      className="location-no-focus"
      margin="normal"
      fullWidth
      onOpen={onMenuOpen}
      onClose={onMenuClose}
      open={isMenuOpen}
      IconComponent={KeyboardArrowDown}
    >
      {menuItems.map((loc) => <MenuItem data-testid={loc.id || "free"} key={loc.id || "free"} value={loc.id || "free"}>
        <LocationMenuItem loc={loc} selected={conference} innerLocation={value} onChange={onChange} menuOpen={isMenuOpen} disabled={isRegistering} />
      </MenuItem >)}
    </Select>
    {error && <p className="done-Mui-error align-right" data-testid="location-error-text">{t('createEvent.form.errors.locationTooLong', { max: 1024 })}</p>}
  </fieldset >
}

export const LocationMenuItem = ({ loc, selected, innerLocation, onChange, menuOpen, disabled }) => {
  const { t } = useTranslation();
  if (!menuOpen && loc.component) {
    return <loc.component value={innerLocation} onChange={onChange} isMenuOpen={menuOpen} disabled={disabled} />
  }
  return <div className="location-menu-item">
    {loc.icon}
    <div className="location-menu-desc">
      <div className="location-menu-desc-title">{t(`createEvent.form.locationitems.${loc.id.replace(':', '')}.title`)}</div>
      <div className="location-menu-desc-desc secondary-text">{t(`createEvent.form.locationitems.${loc.id.replace(':', '')}.${loc.isError ? "descError" : 'desc'}`)}</div>
    </div>
  </div>
}


