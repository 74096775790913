/**
 * Really simple throttle function
 * mainly dedicated to mouse events throttle
 * Always call funtion with last call params (no merge, no custom like
 * in debounce)
 * Limitation: last one will not be called
 * @param {Function} func function to throttle
 * @param {number} wait wait in ms
 */
export const throttle = function (func, wait) {
  var last_timestamp, limit, lastargs;
  last_timestamp = Date.now();
  limit = wait;
  return function () {
    var  context, now;
    context = this;
    var args = arguments;
    now = Date.now();
    if (!last_timestamp || ((now - last_timestamp) >= limit)) {
      last_timestamp = now;
      return func.apply(context, args);
    }
  };
};