import { combineReducers } from 'redux';
import dumb from './dumb';
import user from './user';
import contacts from './contacts';
import app from './app';
import accounts from './accounts';
import event from './event';
import busy from './busy';
import proposals from './proposals';
import register from './register';
import dashboard from './dashboard';
import profile from './profile';
import currentProposal from './current.proposal';
import userEvents from './user.events';
import links from './link';

export default combineReducers({
  dumb,
  app,
  user,
  contacts,
  accounts,
  event,
  busy,
  proposals,
  register,
  dashboard,
  profile,
  currentProposal,
  userEvents,
  links,
})