import React from 'react';
import { useDispatch } from 'react-redux';
import * as ACTIONS from '../actions';
import { signInAction } from '../reducers/user';
import moment from 'moment';
import { DOMAIN } from '../api/config';
import { signup } from '../api/signup';
import * as uuid from 'uuid';
import { useLocation } from 'react-router-dom';
import { parseQuery } from '../utils/parse.query';
import NOOP from '../utils/noop';
import jwt_decode from 'jwt-decode';

const MS_ID = process.env.REACT_APP_MSAL_ID || '59e872b0-69fe-4432-9c12-92c2709438cd';
// Open-id want an api description
export const doc_url = 'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration';
let doc_api;
function get_doc() {
  /* istanbul ignore if */
  if (doc_api) return Promise.resolve(doc_api);
  return fetch(doc_url)
    .then((rep) => {
      return rep.json()
    })
    .then((doc) => {
      doc_api = doc;
      console.log('Get doc', doc_api)
      return doc;
    })
}

/* istanbul ignore next no token on msal */
function _loadUserProfileRest(dt) {
  return get_doc()
    .then((doc) => {
      return fetch(doc.userinfo_endpoint, {
        headers: {
          Authorization: 'Bearer ' + dt.access_token
        }
      })
    }).then((rep) => rep.json())
}
function useMSALLogin(success, error, fromEventPage = false) {
  const dispatch = useDispatch();
  const location = useLocation();
  const signIn = React.useCallback((type, login_hint = '') => {
    return get_doc()
      .then((doc) => {
        // if type === Office, switch to v1?
        if (login_hint) login_hint = '&login_hint=' + encodeURIComponent(login_hint);
        // Test: only permitt v2.0 and common connection/oauth
        // if (type === 'office') {
        //   let endpoint = doc.authorization_endpoint.replace('/v2.0/', '/');
        //   window.location.assign(`${endpoint}?response_type=id_token&client_id=${MS_ID}&redirect_uri=${DOMAIN}&prompt=select_account&nonce=${uuid.v4()}&state=microsoft-office${login_hint}`);
        // } else {
        // default scopes and extra scopes if any
        const scopes = encodeURIComponent([
          'openid',
          'profile',
          'email',
          'User.Read',
          'Calendars.ReadWrite',
          'offline_access',
        ].join(' '))
        window.location.assign(`${doc.authorization_endpoint}?response_type=id_token&scope=${scopes}&client_id=${MS_ID}&redirect_uri=${DOMAIN}&prompt=select_account&nonce=${uuid.v4()}&state=microsoft${login_hint}`);
        // }

      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        /* istanbul ignore else */
        if (error) error(2)
      });
  }, []);

  React.useEffect(() => {
    /* istanbul ignore else */
    if (location.hash) {
      let dt = parseQuery(location.hash);
      /* istanbul ignore else */
      if (dt.id_token && (dt.state && dt.state.startsWith("microsoft"))) {
        // load user profile infos
        let profile = jwt_decode(dt.id_token);
        let loginResponse = {
          tokenId: dt.id_token,
          account: {
            ...profile,
            imageUrl: profile.picture,
            userName: profile.email,
          }
        }
        dispatch(signInAction('microsoft', loginResponse))
          .then(() => {
            /* istanbul ignore else */
            if (success) success();
            // console.log('Finished')
          })
          .catch(err => {
            if (err && err.payload && err.payload.code === 'UNREGISTERED_ACCOUNT') {

              let email;
              if (loginResponse && loginResponse.account) email = loginResponse.account.userName;

              // save current form and add redirect infos for next loading....
              dispatch({
                type: ACTIONS.PRE_CONNECTION_TOKEN,
                payload: loginResponse,
                name: 'microsoft'
              });

              signup('microsoft', /*dt.state === 'microsoft-office' ? 'office' :*/ undefined, email)
                .then((link) => {
                  // dispatch({
                  //   type: ACTIONS.ANALYTICS_SIGNED_UP,
                  //   payload: {
                  //     provider: 'microsoft',
                  //     fromEventPage: fromEventPage,
                  //   }
                  // });
                  window.location.assign(link.link);

                }).catch((err) => {
                  console.error(err);
                  if (error) error(2)
                })
            } else {
              dispatch({
                type: ACTIONS.WORKER_PROVIDER_TOKEN_REFRESH_ERROR,
              });
              /* istanbul ignore else */
              if (error) error(2, 'silent', err);

            }
          })
      } else if (error) error(2, 'silent');
    }
    // not a connection or do not concern google, silently stop
    else if (error) error(2, 'silent');


  }, [])

  return signIn;
}
export default useMSALLogin;