import { handleActions } from 'redux-actions';
import * as ACTIONS from '../actions';
import moment from 'moment';
import { CONFIG } from '../utils/gen.timeframes';
export const defaultState = {
  enabled: true,
  timezone: moment.tz.guess(),
  workingHours: CONFIG,
  fb_visibility: 31
};


function saveWH(state, action) {
  if (action.payload && action.payload.timezone) {
    let tz = (action.payload.timezone || '');
    moment.tz.setDefault(tz);
    // if locale is one of supported???
  }
  return action.payload || state;
}
const reducer = handleActions(
  {
    [ACTIONS.WORKER_SAVE_WH_SUCCESS]: saveWH,
    [ACTIONS.WORKER_REFRESH_PROFILE_SUCCESS]: (state, action) => action.payload || state,
    [ACTIONS.WORKER_CONNECT_SUCCESS]: (state, action) => {
      return saveWH(state, { payload: action.payload.profile })
    },
    [ACTIONS.WORKER_SET_COMPANY_LOGO_SUCCESS]: (state, action) => {
      let company = action.payload;
      let billing = state.billing || {};
      console.log("SET LOGO SUCCESS", state, company)
      return {
        ...state,
        billing: {
          ...billing,
          company,
        }
      }
    }
  },
  defaultState
);
export default reducer;
export function getProfile(state) { return state.profile }
export function getWorkingHours(state) { return state.profile }
export function getWorkingHourStartForToday(state) {
  let wh = state.profile.workingHours || {};
  let today = '' + moment().isoWeekday();
  if (wh[today]) {
    let frm = wh[today].frames[0];
    if (frm) {
      return frm;
    }
  }
  return { start: moment().hour() }
}
