import React from "react";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import "./Year.scss";

export default ({ date, // date to display (ie selected) Moment
  onPrevYear, // prec year cllbck
  onNextYear, // next year cllbck
  mini, // display as mini component
  disablePast = true, // can select date in past?
 }) => {
  const year = date.year();
  const now = moment().startOf("day");
  const cp = date.clone();
  const canDisable = disablePast && cp.year(year - 1).unix() < now.unix();

  return (
    <div className={"year" + (mini ? " condensed" : "")}>
      <IconButton
        color="primary"
        data-testid="prev-year"
        onClick={onPrevYear}
        disabled={canDisable}
        className={canDisable ? "disabled" : ""}
      >
        <KeyboardArrowLeft />
      </IconButton>
        <span className="year-value">{year}</span>
      <IconButton color="primary" data-testid="next-year" onClick={onNextYear}>
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};
