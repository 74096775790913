import React from "react";
import { useTranslation } from "react-i18next";
import Created from "./created";
import AlreadyAnswer, { AnswerNewSlots } from "./already.answer";
import Refused from "./refused";
import Response from "./response";
import Unknwons from "./unknowns";
import Status from "./status";
import EventStatus from './event.status';
import { STATUS, PROPOSAL_TYPE } from "../../../../../api/validators/proposal";
import UserVote from './user.vote';
import EventCancelledStatus from './event.cancelled.status'
import { ProposalActions } from "../proposal.actions";
import "./timeline.scss";
const STEPS = {
  created: proposal => <Created key="created" proposal={proposal} />,
  answered: (invitees, inviteesDetails, isPoll = false) => <AlreadyAnswer key="answered" invitees={invitees} inviteesDetails={inviteesDetails} isPoll={isPoll} />,
  answeredNewSlots: (invitees, inviteesDetails) => <AnswerNewSlots key="answered-new-slots" invitees={invitees} inviteesDetails={inviteesDetails} />,
  refused: (invitees, accountsEmails) => (
    <Refused
      key="refused"
      invitees={invitees}
      accountsEmails={accountsEmails}
    />
  ),
  response: (proposal, showSlots, loading) => (
    <Response key="response" proposal={proposal} showSlots={showSlots} loading={loading} />
  ),
  unknowns: (invitees, inviteesDetails, remaining, showSlots, isActive, isSmaller, isPoll) => {

    return <Unknwons
      key="unknowns"
      isActive={isActive}
      isSmaller={isSmaller}
      invitees={invitees}
      inviteesDetails={inviteesDetails}
      remainingCount={remaining}
      showSlots={showSlots}
      isPoll={isPoll}
    />
  },
  status: (proposal, isActive, doShowSlots) => (
    <Status key="status" proposal={proposal} isActive={isActive} onShowSlots={doShowSlots} />
  ),
  eventstatus: (proposal) => (
    <EventStatus key="event-status" proposal={proposal} />
  ),
  uservote: (proposal, showSlots, loading) => (
    <UserVote key="user-vote" proposal={proposal} showSlots={showSlots} loading={loading} />
  ),
  eventcompletedstatus: (proposal) => <EventCancelledStatus key="event-cancel-status" proposal={proposal} />
};
const EMPTY_ACCOUNTS = [];
const getProposalSlotCount = (proposal) => {
  if (!proposal || !proposal.slots) return 0;
  return proposal.slots.reduce((total, day) => {
    if (!day || !day.slots) return total;
    return day.slots.length + total;
  }, 0)
}
export function Timeline({
  proposal,
  showSlots,
  forceSlot,
  loading = false,
  accountsEmails = EMPTY_ACCOUNTS
}) {
  const { t } = useTranslation();

  const doShowSlots = React.useCallback(
    (v, forVote = false) => {
      if (loading /*|| !showSlots*/) return;
      showSlots(v, forVote);
    },
    [proposal, showSlots, loading]
  );
  // const isEventEditable = proposal && proposal.iAmHost;
  // calculate different timeline steps from proposal
  const steps = React.useMemo(() => {
    if (
      !proposal ||
      !proposal.id ||
      !proposal.organizer ||
      !proposal.inviteesDetails
    )
      return []; // no steps
    // create steps, first one is always created
    const steps = [STEPS["created"](proposal)];
    let waiting = proposal.status === STATUS.PENDING;
    let slotCount = getProposalSlotCount(proposal);
    if (slotCount > 1 || proposal.status === 'sent') {
      // did some already answer?
      // Chg: remove optional from list
      let details = proposal.inviteesDetails;
      const invitees = proposal.invitees.filter((inv) => {
        return !details[inv].optional;
      })
      const answered = [];
      const answeredNewSlots = [];
      const refused = [];
      const unknowns = [];
      const remaining = (proposal.remaining_slots || []).reduce((acc, day) => {
        return (day || { slots: [] }).slots.length + acc;
      }, 0);

      steps.push(STEPS["response"](proposal, doShowSlots, loading));
      let isForcedSlotsProposal = proposal.ignore_initial_avail;
      for (let i of invitees) {
        // if registered or hybrid or respond
        let invitee = proposal.inviteesDetails[i];
        if (invitee && invitee.response === "yes" && proposal.type !== 'poll') {
          if (invitee.slots && invitee.slots.length > 0) answered.push(i);
          else refused.push(i);
        } else if (invitee && invitee.response === "yes" && proposal.type === 'poll') {
          // do proposed new slots?
          if (proposal.iAmHost && invitee.alternate_slots && invitee.alternate_slots.length > 0) answeredNewSlots.push(i)
          else answered.push(i);
        } else if (
          invitee &&
          // registered or hybrid?
          (invitee.registered || (invitee.related_to && invitee.related_to.provider))
          // not forced to reply?
          && (!isForcedSlotsProposal || !(invitee.conflicting_slots && invitee.conflicting_slots.length > 0))
          && !invitee.vote_requested
        )
          answered.push(i);
        else if (!accountsEmails.find((e) => e === i)) unknowns.push(i);
      }
      if (answered.length > 0) steps.push(STEPS["answered"](answered, proposal.inviteesDetails, proposal.type === PROPOSAL_TYPE.POLL));
      if (answeredNewSlots.length > 0) steps.push(STEPS["answeredNewSlots"](answeredNewSlots, proposal.inviteesDetails));
      if (refused.length > 0)
        steps.push(STEPS["refused"](refused, accountsEmails));
      // if status is pending, means we are waiting...      
      steps.push(STEPS["uservote"](proposal, doShowSlots, loading));
      if (unknowns.length > 0) {
        const hasAnswered = accountsEmails.find(userEmail => {
          return (proposal.inviteesDetails[userEmail] || {}).response === 'yes';
        });
        steps.push(STEPS["unknowns"](unknowns,
          proposal.inviteesDetails,
          proposal.iAmHost ? remaining : 0,
          doShowSlots,
          waiting,
          (hasAnswered || proposal.iAmHost) ? false : true,
          proposal.type === 'poll'));
      }

    }
    // finally, add status
    let finalstep = proposal.status === STATUS.CONFIRMED
      && proposal.iAmHost;
    steps.push(STEPS["status"](proposal, !waiting && !finalstep, forceSlot));
    steps.push(STEPS["eventstatus"](proposal));
    steps.push(STEPS["eventcompletedstatus"](proposal));
    return steps.reverse();
  }, [proposal, forceSlot, accountsEmails, loading]);
  // display calculated steps
  return (
    <div className="timeline">
      {steps}
      {/* {isEventEditable && <div className="timeline-actions">
      <ProposalActions proposal={proposal}/>
    </div>} */}
    </div>
  );
}
