import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { DoneTextFieldRef as TextField } from '../inputs/Text';
import Popper from '@material-ui/core/Popper';
import NOOP from '../../../../utils/noop';
import usePagination from '../../../../hooks/use.pagination';
import { getContacts } from '../../../../reducers/contacts';
import GuestItem from './guest.item';
import * as ACTIONS from '../../../../actions';
import { useSnackbar } from 'notistack';
import useFlags from '../../../../hooks/use.flags'
import {
  MAIL
} from '../../../../validators/event';
import { MAIL_RX_SENTENCE } from "../../settings/invite/mailer";
import {
  MailOutline,
  Link
} from '@material-ui/icons';
import { RenderWithSearch } from '../inputs/render.with.search';
import './guest.selector.scss';
import { getAccountMails, hasConnectedContactsSomeContacts } from '../../../../reducers/accounts';
import { Button, ClickAwayListener } from '@material-ui/core';
import Guest from './guest';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '../../../avatar';
import { LMTooltip } from '../../../tooltip/Tooltip';
import { arrayIncludes } from "../../../../utils/array.includes"
const LOADING_ICON = <CircularProgress className="small-loading-icon" />
export const UNKNOWN_EMAIL_DOMAIN = '@null-letsmeet.network'; //todo: put this in validatr
/**
 * Select a contact from list or
 * add simple email (with copy paste inside)
 * @param {Funtion} onAddContact contact added callback 
 */
export default function GuestsSelector({
  onAddContact = NOOP,
  onAddUnknownContact = NOOP,
  startAdornment = null,
  doFocus = NOOP,
  setcanSubmit = NOOP,
  doConnectContacts = NOOP,
  oboCalendar,

  simpleContactSelector = false, // do you ask for LM profile
}) {
  let { t } = useTranslation();
  const dispatch = useDispatch();
  const accountsEmails = useSelector(getAccountMails);
  const hasConnectedContacts = useSelector(hasConnectedContactsSomeContacts);
  let [value, setValue] = React.useState('');
  let [toggleAddButton, settoggleAddButton] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isInvalidEmail, _setIsInvalidEmail] = React.useState(false);
  const setInvalidEmail = React.useCallback(() => {
    _setIsInvalidEmail(true);
    setTimeout(() => _setIsInvalidEmail(false), 3000)
  }, []);

  const inputRef = React.useRef();
  const [anchorElWidth, setAnchorElWidth] = React.useState({ width: 400 });
  const [popperselected, setpopperSelected] = React.useState(0); // By default, first one is selected
  const scrollerRef = React.useRef(null);
  const {
    setFlagSetting,
    getFlagSetting,
    FLAGS,
  } = useFlags();
  const toggleContactPopup = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setFlagSetting(FLAGS.PROPOSE_CONTACT_POPUP, false);
  }, [setFlagSetting, FLAGS]);
  const [pendingInvites, setPendingInvite] = React.useState([]); // buffer for invitees
  const addToPendingInvite = React.useCallback((i) => {
    if (!Array.isArray(i)) i = [i]
    setPendingInvite([...pendingInvites, ...i])
  }, [pendingInvites, setPendingInvite])
  const removeFromPendingInvite = React.useCallback((i) => {
    if (!Array.isArray(i)) i = [i]
    setPendingInvite(pendingInvites.filter((p) => i.find((mail) => mail === p)))
  }, [pendingInvites, setPendingInvite])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let cnvRef = React.useRef();

  const _onAddContact = React.useCallback((e, checkProfile = true, opts = {}) => {
    if (e === '' || !e) return;
    e = e.toLowerCase();
    if (arrayIncludes(accountsEmails, e)) { // accountsEmails.includes(e.toLowerCase())) {
      enqueueSnackbar(t('createEvent.form.errors.cannotInviteYourself'), {
        variant: 'error',
        preventDuplicate: true,
        className: 'snack-error',
        action: /*istanbul ignore next */(key) => {
          return <>
            <Button onClick={() => { closeSnackbar(key) }}>
              {t('common.ok')}
            </Button>
          </>;
        }
      })
      return;
    }
    if (arrayIncludes(pendingInvites, e)) {
      // nothing todo
      return;
    }
    if (!checkProfile) {
      // directly add to the list
      setcanSubmit(true)
      onAddUnknownContact({
        label: opts.name,
        name: opts.name,
        email: e,
        isUnknown: true,
      });
      return;
    }
    setcanSubmit(false)
    // add to pending invites
    addToPendingInvite(e);
    // search for profile infos
    dispatch({
      type: ACTIONS.WORKER_GET_PROFILE,
      payload: e,
      opts: { getAccessRole: true }, // decorate contact with access role, used for "hold slots"
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROFILE_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROFILE_ERROR,
      }
    }).catch(() => { })
      .then(() => {
        removeFromPendingInvite(e);
        setcanSubmit(true)
        onAddContact(e);
      })
  }, [onAddContact, addToPendingInvite, removeFromPendingInvite, oboCalendar, pendingInvites]);
  // same, but more than once - from paste
  const _onAddContacts = React.useCallback((e = []) => {
    // remove My emails from list
    e = e.filter((m) => !arrayIncludes(accountsEmails, m) && !arrayIncludes(pendingInvites, m)) // accountsEmails.includes(m))
    // if already in pending, do not process

    // deduplication on copy/paste: if not deduplicate, problem when removing
    e = Object.keys(e.reduce((acc, i) => {
      acc[i.toLowerCase()] = 1;
      return acc
    }, {}))
    // MUST be emails, so always check profiles
    setcanSubmit(false)
    // add to pending invites
    addToPendingInvite(e);
    // search for profile infos
    dispatch({
      type: ACTIONS.WORKER_GET_PROFILES,
      payload: e,
      opts: { getAccessRole: true }, // decorate contact with access role, used for "hold slots"
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROFILES_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROFILES_ERROR,
      }
    }).catch(() => { })
      .then(() => {
        removeFromPendingInvite(e);
        setcanSubmit(true)
        onAddContact(e);
      })
  }, [onAddContact, addToPendingInvite, removeFromPendingInvite, oboCalendar, pendingInvites]);
  // datas
  // contacts objects to transform to sort array
  let contacts = useSelector(getContacts);
  let sortedContacts = React.useMemo(() => {
    return Object.values(contacts)
      .sort((a, b) => ((a.name || a.email) < (b.name || b.email) ? -1 : 1))
    //.map((k)=> contacts[k]);
  }, [contacts]);

  let pagination = usePagination(sortedContacts || [], 10);
  const [filteredContacts, setFilteredContacts] = React.useState([]);
  // reset propositions
  const doReset = React.useCallback((focus = true) => {
    // remove popper
    pagination.reset();
    setFilteredContacts([]);
    // clear value
    setValue('');
    settoggleAddButton(false);
    setpopperSelected(0);
    /* istanbul ignore if dummy test */
    if (focus && inputRef && inputRef.current) {
      inputRef.current.focus();
    }

  }, [pagination, setFilteredContacts, setValue, settoggleAddButton, inputRef])


  // change in name
  const handleChange = React.useCallback((e) => {
    let v = e.target.value;
    setValue(v);
    // filter contacts
    if (v) {
      let r = pagination.next(e.target.value);

      if (r.length === 0) {
        // check if v is a valid email, if so, add button
        settoggleAddButton(MAIL.isValidSync({ mail: v }));
      }

      setFilteredContacts(r);
    } else {
      pagination.reset()
      setFilteredContacts([]);
      settoggleAddButton(false)

    }
  }, [setValue, pagination, setFilteredContacts, settoggleAddButton]);
  // enter to validate?
  const onKeyDown = React.useCallback((e) => {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 188 || e.key === ';') {
      let v = value.trim();
      if (v === '') return false;
      // e.preventDefault();
      if (filteredContacts.length > 0 && !e.ctrlKey) {
        if (popperselected > 0 && filteredContacts[popperselected - 1]) _onAddContact(filteredContacts[popperselected - 1].email)
        else if (MAIL.isValidSync({ mail: v.toLowerCase() })) _onAddContact(v);
        else {
          if (!simpleContactSelector) {
            // do not create fake mail on space
            if (e.keyCode === 32) return;
            // create a email from value with null domain
            let email = encodeLabelToEmail(v) + UNKNOWN_EMAIL_DOMAIN
            _onAddContact(email, false, { name: v });
          } else {
            // show error
            setInvalidEmail(true);
          }
        }

      }
      else if (MAIL.isValidSync({ mail: v })) _onAddContact(v);
      // if not a mail, add to contact list?
      // else return false;
      else {
        if (!simpleContactSelector) {
          // do not create fake mail on space
          if (e.keyCode === 32) return;
          // create a email from value with null domain
          let email = encodeLabelToEmail(v) + UNKNOWN_EMAIL_DOMAIN
          _onAddContact(email, false, { name: v });
        } else {
          // show error
          setInvalidEmail(true);
        }

      }
      // prevent key processing
      e.preventDefault();
      doReset();
      return false;
    } else if (e.keyCode === 40) {
      let index = popperselected + 1;
      if (index >= pagination.current.length + 1) index = pagination.current.length - 1;
      setpopperSelected(index);
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = index * 52;
      }
      e.preventDefault();
      // ensure scroll to
    } else if (e.keyCode === 38) {
      let index = popperselected - 1;
      if (index < 0) index = 0;
      setpopperSelected(index);
      // ensure scroll to
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = index * 52;
      }
      e.preventDefault();
    }
  }, [value, _onAddContact, filteredContacts, doReset, inputRef, simpleContactSelector]);
  const onPaste = React.useCallback((event) => {
    event.preventDefault();
    let paste = (event.clipboardData || window.clipboardData || { getData: () => '' }).getData('text');
    paste.trim();
    /* istanbul ignore else no work */
    if (paste !== "") {
      // search for emails
      // let ps = paste.toLowerCase().split(/[ ,;\r\n]/g).filter(e => MAIL.isValidSync({ mail: e }))
      let ps = paste.toLowerCase().match(MAIL_RX_SENTENCE)
      /* istanbul ignore else no work */
      if (ps && ps.length > 0) {
        _onAddContacts(ps);
      }
    }
    doReset();
    return false;
  })
  /* istanbul ignore next no drop datatransfer event */
  const onDrop = React.useCallback((event) => {
    event.preventDefault();
    let paste = (event.dataTransfer || { getData: () => '' }).getData('text');
    paste.trim();
    /* istanbul ignore else no work */
    if (paste !== "") {
      // search for emails
      // let ps = paste.toLowerCase().split(/[ ,;\r\n]/g).filter(e => MAIL.isValidSync({ mail: e }))
      let ps = paste.toLowerCase().match(MAIL_RX_SENTENCE)
      /* istanbul ignore else no work */
      if (ps && ps.length > 0) {
        _onAddContacts(ps);
      }
    }
    doReset();
    return false;
  })
  const onFocus = React.useCallback((event) => {
    doFocus(true);
    setAnchorEl(event.target)
  }, [doFocus, anchorEl]);
  React.useLayoutEffect(() => {
    /* istanbul ignore else no work */
    if (anchorEl) {
      // calculate size of caller
      let rect = anchorEl.getBoundingClientRect() || { width: 400 };
      let w = rect.width;
      setAnchorElWidth({ width: w });
    }
  }, [anchorEl])
  // scroll to bottom on list of contacts
  /* istanbul ignore next cannot test */
  const onScroll = React.useCallback((e) => {
    // if bottom, load more
    const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 5);
    if (bottom && filteredContacts) {
      let tmp = pagination.next(value) || [];
      // remove possible emails doublons
      tmp = Object.values(tmp.reduce((acc, t) => {
        acc[t.email] = t;
        return acc;
      }, {}))
      setFilteredContacts(tmp);
    }
  }, [value, filteredContacts, setFilteredContacts, pagination]);

  // select a contact in list
  const doSelect = React.useCallback((c) => {
    if (!c) c = value;
    _onAddContact(c);
    // remove popper
    doReset();
  }, [_onAddContact, value, doReset]);

  // let valueSize = React.useMemo(() => {
  //   if (cnvRef.current && value) {
  //     cnvRef.current.font = 'Poppins 16px'
  //     // return cnvRef.current.getContext('2d').measureText(value).width
  //     return cnvRef.current.getBoundingClientRect().width;
  //   } else return 0;
  // }, [value]);

  const [valueSize, setValueSize] = React.useState(null);
  React.useEffect(() => {
    if (cnvRef.current && value) {
      cnvRef.current.font = 'Montserrat 16px'
      // return cnvRef.current.getContext('2d').measureText(value).width
      setValueSize(cnvRef.current.getBoundingClientRect().width);
    } else setValueSize(0);
  }, [valueSize, setValueSize, value])
  /* istanbul ignore next no need */
  const dismissPopup = React.useCallback((e) => {
    let src = e.srcElement;
    if (src && src === anchorEl) return;
    setAnchorEl(null);
    // if there is some text in, process
    if (value) {
      let v = value.trim();
      if (v === "") return;
      if (MAIL.isValidSync({ mail: v.toLowerCase() })) {
        _onAddContact(v);
        doReset(false);
      } else {
        if (!simpleContactSelector) {
          // create a email from value with null domain
          let email = encodeLabelToEmail(v) + UNKNOWN_EMAIL_DOMAIN
          _onAddContact(email, false, { name: v });
        } else {
          // show error
          setInvalidEmail(true);
        }
        doReset(false);
      }
      return;
    }
  }, [anchorEl, value, setInvalidEmail, _onAddContact, doReset])
  // note: remove onBlur={onBlur} on textfield (to see later)
  return (
    <div className="guest-selector" data-testid="guest-selector">
      <span ref={cnvRef} className="tiny-measures">{value}</span>

      <div className="text-search">
        {startAdornment}
        {pendingInvites.map((i) => {
          return <div className="chips" key={i}><Guest guesticon={LOADING_ICON} displayAsChips={true} onClick={NOOP} email={i} /></div>;
        })}
        <LMTooltip
          open={isInvalidEmail}
          content={t('createEvent.form.errors.mustEnterAnEmail')}
          className='flex-grow error-tooltip'
          placement='bottom-start'
        >
          <TextField
            name="search-contact"
            value={value}
            onChange={handleChange}
            autoComplete="off"
            placeholder={t('createEvent.guests.list.search')}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onPaste={onPaste}
            onDrag={onDrop}
            isTouched={toggleAddButton ? true : undefined}
            isValid={toggleAddButton ? true : undefined}
            ref={inputRef}
            // isRequired={!simpleContactSelector}
            type='search'
          />
        </LMTooltip>
        {/*
          toggleAddButton && <><IconButton data-testid="addemail-btn"
            style={{ left: 11 + valueSize + 10 }}
            onClick={() => doSelect()} variant="contained"><PersonAdd /></IconButton></>
        */}
      </div>
      {!!anchorEl && value !== "" && hasConnectedContacts &&
        <ClickAwayListener onClickAway={dismissPopup}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart">
          <Popper className="popper" open={!!anchorEl} anchorEl={anchorEl}
            placement="bottom" >
            <div className="selector-options guest-selector fancy-scroll" style={{ width: anchorEl.getBoundingClientRect().width }}
              onScroll={onScroll}
              ref={scrollerRef}
              data-testid="guest-selector-popper"
            >
              {/* add a "simply add what you enter" item */}
              <SimpleAddWhatYouEnterItem value={value} classes={(0 === popperselected) ? 'selected' : ''} onClick={(e) => {
                // Fix: check if email entered
                let v = value.trim();
                if (v === '') return;
                if (MAIL.isValidSync({ mail: v.toLowerCase() })) _onAddContact(v);
                else {
                  let email = encodeLabelToEmail(value) + UNKNOWN_EMAIL_DOMAIN
                  _onAddContact(email, false, { name: value });
                }
                doReset(false)
              }}></SimpleAddWhatYouEnterItem>
              {filteredContacts.map((c, i) => <div key={c.email}>
                <GuestItem classes={((i + 1) === popperselected) ? 'selected' : ''} onClick={(e) => {
                  doSelect(c.email)
                }} contact={c} searchString={value} />
              </div>)}
            </div>
          </Popper ></ClickAwayListener>}
      {!!anchorEl &&
        !hasConnectedContacts && ((value && value.trim() !== '') || getFlagSetting(FLAGS.PROPOSE_CONTACT_POPUP)) && <ClickAwayListener onClickAway={dismissPopup}>
          <Popper className="popper" open={!!anchorEl} anchorEl={anchorEl}
            placement="bottom" >
            <div className="selector-options guest-selector fancy-scroll" style={{ width: anchorEl.getBoundingClientRect().width }}
              data-testid="guest-selector-popper-no-contacts"
            >
              <SimpleAddWhatYouEnterItem value={value} classes={'selected'} onClick={(e) => {
                let v = value.trim();
                if (v === '') return;
                if (MAIL.isValidSync({ mail: v.toLowerCase() })) _onAddContact(v);
                else {
                  let email = encodeLabelToEmail(value) + UNKNOWN_EMAIL_DOMAIN
                  _onAddContact(email, false, { name: value });
                }
                doReset(false)
              }}></SimpleAddWhatYouEnterItem>
              {getFlagSetting(FLAGS.PROPOSE_CONTACT_POPUP) && <div key={"contact-infos"} onClick={doConnectContacts}>
                <ConnectItem />
                <div className="close" onClick={toggleContactPopup}><div>{t('common.doNotShowAgain')}</div></div>
              </div>}
            </div>
          </Popper ></ClickAwayListener>}
    </div >
  )
}

export const SimpleAddWhatYouEnterItem = function ({ value = '', classes, onClick }) {
  const { t } = useTranslation();

  if (value.trim() === '') return null;

  return <div data-testid="free-text" className={"guest-item-contact " + classes} onClick={onClick}>
    <Avatar defaultTo={<Link />}
      fallBackColor='#CDCDCD'
    />
    <div className="content">
      <div className="name"><RenderWithSearch txt={value} srch={value} /></div>
      <div className="email">{t("createEvent.guests.list.simpleEnter")}</div>
    </div>

  </div>
}

export const ConnectItem = () => {
  const { t } = useTranslation();

  return <div className="connect-item-contact " data-testid="connect-contacts">
    <Avatar name="Connect Contacts"
      defaultTo={<MailOutline />}
      fallBackColor='#CDCDCD'
    />
    <div className="content">
      <div className="title">{t('settings.sections.tools.items.permissions.contacts.connect')}</div>
      <div className="desc">{t('settings.sections.tools.items.permissions.contacts.description')}</div>
    </div>
  </div>
}
// encode label to email like start. Could have some surprise, so keep it as
// a separate function
export function encodeLabelToEmail(label) {
  return encodeURIComponent(label).replace('.', '%2E');
}