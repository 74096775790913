import { createActions, handleActions, combineActions } from 'redux-actions';
import * as ACTIONS from '../actions'
import { STATUS } from '../api/validators/proposal';
// ALL THIS IS TEMPORARY!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const defaultState = {
  current:[],
  past:[],
};

const reducer = handleActions(
{
  [ACTIONS.WORKER_GET_PROPOSAL_SUCCESS]: (state, action) => {
    console.log('WORKER_GET_PROPOSAL_SUCCESS', action)
    if(action.sorted_proposals){
      return {
        current: action.sorted_proposals.current || [],
        past: action.sorted_proposals.past || []
      };
    }
    return state;
    
  },
  [ACTIONS.WORKER_LOGOUT]: () => defaultState
}, defaultState);

export default reducer;

export function getUpcommingProposals(state){
  return state.dashboard.current;
}
export function getPastProposals(state){
  return state.dashboard.past ;
}