import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as ACTIONS from '../actions';

export default function useDefaultTZ(){
  const profile = useSelector((state)=> state.profile);
  const dispatch = useDispatch();
  
  React.useEffect(()=>{
    if(!profile) return;
    if(profile.timezone && moment.tz){
      moment.tz.setDefault(profile.timezone);
      dispatch({
        type: ACTIONS.WORKER_SET_TZ,
        payload: {
          timezone: profile.timezone,
        }
      });
    }
  },[profile])
}