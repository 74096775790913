import React from 'react';
import Avatar from '../../../avatar.with.states';
import { useTranslation } from 'react-i18next';
import './guest.item.scss';
import {
  MailOutline
} from '@material-ui/icons';
import NOOP from '../../../../utils/noop';
import { RenderWithSearch } from '../inputs/render.with.search';

const MAIL = <MailOutline />
/**
 * Display a simple contact
 * @param {Object} contact contact to display
 * @param {Function} onClick click callback if needed
 * 
 * @param {string} searchString string to search for
 */
export default function GuestItem({
  contact,
  onClick = NOOP,
  onMouseDown = NOOP,
  searchString = '',
  classes = ''
}) {
  const { t } = useTranslation();
  if (!contact) return null;
  const name = contact.name || contact.email;


  return <div className={"guest-item-contact " + classes} onClick={onClick} onMouseDown={onMouseDown} data-testid={contact.email}>
    <Avatar name={contact.external ? '' : name}
      defaultTo={contact.external ? MAIL : undefined}
      fallBackColor='#CDCDCD'
      imageSrc={contact.avatar_url}
    />
    <div className="content">
      <div className="name"><RenderWithSearch txt={name} srch={searchString} /></div>
      {name !== contact.email && <div className="email"><RenderWithSearch txt={contact.email} srch={searchString} /></div>}
      {/* {contact.external && <div className="email">{t('createEvent.form.externalContactWarning')}</div>} */}
    </div>

  </div>
}

