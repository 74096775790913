import React from 'react';
import {useSpring, useSprings, animated} from 'react-spring'
// import { interpolate } from 'flubber';

import './potatoes.scss';
/*
First potatoe
M 292.36854,77.465169 C 410.72006,80.19787 506.7225,179.94798 513.5191,268.62921 c 6.7966,88.68123 -73.88471,208.567 -211.77977,209.2809 C 163.84427,478.62401 30.71536,342.97694 39.982022,265.50562 49.248684,188.0343 174.01702,74.732468 292.36854,77.465169 Z

M 292.99326,63.721349 C 453.20096,48.337196 505.47306,176.82438 496.65168,265.50561 487.83031,354.18684 415.27035,494.06363 299.86517,496.02696 184.46,497.9903 50.706371,372.96346 56.224719,270.50337 61.743066,168.04329 132.78556,79.105502 292.99326,63.721349 Z

M 266.13034,93.083147 C 367.61444,78.323713 492.97868,206.8109 505.39775,305.48764 517.81682,404.16437 463.37372,492.81419 327.97753,488.53033 192.58135,484.24648 46.333338,417.31851 47.478651,286.12135 48.623965,154.92419 164.64623,107.84258 266.13034,93.083147 Z

M 261.75731,78.714608 C 363.86614,65.829332 480.4843,161.83112 487.90562,272.37753 495.32693,382.92393 437.76024,473.4479 302.36405,489.15505 166.96786,504.8622 15.722101,401.70053 36.858426,271.75281 57.994752,141.80509 159.64848,91.599883 261.75731,78.714608 Z

M 277.37529,62.471911 C 390.72906,63.330456 489.85509,163.08056 502.89888,268.0045 515.94267,372.92843 415.89507,490.94003 284.2472,482.28314 152.59932,473.62624 51.33109,364.8421 48.10337,273.00225 44.87565,181.1624 164.02151,61.613367 277.37529,62.471911 Z
*/

/**
 * Animation of potatoes
 * @param {string} className css class to add to svg
 * @param {Object} animation animation conf with
 *    width: width of animation,
      height: height of animation,
      viewBox: svg viewbox
      duration: in ms,

      paths: Array
          id: id of path animation
          classname: class css to apply
          keyframes: array of path keyframe
 */
export function Potatoes({ className = '', animation= {paths:[]}} ){
  
  let total = animation.paths.length;
  const props = useSprings(total, animation.paths.map(a=> ({
    config: { duration: animation.duration },
    from : { d: a.keyframes[0]},
    to: async next => {
      let i = 0;
      let t = a.keyframes.length;
      while(1){
        if(i === t) i = 0;
        await next({ d:a.keyframes[i++]});
      }
    }
  })));
  
  return <svg
    height={animation.height}
    width={animation.width}
    viewBox={animation.viewBox}
    y="0px"
    x="0px"
    id="anim"
    className={className}
    version="1.1">
      <defs id="defs30" />
      {
        props.map((p, i) => <animated.path key={i} d={p.d} className={animation.paths[i].classname}/>)
      }
      
  </svg>
}
export default React.memo(Potatoes)