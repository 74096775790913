import React from 'react';
import moment from 'moment';
import {
  ToggleButtonGroup,
  ToggleButton,
} from '@material-ui/lab';
import { DEFAULT_DAY_WH } from '../../../../utils/gen.timeframes';
import './day.selector.scss';
// Display day selector 
export default function DaySelector(
  { values = {}, // formik.values
    dow = [], // days of week indexes
    onChange }
) {
  let DAYS = moment.weekdays();
  DAYS.push(DAYS[0])
  // check each working hours?
  let v = React.useMemo(() => Object.keys(values), [values]);
  const toggleDay = React.useCallback((e, v) => {
    let wh = {};
    v.map((day) => {
      if (values[day]) {
        // copy
        wh[day] = values[day];
      } else {
        // create a dumb one?
        wh[day] = JSON.parse(JSON.stringify(DEFAULT_DAY_WH))
      }
    })
    onChange({
      target: {
        name: 'workingHours',
        value: wh
      }
    })
  }, [onChange, values])
  return (
    <ToggleButtonGroup
      value={v} onChange={toggleDay} className="done-input-toggle">
      {dow.map((d) => {
        return <ToggleButton value={d} key={'toggle-day-' + d} data-testid={'toggle-day-' + d}>
          {DAYS[d][0]}
        </ToggleButton>
      })
      }
    </ToggleButtonGroup >);

}