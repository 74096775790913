import { useSelector } from 'react-redux'
import { getAccounts } from '../reducers/accounts';


export default function useOnBehalfOf(calendar) {
    const accounts = useSelector(getAccounts);
    if(!calendar || !calendar.accountId || !calendar.calendarId) return '' 
    let selected = accounts.find((acc)=> acc.id === calendar.accountId)
    if (!selected) return '';
    let oboCalendar = false;
    if(selected.settings && selected.settings.freebusy_calendars){
      oboCalendar = selected.settings.freebusy_calendars.find((fb)=> fb === calendar.calendarId)
    } else {
      // is calendar primary?
      oboCalendar = ((selected.calendars || []).find((c)=> c.id === calendar.calendarId) || {primary: false}).primary
    }
    return oboCalendar ? '' : calendar.accountId+':'+calendar.calendarId
}
