import moment from 'moment';

// format duration to dd HH:mm:ss + optional string label
export function durationFormatter(value, t, ending) {
  let res = [];
  let duration = moment.duration(value, 'minutes');
  // get days
  let v = Math.floor(duration.asDays());
  if (v) res.push(t('common.days', { count: v }));
  // remove from duration
  value -= v * 24 * 60;

  duration = moment.duration(value, 'minutes');
  v = Math.floor(duration.asHours());
  if (v) res.push(t('common.hours', { count: v }));
  // remove from duration
  value -= v * 60;

  duration = moment.duration(value, 'minutes');
  v = Math.floor(duration.asMinutes());
  if (v) res.push(t('common.minutes', { count: v }));

  if (ending) {
    res.push(' ');
    res.push(t(ending));
  }
  return res.join(' ');
}