import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copyLinkToClipboard from 'copy-to-clipboard';
import * as ACTIONS from '../../../../actions';
import { useTranslation, Trans } from 'react-i18next';
import {
  Copy as FileCopy
} from '../../../../assets/icons';
import { useSnackbar } from 'notistack';
import DoneButton from '../../../DoneButton';
import Button from '@material-ui/core/Button';
import Mailer, { MAIL_RX, MAIL_RX_SENTENCE } from './mailer';
import noop from '../../../../utils/noop';
import { getAccountMails } from '../../../../reducers/accounts';

import { CircularProgress } from '@material-ui/core';
import './invite.scss';
import { FIREBASE_VALUES } from '../../../../middlewares/analytics';
import useReferralFactory from '../../../../hooks/use.referral.factory';

export default () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contacts = useSelector((state) => state.contacts);

  const sortedContacts = React.useMemo(() => {
    return Object.values(contacts)
      .sort((a, b) => ((a.name || a.email) < (b.name || b.email) ? -1 : 1))
  }, [contacts]);

  const userEmails = useSelector(getAccountMails);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [mails, setMails] = React.useState(['', '']); // list of mails to invite
  const [loading, setLoading] = React.useState(false);
  const [current, _setCurrent] = React.useState(0); // currently focused mail component

  const setCurrent = React.useCallback((i) => {
    if (i !== current) _setCurrent(i)
  }, [current, _setCurrent])

  const inviteAll = React.useCallback(() => {
    // keep only valid emails
    let m = mails.filter((m) => MAIL_RX.test(m));
    if (m.length === 0) return;

    setLoading(true);
    // Todo: call API
    dispatch({
      type: ACTIONS.WORKER_INVITE,
      payload: {
        emails: m
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_INVITE_SUCCESS,
        rejectOn: ACTIONS.WORKER_INVITE_ERROR,
      }
    })
      .then(() => {
        // analytics for K-Factor
        dispatch({
          type: ACTIONS.ANALYTICS_INVITE_SENT,
          payload: {
            invitedEmails: m,
            source: FIREBASE_VALUES.INVITE_SENT_FROM_INVITE
          }
        });
        // clear UI? or clear Panel?
        setMails(['', '']);
        enqueueSnackbar(t('settings.sections.invite.content.success'), {
          variant: 'success',
          className: "snack-success",
          action: (key) => {
            return <>
              <Button onClick={() => { closeSnackbar(key) }}>
                {t('common.ok')}
              </Button>
            </>;
          }
        })

      })
      .catch((err) => {
        console.error(err)
        // display error popup
        enqueueSnackbar(t('settings.sections.invite.content.error'), {
          variant: 'error',
          className: "snack-error",
          action: (key) => {
            return <>
              <Button onClick={() => { closeSnackbar(key) }}>
                {t('common.ok')}
              </Button>
            </>;
          }
        })
      }).then(() => {
        setLoading(false);
      });
  }, [mails, setLoading]);

  // remove a mail from list
  const removeMail = React.useCallback((index, moveFocus = true) => {
    // 2 cases: if less than 2 mails, just set empty
    if (mails.length > 2) {
      mails.splice(index, 1);
      let next = mails.length;
      setMails([...mails]);
      if (moveFocus) setCurrent(index + 1); // focus on last input
      return true;
    }
    /* istanbul ignore else not used anymore with 3 by default? */
    else {
      mails[index] = '';
      setMails([...mails]);
      if (moveFocus) setCurrent(index); // focus on last input
      return false;
    }

  }, [mails, setMails, setCurrent]);

  // validate a mail in list
  const addMail = React.useCallback((v, i) => {
    // set mail value
    if (v === '') {
      // empty value, if not 0 or 1, remove
      if (mails.lengh > 2) return removeMail(i);
      else return; // keep it
    }
    // if not unique, do not had
    let oi = mails.findIndex((m, ind) => (m === v && ind !== i));
    if (oi > -1) {
      // remove? so i is not valid anymore?
      let hasRemoved = removeMail(oi);
      if (hasRemoved && i > oi) i--;
    }
    mails[i] = v;
    setMails([...mails]);
    // focus on next input
    setCurrent(i + 1)
  }, [mails, setMails, setCurrent]);

  const link = t('sharelink.url');

  /* istanbul ignore next lib take care of all shit */
  const copyToClipboard = React.useCallback(() => {
    if (copyLinkToClipboard(link)) {
      enqueueSnackbar(t('sharelink.success'), {
        variant: 'success', className: "snack-success",
        action: (key) => {
          return <>
            <Button onClick={() => { closeSnackbar(key) }}>
              {t('common.ok')}
            </Button>
          </>;
        }
      })
    } else {
      enqueueSnackbar(t('sharelink.error'), { variant: 'error', className: "snack-error" })
    }
  }, [link, enqueueSnackbar]);

  // include referral factory widget
  useReferralFactory();

  // check at least 3 elements
  return (<div className="settings-invite  fancyscroll " data-testid="invite-panel">
    <div className={"section invite-team " + (loading ? 'inactive' : '')}>
      <div className="title">{t('settings.sections.invite.content.title')}</div>
      <div className="subtitle"><Trans i18nKey="settings.sections.invite.content.subtitle">Ask your contacts to join and see when they are available. <strong>Automagically!</strong></Trans></div>
      <div className="mails">
        {mails.map((mail, index) => {
          return <div key={index}>
            <Mailer value={mail}
              setFocus={setCurrent}
              index={index}
              focus={current === index}
              addMail={addMail}
              setmail={(v, i) => {
                // do some magic with d&d from other apps
                // see if there is some emails addresses in mail, if so, create as many emails
                // than emails provided
                let tmp = [...mails];
                let matches = MAIL_RX_SENTENCE.exec(v);
                if (matches !== null) {
                  let dt = new Set();
                  // dt.add(matches[0]);
                  while (matches !== null) {
                    if (matches[0] !== '') dt.add('' + matches[0]);
                    matches = MAIL_RX_SENTENCE.exec(v);
                  }

                  // for each mail I got, add en entrie
                  let nms = Array.from(dt); // list of added mails
                  if (nms.length > 1) {
                    // insert mails at index
                    tmp.splice(i, 0, ...nms);
                    setMails(tmp);
                    setCurrent(i);
                  } else {
                    // create a simple mail
                    tmp[i] = nms[0];
                    setMails(tmp)
                  }

                } else {
                  // create a simple mail
                  tmp[i] = v;
                  setMails(tmp)
                }

              }}
              removeMail={removeMail}
              contacts={sortedContacts}
              blacklist={userEmails} /></div>
        })}

        <Mailer
          setFocus={noop}
          addMail={noop}
          focus={current === mails.length}
          index={mails.length}
          setmail={(mail, index) => {
            // do some magic with d&d from other apps
            // see if there is some emails addresses in mail, if so, create as many emails
            // than emails provided
            let matches = MAIL_RX_SENTENCE.exec(mail);
            if (matches !== null) {
              let dt = new Set(mails);
              dt.add(matches[0]);
              while (matches !== null) {
                dt.add(matches[0]);
                matches = MAIL_RX_SENTENCE.exec(mail);
              }

              // for each mail I got, add en entrie
              let nms = Array.from(dt);
              setMails(nms);
              setCurrent(nms.length);
            } else {
              // create a simple mail
              setMails([...mails, mail]);
              setCurrent(index);
            }

          }}
          contacts={sortedContacts}
          blacklist={userEmails}
          showSelector={false}
        />

      </div>
      <DoneButton className="move-down"
        name="send-invite-btn"
        label={
          loading ?
            <>
              <CircularProgress />
              <div className="sending-invite">
                {t('settings.sections.invite.content.ctaLoading')}
              </div>
            </>
            : t('settings.sections.invite.content.cta')}
        disabled={loading}
        onClick={inviteAll} />
    </div>
    <div className="sharelink">
      <div className="title">{t('sharelink.title')}</div>
      <div className="sharelink-copy">
        <div className="sharelink-url">{t('sharelink.url')}</div>
        <DoneButton className="small grey"
          name="share-btn"
          onClick={copyToClipboard}
          icon={<FileCopy />}
          label={t('sharelink.copy')}

        ></DoneButton>
      </div>
    </div>

    {/*loading && <Loader/>*/}
  </div>)
}