import React from 'react';
import ROUTES from './routes.name';
import {
  Link,
} from "react-router-dom";
import { LogoDone } from '../assets/icons';
import DashboardMenu from '../components/pages/dashboard/menu';
import { LMTooltip } from '../components/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import OnlineHelp from '../components/online.help';
import { useDialog } from '../components/dialogs/dialog.provider';
import Rewards from './rewards';

import "./app.header.scss";
// import { useSelector } from 'react-redux';
// import { getNbCompleteProposalsICreated } from '../reducers/proposals';
const dlgOpts = { maxWidth: "lg", fullWidth: true };

// const NB_PROPOSAL_MAX_TO_SHOW_HELP = 10;

export default function AppHeader() {

  const { t } = useTranslation();
  const { enqueueDialog, closeDialog } = useDialog(dlgOpts);

  // const nbProposal = useSelector(getNbCompleteProposalsICreated);

  const showOnlineHelp = React.useCallback((e) => {
    e.preventDefault();
    const doClose = () => {
      closeDialog();
    }
    enqueueDialog({
      content: <OnlineHelp doClose={doClose} />,
      className: "big-screen"
    })
  }, [enqueueDialog, closeDialog]);

  return (
    <header className="app-header">
      
      <div>
        <div className="flexed">
          <Rewards/>
          <Link to={ROUTES.APP}><LogoDone /></Link>
            <IconButton onClick={showOnlineHelp}>
            <LMTooltip content={<p>{t('menu.helpOnlineTutorial')}</p>}><HelpOutline fontSize="small" /></LMTooltip>
          </IconButton>
        </div>
        <DashboardMenu />
      </div>
    </header>
  );

}