import { isProd } from "../api/config";

// web stream ID
const GA_MEASUREMENT_ID = isProd() ? 'G-HVWJ3XWZRP' : 'G-1YPJMNCDQN';

// load gtag.js script
let tag = 'script';
let a = document.createElement(tag);
let m = document.getElementsByTagName(tag)[0];
//a.async = 1; // try uncomment?
a.src = 'https://www.googletagmanager.com/gtag/js?id=' + GA_MEASUREMENT_ID;
if (m) m.parentNode.insertBefore(a, m);

// configure gtag snippet
window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments); }

// initialize
gtag('js', new Date());
gtag('config', GA_MEASUREMENT_ID);

window.gtag = gtag;