import React from 'react';
import moment from 'moment';
import { GRID_HEIGHT } from '../../../../agenda/config';
import './time.scss';

let timeID = 0;
const timeToCoords = (now)=>{
  
  // days
  let sow = now.clone().startOf('week');
  let days = now.diff(sow,'days') * 100 / 7;
  let mmtMidnight = now.clone().startOf('day');
  let hours = now.diff(mmtMidnight, 'minutes') * GRID_HEIGHT / 60;

  return {
    left: days+'%',
    top: hours,
    
  }
}
export default function Hour( {selectedDate = moment()} ){
  const [time, setTime] = React.useState();
  React.useEffect(()=>{
    // start timer
    let sow = selectedDate.clone().startOf('week');
    let eow = selectedDate.clone().endOf('week');
    let now = moment();
    if(now >= sow && now <= eow){
      setTime(timeToCoords(now))
      timeID = setInterval(()=> setTime(timeToCoords(moment())), 60 * 1000);
    } else {
      setTime();
    }
    
    return ()=>{
      if(timeID) clearInterval(timeID);
    }
  }, [selectedDate]);

  if(!time) return null;
  return <div className="heatmap-time-container"><div className="heatmap-time" style={time}/></div>;
}