import React from 'react';
import * as ACTIONS from '../../../actions';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Avatar from '../../avatar';
import {
  Link
} from 'react-router-dom';
import { Popover } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ROUTES from '../../../routes/routes.name';
import { useDialog } from '../../dialogs/dialog.provider';
import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import {
  Edit,
  VisibilityOff,
  Public,
  VisibilityOutlined
} from '@material-ui/icons';
import {
  getAccountsWithCalendar,
  getFBCalendarCount
} from '../../../reducers/accounts'

import { Switch } from '../../LMSwitch';
import "./accounts.scss";

export default function UserAccounts() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user)
  const accounts = useSelector(getAccountsWithCalendar);
  const calendarsCount = useSelector(getFBCalendarCount);

  return <div className="stats-accounts">
    <div className="profile">
      {user.avatar_url ? <img src={user.avatar_url} /> : <Avatar className="square profile-pic" name={user.name} imageSrc={null} />}
      <div className="profile-details">
        <div className="title">{user.name}</div>
        <div className='emails'>
          {accounts.map((acc) =>
            <div>{acc.email}</div>
          )}
        </div>
      </div>
    </div>
    <div className="availability">
      <div className="title"><VisibilityOutlined /><span>{t("profile.availability.title")}</span></div>
      <Link to={ROUTES.APP_SETTINGS_ACCOUNTS} className="item"><span className="count">{t("profile.availability.calendarsCount", { count: calendarsCount })}</span><Edit /></Link>
      <Link to={ROUTES.APP_SETTINGS_WH} className="item"><span className="count">{t("profile.availability.working_hours")}</span><Edit /></Link>
    </div>
    <div className="network-share">
      <SharedAvailability />
    </div>
  </div>
}

export const PUBLIC = 16,
  ORGANISATION = 1,
  CONTACT = 2,
  PROPOSAL = 4,
  REFERAL = 8,
  ALL = ORGANISATION + CONTACT + PROPOSAL + REFERAL;

function isSet(value, mask) {
  return (value & mask) === mask
}
function sharedWithNoLM(value) {
  return (value & ALL) === 0;
}
const SHARED = [
  {
    name: 'profile.availability.shareReferal',
    desc: 'profile.availability.shareReferalDesc',
    value: REFERAL,
    enabled: false,
  },
  {
    name: 'profile.availability.shareProposal',
    desc: 'profile.availability.shareProposalDesc',
    value: PROPOSAL,
    enabled: false,
  },
  {
    name: 'profile.availability.shareContact',
    desc: 'profile.availability.shareContactDesc',
    value: CONTACT,
    enabled: false,
  },
  {
    name: 'profile.availability.shareOrganisation',
    desc: 'profile.availability.shareOrganisationDesc',
    value: ORGANISATION,
    enabled: false,

  },
]


export function SharedAvailability({
  asDialog = true
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { enqueueDialog, closeDialog } = useDialog();
  const profile = useSelector((state) => state.profile);
  const [visibility, setVisibility] = React.useState(profile.fb_visibility);
  React.useEffect(() => setVisibility(profile.fb_visibility), [profile.fb_visibility]);

  const resume = React.useMemo(() => {
    if (visibility === 0) return <span><Trans i18nKey='profile.availability.shareNoneDesc' /></span>;
    if (isSet(visibility, ALL)) return <span><Trans i18nKey='profile.availability.shareAllDesc' /></span>;
    // must construct
    // get shared desc
    let shared = SHARED.reduce((acc, v) => {
      if ((v.value & visibility) === v.value) acc.push(t(v.desc));
      return acc;
    }, []);
    let items = shared.length > 1 ? [shared.slice(0, -1).map((s) => t(s)).join(t('common.listSeparator')), shared[shared.length - 1]] : [t(shared[0])];
    // TODO handle the array in i18n...
    return <span><Trans i18nKey='profile.availability.labels.share'
      components={{ strong: <strong /> }} values={{ values: items, count: items.length }}></Trans></span >;
  }, [visibility, t]);

  const processChange = React.useCallback((value) => {
    let old = visibility;
    setVisibility(value);
    dispatch({
      type: ACTIONS.WORKER_SAVE_WH,
      payload: {
        ...profile,
        fb_visibility: value,
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_SAVE_WH_SUCCESS,
        rejectOn: ACTIONS.WORKER_SAVE_WH_ERROR
      }
    }).catch((err) => {
      // rollback
      enqueueSnackbar(t('profile.availability.shareError'), { variant: 'error' });
      setVisibility(old);
    })
  }, [visibility, profile, dispatch, enqueueSnackbar, t])
  const handleChange = React.useCallback((item, state) => {
    let nv = visibility;
    console.log("INFO", nv, item)
    // special case, PUBLIC
    if (state) nv |= item.value;
    else nv &= ~item.value;
    if (sharedWithNoLM(nv)) {
      // confirm
      const onConfirm = () => {
        closeDialog();
        processChange(nv);
      }
      enqueueDialog({
        content: <Confirm onConfirm={onConfirm} onCancel={closeDialog} />,
        mustConfirm: true
      })
    } else {
      processChange(nv)
    }


  }, [visibility, processChange, closeDialog, enqueueDialog]);
  const handleNoVisibility = React.useCallback((state) => {

    if (state) {
      // confirm
      const onConfirm = () => {
        closeDialog();
        processChange(visibility & ~ALL);
      }
      enqueueDialog({
        content: <Confirm onConfirm={onConfirm} onCancel={closeDialog} />,
        mustConfirm: true,
        overrideDefaultProps: { maxWidth: "xs", fullWidth: true }
      })
    } else {
      // unclick, set all permissions
      processChange(ALL)
    }
  }, [visibility, processChange, closeDialog, enqueueDialog]);

  return asDialog ? <SharedAsDialog resume={resume} handleChange={handleChange} handleNoVisibility={handleNoVisibility} visibility={visibility} />
    : <ShareContent resume={resume} handleChange={handleChange} handleNoVisibility={handleNoVisibility} visibility={visibility} />
}
export function PublicAvailability() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const [visibility, setVisibility] = React.useState(profile.fb_visibility);
  React.useEffect(() => setVisibility(profile.fb_visibility), [profile.fb_visibility]);

  const { enqueueSnackbar } = useSnackbar();

  const processChange = React.useCallback((value) => {
    let old = visibility;
    setVisibility(value)
    dispatch({
      type: ACTIONS.WORKER_SAVE_WH,
      payload: {
        ...profile,
        fb_visibility: value,
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_SAVE_WH_SUCCESS,
        rejectOn: ACTIONS.WORKER_SAVE_WH_ERROR
      }
    }).catch((err) => {
      // rollback
      enqueueSnackbar(t('profile.availability.shareError'), { variant: 'error' });
      setVisibility(old)
    })
  }, [visibility, profile, dispatch, enqueueSnackbar, t])
  const handleChange = React.useCallback((e) => {
    let nv = visibility;
    let state = e.target.checked;
    // special case, PUBLIC
    if (state) nv |= PUBLIC;
    else nv &= ~PUBLIC;
    processChange(nv)
  }, [visibility, processChange]);
  return <div className={"section advanced-item "}>
    <div className="title">{t('settings.sections.privacy.public.title')}</div>
    <div className="notifications">
      <div className="notifications-item">
        <FormControlLabel
          value="start"
          control={<Switch className="done-input" checked={isSet(visibility, PUBLIC)} onChange={handleChange} color="primary" data-testid="swch-public-fb" />}
          label={t("settings.sections.privacy.public.label")}
        />
        <div className="more subtitle">{t('settings.sections.privacy.public.description')}</div>
      </div>
    </div>
  </div>
}

function SharedAsDialog({
  resume,
  handleChange,
  handleNoVisibility,
  visibility,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false); // closed by default
  const [anchorEl, setAnchorEl] = React.useState(null);
  const toggleOpen = React.useCallback((event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  }, [open, setOpen])
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  return <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
    <FormLabel component="div" className={"visibility no-margin " + (open ? " opened" : "")} onClick={toggleOpen}>
      {resume}
      <Edit data-testid="open-shared" />
      {/* <IconButton data-testid="open-shared">{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton> */}
    </FormLabel>
    {open &&
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='network-share-popover-container'>
        <ShareContent handleChange={handleChange} handleNoVisibility={handleNoVisibility} visibility={visibility} />
      </Popover>}
  </FormControl>
}
function ShareContent({
  resume = null,
  handleChange,
  handleNoVisibility,
  visibility,
}) {
  const { t } = useTranslation();
  return <div
    className="network-share-popover">
    {resume}
    <FormGroup>
      <FormControlLabel
        key={"shared-item-all"}
        control={
          <div>
            <Checkbox data-testid="btn-v-all" className="done-input" checked={isSet(visibility, ALL)} onChange={(evt) => evt.target.checked ? handleChange({ value: ALL }, true) : handleChange({ value: ALL }, false)} name={'all'}
            />
          </div>
        }
        label={<div className="label-with-icon"><span>{t('profile.availability.shareAll')}</span><Public /></div>}
      />
      {
        SHARED.map((v, i) => {
          return <FormControlLabel
            className={isSet(visibility, ALL) ? 'disabled' : ''}
            key={"shared-item-" + v.value}
            control={
              <div>
                <Checkbox className="done-input" checked={isSet(visibility, v.value)} onChange={(evt) => handleChange(v, evt.target.checked)} name={t(v.name)}
                  disabled={visibility === ALL}
                  data-testid={"btn-v-" + v.value} />
              </div>
            }
            label={v.icon ? <div className="label-with-icon"><span>{t(v.name)}</span>{v.icon}</div> : t(v.name)}
          />
        })
      }
      <FormControlLabel
        key={"shared-item-none"}
        control={
          <div>
            <Checkbox data-testid="btn-v-none" className="done-input" checked={sharedWithNoLM(visibility)} onChange={(evt) => handleNoVisibility(evt.target.checked)} name={'none'}
            />
          </div>
        }
        label={<div className="label-with-icon"><span>{t('profile.availability.shareNone')}</span><VisibilityOff /></div>}
      />
    </FormGroup>
    <div className='note'>{t('profile.availability.shareNote')}</div>
  </div>
}
function Confirm({ onConfirm, onCancel }) {
  const { t } = useTranslation();
  return <div data-testid='confirm-slg'>
    <DialogTitle key="title">
      {t('profile.availability.confirm.title')}
    </DialogTitle>,
    <DialogContent className="fancy-scroll" key="content">
      {t('profile.availability.confirm.message')}
    </DialogContent>
    <DialogActions>
      <Button data-testid="btn-confirm" onClick={onConfirm}>
        {t('profile.availability.confirm.ok')}
      </Button>
      <Button data-testid="btn-cancel" onClick={onCancel} color="primary" autoFocus>
        {t('profile.availability.confirm.cancel')}
      </Button>
    </DialogActions>
  </div>
}