import React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next'
import './grid.scss';

export default function Grid(){
  const { t } = useTranslation();
  const GRID = React.useMemo(()=>{
    let days = [];
    // 1st day(s) is timezone
    let hours = [];
    let m = moment();
    for(let h = 0; h<24; h++ ){
      m.hour(h);
      hours.push(<div className="tz-hour" key={'tz-'+h} id={'tz-'+h} >{m.format(t('common.hourFormat'))}</div>)
    }
    days.push(<div className="timezone" key="timezone">{hours.map(h=>h)}</div>)
    for(let d = 0; d< 7; d++){
      let hours = [];
      for(let h = 0; h<24; h++ ){
        hours.push(<div id="hours" className="hour" key={'day-' + d + 'hour-'+h}>
            <div  id="hours"  className="quarters"/>
            <div  id="hours"  className="quarters"/>
            <div  id="hours"  className="quarters"/>
            <div  id="hours"  className="quarters"/>
          </div>)
      }
      days.push(<div className="days" key={'day-'+d}>
        {
          hours.map((h)=> h)
        }</div>)
    }
    return days;
  }, [t])
  return (
    <div className="heatmap-grid">
      {
        GRID
      }
    </div>
  );
}