import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClickAwayListener, Portal } from '@material-ui/core';

import moment from 'moment';
import "./flip.confirm.scss";

function distance(x1, y1, x2, y2) {
  var dx = x1 - x2;
  var dy = y1 - y2;
  return Math.sqrt(dx * dx + dy * dy);
}
export const FlipConfirm = ({ name, selected, confirm, start, onChange, children, dataTestid, header }) => {
  const { t } = useTranslation();
  const [positions, setPositions] = React.useState({});
  const [askconfirm, setaskconfirm] = React.useState();
  const container = React.useRef();
  const child = React.useRef();

  dataTestid = dataTestid || name;

  React.useEffect(() => {
    if (!selected) { setPositions({}); setaskconfirm(false) }
  }, [selected]);
  const onFrontClick = React.useCallback((event) => {
    if (onChange) onChange(event);
    let lbl = child.current;
    if (!lbl) {
      return;
    }

    let { width, height, top, left } = lbl.getBoundingClientRect();
    var mx = event.clientX - left,
      my = event.clientY - top;
    var w = width,
      h = height;
    var directions = [
      { id: 'right', x: w, y: h / 2 },
      { id: 'left', x: 0, y: h / 2 }
    ];
    directions.sort(function (a, b) {
      return distance(mx, my, a.x, a.y) - distance(mx, my, b.x, b.y);
    });
    // check if out of screen to reposition in it
    // get container position
    let container = document.getElementById(name);

    let repos = { left: 0, top: 0 };
    if (container) {
      let left = -event.clientX + 150;
      left = window.innerWidth + left - 300 < 0 ? -(window.innerWidth - 310) : left;
      left = left > 0 ? -160 : left;
      repos = {
        top: event.clientY - 100,
        left,
      }
    }

    setPositions({ dataDirection: directions.shift().id, className: "is-open", position: repos });
    setaskconfirm(true);
  }, [name, onChange]);
  const remove = React.useCallback((e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setPositions({})
    setaskconfirm(false);
    if (onChange) onChange({ target: { name: name, value: false } });
  }, [onChange, name]);
  const doConfirm = React.useCallback((e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setPositions({})
    setaskconfirm(false);
    confirm()
  }, [confirm])

  return (

    <div className={"simple-flip-btn "} data-testid={"flip-btn-confirm" + dataTestid}
      ref={container}>
      {askconfirm &&
        <Portal ><ClickAwayListener onClickAway={remove}>
          <div className={"simple-flip-btn-container "}>
            <div className={"simple-flip-btn " + (askconfirm && positions.className)} data-direction={positions.dataDirection} data-testid={"flip-btn-confirm-dlg" + dataTestid}>
              <div className={"simple-flip-btn-back "} style={positions.position} data-testid={"flip-btn-back" + dataTestid}>
                {header}
                <p><Trans i18nKey="event.anonymous.lastToVote.confirm" values={{ start: moment(start).format(t('common.slotHourZ')) }}>confirm <b>date</b></Trans></p>
                <div className="actions">
                  <button className="no" onClick={remove} data-testid={"flip-btn-confirm-no" + dataTestid}>{t('common.cancel')}</button>
                  <button className="yes" onClick={doConfirm} data-testid={"flip-btn-confirm-yes" + dataTestid}>{t('event.anonymous.lastToVote.confirmOk')}</button>
                </div>
              </div></div></div></ClickAwayListener></Portal>}
      <div ref={child} onClick={onFrontClick} data-testid={"flip-click-target" + dataTestid}>{children}</div>

    </div>);
}