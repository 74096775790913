import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import AppHeader from './app.header';
import ROUTES from './routes.name';
import HomeV2 from '../components/pages/dashboard-v2';
import './dashboard.scss';
import Settings from '../components/pages/settings';
import Profile from '../components/pages/profile';

export default function Dashboard() {

  let location = useLocation();

  return (
    <div className='dashboard'>
      <AppHeader />
      <section>
        <div className="page-content">
          <div className="full-height">
            <Switch location={location}>
              <Route
                path={ROUTES.APP}
                exact={true}
                render={() => {
                  return <HomeV2 />;
                }
                } />
              <Route
                path={ROUTES.APP_SETTINGS}
                exact={false}
                render={() => <Settings />
                } />
              <Route
                path={ROUTES.APP_PROFILE}
                exact={true}
                render={() => <Profile />
                } />
              <Route path="*" render={() => <Redirect to={
                {
                  pathname: ROUTES.APP,
                  search: location.search
                }} />} />
            </Switch>
          </div>
        </div>
      </section>
    </div>
  );
}

