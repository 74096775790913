import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ACTIONS from '../../../actions';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../Loader';
import { DialogTitle, DialogContent, IconButton, CircularProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import './preview.scss';
import { getContacts } from '../../../reducers/contacts';
import { attendeeMustVote } from '../../../utils/nbr.of.voters';
export default function Preview({ proposal, doClose, options = {} }) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [preview, setPreview] = React.useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contacts = useSelector(getContacts);

  const nonLMInvitees = React.useMemo(() => {
    /* istanbul ignore if no work */
    if (!proposal || !proposal.invitees || !proposal.organizer) return [];
    let details = proposal.inviteesDetails || {};
    if (options && options.forcedEmailsOnly) {
      return proposal.invitees.filter(invitee => (invitee !== proposal.organizer.email) && (details[invitee] || {}).isForced);
    }
    if (options && options.LMEmailsOnly) {
      return proposal.invitees.filter(invitee => (invitee !== proposal.organizer.email) && ((contacts[invitee] || {}).isDoner && !(details[invitee] || {}).isForced)); // happen only if all doners
    }
    if (options && options.EmailsOnly) {
      return proposal.invitees.filter(invitee =>
        !(details[invitee] && details[invitee].isUnknown));
    }
    return proposal.invitees.filter(invitee =>
      !(details[invitee] && details[invitee].isUnknown) // skip unknown
      && (details[invitee] && details[invitee].response !== 'yes')
      && attendeeMustVote(proposal.invitees, proposal.inviteesDetails, contacts, details[invitee].vote_requested)
    );
  }, [proposal, contacts, options]);

  React.useEffect(() => {
    // load preview from server
    if (!proposal) return;
    setLoading(true)
    let values = proposal;
    dispatch({
      type: ACTIONS.WORKER_GET_PREVIEW,
      payload: JSON.stringify({
        title: values.title || t('createEvent.preview.titlePlaceholder'),
        location: values.location,
        conference: values.conference,
        duration: values.duration,
        customduration: +values.customduration,
        reminders: values.reminders,
        notes: values.notes,
        calendar: values.calendar,
        organizer: values.organizer,
        invitees: values.invitees,
        inviteesDetails: values.inviteesDetails,
        proposals: values.proposals,
        slots: values.slots,
        currenttimeframe: values.currenttimeframe,
        duringWH: values.duringWH,
        sendEmail: !values.generateLink,
        preview_logo: values.preview_logo,
      }),
      showForcedAttendees: options && options.forcedEmailsOnly,
      as_reminders: options && options.show_reminders,
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PREVIEW_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PREVIEW_ERROR
      }
    }).then((rep) => {
      if (rep && rep.payload)
        setPreview({ __html: rep.payload });
    })
      .catch((err) => {
        setError(err);
      }).then(() => setLoading(false))
  }, [dispatch, proposal]);
  let inner = null;
  if (error) inner = <ErrorPreview />;
  else if (preview) inner = <>
    <Explanation nonLMInvitees={nonLMInvitees} options={options} /><div className={options.className || ''} dangerouslySetInnerHTML={preview}></div>
  </>;
  // has htlm code is generated by server, it's OK do do it
  return [
    <DialogTitle key="title" className={"title-with-close " + (loading ? "loader" : "")}>
      {loading ?
        <><CircularProgress size={30} /><span>{t('common.loading')}</span></>
        : t('createEvent.preview.title')}
      {doClose && <IconButton onClick={doClose}>
        <Close />
      </IconButton>}
    </DialogTitle>,
    loading ? null :
      <DialogContent className="fancy-scroll preview-email-dlg" key="content">
        {inner}
      </DialogContent>
  ];
}

export function LoadingPreview() {
  return <Loader className="no-fullscreen" />;
}
export function ErrorPreview() {
  const { t } = useTranslation();
  return <div data-testid="error-preview" className="error-preview">
    <div className="error-message">{t('createEvent.preview.error')}</div>
  </div>;
}

const Explanation = ({ nonLMInvitees = [], options }) => {
  const { t } = useTranslation();
  return (
    <div className="preview-explanation">
      <div className="sentence">
        {t('createEvent.preview.explanation')}
        {/* {options.doOpen && <span className="sentence"><a href="#" onClick={() => options.doOpen({ showAsDialog: true })}>{t('createEvent.preview.open')}</a></span>} */}
      </div>

      <div className="from">
        <span className="label">{t('createEvent.preview.from')}</span>
        <span className="value">Letsmeet</span>
      </div>
      <div className="to">
        <span className="label">{t('createEvent.preview.to')}</span>
        <span className="value">
          {
            nonLMInvitees.map((invitee, index) => <span className="invitee">{invitee}{index === nonLMInvitees.length - 1 ? "" : ", "}</span>)
          }
        </span>
      </div>
    </div>
  );
}