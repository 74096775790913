import React from 'react';
import { useTranslation } from 'react-i18next';
import { linearize } from '../../worker/handlers/generate.slots';
import moment from 'moment';
import Avatar from '../avatar';
import "./funnel.promote.scss";

export default function FunnelPromote({ proposal }) {
  if (proposal) {
    return <FunnelPromoteAdaptative proposal={proposal} />
  } else {
    return <FunnelPromoteSimple />
  }
}
export function FunnelPromoteAdaptative({ proposal }) {
  const { t } = useTranslation();
  const steps = React.useMemo(() => {
    if (!proposal
      || !proposal.organizer
      || !proposal.invitees
      || !proposal.slots) return [];

    console.log("PROPOSAL", proposal)
    let steps = [];
    // create steps data from proposal info
    // first, organizer
    let slots = linearize(proposal.slots)
    let slotCount = slots.length; // nbr of slots proposed
    let guestCount = proposal.invitees.length;
    let demoSlots = slots.slice(0, 10); // 10 slots max
    steps.push({
      view: <FunnelView name={proposal.organizer.name} slots={demoSlots} />,
      text: t('funnelPromote.step1adap', { name: proposal.organizer.name, guestCount: guestCount, slotCount: slotCount })
    });
    let currentGuestIndex = 0;
    if (guestCount > 1) {
      // add 2 invitees if possible for text 1 and 2
      let first = proposal.inviteesDetails[proposal.invitees[currentGuestIndex++]] || {}
      steps.push({
        view: <FunnelView name={first.name} slots={demoSlots.slice(0, 8)} />,
        text: t('funnelPromote.step2adap', { name: first.name, guestCount: guestCount, slotCount: slotCount, slotVoted: 8 })
      });
      first = proposal.inviteesDetails[proposal.invitees[currentGuestIndex++]] || {}
      steps.push({
        view: <FunnelView name={first.name} slots={demoSlots.slice(0, 6)} />,
        text: t('funnelPromote.step3adap', { name: first.name, guestCount: guestCount, slotCount: slotCount, slotVoted: 6 })
      });
      // if more invitees?
      if (guestCount > 3) {
        // add a label for the rest of them
        // let first = proposal.inviteesDetails[proposal.invitees[currentGuestIndex++]] || {}
        // steps.push({
        //   name: first.name, // name of 2nd invitee
        //   slots: proposal.slots, // slots it choose from
        //   text: t('funnelPromote.stepMore', { name: proposal.organizer.label, guestCount: guestCount, slotCount: slotCount, slotVoted: slotCount - 2 })
        // });
      }
    }
    // last one
    let first = proposal.inviteesDetails[proposal.invitees[guestCount - 1]] || {}
    steps.push({
      view: <FunnelView name={first.name} slots={demoSlots.slice(0, 2)} />,
      text: t('funnelPromote.step4adap', { name: first.name, guestCount: guestCount, slotCount: slotCount, slotVoted: 2 })
    });
    return steps;
  }, [proposal])
  if (!proposal) return null;
  return <div className="funnel-promote theme--light">
    <div className="funnel-promote-title">{t('funnelPromote.title')}</div>
    <ul>
      {
        steps.map((step, i) => {
          return <li key={'funnel-' + i}>{step.view}{step.text}</li>
        })
      }
    </ul>
  </div>
}
export function FunnelPromoteSimple() {
  const { t } = useTranslation();
  return <div className="funnel-promote theme--light">
    <div className="funnel-promote-title">{t('funnelPromote.title')}</div>
    <ul>
      <li><img alt="funnel 1" src="/app/funnel1.png" />{t('funnelPromote.step1')}</li>
      <li><img alt="funnel 2" src="/app/funnel2.png" />{t('funnelPromote.step2')}</li>
      <li><img alt="funnel 3" src="/app/funnel3.png" />{t('funnelPromote.step3')}</li>
      <li><img alt="funnel 4" src="/app/funnel4.png" />{t('funnelPromote.step4')}</li>
    </ul>
  </div>;
}
function FunnelView({ name, slots }) {
  return <div className="funnel-view">
    <Avatar name={name} />
    <div className="slots">
      {slots.map((slot) => {
        return <Slot slot={slot} />
      })}
    </div>
  </div>
}
function Slot({ slot }) {
  const { t } = useTranslation();
  return <div className={'slot ' + (slot.isSelected ? "selected" : "")}>
    <span className="date">{moment(slot.start).format("MM/DD")}</span>
    <span className="hour">{moment(slot.start).format(t('common.hourFormat'))}</span>
  </div>
}