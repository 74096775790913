import React from 'react';
import { connect } from 'react-redux';
import {
  PROVIDERS
} from '../../../../../assets/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import {
  getAccountsWithCalendar
} from '../../../../../reducers/accounts';
import DoneButton from '../../../../DoneButton';
import { useTranslation } from 'react-i18next';
import ROUTES from '../../../../../routes/routes.name';
import '../inputs.scss';
import './calendar.selector.scss';

/**
 * Display list of calendars
 * @param {string} selectedId uuid of calendar as account.id + calendar.id
 * @param {Function} processItemClicked callback when a calendar is selected
 * 
 * from redux
 * @param {Array<Account>} accounts user connected accounts
 */
export function CalendarList({
  accounts,
  selectedId,
  processItemClicked,
}) {
  const {t} = useTranslation();
  const hasMoreThanOneCalendarInAccount = React.useMemo(()=>{
    if (!accounts || accounts.length === 0) return false;
    for(let acc of accounts){
      if(acc.calendars && acc.calendars.filter((c)=> !c.readonly).length > 1) return true;
    }
    return false;
  },[accounts]);
  if (!accounts || accounts.length === 0) return null; //could this happen IRL?

  if(hasMoreThanOneCalendarInAccount){
     return (
      <div className="selector-options done-input">

        <List>

          {accounts.map((acc) => (
            <li key={`section-${acc.id}`}>
              <ul>
                <ListSubheader><div className="selected-calendar">
                  <div className="content">
                    <div className="title">{acc.email}</div>
                  </div>
                </div></ListSubheader>
                {acc.calendars.map((item) => {
                  const key = `item-${acc.id}-${item.id}`
                  return <ListItem button key={`item-${acc.id}-${item.id}`}
                    data-testid={`item-${acc.id}-${item.id}`}
                    onClick={() => processItemClicked(acc, item)}>
                    <ListItemIcon>
                      <Radio
                        edge="start"
                        checked={key === selectedId}
                        tabIndex={-1}
                        disableRipple
                      /></ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                })}
              </ul>
            </li>

          )
          )}
          <li className="manage-link">
            <Link to={ROUTES.APP_SETTINGS_ACCOUNTS}><DoneButton label={t('createEvent.form.manageCalendars')} className="grey"/></Link>
          </li>
        </List>
      </div>
    );
  } else {
    // simplier version, display only accounts emails
    return <div className="selector-options done-input">
        <List>
          {accounts.map((acc) => {
            let item = acc.calendars[0];
            if(!item) return null;
            const key = `item-${acc.id}-${item.id}`;
            return <li key={`section-${acc.id}`}>
              <ul>
                  <ListItem button key={`item-${acc.id}-${item.id}`}
                    data-testid={`item-${acc.id}-${item.id}`}
                    onClick={() => processItemClicked(acc, item)}>
                    <ListItemIcon>
                      <Radio
                        edge="start"
                        checked={key === selectedId}
                        tabIndex={-1}
                        disableRipple
                      /></ListItemIcon>
                    <ListItemText primary={acc.email} />
                  </ListItem>
              </ul>
            </li>

          }
          )}

          <li className="manage-link">
            <Link to={ROUTES.APP_SETTINGS_ACCOUNTS}><DoneButton label={t('createEvent.form.manageCalendars')} className="grey"/></Link>
          </li>
        </List>
        
      </div>
  }
 
}


const mstp = (state) => ({
  accounts: getAccountsWithCalendar(state),
})
const mdtp = (dispatch) => ({

});
export default connect(mstp, mdtp)(CalendarList);
