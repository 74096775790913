import React from 'react';
import Button from '@material-ui/core/Button';
import './DoneButton.scss';


const NOOP = () => { }
/**
 * Default button for done app
 * @param {string} label text to display
 * @param {Component} icon icon to display
 * @param {string} className classes to override
 * @param {boolean} disabled if true
 * @param {Function} click callback
 */
export default function DoneButton({
  label = 'common.common',
  icon,
  endIcon,
  disabled = false,
  className = '',
  onClick = NOOP,
  name,
  type = "button"
}) {
  return (
    <Button type={type}
      data-testid={name}
      className={"done-button " + (disabled ? 'disabled ' : '') + className + (endIcon ? " end-endornment " : "")}
      onClick={onClick}>
      {icon && icon}
      {label}
      {endIcon && endIcon}
    </Button>
  )
}