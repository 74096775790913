/* istanbul ignore file do nothing methods */
/**
 * Noop function
 * usefull for default listener
 */
export default () => { }
export const NOOP_RESOLVE = () => Promise.resolve();
export const NO_BUBBLES = (e) => {
  e.stopPropagation();
  e.preventDefault();
}
export const NO_PROPAGATION = (e) => {
  e.stopPropagation();
}