import React from 'react';
import useGoogle from '../hooks/use.google.login';
import useMSALLogin from '../hooks/use.msal.login';
import { LogoDone } from '../assets/icons';
import { useDialog } from './dialogs/dialog.provider';
import { askForLogin } from '../reducers/user';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
  IconDelete,
  PROVIDERS
} from '../assets/icons';
import { Loader } from './Loader';
import TLC from './tlc';
import * as ACTIONS from '../actions'
import './provider.id.scss';
import { useLocation, useHistory } from 'react-router-dom';
import routes from '../routes/routes.name';
import { getUserDatas } from '../reducers/user'
import jwt_decode from 'jwt-decode';
import { signInAction } from '../reducers/user';
import { parseQuery } from '../utils/parse.query';
import { LS_KEY_BACK_HERE_AFTER_SIGNUP } from './pages/event/simple';
import { DOMAIN } from '../api/config';
import Promote from './Promote';

export function ProvidersID({ children,
  autoload = true,
}) {
  const [autoConnect, setAutoConnect] = React.useState(true);
  const [gloading, setgLoading] = React.useState(true); // google is 1, crosoft is 2, 3 means all are loading
  const [mloading, setmLoading] = React.useState(true); // google is 1, crosoft is 2, 3 means all are loading
  const { enqueueDialog, closeDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const askLogin = useSelector(askForLogin);
  const location = useLocation();
  const history = useHistory();
  const [justSignedUp, setjustSignedUp] = React.useState(false);
  const dispatch = useDispatch();
  const fromEventPage = location && location.pathname && location.pathname.startsWith(routes.EVENT);
  const SuccessCllbck = React.useCallback(() => {
    closeDialog();
    dispatch({
      type: 'ASK_4_LOGIN',
      payload: false,
    });
    try {
      let redirectTo = localStorage.getItem(LS_KEY_BACK_HERE_AFTER_SIGNUP, '');
      let url = redirectTo.split('?')[0]; // remove query string (?...)
      let extra = parseQuery('?' + (redirectTo.split('?')[1] || ''), false);
      console.log("EXTRA", url)
      if (url.startsWith(DOMAIN)) url = url.split(DOMAIN)[1]; // remove host/port (http://...../) + /app!!!
      console.log("EXTRA", url)
      if (url.startsWith('/app')) url = url.split('/app')[1]; // remove host/port (http://...../) + /app!!!
      console.log("EXTRA", url)
      localStorage.removeItem(LS_KEY_BACK_HERE_AFTER_SIGNUP);
      if (url) history.push(url, extra.appstate)
    } catch (err) { }

    setgLoading(false);
    setmLoading(false);

  }, [history, askLogin])
  const errorCllbck = React.useCallback((from, mode = '', more = {}) => {
    if (from === 1) setgLoading(false);
    else setmLoading(false)
    if (mode !== 'silent') {
      closeDialog();
      dispatch({
        type: 'ASK_4_LOGIN',
        payload: false,
      });
    }
    if (more && more.payload && more.payload.code === 'INVALID_PROVIDER_NAME') {
      enqueueSnackbar(t("errors.badProvider", { provider: more.payload.provider }), {
        variant: 'error',
        className: 'snack-error',
      })
    }
  }, []);
  // for analytics prupose we want to know if the account is created from the event Page.
  const googleloggin = useGoogle(SuccessCllbck, errorCllbck, fromEventPage);
  const mslogin = useMSALLogin(SuccessCllbck, errorCllbck, fromEventPage);
  const doLog = (provider) => {
    // Use pathname if not on /auth ? What if coming from event page? => OK
    let pathname = window.location.pathname;
    if (pathname && pathname.endsWith('/auth')) {
      try {
        if (askLogin && askLogin.state) pathname += "?appstate=" + askLogin.state;
        localStorage.setItem(LS_KEY_BACK_HERE_AFTER_SIGNUP, pathname);
      } catch (err) { }
    }
    if (provider === 'google') {
      setgLoading(true);
      googleloggin();
    } else {
      setmLoading(true);
      mslogin(provider);
    }
  }

  // auto connect on application load
  React.useEffect(() => {
    // if got a provider in LS, sign in normal
    // get LS datas
    let providerToken;
    let token;
    let providerInfos;
    let knownAccounts;
    let urlToGoBackTo;
    try {
      urlToGoBackTo = localStorage.getItem(LS_KEY_BACK_HERE_AFTER_SIGNUP);
      providerToken = JSON.parse(localStorage.getItem('provider'));
      if (providerToken) localStorage.removeItem('provider'); // Change: token is a one time use!
      token = JSON.parse(localStorage.getItem('done_token'));
      providerInfos = JSON.parse(localStorage.getItem('user_datas') || '{}');
      knownAccounts = JSON.parse(localStorage.getItem('known_accounts'));
    } catch (err) { }

    let user = getUserDatas(providerInfos);
    let query = parseQuery(location.search);

    if (query && query.register_status && query.register_status !== '200') {
      // dispatch an error
      // can have more status now
      // 409 conflict => premium admin account
      // 422 UnprocessableEntity => error on store account
      let labelKey = 'errors.registerUnknown';
      // special case
      if (query.register_status == 403) {
        // show dialog error
        enqueueDialog({
          content: <ErrorAutorizationDlg googleloggin={() => doLog('google')} mslogin={() => doLog('microsoft')} />,
          doClose: closeDialog,
          mustConfirm: true,
          fullScreen: true,
        });
      } else {
        switch (query.register_status) {
          case '409': {
            labelKey = 'errors.registerPremiumAdmin';
            break;
          }
          case '422': {
            labelKey = 'errors.registerStoreAccount';
            break;
          }
        }
        enqueueSnackbar(t(labelKey), {
          variant: 'error',
          className: 'snack-error',
        })
      }

    }
    let p = Promise.resolve();
    // if got a provider, try sign in app
    if (providerToken && providerToken.name) {
      // check if got the register success
      if (query && query.register_status === '200') {
        // after a successful sign up, we try to sign in
        p = dispatch(signInAction(providerToken.name, providerToken)).then(() => {
          // The account was successfully created 
          // and Segment *identify* call was done with connected user info
          // we can send sign_up event to analytics 
          // This analytics "signup" event will be sent after "signin" but no big deal
          dispatch({
            type: ACTIONS.ANALYTICS_SIGNED_UP,
            payload: {
              provider: providerToken.name,
              fromUrl: urlToGoBackTo,
            }
          });
          setjustSignedUp(true);
          // once signed in, we get back to original sign up URL
          // redirect to the page the user decided to register
          if (urlToGoBackTo) {
            try {
              localStorage.removeItem(LS_KEY_BACK_HERE_AFTER_SIGNUP);
            } catch (e) { }
            let url = urlToGoBackTo.split('?')[0]; // remove query string (?...)
            let extra = parseQuery('?' + (urlToGoBackTo.split('?')[1] || ''), false);
            if (url.startsWith(DOMAIN)) url = url.split(DOMAIN)[1]; // remove host/port (http://...../)   
            if (url.startsWith('/app')) url = url.split('/app')[1]; // remove host/port (http://...../) + /app!!!
            history.push(url, extra.appstate);
          } else {
            // go to create a meeting form
            console.log("REGISER OK, go to form")
            history.push(routes.CREATE_A_MEETING);
          }
        });
      }

    }
    // New case for anonymous link: if anonymous, must not reconnect...
    else if (token && providerInfos && user.email && knownAccounts && // can auto connect
      (!query.email || knownAccounts.find((a) => a === query.email)) && // got no email or not one of mine
      !(query.sig && !query.email)) { // got no sig without email

      let dt;
      try {
        dt = jwt_decode(token.done);
        if (!dt || !dt.exp) throw new Error('No timestamp');
      } catch (err) {
        console.log('Token check error', err)
        // resolve simply
        dt = {
          exp: Date.now() + 1000,
        }
      };

      // check if valid
      let now = Date.now();
      let valid = dt.exp * 1000;

      if (valid > now) {
        providerInfos.doneToken = token;
        providerInfos.provider = providerInfos.name;
        // ask for profile account data?
        providerInfos.loadProfile = true;
        // Update: do not ask for proposals because we have a dedicated
        // api for title predictive
        providerInfos.loadProposals = false;
        // try to auto-connect
        p = dispatch({
          type: ACTIONS.WORKER_CONNECT,
          payload: providerInfos,
          resolvers: {
            resolveOn: ACTIONS.WORKER_CONNECT_SUCCESS,
            rejectOn: [ACTIONS.WORKER_CONNECT_ERROR, ACTIONS.WORKER_CONNECT_ERROR_NO_TOKEN]
          }
        });
      } else {
        console.log('Token check invalid, no auto logg')
        p = Promise.resolve();
      }
    } else p = Promise.resolve();
    p.catch((err) => {
      // something went wrong while signin => logout
      console.error("Unable to signin: ", err);
      dispatch({
        type: ACTIONS.WORKER_LOGOUT
      });
    }).then((userData) => {
      // pass user data to Tawk.to chat
      if (window && window.Tawk_API && window.Tawk_API.setAttributes && userData && userData.account_profile) {
        window.Tawk_API.setAttributes({ name: userData.account_profile.name, email: userData.account_profile.email });
      }
      setAutoConnect(false);
    })

  }, []);


  const isLoading = autoConnect || gloading || mloading;
  React.useEffect(() => {
    if (askLogin) {
      // enqueue dialog
      const doClose = () => {
        setgLoading(false);
        setmLoading(false)
        dispatch({
          type: 'ASK_4_LOGIN',
          payload: false,
        })
        // popup closed, remove sign up origin URL 
        try {
          localStorage.removeItem(LS_KEY_BACK_HERE_AFTER_SIGNUP);
        } catch (e) { }
        closeDialog();
      }
      enqueueDialog({
        content: <div className={"toast fancy-scroll " + (isLoading ? 'no-display' : '')}><SignInDialogContent doClose={doClose} googleloggin={() => doLog('google')} mslogin={() => doLog('microsoft')}
          officelogin={() => doLog('office')} isModal={askLogin === 'modal'}
          isConnecting={isLoading} /></div>,
        doClose: doClose,
        mustConfirm: askLogin === 'modal',
        overrideDefaultProps: { maxWidth: "lg" },
      })
    }
  }, [askLogin, isLoading])

  // Just after sign up, display the panel that promotes extensions
  if (justSignedUp) {
    const goToSchedulingLink = location && location.pathname && location.pathname.startsWith(routes.CREATE_A_LINK);
    return <Promote dismiss={() => setjustSignedUp(false)} step={(goToSchedulingLink ? 1 : 0)} />;
  }

  return <>
    {(!isLoading) && children}
    {(isLoading) && <Loader />}
  </>;
}

export function SignInDialogContent({ doClose, googleloggin, mslogin, isModal, showTlc = true }) {
  const { t } = useTranslation();

  return (
    <div className="signin-dialog">
      <div className="header">{t('dialog.signin.title')}</div>
      <div className="providers">

        <Button
          variant="contained"
          startIcon={PROVIDERS.google}
          data-testid="google"
          onClick={googleloggin}
        >
          {t('dialog.signin.providerGoogle')}
        </Button>
        <Button
          variant="contained"
          startIcon={PROVIDERS.outlook}
          data-testid="microsoft"
          onClick={mslogin}
        >
          {t('dialog.signin.providerMicrosoft')}
        </Button>
      </div>
      {!isModal && doClose && <IconButton className="fixed-top" onClick={doClose} data-testid="success-close"><IconDelete /></IconButton>}
      {showTlc && <TLC />}
    </div>
  )
}
export function ErrorAutorizationDlg({ googleloggin, mslogin }) {
  const { t } = useTranslation();
  return (
    <div className="dialog-error-auth">
      <div className="header"><LogoDone /></div>
      <div className="content">
        <div className="text">
          <Trans i18nKey='dialog.auth.description'
            components={{
              title: <p className="pop-color" />
            }}>
            <title>Nous avons besoin de votre permission.</title>
            <p>Pour vous aider à planifier des réunions nous avons besoin d’accèder à votre calendrier.</p>
            <p>Une fois sur la page de votre fournisseur de calendrier, veillez à bien suivre les instructions et <b>selectionnez l’ensemble des permissions demandées.</b></p>
            <p>A vous de jouer et à tout de suite dans Letsmeet !</p>
          </Trans>
        </div>
        <div className="providers">

          <Button
            variant="contained"
            startIcon={PROVIDERS.google}
            data-testid="google"
            onClick={googleloggin}
          >
            {t('dialog.signin.providerGoogle')}
          </Button>
          <Button
            variant="contained"
            startIcon={PROVIDERS.outlook}
            data-testid="microsoft"
            onClick={mslogin}
          >
            {t('dialog.signin.providerMicrosoft')}
          </Button>
        </div>
        <p className="text">
          <Trans i18nKey='dialog.auth.footer'
            values={{ link: t('dialog.auth.link') }}
            components={{
              lnk: <a href={t('dialog.auth.link')} target='_blank' rel='noopener noreferrer' />
            }}>Pour en savoir plus vous pouvez consulter la rubrique d’aide. <lnk>C’est par ici.</lnk></Trans>
        </p>
      </div>
    </div>);
}