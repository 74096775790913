import React from 'react';
import moment from 'moment';
import { position } from '../../../../agenda/utils/position';
import BusyPopup from './Busy.popup';
import chroma from "chroma-js";
import { useTranslation } from 'react-i18next';
import './busy.scss';
import { Clear } from "@material-ui/icons"
const ALLOW_ON_PAST = false;
const COLOR_BUSY = true;
const GRIS = "#F7F7F7",
  GREEN = "#00a807",
  JAUNE = "#f7e51d", //"#F8F4C8", "#f7e51d",
  ROUGE = "#f52a2a"; // "#FFD2D2";"#f52a2a";
const CHROMAS = chroma.scale([JAUNE, ROUGE]);
function colorFromBusys(b, invitees = [], alpha = 0.5) {
  if (!b || !b.user) return GRIS;
  if (b.isPast) return GRIS;
  if (invitees.length === 0) return GRIS;
  let percent = Object.keys(b.user).length / (invitees.length + 1); // add me to the count

  return CHROMAS(percent)
    .alpha(alpha)
    .css();
}

export function Busy({
  selectedDate = moment(),
  busy = {},
  calendar, // current calendar used
  organizer, invitees,
  oboCalendar,
  interactive = true,
}) {


  // useDiff(busy)

  const busyContainer = React.useRef(null);
  // place times on grid
  const busyCmp = React.useMemo(() => {
    // if (!busyContainer || !busyContainer.current) return [];
    const { width } = busyContainer.current ? busyContainer.current.getBoundingClientRect() : { width: 100, height: 100 };

    let st = moment(selectedDate.format()).startOf('week');
    let ed = moment(selectedDate.format()).endOf('week');
    const period = {
      start: st,
      end: ed,
      numOfDays: 7, // for now, only weeks
    }
    let p = position(period, busy, false, false);
    if (!p || !p.events) return [];
    return p.events
      .map((e) => {
        // if host is only one free, use special grey color
        let bcolor = colorFromBusys(e, invitees);
        // see https://gitlab.com/letsme.et/done-calendar-web/-/issues/249
        // if (Object.keys(e.user || {}).length === 1) {
        //   const email = (organizer || {}).email;
        //   if (email && e.user[email]) bcolor = GRIS;
        // }
        return {
          ...e,
          position: {
            ...e.position,
            left: `calc(${e.position.x}% + 4px)`,
            top: e.position.y,
            width: `calc(${e.position.width}% - 9px)`,
            // backgroundColor: colorFromBusys(e, invitees, 0.2),
            borderLeft: COLOR_BUSY ? `5px solid ${bcolor}` : undefined,
            // backgroundImage: COLOR_BUSY ? `linear-gradient(135deg, ${bcolor} 50%, #f7f7f7 60%, #f7f7f7 10%, ${bcolor} 10%, ${bcolor} 90%, #f7f7f7 90%, #f7f7f7 100%)` : undefined,
            // backgroundSize: COLOR_BUSY ?  "42.43px 42.43px" : undefined,
            // backgroundPositionX: `-${(width * e.position.x) / 100}px`,
            // backgroundPositionY: `-${e.position.y}px`
          },
          background: {
            backgroundPositionX: `-${width * e.position.x / 100}px`,
            backgroundPositionY: `-${e.position.y}px`
          }
        };
      });
  }, [busy, selectedDate, busyContainer.current]);
  // },[times, selectedDate, busyContainer.current]);


  return (
    <div className="busy-times" ref={busyContainer}>
      {
        busyCmp.map((b, i) => {
          // tooltip position -----------------------------
          let position = placePoppup(b);
          let id = undefined;
          if (ALLOW_ON_PAST && b.isPast) id = 'hours-past';
          let className = 'hash '; //  + ((!b.isPast && b.isNew) ? "animated" : '');
          if ((b.off === true && !b.busy) || b.isPast) className += ' away'
          return (
            <div id={id} data-testid={'busy-' + i} key={b.id + '-' + i} className={"busy-slots " + (b.isPast !== true ? "evttooltip" : "")} style={b.position}>
              <div className={className} style={b.background}></div>
              {(interactive && b.isPast !== true) && <BusyPopup organizer={organizer} calendar={calendar} oboCalendar={oboCalendar} inviteesList={invitees} invitees={b.user} position={position} />}
            </div>
          )
        })
      }
    </div>
  );
}

export function placePoppup(event) {

  let position = "top";

  // use event start day or dedicated property `currentDate` for mulit-day busy/event
  let startDate = event.currentDate ? event.currentDate.clone() : event.start.clone();
  // event starts in the morning and ends in the afternoon
  if (startDate.day() === moment().endOf("week").day() // the last day of the week
    || (moment().endOf("week").day() === 0 && startDate.day() === 6) // the day before the last day of the week (if week ends on sunday)
    || (moment().endOf("week").day() === 6 && startDate.day() === 5) // the day before the last day of the week (if week ends on saturday)
  ) {
    // special case for last 2 days of the week => popup on the left
    position = "left";
  } else {
    // popup on the right
    position = "right"
  }
  //}

  // position on sides if first and last day of week
  if (startDate.day() === moment().startOf("week").day()) position = "right";
  if (startDate.day() === moment().endOf("week").day()) position = "left";

  return position;
}


// function useDiff(value) {
//   const ref = React.useRef();
//   if (ref.current && value !== ref.current) compare(ref.current, value)
//   // save old
//   React.useEffect(() => {
//     ref.current = value;
//   }, [value]);
// };
// const compare = (oldstate = [], newstate = []) => {
//   // check if some busy times added
//   // cannot mock simply this
//   if (!Array.isArray(oldstate)) return;

//   for (let st of newstate) {
//     // get in old state
//     st.isNew = !oldstate.find((os) => {
//       return os.start.unix() === st.start.unix() && os.end.unix() === st.end.unix()
//     })
//   }
// }


export default React.memo(Busy)