import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ROUTES from '../../../../../routes/routes.name';
import { ErrorBoundary } from '../../../../errors/ErrorBoundary';
import '../inputs.scss';
import './calendar.selector.scss';
import { getAccount, getCalendar } from '../../../../../reducers/accounts';
import CalendarSelectorRenderer from './CalendarSelectorRenderer';
import routes from '../../../../../routes/routes.name';
import { PROVIDERS } from '../../../../../assets/icons';
import useGoogle from '../../../../../hooks/use.google.login';
import useMSALLogin from '../../../../../hooks/use.msal.login';
import Tlc from '../../../../tlc';
import { useHistory } from 'react-router-dom';

/**
 * Caldendar selector for user
 * @param {any} value selected calendar
 * @param {Function} onChange change callback
 * 
 */
export function CalendarSelector({
  value,
  isAuth,
  profile,
  onChange,
  name,
  disabled = false,
  account,
  calendar,
  Renderer = CalendarSelectorRenderer,
  ConnectYourCalendarRenderer = ConnectYourCalendar,
  labelTitle,
  labelHelp,
  showTimezone = true,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const doSign = React.useCallback(() => {
    window.location.assign(routes.AUTH)
  }, [])
  const processItemClicked = React.useCallback((account, calendar) => {
    let wh = calendar.working_hours ? calendar.working_hours.days : undefined;
    onChange({
      target: {
        name: name,
        value: {
          accountId: account.id,
          calendarId: calendar.id,
          provider: account.provider.name,
          email: account.email,
          name: account.name,
          timezone: calendar.timezone,
          working_hours: wh,
          supported_conferences: calendar.supported_conferences || []
        }
      }
    });
    setAnchorEl(null);
  }, [onChange])
  const handleClick = React.useCallback((event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }, [anchorEl]);
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);


  const isValid = !!value && calendar; // value will at least be default one if exists
  const selectedId = value ? `item-${value.accountId}-${value.calendarId}` : ''; // SHould not happen

  if (!isAuth) return <ConnectYourCalendarRenderer doSign={doSign} />;

  return (
    <ErrorBoundary>
      <Renderer
        isValid={isValid}
        disabled={disabled}
        isAuth={isAuth}
        handleClick={handleClick}
        doSign={doSign}
        name={name}
        profile={profile}
        calendar={calendar}
        account={account}
        anchorEl={anchorEl}
        handleClose={handleClose}
        processItemClicked={processItemClicked}
        selectedId={selectedId}
        labelTitle={labelTitle}
        labelHelp={labelHelp}
        showTimezone={showTimezone}
      />
    </ErrorBoundary>
  );

}

const mstp = (state, props) => {
  return {
    account: getAccount(state, { accountId: (props.value || {}).accountId }),
    calendar: getCalendar(state, { calendarId: (props.value || {}).calendarId, accountId: (props.value || {}).accountId })
  }
};

export default connect(mstp)(CalendarSelector);

const ConnectYourCalendar = ({ doSign }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const SuccessCllbck = React.useCallback(() => {
    setLoading(false);
    history.push(ROUTES.APP)
  }, [history])
  const errorCllbck = React.useCallback(() => setLoading(false), [])

  const googleloggin = useGoogle(SuccessCllbck, errorCllbck, false); // no auto-login
  const mslogin = useMSALLogin(SuccessCllbck, errorCllbck, false); // no auto-login

  const login = React.useCallback((type) => {
    setLoading(true);
    if (type === 'google') googleloggin();
    else mslogin(type);
  }, [googleloggin, mslogin])

  return (
    <div className="done-calendar-selector user-not-auth">
      <div className='title'>{t('createEvent.form.signup.title')}</div>
      <div className='container'>
        <div className='subcontainer'>
          <div className='subtitle1'><Trans i18nKey='createEvent.form.signup.subtitle1'>Sign up and connect your business calendar to plan your first meeting.</Trans></div>
          {/* <div className='subtitle2'>Use your corporate email address to sync your calendar and plan your first meeting.</div> */}
        </div>
        <div className="providers">
          <Button
            variant="contained"
            startIcon={PROVIDERS.google}
            data-testid="google-btn"
            onClick={() => login('google')}>
            {t('dialog.signin.providerGoogle')}
          </Button>
          <Button
            variant="contained"
            startIcon={PROVIDERS.outlook}
            data-testid="microsoft-btn"
            onClick={() => login('microsoft')}>
            {t('dialog.signin.providerMicrosoft')}
          </Button>
        </div>
        <Tlc />
      </div>
    </div>)
}
