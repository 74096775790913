import React from 'react';
// import GuestsList from './guests.list';
import { useTranslation, Trans } from 'react-i18next';
import {
  DeleteOutline, HelpOutline, Close, MoreVert as More,
} from '@material-ui/icons';
import {
  DonerBadge, CalendarCheck as CalendarToday, Envelope as Email, Link
} from "../../../../assets/icons"
import { LMTooltip } from '../../../tooltip/Tooltip';
import { DoneTextFieldRef as TextField } from '../inputs/Text';
import Popper from '@material-ui/core/Popper';
import NOOP from '../../../../utils/noop';
import usePagination from '../../../../hooks/use.pagination';
import { getContacts } from '../../../../reducers/contacts';
import GuestItem from './guest.item';
import * as ACTIONS from '../../../../actions';
import { useSnackbar } from 'notistack';
import useFlags from '../../../../hooks/use.flags'
import {
  MAIL
} from '../../../../validators/event';
import { MAIL_RX_SENTENCE } from "../../settings/invite/mailer";
import { useSelector, useDispatch } from 'react-redux';
import { getAccountMails, hasConnectedContactsSomeContacts } from '../../../../reducers/accounts';
import { Button, ClickAwayListener } from '@material-ui/core';
import AvatarWithStates from '../../../avatar.with.states';
import { arrayIncludes } from "../../../../utils/array.includes";
import { getContact } from '../../../../reducers/contacts';
import IconButton from '@material-ui/core/IconButton';
import { isUnknownDomain } from '../../../../api/validators/proposal';
import {
  SimpleAddWhatYouEnterItem, ConnectItem, encodeLabelToEmail
} from './guest.selector';
import {
  MARK_LOADING
} from '../../../consts'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import './fast.guest.selector.scss';
import './guest.selector.v3.scss';

export const UNKNOWN_EMAIL_DOMAIN = '@null-letsmeet.network'; //todo: put this in validatr
/**
 * Fast guest selector for 1st view
 * display invitees as Pills
 */
const EMPTY = {};
const EMPTY_ARR = [];
export default function FastGuestSelector({
  invitees = EMPTY_ARR,
  inviteesDetails = EMPTY,
  setInvitees,
  errorText,
  setcanSubmit,
  toggleOptional, toggleVoteRequested,
  doConnectContacts,
  oboCalendar,
  showPreview = NOOP, // function to show preview, may be undefined
  simpleContactSelector = false,
  disabled = false,
  placeholder,
  tooltip = 'createEvent.guests.tooltip',
}) {

  const { t } = useTranslation();
  const [focused, setFocused] = React.useState(false);
  const [pendingInvites, setPendingInvite] = React.useState([]); // buffer for invitees
  const contacts = useSelector(getContacts);
  const onAddContact = React.useCallback((c) => {
    if (!Array.isArray(c)) c = [c]
    // if not already present
    let nv = invitees;
    // filter emails already present
    c = c.filter((m) => !nv.find((i) => m.toLowerCase() === i.toLowerCase()))
    /* istanbul ignore else no work */
    // if (!invitees.find((i) => i === c)) {
    if (c.length > 0) {
      nv = [...invitees, ...c];

      setInvitees({
        target: {
          name: 'invitees',
          value: nv
        }
      });
    }
  }, [invitees]);
  const onAddUnknownContact = React.useCallback((c) => {
    let nv = invitees;
    if (!invitees.find((i) => i === c.email)) {
      nv = [...invitees, c.email];
      // Only update value for invitee
      setInvitees({
        target: {
          name: 'inviteesDetails["' + c.email + '"]',
          value: c,
          // value: {
          //   ...inviteesDetails,
          //   [c.email]: c
          // }
        }
      });
      setInvitees({
        target: {
          name: 'invitees',
          value: nv
        }
      });
    }



  }, [invitees, inviteesDetails])
  const onRemoveContact = React.useCallback((c) => {
    let nv = invitees;
    /* istanbul ignore else no work */
    if (invitees.find((i) => i === c)) {
      nv = invitees.filter((i) => i !== c);
      // only delete for form
      if (!simpleContactSelector) {
        delete inviteesDetails[c];
        setInvitees({
          target: {
            name: 'inviteesDetails',
            value: { ...inviteesDetails },
          }
        });
      }
      setInvitees({
        target: {
          name: 'invitees',
          value: nv,
        }
      });
    }
  }, [invitees, inviteesDetails, simpleContactSelector/*dispatch*/]);

  const hasVisibleDoners = true;
  // only use freshest data instead of a memo
  const startAdornment = (() => {
    if (invitees && invitees.length > 0) {
      return invitees.map((i) => {
        let details = inviteesDetails[i] || {};
        return (<Guest onClick={onRemoveContact} key={i}
          toggleOptional={toggleOptional} toggleVoteRequested={toggleVoteRequested} email={i} details={details}
          showPreviewEmail={showPreview}
        />);
      })
    } else return null;
  })();

  const doFocus = React.useCallback((e) => {
    setFocused(e);
  }, []);

  const hasNoInvitees = pendingInvites.length === 0 && startAdornment === null

  return <>
    <div className="guestsv3">
      {startAdornment}
      {pendingInvites.map((i) => {
        return <div className="guest-v3" key={"load-" + i}><EmailWithAvatar guestEmail={i} loading={true} /></div>;
      })}
    </div>
    {!disabled && <fieldset className={`done-input done-calendar-selector fast-selector fast-selector-v2 ${focused ? 'Mui-focused' : ""} ${errorText ? 'Mui-error' : ""} ${disabled ? 'disabled' : ''}`}>
      <legend className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined done-input-label  with-help-button">
        {t(hasNoInvitees ? 'createEvent.form.invitees' : 'createEvent.form.inviteesAdd')}
        <LMTooltip
          childrenClassName="showHand"
          content={
            <>
              <p><Trans i18nKey={tooltip}> <span className="strong">Name your invitees </span>.</Trans></p>
            </>}
        >
          <HelpOutline />
        </LMTooltip>
      </legend>
      <GuestsSelector setcanSubmit={setcanSubmit} onAddContact={onAddContact} doFocus={doFocus} oboCalendar={oboCalendar}
        doConnectContacts={doConnectContacts} onAddUnknownContact={onAddUnknownContact} simpleContactSelector={simpleContactSelector}
        pendingInvites={pendingInvites} setPendingInvite={setPendingInvite} placeholder={placeholder} />
    </fieldset>}
    {/*invitees && invitees.length > 0 && <GuestsList displayAsChips={true} invitees={invitees} onRemoveContact={onRemoveContact} />*/}

    {errorText &&
      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errorText}</p>}
  </>;
  // }

}

export function GuestsSelector({
  onAddContact = NOOP,
  onAddUnknownContact = NOOP,
  doFocus = NOOP,
  setcanSubmit = NOOP,
  doConnectContacts = NOOP,
  oboCalendar,
  simpleContactSelector = false, // do you ask for LM profile
  pendingInvites,
  setPendingInvite = NOOP,
  placeholder = 'createEvent.guests.list.search',
}) {
  let { t } = useTranslation();
  const dispatch = useDispatch();
  const accountsEmails = useSelector(getAccountMails);
  const hasConnectedContacts = useSelector(hasConnectedContactsSomeContacts);
  let [value, setValue] = React.useState('');
  let [toggleAddButton, settoggleAddButton] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isInvalidEmail, _setIsInvalidEmail] = React.useState(false);
  const setInvalidEmail = React.useCallback(() => {
    _setIsInvalidEmail(true);
    setTimeout(() => _setIsInvalidEmail(false), 3000)
  }, []);
  const addToPendingInvite = React.useCallback((i) => {
    if (!Array.isArray(i)) i = [i]
    setPendingInvite([...pendingInvites, ...i])
  }, [pendingInvites, setPendingInvite])
  const removeFromPendingInvite = React.useCallback((i) => {
    if (!Array.isArray(i)) i = [i]
    setPendingInvite(pendingInvites.filter((p) => i.find((mail) => mail === p)))
  }, [pendingInvites, setPendingInvite])
  const inputRef = React.useRef();
  const [anchorElWidth, setAnchorElWidth] = React.useState({ width: 400 });
  const [popperselected, setpopperSelected] = React.useState(0); // By default, first one is selected
  const scrollerRef = React.useRef(null);
  const {
    setFlagSetting,
    getFlagSetting,
    FLAGS,
  } = useFlags();
  const toggleContactPopup = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setFlagSetting(FLAGS.PROPOSE_CONTACT_POPUP, false);
  }, [setFlagSetting, FLAGS]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let cnvRef = React.useRef();

  const _onAddContact = React.useCallback((e, checkProfile = true, opts = {}) => {
    /* istanbul ignore if */
    if (e === '' || !e) return;
    e = e.toLowerCase();
    if (arrayIncludes(accountsEmails, e)) { // accountsEmails.includes(e.toLowerCase())) {
      enqueueSnackbar(t('createEvent.form.errors.cannotInviteYourself'), {
        variant: 'error',
        preventDuplicate: true,
        className: 'snack-error',
        action: /*istanbul ignore next */(key) => {
          return <>
            <Button onClick={() => { closeSnackbar(key) }}>
              {t('common.ok')}
            </Button>
          </>;
        }
      })
      return;
    }
    /* istanbul ignore if */
    if (arrayIncludes(pendingInvites, e)) {
      // nothing todo
      return;
    }
    if (!checkProfile) {
      // directly add to the list
      setcanSubmit(true)
      onAddUnknownContact({
        label: opts.name,
        name: opts.name,
        email: e,
        isUnknown: true,
      });
      return;
    }
    setcanSubmit(false)
    //onAddContact(e);
    // add to pending invites
    addToPendingInvite(e);
    // search for profile infos
    dispatch({
      type: ACTIONS.WORKER_GET_PROFILE,
      payload: e,
      opts: { getAccessRole: true }, // decorate contact with access role, used for "hold slots"
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROFILE_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROFILE_ERROR,
      }
    }).catch(() => { })
      .then(() => {
        setcanSubmit(true)
        removeFromPendingInvite(e);
        onAddContact(e);
      })
  }, [onAddContact, oboCalendar, pendingInvites, addToPendingInvite, removeFromPendingInvite]);
  // same, but more than once - from paste
  const _onAddContacts = React.useCallback((e = []) => {
    // remove My emails from list
    e = e.filter((m) => !arrayIncludes(accountsEmails, m) && !arrayIncludes(pendingInvites, m)) // accountsEmails.includes(m))
    // if already in pending, do not process

    // deduplication on copy/paste: if not deduplicate, problem when removing
    e = Object.keys(e.reduce((acc, i) => {
      acc[i.toLowerCase()] = 1;
      return acc
    }, {}))
    // MUST be emails, so always check profiles
    setcanSubmit(false)
    // onAddContact(e);
    addToPendingInvite(e);
    // search for profile infos
    dispatch({
      type: ACTIONS.WORKER_GET_PROFILES,
      payload: e,
      opts: { getAccessRole: true }, // decorate contact with access role, used for "hold slots"
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_PROFILES_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_PROFILES_ERROR,
      }
    }).catch(() => { })
      .then(() => {
        setcanSubmit(true)
        removeFromPendingInvite(e);
        onAddContact(e);
      })
  }, [onAddContact, oboCalendar, pendingInvites, addToPendingInvite, removeFromPendingInvite]);
  // datas
  // contacts objects to transform to sort array
  let contacts = useSelector(getContacts);
  let sortedContacts = React.useMemo(() => {
    return Object.values(contacts)
      .sort((a, b) => ((a.name || a.email) < (b.name || b.email) ? -1 : 1))
    //.map((k)=> contacts[k]);
  }, [contacts]);

  let pagination = usePagination(sortedContacts || [], 10);
  const [filteredContacts, setFilteredContacts] = React.useState([]);
  // reset propositions
  const doReset = React.useCallback((focus = true) => {
    // remove popper
    pagination.reset();
    setFilteredContacts([]);
    // clear value
    setValue('');
    settoggleAddButton(false);
    setpopperSelected(0);
    /* istanbul ignore if */
    if (focus && inputRef && inputRef.current) {
      inputRef.current.focus();
    }

  }, [pagination, setFilteredContacts, setValue, settoggleAddButton, inputRef])

  // change in name
  const handleChange = React.useCallback((e) => {
    let v = e.target.value;
    setValue(v);
    // filter contacts
    if (v) {
      let r = pagination.next(e.target.value);

      if (r.length === 0) {
        // check if v is a valid email, if so, add button
        settoggleAddButton(MAIL.isValidSync({ mail: v }));
      }

      setFilteredContacts(r);
    } else {
      pagination.reset()
      setFilteredContacts([]);
      settoggleAddButton(false)

    }
  }, [setValue, pagination, setFilteredContacts, settoggleAddButton]);
  // enter to validate?
  const onKeyDown = React.useCallback((e) => {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 188 || e.key === ';') {
      let v = value.trim();
      /* istanbul ignore if */
      if (v === '') return false;
      // e.preventDefault();
      if (filteredContacts.length > 0 && !e.ctrlKey) {
        if (popperselected > 0 && filteredContacts[popperselected - 1]) _onAddContact(filteredContacts[popperselected - 1].email)
        else if (MAIL.isValidSync({ mail: v.toLowerCase() })) _onAddContact(v);
        else {
          if (!simpleContactSelector) {
            // do not create fake mail on space
            if (e.keyCode === 32) return;
            // create a email from value with null domain
            let email = encodeLabelToEmail(v) + UNKNOWN_EMAIL_DOMAIN
            _onAddContact(email, false, { name: v });
          } else {
            // show error
            setInvalidEmail(true);
          }
        }

      }
      else if (MAIL.isValidSync({ mail: v })) _onAddContact(v);
      // if not a mail, add to contact list?
      // else return false;
      else {
        if (!simpleContactSelector) {
          // do not create fake mail on space
          if (e.keyCode === 32) return;
          // create a email from value with null domain
          let email = encodeLabelToEmail(v) + UNKNOWN_EMAIL_DOMAIN
          _onAddContact(email, false, { name: v });
        } else {
          // show error
          setInvalidEmail(true);
        }

      }
      // prevent key processing
      e.preventDefault();
      doReset();
      return false;
    } else /* istanbul ignore if */if (e.keyCode === 40) {
      let index = popperselected + 1;
      if (index >= pagination.current.length + 1) index = pagination.current.length - 1;
      setpopperSelected(index);
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = index * 52;
      }
      e.preventDefault();
      // ensure scroll to
    } else /* istanbul ignore if */if (e.keyCode === 38) {
      let index = popperselected - 1;
      if (index < 0) index = 0;
      setpopperSelected(index);
      // ensure scroll to
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop = index * 52;
      }
      e.preventDefault();
    }
  }, [value, _onAddContact, filteredContacts, doReset, inputRef, simpleContactSelector]);
  const onPaste = React.useCallback((event) => {
    event.preventDefault();
    let paste = (event.clipboardData || window.clipboardData || { getData: () => '' }).getData('text');
    paste.trim();
    /* istanbul ignore else no work */
    if (paste !== "") {
      // search for emails
      // let ps = paste.toLowerCase().split(/[ ,;\r\n]/g).filter(e => MAIL.isValidSync({ mail: e }))
      let ps = paste.toLowerCase().match(MAIL_RX_SENTENCE)
      /* istanbul ignore else no work */
      if (ps && ps.length > 0) {
        _onAddContacts(ps);
      }
    }
    doReset();
    return false;
  })
  /* istanbul ignore next no drop datatransfer event */
  const onDrop = React.useCallback((event) => {
    event.preventDefault();
    let paste = (event.dataTransfer || { getData: () => '' }).getData('text');
    paste.trim();
    /* istanbul ignore else no work */
    if (paste !== "") {
      // search for emails
      // let ps = paste.toLowerCase().split(/[ ,;\r\n]/g).filter(e => MAIL.isValidSync({ mail: e }))
      let ps = paste.toLowerCase().match(MAIL_RX_SENTENCE)
      /* istanbul ignore else no work */
      if (ps && ps.length > 0) {
        _onAddContacts(ps);
      }
    }
    doReset();
    return false;
  })
  const onFocus = React.useCallback((event) => {
    doFocus(true);
    setAnchorEl(event.target)
  }, [doFocus, anchorEl]);
  React.useLayoutEffect(() => {
    /* istanbul ignore else no work */
    if (anchorEl) {
      // calculate size of caller
      let rect = anchorEl.getBoundingClientRect() || { width: 400 };
      let w = rect.width;
      setAnchorElWidth({ width: w });
    }
  }, [anchorEl])
  // scroll to bottom on list of contacts
  /* istanbul ignore next cannot test */
  const onScroll = React.useCallback((e) => {
    // if bottom, load more
    const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 5);
    if (bottom && filteredContacts) {
      let tmp = pagination.next(value) || [];
      // remove possible emails doublons
      tmp = Object.values(tmp.reduce((acc, t) => {
        acc[t.email] = t;
        return acc;
      }, {}))
      setFilteredContacts(tmp);
    }
  }, [value, filteredContacts, setFilteredContacts, pagination]);

  // select a contact in list
  const doSelect = React.useCallback((c) => {
    /* istanbul ignore if */
    if (!c) c = value;
    _onAddContact(c);
    // remove popper
    doReset();
  }, [_onAddContact, value, doReset]);

  const [valueSize, setValueSize] = React.useState(null);
  React.useEffect(() => {
    if (cnvRef.current && value) {
      cnvRef.current.font = 'Montserrat 16px'
      // return cnvRef.current.getContext('2d').measureText(value).width
      setValueSize(cnvRef.current.getBoundingClientRect().width);
    } else setValueSize(0);
  }, [valueSize, setValueSize, value])
  /* istanbul ignore next no need */
  const dismissPopup = React.useCallback((e) => {
    let src = e.srcElement;
    if (src && src === anchorEl) return;
    setAnchorEl(null);
    // if there is some text in, process
    if (value) {
      let v = value.trim();
      if (v === "") return;
      if (MAIL.isValidSync({ mail: v.toLowerCase() })) {
        _onAddContact(v);
        doReset(false);
      } else {
        if (!simpleContactSelector) {
          // create a email from value with null domain
          let email = encodeLabelToEmail(v) + UNKNOWN_EMAIL_DOMAIN
          _onAddContact(email, false, { name: v });
        } else {
          // show error
          setInvalidEmail(true);
        }
        doReset(false);
      }
      return;
    }
  }, [anchorEl, value, setInvalidEmail, _onAddContact, doReset])
  // note: remove onBlur={onBlur} on textfield (to see later)
  return (
    <div className="guest-selector" data-testid="guest-selector">
      <span ref={cnvRef} className="tiny-measures">{value}</span>

      <div className="text-search">
        <LMTooltip
          open={isInvalidEmail}
          content={t('createEvent.form.errors.mustEnterAnEmail')}
          className='flex-grow error-tooltip'
          placement='bottom-start'
        >
          <TextField
            name="search-contact"
            value={value}
            onChange={handleChange}
            autoComplete="off"
            placeholder={t(placeholder)}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onPaste={onPaste}
            onDrag={onDrop}
            isTouched={toggleAddButton ? true : undefined}
            isValid={toggleAddButton ? true : undefined}
            ref={inputRef}
            // isRequired={!simpleContactSelector}
            type='search'
          />
        </LMTooltip>
        {/*
          toggleAddButton && <><IconButton data-testid="addemail-btn"
            style={{ left: 11 + valueSize + 10 }}
            onClick={() => doSelect()} variant="contained"><PersonAdd /></IconButton></>
        */}
      </div>
      {!!anchorEl && value !== "" && hasConnectedContacts &&
        <ClickAwayListener onClickAway={dismissPopup}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart">
          <Popper className="popper" open={!!anchorEl} anchorEl={anchorEl}
            placement="bottom" >
            <div className="selector-options guest-selector fancy-scroll" style={{ width: anchorEl.getBoundingClientRect().width }}
              onScroll={onScroll}
              ref={scrollerRef}
              data-testid="guest-selector-popper"
            >
              {/* add a "simply add what you enter" item */}
              <SimpleAddWhatYouEnterItem value={value} classes={(0 === popperselected) ? 'selected' : ''} onClick={(e) => {
                // Fix: check if email entered
                let v = value.trim();
                if (v === '') return;
                if (MAIL.isValidSync({ mail: v.toLowerCase() })) _onAddContact(v);
                else {
                  let email = encodeLabelToEmail(value) + UNKNOWN_EMAIL_DOMAIN
                  _onAddContact(email, false, { name: value });
                }
                doReset(true)
              }}></SimpleAddWhatYouEnterItem>
              {filteredContacts.map((c, i) => <div key={c.email}>
                <GuestItem classes={((i + 1) === popperselected) ? 'selected' : ''} onClick={(e) => {
                  doSelect(c.email)
                }} contact={c} searchString={value} />
              </div>)}
            </div>
          </Popper ></ClickAwayListener>}
      {!!anchorEl &&
        !hasConnectedContacts && ((value && value.trim() !== '') || getFlagSetting(FLAGS.PROPOSE_CONTACT_POPUP)) && <ClickAwayListener onClickAway={dismissPopup}>
          <Popper className="popper" open={!!anchorEl} anchorEl={anchorEl}
            placement="bottom" >
            <div className="selector-options guest-selector fancy-scroll" style={{ width: anchorEl.getBoundingClientRect().width }}
              data-testid="guest-selector-popper-no-contacts"
            >
              <SimpleAddWhatYouEnterItem value={value} classes={'selected'} onClick={(e) => {
                let v = value.trim();
                if (v === '') return;
                if (MAIL.isValidSync({ mail: v.toLowerCase() })) _onAddContact(v);
                else {
                  let email = encodeLabelToEmail(value) + UNKNOWN_EMAIL_DOMAIN
                  _onAddContact(email, false, { name: value });
                }
                doReset(false)
              }}></SimpleAddWhatYouEnterItem>
              {getFlagSetting(FLAGS.PROPOSE_CONTACT_POPUP) && <div key={"contact-infos"} onClick={doConnectContacts}>
                <ConnectItem />
                <div className="close" onClick={toggleContactPopup}><div>{t('common.doNotShowAgain')}</div></div>
              </div>}
            </div>
          </Popper ></ClickAwayListener>}
    </div >
  )
}

// Guest tab line
function Guest({
  details,
  email,
  onClick,
  toggleOptional,
  toggleVoteRequested,
  showPreviewEmail
}) {
  const { t } = useTranslation();
  let contact = useSelector(getContact(email));
  /* istanbul ignore if */
  if (!contact || !contact.email || !details) {
    // return loading cmp
    return <div className="tab-line">{email + " loading...."}</div>
  }
  let guest = {
    ...contact,
    ...details,
  }
  // compute different values
  const isUnknown = isUnknownDomain(guest.email);
  const isDoner = guest.isDoner || false; // is LM USer or Hybrid
  // const name = guest.name || guest.label || guest.email;
  const guestEmail = !isUnknown && guest.email;
  const guestName = (isDoner || isUnknown) ? (guest.name || guest.label) : undefined;
  const isVisible = (isDoner && !guest.isForbidden); // can access it's WH?
  const isOptional = guest.optional || false;// will be asked to vote?
  let isVoteRequired = true;
  let canRequestVote = true;
  if (isOptional) {
    //canRequestVote = false;
    isVoteRequired = guest.vote_requested || false; // we lie a little here
    canRequestVote = isVisible && !guest.isForced;
  }
  else if (!isDoner || !isVisible) {
    canRequestVote = false;
    isVoteRequired = true;
  } else {
    canRequestVote = !guest.isForced;
    isVoteRequired = guest.isForced || guest.vote_requested || false;
  }

  // default status, we send a mail
  let status = <LMTooltip
    interactive={true}
    className="thumbnail"
    childrenClassName="showHand"
    content={showPreviewEmail && showPreviewEmail({ className: "thumb-preview fancy-scroll", doOpen: showPreviewEmail })}
  ><Email /></LMTooltip>;

  // we send a link
  if (isUnknown) status = <LMTooltip
    childrenClassName="showHand"
    content={t("createEvent.form.guests.interactions.sendLink")}
  ><Link /></LMTooltip>;
  else if (isDoner && canRequestVote && !isVoteRequired) status = <LMTooltip
    childrenClassName="showHand"
    content={t("createEvent.form.guests.interactions.useFBStatus")}
  ><CalendarToday /></LMTooltip>;


  return <div className="guest-v3" key={email} data-testid={"guest-" + email}>
    <div className="email"><EmailWithAvatar guestName={guestName} guestEmail={guestEmail} avatar_url={guest.avatar_url} isDoner={isDoner && isVisible} isOptional={isOptional} /></div>
    <div className="actions">
      {status}
      <div className="separator">&nbsp;</div>
      <IconButton className="with-border" data-testid={"toggle-delete"} onClick={() => onClick(email)} size='small' ><Close /></IconButton>
      <AttendeeMenu email={email} isOptional={isOptional}
        isDoner={isDoner}
        isVoteRequired={guest.vote_requested}
        canRequestVote={canRequestVote}
        toggleOptional={toggleOptional} toggleVoteRequested={toggleVoteRequested} />
    </div>
  </div>;
}
// Tab components...
export function EmailWithAvatar({ guestName, guestEmail, avatar_url = '', loading = false,
  isDoner = false, isOptional = false, fallBackColor = "none" }) {
  const { t } = useTranslation()
  return <div className={"email-avatar " + (isOptional ? 'optional' : '')}>
    <AvatarWithStates imageSrc={avatar_url} name={guestName || guestEmail} fallBackColor={fallBackColor} size={2} mark={loading ? MARK_LOADING : undefined} />
    <div className='info'>
      {guestName &&
        <span className='name'>
          {guestName} {guestEmail && guestEmail !== guestName && <> — {guestEmail}</>}
          {isDoner && <DonerBadge />}
        </span>}
      {!guestName && guestEmail !== guestName &&
        <span>{guestEmail}</span>}
      {isOptional ? <span className="optional-mark">{t('common.optional')}</span> : ''}
    </div>
  </div>
}

export function AttendeeMenu({ email,
  isDoner, isOptional, canRequestVote, isVoteRequired,
  toggleOptional,
  toggleVoteRequested }) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorMenuEl] = React.useState(null);
  const toggleMenu = React.useCallback((event) => {
    setAnchorMenuEl(event.currentTarget);
  }, []);
  const handleClose = React.useCallback((cllbck) => {
    setAnchorMenuEl(null);
    if (cllbck) cllbck()
  }, []);

  const options = [{
    id: "toggle-optional",
    handleMenuItemClick: () => toggleOptional(email),
    label: <div className="form-menu"><span>{t(isOptional ? "createEvent.form.guests.interactions.mandatory" : "createEvent.form.guests.interactions.optional")}</span><LMTooltip
      childrenClassName="showHand"
      content={t("createEvent.form.guests.interactions.optionalHelp")}
    ><HelpOutline /></LMTooltip></div>
  }];

  if (isDoner && canRequestVote) {
    //depending on current state
    options.push({
      id: "toggle-vote-requested",
      handleMenuItemClick: () => toggleVoteRequested(email),
      label: <div className="form-menu"><span>{t(isVoteRequired ? "createEvent.form.guests.interactions.useFB" : "createEvent.form.guests.interactions.notUseFB")}</span><LMTooltip
        childrenClassName="showHand"
        content={t("createEvent.form.guests.interactions.useFBHelp")}
      ><HelpOutline /></LMTooltip></div>
    })
  }
  return <><IconButton className="with-border" data-testid={"toggle-menu-" + email} onClick={toggleMenu} size='small' ><More /></IconButton><Menu
    id="lock-menu"
    anchorEl={anchorEl}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={Boolean(anchorEl)}
    onClose={() => handleClose()}
  >
    {options.map((option, index) => (
      <MenuItem
        key={option.id}
        data-testid={option.id}
        onClick={(event) => handleClose(() => option.handleMenuItemClick(event, index))}
      >
        {option.label}
      </MenuItem>
    ))}
  </Menu></>
}