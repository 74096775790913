import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import ROUTES from '../routes/routes.name'
import * as ACTIONS from '../actions'
import { useHistory } from 'react-router-dom';
import { GmailIcon, OutlookIcon, UsedByAdobe, UsedByCitrix, UsedBySalesforce, UsedByTwilio, UsedByZendesk } from '../assets/icons';
import useTheme from '../hooks/use.theme';
import {
  SuccessIllusBouchon
} from '../assets/icons'
import DoneButton from './DoneButton';
import './promote.scss';
import { KeyboardArrowRight } from '@material-ui/icons';
export default function Promote({ dismiss, step = 0 }) {
  return (step === 0) ? <Welcome dismiss={dismiss} /> : <PromoteExtensions dismiss={dismiss} />
}

export function Welcome({ dismiss, setStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const planLater = React.useCallback(() => {
    dispatch({
      type: ACTIONS.ANALYTICS_PLAN_LATER
    })
    dismiss();
    history.push(ROUTES.APP_PROFILE);
  }, [dismiss, dispatch, history])

  const toMeetingForm = React.useCallback(() => {
    dispatch({
      type: ACTIONS.ANALYTICS_PLAN_NOW
    })
    dismiss();
  }, [dismiss, dispatch])

  return (
    <div className={theme + " promote-extension fancy-scroll"} data-testid="promote-ext-welcome">
      <div className="content">
        <div className="promote welcome">
          <div className="welcome-title">
            <SuccessIllusBouchon className='slide-in-bottom' />
            <div className="title">{t('promote.welcomePanel.title')}</div>
          </div>
          <div className="welcome-actions">
            <DoneButton
              name="promote-planmeeting"
              onClick={toMeetingForm}
              label={<>{t('promote.welcomePanel.planMeeting')}<KeyboardArrowRight /></>} />
            <DoneButton
              name="promote-skip"
              onClick={planLater}
              className="grey"
              label={<>{t('promote.welcomePanel.planLater')}</>} />
          </div>
          <div className="welcome-infos">
            <div className="title">{t('promote.welcomePanel.users')}</div>
            <div className="users">
              <UsedByAdobe />
              <UsedByCitrix />
              <UsedBySalesforce />
              <UsedByTwilio />
              <UsedByZendesk />
            </div>
          </div>
        </div>
      </div>
    </div>);
}

export function PromoteExtensions({ dismiss }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const doDismiss = React.useCallback(() => {
    dismiss();
  }, [dismiss])
  return (
    <div className={theme + " promote-extension fancy-scroll"} data-testid="promote-ext">
      <div className="content">
        <div className="title">{t('promote.welcome', { name: user.name })}</div>
        <div className="message">{t('promote.message')}</div>
        <div className="promote">
          <div className="bigtitle">{t('promote.platforms.title')}</div>
          <div className="section">
            <div className="title">{t('promote.platforms.mail.title')}</div>
            <div className="ctas">
              <a
                className="cta-install"
                href={t('promote.platforms.mail.gmailUrl')}
                target="_blank"
                rel="noopener noreferrer">
                <GmailIcon /><span>{t('promote.platforms.mail.gmail')}</span>
              </a>
              <a
                className="cta-install"
                href={t('settings.sections.tools.items.extensions.outlook.link')}
                target="_blank"
                rel="noopener noreferrer">
                <OutlookIcon /><span>{t('promote.platforms.mail.outlook')}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="skip">
          <button
            data-testid="promote-skip"
            onClick={doDismiss}>{t('promote.platforms.skip.cta')}<KeyboardArrowRight /><KeyboardArrowRight /></button>
        </div>
      </div>
    </div >
  );
}