import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import routes from '../../../../../routes/routes.name';

// display a link to calendars settings
export function NoAccountForUser() {
  const { t } = useTranslation();
  return (
    <div className="no-account-user">
      <Link to={routes.APP_SETTINGS_ACCOUNTS}><Button variant="contained" className="white-btn">{t('createEvent.form.agenda.noCalendarUserAction')}</Button></Link>
      <div className="no-account-text">{t('createEvent.form.agenda.noCalendarUser')}</div>
    </div>
  )
}