import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isOneOfUserAccounts, getCalendarForEdit } from '../../../reducers/accounts'
import { LINK, MAX_NOTICE_DURATION } from '../../../validators/link';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { LinearProgress, TextField } from '@material-ui/core';
import NOOP from '../../../utils/noop';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../../reducers/profile';
import * as ACTIONS from '../../../actions';
import ROUTES from '../../../routes/routes.name';
import { getUser } from '../../../reducers/user';
import { getDefaultCalendar } from '../../../reducers/accounts';
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';
import debounce from '../../../utils/debounce'
import useOnBehalfOf from '../../../hooks/use.onBehalfOf'
import { PREFILL_EDIT } from '../../../reducers/proposals'
import {
  HelpOutline,
  DeleteOutline as Delete,
  Add
} from '@material-ui/icons';
import { Copy } from '../../../assets/icons';
import {
  EVENT_DURATION_MAX,
  EVENT_DURATION_MIN,
} from '../../../validators/event';
import {
  ChevronRight
} from '@material-ui/icons';
import CTA from './periods/cta.js';
import { LMTooltip } from '../../tooltip/Tooltip';
import DoneTextField from './inputs/Text';
import CalendarSelector from './inputs/calendars/CalendarSelector';
import CalendarSelectorRenderer202212 from './inputs/calendars/CalendarSelectorRenderer202212';
import ConnectYourCalendar202212 from './inputs/calendars/ConnectYourCalendar202212';
import DurationSelector from './inputs/DurationSelector';
import LocationSelector from './inputs/location/location.v2';
import {
  EVENT_TITLE_MAX,
} from '../../../validators/event';
import { getDefaultConferenceTool } from './SimpleForm';
import { saveInCache, clearCache, loadLinkFromCache, LS_LINK_KEY } from '../../../utils/load.proposal.from.cache';
import DayAvailability, { DayFrame } from '../settings/calendars/day.availability';
import { DEFAULT_START_HOUR, DEFAULT_END_HOUR, CONFIG } from '../../../utils/gen.timeframes';
import {
  FormControl, FormControlLabel, RadioGroup, Radio
} from '@material-ui/core'
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TZPicker from '../settings/calendars/timezone.picker';
import { useDialog } from '../../dialogs/dialog.provider';
import { BillingDlg } from './BillingDlg';
import { tz_exists } from '../../../utils/timezones.list'
import './inputs/inputs.scss'
import './simpleform.scss';
import './booking.link.scss';
import { DateRange } from 'react-date-range';
import Popover from '@material-ui/core/Popover';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


const DAY = 24 * 60; // in mins
// tmp, need server-side validation
export function username_as_slug(user) {
  if (!user) return "";
  let sl = user.slug // TODO definie name
  if (!sl) {
    sl = (user.name || user.label || user.email || 'letsmeet-user').normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .toLowerCase().replace(/[^a-z0-9_-]/g, '-')
  }
  return sl;
}
export default function BookingLinkForm({
  bookinglink, // (Optional) form can init from a proposal
  prefillType, // (Optional) type of form prefill (reschedule, follow up, edit...),
  debounce_delay = 300
}) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = React.useState(false);
  const [isGeneratingLink, setisGeneratingLink] = React.useState(false);
  const [isSlugInUse, setisSlugInUse] = React.useState(false);
  const snackbar = useSnackbar();
  const { enqueueDialog, closeDialog } = useDialog();
  const history = useHistory();
  const dispatch = useDispatch();

  // form datas
  const user = useSelector(getUser);
  const accounts = useSelector((state) => state.accounts);
  const profile = useSelector(getProfile);
  const defaultCalendar = useSelector(getDefaultCalendar);

  // initialize the form data
  const init = React.useMemo(() => {
    let tmp = bookinglink;
    let wh = (profile && profile.enabled) ? profile.workingHours : CONFIG;
    if (!tmp) {
      // return default empty form data
      let cache = loadLinkFromCache();
      if (cache) {
        if (isOneOfUserAccounts(accounts, cache.calendar)) {
          if (cache.calendar) cache.calendar = getCalendarForEdit(accounts, cache.calendar)
          return cache;
        }
      }
      // return default config
      let tz = (defaultCalendar || {}).timezone;
      if (!tz_exists(tz)) tz = moment.tz.guess();
      return {
        title: '',
        slug: "",
        location: '',
        conference: '',
        duration: 60,
        customduration: 10,
        notes: '',
        calendar: defaultCalendar,
        timezone: tz,
        organizer: user,
        is_active: true,
        validity: {
          start: moment(),
          duration: -1,
        },
        configuration: {
          start_inc: 30,
          notice_value: 0,
          notice_duration: 0,
          notice_multiplier: 1, // minutes by default
          before_duration: 0,
          after_duration: 0,
        },
        business_hours: wh
      };
    }

    // always reset validity in edit mode?
    // form is in edit mode
    if (prefillType === PREFILL_EDIT) {
      tmp.prefill_type = PREFILL_EDIT; // save it for later and reload
      tmp.__originalbookinglink = Object.assign({}, bookinglink); // used to compute the diff when needed (recap)
      // calendar
      tmp.calendar = getCalendarForEdit(accounts, tmp.calendar)
      tmp.organizer = user;
      // tmp.validity = {
      //   duration: -1,
      //   start: moment(),
      // }
      return tmp;
    }
    tmp.organizer = user;
    tmp.calendar = defaultCalendar;

    return tmp;
  }, [bookinglink, prefillType, profile]);
  // check if edit mode
  const isEditing = React.useMemo(() => {
    return init.prefill_type === PREFILL_EDIT;
  }, [init]);
  const usernameslug = React.useMemo(() => {
    return profile.link_name
  }, [profile])
  const formik = useFormik({
    initialValues: init,
    validationSchema: LINK,
    onSubmit: (values) => {
      /* istanbul ignore if no work */
      if (isGeneratingLink || isSlugInUse) {
        return;
      }

      setisGeneratingLink(true)
      // values.configuration.notice_duration = values.configuration.notice_value * values.configuration.notice_multiplier;
      // perform save/edit link
      dispatch({
        type: prefillType === PREFILL_EDIT ? ACTIONS.WORKER_UPDATE_LINK : ACTIONS.WORKER_CREATE_LINK,
        payload: {
          id: init.id,
          slug: values.slug,
          is_active: values.is_active,
          title: values.title,
          location: values.location,
          conference: values.conference,
          duration: values.duration,
          customduration: +values.customduration,
          notes: values.notes,
          calendar: values.calendar,
          timezone: values.timezone,
          organizer: values.organizer,
          validity: {
            ...values.validity,
            start: moment(values.validity.start).format(),
          },
          configuration: values.configuration,
          business_hours: values.business_hours,
        },
        resolvers: {
          resolveOn: [ACTIONS.WORKER_CREATE_LINK_SUCCESS, ACTIONS.WORKER_UPDATE_LINK_SUCCESS],
          rejectOn: [ACTIONS.WORKER_CREATE_LINK_ERROR, ACTIONS.WORKER_UPDATE_LINK_ERROR]
        }
      }).then((dt = {}) => {
        clearCache(LS_LINK_KEY);
        // dispatch({
        //   type: (creatingEvent ? ACTIONS.ANALYTICS_MEETING_CREATED : prefillType === PREFILL_EDIT ? ACTIONS.ANALYTICS_PROPOSAL_UPDATED : ACTIONS.ANALYTICS_PROPOSAL_CREATED),
        //   payload: {
        //     proposal: values,
        //   }
        // });
        // redirect to meeting page
        let payload = dt.payload || {};
        payload.url = "/" + usernameslug + "/" + payload.slug;
        let route = ROUTES.CREATE_A_LINK_RESULT;
        history.push(route, { bookinglink: payload, type: prefillType });

      })
        .catch(
          /* istanbul ignore next no notistack */
          (err) => {
            // show an error popup
            if (err && err.payload && err.payload.status === 402) {
              const unblock = history.block();
              const doClose = () => {
                unblock();
                closeDialog();
              };
              // need billing for this, show dialog
              enqueueDialog({
                content: <BillingDlg version='link' />,
                doClose: doClose,
                overrideDefaultProps: {}
              });
              return;
            }
            // show an error popup
            if (snackbar) snackbar.enqueueSnackbar(t('schedulingLink.form.errors.sendError'), { variant: 'error' })
          }).then(() => setisGeneratingLink(false));
    }
  });

  const oboCalendar = useOnBehalfOf(formik.values.calendar);
  const __hndSlugChange = React.useMemo(() => debounce((e) => {
    let sl = e[0]
    if (sl && sl.length >= 3 && sl.length < 255) {
      dispatch({
        type: ACTIONS.WORKER_VALIDATE_SLUG,
        payload: {
          slug: sl,
          id: formik.values.id // undefined if not known
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_VALIDATE_SLUG_SUCCESS,
          rejectOn: ACTIONS.WORKER_VALIDATE_SLUG_ERROR
        }
      }).then(() => {
        // valide, save
        setisSlugInUse(false)
      }).catch(err => {
        console.log("")
        // invalid, show error
        setisSlugInUse("schedulingLink.form.errors.slugAlreadyInUse")
      }).finally(() => {
        setisGeneratingLink(false)
      })
    } else {
      setisSlugInUse(false)
      setisGeneratingLink(false);
    }

  }, { wait: debounce_delay, maxWait: 1000, maxCall: 100 }), [formik.handleChange])
  const hndSlugChange = React.useCallback((e) => {
    let chaboured_slug = e.target.value || ''
    if (chaboured_slug != "") {
      setisGeneratingLink(true)
      setisSlugInUse(false)
      chaboured_slug = username_as_slug({ label: chaboured_slug })
      __hndSlugChange(chaboured_slug);
    }

    formik.setFieldValue("slug", chaboured_slug, true);
  }, [__hndSlugChange]);

  // check slug if set
  React.useEffect(() => {
    if (init.slug) {
      hndSlugChange({
        target: {
          name: "slug",
          value: init.slug
        }
      })
    }
  }, [])
  // load user profile and update formik
  React.useEffect(() => {
    if (!user.isAuth) return;
    setLoading(true);
    dispatch({
      type: ACTIONS.WORKER_REFRESH_PROFILE,
      resolvers: {
        resolveOn: ACTIONS.WORKER_REFRESH_PROFILE_SUCCESS,
        rejectOn: ACTIONS.WORKER_REFRESH_PROFILE_ERROR,
      }
    })
      .then((dt) => {
        // if init got some datas, we should not generate new timeframes
        // and check for the tools we got (if any)
        // to allow for any type of meeting, comment !prefillType....
        if (dt && dt.payload && !init.conference && !prefillType) {
          let profile = dt.payload;
          const conference = getDefaultConferenceTool(init.calendar, profile.tools)
          formik.setFieldValue('conference', conference)
        }
      })
      .catch((err) => {
        console.log(err)
      }).then(() => {
        setLoading(false);
      })
  }, [/*user.isAuth*/]);

  // user or calendars change, refresh
  React.useEffect(() => {
    // clear all errors?
    /* istanbul ignore if no user is not mandatory */
    if (user) formik.setFieldValue('organizer', user, false);
    if (defaultCalendar && !formik.values.calendar) formik.setFieldValue('calendar', defaultCalendar, false);
  }, [user, defaultCalendar]);
  // each time form change, save in cache
  React.useEffect(() => {
    // do not save in cache if edit mode
    if (prefillType !== PREFILL_EDIT) saveInCache(formik.values, LS_LINK_KEY);
  }, [formik.values])
  // if got an error, scroll to it
  React.useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      let name = Object.keys(formik.errors)[0];
      let input = document.getElementsByName(name)[0];
      if (input && input.scrollIntoView) {
        // focus on first error?
        input.focus();
        input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }

    } else {
      if (isSlugInUse) {

        let input = document.getElementsByName("slug")[0];
        if (input && input.scrollIntoView) {
          // focus on first error?
          input.focus();
          input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }
      }
    }
  }, [formik.isSubmitting, formik.errors]);

  // on customduration/duration change, we reset proposals (dumb, but we will try harder next time)
  const handleCalendarChange = React.useCallback((e) => {
    // if got proposals, change timezone if needed
    formik.handleChange(e);
    // check if valid too
    let tz = e.target.value.timezone;
    if (!tz_exists(tz)) tz = moment.tz.guess();
    formik.setFieldValue("timezone", tz, false)
    // check if got some meeting?
    // if choose a provider-depend one
    if (formik.values.conference != "zoom" && formik.values.conference != "") {
      const conference = getDefaultConferenceTool(e.target.value, profile.tools);
      if (conference) {
        formik.setFieldValue("conference", conference, false)
      }
    }
  }, [formik.handleChange, formik.values.conference, profile.tools]);

  const cancelEditing = () => {
    history.go(0);
  };
  if (isLoading) return (
    <LinearProgress />
  );

  return (
    <LinkFormRenderer isEditing={isEditing} cancelEditing={cancelEditing}
      formik={formik}
      profile={profile}
      oboCalendar={oboCalendar}
      isGeneratingLink={isGeneratingLink}
      handleCalendarChange={handleCalendarChange}
      hndChange={formik.handleChange}
      hndSlugChange={hndSlugChange}
      handleFormSubmit={formik.handleSubmit}
      user={user}
      usernameslug={usernameslug}
      isSlugInUse={isSlugInUse} />);
};

function LinkFormRenderer({
  isEditing = false,
  formik,
  profile, // host profile
  usernameslug,
  isGeneratingLink = false,
  // cllbacks
  handleCalendarChange = NOOP,
  hndChange = NOOP, // general change handler
  hndSlugChange = NOOP,
  handleFormSubmit = NOOP,
  isSlugInUse, user,
}) {
  const { t } = useTranslation();
  const isAuth = user && user.isAuth;
  const hndSlugBlur = React.useCallback(() => {
    formik.setFieldTouched("slug", true, true)
  }, [formik.setFieldTouched]);
  const setSlugValue = React.useCallback((v) => {
    if (formik.values.title === '') return;
    if (formik.values.slug !== '') return;
    let sl = username_as_slug({ label: formik.values.title });
    formik.setFieldValue("slug", sl, true)
    hndSlugChange({ target: { value: sl } })
    // check validity
  }, [formik.values.title, formik.values.slug, hndSlugChange]);
  // console.log(formik.errors)
  return <form autoComplete="off"
    data-testid="booking-form"
    className={'create-event-form-container Version202212 bookinglink ' + (isAuth ? '' : 'unactive')}>
    <div className='title'>{t(isEditing ? 'schedulingLink.form.pageEditTitle' : 'schedulingLink.form.pageTitle')}</div>
    <div className={"create-event-form "} data-testid="mainform">

      <div className='form-header'>
        <CalendarSelector
          name='calendar'
          profile={profile}
          isAuth={isAuth}
          value={formik.values.calendar}
          onChange={handleCalendarChange}
          disabled={isEditing}
          Renderer={CalendarSelectorRenderer202212}
          ConnectYourCalendarRenderer={ConnectYourCalendar202212}
          labelTitle={"schedulingLink.form.calendarLabel"}
          labelHelp={"schedulingLink.form.calendarHelp"}
          showTimezone={false}
        />
      </div>
      <div className="form-content">
        <div className="form-item-titles">{t('createEvent.form.titles.what')}</div>
        <DoneTextField
          label={
            <>{t('schedulingLink.form.nameLabel')}
              <LMTooltip
                childrenClassName="showHand"
                content={
                  <>
                    <p><Trans i18nKey="schedulingLink.form.nameHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
                  </>
                }
              >
                <HelpOutline />
              </LMTooltip></>}
          value={formik.values.title}
          className="with-help"
          InputLabelProps={{ className: "with-help-button" }}
          name='title'
          onChange={hndChange}
          onBlur={setSlugValue}
          errorText={formik.errors.title && t(formik.errors.title, { max: EVENT_TITLE_MAX })}
          isValid={(formik.values.title || !formik.touched.title)}
        />
        <DoneTextField label={
          <>{t('schedulingLink.form.descriptionLabel')}
            <LMTooltip
              childrenClassName="showHand"
              content={
                <>
                  <p><Trans i18nKey="schedulingLink.form.descriptionLabelHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
                </>
              }
            >
              <HelpOutline />
            </LMTooltip></>
        }
          className="with-help"
          value={formik.values.notes}
          name='notes'
          onChange={hndChange}
          multiline={true}
          rows={2}
          rowsMax={15}
          rowsMin={1}
        />
        <DoneTextField
          label={
            <>{t('schedulingLink.form.linkLabel')}
              <LMTooltip
                childrenClassName="showHand"
                content={
                  <>
                    <p><Trans i18nKey="schedulingLink.form.linkHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
                  </>
                }
              >
                <HelpOutline />
              </LMTooltip></>}
          value={formik.values.slug}
          className="with-help no-bottom slug-link"
          InputLabelProps={{ className: "with-help-button" }}
          name='slug'
          onChange={hndSlugChange}
          onBlur={hndSlugBlur}
          errorText={(isSlugInUse || formik.errors.slug) && t(isSlugInUse || formik.errors.slug)}
          startAdornment={`letsmeet.network/${usernameslug || ''}/`}
          endAdornment={isGeneratingLink ? <CircularProgress /> : null}
          isValid={!isSlugInUse && (formik.errors.slug === undefined || !formik.touched.slug)}
        />
        <div className="form-item-titles">{t('createEvent.form.titles.where')}</div>
        <LocationSelector
          value={formik.values.location}
          conference={formik.values.conference}
          profile={profile}
          calendar={formik.values.calendar}
          onChange={formik.handleChange}
          error={formik.errors.location}
        />
        <div className="form-item-titles">
          {t('createEvent.form.titles.when')}
        </div>
        <SchedulingPeriod value={formik.values.validity} handleChange={hndChange} />
        <div className="link-cmp">
          <div className="title"><span>{t('schedulingLink.form.duration')}</span>
            <LMTooltip
              childrenClassName="showHand"
              content={
                <>
                  <p><Trans i18nKey="schedulingLink.form.durationHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
                </>
              }
            >
              <HelpOutline />
            </LMTooltip>
          </div>
          <DurationSelector
            name="duration"
            labels={false}
            value={formik.values.duration}
            customValue={formik.values.customduration}
            onChange={hndChange}
            isValid={!formik.errors.customduration}
            errorText={t(formik.errors.customduration, { min: EVENT_DURATION_MIN, max: EVENT_DURATION_MAX })}

          />
        </div>
        <BusinessHours value={formik.values.business_hours} handleChange={hndChange} error={formik.errors.business_hours}
          tz={formik.values.timezone} />
        <ConfigLink value={formik.values.configuration} handleChange={hndChange} errors={formik.errors.configuration} />
        <div className="period-v2" data-testid="edit-funnel-started">
          <div className="period-cta">
            <CTA label={isEditing ? "schedulingLink.form.editButton" : "schedulingLink.form.submitButton"}
              name="valid-changes"
              handleSubmit={handleFormSubmit}
              canSubmit={!isGeneratingLink}
              isGeneratingLink={false}
              endIcon={<ChevronRight />} />
          </div >
        </div>
      </div>
    </div>
  </form >
}

export function BusinessHours({ value, tz, handleChange, error }) {
  const { t } = useTranslation();
  const DAYS_OF_WEEK = React.useMemo(() => {
    // get locale dow
    let DAYS = ['1', '2', '3', '4', '5', '6']; // iso day indexes without sunday
    let sow = moment().startOf("week");
    // pass sunday at end of week
    // Or at first day depending on locale
    if (sow.day() === 1) DAYS.push('7');
    else DAYS.unshift('7'); // sunday is first day

    return DAYS;
  }, []);
  const copyAll = React.useCallback((day) => {
    // copy frame to all
    let wh = {};
    for (let d of Object.keys(value)) {
      wh[d] = day;
    }
    handleChange({
      target: {
        name: 'business_hours',
        value: wh
      }
    });
  }, [value, handleChange]);
  return <div className='done-input link-cmp'>
    <div className="title"><span>{t('schedulingLink.form.openingHours')}</span>
      <LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p><Trans i18nKey="schedulingLink.form.openingHoursHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
          </>
        }
      >
        <HelpOutline />
      </LMTooltip>
    </div>
    <TZPicker auto={false} value={tz} canBeAuto={false} onChange={handleChange} className="inline"
      inputPropsParams={{ variant: "outlined" }} label={<div className="title inline">{t("schedulingLink.form.timezone")}<LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p><Trans i18nKey="schedulingLink.form.timezoneHelp">This is the timezone ;)</Trans></p>
          </>
        }
      >
        <HelpOutline />
      </LMTooltip></div>} />
    <div className="day-table">
      {DAYS_OF_WEEK.map((d) => {
        return <DayAvailability key={'day-' + d} day={d} values={value[d] || { frames: [] }} onChange={handleChange} onCopyAll={copyAll} name="business_hours"
          Renderer={LinkDayRenderer} />
      })}
    </div>
    {error && <div className="Mui-error MuiFormHelperText-root"><p>{t(error)}</p></div>}
  </div>
}
function LinkDayRenderer({
  name,
  day,
  dayName,
  values,
  onChange,
  onSplit,
  onCopyAll
}) {
  const { t } = useTranslation();

  const doToggle = React.useCallback((e) => {
    let c = e.target.checked;
    let frames = []
    if (c) {
      // add default
      frames = [{ start: DEFAULT_START_HOUR, end: DEFAULT_END_HOUR }]
    }
    onChange({
      target: {
        name: `${name}.${day}`,
        value: { frames },
      }
    })
  }, [name, day, onChange])
  const doTrash = React.useCallback((i) => {
    let frames = values.frames;
    /* istanbul ignore else */
    if (frames && frames[i]) {
      // remove index
      frames.splice(i, 1)

      onChange({
        target: {
          name: `${name}.${day}`,
          value: { frames },
        }
      })
    }
  }, [name, day, values, onChange])

  return <div className="link-input-day-availability">
    <div className="day-name">
      <Checkbox className="done-input" data-testid={"checker-" + day} onClick={doToggle} checked={values.frames.length > 0} />{dayName}</div>
    <div className="item done-input-time-item">
      {(values.frames.length === 0) && <span className="italic">{t('common.unavailable')}</span>}
      {values.frames.map((frm, i) => {
        return <div key={'day-' + i} className="day-container"><DayFrame name={`${name}.${day}.frames[${i}]`} frame={frm} onChange={onChange}
          prec={(i > 0) ? values.frames[i - 1] : null} next={i < (values.frames.length - 1) ? values.frames[i + 1] : null}
          selectVariant={"outlined"} />
          <LMTooltip
            childrenClassName="showHand"
            content={
              <>
                <p><Trans i18nKey="schedulingLink.form.openingHoursActions.deleteTooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
              </>
            }
          >
            <IconButton data-testid={'trash-' + day + "-" + i} onClick={() => doTrash(i)}><Delete /></IconButton></LMTooltip>
        </div>
      })}
    </div>

    <div className="actions">
      <LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p><Trans i18nKey="schedulingLink.form.openingHoursActions.addTooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
          </>
        }
      >
        <IconButton data-testid={'split-' + day} onClick={onSplit} disabled={(values.frames.length > 1) ? true : undefined}><Add /></IconButton>
      </LMTooltip>
      <LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p><Trans i18nKey="schedulingLink.form.openingHoursActions.copyToAllTooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
          </>
        }
      >
        <IconButton data-testid={'copy-' + day} onClick={() => onCopyAll(values)} className="copy-" disabled={(values.frames.length === 0) ? true : undefined}>{
          <Copy />
        }</IconButton></LMTooltip>
    </div>
  </div>;
}

export function SchedulingPeriod({ value, handleChange }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [period, setPeriod] = React.useState(value.duration === -1 ? '0' : '1');
  const doChange = React.useCallback(e => {
    let v = e.target.value;
    if (v === '0') {
      handleChange({
        target: {
          name: 'validity',
          value: {
            start: moment(),
            duration: -1,
          }
        }
      })
    } else {
      handleChange({
        target: {
          name: 'validity',
          value: {
            start: moment(),
            duration: 7 * DAY, // 1 week per default?
          }
        }
      })
    }
    setPeriod(v)
  }, [handleChange]);
  return (<div className='done-input link-cmp'><div className='done-input'>
    <div className="title">
      <span>{t('schedulingLink.form.schedulingPeriod')}</span>
      <LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p><Trans i18nKey="schedulingLink.form.schedulingPeriodHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
          </>
        }
      >
        <HelpOutline />
      </LMTooltip>
    </div>
    <div className="subtitle">{t("schedulingLink.form.schedulingPeriodSubtitle")}</div>
    <FormControl className="scheduling-period">
      <RadioGroup
        aria-labelledby="scheduling period"
        value={period}
        name="rd-schedule-period"
        onChange={doChange}
        data-testid="rd-periods"
      >
        <FormControlLabel value="0" className={(period === "0") ? "selected" : ""} control={<Radio data-testid="rd-anytime" />} label={t("schedulingLink.form.schedulingPeriodOptions.anytime")} />
        <FormControlLabel value="1" className={(period === "1") ? "selected" : ""} control={<Radio data-testid="rd-period" />} label={<StartEndLabel value={value} handleChange={handleChange} disabled={period === '0'}
          anchorEl={anchorEl} />}></FormControlLabel>
      </RadioGroup>
    </FormControl>
  </div></div>)
}
// cannot test Trans components....
const ReadOnly = { readOnly: true }
export function StartEndLabel({ value, disabled, handleChange }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = React.useCallback((event) => {
    if (disabled) {
      setAnchorEl(event.currentTarget);
      return
    }
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, [disabled]);
  const handleClickAway = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const [state, _setState] = React.useState([
    {
      startDate: moment(value.start).toDate(),
      endDate: moment(value.start).clone().add((value.duration > 0) ? value.duration : 7 * DAY + DAY, 'm').toDate(),
      key: 'selection'
    }
  ]);
  const setState = React.useCallback((selection) => {
    _setState([selection])
    let start = moment(selection.startDate);
    let end = moment(selection.endDate).clone().diff(start, "m") + DAY;
    handleChange({
      target: {
        name: "validity.start",
        value: start
      }
    });
    setTimeout(() => handleChange({
      target: {
        name: "validity.duration",
        value: end
      }
    }), 0);
  }, [handleChange]);
  return <div className="scheduling-period-label" onClick={handleClick} ><Trans i18nKey='schedulingLink.form.schedulingPeriodOptions.rangebetween2' values={{
    start: moment(state[0].startDate).format(t('common.dateFormatSimple')),
    end: moment(state[0].endDate).format(t('common.dateFormatSimple'))
  }}>
    between <span className="date date-start">date start</span>and <span className="date date-end">date end</span></Trans>
    {Boolean(anchorEl) &&
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }} onClose={handleClickAway}
        className='date-range-popover'>
        <DateRange
          enabled={!disabled}
          rangeColors={["#7060df"]}
          editableDateInputs={false}
          onChange={item => setState(item.selection)}
          moveRangeOnFirstSelection={false}
          ranges={state}
        />
      </Popover>}
  </div>

}
/* istanbul ignore next */
export function __StartEndLabel({ value, disabled, handleChange }) {
  const { t } = useTranslation();
  const end = moment(value.start).clone().add((value.duration > 0) ? value.duration : 24 * 60, 'm')
  const doStartChange = React.useCallback(e => {
    handleChange({
      target: {
        name: "validity.start",
        value: e
      }
    })
  }, [handleChange]);
  const doEndChange = React.useCallback(e => {
    let end = e.diff(value.start, "m");
    handleChange({
      target: {
        name: "validity.duration",
        value: end
      }
    })
  }, [value.start, handleChange]);
  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <div className="scheduling-period-label"><Trans i18nKey='schedulingLink.form.schedulingPeriodOptions.rangebetween'>
      between <KeyboardDatePicker
        margin="normal"
        disabled={disabled}
        id="dt-pck-start"
        format={t('common.dateFormatSimple')}
        value={value.start}
        onChange={doStartChange}
        inputVariant="outlined"
        inputProps={ReadOnly}
      /> and <KeyboardDatePicker
        margin="normal"
        id="dt-pck-end"
        disabled={disabled}
        minDate={moment(value.start).clone().add(1, 'd')}
        format={t('common.dateFormatSimple')}
        value={end}
        onChange={doEndChange}
        inputVariant="outlined"
        inputProps={ReadOnly}
      />
    </Trans></div>
  </MuiPickersUtilsProvider>
}
const VALID_BUFFER = [
  {
    value: 0,
    label: "schedulingLink.form.config.buffer.options.none"
  },
  {
    value: 5,
    label: "schedulingLink.form.config.buffer.options.5min"
  },
  {
    value: 10,
    label: "schedulingLink.form.config.buffer.options.10min"
  },
  {
    value: 15,
    label: "schedulingLink.form.config.buffer.options.15min"
  },
  {
    value: 30,
    label: "schedulingLink.form.config.buffer.options.30min"
  },
  {
    value: 45,
    label: "schedulingLink.form.config.buffer.options.45min"
  },
  {
    value: 60,
    label: "schedulingLink.form.config.buffer.options.1h"
  },
  {
    value: 90,
    label: "schedulingLink.form.config.buffer.options.1h30"
  },

]
const VALID_START_INC = [5, 10, 15, 20, 30, 45, 60];
const onlyIntegers = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}
export function ConfigLink({ value, handleChange, errors }) {
  const { t } = useTranslation();

  return (<div>
    <ConfigStartInc value={value.start_inc} handleChange={handleChange} errors={errors} />

    <ConfigNotice valueDuration={value.notice_duration} valueMultiplier={value.notice_multiplier} handleChange={handleChange} error={errors && errors.notice_duration} />

    <div className='done-input link-cmp'>
      <div className="title">
        <span>{t('schedulingLink.form.buffer')}</span>
        <LMTooltip
          childrenClassName="showHand"
          content={
            <>
              <p><Trans i18nKey="schedulingLink.form.bufferHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
            </>
          }
        >
          <HelpOutline />
        </LMTooltip>
      </div>

      <div className="config-buffers">
        <div className="smallhint">{t('schedulingLink.form.bufferBefore')}</div>
        <Select
          id="before-buffer"
          native={true}
          variant="outlined"
          name='configuration.before_duration'
          value={value.before_duration}
          onChange={handleChange}
        >{
            VALID_BUFFER.map((v) => <option key={"before-" + v.value} value={v.value}>{t(v.label)}</option>)
          }
        </Select>
        <div className="smallhint sized">{t('schedulingLink.form.bufferAfter')}</div>
        <Select
          id="after-buffer"
          native={true}
          variant="outlined"
          name='configuration.after_duration'
          value={value.after_duration}
          onChange={handleChange}
        >{
            VALID_BUFFER.map((v) => <option key={"after-" + v.value} value={v.value}>{t(v.label)}</option>)
          }
        </Select>
      </div>
    </div>
  </div>)
}
export function ConfigStartInc({ value, handleChange, errors }) {
  const { t } = useTranslation();
  const choices = React.useMemo(() => {
    // if value is not present, add it to the list
    if (!VALID_START_INC.find((opt) => opt === value)) {
      return [value, ...VALID_START_INC]
    }
    // in set
    return VALID_START_INC
  }, []);
  const doSelectChange = React.useCallback((e, v, reason) => {
    let value = 0;
    if (reason === "select-option") value = v;
    else if (reason === "input") value = v || 0
    else return;
    handleChange({
      target: {
        name: "configuration.start_inc",
        value: +value
      }
    })
  }, [handleChange])

  return <div className='done-input link-cmp'>
    <div className="title">
      <span>{t('schedulingLink.form.startIncrement')}</span>
      <LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p><Trans i18nKey="schedulingLink.form.startIncrementHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
          </>
        }>
        <HelpOutline />
      </LMTooltip>
    </div>
    <div className="subtitle">{t('schedulingLink.form.startIncrementSubtitle')}</div>
    <div className="config-start_inc">
      <Autocomplete
        id="cmb_start_inc"
        name="configuration.start_inc"
        freeSolo
        disableClearable={true}
        data-testid="cmb_start_inc"
        className="link-autocomplete"
        onChange={doSelectChange}
        onInputChange={doSelectChange}
        value={value}
        options={choices}
        getOptionLabel={(option) => {
          return "" + option
        }}
        getOptionSelected={(option, value) => {
          return "" + value === option
        }}
        renderInput={(params) => <TextField {...params} inputProps={{
          ...params.inputProps, onKeyPress: onlyIntegers
        }} variant="outlined" />}
      />
      <span className="units">{t('common.min')}</span>
    </div>
    {errors && errors.start_inc && <p className="Mui-error MuiFormHelperText-root">{t(errors.start_inc)}</p>}
  </div>
}
export function ConfigNotice({ valueDuration, valueMultiplier, handleChange, error }) {
  const { t } = useTranslation();
  const [noticeValue, noticeMultiplier] = React.useMemo(() => {
    if (valueDuration === 0) return ['', valueMultiplier];
    return [valueDuration, valueMultiplier]
  }, [valueDuration, valueMultiplier])
  const handleNoticeChange = React.useCallback((e) => {
    let v = +e.target.value;
    if (v >= 0 && ((v * valueMultiplier) <= MAX_NOTICE_DURATION)) handleChange({
      target: {
        name: "configuration.notice_duration",
        value: v
      }
    })
  }, [handleChange, valueMultiplier]);
  const handleMultiplierChange = React.useCallback((e) => {
    let multiplier = +e.target.value;
    handleChange({
      target: {
        name: "configuration.notice_multiplier",
        value: multiplier
      }
    })
  }, [handleChange]);

  return <div className='done-input link-cmp'>
    <div className="title">
      <span>{t('schedulingLink.form.notice')}</span>
      <LMTooltip
        childrenClassName="showHand"
        content={
          <>
            <p><Trans i18nKey="schedulingLink.form.noticeHelp"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
          </>
        }>
        <HelpOutline />
      </LMTooltip>
    </div>
    <div className="subtitle">{t('schedulingLink.form.noticeSubtitle')}</div>
    <div className="config-notice">
      <DoneTextField
        name="configuration.notice_value"
        inputProps={{
          type: "number",
          "data-testid": "configuration.notice_value",
          onKeyPress: onlyIntegers,
        }}
        value={noticeValue}
        onChange={handleNoticeChange}
      ></DoneTextField>
      <Select
        id="mulitplier"
        native={true}
        variant="outlined"
        value={noticeMultiplier}
        name="configuration.notice_multiplier"
        data-testid="configuration.notice_multiplier"
        onChange={handleMultiplierChange}
      >
        <option value={1}>{t('common.minute', { count: valueDuration })}</option>
        <option value={60}>{t('common.hour', { count: valueDuration })}</option>
        <option value={1440}>{t('common.day', { count: valueDuration })}</option>
      </Select>
    </div>
    {error && <div className="Mui-error MuiFormHelperText-root"><p>{t(error)}</p></div>}
  </div >
}