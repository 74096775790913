import {
  STATUS
} from '../api/validators/proposal'
export const isWaitingForYou = (proposal) => {
  if (!proposal || !proposal.inviteesDetails || !proposal.me || !proposal.inviteesDetails[proposal.me]) return false;
  if (proposal.iAmHost) return false;
  return (
    // Forced on some slots
    proposal.status === STATUS.PENDING
    && proposal.ignore_initial_avail
    && proposal.me && proposal.inviteesDetails[proposal.me] && proposal.inviteesDetails[proposal.me].conflicting_slots && proposal.inviteesDetails[proposal.me].conflicting_slots.length > 0
    && proposal.inviteesDetails[proposal.me].response !== 'yes')
    ||
    // If I was requested to vote
    (proposal.status === STATUS.PENDING
      && proposal.me && proposal.inviteesDetails[proposal.me] && proposal.inviteesDetails[proposal.me].vote_requested
      && proposal.inviteesDetails[proposal.me].response !== 'yes');

}