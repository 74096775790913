/* istanbul ignore file */
import { Switch } from '../../../LMSwitch';
import React from 'react';
import useDevSettings from '../../../../hooks/use.dev.settings';
import './DevSettings.scss';

export default function DevSettings() {

  const { setDevSettingItem, getDevSettingItem, DEV_FLAGS } = useDevSettings();

  const contactsUseConnectionsList = getDevSettingItem(DEV_FLAGS.CONTACTS_USE_PEOPLE_CONNECTIONS_LIST);
  const heatmapOnlySlots = getDevSettingItem(DEV_FLAGS.HEATMAP_USE_ONLY_SLOTS);
  const julyForm = getDevSettingItem(DEV_FLAGS.JULY_FORM);
  return (
    <div className="settings-dev">
      <div className="section item valign-center">
        <div>Contacts: Use People.Connections.List API</div>
        <Switch
          color="primary"
          checked={contactsUseConnectionsList || false}
          value={contactsUseConnectionsList}
          onChange={(event) => {
            setDevSettingItem(DEV_FLAGS.CONTACTS_USE_PEOPLE_CONNECTIONS_LIST,
              event.target.checked);
          }} />
      </div>
      <div className="section item valign-center">
        <div>Heatmap: Use only slots</div>
        <Switch
          color="primary"
          checked={heatmapOnlySlots || false}
          value={heatmapOnlySlots}
          onChange={(event) => {
            setDevSettingItem(DEV_FLAGS.HEATMAP_USE_ONLY_SLOTS,
              event.target.checked);
          }} />
      </div>
      <div className="section item valign-center">
        <div>Force use of July Form (Heatmap in Form)</div>
        <Switch
          color="primary"
          checked={julyForm || false}
          value={julyForm}
          onChange={(event) => {
            setDevSettingItem(DEV_FLAGS.JULY_FORM,
              event.target.checked);
            try { localStorage.removeItem("current_form_values"); } catch (err) {
              console.error("Err", err)
            }
          }} />
      </div>
    </div>
  );
}