/* istanbul ignore file */
/**
 * @fileOverview Main app worker start and ipc messages
 * processing. 
 * For an action to be processed by the web worker, the name
 * MUST start with "WORKER_"
 * @author stephane@do.ne
 * @module worker
 */
// just a mapping between web-worker postMessage/sendMessage and
// redux dispatch

// eslint-disable-next-line
import DoneWorker from 'worker-loader!./done.worker';
import logger from '../logger';
import * as ACTIONS from '../actions';
/**
 * @property Web worker instance
 * @private
 * 
 */
const worker = DoneWorker();


/**
 * @method
 * listen to worker message, dispatch result
 * @param {function} dispatch redux store dispatch method
 */
export default (dispatch) => {
  // listen to worker message, dispatch result
  // exemple with counter
  worker.addEventListener('message', (result) => dispatch(result.data));
  return {
    process: function (action) {
      return new Promise((resolve, reject) => {
        worker.postMessage(action);
        resolve();
      });
    }
  }
}