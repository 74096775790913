import React from 'react';
import DoneButton from '../../../DoneButton';
import NOOP from '../../../../utils/noop';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleOutlineRounded, ExpandMoreOutlined
} from '@material-ui/icons';
import orderedInvitees, { attendeeHasVoted } from '../../../../utils/get.attendee.score'
import './who.are.you.scss';
import { Collapse } from '@material-ui/core';

export default function WhoAreYou({ invitees = [], onSelectAction = NOOP, organizer = {} }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const availableList = React.useMemo(() => {
    return invitees.filter(inv => attendeeHasVoted(inv));
  }, [invitees])
  const needToReplyList = React.useMemo(() => {
    return orderedInvitees(invitees.filter(inv => !attendeeHasVoted(inv)), organizer.email);
  }, [invitees, organizer.email])
  return (
    <div data-testid="who-are-you" className='who-are-you-dlg'>
      <div className="textual">
        <h4>{t('event.anonymous.dialog.title')}</h4>
        <p>{t('event.anonymous.dialog.subTitle')}</p>
      </div>
      <div className="items fancy-scroll">
        {needToReplyList.map((invitee, i) => {
          return <div key={'inviteeA-' + i}><WhoAreYouItem invitee={invitee} onSelectAction={onSelectAction} /></div>
        })}
        {availableList && availableList.length > 0 &&
          <>
            <div className={"expandContainer " + (expanded ? " expanded " : "")} data-testid="expandContainer" onClick={handleExpandClick}>
              <span>{t('event.anonymous.dialog.available', { count: availableList.length })}</span>
              {<ExpandMoreOutlined />}
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit className='expandableContainer'>
              {availableList.map((invitee, i) => {
                return <div key={'inviteeB-' + i}><WhoAreYouItem invitee={invitee} onSelectAction={onSelectAction} /></div>
              })}
            </Collapse>
          </>}
        <a data-testid="who-are-you-host" onClick={() => onSelectAction(organizer, true)}>{t('event.anonymous.dialog.IAmTheHost')}</a>
      </div>

    </div>
  );
}

function WhoAreYouItem({ invitee, onSelectAction }) {
  const { t } = useTranslation();
  let isRegistered = invitee.registered;
  let isUnknown = invitee.isUnknown;
  let hasVoted = attendeeHasVoted(invitee)
  return (
    <div className="who-are-you-item">
      <div className="invitee-infos">
        {invitee.name && <div className="title">{invitee.name}</div>}
        {!isUnknown && <div className="email">{invitee.email}</div>}
      </div>
      {hasVoted ? <div data-testid={"who-are-you-item-" + invitee.email} className="already-vote" onClick={() => onSelectAction(invitee, isRegistered)}><CheckCircleOutlineRounded /></div> : <DoneButton
        name={"who-are-you-item-" + invitee.email}
        onClick={() => onSelectAction(invitee, isRegistered)}
        label={isRegistered ? t('event.anonymous.dialog.signIn') : t('event.anonymous.dialog.thisIsMe')}
        className={isRegistered ? "grey small" : "small"} />}
    </div>
  );
}
