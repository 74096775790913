import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack'
import { FreeInput, LocationMenuItem } from './index';
import * as ACTIONS from '../../../../../actions';
import { ZoomIcon as VideoCall, MeetIcon, TeamsIcon, Close } from '../../../../../assets/icons';
import NOOP, { NO_BUBBLES, NO_PROPAGATION } from '../../../../../utils/noop';
import { HelpOutline } from '@material-ui/icons';
import { LMTooltip } from '../../../../tooltip/Tooltip';
import DoneTextField from '../Text';
import FoldableInput from '../foldable.input';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import "./location.v2.scss";
import "./location.v3.scss";
const LOCATION_VALUES = {
  "zoom": {
    id: 'zoom',
    conference_tool: 'zoom',
    icon: <VideoCall />,
  },
  'zoom:personal': {
    id: 'zoom:personal',
    conference_tool: 'zoom',
    icon: <VideoCall />,
  },
  'gmeet': {
    id: 'gmeet',
    conference_tool: 'gmeet',
    icon: <MeetIcon />,
  },
  'teams': {
    id: 'teams',
    conference_tool: 'teams',
    icon: <TeamsIcon />,
  },

};
const REGISTRATIONS = {
  'zoom:register': {
    id: 'zoom:register',
    conference_tool: 'zoom',
    register: true,
    icon: <VideoCall />,
  },
}
export default function Locations({
  value, conference,
  profile,
  calendar,
  onChange = NOOP,
  onRegister = NOOP,
  error = "",
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isRegistering, setIsRegistering] = React.useState(false);
  const doRegistration = React.useCallback((toolId) => {
    setIsRegistering(true)
    // ask for oauth url
    // go
    onRegister(toolId);
    dispatch({
      type: ACTIONS.WORKER_GET_TOOL_OAUTH,
      payload: toolId,
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_TOOL_OAUTH_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_TOOL_OAUTH_ERROR
      }
    }).then((url) => {
      /* istanbul ignore if no work */
      if (!url || !url.payload) return Promise.reject()
      window.location.assign(url.payload)
    }).catch((err) => {
      // could not
      enqueueSnackbar(t('createEvent.form.errors.register_error'), {
        variant: 'error'
      })
    }).then(() => {
      setIsRegistering(false)
    })
  }, [onRegister]);
  // detect wich buttons to show
  const menuItems = React.useMemo(() => {
    /* istanbul ignore if no work */
    if (!profile) return null;
    let confs = [];
    let tools = profile.tools || [];
    // check for providers dedicated online conference tools
    let gmeet = calendar && calendar.provider === 'google' && calendar.supported_conferences
      && calendar.supported_conferences.indexOf("gmeet") > -1;
    let teams = calendar && calendar.provider === 'microsoft' && calendar.supported_conferences
      && calendar.supported_conferences.indexOf("teams") > -1;
    let zoom = tools.find((tool) => tool.toolID === 'zoom');

    if (gmeet) {
      // add toggle
      let loc = LOCATION_VALUES["gmeet"];
      confs.push(<ToggleButton data-testid={"conference-" + loc.id} value={loc.id} size="large" key={loc.id}>
        <LocationMenuItem loc={loc} />
      </ToggleButton>)
    }
    if (teams) {
      // add toggle
      let loc = LOCATION_VALUES["teams"];
      confs.push(<ToggleButton data-testid={"conference-" + loc.id} value={loc.id} size="large" key={loc.id}>
        <LocationMenuItem loc={loc} />
      </ToggleButton>)
    }
    // check if user have zoom connected
    if (zoom && zoom.account_id) {
      let loc = LOCATION_VALUES["zoom"];
      confs.push(<ToggleButton data-testid={"conference-" + loc.id} value={loc.id} size="large" key={loc.id}>
        <LocationMenuItem loc={loc} />
      </ToggleButton>)
      loc = LOCATION_VALUES["zoom:personal"];
      confs.push(<ToggleButton data-testid={"conference-" + loc.id} value={loc.id} size="large" key={loc.id}>
        <LocationMenuItem loc={loc} />
      </ToggleButton>)
    } else if (zoom) {
      // connect zoom!
      let loc = REGISTRATIONS["zoom:register"];
      confs.push(<ToggleButton data-testid={"conference-" + loc.id} value={loc.id} size="large" key={loc.id}>
        <LocationMenuItem loc={loc} />
      </ToggleButton>)
    }
    return confs;
  }, [profile, doRegistration, calendar])// need calendar, gmeet only with google account
  const doSelectConference = React.useCallback((e, value) => {
    // if null, reset
    if (value === null || value === '') onChange({
      target: {
        name: "conference",
        value: "", // allow unselect
      }
    });
    // if zoom or meet, set conference datas
    let type = LOCATION_VALUES[value];
    if (type) {
      onChange({
        target: {
          name: "conference",
          value: value, // allow unselect
        }
      });
    } else if (REGISTRATIONS[value]) doRegistration(REGISTRATIONS[value].conference_tool)
  }, [onChange, doRegistration, conference]);
  return <>
    {menuItems && menuItems.length > 0 && <div className={"duration-padding duration-cmp  conference-selector  no-bottom"}>
      <InputLabel className="fake-label with-help-button">
        {t('common.conference')}
        <LMTooltip
          childrenClassName="showHand"
          content={
            <>
              <p><Trans i18nKey="createEvent.form.conferenceTooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
            </>
          }
        >
          <HelpOutline />
        </LMTooltip></InputLabel>
      <ToggleButtonGroup
        className="done-input"
        name={"conference"}
        value={conference}
        exclusive
        data-testid="conferences"
        onChange={doSelectConference}
        aria-label="Meeting conference"
      >
        {menuItems}
        <ToggleButton className="no-stretch" data-testid={"conference-physic"} value={''} key={"physic"}>
          <div className="location-menu-item">
            <div className="location-menu-desc">
              <div className="location-menu-desc-title">{t(`createEvent.form.locationitems.physic.title`)}</div>
            </div>
          </div>
        </ToggleButton>
      </ToggleButtonGroup>

    </div>}
    <FoldableInput name="foldable-notes" title={t('createEvent.form.locationTitle')} open={value === '' ? 0 : 1}
      className={"done-input fast-selector location-selector " + (error ? 'Mui-error' : '')}>

      <DoneTextField label={
        <>{t('createEvent.form.location')}
          <LMTooltip
            childrenClassName="showHand"
            content={
              <>
                <p><Trans i18nKey="createEvent.form.locationTooltip"> <span className={"strong " + (error ? 'Mui-error' : '')}>This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
              </>
            }
          >
            <HelpOutline />
          </LMTooltip></>
      }
        className="with-help"
        value={value}
        name="location"
        onChange={onChange}
        placeholder={t('createEvent.form.locationPlaceholder')}
        multiline={true}
        rows={2}
        rowsMax={10}
        rowsMin={1}
      />
      {/* <FreeInput value={value} onChange={onChange} /> */}
      {error && <p className="done-Mui-error align-right" data-testid="location-error-text">{t('createEvent.form.errors.locationTooLong', { max: 1024 })}</p>}
    </FoldableInput>
  </>;
}