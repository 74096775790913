import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import NOOP, { NO_BUBBLES } from '../../../../../utils/noop';

import './period.scss';
import { Clear } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
/**
 * @param {number} index index of selected period in list
 * @param {Period} period period (ie: start and end?) of proposal
 * @param {String} title event title @WAITANDSEE do we need more datas for this? 
 */
export default function Period({
  index,
  period,
  title,
  onRemoveProposal,
  setDragactive,
  onClick = NOOP,
  isFreeSlot = true,
}) {

  const { t } = useTranslation();
  const btnRef = React.useRef(null);
  React.useEffect(() => {
    let current = btnRef.current;
    if (!onRemoveProposal || !setDragactive) return;
    const clear = (e) => {
      e.preventDefault();
      e.stopPropagation();
      onRemoveProposal(period);
      setDragactive(false)
    }
    if (current) {
      current.addEventListener('touchstart', clear, { passive: false });
      current.addEventListener('touchend', NO_BUBBLES, { passive: false });
      current.addEventListener('touchmove', NO_BUBBLES, { passive: false });
    }
    return () => {
      if (current) {
        current.removeEventListener('touchstart', clear);
        current.removeEventListener('touchend', NO_BUBBLES);
        current.removeEventListener('touchmove', NO_BUBBLES);
      }
    }
  }, [btnRef, onRemoveProposal, period, setDragactive])
  // if(!title) return null;
  if (!period) {
    // return empty
    return <div className="proposal empty">&nbsp;</div>
  } else {
    // if small period, add a custom class
    let small = (period.geometry || {height: 0}).height < 15 ? "small" : '';
    return <div id="period" className={"proposal " + small + (isFreeSlot ? '' : ' is-busy')} onClick={onClick}>
      <div className={"proposal-title " + (isFreeSlot ? '' : 'is-busy')}>{title ? title : (isFreeSlot ? period.start.format(t('common.slotHour')) : t('createEvent.heatmap.conflictingSlot'))}</div>
      {index >= 0 && <div className="slot">{index + 1}</div>}

      <IconButton ref={btnRef} id="period-remove" size="small" data-testid="removeProposal-btn" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onRemoveProposal(period);
      }} variant="contained"

        onMouseDown={NO_BUBBLES}
        onMouseUp={NO_BUBBLES}
      ><Clear /> </IconButton>
    </div>
  }
}