import React from 'react';
import * as ACTIONS from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { getProfile } from '../../../../reducers/profile';
import { Loader } from '../../../Loader';
import FreeUser from './free.user';
import PremiumUser from './premium.user';
import useBravo from '../../../../hooks/use.bravo';
import ROUTES from '../../../../routes/routes.name';
import { useDialog } from '../../../dialogs/dialog.provider';
import WelcomePremium from './welcome';
import { HOME } from '../../../../api/config';
export default function Billing() {
  const { t } = useTranslation();
  const stripe = useStripe();
  const { enqueueSnackbar } = useSnackbar()
  const { enqueueDialog, closeDialog } = useDialog();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [loadingCustomerSession, setLoadingCustomerSession] = React.useState(false)
  const successfullSubscription = useBravo();
  const profile = useSelector(getProfile);
  const location = useLocation();

  React.useEffect(() => {
    if (successfullSubscription) {
      enqueueDialog({
        content: (
          <WelcomePremium doClose={() => closeDialog()} />
        ),
        mustConfirm: true,
        overrideDefaultProps: { maxWidth: 'md' },
        doClose: () => closeDialog()
      });
    }
  }, [successfullSubscription]);

  React.useEffect(() => {
    // on start, refresh user profile
    setLoading(true)
    dispatch({
      type: ACTIONS.WORKER_REFRESH_PROFILE,
      resolvers: {
        resolveOn: ACTIONS.WORKER_REFRESH_PROFILE_SUCCESS,
        rejectOn: ACTIONS.WORKER_REFRESH_PROFILE_ERROR,
      }
    }).catch((err) => { })
      .then(() => setLoading(false))
  }, []);
  const doBilling = React.useCallback(() => {
    // call api
    const back = location.state ? location.state.backTo : undefined;
    setLoadingCustomerSession(true)
    dispatch({
      type: ACTIONS.WORKER_BILLING_SETUP,
      payload: {
        url: HOME + (back || ROUTES.APP_SETTINGS_BILLING)
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_BILLING_SETUP_SUCCESS,
        rejectOn: ACTIONS.WORKER_BILLING_SETUP_ERROR
      }
    }).then((data) => {
      if (!data || !data.payload) return Promise.reject()
      // redirect to new page
      return stripe
        .redirectToCheckout({
          sessionId: data.payload
        })
    }).catch((err) => {
      console.log('[error]', err);
      enqueueSnackbar(t("errors.stripeError", { variant: 'error', className: "snack-error" }))
    })
      .then(() => setLoadingCustomerSession(false));
  }, [stripe, location]);

  const customerPortal = React.useCallback(() => {
    // call api
    /* istanbul ignore if */
    if (!profile || !profile.billing.customer_id) return;
    setLoadingCustomerSession(true)
    dispatch({
      type: ACTIONS.WORKER_BILLING_CUSTOMER,
      customerId: profile.billing.customer_id,
      resolvers: {
        resolveOn: ACTIONS.WORKER_BILLING_CUSTOMER_SUCCESS,
        rejectOn: ACTIONS.WORKER_BILLING_CUSTOMER_ERROR
      }
    }).then((data) => {
      // redirect to new page
      if (data) window.location.assign(data.payload);
      else return Promise.reject()
    }).catch((err) => {
      console.log('[error]', err);
      enqueueSnackbar(t("errors.stripeError", { variant: 'error', className: "snack-error" }))
    }).then(() => setLoadingCustomerSession(false));
  }, [profile]);

  if (!profile) return null;
  if (loading) return <Loader />
  return (profile.billing && profile.billing.customer_id) ? <PremiumUser profile={profile} goToPortal={customerPortal} loadingCustomerSession={loadingCustomerSession} />
    : <FreeUser profile={profile} doSubscribe={doBilling} loadingCustomerSession={loadingCustomerSession} />

}
