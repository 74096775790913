export const getNbVoters = (invitees, inviteesDetails, contacts, vote_required) => {
  return invitees.reduce((acc, invitee) => {
    if (attendeeMustVote(invitee, inviteesDetails, contacts, vote_required)) acc = acc + 1;
    return acc;
  }, 0)
}
export const getVoters = (invitees = [], inviteesDetails = {}, contacts = {}, vote_required) => {
  return invitees.filter((invitee) => attendeeMustVote(invitee, inviteesDetails, contacts, vote_required));
}

export function attendeeMustVote(invitee, inviteesDetails, contacts, vote_required) {
  let contact = (contacts && contacts[invitee]) || {};
  let inviteeDetails = (inviteesDetails && inviteesDetails[invitee]) || {};
  let optional = inviteeDetails.optional;
  let hasConflicts = inviteesDetails && inviteesDetails[invitee] && (inviteesDetails[invitee].isForced || inviteesDetails[invitee].isForbidden);
  let vote_requested = vote_required;
  if (vote_required === undefined) {
    vote_requested = inviteeDetails.vote_requested;
  }

  if (!optional &&
    (!contact.isDoner || hasConflicts || vote_requested)) return true;
  return false;
}
