import React from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import * as ACTIONS from '../../../../actions';
import { useTranslation } from 'react-i18next';
import { ConfigStartInc } from "../../create-event/booking.link";
import { useSnackbar } from 'notistack';
import Select from '@material-ui/core/Select';
import './overlapp.scss';

const VALID_START_INC = [15, 30, 60];
export function ConfigStartSettings({ profile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const onChange = React.useCallback((e) => {
    /* istanbul ignore if */
    if (loading) return;
    const newValue = +e.target.value;
    setLoading(true);
    dispatch({
      type: ACTIONS.WORKER_SAVE_WH,
      payload: {
        ...profile,
        overlapp: newValue
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_SAVE_WH_SUCCESS,
        rejectOn: ACTIONS.WORKER_SAVE_WH_ERROR,
      }
    }).catch((err) => {
      // rollback
      enqueueSnackbar(t('settings.sections.advanced.lang.error'), { variant: 'error' });
    }).then(() => {
      setLoading(false);
    })
  }, [loading, profile]);

  return <div className={"section advanced-item " + (loading ? 'inactive' : '')}>
    <div className="title">{t('schedulingLink.form.startIncrement')}</div>
    <div className="subtitle">{t("schedulingLink.form.startIncrementSubtitle")}</div>
    {!loading && <div className="test-only" data-testid="loader" />}
    <div className="overlapp">
      <div className="overlapp-item">
        <Select
          native
          value={profile.overlapp}
          onChange={onChange}
          className="done-input"
          inputProps={{
            "data-testid": 'overlapp',
          }}
        >
          <option aria-label="None" value={-1} >{t('common.none')}</option>
          {VALID_START_INC.map((v) => <option key={v} value={v}>{v}</option>)}
        </Select>
        <span className="units">{t('common.min')}</span>
      </div>
    </div>
  </div>
}