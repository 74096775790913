// global __lm_booking_link __lm_slots __lm_company __lm_host_profile
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import BookingContent from './link.content';
import BookingForm from './link.form';
import DoneButton from '../../DoneButton';
import * as ACTIONS from '../../../actions'
import {
  CalendarBlank, LetsmeetSignatureLight
} from '../../../assets/icons';
import { fromApi } from '../../../api/validators/link';
import { useParams } from "react-router-dom";
import { ArrowBack } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton'
import "./booking.scss";
import moment from 'moment'
import { useDispatch } from 'react-redux';

export default function Booking({
  __test_link,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { username, slug } = useParams()
  const [isBooked, __setBook] = React.useState(false);
  const dispatch = useDispatch();
  const setBook = React.useCallback((v) => {
    __setBook(v);
    history.push({
      pathname: `/${username}/${slug}`,
      state: {
        "target": "booked"
      }
    })
  }, []);
  const doBack = React.useCallback(() => {
    history.push({
      pathname: `/${username}/${slug}`,
    })
  }, [])
  // check status of booking link, handle start error
  const link = React.useMemo(() => {
    // do something here
    /* istanbul ignore if test only */
    if (__test_link) return __test_link;

    /* istanbul ignore else */
    if (window.__lm_booking_link) {
      // set default tz
      moment.tz.setDefault(moment.tz.guess());
      let link = fromApi(window.__lm_booking_link);
      /* istanbul ignore else */
      if (window.__lm_company) link.company = window.__lm_company
      /* istanbul ignore else */
      if (window.__lm_host_profile) link.organizer = window.__lm_host_profile
      /* istanbul ignore else */
      if (window.__lm_slots) link.slots = window.__lm_slots;
      // MUST have a signatire
      /* istanbul ignore else */
      if (window.__lm_anonymous_token) link.signature = window.__lm_anonymous_token;
      /* istanbul ignore else */
      if (!link || !link.organizer || !link.id || !link.slots
        || !link.signature) return { error: window.__lm_booking_link_error || 'unknown' };
      return link;
    }
  }, [])
  React.useEffect(() => {
    // on page load, trigger analytics event
    dispatch({
      type: ACTIONS.WORKER_ANALYTICS_BOOKING_PAGE_VIEWED,
      payload: { bookingLinkID: link && link.id ? link.id : undefined, signature: link && link.signature },
      resolvers: {
        resolveOn: ACTIONS.WORKER_ANALYTICS_BOOKING_PAGE_VIEWED_SUCCESS,
        rejectOn: ACTIONS.WORKER_ANALYTICS_BOOKING_PAGE_VIEWED_ERROR
      }
    }).catch((err) => {
      // silently fail
    });
  }, []);
  return (
    <div className="booking-page " data-testid="booking-page">

      {isBooked ? <Confirmation link={link} values={isBooked} /> : <><div className={"booking-info-panel fancy-scroll "}

        data-testid="booking-info-panel">
        {location && location.state && location.state.target === '/infos'
          && <div className="back mobile-only">
            <IconButton className="grey" onClick={doBack}>
              <ArrowBack />
            </IconButton>
            <span className="button-back-label">{t('common.back')}</span>
          </div>}
        <div className="inner">
          <BookingContent link={link} />
        </div>
        <div className="signature desktop-only">
          <a href="https://letsmeet.network?utm_source=scheduling_link_powered_by&utm_medium=web">
            <LetsmeetSignatureLight />
          </a>
        </div>
      </div>

        <div id="booking-container" className="booking-action-panel fancy-scroll" >
          <BookingForm link={link} onBooked={setBook} />
        </div></>}

      <div className="signature mobile-only">
        <a href="https://letsmeet.network?utm_source=scheduling_link_powered_by&utm_medium=web">
          <LetsmeetSignatureLight />
        </a>
      </div>

    </div>
  );
}

function Confirmation({ link, values }) {
  const { t } = useTranslation();

  return <div className="confirmation" data-testid="confirmation-page">
    <div className="confirm-title">{t('schedulingPage.confirm.title')}</div>
    <div className="confirm-desc"><Trans i18nKey='schedulingPage.confirm.desc' values={{ name: link.organizer.name }}></Trans></div>
    <div className="confirmed-slot">
      <CalendarBlank /><span className="selected-date">
        <Trans i18nKey='schedulingPage.form.selectedDate' values={{
          start: values.slot.start.format(t('common.hourMinutesFormatFunnel')),
          end: values.slot.end.format(t('common.hourMinutesFormatFunnel')),
          day: values.slot.start.format(t('common.dateFormatWirthYearNoTZ')),
          tz: values.slot.start.format(t('common.timezoneAlone'))
        }}>The selected date.</Trans>
      </span>
    </div>
    <div className="confirm-desc">{t('schedulingPage.confirm.invite')}</div>
    <ConnectYourCalendar link={link} />
  </div>;
}
function ConnectYourCalendar({ link }) {
  const { t } = useTranslation();
  return <div className="connect-your-calendar">
    <div className="connect-title"><Trans i18nKey='schedulingPage.confirm.promo.title'><span className="strong-title">Create your account.</span> <span className="light-title">Make your life easier</span></Trans></div>
    <div className="connect-message"><Trans i18nKey='schedulingPage.confirm.promo.message' values={{ name: link.organizer.name }}>Create your own professional scheduling links and start being more productive.</Trans></div>
    <DoneButton onClick={() => window.location.assign(window.location.origin + "?utm_source=scheduling_page_confirmed&utm_medium=web")} label={t('schedulingPage.confirm.promo.signin')} />
  </div>
}
