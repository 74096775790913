import * as ACTIONS from '../actions';

export default store => next => action => {
  try {
    if (action && (action.type === ACTIONS.WORKER_DONE_TOKEN_RECEIVED ||
      action.type === ACTIONS.DONE_TOKEN_REFRESH)) {
      // save token
      // do I need to save others one?
      // onlu need token, keep structure because who knows?
      let tk = {
        done: action.payload.done
      }
      localStorage.setItem('done_token', JSON.stringify(tk));
      localStorage.removeItem('provider'); // if exists...
      if (action.payload.provider) localStorage.setItem('user_datas', JSON.stringify(action.payload.provider));
    } else if (action && (action.type === ACTIONS.WORKER_LOGOUT
      || action.type === ACTIONS.INVALID_CREDENTIALS)) {
      localStorage.clear();
    } else if (action && action.type === ACTIONS.WORKER_CONNECT_SUCCESS) {
      // save accounts
      let accounts = (action.payload.accounts || []).map((acc) => acc.email);
      localStorage.setItem('known_accounts', JSON.stringify(accounts))
    } else if (action && action.type === ACTIONS.PRE_CONNECTION_TOKEN) {
      // save provider token for full auto login
      let tmp = {
        ...action.payload,
        name: action.name,
      }
      localStorage.setItem('provider', JSON.stringify(tmp))
    } else if (action && action.type === ACTIONS.CLEAN_LS_VALUES){
      let items = action.payload || [];
      for(let item of items) localStorage.removeItem(item);
    }
  } catch (err) { }
  next(action);
}