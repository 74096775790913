import React from 'react';
import ROUTES from './routes.name';
import {
  IconButton
} from "@material-ui/core";
import { Loader } from '../components/Loader';
import {
  PREFILL_FOLLOW_UP,
  PREFILL_EDIT
} from '../reducers/proposals';
import { Redirect, useLocation } from 'react-router-dom';
import {
  Switch,
  Route
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Close
} from "@material-ui/icons";
// import { useTransition, animated } from 'react-spring';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as ACTIONS from '../actions';
import SimpleForm from '../components/pages/create-event/SimpleForm';
import './create.event.scss';
import AppHeader from './app.header';
import { initProposalFrom, copyFromProposal } from '../utils/reinit.proposal';
import Success from '../components/pages/success/success'

export default function CreateEvent() {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  let location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [proposal, setProposal] = React.useState(undefined);
  const [prefillType, setprefillType] = React.useState(undefined);

  React.useEffect(() => {
    // on first render, getsome values from LS to know if must load a proposal
    try {
      let prefill_from_id = localStorage.getItem("PREFILL_FROM_ID", undefined);
      let prefill_type = localStorage.getItem("PREFILL_TYPE", undefined);

      if (prefill_from_id) {
        // try to load
        dispatch({
          type: ACTIONS.WORKER_GET_PROPOSAL,
          payload: {
            id: prefill_from_id,
            // email: query.email,
            // userEmail: [query.email]
          },
          resolvers: {
            resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
            rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR,
          }
        }).then((res) => {
          if (res && res.payload && res.payload[0]) {
            let p = res.payload[0];
            if (prefill_type === PREFILL_FOLLOW_UP) {
              p = initProposalFrom(p);
              // add title decoration
            } else {
              // reschedule: keep all data
              p = copyFromProposal(p, prefill_type === PREFILL_EDIT)
            }
            setprefillType(prefill_type)
            setProposal(p);
          }
        }).catch((err) => {
          // error while loading data
          const action = key => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <Close />
            </IconButton>);
          console.error(err)
          enqueueSnackbar(
            t("errors.prefillError"), {
            variant: 'error',
            className: 'snack-error',
            persist: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            action
          })
        }).then(() => {
          // cleanup LS   ---------- !important
          dispatch({
            type: ACTIONS.CLEAN_LS_VALUES,
            payload: ["PREFILL_FROM_ID", "PREFILL_TYPE"]
          })
        }).then(() => {
          setLoading(false)
        })
      } else {
        setLoading(false);
      }
      // remove LS keys
    } catch (err) {
      console.error('Prefill Error:', err)
      // oups, pass
      setLoading(false);
    }
  }, []);

  const reset = React.useCallback(() => {
    // after proposal edition/other, clear state
    setProposal()
    setprefillType()
  }, [setProposal, setprefillType])

  return loading ? <Loader className="display-block" />
    : (
      <div className='create-event dashboard' >
        <AppHeader />
        <section>

          <div className="page-content create-form">
            <Switch location={location}>
              <Route
                path={ROUTES.CREATE_A_MEETING}
                exact={true}
                render={() => <SimpleForm proposal={proposal} prefillType={prefillType} />
                } />
              <Route
                path={ROUTES.CREATE_A_MEETING_LINK}
                exact={true}
                render={() => <RenderLinkPage location={location} reset={reset} />} />
            </Switch>
          </div>
        </section>
      </div>
    );
}
function RenderLinkPage({ location, reset }) {

  React.useEffect(() => {
    if (reset) reset()
  }, [reset])
  
  if (location && location.state && location.state.proposal)
    return <Success />;
  else
    return <Redirect to={{ pathname: ROUTES.APP }} />
}