import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTranslation, Trans } from 'react-i18next';
import {
  HelpOutline
} from '@material-ui/icons';
import { LMTooltip } from '../../../tooltip/Tooltip';
import "./duration.selector.scss"
import './inputs.scss';
import './timeframe.selector.scss'
import InputLabel from '@material-ui/core/InputLabel';
import { NEXTS } from '../../../../utils/gen.timeframes';
import { linearize } from '../../../../worker/handlers/generate.slots';
export const TIMEFRAMES = NEXTS;

export default function DurationSelector({
  uivalue = 0, // selected values, UI speaking
  proposals = [],
  onChange,
  errorText,
  disabled = false,
  timeframes = TIMEFRAMES
}) {
  const { t } = useTranslation();

  const isValid = errorText && uivalue === -1;

  const slots = React.useMemo(() => {
    return linearize(proposals);
  }, [proposals]);

  return (
    <div className={"duration-padding timeframe-selector " + (disabled ? "disabled" : '')}>
      <InputLabel className="fake-label with-help-button">
        {t('createEvent.form.timeframe')}
        <LMTooltip
          childrenClassName="showHand"
          content={
            <>
              <p><Trans i18nKey="createEvent.form.dateAndTime.tooltip"> <span className="strong">This is where Letsmeet will create the meeting </span>— and invites will be sent from this calendar.</Trans></p>
            </>
          }
        >
          <HelpOutline />
        </LMTooltip>
      </InputLabel>
      <ToggleButtonGroup
        className={"done-input " + (isValid ? 'Mui-error' : '')}
        name={'currenttimeframe'}
        value={uivalue}
        exclusive
        onChange={(e, v) => {
          onChange({
            target: {
              name: 'currenttimeframe',
              value: v,
            }
          });

        }
        }
        aria-label="text alignment"
      >


        {
          timeframes.map((d) => (
            <ToggleButton value={d} size="large" key={d} data-testid={'frame-' + d}>
              <div className="strong">{t('createEvent.form.timeframes_' + d)}</div>
            </ToggleButton>
          ))
        }
        <ToggleButton data-testid="custom-time-toggle" value={-1} size="large" className=" custom-duration-input shrinkalittlebit"

        >
          <div className="strong">
            {(uivalue !== -1 || (slots && slots.length === 0)) && t('createEvent.form.customTimeframes')}
            {uivalue === -1 && slots && slots.length > 0 && t('createEvent.form.customTimeframesSelected', { count: slots.length })}
          </div>
        </ToggleButton>
      </ToggleButtonGroup>
      {isValid && <p className="done-Mui-error align-right" id="customduration-helper-text">{t(errorText)}</p>}
    </div>
  );
}

// Note: remove onMouseDown => race condition between click and blur (see guestSelctor)
// onMouseDown={(e, v) => {
//               onChange({
//                 target: {
//                   name: 'currenttimeframe',
//                   value: d,
//                 }
//               });

//         }
//         }
