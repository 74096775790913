import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import * as ACTIONS from '../../../actions';
import { useSnackbar } from 'notistack';
import { RocketIcon } from '../../../assets/icons';
import { PREFILL_EDIT } from '../../../reducers/proposals'
import InputBase from '@material-ui/core/InputBase';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copyLinkToClipboard from 'copy-to-clipboard';
import ROUTES from '../../../routes/routes.name'
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { isUnknownDomain } from '../../../api/validators/proposal';
import { isMobilePlateform } from '../../../utils/browser';
import { getLink } from '../../../utils/get.proposal.link';
// import {
//   useABTesting,
//   AB_TESTING_EXPERIMENTS_IDS,
//   AB_SIGN_UP_FLOW_VARIANT_1_SIMPLE_FORM,
//   AB_SIGN_UP_FLOW_VARIANT_1_COMPOSITE_FORM
// } from '../../../providers/ga.optimize';
import { SuccessRenderer202305 } from './success.202305';
import './success.scss';
/**
 * Display success popup
 * @param {Function} doClose close callback
 */
export default function SuccessToast() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // const abtest = useABTesting();
  // const abvalue = abtest(AB_TESTING_EXPERIMENTS_IDS.SIMPLE_FORM_2023_05)
  // const use_form_202305 = (abvalue === AB_SIGN_UP_FLOW_VARIANT_1_SIMPLE_FORM || abvalue === AB_SIGN_UP_FLOW_VARIANT_1_COMPOSITE_FORM);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const useNewDashboard = !isMobilePlateform();
  const { proposal, type } = location.state || {};
  const link = React.useMemo(() => {
    // must continue to support old way
    if (proposal && proposal.id) return getLink(proposal);
  }, [proposal])

  const doGenLink = React.useCallback((navigateToDash = true) => {
    /* istanbul ignore if no work */
    if (!proposal || !proposal.id || !link) return;
    dispatch({
      type: ACTIONS.ANALYTICS_GET_LINK,
      payload: {
        proposal, // TODO
      }
    });
    /* istanbul ignore else no work */
    if (copyLinkToClipboard(link)) {
      enqueueSnackbar(t('event.linkSuccess'), {
        variant: 'success', className: "snack-success",
        action: (key) => {
          return <>
            <Button onClick={() => { closeSnackbar(key) }}>
              {t('common.ok')}
            </Button>
          </>;
        }
      })
    } else {
      enqueueSnackbar(t('event.linkError'), { variant: 'error', className: "snack-error" })
    }
    // back to dashboard
    if (navigateToDash) {
      let route = ROUTES.APP;
      if (!useNewDashboard) route += "?id=" + proposal.id;
      history.push(route);
    }

  }, [proposal, link, enqueueSnackbar, useNewDashboard]);

  /* istanbul ignore if no work */
  if (!proposal || !proposal.id) return null;
  if (type !== PREFILL_EDIT) return <SuccessRenderer202305 proposal={proposal} type={type} link={link} doGenLink={doGenLink} />;
  // else do normal
  return <SuccessRenderer proposal={proposal} type={type} link={link} doGenLink={doGenLink} />;
}
function SuccessRenderer({ proposal, type, link, doGenLink }) {
  const { t } = useTranslation();
  const onlyUnknwon = React.useMemo(() => {
    if (proposal && proposal.id && proposal.invitees) {
      return proposal.invitees.filter(invitee => {
        return !isUnknownDomain(invitee);
      }).length === 0;

    } return false;
  }, [proposal])
  let title = 'createEvent.successPage.title';
  let subTitle = 'createEvent.successPage.subTitle.' + proposal.type
  let description = 'createEvent.successPage.description';
  if (type === PREFILL_EDIT) {
    // change some labels?
    title = 'createEvent.successPage.titleEdit';
    subTitle = 'createEvent.successPage.subTitleEdit.' + proposal.type
    description = 'createEvent.successPage.descriptionEdit'
  } else if (onlyUnknwon) {
    // special case
    subTitle = 'createEvent.successPage.subTitleLinkOnly.' + proposal.type
    description = 'createEvent.successPage.descriptionLinkOnly'
  }
  return (
    <div className="success-toast" data-testid="success-toast">
      <h1 className="header">{t(title)}</h1>
      <div className="illus"><RocketIcon /></div>
      <div className="sub-header">{t(subTitle)}</div>
      <div className="description"><Trans i18nKey={description}
        components={{
          b: <strong />
        }}>To maximise guest reply rates you can provide them by <b>email, SMS</b> … the link below.</Trans></div>
      {link &&
        <div className='copy-link-component'>
          <InputBase
            value={link}
            inputProps={{ 'aria-label': 'copy link' }}
            contentEditable={false}
          />
          <Button
            data-testid="copylink"
            color="primary"
            aria-label="copy link"
            onClick={doGenLink}
            startIcon={<FileCopyIcon />}>
            {t('proposal.details.actions.linkShort')}
          </Button>
        </div>}
      <Link data-testid="gotodash" to={ROUTES.APP}>{t('createEvent.successPage.toDashboard')}</Link>
    </div>
  );
}

export function SuccessBookingLnkToast() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const useNewDashboard = !isMobilePlateform();
  const { bookinglink, type } = location.state || {};
  const link = React.useMemo(() => {
    if (bookinglink && bookinglink.id) return `${window.location.origin}${bookinglink.url}`;
  }, [bookinglink])

  const doGenLink = React.useCallback(() => {
    /* istanbul ignore if no work */
    if (!bookinglink || !bookinglink.id || !link) return;
    // dispatch({
    //   type: ACTIONS.ANALYTICS_GET_BOOKING_LINK,
    //   payload: {
    //     proposal, // TODO
    //   }
    // });
    /* istanbul ignore else no work */
    if (copyLinkToClipboard(link)) {
      enqueueSnackbar(t('event.linkSuccess'), {
        variant: 'success', className: "snack-success",
        action: (key) => {
          return <>
            <Button onClick={() => { closeSnackbar(key) }}>
              {t('common.ok')}
            </Button>
          </>;
        }
      })
    } else {
      enqueueSnackbar(t('event.linkError'), { variant: 'error', className: "snack-error" })
    }
    // back to dashboard
    let route = ROUTES.APP;
    if (!useNewDashboard) route += "?bid=" + bookinglink.id;
    history.push(route);

  }, [bookinglink, link, enqueueSnackbar, useNewDashboard]);

  /* istanbul ignore if no work */
  if (!bookinglink || !bookinglink.id) return null;
  let title = 'schedulingLink.successPage.title';
  let subTitle = 'schedulingLink.successPage.subTitle';
  let description = 'schedulingLink.successPage.description';
  if (type === PREFILL_EDIT) {
    // Edition, change labels
    title = 'schedulingLink.successPage.titleEdit';
    subTitle = 'schedulingLink.successPage.subTitleEdit';
    description = 'schedulingLink.successPage.descriptionEdit'
  }
  // else do normal
  return (
    <div className="success-toast" data-testid="success-toast">
      <h1 className="header">{t(title)}</h1>
      <div className="illus"><RocketIcon /></div>
      <div className="sub-header">{t(subTitle)}</div>
      <div className="description"><Trans i18nKey={description}
        components={{
          b: <strong />
        }}>To maximise guest reply rates you can provide them by <b>email, SMS</b> … the link below.</Trans></div>
      {link &&
        <div className='copy-link-component'>
          <InputBase
            value={link}
            inputProps={{ 'aria-label': 'copy link' }}
            contentEditable={false}
          />
          <Button
            data-testid="copylink"
            color="primary"
            aria-label="copy link"
            onClick={doGenLink}
            startIcon={<FileCopyIcon />}>
            {t('proposal.details.actions.linkShort')}
          </Button>
        </div>}
      <Link data-testid="gotodash" to={ROUTES.APP}>{t('createEvent.successPage.toDashboard')}</Link>
    </div>
  )
}