import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import {
  IllusOK,
  IllusDelete,
  IllusCancel
} from '../../../assets/icons'
import './event.states.scss'
import i18next from 'i18next';
export function CancelledEvent({ isOrganizer = false }) {
  const { t } = useTranslation();
  const keyTitle = "event.state.cancelled.title";
  const keySubtitle = "event.state.cancelled.subtitle";
  const keySubSubtitle = isOrganizer ? "event.state.cancelled.hostMessage" : "event.state.cancelled.guestMessage";
  return (
    <EventStatusPanel
      illus={<IllusCancel />}
      title={t(keyTitle)}
      subtitle={t(keySubtitle)}
      subsubtitle={t(keySubSubtitle)}
    />
  );
}
export function ConfirmedEvent({ date }) {
  const { t } = useTranslation();
  const keyTitle = "event.state.confirmed.title";
  const keySubtitle = "event.state.confirmed.subtitle";
  return (
    <EventStatusPanel
      illus={<IllusOK />}
      title={t(keyTitle)}
      subtitle={date ? t(keySubtitle, { date: moment(date).format('LLLL') }) : t("event.state.confirmed.subtitleNoDate")}
    />
  );
}
export function NoAviabilityEvent({ isOrganizer = false }) {
  const { t } = useTranslation();
  const keyTitle = "event.state.no_aviability.title";
  const keySubtitle = "event.state.no_aviability.subtitle";
  const keySubSubtitle = isOrganizer ? "event.state.no_aviability.hostMessage" : "event.state.no_aviability.guestMessage";
  return (
    <EventStatusPanel
      illus={<IllusDelete />}
      title={t(keyTitle)}
      subtitle={t(keySubtitle)}
      subsubtitle={t(keySubSubtitle)}
    />
  );
}

const EventStatusPanel = ({ illus, title, subtitle, subsubtitle }) => (
  <div className="state-event">
    {illus}
    <div className="state-message">
      {title &&
        <div className="title">
          {title}
        </div>
      }
      {subtitle &&
        <div className="subtitle">
          {subtitle}
        </div>}
      {subsubtitle &&
        <div className="subsubtitle">
          {subsubtitle}
        </div>}
    </div>
  </div >
);