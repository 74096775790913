import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import * as ACTIONS from '../actions';
// for fun, we load dumb datas
const defaultState = {};

const reducer = handleActions(
  {
    [ACTIONS.WORKER_CONNECT_SUCCESS]: (state, action) => {
      return { ...state, ...(action.payload.contacts || defaultState) }; // there can already been some contacts there!
    },
    [ACTIONS.WORKER_REFRESH_ACCOUNTS_SUCCESS]: (state, action) => {
      return { ...state, ...(action.payload.contacts || defaultState) };
    },
    [ACTIONS.WORKER_UNREGISTER_ACCOUNT_SUCCESS]: (state, action) => {
      return { ...state, ...(action.payload.contacts || defaultState) };
    },
    [ACTIONS.WORKER_GET_PROFILE_SUCCESS]: (state, action) => {
      let profile = action.payload;
      /* istanbul ignore else no work */
      if (profile) {
        let old = state[profile.email] || profile;
        state[profile.email] = {
          ...old,
          ...profile,
          isDoner: true,
          // test only
          name: old.name || profile.lastname + " " + profile.firstname
        };
        return { ...state }
      }
    },
    [ACTIONS.WORKER_GET_PROFILES_SUCCESS]: (state, action) => {
      let profiles = action.payload;
      /* istanbul ignore else no work */
      if (profiles) {
        for (let profile of profiles) {
          if (!profile || !profile.email) continue;

          let old = state[profile.email] || profile;
          state[profile.email] = {
            ...old,
            ...profile,
            isDoner: true,
            // test only
            name: old.name || profile.lastname + " " + profile.firstname
          };
        }
        return { ...state }
      }
    },
    [ACTIONS.WORKER_GET_PROPOSAL_SUCCESS]: (state, action) => {
      // add contacts if exists
      if (action.profiles) {

        return {
          ...state,
          ...action.profiles.reduce((acc, profile) => {
            if (profile && profile.email) {
              profile.isDoner = true
              acc[profile.email] = profile;
            }

            return acc;
          }, {}),
        }
      } else return state;
    },
    [ACTIONS.WORKER_GET_UNKNWON_PROFILE]: (state, action) => {
      // add contacts if exists
      console.log("ADD UNKNOWN CONTACT", action.profile)
      if (action.profile) {
        return {
          ...state,
          [action.profile.email]: action.profile,
        }
      } else return state;
    },
    [ACTIONS.WORKER_GET_PROFILES_SUCCESS]: (state, action) => {
      let profiles = action.payload;
      /* istanbul ignore else no work */
      if (profiles) {
        for (let profile of profiles) {
          if (!profile || !profile.email) continue;
          let old = state[profile.email] || profile;
          state[profile.email] = {
            ...old,
            ...profile,
            isDoner: true,
            // test only
            name: old.name || profile.lastname + " " + profile.firstname
          };
        }
        return { ...state }
      }
    },
    [ACTIONS.WORKER_GET_PROFILES_ERROR]: (state, action) => {
      let emails = action.payload || [];
      emails.forEach(email => {
        let existingOrNewContact = state[email] || { email: email };
        state[email] = {
          ...existingOrNewContact
        }
        return { ...state }
      });
    },
    [ACTIONS.WORKER_GET_PROFILE_ERROR]: (state, action) => {
      let email = action.payload;
      /* istanbul ignore else no work */
      if (email) {
        let existingOrNewContact = state[email] || { email: email };
        state[email] = {
          ...existingOrNewContact
        }
        return { ...state }
      }
    },
    [ACTIONS.WORKER_LOGOUT]: () => defaultState,

    [ACTIONS.INVALID_CREDENTIALS]: () => defaultState,

    [ACTIONS.ON_NEW_CONTACTS]: (state, actions) => {
      let olds = { ...state };
      for (let cnt of Object.values(actions.payload || {})) {
        olds[cnt.email] = {
          ...cnt,
          ...(olds[cnt.email] || {}),
        }
      }
      return olds;
    },
  },
  defaultState
);


export default reducer;

export const getContacts = (state) => state.contacts;
export const getContact = (email) => (state) => {

  if (state.user && email === state.user.email) return { ...state.user, isDoner: true };
  // if email is one of user account, return account
  if (state.accounts) {
    let acc = state.accounts.find((a) => a.id === email);
    if (acc) {
      return {
        ...state.user,
        ...acc,
        isDoner: true
      };
    }
  }
  let c = state.contacts[email];
  if (!c && email) {
    // create a dumb contact?
    return {
      email: email,
      external: true, // not a doner?
    }
  } else return c;
}
export const getSortedContacts = createSelector(
  getContacts,
  (contacts) => {
    return Object.values(contacts || {})
      .sort((a, b) => ((a.name || a.email) < (b.name || b.email) ? -1 : 1))
  }
)

export const someInviteesAreNotLMUsers = (invitees) => createSelector(
  getContacts,
  (contacts) => {
    // if no guest (ie: the host), return true
    if (invitees.length === 0) return true;
    for (let invitee of invitees) {
      if (!contacts[invitee] ||
        !contacts[invitee].isDoner) return true;
    }
    return false;
  }
)