import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'

import './LoadingButton.scss'

/**
 * Simple loading button that shows a loader based on isLoading param
 * 
 * @param {boolean} isLoading true to show loader
 * @param {children} Component button text is taken from the children
 * @param {{}} ...props Any other props are forwarded to the Button
 * @returns 
 */
 export const LoadingButton = ({ isLoading, children, ...props }) => {
    if(props.className) props.className += ' LoadingButton ';
    return (
        <Button {...props}
            startIcon={isLoading ? <CircularProgress size="1rem" /> : null}>
            {children}
        </Button>
    )
}
