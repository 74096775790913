import * as Yup from 'yup';

export const STATS = Yup.object({
  total_saved_time: Yup.number().default(0),
  complete_proposal_count: Yup.number().default(0),
  usage: Yup.array().of(Yup.object({
    date: Yup.date().required(),
    total: Yup.number(),
    year: Yup.number(),
    month: Yup.number(),
    day: Yup.number(),
  }))
});