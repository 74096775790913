/* istanbul ignore file */
import React from 'react';
// import * as Sentry from '@sentry/browser';

export  class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            hasError: false
        }


    }
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
        // send error to Sentry
        /*Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
        });*/
    }
    static getDerivedStateFromError(error) {
        // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
        return { hasError: true, error: error };
    }
    render() {
        const { error, hasError } = this.state;
        const ErrorCmp = this.props.ErrorCmp
        if (hasError) {
            return ErrorCmp ?
                <ErrorCmp  error={error} {...this.props} /> :
                <div>
                    <h2>Error</h2>
                    <p>{this.state.error && this.state.error.toString()}</p>
                    <br />
                    
                </div>
        } else {
            return this.props.children
        }
    }
}