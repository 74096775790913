import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAccountMails, getAccountsIds } from '../../../../reducers/accounts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Slot from './slot';
import * as ACTIONS from '../../../../actions'
import DoneButton from '../../../DoneButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close';
import { throttle } from '../../../../utils/throttle';
import { notYetAnswered } from '../../../../utils/all.mandatory.answered';
import { Loader } from "../../../Loader";
import { BROWSER } from '../../../../utils/browser';
import NOOP from '../../../../utils/noop';
import './slots.scss';
import { getAppTZ } from '../../../../reducers/app';
import { overlapp } from '../../../../worker/handlers/generate.slots';
import { lastToVote } from '../anonymous';
import { GuestHeatmap } from '../anonymous/Slots';
const EMPTY = {};
const EMPTY_STYLE = 0;
const DISPLAY_SLOTS = 0, DISPLAY_DECLINE = 1, DISPLAY_MORE = 2;

////// IMPORTANT ///////////
// in order to known how/when to redraw, we assume heights are constants
// if height get changed (via css for exemple), we MUST update those values here
const HEADER_HEIGHT = 19 + 10 + 20; // date height in px
const ELEM_HEIGHT = 60; // in px, height of 1 slot element
const ELEM_WIDTH = 200 + 10; // in px
const CONTAINER_PADDING = 48; // in px
const BREAKPOINT_LG = 927, BREAKPOINT_MD = 768, BREAKPOINT_XS = 0; // breakpoints in pixels

const getBreakpointRowsLength = w => {
  let width = document.body.clientWidth || 200;
  // case mobile, 1 by line: problem if not on mobile
  if (width <= 767) return 1;
  // if (width > 767) width *= 0.6
  // problem: width is not set yet to final?
  let s = Math.floor((w - CONTAINER_PADDING) / ELEM_WIDTH);
  return s;
};
export function Slots({ proposal = EMPTY, onClose = NOOP, forVote = false }) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const timezone = useSelector(getAppTZ);
  // initialisation is problematic
  const [breakpoint, setbreakpoint] = React.useState(BREAKPOINT_LG);
  const [dlgState, setdlgState] = React.useState(DISPLAY_SLOTS);
  const accountsEmails = useSelector(getAccountMails);
  const accountsIds = useSelector(getAccountsIds);
  const topRef = React.useRef();
  const iAmHost = proposal.iAmHost;
  const registeredWhenProposalWasCreated = accountsEmails.find(userEmail => {
    if (!proposal.inviteesDetails) return false;
    return (proposal.inviteesDetails[userEmail] || {}).registered;
  });
  const [topFakeStyle, setTopFakeStyle] = React.useState(0);
  const userDetails = React.useMemo(() => {
    if (!proposal || !proposal.inviteesDetails) return undefined;
    let details = Object.values(proposal.inviteesDetails)
      .find((d) => accountsEmails.find((ae) => ae === d.email));
    // decorate
    if (details && !proposal.iAmHost) {
      const isFunnel = proposal.type === 'funnel';
      const lastToVote = notYetAnswered(proposal).length === 1;
      const requiredToVote = details.vote_requested;
      const hasconflicts = details.conflicting_slots && details.conflicting_slots.length > 0;
      // I am last to vote?
      // Do I have conflicting slots?
      details.meta = {
        isFunnel,
        lastToVote,
        requiredToVote,
        hasconflicts
      }
    }
    return details || {};
  }, [proposal, accountsEmails]);
  // set slots
  const slots = React.useMemo(() => {
    /* istanbul ignore if no work */
    if (!proposal) return [];
    const iAmHost = proposal.iAmHost;
    const slots = iAmHost ? proposal.slots : proposal.remaining_slots;
    /* istanbul ignore if no work */
    if (!slots || slots.length === 0) return []; // or a no slot component?
    // If i a not host, take the selectedByValues?
    const conflicts = userDetails ? userDetails.fb /*conflicting_slots*/ || [] : [];
    // am I the last one to vote?

    const isLast = lastToVote(proposal, proposal.me);
    return slots.reduce((acc, v) => {
      acc.push(...v.slots.map((vs) => {
        if (iAmHost) return false;
        if (isLast) return false; // last in funnel is radiobox
        return vs.IAmFree;
      }));
      return acc;
    }, []);
  }, [proposal, userDetails]);
  const confirmEvent = React.useCallback((event, opts) => {
    /* istanbul ignore if */
    if (loading) return;
    setLoading(true)
    dispatch({
      type: ACTIONS.WORKER_VOTE_PROPOSAL,
      payload: {
        proposal: event,
        opts: opts,
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_VOTE_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_VOTE_PROPOSAL_ERROR
      }
    }).then((res) => {
      // dispatch({
      //   type: ACTIONS.ANALYTICS_PROPOSAL_ANSWERED,
      //   payload: {
      //     answeredSlots: 
      //   }
      // })
    }).catch(/* istanbul ignore next */(err) => {
      // error message


      enqueueSnackbar(t('event.errors.couldNotVote'), {
        variant: "error"
      })
    }).then(() => {
      setLoading(false);
      onClose()
    })
  }, [loading]);
  const createEvent = React.useCallback((event, opts) => {
    /* istanbul ignore if */
    if (loading) return;
    setLoading(true)
    dispatch({
      type: ACTIONS.WORKER_CREATE_PROPOSAL,
      payload: {
        proposal: event,
        opts: opts,
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_CREATE_PROPOSAL_SUCCESS,
        rejectOn: ACTIONS.WORKER_CREATE_PROPOSAL_ERROR
      }
    }).then(() => {
      // reload datas?
    }).catch((err) => {
      // error message
      /* istanbul ignore if storybook */
      enqueueSnackbar(t('event.errors.couldNotVote'), {
        variant: "error"
      })
    }).then(() => {
      setLoading(false);
      onClose()
    })
  }, [loading]);
  const cancelEvent = React.useCallback(() => {
    if (loading) return;
    setLoading(true)
    dispatch({
      type: ACTIONS.WORKER_CANCEL_EVENT,
      payload: proposal,
      resolvers: {
        resolveOn: ACTIONS.WORKER_CANCEL_EVENT_SUCCESS,
        rejectOn: ACTIONS.WORKER_CANCEL_EVENT_ERROR,
      }
    }).catch((err) => {
      let payload = err ? err.payload : {};
      let status = payload ? payload.status : 500;
      let label = 'errors.couldNotCancelMeeting';
      if (status === 403) {
        label = 'errors.couldNotCancelMeetingNotAllowed';
      } else if (status === 404) {
        label = 'errors.couldNotCancelMeetingNotFound';
      } else if (status === 409) {
        label = 'errors.couldNotCancelMeetingConflict';
      }
      enqueueSnackbar(t(label), {
        variant: 'error',
        preventDuplicate: true,
        className: 'snack-error',
      })
    }).then(() => {
      // meeting is cancelled, reload and quit
      setLoading(false);
      onClose()
    })
  }, [proposal]);
  const declineEvent = React.useCallback(() => {
    // do vote for datas
    confirmEvent([], { id: proposal.id }); // decline is just answering empty array?
  }, [proposal, confirmEvent]);
  const onVote = React.useCallback((votes, proposedNew = false) => {
    // set proposal slots
    // only got an array of true/false to remap to slots
    if (iAmHost) {
      let slots = proposal.slots.reduce((acc, v) => {
        acc.push(...v.slots);
        return acc;
      }, []);
      for (let i = 0; i < slots.length; i++) {
        slots[i].vote = votes[i];
      }
      // create meeting
      createEvent(proposal, { id: proposal.id, userAccounts: accountsIds })
    } else {
      let slots = [];
      if (proposedNew) {
        slots = votes.map((sl) => {
          return {
            start: sl.start.toISOString(),
            end: sl.end.toISOString(),
            vote: true
          }
        })
      } else {
        slots = proposal.remaining_slots.reduce((acc, v) => {
          acc.push(...v.slots);
          return acc;
        }, []);
        for (let i = 0; i < slots.length; i++) {
          slots[i].vote = votes[i];
        }
      }
      // do vote for datas
      confirmEvent(slots, { id: proposal.id, userEmail: accountsEmails, userAccounts: accountsIds, });
    }

  }, [proposal, iAmHost, confirmEvent, createEvent, accountsEmails, accountsIds]);
  const formik = useFormik({
    initialValues: {
      slots: slots,
    },
    // validationSchema: EventSchemaStep1,
    enableReinitialize: true,
    onSubmit: (values) => {
      // check at least 1 slot selected
      let isValid = values.slots.find((v) => v);
      const iAmHost = proposal.iAmHost;
      /* istanbul ignore if should not happen? */
      if (!isValid) {
        // show errir
        let label = iAmHost ? 'event.errors.noSlotSelectedForConfirm' : 'event.errors.noSlotSelected'
        enqueueSnackbar(t(label), {
          variant: 'error',
          preventDuplicate: true,
          className: 'snack-error',
          action: (key) => {
            return <>
              <Button onClick={() => { closeSnackbar(key) }}>
                {t('common.ok')}
              </Button>
            </>;
          }
        })
        return;
      }
      // dispatch to state
      onVote(values.slots)
    }
  });

  // const isMobile = BROWSER.getPlatformType() === 'mobile' || BROWSER.getPlatformType() === 'tablet';
  const handleChange = React.useCallback((e) => {
    const iAmHost = proposal.iAmHost;
    const isLast = lastToVote(proposal, proposal.me);
    if (iAmHost || isLast) {
      // clear all?
      formik.setFieldValue('slots', slots);
    }
    formik.handleChange(e);
  }, [slots, proposal.iAmHost,
    formik.handleChange, formik.setFieldValue]);

  // actions for form
  const doDecline = React.useCallback((e) => {
    // ask for confirmation
    setdlgState(DISPLAY_DECLINE);
    setTopFakeStyle(EMPTY_STYLE);
  }, []);
  const confirmSlotsButtonDisabled = React.useMemo(() => {
    for (let i = 0; i < formik.values.slots.length; i++) {
      let slot = formik.values.slots[i];
      if (slot) { return false; }
    };
    return true;
  }, [formik.values.slots]);
  /* istanbul ignore next no resize event on UT */
  React.useEffect(() => {
    let curr = topRef.current
    if (!curr) return;
    let w = curr.getBoundingClientRect().width;
    setbreakpoint(w)
    const doResize = (e) => {
      let w = curr.getBoundingClientRect().width;
      setbreakpoint(w)
    };
    window.addEventListener('resize', doResize);
    return () => window.removeEventListener('resize', doResize);
  }, [topRef]);
  /* istanbul ignore next no scrolling test */
  React.useEffect(() => {
    if (!topRef.current) return;
    let curr = topRef.current; // add t closure
    const doScroll = throttle((e) => {
      // check position top of fake scroll
      let elem = document.getElementById("top-scroll-fake");
      if (!elem) return;
      let topbounds = elem.getBoundingClientRect();
      if (topbounds.top < 0) {
        // means, list start beeing out of window
        // set new height
        let nh = Math.ceil(-topbounds.top / ELEM_HEIGHT) * ELEM_HEIGHT;
        setTopFakeStyle(nh);
      } else {
        if (topFakeStyle != EMPTY_STYLE) setTopFakeStyle(EMPTY_STYLE)
      }
    }, 20);
    curr.addEventListener('scroll', doScroll);
    return () => curr.removeEventListener('scroll', doScroll);
  }, [topRef.current, topFakeStyle]);
  // listen to dialog state changes and add/remove full screen class
  // note: full screen is necessary to compute elem in rows
  React.useEffect(() => {
    let dlg = document.querySelector("[role='dialog']");
    if (dlg) {
      if (dlgState !== DISPLAY_SLOTS) {
        dlg.classList.remove("MuiDialog-paperFullWidth");
      } else {
        dlg.classList.add("MuiDialog-paperFullWidth"); // will omit if already present
      }
    }
  }, [dlgState]);

  // memo do not recalculate on height change...
  const { title, actions, elems } = React.useMemo(() => {
    // virualize list depending on day-slots?
    let elems = [], actions = [];

    switch (dlgState) {
      case DISPLAY_SLOTS: {
        if (!topRef.current) return { elems, actions };
        // get dimensions to calculate slots in row
        // let { top } = topRef.current.getBoundingClientRect();
        let top = - topFakeStyle;
        let scroller = topRef.current.getBoundingClientRect();

        let width = scroller.width || document.body.clientWidth || 200;
        let height = document.body.clientHeight || 800; // for UT
        let slotInRows = getBreakpointRowsLength(width);
        // virtualisation init
        let count = -1;
        let pos = top; // position of 1st element in pixels
        let topHeight = 0; // heights of fakers div
        let bottomHeight = 0;

        const MIN_POS_X = -1 * ELEM_HEIGHT;
        const MAX_POS_X = height + 1 * ELEM_HEIGHT;

        const iAmHost = proposal.iAmHost;
        const isLast = lastToVote(proposal, proposal.me);
        let sls = iAmHost ? proposal.slots : proposal.remaining_slots;
        if (sls) {

          for (let i = 0; i < sls.length; i++) {
            let pro = sls[i];

            // calculate height of day depending on number of slots
            let dayHeight = (Math.ceil(pro.slots.length / slotInRows) * ELEM_HEIGHT) + HEADER_HEIGHT;
            if (pos + dayHeight < MIN_POS_X) {
              // add to top
              topHeight += dayHeight;
              pos += dayHeight;
              count += pro.slots.length;
              continue;
            } else if (pos > MAX_POS_X) {
              // add to bottom
              bottomHeight += dayHeight;
              pos += dayHeight;
              count += pro.slots.length;
              // should not continue, find a way to kill rendering
              // precalculate all left?
              continue;
            }
            pos += dayHeight;

            elems.push(
              <div key={pro.day} className="slots-proposal-day-slots">
                <div className="slots-proposal-day-date">
                  {moment(pro.day).format(t('common.dayFormat'))}
                  <span className="timezone">{" " + moment().format("[UTC]Z") + " " + timezone}</span>
                </div>
                <div className="day-slots">
                  <DaySlot id={proposal.id} slots={pro.slots} values={formik.values.slots}
                    handleChange={handleChange}
                    iAmHost={iAmHost}
                    count={count}
                    proposal={proposal}
                    userDetails={userDetails}
                    isLastVoter={isLast} />
                </div>
              </div>);
            count += pro.slots.length;

          }
        }
        // add fake divs
        elems.unshift(<div key="top-scroll-fake" id="top-scroll-fake" style={{ height: topHeight }}></div>);
        elems.push(<div key="bottom-scroll-fake" style={{ height: bottomHeight }}></div>);
        const someAreUnknown = Object.values(proposal.inviteesDetails || {}).find((d) => d.isUnknown);
        actions = iAmHost ?
          [
            null,
            someAreUnknown ? <><span className="warning">{t('proposal.slots.warningUnknwon')}</span><DoneButton key="confirm" name={"btn-vote-unknown"} className="no-margin small"
              label={t('createEvent.form.sendInviteWithoutUnknown')} onClick={formik.handleSubmit} disabled={confirmSlotsButtonDisabled} /></> : <DoneButton key="confirm" name={"btn-vote"} className="no-margin small"
                label={t('createEvent.form.sendInvite')} onClick={formik.handleSubmit} disabled={confirmSlotsButtonDisabled} />,
          ] : [
            <DoneButton key="decline" label={t('event.decline')} name={"btn-decline"}
              className="no-margin grey small decline"
              // className="white secondary"
              onClick={doDecline} />,
            <DoneButton key="vote" name={"btn-vote"} className="no-margin small"
              label={t('event.buttonConfirmAvailabilityRegistered')} onClick={formik.handleSubmit} />,
          ];
        //labels depending on what is happening
        let title = iAmHost ? 'proposal.slots.titleHost1' : forVote ? 'proposal.slots.titleNotHostVote' : 'proposal.slots.titleNotHost1';
        let subtitle = iAmHost ? t('proposal.slots.titleHost2') : t('proposal.slots.titleNotHost2');
        if (!iAmHost && forVote && userDetails) {
          // need to check subtitle
          const {
            isFunnel,
            lastToVote,
            requiredToVote,
            hasconflicts
          } = userDetails.meta;
          const organizer = proposal.organizer || {};
          subtitle = `proposal.slots.titleNotHost${isFunnel ? 'Funnel' : 'Poll'}${hasconflicts ? 'Conflicts' : 'Free'}${lastToVote ? 'Last2Vote' : 'Vote'}`; // default one
          subtitle = t(subtitle, { host: organizer.label || organizer.email });
          // set actions
          actions = [
            <DoneButton key="decline" label={t('event.decline')} name={"btn-decline"}
              className="no-margin grey small decline"
              // className="white secondary"
              onClick={doDecline} />,
            <DoneButton key="vote" name={"btn-vote"} className="no-margin small"
              label={t((isFunnel && lastToVote) ? 'event.buttonConfirmAvailabilityRegisteredLast2Vote' : 'event.buttonConfirmAvailabilityRegistered')} onClick={formik.handleSubmit} />,
          ];
        }
        return { title: <div className="double"><div>{t(title)}</div><div>{subtitle}</div></div>, elems, actions };
      }
      case DISPLAY_DECLINE: {
        elems = <span className="message">{t(proposal.are_slots_suggestion_allowed ? 'event.declineDlg.messageProposeNewSlots' : 'event.declineDlg.message')}</span>;
        actions = [
          <Button key="decline-dismiss" onClick={() => setdlgState(DISPLAY_SLOTS)} color="secondary" data-testid="cancel-event-dialog-cancel">
            {t(proposal.are_slots_suggestion_allowed ? 'event.declineDlg.dismiss' : 'common.no')}
          </Button>,
          <Button key="decline-ok" onClick={declineEvent} color="primary" autoFocus data-testid="cancel-event-dialog-ok">
            {t(proposal.are_slots_suggestion_allowed ? 'event.declineDlg.ok' : 'common.yes')}
          </Button>,
          proposal.are_slots_suggestion_allowed ? <Button key="decline-more" onClick={() => setdlgState(DISPLAY_MORE)} color="primary" data-testid="cancel-event-dialog-more">
            {t('event.declineDlg.proposeNewSlots')}
          </Button> : null,
        ];
        return { title: t(proposal.are_slots_suggestion_allowed ? 'event.declineDlg.title' : 'event.declineDlg.titleAreYouSure'), elems, actions };
      }
      case DISPLAY_MORE: {
        elems = <GuestHeatmap proposal={proposal} onValid={(slots) => onVote(slots, true)} onClose={() => setdlgState(DISPLAY_SLOTS)} />;
        actions = null;
        return { title: t('event.proposeNewSlots.title'), elems, actions };
      }
      default:
        return {};
    }


  }, [slots, topRef.current, confirmSlotsButtonDisabled,
    formik.values, proposal.iAmHost, proposal.id, proposal.are_slots_suggestion_allowed, registeredWhenProposalWasCreated, dlgState, cancelEvent, declineEvent,
    breakpoint, // to recalculate based on window size
    topFakeStyle, // on scroll event
    userDetails,
    forVote,
  ]);

  return <>
    <DialogTitle id="alert-dialog-title" onClose={onClose} className="slots-dlg-title">
      <div>{title}</div>
    </DialogTitle>
    {elems &&
      <DialogContent id="top-scroll" ref={topRef} dividers={true} className="slots-proposal-day">
        {
          elems
        }
      </DialogContent>}
    {actions && <DialogActions className="slots-dlg-actions">
      {actions}
      {loading && <Loader />}
    </DialogActions>}
  </>
};
export const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other} className={"dialog-with-close-btn " + (other.className ? other.className : "")}>
      <h2>{children}</h2>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

// memoise day slot for re-usage
function _DaySlot({
  id,
  slots,
  values,
  iAmHost,
  handleChange,
  count,
  proposal,
  userDetails,
  isLastVoter = false,
}) {
  let items = React.useMemo(() => {
    if (!slots || slots.length === 0) return null;

    return slots.map((slot, i) => {
      count++;
      // get next item position depending on how many by row
      // check if slot is forced
      let isHostForced = proposal.iAmHost && proposal.ignore_initial_avail && !slot.IAmFree
      let isForced = !!(!proposal.iAmHost && !slot.IAmFree)
      let isMultiple = true;
      if (proposal.iAmHost || isLastVoter) isMultiple = false;

      return (<div className="proposal-items" key={"slot-" + id + '-' + count}>
        <Slot
          slot={slot}
          value={values[count]}
          name={'slots[' + count + ']'}
          onChange={handleChange}
          multiselect={isMultiple}
          proposal={proposal}
          isForced={isForced ? userDetails.email : isHostForced ? true : ''}
        />
      </div>);
    });
  }, [slots, values, handleChange]);
  return items;
}
const DaySlot = React.memo(_DaySlot, (prev, next) => {
  // check if must render
  if (prev.iAmHost !== next.iAmHost) return false; // force rerender
  // check if a value in range changed
  let old = prev.values;
  let nw = next.values;
  let start = prev.count + 1;
  let end = start + (prev.slots || []).length;
  for (let i = start; i < end; i++) {
    if (old[i] !== nw[i]) {
      return false;
    }
  }
  return true;
});

function getPeriod(slots = []) {
  if (slots.length === 0) return;
  let first = slots[0];
  let last = slots[slots.length - 1];

  first = first.slots ? first.slots[0] : {};
  last = last.slots ? last.slots[last.slots.length - 1] : {};
  return {
    start: first.start,
    end: last.end
  }
}
export default function SlotsWithFB({ proposal = EMPTY, onClose = NOOP, forVote = false }) {
  const dispatch = useDispatch();
  const accountsEmails = useSelector(getAccountMails);
  const accountsIds = useSelector(getAccountsIds);
  const [isLoadingFB, setIsLoadingFB] = React.useState(true);
  const [updatedProposal, setUpdatedProposal] = React.useState(undefined)
  React.useEffect(() => {
    if (!proposal) return;
    let details = Object.values(proposal.inviteesDetails)
      .find((d) => accountsEmails.find((ae) => ae === d.email));

    if (details && !proposal.iAmHost) {
      dispatch({
        type: ACTIONS.WORKER_GET_PROPOSAL,
        payload: {
          id: proposal.id,
          userEmail: accountsEmails,
          userAccounts: accountsIds,
        },
        resolvers: {
          resolveOn: ACTIONS.WORKER_GET_PROPOSAL_SUCCESS,
          rejectOn: ACTIONS.WORKER_GET_PROPOSAL_ERROR
          // requestId: uuid.v4(),    //  ----> @TODO cancel proposals on replay (after merge heatmap)
        }
      }).then((rep) => {
        if (!rep || !rep.payload || rep.payload.length === 0) return;
        proposal = rep.payload[0];
        const periods = getPeriod(proposal.remaining_slots)
        if (!periods || !periods.start || !periods.end) return;

        const start = periods.start;
        const end = periods.end;
        return dispatch({
          type: ACTIONS.WORKER_FREE_BUSY,
          payload: {
            invitees: [details.email],
            period: {
              start,
              end
            },
          },
          resolvers: {
            resolveOn: ACTIONS.WORKER_FREE_BUSY_SUCCESS,
            rejectOn: ACTIONS.WORKER_FREE_BUSY_ERROR
          }
        });
      }).then((fb) => {
        // decorate FB
        let details = Object.values(proposal.inviteesDetails)
          .find((d) => accountsEmails.find((ae) => ae === d.email));
        details.fb = fb.payload;
        // decorate remaining slots
        for (let day of proposal.remaining_slots) {
          if (day && day.slots) {
            for (let sl of day.slots) {
              sl.IAmFree = !overlapp(sl, details.fb);
            }
          }
        }
        setUpdatedProposal(proposal)
      }).catch((err) => {
        details.fb = [];
      }).finally(() => {
        setIsLoadingFB(false);
      })
    }
  }, []);
  if (isLoadingFB) return <FBLoader />;
  return <Slots proposal={updatedProposal} onClose={onClose} forVote={forVote} />

}

function FBLoader() {
  return <div className="slots-fb-loading">
    <CircularProgress />
  </div>
}