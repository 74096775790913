/* istanbul ignore file development tools */
/**
 * @fileoverview Dev settings hook for application
 * @module tools
 */ 
import React from 'react';
// import DoneDB from 'util/DoneDatabase';
/**
 * @property localstorage key for dev settings
 */
const FLAGS_KEY = 'lm_flags'; // python way :)
/**
 * @property dev settings property names
 */
 const FLAGS = {
    PROPOSE_CONTACT_POPUP: 'proposal_contact_popup',
}
let FLAGS_SETTINGS = {
    [FLAGS.PROPOSE_CONTACT_POPUP]: true,
};
// load dev Setting from localStorage
try { FLAGS_SETTINGS = JSON.parse(localStorage.getItem(FLAGS_KEY)) || FLAGS_SETTINGS; } catch (err) {}

const useFlags = () => {
    let [ds, setDs] = React.useState(FLAGS_SETTINGS);
    // a bunch of actions todo
    
    // save devSettings to localstorage
    const setFlagSetting = React.useCallback((name, value) => {
        ds[name] = value;
        FLAGS_SETTINGS = ds;
        localStorage.setItem(FLAGS_KEY, JSON.stringify(ds));
        setDs(Object.assign({}, ds));
    },[ds]);
    const getFlagSetting = React.useCallback((name) => {
      if (ds[name] !== undefined) return ds[name];
    },[ds]);
    return {
        setFlagSetting,
        getFlagSetting,
        FLAGS,
    };

}
export default useFlags;