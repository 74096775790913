import moment from 'moment';
export function isSameSlot(s, s2) {
  return s.start === moment(s2.start).valueOf() && s.end === moment(s2.end).valueOf()
}
export function isOneOfSlots(s, s2 = []) {
  let slot = {
    start: moment(s.start).valueOf(),
    end: moment(s.end).valueOf(),
  }
  return !!s2.find((sl) => isSameSlot(slot, sl))
}