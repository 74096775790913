import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import { durationFormatter } from '../../../utils/duration.formatter';
import { conferenceUrlCompat } from '../../../utils/compatibility.conference';
import TextBlock from '../../TextBlock';
import Conference from '../create-event/recap/conference';
import Avatar from '../../avatar';
import {
  ClockSetTime,
  LinkNote,
  MapPineLine,
} from '../../../assets/icons';
import "./link.content.scss";

export default function BookingContent({
  link
}) {
  const { t } = useTranslation();
  const styles = useSpring({
    to: [
      { opacity: 1 },
    ],
    from: { opacity: 0 },
    delay: 300
  });

  let content = null;
  if (!link || !link.is_link) {
    content = null;
  } else {

  }
  return <animated.div style={styles}>
    <LinkDetails link={link} />
  </animated.div>;
}

function LinkDetails({ link }) {
  const { t } = useTranslation();
  const [notesExpanded, expandNotes] = useState(false);

  if (!link || !link.is_link) {
    return null;
  }


  const duration = link.duration === -1 ? durationFormatter(link.customduration, t) : durationFormatter(link.duration, t)
  const { conference_url, location_txt } = conferenceUrlCompat(link)
  const conference = link.conference ? <Conference id={link.conference} conference_url={conference_url} error={link.__conference_not_generated} />
    : null;
  const location = location_txt ? <TextBlock txt={location_txt} /> : null

  return (<>
    <div className="LinkInfo" data-testid="link-info">
      {link.company && link.company.logo_url && <img className="link_company_logo" src={link.company.logo_url} />}
      <div className="from"><Organizer link={link} /></div>
      <div className="title">{link.title}</div>
      <div className="duration withicon"><ClockSetTime />{duration}</div>
      {link.notes && <div className="withicon desktop-only"><LinkNote /><span className="notes fancy-scroll"><TextBlock txt={link.notes} /></span></div>}
      {link.notes && <div className="withicon mobile-only " onClick={() => expandNotes(!notesExpanded)}><LinkNote /><span className="notes fancy-scroll"><TextBlock txt={notesExpanded ? link.notes : link.notes.substring(0, 100) + '...'} /></span></div>}
      {location && <div className="location location-text withicon"><MapPineLine />{location}</div>}
      {conference && <div className="location">{conference}</div>}
    </div ></>)
}
// Organizer: name + avatar_url?
function Organizer({ link }) {
  if (!link || !link.organizer) return null;
  const organizer = link.organizer;
  return <span className="link-organizer"><Avatar name={organizer.name}
    className="link-avatar"
    fallBackColor="#7060DF"
    imageSrc={organizer.avatar_url} /><span className="link-organizer-name">{organizer.name}</span></span>;
}