import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../../api/validators/proposal';
import routes from '../../../../routes/routes.name';
import Promote from './Promote';
import { allMandatoryAnswered } from '../../../../utils/all.mandatory.answered';
const EMPTY = {};

export default function ProposalStatus({ proposal = EMPTY, isVoting, email }) {

  const { t } = useTranslation();

  let title = null;
  let subTitle = null;
  let cta = null;
  const isPoll = proposal.type === 'poll';
  if (proposal.status === STATUS.NO_AVIABILITY
    // User hasn't voted
    && hasNotVoted(proposal, email)
    // all possible slots are in the past
    && allSlotsExpired(proposal)) {
    title = t('event.state.no_aviability_all_slots_expired.title');
    subTitle = null;
    cta = <Promote
      promoteTextKey='event.anonymous.promoteTextPlan'
      ctaTextKey='event.anonymous.promoteCTAPlan'
      ctaLink={'/app' + routes.CREATE_A_MEETING + '?utm_source=proposal_promote_plan&utm_medium=web'} />
  } else if (proposal.status === STATUS.NO_AVIABILITY) {
    // general "no availability" status
    title = t('event.state.no_aviability.title');
    subTitle = t('event.state.no_aviability.subtitle');
  } else if (proposal.status === 'sent' && (proposal.hadVoted || (proposal.inviteesDetails[email] || {}).response === 'yes')) {
    // If user has voted
    title = t('event.state.sent.hasVoted.title');
    subTitle = t(isPoll ? 'event.state.sent.hasVoted.subTitlePoll' : 'event.state.sent.hasVoted.subTitle');
  } else if (proposal.status === STATUS.CANCELLED) {
    title = t('event.state.cancelled.subtitle');
    subTitle = t('event.state.cancelled.guestMessage');
  } else if (proposal.status === STATUS.CONFIRMED) {
    // si forcé, ajoute un message
    title = t('event.state.confirmed.subtitleNoDate');
    if (!allMandatoryAnswered(proposal)) {
      let host = proposal.organizer || {};
      host = host.label || host.email
      title = t('event.state.confirmed.forcedMeeting', { host });
    }
    const date = proposal.icaluid && proposal.start;
    subTitle = date && moment(date).format(t('common.fullDateFormat'));
  } else if (isVoting) {
    title = t('event.anonymous.pleaseWait');
    subTitle = null;
  } else if (email && !(proposal.inviteesDetails || {})[email]) {
    title = t('event.state.sent.hasVoted.title');
    subTitle = t(isPoll ? 'event.state.sent.hasVoted.subTitlePoll' : 'event.state.sent.hasVoted.subTitle');
  }
  return (
    <>
      <div className="proposal-status">
        {title &&
          <div className="title">{title}</div>}
        {subTitle &&
          <div className="subtitle">{subTitle}</div>}
        {cta && cta}
      </div>
      {/* {showPromote && <Promote />} */}
    </>
  );
}

export const allSlotsExpired = (proposal) => {
  if (!proposal.slots) return false;
  // explore all slots
  const now = moment().toISOString();
  for (let i = 0; i < proposal.slots.length; i++) {

    let slotsForDay = proposal.slots[i] && proposal.slots[i].slots;

    for (let j = 0; j < slotsForDay.length; j++) {
      let slot = slotsForDay[j]
      if (slot.start > now) {
        // at least one slot starts in the future
        return false;
      }
    }
  }
  // all slots start in the past
  return true;
}

export const hasNotVoted = (proposal, email) => !proposal.hadVoted
  && ((proposal.inviteesDetails || {})[email] || {}).response !== 'yes';