import { useCallback, useState } from "react";
import { MAIL } from "../validators/event";

// hook to manage guest consent
// to be used with GuestConsent checkbox component
// see return statement for more info.
export default function useGuestConsent() {

  // guest consent is checked by default
  const [isGuestConsentChecked, setIsGuestConsentChecked] = useState(false);

  // function called when the checkbox is checked
  // parameter 'e' is a SyntheticEvent
  const guestConsentChanged = useCallback((e) => {
    if (e && e.target) {
      setIsGuestConsentChecked(e.target.checked);
    }
  }, []);

  // identify the "visitor" using the guest email
  // call to identify creates a "People" in customer.io
  const sendGuestConsent = useCallback((email, name, origin) => {
    if (isGuestConsentChecked && email && MAIL.isValidSync({ mail: email })) {
      console.log("Guest consent is checked, sending email to Segment", email);
      // ensure Segment is loaded
      if (window.analytics && window.analytics.user) {
        const user = window.analytics.user();
        const traits = user && user.traits();
        // ensure the visitor is not already given an email address
        if (!traits || !traits.email) {
          // user is not signed in, we set the email of the anonymous user
          window.analytics.identify({ 'email': email, 'name': name, 'subscription_origin': origin });
          console.log("User email subscribed: ", email.toLowerCase());
        } else if (traits && traits.email) {
          // user is signed in, we don't set/change the email.
          // Otherwise it could lead to LM users changing their email to another email, 
          // possibly not their email address.
          console.log("A user is already identified with an email address. Do not interfere.")
        }
      }
    }
  }, [isGuestConsentChecked]);

  return {
    isGuestConsentChecked,  // returns TRUE if user consent is set to YES, FALSE otherwise
    guestConsentChanged,    // returns a function to call when the checkbox check state changes
    sendGuestConsent,       // returns a function to call when consent can be sent, this sends the email to Segment.
  };

}

// 
export const GUEST_CONSENT_ORIGINS = {
  BOOKING_LINK: 'scheduling-link',
  PROPOSAL_LINK: 'proposal-link',
}