import { handleActions, combineActions } from 'redux-actions';
import { createSelector } from 'reselect';
import * as ACTIONS from '../actions';

const defaultState = [];
function doUpdate(state, action) {
  let links = action.payload;
  /* istanbul ignore else no work */
  if (links) {
    if (!Array.isArray(links)) links = [links]
    for (let lnk of links) {
      let i = state.findIndex((l) => l.id === lnk.id);
      if (i > -1) {
        state[i] = { ...state[i], ...lnk };
      } else {
        state.push(lnk)
      }
    }

    return [...state];
  }
  return state;
}
const reducer = handleActions(
  {
    [ACTIONS.WORKER_GET_MY_LINKS_SUCCESS]: (state, action) => {
      return action.payload || [];
    },
    [combineActions(
      ACTIONS.WORKER_GET_LINK_SUCCESS,
      ACTIONS.WORKER_CREATE_LINK_SUCCESS,
      ACTIONS.WORKER_UPDATE_LINK_SUCCESS,
      ACTIONS.WORKER_ACTIVATE_LINK_ERROR,
      ACTIONS.WORKER_DELETE_LINK_ERROR,
    )]: (state, action) => {
      // try to update
      return doUpdate(state, action)
    },
    [ACTIONS.WORKER_ACTIVATE_LINK]: (state, action) => {
      // try to update
      let l = { ...action.payload, is_active: !action.payload.is_active }
      return doUpdate(state, { payload: l })
    },
    [ACTIONS.WORKER_DELETE_LINK]: (state, action) => {
      let lnk = action.payload;
      /* istanbul ignore else no work */
      if (lnk && lnk.id) {
        return state.filter((l) => l.id != lnk.id)
      }
      return state;
    }
  },
  defaultState,
)
export const getLinks = (state) => state.links;
export const getLink = (id) => createSelector(
  getLinks,
  (links) => links.find((lnk) => lnk.id === id)
)
export const getLinkBySlug = (slug) => createSelector(
  getLinks,
  (links) => links.find((lnk) => lnk.slug === slug)
)
export default reducer;