import worker from './worker';
import workerAsPromise from './worker.as.promise';
import analytics from './analytics';
import sentryErrors from './sentryErrors';
import segmentAnalytics from './segment';
import token from './token'; // maybe not the best name....
import devSettings from './dev.settings';
export default [
  devSettings,
  segmentAnalytics(),
  workerAsPromise,
  worker,
  token,
  analytics,
  sentryErrors,
]