import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../reducers/profile';
import Tooltip from '@material-ui/core/Tooltip';
import { HelpOutline } from '@material-ui/icons';
import NOOP from '../../../utils/noop';

import './free.user.scss';
import { LMTooltip } from '../../tooltip/Tooltip';
import routes from '../../../routes/routes.name';
import { Link } from 'react-router-dom';
export default function FreeUserTip({
  className = "",
  onClick = NOOP
}) {
  const { t } = useTranslation();
  const profile = useSelector(getProfile)
  if (!profile || (profile.billing && (profile.billing.customer_id || profile.monthly_quota_remaining <= 0))) return null;
  const key = profile.monthly_quota_remaining === 0 ? 'createEvent.freeUserTip_0' : 'createEvent.freeUserTip';
  return <div className={"free-user-tip " + className} onClick={onClick}>
    {t(key, { count: profile.monthly_quota_remaining || 0 })}
    <LMTooltip
      interactive={true}
      content={
        <>
          <p>{t('createEvent.freeUserMessage', { count: profile.monthly_quota_remaining || 0 })}</p>
          <p><Link to={routes.APP_SETTINGS_BILLING}>{t('createEvent.freeUserMessageCTA')}</Link></p>
        </>}>
      <HelpOutline />
    </LMTooltip>
  </div>
}