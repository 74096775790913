import moment from 'moment';
import { STATUS } from '../api/validators/proposal';
import { hasRemaining } from "./has.remaining";
export function isMis(dt, srch) {
  // ALL sent
  if (dt.status === STATUS.CONFIRMED) return moment(dt.start) > moment();
  return false;
}
export function isOngoing(dt, srch) {
  // not yet finished
  return dt.status === STATUS.PENDING;
}
export function isArchived(dt, srch) {
  return !isOngoing(dt, srch) && !isMis(dt, srch);
}
export const TABS = {
  ONGOING: 0,
  MIS: 1,
  PAST: 2,
};
export function getProposalTab(proposal) {
  /* istanbul ignore if no work */
  if (!proposal) return TABS.ONGOING;
  if (proposal.status === STATUS.CONFIRMED) {
    if (moment(proposal.start) > moment()) return TABS.MIS;
  }
  return hasRemaining(proposal) ? TABS.ONGOING : TABS.PAST;
}