/**
 * @fileoverview Day renderer for done picker
 * @module DonPicker
 */
import React, { useCallback, useState } from "react";
import moment from "moment";

import './day.scss';
import { CheckBoxOutlined } from "@material-ui/icons";

export function Day({ day, // day for this component
  selected, // actual selected day in picker
  onSelected, // callback on click this day
  disabled = false, // is this day clickable?
  hasEvents = false, // do we display a dot to indicate events for this day?
  hasPendingEvents = false, // do some of those events are pending for a response?
  hovered, // hovered day
  unselectable = false,
}) {
  const [__isHovered, setIsHovered] = useState(false); // current day column header is hovered
  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);
  const isHovered = __isHovered || (hovered ? hovered.isSame(day, 'day') : false);
  let isSelected = selected ? selected.isSame(day, 'day') : false; // 
  let isNow = day.isSame(moment(), 'day');
  const doSelect = useCallback((e) => onSelected(day), [onSelected, day])
  return (
    <div
      data-testid={"day-click-" + day.dayOfYear()}
      onClick={doSelect}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={
        "donepicker-date " +
        (disabled || day.unselectable ? " unselectable" : "") +
        (isSelected ? " selected" : "") +
        (isNow ? ' today' : '') +
        (isHovered ? " hovered " : "") +
        (unselectable ? " next-month" : "") +
        (hasEvents ? " has-events" : "")
      }
    >
      {day.format("DD")}
      {/*hasEvents && <div className="hasEvents" />*/}
      {hasPendingEvents && <div className="hasPendingEvents" />}
      <span className={"day"}>
        {day.format("ddd")}
      </span>
      {isHovered && !disabled && !day.unselectable &&
        // show possible action icon if day is hovered and not disabled
        <CheckBoxOutlined className="action" />}
    </div>
  );
}