import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as ACTIONS from '../../../../actions';
import useAccounts from '../../../../hooks/use.accounts';
import { PROVIDERS } from '../../../../assets/icons';
import './Accounts.scss';
import DoneButton from '../../../DoneButton';
import Dialog from '@material-ui/core/Dialog';
import { SignInDialogContent } from '../../../providers.id';
import { CalendarToday } from '@material-ui/icons';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { useDialog } from '../../../dialogs/dialog.provider';
import { useHistory } from 'react-router-dom';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FreeBusyCalendars from './FreeBusyCalendars';
import {
  PermContactCalendar,
} from '@material-ui/icons';

const ACTIONS_ADD = 1;
const ACTIONS_REMOVE = 2;

export default function Accounts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accounts = useAccounts();
  const history = useHistory();
  const { enqueueDialog, closeDialog } = useDialog();
  // add account popup
  const [showPopup, setShowPopup] = React.useState(false);
  // contains account.id while removing an account
  const [isWorking, setIsWorking] = React.useState({ accountId: false, action: false });
  const [loading, setLoading] = React.useState(true);

  // load user accounts infos
  React.useEffect(() => {
    dispatch({
      type: ACTIONS.WORKER_REFRESH_ACCOUNTS,
      resolvers: {
        resolveOn: ACTIONS.WORKER_REFRESH_ACCOUNTS_SUCCESS,
        rejectOn: ACTIONS.WORKER_REFRESH_ACCOUNTS_ERROR
      }
    }).then(() => setLoading(false))
  }, []);


  /**
   * Add/connect/register an account of a particular provider
   */
  const addAccount = React.useCallback((provider, login_host, email, extra_scopes) => {
    dispatch({
      type: ACTIONS.WORKER_SIGN_UP_ACCOUNT,
      payload: provider,
      login_host: login_host,
      login_hint: email,
      extra_scopes,
      resolvers: {
        resolveOn: ACTIONS.WORKER_SIGN_UP_ACCOUNT_SUCCESS,
        rejectOn: ACTIONS.WORKER_SIGN_UP_ACCOUNT_ERROR,
      }
    }).then((link) => {
      if (link && link.payload && link.payload.link) {
        //window.location.href = link.payload.link;
        window.location.assign(link.payload.link/*.replace('prompt=consent', 'prompt=select_account')*/); // mockable for tests!
      }
    }).catch((err) => {
      console.error(err);
    })
  }, [dispatch]);


  /**
   * Remove/disconnect/unregister a specific account
   */
  const removeAccount = React.useCallback((account) => {
    const unblock = history.block();
    const doClose = () => {
      unblock();
      closeDialog();
    };
    const doConfirm = () => {
      doClose();
      setIsWorking({ accountId: account.id, action: ACTIONS_REMOVE });
      dispatch({
        type: ACTIONS.WORKER_UNREGISTER_ACCOUNT,
        payload: account,
        resolvers: {
          resolveOn: ACTIONS.WORKER_UNREGISTER_ACCOUNT_SUCCESS,
          rejectOn: ACTIONS.WORKER_UNREGISTER_ACCOUNT_ERROR,
        }
      })
        .then((dt) => {
          if (process.env.NODE_ENV === 'development' && accounts.filter((acc) => acc.id !== account.id).length === 0) {
            dispatch({
              type: ACTIONS.WORKER_LOGOUT
            })
          }
        })
        .catch((err) => {
          console.error(err);
        }).finally(() => {
          // check how many remaining accounts left
          // if no more, disconnect

          setIsWorking({ accountId: false, action: false });
        })
    }

    enqueueDialog({
      content: <ConfirmDlg onClose={doClose} onConfirm={doConfirm} />
    })

  }, [dispatch, accounts]);


  const doClose = () => setShowPopup(false);


  return (
    <div className="settings-accounts">
      <div className="section">
        <div className="title">
          {t('settings.sections.accounts.content.sectionAccountsTitle')}
        </div>
        {loading && <div className="home-loader"><LinearProgress /></div>}
        <div className="accounts">
          {accounts && accounts.map(account => {
            const calendars = (account.calendars || []).filter(calendar => !calendar.readonly);
            const isActingOnAccount = (isWorking.accountId === account.id);
            const action = isWorking.action;
            const hasContacts = !!(account.permissions || []).find((p) => p === 'contact');
            return (
              <div className={"item account " + ((account.isInvalid || account.revoked) ? 'invalid' : '') + (isActingOnAccount ? " removing" : "")} key={account.id}>
                {/* account details */}
                <div className="container">
                  <div className="container">
                    {PROVIDERS[account.provider.name] || PROVIDERS['google']}
                    <AccountSummary account={account} />
                  </div>
                  <div className="actions">
                    {(account.isInvalid || account.revoked) && <DoneButton
                      name={"reconnect-" + account.id}
                      label={
                        (isActingOnAccount && action === ACTIONS_ADD) ?
                          <>
                            <CircularProgress />
                            <div className="removing">
                              {t('settings.sections.accounts.content.reconnectingAccount')}
                            </div>
                          </>
                          : t('settings.sections.accounts.content.reconnectAccountButtonText')}
                      disabled={isActingOnAccount}
                      className="small remove-account" onClick={() => {
                        let tenant_hint = ['@hotmail.', '@outlook.'].reduce((acc, test) => {
                          return acc || account.email.includes(test);
                        }, false);
                        addAccount(account.provider.name, !tenant_hint/*account.provider.tenant_hint*/, account.email);

                      }} />}
                    {/* disco button: do not allow to remove the last account */}
                    {(process.env.NODE_ENV === 'development' || accounts.length > 1) /*&& !(account.isInvalid || account.revoked)*/ &&
                      <DoneButton
                        name={"valid" + account.id}
                        label={
                          (isActingOnAccount && action === ACTIONS_REMOVE) ?
                            <>
                              <CircularProgress />
                              <div className="removing">
                                {t('settings.sections.accounts.content.removingAccount')}
                              </div>
                            </>
                            : t('settings.sections.accounts.content.removeAccountButtonText')}
                        disabled={isActingOnAccount}
                        className="small grey remove-account" onClick={() => removeAccount(account)} />}
                  </div>
                </div>
                {!hasContacts && <div className="add-contacts-permission">
                  <div className="permission-title">
                    {/* <PermContactCalendar /> */}
                    <span>{t('settings.sections.tools.items.permissions.contacts.description')}</span>
                    <DoneButton
                      className="small blue-outlined"
                      label={t('settings.sections.tools.items.permissions.contacts.connect')}
                      onClick={() => { addAccount(account.provider.name, undefined, account.email, [...account.permissions, 'contact']) }}
                    /></div>
                  {/* <div className="permission-description">
                    <span>{t('settings.sections.tools.items.permissions.contacts.description')}</span>

                  </div> */}
                </div>}
              </div>
            );
          })}
        </div>
        {/* add an account button */}
        <div className="add-account item">
          <DoneButton
            name="add-account"
            label={t('settings.sections.accounts.content.addAccountButtonText')}
            onClick={() => setShowPopup(true)} />
        </div>
      </div>
      <FreeBusyCalendars />
      {
        showPopup && <Dialog onClose={doClose} open={showPopup}>
          <SignInDialogContent doClose={doClose} googleloggin={() => addAccount('google')} mslogin={() => addAccount('microsoft')}
            officelogin={() => addAccount('microsoft', 'office')} />
        </Dialog>
      }
    </div>
  )
}


const ConfirmDlg = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  return <div className="confirm-dialog" data-testid="confirm-dialog-dialog">

    <DialogTitle id="alert-dialog-title">{t('settings.sections.accounts.content.confirmDlg.title')}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {t('settings.sections.accounts.content.confirmDlg.message')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary" data-testid="confirm-event-dialog-cancel">
        {t('settings.sections.accounts.content.confirmDlg.dismiss')}
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus data-testid="confirm-event-dialog-ok">
        {t('settings.sections.accounts.content.confirmDlg.ok')}
      </Button>
    </DialogActions>

  </div>
}

export const AccountSummary = ({ account }) => {
  const { t } = useTranslation();
  return (
    <div className="provider">
      <div className="title"><span className="capitalize">{account.provider.name}</span></div>
      <div className="email">{account.email}</div>
      {account.isInvalid && <div className="infos" data-testid={"infos-invalid" + account.id}>{t('settings.sections.accounts.content.invalid')}</div>}
      {account.revoked && <div className="infos" data-testid={"infos-invalid" + account.id}>{t('settings.sections.accounts.content.revoked')}</div>}
    </div>
  );
}