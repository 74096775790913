import React, { useMemo } from 'react';
import './ProposalStatus.scss';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../api/validators/proposal';
import {
  EVENT_STATUS_WAITING,
  EVENT_STATUS_CONFIRMED,
  EVENT_STATUS_CONFIRMED_NO,
  EVENT_STATUS_WAITING_NO,
  EVENT_STATUS_NO,
  completeEventStatus
} from '../../../utils/event.status';
import moment from 'moment';

import {
  MailSuccessIcon,
  StatusCancelledIcon,
  StatusNoAvailIcon,
  StatusSuccessIcon,
  StatusPendingIcon,
  // icons when event is complete
  StatusMaybeIcon, // 1 no
  StatusPendingNoIcon,
  StatusSuccessNoIcon,
  StatusPollIcon,
} from '../../../assets/icons';

const WAITING_FOR_YOU = 'WAITING_FOR_YOU';

const STATUSES = {
  [STATUS.PENDING]: { icon: <StatusPendingIcon /> },
  [STATUS.CANCELLED]: { icon: <StatusCancelledIcon /> },
  [STATUS.CONFIRMED]: { icon: <MailSuccessIcon /> },
  [STATUS.NO_AVIABILITY]: { icon: <StatusNoAvailIcon /> },
  [STATUS.EVT_CANCELLED]: { icon: <StatusNoAvailIcon /> },
  [WAITING_FOR_YOU]: { icon: <StatusPendingIcon /> },
}
const EVT_STATUSES = {
  [EVENT_STATUS_WAITING]: { icon: <StatusPendingIcon />, text: 'proposal.timeline.eventStatus.sent' },
  [EVENT_STATUS_NO]: { icon: <StatusMaybeIcon />, text: 'proposal.timeline.eventStatus.canceled' },
  [EVENT_STATUS_CONFIRMED]: { icon: <StatusSuccessIcon />, text: 'proposal.timeline.eventStatus.complete' },
  [EVENT_STATUS_CONFIRMED_NO]: { icon: <StatusSuccessNoIcon />, text: 'proposal.timeline.eventStatus.complete_with_no' },
  [EVENT_STATUS_WAITING_NO]: { icon: <StatusPendingNoIcon />, text: 'proposal.timeline.eventStatus.sent_with_no' },
}
// Maybe those func should go in utils?
// Has attendee answer or not need to answer
function hasAnswer(invitee) {
  return !!(invitee.response === "yes"
    || (invitee.registered && !invitee.conflicting_slots && !invitee.vote_requested)
    || (invitee.related_to && invitee.related_to.provider && invitee.related_to.user_id && !invitee.conflicting_slots && !invitee.vote_requested));
}
// have all user requested to vote voted?
export function allMandatoryAnswered(proposal) {
  if (!proposal.inviteesDetails) return false;
  const notAnswers =
    Object.values(proposal.inviteesDetails)
      .filter(
        (invitee) => {
          // if MUST answer and has not, return true
          return !hasAnswer(invitee) && !invitee.optional;
        })
  return notAnswers.length === 0;
}
export function getNbGuestsWhoVoted(proposal) {
  if (!proposal.inviteesDetails) return 0;
  const withAnswers =
    Object.values(proposal.inviteesDetails)
      .filter(
        invitee =>
          invitee.response === "yes"
          || (invitee.registered && !invitee.conflicting_slots && !invitee.vote_requested)
          || (invitee.related_to && invitee.related_to.provider && invitee.related_to.user_id && !invitee.conflicting_slots && !invitee.vote_requested));
  return withAnswers.length;
}

export default function ProposalStatus({ proposal = {}, isRightToLeft = true }) {
  const { t } = useTranslation();
  let status = proposal.status;
  // poll finished
  let isPollFinished = false;
  if (proposal.type === 'poll' && proposal.status === STATUS.PENDING) {
    // is poll over, ie: everybody answer?
    isPollFinished = allMandatoryAnswered(proposal);
  }

  // decide which status label will be used
  const statusLabel = useMemo(() => {
    if (!proposal) return null;
    const isOneToOneMeeting = Object.values(proposal.inviteesDetails || []).length === 1;
    const isPoll = proposal.type === 'poll';
    const waitingForYou = isWaitingForYou(proposal)
    if (waitingForYou) {
      // user is requested to reply
      return 'dashboard.event.statuses.WAITING_FOR_YOU';
    } else if (status === STATUS.PENDING && isOneToOneMeeting && !isPoll) {
      // one to one meeting is in progress
      return 'dashboard.event.statuses.sentOneToOne';
    } else if (status === STATUS.PENDING && isPoll) {
      if (isPollFinished) {
        // poll finished
        return 'dashboard.event.statuses.sentPollFinished';
      } else {
        // poll in progress
        return 'dashboard.event.statuses.sentPoll';
      }
    } else {
      // other status have their dedicated status label
      return 'dashboard.event.statuses.' + status;
    }
  }, [proposal, isPollFinished, status])

  // did I get forced or requested to vote
  if (isWaitingForYou(proposal)) {
    status = WAITING_FOR_YOU;
  }

  if (!status || !STATUSES[status]) return null;
  if (proposal.iAmHost && proposal.status === STATUS.CONFIRMED) return <CompleteEventStatus proposal={proposal} isRightToLeft={isRightToLeft} />;

  const nbGuestsWhoVoted = getNbGuestsWhoVoted(proposal);
  const nbGuests = (proposal.invitees || []).length;

  return (
    <div className={"ProposalStatus" + (isRightToLeft ? ' righttoleft' : '')}>
      <StatusIcon
        color={STATUSES[status].color}
        className={status + ((isPollFinished && proposal.iAmHost) ? ' POLL_FINISHED' : '')}
        icon={(isPollFinished) ? <StatusPollIcon /> : STATUSES[status].icon} />
      <div className="text-container">
        <div className="primary">
          {statusLabel && t(statusLabel)}
        </div>
        {(status === STATUS.PENDING || status === WAITING_FOR_YOU) && !isPollFinished &&
          <div className="secondary">
            {t(
              'dashboard.event.statusPendingNbAnswered',
              { ratio: nbGuestsWhoVoted + '/' + nbGuests }
            )}
          </div>}
        {status === STATUS.CONFIRMED && proposal.start &&
          <div className="secondary">
            {moment(proposal.start).format(t('common.dateFormatShorter'))}
          </div>}
      </div>
    </div>
  )
}

const CompleteEventStatus = ({ proposal, isRightToLeft }) => {
  const { t } = useTranslation();
  const status = completeEventStatus(proposal);
  if (status === false) return null;
  return (<div className={"ProposalStatus" + (isRightToLeft ? ' righttoleft' : '')}>
    <StatusIcon
      color={EVT_STATUSES[status].color}
      className={status}
      icon={EVT_STATUSES[status].icon} />
    <div className="text-container">
      <div className="primary">
        {t(EVT_STATUSES[status].text)}
      </div>
      <div className="secondary">
        {moment(proposal.start).format(t('common.dateFormatShorter'))}
      </div>
    </div>
  </div>)
}
const StatusIcon = ({ icon = null, color = "white", className = '' }) => {
  if (!icon) return null;
  return (
    <div className={"StatusIcon " + className} style={{ backgroundColor: color }}>
      {icon}
    </div>
  );
}

export const isWaitingForYou = (proposal) => {
  if (!proposal || !proposal.inviteesDetails || !proposal.me || !proposal.inviteesDetails[proposal.me]) return false;

  return (
    // Forced on some slots
    proposal.status === STATUS.PENDING
    && proposal.ignore_initial_avail
    && proposal.me && proposal.inviteesDetails[proposal.me] && proposal.inviteesDetails[proposal.me].conflicting_slots && proposal.inviteesDetails[proposal.me].conflicting_slots.length > 0
    && proposal.inviteesDetails[proposal.me].response !== 'yes')
    ||
    // If I was requested to vote
    (proposal.status === STATUS.PENDING
      && proposal.me && proposal.inviteesDetails[proposal.me] && proposal.inviteesDetails[proposal.me].vote_requested
      && proposal.inviteesDetails[proposal.me].response !== 'yes');

}