import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  FormControlLabel,
  // Switch,
} from '@material-ui/core';
import * as ACTIONS from '../../../../actions';

import './notifications.scss';
import { Switch } from '../../../LMSwitch';
export default function Notifications({ profile = {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // generic when more options will come...
  const [loading, setLoading] = React.useState(false);
  const onChange = React.useCallback((e) => {
    if (loading) return;
    const newValue = e.target.checked;
    setLoading(true);
    dispatch({
      type: ACTIONS.WORKER_SAVE_WH,
      payload: {
        ...profile,
        notify_follow_up: newValue
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_SAVE_WH_SUCCESS,
        rejectOn: ACTIONS.WORKER_SAVE_WH_ERROR,
      }
    }).catch((err) => {
      // rollback
      enqueueSnackbar(t('settings.sections.advanced.notifications.error'), { variant: 'error' });

    }).then(() => {
      setLoading(false);
    })
  }, [loading, profile]);
  return (
    <div className={"section advanced-item " + (loading ? 'inactive' : '')}>
      <div className="title">{t('settings.sections.advanced.notifications.title')}</div>
      <div className="subtitle">{t("settings.sections.advanced.notifications.subtitle")}</div>
      {!loading && <div className="test-only" data-testid="loader" />}
      <div className="notifications">
        <div className="notifications-item">
          <FormControlLabel
            value="start"
            control={<Switch className="done-input" checked={profile.notify_follow_up} onChange={onChange} color="primary" data-testid="swch-followup" />}
            label={t("settings.sections.advanced.notifications.followUp.label")}
          />
          <div className="more subtitle">{t('settings.sections.advanced.notifications.followUp.description')}</div>
        </div>
      </div>
    </div>
  )
}