import localforage from 'localforage';
const MAX_CACHE_TTL = 60*60*1000;
// Simplify: save url in cache as key
const contactRx = /^\/v1\/accounts\/(google|microsoft):([^/]*)\/contacts(\?continuation=.*)?$/;
// const calendarRx = /^\/v1\/accounts\/(google|microsoft):([^/]*)\/calendars(\?continuation=.*)?$/;
// const eventRx = /^\/v1\/accounts\/(google|microsoft):([^/]*)\/events\/icaluid\/.*$/;
// all urls to cache
const CACHED_URLS = [
  {rx: contactRx, ttl: MAX_CACHE_TTL},  // IMPORTANT UNCOMMENT THIS SENTENCE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // {rx: calendarRx, ttl: 20*1000}, // we could determine ttl by request type...
  // {rx: eventRx, ttl: MAX_CACHE_TTL},
]

function isCachable(url){
  for(let rx of CACHED_URLS){
    if(rx.rx.test(url)) return rx;
  }
  return false;
}
export function cachedFetch(fetch){
  return function(url, opts = {}, addToken = true){
    // check if contact url
    let cache_request = false;
    let cached = Promise.resolve(false);
    const DB={}; // per request cache
    let cacher = isCachable(url)
    if(cacher){
      cache_request = url;
      // check if cache exists
      
      cached = localforage.getItem(cache_request) //let value =   DB[cache_request]
      .then((value)=>{
        if(value && Date.now() - value.ttl <= cacher.ttl){
          value.data.__from_cache=true
          return Promise.resolve(value.data); 
        } else{
          DB[cache_request] = value; // per request cache
          return false;
        };
      }).catch((err)=> {
        console.log(err);
        return false;
      })
    }
    
    
    return cached.then((fromCache) => {
      if(!fromCache) return fetch(url, opts, addToken);
      else return fromCache;
    }).then((res)=>{
        if(res && cache_request && !res.__from_cache){
          let value ={
            ttl: Date.now(),
            data: res
          }
          localforage.setItem(cache_request, value).catch(/* istanbul ignore next silent error */(err)=>{
            console.error('Fail cache data ', err)
          })
        }
        return res;
    }).catch((err)=>{
      // if not uthorized, do not proceed
      if( (err && err.status != 401) && cache_request){
        let v = DB[cache_request];
        if (v) {
          return v.data;
        }
      }
      return Promise.reject(err);
    });               
  }
}