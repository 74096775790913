/* istanbul ignore file */
// not in use for now
/**
 * A notistacjk-like dialog container 
 * make it possible to enqueueDialog to user ?
 * should make it evoluate depending on needs, keep it simple for now
 * 
 * 
 * I HAVE NO F*CKING CLUE ON HOW TO TEST THIS!!!!
 * 
 */
import React, { createContext } from 'react';
import './tooltop.scss'
export const TooltopContext = createContext({});
// Context provider 
export function TooltopProvider({ children }) {
  const dlg = React.useState();
  const handleClose = React.useCallback(() => {
    dlg[1](false);

  });
  return <TooltopContext.Provider value={dlg}>
    {!!dlg[0] && dlg[0]}
    {children}

  </TooltopContext.Provider>
}
// add a dialog to the screen
export function useTooltop() {
  // only one stack for now?
  const dlg = React.useContext(TooltopContext);

  return {
    enqueueTooltop: ({
      content, // what to display in dialog
      autoCommitDuration = 30, // a timer to autocommit dialog?
    }) => {

      dlg[1](<div className="tooltop" open={!!dlg}>
        {content}
      </div>);
      // autocommit
    },
    closeTooltop: () => dlg[1](false),

  }
}

