import React from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { IconDelete as Delete } from '../../../../assets/icons';
import GuestItem from './guest.item';
import GuestChipItem from './guest.chip.item';

import { getContact } from '../../../../reducers/contacts';
import NOOP from '../../../../utils/noop';

import './guest.scss';
/**
 * Guest invitee in list
 * @param {String} email guest email
 * @param {Function} onClick click on cross
 */
export default function Guest({
  email = '',
  details,
  guesticon = null,
  onClick = NOOP,
  toggleOptional = NOOP,
  displayAsChips = false,

  simpleContactSelector = false,
}) {
  let contact = useSelector(getContact(email));
  if (!contact || !contact.email) return null;
  if (simpleContactSelector) return <GuestChipItem contact={contact} details={details} onClick={onClick} className={'unknown-invitee'} />
  if (displayAsChips) return <GuestChipItem guesticon={guesticon} contact={contact} details={details} onClick={onClick} toggleOptional={toggleOptional} className={!contact.isDoner && 'unknown-invitee'} />
  return <div className="guest">
    <GuestItem contact={contact} details={details} />
    {onClick !== NOOP && <IconButton data-testid={"guest-delete"} onClick={() => onClick(email)}><Delete /></IconButton>}
  </div>
}