import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { LinearProgress } from '@material-ui/core';
import { getNbVoters, getVoters } from '../../../../utils/nbr.of.voters';
import { isUnknownDomain } from '../../../../api/validators/proposal';
import moment from 'moment';
import { v4 } from 'uuid';
import {
  ChevronRight
} from '@material-ui/icons';
import CTA from './cta.js';
import { KeyboardArrowDown } from '@material-ui/icons';
import NOOP from '../../../../utils/noop';
import { isValidDuration } from '../../../../validators/event'
import './period.renderer.v2.scss';
import { IllusFunnel, IllusPoll } from '../../../../assets/icons';
import * as uuid from "uuid";
import { linearize } from '../../../../worker/handlers/generate.slots';

const STATE_INVALID = 0,
  STATE_POLL_OR_FUNNEL = 1,// (multiple guests must vote, multiple slots)
  STATE_POLL_ONLY = 2, // (multiple guests must vote, multiple slots; but funnel is not possible because not enough slots)
  STATE_SEND_INVITES = 3, // (no one has to vote; no funnel, no poll)
  STATE_ONE_TO_ONE = 4,// (1 guest must vote; no funnel, no poll)
  STATE_ONE_TO_MANY = 5; //  (1 guest to vote only; no funnel, no poll)
const TITLES = [
  'createEvent.form.periods.titles.noResults',
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];
const RENDERERS = [
  NoResults,
  PollOrFunnel,
  PollOnly,
  SendInvites,
  OnlyOneGuestToVote,
  OnlyOneGuestToVote
]
export function PeriodRenderer({
  loading = false,
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview,
  ctaLabel
}) {
  const { t } = useTranslation();
  // compute labels and informations
  const currentState = currentStateOfProposal(formDatas, slots, pollInfo, contacts);
  const resultRef = React.useRef();
  const [resultCmpHeight, setResultCmpHeight] = React.useState({ height: 'auto' })
  React.useLayoutEffect(() => {
    if (!loading && resultRef.current) {
      // get height of ref
      let cmp = resultRef.current;
      setResultCmpHeight({ height: cmp.getBoundingClientRect().height })
    }
  }, [loading, resultRef])
  let Content = RENDERERS[currentState];
  if (loading) {
    return null;
  }
  return <div className="period-v2" ref={resultRef} data-testid="multi-slots">
    {TITLES[currentState] && <div className="period-title">{t(TITLES[currentState])}</div>}
    <Content formDatas={formDatas} slots={slots} handleSubmit={handleSubmit} freeUserExpired={freeUserExpired} isGeneratingLink={isGeneratingLink}
      showcustomSlotsDlg={showcustomSlotsDlg} showHeatMap={showHeatMap} onChange={onChange} disableSlots={disableSlots}
      showPreview={showPreview} contacts={contacts} pollInfo={pollInfo} />
  </div>;
}

const MESSAGE_RENDERERS = [
  NoSlotFoundMessage, // no message if not valid
  PollOrFunnelMsg,
  PollOnlyMsg,
  SendInvitesMsg,
  OneToOneMsg,
  OneToOneMsg
];

export function PeriodMessage({
  loading = false,
  formDatas,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  contacts,
  showPreview,
  pollInfo = {},
}) {
  const { t } = useTranslation();
  const ref = React.useRef()
  const [isSticky, setIsSticky] = React.useState(false)
  const slots = React.useMemo(() => {
    let sl = formDatas.slots || {};
    return {
      total: pollInfo.total || 0,
      final_slot: pollInfo.final_slot,
      slots: sl
    }
  }, [formDatas.slots, pollInfo]);

  // callback to show the Heatmap dialog
  const showHeatMap = React.useCallback((withSlots) => {

    let slotsForHeatmap = slots;
    if (withSlots && withSlots.slots) {
      slotsForHeatmap = withSlots;
    }
    if (slotsForHeatmap && slotsForHeatmap.slots) {
      let ln = linearize(slotsForHeatmap.slots).map((s) => ({
        start: moment(s.start),
        end: moment(s.end),
        uuid: uuid.v4(),
      }))
      let start = (ln[0] || { start: moment() }).start
      showcustomSlotsDlg(true, { values: ln, initialDate: start })
    }
  }, [slots, showcustomSlotsDlg]);

  // detect when the period message is stuck/unstuck to bottom of the screen
  const setObserver = React.useCallback((node) => {
    if (!window.IntersectionObserver) return;
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
      const { observer, node } = ref.current;
      observer.unobserve(node)
    }
    if (node !== null) {
      const cachedRef = node;
      const observer = new window.IntersectionObserver(
        ([e]) => {
          setIsSticky(e.intersectionRatio < 1)
        },
        {
          root: null,
          threshold: 1.0
        }
      )
      observer.observe(cachedRef)
      ref.current = { observer, node };
    } else ref.current = null;
  }, [])

  const currentState = currentStateOfProposal(formDatas, slots, pollInfo, contacts);
  let Content = MESSAGE_RENDERERS[currentState];
  if (!Content) return null;

  return <div id='stiky-header' className={"period-v2 floating-message " + (isSticky ? " isSticky" : "") + (loading ? " isLoading" : "")} ref={setObserver} >
    <div className="period-message">
      <Content formDatas={formDatas} slots={slots} freeUserExpired={freeUserExpired} isGeneratingLink={isGeneratingLink}
        showcustomSlotsDlg={showcustomSlotsDlg} showHeatMap={showHeatMap}
        showPreview={showPreview} contacts={contacts} pollInfo={pollInfo} />
    </div>
    {/* loader */}
    <div className="progress-label period-message-loader">
      <div>{t('createEvent.form.recap.loading')}</div>
      <LinearProgress />
    </div>
    <KeyboardArrowDown id={"scroller-hint"} />
  </div>
}

function PollOrFunnelMsg({
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  const { t } = useTranslation();
  const message = React.useMemo(() => {
    /* istanbul ignore if */
    if (!slots || !formDatas) return {};
    // time to go
    let currenttimeframe = "";
    if (formDatas.currenttimeframe > -1) currenttimeframe = t('createEvent.form.periods.messages.timeframes_' + formDatas.currenttimeframe);

    return {
      slotsCount: slots.total,
      title: formDatas.title || t('createEvent.form.periods.messages.enterMeetingName'),
      inviteesCount: formDatas.invitees.length,
      periodOfTime: currenttimeframe,
      duration: formDatas.duration === -1 ? formDatas.customduration : formDatas.duration,
      location: formDatas.location,
      conference: formDatas.conference ? t(`createEvent.form.locationitems.${formDatas.conference.replace(':', '')}.title`) : null,
    }
  }, [formDatas, slots.total]);
  return <Trans i18nKey='createEvent.form.periods.messages.pollOrFunnel_dl' values={message}
    components={{
      b: <b />,
      slots: <span className='open-heatmap' onClick={showHeatMap} />,
      s: <span className={message.periodOfTime ? '' : "invisible"} />,
      c: <span className={message.conference ? '' : "invisible"} />,
      cl: <span className={(message.conference && message.location) ? '' : "invisible"} />,
      l: <span className={message.location ? '' : "invisible"} />
    }} />;
}

function PollOnlyMsg({
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  const { t } = useTranslation();
  const message = React.useMemo(() => {
    /* istanbul ignore if */
    if (!slots || !formDatas) return {};
    // time to go
    let currenttimeframe = "";
    if (formDatas.currenttimeframe > -1) currenttimeframe = t('createEvent.form.periods.messages.timeframes_' + formDatas.currenttimeframe);

    return {
      slotsCount: slots.total,
      title: formDatas.title || t('createEvent.form.periods.messages.enterMeetingName'),
      inviteesCount: formDatas.invitees.length,
      periodOfTime: currenttimeframe,
      duration: formDatas.duration === -1 ? formDatas.customduration : formDatas.duration,
      location: formDatas.location,
      conference: formDatas.conference ? t(`createEvent.form.locationitems.${formDatas.conference.replace(':', '')}.title`) : "",
    }
  }, [formDatas, slots.total])
  if (!slots || !formDatas) return null;
  return <Trans i18nKey='createEvent.form.periods.messages.pollOnly_dl' values={message}
    components={{
      b: <b />,
      slots: <span className='open-heatmap' onClick={showHeatMap} />,
      s: <span className={message.periodOfTime ? '' : "invisible"} />,
      c: <span className={message.conference ? '' : "invisible"} />,
      cl: <span className={(message.conference && message.location) ? '' : "invisible"} />,
      l: <span className={message.location ? '' : "invisible"} />
    }} />
}

function SendInvitesMsg({
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  const { t } = useTranslation();

  const doShowHeatmap = React.useCallback(() => {
    if (!slots || !slots.final_slot) return;
    showcustomSlotsDlg(true, {
      allowMultipleSlots: false,
      values: [{ uuid: v4(), start: moment(slots.final_slot.start), end: moment(slots.final_slot.end) }],
      initialDate: moment(slots.final_slot.start)
    })
  }, [showcustomSlotsDlg, slots])

  const message = React.useMemo(() => {
    /* istanbul ignore if */
    if (!slots || !formDatas || !slots.final_slot) return {};
    let currenttimeframe = "";
    if (formDatas.currenttimeframe > -1) currenttimeframe = t('createEvent.form.periods.messages.timeframes_' + formDatas.currenttimeframe);
    let finvitee = formDatas.invitees[0] || '';

    let cnt = (contacts || {})[finvitee] || { email: finvitee };
    let details = formDatas.inviteesDetails || {};
    let dt = details[finvitee] || { email: finvitee };
    dt = {
      ...cnt,
      ...dt,
    }
    let firstInvitee = dt.label || dt.name || dt.email;
    return {
      title: formDatas.title || t('createEvent.form.periods.messages.enterMeetingName'),
      name: firstInvitee,
      count: formDatas.invitees.length,
      date: moment(slots.final_slot.start).format(t('common.fullDateFormat')),
      location: formDatas.location,
      conference: formDatas.conference ? t(`createEvent.form.locationitems.${formDatas.conference.replace(':', '')}.title`) : "",
    }
  }, [formDatas, slots.total, contacts]);

  return (
    <>
      <Trans i18nKey='createEvent.form.periods.messages.sendInvites_dl' values={message}
        components={{
          b: <b />,
          c: <span className={message.conference ? '' : "invisible"} />,
          l: <span className={message.location ? '' : "invisible"} />,

          cl: <span className={(message.conference && message.location) ? '' : "invisible"} />,
        }} />
      <a data-testid="show-details" onClick={doShowHeatmap}>{t('createEvent.form.recap.changeSlot')}</a>
    </>)
}

export function OneToOneMsg({
  slots = {},
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  const { t } = useTranslation();
  const message = React.useMemo(() => {
    /* istanbul ignore if */
    if (!slots || !formDatas) return {};
    // time to go
    let currenttimeframe = "";
    if (formDatas.currenttimeframe > -1) currenttimeframe = t('createEvent.form.periods.messages.timeframes_' + formDatas.currenttimeframe);
    let firstVoterEmail = getVoters(formDatas.invitees, formDatas.inviteesDetails, contacts)[0];
    if (firstVoterEmail) {
      let finvitee = firstVoterEmail
      let cnt = (contacts || {})[finvitee] || { email: finvitee };
      let details = formDatas.inviteesDetails || {};
      let dt = details[finvitee] || { email: finvitee };
      dt = {
        ...cnt,
        ...dt
      }
      firstVoterEmail = dt.label || dt.name || dt.email;
    }
    let theDate = '';
    if (slots && slots.total === 1) {
      let tmpslots = (slots.slots[0] || { slots: [] }).slots[0];
      theDate = moment(tmpslots).format(t('common.fullDateFormat'));
    }
    return {
      slotsCount: slots.total,
      title: formDatas.title || t('createEvent.form.periods.messages.enterMeetingName'),
      name: firstVoterEmail,
      count: getNbVoters(formDatas.invitees, formDatas.inviteesDetails, contacts),
      periodOfTime: currenttimeframe,
      duration: formDatas.duration === -1 ? formDatas.customduration : formDatas.duration,
      location: formDatas.location,
      conference: formDatas.conference ? t(`createEvent.form.locationitems.${formDatas.conference.replace(':', '')}.title`) : "",
      theDate: theDate,
    }
  }, [formDatas, slots.total])
  if (!slots || !formDatas) return null;
  return <Trans i18nKey='createEvent.form.periods.messages.oneToOne_dl' values={message}
    components={{
      b: <b />,
      slots: <span className='open-heatmap' onClick={showHeatMap} />,
      s: <span className={message.periodOfTime ? '' : "invisible"} />,
      c: <span className={message.conference ? '' : "invisible"} />,
      cl: <span className={(message.conference && message.location) ? '' : "invisible"} />,
      l: <span className={message.location ? '' : "invisible"} />,
      sl: <span className={(!message.theDate) ? '' : "invisible"} />,
      d: <span className={(message.theDate) ? '' : "invisible"} />,
    }} />
}

// for update a funnel-started proposal
export function SendChanges({
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {

  const hndSubmit = React.useCallback(() => {
    handleSubmit({ forcePoll: formDatas.type === 'poll' })
  }, [formDatas.type, handleSubmit]);

  const btnClass = formDatas.type === 'poll' ? 'poll' : 'funnel-block';

  return <div className="period-v2" data-testid="edit-funnel-started">
    <div className="period-cta">
      <CTA label={"createEvent.form.saveChanges"}
        name="valid-changes"
        handleSubmit={hndSubmit}
        ctaclassName={btnClass}
        canSubmit={!isGeneratingLink}
        isGeneratingLink={isGeneratingLink}
        endIcon={<ChevronRight />} />
    </div >
  </div>;
}

export function SendInvites({
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  /* istanbul ignore if */
  if (!slots || !formDatas) return null;

  return <div className="period-cta">
    {!freeUserExpired &&
      <CTA label={"createEvent.form.periods.cta.sendInvites"}
        name="valid-invite"
        handleSubmit={() => handleSubmit({
          forced_slot: slots.final_slot
        })}
        canSubmit={!isGeneratingLink}
        isGeneratingLink={isGeneratingLink}
        endIcon={<ChevronRight />} />}
  </div >;
}

export function PollOrFunnel({
  slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  const { t } = useTranslation();
  const [pollOrFunnel, setPollOrFunnel] = React.useState('funnel');

  const doHandlePollSubmit = React.useCallback(() => {
    setPollOrFunnel('poll');
    handleSubmit({ forcePoll: true })
  }, [handleSubmit])

  const doHandleFunnelSubmit = React.useCallback(() => {
    setPollOrFunnel('funnel');
    handleSubmit()
  }, [handleSubmit])

  if (!slots || !formDatas) return null;

  return <>
    <div className="period-title inside">{t("createEvent.form.periods.titles.pollOrFunnel")}</div>
    {!freeUserExpired && <div className="period-cta poll-or-funnel">
      <CTAOptions recommended={true} title={'createEvent.form.periods.funnel.title'}
        subtitle={'createEvent.form.periods.funnel.subtitle'}
        description={'createEvent.form.periods.funnel.description'}
        ctaLabel={'createEvent.form.periods.funnel.cta'}
        illus={<IllusFunnel />}
        ctaclassName="funnel-block"
        isGeneratingLink={isGeneratingLink && pollOrFunnel === 'funnel'}
        handleSubmit={isGeneratingLink ? NOOP : doHandleFunnelSubmit}
        freeUserExpired={freeUserExpired} />
      <CTAOptions title={'createEvent.form.periods.poll.title'}
        subtitle={'createEvent.form.periods.poll.subtitle'}
        description={'createEvent.form.periods.poll.description'}
        ctaLabel={'createEvent.form.periods.poll.cta'}
        illus={<IllusPoll />}
        ctaclassName="poll"
        className="poller"
        isGeneratingLink={isGeneratingLink && pollOrFunnel === 'poll'}
        handleSubmit={isGeneratingLink ? NOOP : doHandlePollSubmit}
        freeUserExpired={freeUserExpired}
      />
    </div>}
  </>;
}

export function CTAOptions({
  recommended = false,
  tips = null,
  title, subtitle, description, illus, ctaLabel,
  className = "",
  ctaclassName = "",
  isGeneratingLink,
  freeUserExpired,
  handleSubmit,
}) {
  const { t } = useTranslation();
  return <div className={"cta-poll " + className + (recommended ? " recommended " : "")}>
    {recommended && (tips || <div className="ribbon">{t('createEvent.form.periods.recommended')}</div>)}
    <div className="cta-title">{t(title)}</div>
    <div className="cta-subtitle">{t(subtitle)}</div>
    <div className={"cta-illus " + ctaclassName}>{illus}</div>
    <div className="cta-desc">{t(description)}</div>
    <div className={"period-cta " + ctaclassName}>
      {!freeUserExpired &&
        <CTA label={ctaLabel}
          disabled={handleSubmit !== undefined}
          handleSubmit={handleSubmit}
          canSubmit={!isGeneratingLink}
          isGeneratingLink={isGeneratingLink}
          endIcon={<ChevronRight />} />}
    </div >
  </div>
}

export function PollOnly({ slots,
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  const { t } = useTranslation();
  const doHandlePollSubmit = React.useCallback(() => {
    handleSubmit({ forcePoll: true })
  }, [handleSubmit])

  if (!slots || !formDatas) return null;

  return <>
    <div className="period-title inside">{t("createEvent.form.periods.titles.pollOnly")}</div>
    <div className="period-cta poll-or-funnel">
      {!freeUserExpired && <div className="period-cta poll-or-funnel">
        <CTAOptions title={'createEvent.form.periods.funnel.title'}
          subtitle={'createEvent.form.periods.funnel.subtitle'}
          description={'createEvent.form.periods.funnel.description'}
          ctaLabel={'createEvent.form.periods.funnel.cta'}
          illus={<IllusFunnel />}
          isGeneratingLink={false}
          freeUserExpired={freeUserExpired}
          className='inert'
          handleSubmit={NOOP}
          ctaclassName="funnel-block" />
        <CTAOptions recommended={true}
          tips={<FunnelTips slots={slots} pollInfo={pollInfo} />}
          title={'createEvent.form.periods.poll.title'}
          subtitle={'createEvent.form.periods.poll.subtitle'}
          description={'createEvent.form.periods.poll.description'}
          ctaLabel={'createEvent.form.periods.poll.cta'}
          illus={<IllusPoll />}
          className="poller"
          ctaclassName="poll"
          isGeneratingLink={isGeneratingLink}
          handleSubmit={doHandlePollSubmit}
          freeUserExpired={freeUserExpired}
        />

      </div>}
    </div >
  </>;
}

export function FunnelTips({ slots, pollInfo = {}, onClick = NOOP }) {
  const { t } = useTranslation();
  const recommended = pollInfo.recommended_slot_count || 0;
  const actual = slots.total || 0;
  const nbrOfSlotsLeft = recommended - actual;
  return <div className="poll-tips" onClick={onClick}>
    {t('createEvent.form.periods.poll.tips', { count: nbrOfSlotsLeft })}
  </div>
}

export function OnlyOneGuestToVote({ slots = {},
  formDatas,
  handleSubmit,
  freeUserExpired,
  isGeneratingLink,
  showcustomSlotsDlg,
  showHeatMap,
  onChange,
  disableSlots,
  pollInfo,
  contacts,
  showPreview, }) {
  if (!slots || !formDatas) return null;

  return <>
    <div className="period-cta">
      {!freeUserExpired &&
        <CTA label={"createEvent.form.periods.cta.sendSlots"}
          handleSubmit={() => handleSubmit()}
          canSubmit={!isGeneratingLink}
          isGeneratingLink={isGeneratingLink}
          endIcon={<ChevronRight />} />}
    </div >
  </>;
}

// When form not completed OR not connected
export function NoPeriod({ isAuth, formDatas = {}, onSubmit = NOOP, isGeneratingLink }) {
  const { t } = useTranslation();
  const hasTitle = !!formDatas.title;
  const hasInvitees = formDatas.invitees && formDatas.invitees.length > 0;
  const hasSlots = formDatas.slots && formDatas.slots.length > 0;

  return <div className={"period-v2 no-period " + (!isAuth ? "not-auth " : "")}>
    {isAuth && <div className="period-title">
      <span className="no-period-msg">
        <Trans i18nKey='createEvent.form.periods.launchingTrans'
          components={{
            n: <span className={hasTitle ? "" : "bold"} />,
            inv: <span className={hasInvitees ? "" : "bold"} />,
            d: <span className={hasSlots ? "" : "bold"} />,
          }}>
          createEvent.form.periods.launching
        </Trans>
      </span>
      <CTA label={"createEvent.form.periods.cta.sendSlots"}
        handleSubmit={onSubmit}
        canSubmit={!isGeneratingLink}
        isGeneratingLink={isGeneratingLink}
        endIcon={<ChevronRight />} />
    </div>}
  </div>;
}

export function NoResults() {
  return null;
}

// Utils
export function currentStateOfProposal(formDatas, slots, pollInfo, contacts) {
  if (!formDatas || !slots || !pollInfo || !contacts) return STATE_INVALID;
  let slotsCount = slots.total;
  let invitees = formDatas.invitees || [];
  let guestCount = invitees.length;
  let voterCount = getNbVoters(invitees, formDatas.inviteesDetails, contacts);
  let possibleFunnel = !pollInfo.will_turn_to_poll;
  let d = formDatas.duration > 0 ? formDatas.duration : formDatas.customduration;
  let ivd = isValidDuration(d)
  if (!ivd) return STATE_INVALID;
  if (!slotsCount) return STATE_INVALID;
  if (slotsCount === 1) {
    let hasHybrids = invitees.filter((inv) => {
      return isUnknownDomain(inv) && !(formDatas.inviteesDetails[inv] || {}).optional  // unknown domain optional do not count.
    }).length > 0;
    return hasHybrids ? STATE_ONE_TO_ONE : STATE_SEND_INVITES; // ... has hybrids state?
  }
  if (voterCount > 1) {
    return possibleFunnel ? STATE_POLL_OR_FUNNEL : STATE_POLL_ONLY;
  } else if (voterCount === 1) {
    return guestCount > 1 ? STATE_ONE_TO_MANY : STATE_ONE_TO_ONE;
  } else if (voterCount === 0) {
    // direct meeting
    return STATE_SEND_INVITES;
  } else return STATE_INVALID;
}

function NoSlotFoundMessage({ formDatas = {} }) {

  const hasGuest = React.useMemo(() => {
    return formDatas.invitees && formDatas.invitees.length > 0;
  }, [formDatas.invitees]);

  const message = React.useMemo(() => {
    if (!hasGuest) return 'createEvent.form.recap.noSlotsFound.noguestTip';
    return 'createEvent.form.recap.noSlotsFoundTip.timeframe' + formDatas.currenttimeframe;
  }, [hasGuest, formDatas.currenttimeframe]);

  return <div className={"no-slot-found-msg"}>
    {hasGuest &&
      <div className="notslot-message">
        <div>
          <Trans
            i18nKey={message} // optional -> fallbacks to defaults if not provided
            defaults="hello <bold>world</bold>"
            components={{ bold: <strong /> }}
          />
        </div>
        <div>
          <Trans
            i18nKey='createEvent.form.recap.noSlotsFound.textTip'
            defaults="hello <bold>world</bold>"
            components={{ bold: <strong /> }}
          />
        </div>
      </div>}
  </div>;
}