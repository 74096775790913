import {
  GRID_HEIGHT,
  REPLACE,
} from '../config';
import moment from 'moment';
import { padToQuarter, FLOOR, CEIL } from './pad.to.quarter';
import { getOverlappingEventsCount } from './get.overlapping.events'
/**
 * Calculate event position top, left, bottom, 
 * 
 * @TODO when displaying with day or month, maybe will need to
 * have custom position calculator?
 * @param {Object} displayPeriod period for selecting events
 * @param {Array} events list of events to display
 * @param {boolean} allDayEventsOnTop all day events should go in special display
 * @param {boolean} processOverlap if true, check for overlapping events
 * @return {Object} events position information as { events:[], allday:[] or undefined}
 * @module components/agenda/utils
 */
export const position = (displayPeriod, events, allDayEventsOnTop = false, processOverlap = true, processOnlyAllDayEvent = false) => {
  if (!displayPeriod
    || !displayPeriod.start || !displayPeriod.end
    || !events
    || !events.length) return { events: [] }; // empty array means no events to display

  let evts = [];
  let alldays = allDayEventsOnTop ? genAlldaydatas(displayPeriod) : undefined;
  let { start, end, numOfDays, dow } = displayPeriod; // start and end of period to display

  const GRID_WIDTH = 100 / numOfDays;

  for (let evt of events) {
    let x, y, width, height; // position in view
    let evt_start = padToQuarter(moment(evt.start), FLOOR); // to not update datas
    let evt_end = padToQuarter(moment(evt.end), CEIL);
    // check start and end, assert they are in display period, if not, pad
    if (evt_start.unix() < start.unix()) evt_start = start.clone();
    if (evt_end.unix() > end.unix()) evt_end = end.clone();


    // ensure start is before end
    // if (evt.allday) console.log("ALL DAY POSITION", evt_start.format(), evt_end.format())
    /* istanbul ignore if only while debugging this is supposed to be somewhere else */
    if (evt_start >= evt_end) continue;
    if (allDayEventsOnTop && evt.allday) {
      // special ack, do not calculate height
      // only add to the list of days
      addEventToAllDay(evt, alldays, displayPeriod);

    } else if (!processOnlyAllDayEvent) {
      // init x y, width with start datas
      let st = start.clone();
      let es = evt_start.clone();
      x = es.diff(st, 'day') * GRID_WIDTH;
      y = evt_start.hours() * GRID_HEIGHT +
        (evt_start.minutes() / 15) * (GRID_HEIGHT / 4) +
        REPLACE;

      width = GRID_WIDTH; // depending on number of days to display (percents) + overlapping
      // assume no overlap by default
      height = 100; // in percent 100% of grid (for allday events)


      // check if must display on multiple day.
      let sameDay = evt_start.isSame(evt_end, 'day');
      if (!sameDay) {
        // multi day display
        while (!sameDay && evt_start < evt_end) {
          // go to end of day
          let ee = evt_start.clone().endOf('day');
          let tmp = ee.clone();

          // calculate height with new end
          ee.subtract(evt_start.hours(), "h").subtract(evt_start.minutes(), "m");
          height = ee.hours() * GRID_HEIGHT + (ee.minutes() / 15) * (GRID_HEIGHT / 4);

          // sometime, we can have a 0 height event with cuts
          // forget about them
          /* istanbul ignore else extrem edge case */
          if (height > 0) {
            // calculate x and width with overlapps
            let coords = { x, width };
            if (processOverlap) coords = calculateCoords(evt, evt_start, tmp, evts, events, { x, GRID_WIDTH });
            evts.push({
              ...evt,
              position: {
                x: coords.x, y, width: coords.width, height
              },
              // store start day of the event as it may be different from event start day for multi-day events
              // eg. used to position tooltip for busy slots
              currentDate: evt_start.clone()
            });
          }


          // calculate new start
          evt_start.add(1, 'day').startOf('day');
          es = evt_start.clone();
          x = es.diff(st, 'day') * GRID_WIDTH;
          // x = evt_start.weekday() * GRID_WIDTH; // problem ??? monday/sunday???
          y = REPLACE;
          // new diff in days
          sameDay = evt_start.isSame(evt_end, 'day');
        }
      }
      // end of event
      // if multi day, start hours/minutes are 0!
      let ee = evt_end.clone();
      let tmp = ee.clone();
      // calculate height with new end
      let hdiff = ee.diff(evt_start, 'h')
      let mdiff = ee.diff(evt_start, 'm') % 60
      ee.subtract(evt_start.hours(), "h").subtract(evt_start.minutes(), "m");
      let soffset = evt_start.utcOffset() / 60
      let eoffset = ee.utcOffset() / 60

      // height = ee.hours() * GRID_HEIGHT + (ee.minutes() / 15) * (GRID_HEIGHT / 4);
      height = (hdiff + (eoffset - soffset)) * GRID_HEIGHT + (mdiff / 15) * (GRID_HEIGHT / 4);
      // sometime, we can have a 0 height event with cuts
      // forget about them
      /* istanbul ignore else extrem edge case */
      if (height > 0) {
        let coords = { x, width };
        if (processOverlap) coords = calculateCoords(evt, evt_start, tmp, evts, events, { x, GRID_WIDTH });
        evts.push({
          ...evt,
          id: evt.id, // id is the only necessary info here!
          position: {
            x: coords.x, y, width: coords.width, height
          },
          // store start day of the event as it may be different from event start day for multi-day events
          // eg. used to position tooltip for busy slots
          currentDate: evt_start.clone()
        });
      }
    }
  }

  return {
    events: evts,
    allday: alldays
  }

}
/**
 * calculate position of event on grid depending on 
 * preceding events
 * Known limitation: can calculate too big width in some
 * edge cases (if lot of events overlapping)
 * @param {Any} evt event object with id
 * @param {moment} evt_start start date of event portion
 * @param {moment} evt_end end date of event portion
 * @param {array} events already placed events on grid
 * @param {array} eventList all events to display
 * @param {number} x default x position 
 * @param {number} GRID_WIDTH width (percents) of grid cell
 * @return {Object} {x, width} new position and width of event 
 */
function calculateCoords(evt, evt_start, evt_end, events, eventList, { x, GRID_WIDTH }) {
  // x and width: must take into account overlapping events
  const overlappCount = getOverlappingEventsCount(
    evt.id, // event uniq identifier
    evt_start,
    evt_end,
    eventList
  );
  const max = x + GRID_WIDTH; // max size of elem
  const ovc = overlappCount.length;
  let w = GRID_WIDTH;
  let ox = x;
  if (ovc > 0) {
    w = w / (ovc + 1);
    // calculate x position
    // get all events already positionned, calculate pos of new index
    const prec = countUniq(events, overlappCount);
    if (prec.length > 0) ox = calculateXPos(x, w, prec);
    // ensure that x + w is less than a day
    // will not work if negative length... This case, display more than usual
    if ((ox + w) > max && max > ox) {
      w = max - ox;
    }
  }
  return { x: ox, width: w }
}

/**
 * return count of already placed event, 1 for each id
 * @param {Array} evts list of events
 * @param {Array} overlappCount list of overlap
 * @return {number} count of overlapping events
 */
function countUniq(evts, overlappCount) {
  let known = {};
  return evts.filter(evt => {
    if (known[evt.id]) return false;
    known[evt.id] = 1;
    return overlappCount.filter(e => e.id === evt.id).length;
  });
}
// should calculated x
// depending on prec position?????
/**
 * 
 * @param {number} x position x before calculus
 * @param {number} w width before calculus
 * @param {Array} prec precendent placed events on grid
 * with {position:{x, width}} for items
 * @return {number} new x position
 */
function calculateXPos(x, w, prec) {
  // get first possible of values
  // by default, last position
  let i = x;

  for (let j = 0; j < prec.length; /*i += w,*/ j++) {
    // check if one is here
    // or not overlapping?
    // let p = prec.find((p) => p.pos.x === i || ((i + w) >= (p.pos.x + p.pos.width)));
    let p = prec.find(p => find(p, i, w));
    if (!p) return i;
    i = p.position.x + p.position.width;
  }
  return i;
}
// search a place to put this event
function find(p, i, w) {
  return i < p.position.x + p.position.width && i + w > p.position.x;
}
/**
 * generate all days event containers
 * @param {Period} period period to display in agenda
 * @return {Array} for each day of period: { date: moment, events: []}
 */
function genAlldaydatas(period) {
  // generate array for each days
  let pstart = period.start.clone();
  let pend = period.end;
  let dts = [];
  while (pstart < pend) {
    dts.push({
      date: pstart.clone(),
      events: [],
    });
    pstart.add(1, 'day');
  }
  // add last day of period?
  return dts;
}
function addEventToAllDay(event, allday, displayPeriod) {
  // get day of event + number of days of event
  // need to use the utc version of times?
  let estart = event.start.clone();
  let eend = event.end.clone();// .subtract(2, 'hour');
  // repad to week?
  if (estart < displayPeriod.start) estart = displayPeriod.start.clone();
  // if (eend > displayPeriod.end) eend = displayPeriod.end;
  let iday = allday.findIndex((a) => a.date.isSame(estart, 'day'));
  /* istanbul ignore else dumb no crash test */
  if (iday > -1) {
    // add event
    allday[iday].events.push(event.id);
    let daycount = Math.ceil(eend.diff(estart, 'hours') / 24);
    if (daycount < 1) return;
    // how many days?
    while (iday++ < (allday.length - 1) && /*allday[iday].date < eend*/ --daycount) {
      allday[iday].events.push(event.id);
    }
  }
}
