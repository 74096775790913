import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { STATUS } from "../../../../../api/validators/proposal";
import {
  REPONSE_STATUS,
  completeEventStatus
} from '../../../../../utils/event.status';
import {
  isUnknownDomain
} from '../../../../../api/validators/proposal';
import "./event.status.scss";
import { useDialog } from "../../../../dialogs/dialog.provider";
import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { MAX_INVITEES_LEGIT_TO_DISPLAY } from "../../../../../conf/general";


export default function EventStatus({ proposal, isActive = true }) {
  const { t } = useTranslation();
  // compute status for event
  let [status, cmp] = React.useMemo(() => {
    if (!proposal || !proposal.iAmHost || !(proposal.status === STATUS.CONFIRMED || proposal.status === STATUS.EVT_CANCELLED)) return ['', null];

    // meeting is set
    let when = moment(proposal.start).format(t("common.fullDateFormat"));
    let status = completeEventStatus(proposal);
    if (status === false) return ['', null];
    let raw_details = proposal.raw_invitees_details || {};
    let details = proposal.inviteesDetails || {};
    // if all unknown, pass
    let unknowns = Object.values(details).filter((att) => isUnknownDomain(att.email));
    return [status, (
      <div data-testid="confimed-event" className="status-confirmed">
        <div>{t("proposal.timeline.eventStatus." + status)}</div>
        <InviteesResponse proposal={proposal} raw_details={raw_details} details={details} />
        {unknowns.length > 0 && <div className="unknowns">
          <div>{t("proposal.timeline.eventStatus.unknown")}</div>
          <InviteesResponseUnknown unknowns={unknowns} />
        </div>}
      </div>)];

  }, [proposal, t]);

  // only host for now
  if (!proposal || !proposal.iAmHost
    || proposal.status !== STATUS.CONFIRMED) return null;

  return (
    <div
      className={
        "timeline-item event-status " + (isActive ? " active " : "") + status
      }
    >
      {cmp}
    </div>
  );
}

const InviteesResponse = ({ proposal, raw_details, details }) => {
  const { t } = useTranslation();
  const { enqueueDialog, closeDialog } = useDialog();

  const theList = React.useMemo(() => {
    if (!proposal) return [];
    return proposal.invitees.filter(inv => {
      let detail = details[inv] || {};
      return !detail.isUnknown;
    })
  }, [proposal, details]);

  const guestsCmp = React.useMemo(() => {
    return (theList || []).map(inv => {
      let detail = details[inv] || {};
      if (detail.isUnknown) return null;
      return (
        <div
          className={
            "invitee-response " +
            (REPONSE_STATUS[(raw_details[inv] || {}).response] ||
              "unknown")
          }
          key={"response-" + inv}
        >
          <span>{inv}</span>{detail.optional && <span className="optional">&nbsp;{t('proposal.timeline.eventStatus.optional')}</span>}
        </div>
      );
    })
  }, [theList, details, raw_details, t]);

  const showAllInvitees = React.useCallback(() => {
    const onClose = () => {
      closeDialog();
    }
    enqueueDialog({
      content: <>
        <DialogTitle className='lm-guests-popup-title'>
          {t('proposal.details.guestsPopupTitle')}
          <IconButton aria-label="close" onClick={onClose} className="close-button">
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"lm-guests-popup fancy-scroll event-status"}>
          {guestsCmp}
        </DialogContent>
      </>,
      doClose: onClose,
    })
  }, [enqueueDialog, closeDialog, t, guestsCmp])

  return (theList||[]).length <= MAX_INVITEES_LEGIT_TO_DISPLAY ? guestsCmp
    : <span><a href='#' onClick={showAllInvitees}>{t('proposal.timeline.unknown.viewFullList')}</a></span>
}

const InviteesResponseUnknown = ({ unknowns }) => {
  const { enqueueDialog, closeDialog } = useDialog();
  const { t } = useTranslation();

  const guestsCmp = React.useMemo(() => {
    return unknowns.map((unk) => <div
      className={"invitee-response no-mail"}
      key={"response-" + unk.label}
    >
      <span>{unk.label}</span>{unk.optional && <span className="optional">&nbsp;{t('proposal.timeline.eventStatus.optional')}</span>
      }
    </div>)
  }, [unknowns, t]);

  const showAllInvitees = React.useCallback(() => {
    const onClose = () => {
      closeDialog();
    }
    enqueueDialog({
      content: <>
        <DialogTitle className='lm-guests-popup-title'>
          {t('proposal.details.guestsPopupTitle')}
          <IconButton aria-label="close" onClick={onClose} className="close-button">
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"lm-guests-popup fancy-scroll event-status"}>
          {guestsCmp}
        </DialogContent>
      </>,
      doClose: onClose,
    })
  }, [enqueueDialog, closeDialog, t])

  return unknowns.length <= MAX_INVITEES_LEGIT_TO_DISPLAY ? guestsCmp :
    <span><a href='#' onClick={showAllInvitees}>{t('proposal.timeline.unknown.viewFullList')}</a></span>;
}