import React from "react";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import "./Year.scss";
export default ({ date, // selected date
    onPrevMonth,
    onNextMonth, // cllbcks
    mini, // display small
    disablePast = true // past selected?
}) => {
    const year = date.year();
    const month = date.month();

    const MONTHS = moment.months();
    const now = moment().startOf("day");
    const cp = date.clone();
    const canDisable = disablePast && cp.month(month - 1).unix() < now.unix();

    return (
        <div id="monthyear" className={"year" + (mini ? " condensed" : "")}>
            <span className="year-value">{MONTHS[month] + ' ' + year}</span>
            <IconButton
                color="primary"
                data-testid="prev-year"
                onClick={onPrevMonth}
                disabled={canDisable}
                className={canDisable ? "disabled" : ""}
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton color="primary" data-testid="next-year" onClick={onNextMonth}>
                <KeyboardArrowRight />
            </IconButton>

        </div>
    );
};
