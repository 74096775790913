import { createActions, handleActions, combineActions } from 'redux-actions';
import { periodToSlots } from '../utils/period.to.slots';
import * as ACTIONS from '../actions'
import { v4 } from 'uuid';
import moment from 'moment';
import { createSelector } from 'reselect';
import { STATUS } from '../api/validators/proposal';
const defaultState = {};


export const PREFILL_RESCHEDULE = "reschedule";
export const PREFILL_FOLLOW_UP = "follow_up";
export const PREFILL_EDIT = "edit";

const reducer = handleActions(
  {
    /*'__DEV__CONNECTED': (state, action) => {
      // create event in upcomming for test
      let event = action.payload.rawevent;
      // calculate slots
      let d = event.duration > 0 ? event.duration : event.customduration;
      let slots = periodToSlots(event.proposals, d);
      // calculate response????

      // add an id
      let tmp = {
        ...event,
        slots: slots,
        id: v4()
      }
      return {
        ...state,
        [tmp.id]: tmp,
      }
    },*/
    [ACTIONS.WORKER_GET_PROPOSAL_SUCCESS]: (state, action) => {
      // must return an object with id => event
      if (action && action.request && action.request.search) {
        // search from title predictive, do not take care of those one
        // will messed up the dashboard
        return state;
      }
      let tmp = action.payload || {};
      if (Array.isArray(tmp)) {
        tmp = action.payload.reduce((acc, e) => {
          if (!e) {
            return acc;
          }
          acc[e.id] = e;
          return acc;
        }, {})
      } else {
        if (tmp.id) {
          tmp = {
            [tmp.id]: tmp
          }
        } else tmp = {}

      }

      return {
        ...state,
        ...tmp
      }
    },
    [ACTIONS.WORKER_VOTE_PROPOSAL_SUCCESS]: (state, action) => {
      // save object with new state for slots?
      let proposal = (action.payload || {}).opts;
      // console.log('User had voted', action.payload, proposal, proposal.id, state[proposal.id])
      if (!proposal || !proposal.id || !state[proposal.id]) return state;

      // 2 cases: I hd made a lookup, then I have the latest version
      // or i don't, and I must complete by hand
      let p;
      if (action.proposal) {

        // just replace datas
        p = action.proposal;
      } else {
        // mark in details that I Had vote
        let st = state[proposal.id].inviteesDetails;
        if (st) {
          // update status of user
          let att = st[proposal.email];
          if (att) att.response = 'yes'
        }
        p = {
          ...state[proposal.id],
          hadVoted: true,
        };
      }


      return {
        ...state,
        [proposal.id]: p
      }
    },
    [ACTIONS.WORKER_CREATE_PROPOSAL_SUCCESS]: (state, action) => {
      // save object with new state for slots?
      let proposal = action.payload;
      if (!proposal || !proposal.id) return state;
      let np = /*state[proposal.id] ||*/ proposal;
      np.status = STATUS.CONFIRMED;
      np.updated_at = moment().toISOString()
      return {
        ...state,
        [proposal.id]: np
      }
    },
    [ACTIONS.WORKER_SAVE_PROPOSAL_SUCCESS]: (state, action) => {
      let proposal = action.payload;
      if (!proposal || !proposal.id) return state;
      proposal.updated_at = moment().toISOString()
      return {
        ...state,
        [proposal.id]: proposal
      }
    },
    [ACTIONS.WORKER_CANCEL_EVENT_SUCCESS]: (state, action) => {
      // save object with new state for slots?
      let proposal = action.payload;
      if (!proposal || !proposal.id) return state;
      let np = /*state[proposal.id] ||*/ proposal;
      np.status = STATUS.CANCELLED;
      return {
        ...state,
        [proposal.id]: np
      }
    },
    [ACTIONS.WORKER_LOGOUT]: () => defaultState,
    [ACTIONS.INVALID_CREDENTIALS]: () => defaultState, // in case?
  },
  defaultState
);

/* istanbul ignore next legacy */
export function getUpcommingProposals(state) { return Object.values(state.proposals) }

// get full list of proposals ids
export const getProposals = createSelector(
  (state) => state.proposals,
  (proposals) => {
    return Object.values(proposals).sort((a, b) => {
      // sort by creation date, most recent first
      return a.created_at < b.created_at ? 1 : -1;
    }).map((p) => p.id);
  });

export const getDecoratedProposals = createSelector(
  (state) => state.proposals,
  (state) => state.accounts,
  (state) => state.links,
  (proposals, accounts, links) => {
    Object.values(proposals).map((p) => {
      // sort by creation date, most recent first
      if (accounts && accounts.find((acc) => acc.id === p.calendar.accountId)) p.iAmHost = true;
      p.me = (p.invitees || []).find(i => !!(accounts || []).find(a => a.email === i));
      if (p.booking_link_id) p.booking_link = links.find((l) => l.id === p.booking_link_id);
    });
    return proposals;
  });

// get proposals I created
export const getNbCompleteProposalsICreated = createSelector(
  getDecoratedProposals,
  (proposals) => {
    return Object.values(proposals).reduce((previous, currentValue, i) =>
      previous + (currentValue.iAmHost ? 1 : 0), 0);
  }
);

export const getTemplatedProposals = createSelector(
  (state) => state.proposals,
  (state) => state.accounts,
  (proposals, accounts) => {
    let r = {};
    Object.values(proposals).map((p) => {
      // sort by creation date, most recent first
      if (accounts) {
        let acc = accounts.find((acc) => acc.id === p.calendar.accountId);
        if (!acc) return;

        p.iAmHost = true; // mark as I am host
        // populate calendar data
        p.calendar = {
          ...p.calendar,
          ...acc,
          provider: p.calendar.provider,
        }
        if (!r[p.title] || r[p.title].created_at < p.created_at) {
          r[p.title] = p;
        }
      }
    });
    return Object.values(r).sort((a, b) => a.title < b.title);
  });
export const getTemplatedLocations = createSelector(
  getTemplatedProposals,
  (proposals) => {
    let r = {};
    for (let p of proposals) {
      // if conference, pass
      if (p.conference) continue;
      if (!p.location) continue;
      r[p.location] = 1;
    }
    return Object.keys(r).sort((a, b) => a < b);
  });

export function getProposal(id) {
  return (state) => {
    let p = state.proposals[id];
    if (p) {
      // check if I am host
      if (state.accounts && state.accounts.find((acc) => acc.id === p.calendar.accountId)) p.iAmHost = true;
    }
    /*if(!p){
      // generate a dummy proposal for test
      return dev_generateDummyProposal(id)
    }*/
    return p;
  }
}
/* istanbul ignore next dev only */
function dev_generateDummyProposal(id) {

  if (id === 'ooops') {
    // return error
    return {
      errorCode: 500
    }
  }
  if (id === '404') {
    return {
      errorCode: 404
    }
  }
  return {
    id: id,
    title: 'a long title to be able to display on 2 lines.',
    duration: 60,
    notes: "Some notes for a text that will take care of displaying google.com as a link",
    organizer: { email: 'joe@joe.com', name: "a name" },
    invitees: ['anemail@mail.com', 'another@mail.com', 'athird@gmail.com'],
    slots: [
      { day: moment().add(1, 'day'), slots: [{ start: moment().add(1, 'day'), end: moment().add(1, "day").add(1, 'hour'), answers: ['anemail@mail.com', 'another@mail.com', 'athird@gmail.com'] }] },
      {
        day: moment().add(2, 'day'), slots: [{ start: moment().add(2, 'day'), end: moment().add(2, "day").add(1, 'hour') },
        { start: moment().add(2, 'day').add(2, 'hour'), end: moment().add(2, "day").add(3, 'hour'), answers: ['anemail@mail.com', 'another@mail.com'] },
        { start: moment().add(2, 'day').add(3, 'hour'), end: moment().add(1, "day").add(4, 'hour'), answers: ['anemail@mail.com', 'another@mail.com'] }
        ]
      },
      { day: moment().add(3, 'day'), slots: [{ start: moment().add(3, 'day').add(5, 'hour'), end: moment().add(3, "day").add(6, 'hour') }] }
    ]
  }
}
export default reducer;