import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  LinearProgress
} from '@material-ui/core'
import {
  BarChart
} from '@material-ui/icons';
import {
  ComposedChart,
  BarChart as BarChartRecharts,
  // Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  Area,
  ResponsiveContainer
} from 'recharts';
import * as ACTIONS from '../../../actions';
import hduration from "humanize-duration";
import moment from 'moment';
import {
  MONTHS, WEEKS, DAYS
} from '../../../worker/handlers/get.stats';
import "./graph.scss";

const FORMATS = {
  [MONTHS]: "_month",
  [WEEKS]: "_week",
  [DAYS]: "_day"
}
export default function UserGraph() {
  const { t } = useTranslation();
  const [stats, setStats] = React.useState(null)
  const dispatch = useDispatch();
  const formatter = React.useCallback((value, name) => {
    if (name === 'total' && stats && stats.step) {
      return [value, t("profile.graph." + name + FORMATS[stats.step])];
    } else {
      return [value, t("profile.graph." + name)];
    }
  }, [t, stats])
  const labelformatter = React.useCallback((index) => {
    if (!stats || !stats.usage || stats.usage.length === 0) return '';
    // let v = stats.usage[index];
    // if (!v) return '';
    // return v.name;
    return index;
  }, [stats])
  // const legendFormatter = React.useCallback((value) => {
  //   if (value === "total" && stats && stats.step) {
  //     return t("profile.graph." + value + FORMATS[stats.step]);
  //   } else
  //     return t("profile.graph." + value);
  // }, [t, stats])
  React.useEffect(() => {
    dispatch({
      type: ACTIONS.WORKER_GET_STATS,
      payload: {
        stats: "all"
      },
      resolvers: {
        resolveOn: ACTIONS.WORKER_GET_STATS_SUCCESS,
        rejectOn: ACTIONS.WORKER_GET_STATS_ERROR
      }
    }).then((res) => {
      setStats(res.payload)
    }).catch((err) => {
      // n error, show promote
      setStats({ complete_proposal_count: 0 })
    })
  }, [dispatch]);

  if (stats === null) return <LinearProgress />;
  if (stats && stats.complete_proposal_count === 0) return <Promote />
  return <div className="profile-graph">
    <div className="title"><BarChart />{t("profile.graph.title")}</div>
    <div className="subtitle"><Trans i18nKey={"profile.graph.subtitle"}>
      You scheduled <b>{{ value: stats.complete_proposal_count }}</b> meetings with Letsmeet.<br />
      That's <b>{{ duration: hduration(stats.total_saved_time * 1000, { language: moment.locale() }) }}</b> saved!</Trans></div>
    <div className="graph-title">{t("profile.graph.graphTitle")}</div>
    <div className='graph-container'>
      <ResponsiveContainer debounce={300} width="100%" height={200}>
        <BarChartRecharts
          data={stats.usage}
          margin={{ top: 15, right: 0, left: 0, bottom: 15 }}
        // barSize={20}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#7060DF" stopOpacity={1.0} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
            </linearGradient>
          </defs>
          {/* <Legend formatter={legendFormatter} height={50} /> */}
          <YAxis
            xAxisId="left"
            allowDecimals={false}
            // tickCount={200}
            padding={{ left: 0, right: 0 }}
            margin={{ left: 0, right: 0 }}
            tickLine={false}
            tick={{ fontSize: 13 }}
            axisLine={false}
            width={30}
          // dataKey="name" 
          />
          <CartesianGrid
            vertical={false}
            stroke="#D3D3D3"
            strokeDasharray="8 5" />
          <Tooltip
            cursor={{ fill: 'rgba(0,0,0,0.07)', }}
            contentStyle={{ backgroundColor: 'white', boxShadow: "0px 5px 15px -3px rgba(0,0,0,0.3)", borderRadius: 3, border: 0, fontSize: 14, padding: '15px 15px 10px 15px' }}
            labelStyle={{ fontWeight: '500', marginBottom: '5px' }}
            formatter={formatter}
            labelFormatter={labelformatter}
          />
          <Bar xAxisId="x-axis" dataKey="total" /*barSize={20}*/ fill="#7060DF" />
          {/* <Area xAxisId="x-axis" yAxisId="left" type="monotone" dataKey="accu" fill="url(#colorUv)" stroke="#7060DF" /> */}
          <XAxis
            xAxisId="x-axis"
            padding={{ left: 0, right: 0 }}
            tickLine={false}
            tick={{ fontSize: 13 }}
            axisLine={{ stroke: "#D3D3D3" }}
            dataKey="name" />
        </BarChartRecharts>
      </ResponsiveContainer>
    </div>
  </div>
}
function Promote() {
  const { t } = useTranslation();
  const dummies = React.useMemo(() => {
    let tmp = [];
    let accu = 0;
    for (let i = 0; i < 10; i++) {
      let total = Math.ceil(Math.random() * 10);
      accu += total;
      tmp.push({
        accu,
        total,
      })
    }
    return tmp;
  })
  return <div className="profile-promote">
    <ResponsiveContainer width="100%" height={200}>
      <ComposedChart
        data={dummies}
        className="blurred"
        margin={{ top: 15, right: 0, left: 0, bottom: 15 }} >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#7060DF" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#7060DF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Bar xAxisId="x-axis" yAxisId="right" dataKey="total" barSize={20} fill="#7060DF" />
        <Area xAxisId="x-axis" yAxisId="left" type="monotone" dataKey="accu" fill="url(#colorUv)" stroke="#7060DF" />
      </ComposedChart>
    </ResponsiveContainer>
    <div className="promote-msg"><span>{t('profile.graph.promote')}</span></div>
  </div>;
}
// if needed, we acn have a custom tooltip
// function CustomTooltip({ payload, label, active, periodicity }) {
//   if (active) {
//     return (
//       <div className="custom-tooltip">
//         <p className="label total">{JSON.stringify(payload)}</p>
//         <p className="label total">{`${label} : ${payload[0].value} ${payload[0].name}`}</p>
//         <p className="label accu">{`${label} : ${payload[0].value} ${payload[0].name}`}</p>
//       </div>
//     );
//   }
//   return null;
// }
//   return null;
// };
/*<XAxis xAxisId="x-axis" dataKey="name" scale="band" /><CartesianGrid stroke="#f5f5f5" /><YAxis yAxisId="right" orientation="right" />
      <YAxis yAxisId="left" />*/