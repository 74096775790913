import moment from 'moment';
import { STATUS } from '../api/validators/proposal';

// check if event has remaining slots
// special for confirmed event, check start is before now
export function hasRemaining(proposal) {
  let now = moment();
  // if status is confirmed, check start is in future
  // side note: if lookup event fail, event will ALWAYS be in archived
  if(proposal.status === STATUS.CONFIRMED){
    if (moment(proposal.start) < now) return false;
  }
  let count = proposal.slots ? proposal.slots.length : 0;
  /* istanbul ignore else no work */
  if (count > 0 && proposal.slots[count - 1]) {
    let lastSlot = proposal.slots[count - 1].slots;
    count = lastSlot ? lastSlot.length : 0;
    /* istanbul ignore else no  */
    if (count > 0 && lastSlot[count - 1]) {
      lastSlot = lastSlot[count - 1];
      if (moment(lastSlot.start) > now) return lastSlot;
    }
  }
}