import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './guest.item.scss';
import {
  Close,
  Email,
  // MailOutline
} from '@material-ui/icons';
import NOOP from '../../../../utils/noop';
import { LMTooltip } from '../../../tooltip/Tooltip';
// import {
//   Close,
// } from '../../../../assets/icons'
// import {  
//   MoreVertOutlined as More,
// } from '@material-ui/icons';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import IconButton from "@material-ui/core/IconButton";
import {
  Person,
  PersonOutlined,
  Delete,
  Link,
  VisibilityOff
} from '@material-ui/icons';
// const MAIL = <MailOutline />
/**
 * Display a simple contact
 * @param {Object} contact contact to display
 * @param {Function} onClick click callback if needed
 * 
 * @param {string} searchString string to search for
 */
const EMPTY_DETAILS = {}
export default function GuestChipItem({
  contact,
  details = EMPTY_DETAILS,
  guesticon = null,
  onClick = NOOP,
  toggleOptional = NOOP,
}) {
  const { t } = useTranslation();
  if (!contact) return null;
  const name = contact.name || details.name || contact.email;
  const isUnknown = details ? details.isUnknown : false;
  const icon = contact.isDoner ?
    details.isForced ? <Email /> :
      contact.isForbidden ? <LMTooltip content={t('createEvent.form.privateAccount')}><VisibilityOff className="chip-icon" /></LMTooltip> : null
    : isUnknown ? <Link /> : guesticon || <Email />;
  const optional = details ? details.optional : false;
  const isForbidden = contact ? contact.isForbidden : false;

  if (onClick === NOOP)
    return <Chip icon={icon} data-testid="invitee-chip" key={contact.email} label={name} className={contact.isDoner ? 'doner-invitee' : 'unknown-invitee'} />
  else
    return <ChipWithActions icon={icon} data-testid="invitee-chip" key={contact.email} label={name}
      isDoner={contact.isDoner} email={contact.email} optional={optional} forbidden={isForbidden} isUnknown={isUnknown} onDelete={() => onClick(contact.email)} className={contact.isDoner ? 'doner-invitee' : 'unknown-invitee'}
      onOptional={toggleOptional} />
}

// export const ChipWithMenu = ({icon, label,email,optional, onDelete, className, onOptional}) => {
//   const { t } = useTranslation();
//   const [anchorEl, setanchorEl] = React.useState(false);
//   const showMenu = React.useCallback((e)=>setanchorEl(e.target),[]);
//   const handleClose = React.useCallback((e)=>setanchorEl(false),[]);
//   const toggleOptional = React.useCallback((e)=> {
//     onOptional(email);
//     setanchorEl(false);
//   }, [email, onOptional]);

//   return <><Chip icon={icon} data-testid="invitee-chip" label={label} onDelete={showMenu} className={className + (optional ? " optional" : "")}
//     deleteIcon={<More />}/>
//     <Menu
//         id="chip-menu"
//         anchorEl={anchorEl}
//         open={!!anchorEl}
//         onClose={handleClose}
//         data-testid="toggle-invitee-menu"
//       >
//         <MenuItem data-testid="toggle-optional" onClick={toggleOptional}>{t(optional ? 'createEvent.form.setMandatoryInvitee':'createEvent.form.setOptionalInvitee')}</MenuItem>
//         <MenuItem data-testid="toggle-delete" onClick={onDelete}>{t('createEvent.form.deleteInvitee')}</MenuItem>
//       </Menu>
//     </>
// }
export const ChipWithActions = ({ icon, label, email, isDoner, optional, forbidden, isUnknown, onDelete, className, onOptional }) => {
  const { t } = useTranslation();
  const [anchorEl, setanchorEl] = React.useState(false);
  const showMenu = React.useCallback((e) => setanchorEl(e.target), []);
  const handleClose = React.useCallback((e) => setanchorEl(false), []);
  const toggleOptional = React.useCallback((e) => {
    onOptional(email);
    setanchorEl(false);
  }, [email, onOptional]);

  return <>
    <Chip
      icon={icon}
      data-testid="invitee-chip"
      label={isDoner ? <LMTooltip content={email}>{label}</LMTooltip> : label}
      onDelete={showMenu}
      className={className + (optional ? " optional" : "") + (forbidden ? " forbidden" : "")}
      deleteIcon={<div className="chip-actions">
        {onOptional != NOOP && <LMTooltip content={<>
          <Trans i18nKey={optional ? 'createEvent.form.setMandatoryInvitee' : 'createEvent.form.setOptionalInvitee'}>
            <p>Mark as <span className="strong">optional</span></p><p>Optionals aren't mandatory ;P</p>
          </Trans>
        </>}>
          <IconButton
            data-testid="toggle-optional"
            color="primary"
            onClick={toggleOptional}>
            {optional ? <PersonOutlined /> : <Person />}
          </IconButton>
        </LMTooltip>}
        <LMTooltip content={<p>{t('createEvent.form.deleteInvitee')}</p>}>
          <IconButton
            data-testid="toggle-delete"
            color="primary"
            onClick={onDelete}>
            <Close />
          </IconButton>
        </LMTooltip>
      </div>} />
  </>
}
