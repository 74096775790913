/* istanbul ignore file Redux init */
/**
 * @fileOverview Redux store conviguration
 * @author stephane@do.ne
 * @module utilities
 */

import { createStore, applyMiddleware } from 'redux'
import doneReducers from './reducers'
import middlewares from './middlewares';
import { composeWithDevTools } from 'redux-devtools-extension'


// if status && status

/**
 * @method * 
 * Configure Redux store
 * @public
 */
const configureStore = (init) => createStore(
  doneReducers,
  init,
  composeWithDevTools(applyMiddleware(...middlewares)),

);

export default configureStore;